import styled from 'styled-components'

export const SearchWrapper = styled.div`
  padding: 8px;

  input {
    width: 188px;
    margin-bottom: 8px;
    display: block;
  }

  button {
    width: 90px;
  }
`
