import React from 'react'
import { FIELD_NAMES } from 'const/forms'
import {
  Wrapper,
  PriorityInputNumber,
  StyledSwitch,
} from './ShopGroupSwitch.styles'
import { Typography } from 'antd'

const ShopGroupSwitch = ({ name, value, onActiveChange, onPositionChange }) => {
  const active = value[FIELD_NAMES.active]
  const position = value[FIELD_NAMES.position]

  return (
    <Wrapper>
      <Typography.Text
        strong
        style={{
          marginRight: 10,
        }}
      >
        {name}
      </Typography.Text>
      <StyledSwitch checked={active} onChange={onActiveChange} />
      <PriorityInputNumber
        disabled={!active}
        value={position}
        placeholder="Priority"
        min={0}
        onChange={onPositionChange}
      />
    </Wrapper>
  )
}

export default ShopGroupSwitch
