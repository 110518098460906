import React, { useState } from 'react'
import {
  Layout,
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
  Spin,
  Typography,
} from 'antd'
import { ButtonWrapper, StyledLayout } from './Login.styles'
import useAction from 'utils/useAction'
import { actions as adminActions } from 'domains/admin'

const layout = {
  labelCol: {
    span: 6,
    xxl: 8,
  },
  wrapperCol: {
    span: 12,
    xxl: 8,
  },
}

const tailLayout = {
  wrapperCol: {
    offset: 6,
    span: 12,
    xxl: {
      offset: 8,
      span: 8,
    },
  },
}

const Login = () => {
  const loginFunc = useAction(adminActions.adminLogin)
  const [email, setEmail] = useState('')
  const [isPasswordHidden, setIsPasswordHidden] = useState(false)
  const sendResetPasswordEmail = useAction(adminActions.sendResetPasswordEmail)
  const [loading, setLoading] = useState(false)

  const isEmailValid = (email) => {
    // eslint-disable-next-line max-len
    if (
      // eslint-disable-next-line max-len
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
        email
      )
    ) {
      return true
    }
    return false
  }

  const submitHandler = (values) => {
    setLoading(true)
    isPasswordHidden
      ? isEmailValid(email)
        ? sendResetPasswordEmail(email)
        : message.error('Enter a valid email')
      : loginFunc(values)
    setLoading(false)
  }

  const onSubmitFailed = () => {
    message.error('Login failed!', 2)
  }

  const emailChangeHandler = (e) => setEmail(e.target.value)

  const onForgotPasswordClick = () => {
    setIsPasswordHidden(!isPasswordHidden)
  }

  return (
    <StyledLayout>
      <Layout.Content>
        <Row justify="center" align="middle">
          <Col span={12}>
            <Spin spinning={loading} size="large">
              <Form
                {...layout}
                name="login-form"
                onFinish={submitHandler}
                onFinishFailed={onSubmitFailed}
              >
                {isPasswordHidden && (
                  <Typography.Text>
                    Enter your email to reset password
                  </Typography.Text>
                )}
                <Form.Item
                  label="Email"
                  name="email"
                  onChange={emailChangeHandler}
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                {!isPasswordHidden && (
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                )}

                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>

                <ButtonWrapper>
                  {!isPasswordHidden ? (
                    <Button
                      style={{ padding: 0 }}
                      type="link"
                      onClick={onForgotPasswordClick}
                    >
                      Forgot Password?
                    </Button>
                  ) : (
                    <Button
                      style={{ padding: 0 }}
                      type="link"
                      onClick={onForgotPasswordClick}
                    >
                      Login
                    </Button>
                  )}
                </ButtonWrapper>
              </Form>
            </Spin>
          </Col>
        </Row>
      </Layout.Content>
    </StyledLayout>
  )
}

export default Login
