import React from 'react'
import { useSelector } from 'react-redux'
import {
  actions as generalActions,
  selectors as generalSelectors,
} from 'domains/general'
import GroupComponent from './GroupComponent'

const Groups = () => {
  const groups = useSelector(generalSelectors.getUnformattedGroups)

  return (
    <GroupComponent
      addMethod={generalActions.addNewGroup}
      editMethod={generalActions.changeGroup}
      deleteMethod={generalActions.deleteGroup}
      data={groups}
    />
  )
}

export default Groups
