import React from 'react'
import { Divider, Table } from 'antd'
import {
  getEPCSColumns,
  getFeaturesColumns,
  getFlagsColumns,
  getActionsColumns,
} from './columns'
import { Header, Wrapper } from './ExpandableRow.styles'
import { FIELD_NAMES } from 'const/forms'

const ExpandableRow = ({ record, features, epcs, flags }) => {
  return (
    <Wrapper>
      {features.length > 0 && (
        <Table
          title={() => <Header>Features</Header>}
          columns={getFeaturesColumns(features)}
          dataSource={[record]}
          sticky
          bordered
          pagination={false}
        />
      )}
      {features.length > 0 && <Divider />}
      {flags.length > 0 && (
        <Table
          title={() => <Header>Flags</Header>}
          columns={getFlagsColumns(flags)}
          dataSource={[record]}
          sticky
          bordered
          pagination={false}
        />
      )}
      {flags.length > 0 && <Divider />}
      {epcs.length > 0 && (
        <Table
          title={() => <Header>EPCs</Header>}
          columns={getEPCSColumns(epcs)}
          dataSource={[record]}
          sticky
          bordered
          pagination={false}
        />
      )}
      {epcs.length > 0 && <Divider />}
      <Table
        title={() => <Header>Actions</Header>}
        columns={getActionsColumns()}
        dataSource={record[FIELD_NAMES.actions]}
        sticky
        bordered
        pagination={false}
      />
    </Wrapper>
  )
}

export default ExpandableRow
