import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InfoCircleTwoTone,
  FieldTimeOutlined,
  UndoOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'

export const Success = styled(CheckCircleTwoTone)`
  font-size: 25px;
`

export const Failure = styled(CloseCircleTwoTone)`
  font-size: 25px;
`

export const Warning = styled(InfoCircleTwoTone)`
  font-size: 25px;
`

export const Pending = styled(FieldTimeOutlined)`
  color: #3fa9ff;
  font-size: 29px;
`

export const Returned = styled(UndoOutlined)`
  color: #eb2f96;
  font-size: 25px;
`
