import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import getDisplayName from 'helpers/getDisplayName'

const withDisabledReasonTooltip = (WrappedComponent) => {
  const WithDisabledReasonTooltip = ({
    disabled,
    disabledReason,
    ...props
  }) => (
    <Tooltip title={disabled && disabledReason}>
      <WrappedComponent disabled={disabled} {...props} />
    </Tooltip>
  )

  WithDisabledReasonTooltip.propTypes = {
    // eslint-disable-next-line react/boolean-prop-naming
    disabled: PropTypes.bool,
    disabledReason: PropTypes.string,
  }

  WithDisabledReasonTooltip.defaultProps = {
    disabled: false,
    disabledReason: 'Undefined reason',
  }

  WithDisabledReasonTooltip.displayName = `WithDisabledReasonTooltip(${getDisplayName(
    WrappedComponent
  )})`

  return WithDisabledReasonTooltip
}

export default withDisabledReasonTooltip
