import { FIELD_NAMES } from 'const/forms'
import { formatDate } from 'helpers/dates'

export default () => [
  {
    title: 'Bonus Name',
    dataIndex: FIELD_NAMES.bonusName,
    key: FIELD_NAMES.bonusName,
  },
  {
    title: 'Created at',
    dataIndex: FIELD_NAMES.createdAt,
    key: FIELD_NAMES.createdAt,
    render: formatDate,
  },
  {
    title: 'Expires at',
    dataIndex: FIELD_NAMES.expiresAt,
    key: FIELD_NAMES.expiresAt,
    render: formatDate,
  },
  {
    title: 'Bonus Value',
    dataIndex: FIELD_NAMES.value,
    key: FIELD_NAMES.value,
  },
]
