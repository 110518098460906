import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { FIELD_NAMES } from 'const/forms'

const searchState = ({ search }) => search

export const getRecords = createSelector(searchState, (state) =>
  memoize((key) => {
    return state[key]?.data ?? []
  })
)
export const getIsFetching = createSelector(searchState, (state) =>
  memoize((key) => {
    return state[key]?.isFetching ?? false
  })
)
export const getPage = createSelector(searchState, (state) =>
  memoize((key) => {
    return state[key]?.page ?? 1
  })
)

export const getPageCount = createSelector(searchState, (state) =>
  memoize((key) => {
    return state[key]?.pageCount ?? 0
  })
)
export const getSearch = createSelector(searchState, (state) =>
  memoize((key) => {
    return state[key]?.search ?? ''
  })
)
export const getUnavailableRegions = createSelector(getRecords, (records) =>
  memoize((id, key) => {
    const record = records(key).find((item) => ~~item[FIELD_NAMES.id] === id)
    return record ? record.attributes[FIELD_NAMES.unavailableRegions] : []
  })
)
