import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { updateLocalizedShop, updateShop } from 'actions/views/shops'
import useAction from 'utils/useAction'
import arrayMutators from 'final-form-arrays'
import { Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/Input'
import { FIELD_NAMES } from 'const/forms'
import { required } from 'helpers/validators'
import { selectors as ShopsSelectors } from 'domains/shops'
import { StyledButton, StyledForm } from './Shops.styles'
import { RELATIONSHIPS_NAMES } from 'const/api'
import FieldSwitch from 'components/FieldSwitch/FieldSwitch'
import Select from 'components/Select/Select'
import country from 'const/country'
import SearchSelectShops from 'components/SearchSelect/WrappedShopsField'

const ShopModal = ({ id, localized, closeModal }) => {
  const isSavingActive = useSelector(ShopsSelectors.getIsSavingActive)
  const initialShopValues = useSelector(ShopsSelectors.getCurrentShop)
  const initialLocalizedValues = useSelector(
    ShopsSelectors.getLocalizedShopById
  )(id)
  const initialValues = localized ? initialLocalizedValues : initialShopValues
  const updateShopFunc = useAction(updateShop)
  const updateLocalizedShopFunc = useAction(updateLocalizedShop)
  const currentUpdateShopFunc = localized
    ? updateLocalizedShopFunc
    : updateShopFunc

  const ruleTypes = ['regular', 'mobile', 'checkout', 'derivative']

  const onSubmit = (data) => {
    currentUpdateShopFunc({ values: data, callback: closeModal })
  }

  const initialCountry = country.find(
    (item) => item.code === initialValues?.attributes?.[FIELD_NAMES.countryCode]
  )

  const parsedInitialValues = {
    [FIELD_NAMES.id]: initialValues?.id,
    [FIELD_NAMES.countryCode]: initialCountry?.code,
    [FIELD_NAMES.isDefault]: initialValues?.attributes?.[FIELD_NAMES.isDefault],
    [FIELD_NAMES.name]: initialValues?.attributes?.[FIELD_NAMES.name],
    [FIELD_NAMES.availableOnSearch]:
      initialValues?.attributes?.[FIELD_NAMES.availableOnSearch],
    [FIELD_NAMES.shopPageAvailable]:
      initialValues?.attributes?.[FIELD_NAMES.shopPageAvailable],
    [FIELD_NAMES.verified]: initialValues?.attributes?.[FIELD_NAMES.verified],
    [FIELD_NAMES.ruleType]: initialValues?.attributes?.[FIELD_NAMES.ruleType],
    [FIELD_NAMES.shopId]: initialValues?.attributes?.[FIELD_NAMES.shopId],
  }

  return (
    <Form
      destroyOnUnregister
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={parsedInitialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <Spin spinning={isSavingActive} size="large">
            <StyledForm onSubmit={handleSubmit}>
              {id && (
                <Field
                  name={FIELD_NAMES.id}
                  label="ID"
                  disabled
                  component={Input}
                />
              )}
              {localized ? (
                <>
                  <Field
                    name={FIELD_NAMES.shopId}
                    component={SearchSelectShops}
                    label="Shop ID"
                    entity={FIELD_NAMES.shopId}
                  />
                  <Field
                    type="checkbox"
                    name={FIELD_NAMES.isDefault}
                    component={FieldSwitch}
                    label="Is Default"
                    defaultValue={false}
                  />
                  <Field
                    options={country.map((item) => ({
                      key: item.code,
                      value: item.code,
                      label: item.code + ' - ' + item.name,
                    }))}
                    name={FIELD_NAMES.countryCode}
                    label="Country"
                    component={Select}
                    defaultValue={false}
                  />
                  <Field
                    options={ruleTypes.map((item, index) => ({
                      key: index,
                      value: item,
                      label: item,
                    }))}
                    name={FIELD_NAMES.ruleType}
                    label="Rule Type"
                    component={Select}
                    defaultValue={false}
                  />
                </>
              ) : (
                <>
                  <Field
                    name={FIELD_NAMES.name}
                    validate={required}
                    label="Name"
                    component={Input}
                    entity={RELATIONSHIPS_NAMES.name}
                  />
                  <Field
                    name={FIELD_NAMES.availableOnSearch}
                    type="checkbox"
                    label="Available On Search"
                    component={FieldSwitch}
                    defaultValue={false}
                  />
                  <Field
                    name={FIELD_NAMES.verified}
                    type="checkbox"
                    label="Verified"
                    component={FieldSwitch}
                    defaultValue={false}
                  />
                </>
              )}
              <StyledButton
                type="primary"
                disabled={pristine}
                onClick={handleSubmit}
              >
                Save changes
              </StyledButton>
            </StyledForm>
          </Spin>
        )
      }}
    />
  )
}

ShopModal.propTypes = {
  id: PropTypes.any,
  // eslint-disable-next-line react/boolean-prop-naming
  localized: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
}

ShopModal.defaultTypes = {
  id: null,
  localized: false,
}

export default ShopModal
