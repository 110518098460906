import styled from 'styled-components'
import { LinkOutlined } from '@ant-design/icons'

export const LinkIcon = styled(LinkOutlined)`
  font-size: 24px;
  cursor: pointer;
  color: #1890ff;

  &:hover {
    color: #52c41a;
  }
`
