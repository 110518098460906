import { all, put, take, takeLatest } from 'redux-saga/effects'
import { actions as logsActions } from 'domains/adminLogs'
import * as actions from 'actions/views/adminLogs'
import { startLoading, finishLoading } from 'reducers/layoutSlice'

function* getLogsWorker({ payload } = {}) {
  try {
    yield all([put(startLoading()), put(logsActions.fetchAllLogs(payload))])
    yield take([
      logsActions.fetchAllLogsSuccess,
      logsActions.fetchAllLogsFailure,
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

export default function* () {
  yield all([takeLatest(actions.fetchAllLogs, getLogsWorker)])
}
