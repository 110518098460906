import React from 'react'
import Countdown from 'react-countdown'
import { FIELD_NAMES } from 'const/forms'

import { formatDate, formatDay } from 'helpers/dates'
import { formatFieldValue, formatUrl } from 'helpers/formatters/fields'
import { PAYMENT_COLUMN_KEY_TO_TITLE } from 'domains/user/const'
import RegionItem from 'components/RegionItem'

// UI says "Karma Cash Transactions"
export const transactionsColumns = {
  [FIELD_NAMES.id]: {
    title: PAYMENT_COLUMN_KEY_TO_TITLE[FIELD_NAMES.id],
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 100,
  },

  [FIELD_NAMES.retailerName]: {
    title: PAYMENT_COLUMN_KEY_TO_TITLE[FIELD_NAMES.retailerName],
    dataIndex: FIELD_NAMES.retailerName,
    key: FIELD_NAMES.retailerName,
    width: 100,
    render: (name, { [FIELD_NAMES.retailerDomain]: url }) => (
      <a rel="noreferrer noopener" href={formatUrl(url)} target="_blank">
        {name}
      </a>
    ),
  },
  [FIELD_NAMES.paymentDate]: {
    title: PAYMENT_COLUMN_KEY_TO_TITLE[FIELD_NAMES.paymentDate],
    dataIndex: FIELD_NAMES.paymentDate,
    key: FIELD_NAMES.paymentDate,
    width: 120,
    render: formatDay,
  },
  [FIELD_NAMES.processedAt]: {
    title: PAYMENT_COLUMN_KEY_TO_TITLE[FIELD_NAMES.processedAt],
    dataIndex: FIELD_NAMES.processedAt,
    key: FIELD_NAMES.processedAt,
    width: 120,
    render: formatDate,
  },
  [FIELD_NAMES.kcValue]: {
    title: PAYMENT_COLUMN_KEY_TO_TITLE[FIELD_NAMES.kcValue],
    dataIndex: FIELD_NAMES.kcValue,
    key: FIELD_NAMES.kcValue,
    render: formatFieldValue,
    width: 120,
  },
  [FIELD_NAMES.commissionOrderValue]: {
    title: PAYMENT_COLUMN_KEY_TO_TITLE[FIELD_NAMES.commissionOrderValue],
    dataIndex: FIELD_NAMES.commissionOrderValue,
    key: FIELD_NAMES.commissionOrderValue,
    width: 120,
  },
  [FIELD_NAMES.userId]: {
    title: PAYMENT_COLUMN_KEY_TO_TITLE[FIELD_NAMES.userId],
    dataIndex: FIELD_NAMES.userId,
    key: FIELD_NAMES.userId,
    width: 100,
  },
  [FIELD_NAMES.userLocation]: {
    title: PAYMENT_COLUMN_KEY_TO_TITLE[FIELD_NAMES.userLocation],
    dataIndex: FIELD_NAMES.userLocation,
    key: FIELD_NAMES.userLocation,
    render: (code) => <RegionItem country={{ code }} />,
    width: 120,
  },
  [FIELD_NAMES.walletStatus]: {
    title: PAYMENT_COLUMN_KEY_TO_TITLE[FIELD_NAMES.walletStatus],
    dataIndex: FIELD_NAMES.walletStatus,
    key: FIELD_NAMES.walletStatus,
    render: formatFieldValue,
    width: 110,
  },
  [FIELD_NAMES.countdownDate]: {
    title: PAYMENT_COLUMN_KEY_TO_TITLE[FIELD_NAMES.countdownDate],
    dataIndex: FIELD_NAMES.countdownDate,
    key: FIELD_NAMES.countdownDate,
    fixed: 'right',
    width: 150,
    align: 'center',
    render: (date) => (date ? <Countdown date={date} /> : '-'),
  },
  // [FIELD_NAMES.commissionId]: {
  //   title: PAYMENT_COLUMN_KEY_TO_TITLE[FIELD_NAMES.commissionId],
  //   dataIndex: FIELD_NAMES.commissionId,
  //   key: FIELD_NAMES.commissionId,
  //   width: 150,
  // },
  // [FIELD_NAMES.network]: {
  //   title: PAYMENT_COLUMN_KEY_TO_TITLE[FIELD_NAMES.network],
  //   dataIndex: FIELD_NAMES.network,
  //   key: FIELD_NAMES.network,
  //   width: 100,
  // },
  // [FIELD_NAMES.ruleId]: {
  //   title: PAYMENT_COLUMN_KEY_TO_TITLE[FIELD_NAMES.ruleId],
  //   dataIndex: FIELD_NAMES.ruleId,
  //   key: FIELD_NAMES.ruleId,
  //   render: formatFieldValue,
  //   width: 100,
  // },
  // [FIELD_NAMES.clickId]: {
  //   title: PAYMENT_COLUMN_KEY_TO_TITLE[FIELD_NAMES.clickId],
  //   dataIndex: FIELD_NAMES.clickId,
  //   key: FIELD_NAMES.clickId,
  //   render: formatFieldValue,
  //   width: 110,
  // },
  // [FIELD_NAMES.status]: {
  //   title: PAYMENT_COLUMN_KEY_TO_TITLE[FIELD_NAMES.status],
  //   dataIndex: FIELD_NAMES.status,
  //   key: FIELD_NAMES.status,
  //   width: 100,
  // },
  // [FIELD_NAMES.kcPercentage]: {
  //   title: PAYMENT_COLUMN_KEY_TO_TITLE[FIELD_NAMES.kcPercentage],
  //   dataIndex: FIELD_NAMES.kcPercentage,
  //   key: FIELD_NAMES.kcPercentage,
  //   render: formatFieldValue,
  //   width: 120,
  // },
}

export const DEFAULT_PAYMENT_COLUMNS = Object.keys(transactionsColumns).reduce(
  (result, key) => ({
    ...result,
    [key]: FIELD_NAMES.show,
  }),
  {}
)

export const getTransactionsColumns = (columns) =>
  columns
    .filter((column) => !!transactionsColumns[column])
    .map((key) => transactionsColumns[key])

// UI says "Engagement Matrix"
export const getEngagementMetricsColumns = () => [
  {
    title: 'ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 100,
  },
  {
    title: 'Active Days',
    width: 120,
    align: 'center',
    dataIndex: FIELD_NAMES.activeDays,
    key: FIELD_NAMES.activeDays,
    render: formatFieldValue,
  },
  {
    title: 'Avg Purchase Amount',
    width: 120,
    align: 'center',
    dataIndex: FIELD_NAMES.avgPurchaseAmout,
    key: FIELD_NAMES.avgPurchaseAmout,
    render: formatFieldValue,
  },
  {
    title: 'Current confirmed',
    width: 120,
    align: 'center',
    dataIndex: FIELD_NAMES.currentConfirmend,
    key: FIELD_NAMES.currentConfirmend,
    render: formatFieldValue,
  },
  {
    title: 'Current Pending',
    width: 120,
    align: 'center',
    dataIndex: FIELD_NAMES.currentPending,
    key: FIELD_NAMES.currentPending,
    render: formatFieldValue,
  },

  {
    title: 'Last Email Click',
    width: 120,
    align: 'center',
    dataIndex: FIELD_NAMES.lastEmailClick,
    key: FIELD_NAMES.lastEmailClick,
    render: formatDate,
  },
  {
    title: 'Last KarmaCash Confirmed (90 days)',
    width: 150,
    align: 'center',
    dataIndex: FIELD_NAMES.lastKarmaCashRecieved,
    key: FIELD_NAMES.lastKarmaCashRecieved,
    render: formatDate,
  },
  {
    title: 'Last list creation',
    width: 120,
    align: 'center',
    dataIndex: FIELD_NAMES.lastListCreation,
    key: FIELD_NAMES.lastListCreation,
    render: formatDate,
  },

  {
    title: 'Last push click',
    width: 120,
    align: 'center',
    dataIndex: FIELD_NAMES.lastPushClick,
    key: FIELD_NAMES.lastPushClick,
    render: formatDate,
  },
  {
    title: 'Last use of coupons',
    width: 120,
    align: 'center',
    dataIndex: FIELD_NAMES.lastUseOfCoupons,
    key: FIELD_NAMES.lastUseOfCoupons,
    render: formatDate,
  },
  {
    title: 'Past year confirmed',
    width: 120,
    align: 'center',
    dataIndex: FIELD_NAMES.pastYearConfirmed,
    key: FIELD_NAMES.pastYearConfirmed,
    render: formatFieldValue,
  },
  {
    title: 'Past year paid',
    width: 120,
    align: 'center',
    dataIndex: FIELD_NAMES.pastYearPaid,
    key: FIELD_NAMES.pastYearPaid,
    render: formatFieldValue,
  },
  {
    title: 'Sum of purchases',
    width: 120,
    align: 'center',
    dataIndex: FIELD_NAMES.sumOfPurchases,
    key: FIELD_NAMES.sumOfPurchases,
    render: formatFieldValue,
  },
  {
    title: 'Tags count lifetime',
    width: 120,
    align: 'center',
    dataIndex: FIELD_NAMES.tagsCountLifetime,
    key: FIELD_NAMES.tagsCountLifetime,
    render: formatFieldValue,
  },
  {
    title: 'Tags count past year',
    width: 120,
    align: 'center',
    dataIndex: FIELD_NAMES.tagsCountPastYear,
    key: FIELD_NAMES.tagsCountPastYear,
    render: formatFieldValue,
  },
  {
    title: 'Total number of purchases',
    width: 120,
    align: 'center',
    dataIndex: FIELD_NAMES.totalNumberOfPurchases,
    key: FIELD_NAMES.totalNumberOfPurchases,
    render: formatFieldValue,
  },
]
