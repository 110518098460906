import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Buttons, StyledButton, StyledForm, Text } from './Confirm.styles'
import { useSelector } from 'react-redux'
import { prevModalType } from 'reducers/modals/selectors'
import { MODAL_TYPES } from 'const/modals'
import { closePrev } from 'reducers/modals/actions'
import useAction from 'utils/useAction'
import { Form, Input } from 'antd'

const Confirm = ({
  callback,
  closeModal,
  text,
  description,
  inputToConfirm,
  textForConfirmation,
  buttonText,
  isCopyAndPasteDisabled,
}) => {
  const [inputToConfirmValue, setInputToConfirmValue] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const closePrevModalFunc = useAction(closePrev)
  const modalPrevType = useSelector(prevModalType)

  const onInputToConfirmChange = (e) => {
    setInputToConfirmValue(e.target.value)
  }

  const handleConfirm = () => {
    closeModal()
    callback()
  }

  useEffect(() => {
    if (modalPrevType === MODAL_TYPES.GLOBAL_UPDATE) {
      closePrevModalFunc()
    }
  }, [modalPrevType, closePrevModalFunc])

  useEffect(() => {
    if (inputToConfirm === inputToConfirmValue) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [inputToConfirmValue, inputToConfirm])

  return (
    <>
      <Text>{text}</Text>
      {description && <Text>{description}</Text>}
      {inputToConfirm && (
        <StyledForm>
          <Form.Item
            onChange={onInputToConfirmChange}
            label={`Type ${textForConfirmation} to continue`}
            rules={[
              { required: true, message: 'Please enter confirmation word.' },
            ]}
          >
            <Input
              onPaste={(e) => {
                if (isCopyAndPasteDisabled) e.preventDefault()
              }}
              onCopy={(e) => {
                if (isCopyAndPasteDisabled) e.preventDefault()
              }}
              value={inputToConfirmValue}
              placeholder={textForConfirmation}
            />
          </Form.Item>
        </StyledForm>
      )}
      <Buttons>
        <StyledButton onClick={closeModal}>Cancel</StyledButton>
        <StyledButton
          onClick={handleConfirm}
          type="primary"
          disabled={inputToConfirm && isDisabled}
        >
          {buttonText}
        </StyledButton>
      </Buttons>
    </>
  )
}

Confirm.propTypes = {
  text: PropTypes.string,
  description: PropTypes.string,
  callback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  inputToConfirm: PropTypes.string,
  buttonText: PropTypes.string,
  isCopyAndPasteDisabled: PropTypes.bool,
  textForConfirmation: PropTypes.string,
}

Confirm.defaultProps = {
  text: 'Do you want to continue? Unsaved changes will be lost.',
  description: null,
  inputToConfirm: null,
  buttonText: 'Confirm',
  isCopyAndPasteDisabled: false,
  textForConfirmation: 'a confirmation word',
}

export default Confirm
