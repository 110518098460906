import React from 'react'
import { useSelector } from 'react-redux'
import {
  getSlugFilters,
  getIsDataFilteredOrSorted,
  getIsLoadedSlugs,
  getSlugPage,
  getSlugPageCount,
  getSlugSearchString,
  getSlugSorter,
  getSortedRuleSlugs,
} from 'domains/rules/selectors'
import useAction from 'utils/useAction'
import { deleteRuleSlug, fetchRuleSlugs } from 'actions/views/ruleSlugs'
import { StyledTable, Wrapper, Buttons } from './RuleSlugs.styles'
import SearchInTableBar from 'components/SearchInTableBar'
import { Button } from 'antd'
import getColumns from './columns'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import { FIELD_NAMES } from 'const/forms'
import { openConfirmModal, openRuleSlugModal } from 'reducers/modals/actions'
import isEqual from 'lodash/isEqual'

const PAGE_SIZE = 20
const SCROLL = { x: 900 }
export const HEADER_OFFSET = {
  offsetHeader: 49,
}

const RuleSlugs = () => {
  const isLoaded = useSelector(getIsLoadedSlugs)
  const page = useSelector(getSlugPage)
  const search = useSelector(getSlugSearchString)
  const pageCount = useSelector(getSlugPageCount)
  const filters = useSelector(getSlugFilters)
  const sorter = useSelector(getSlugSorter)
  const dataSource = useSelector(getSortedRuleSlugs)
  const isDataFilteredOrSorted = useSelector(getIsDataFilteredOrSorted)

  const fetchRuleSlugsFunc = useAction(fetchRuleSlugs)
  const deleteRuleSlugFunc = useAction(deleteRuleSlug)
  const openConfirmModalFunc = useAction(openConfirmModal)
  const openRuleSlugModalFunc = useAction(openRuleSlugModal)

  const onSearchStringChange = (search) =>
    fetchRuleSlugsFunc({
      page: 1,
      search,
      filters,
      sorter,
    })
  const handleEditRuleSlug = (id) => openRuleSlugModalFunc({ id })
  const handleCreateRuleSlug = () => openRuleSlugModalFunc()
  const handleDeleteRuleSlug = (ruleSlug) => {
    const callback = () => deleteRuleSlugFunc(ruleSlug[FIELD_NAMES.id])
    const text =
      `Are you sure you want to delete this rule slug (ID: ${
        ruleSlug[FIELD_NAMES.id]
      })? ` + "This action can't be revoked."
    openConfirmModalFunc({ callback, text })
  }

  const onTableChange = (pagination, newFilters, newSorter) => {
    const pageChanged = page !== pagination.current
    const newFiltersOrSorter =
      !pageChanged &&
      (!isEqual(newFilters, filters) || !isEqual(newSorter, sorter))
    fetchRuleSlugsFunc({
      page: newFiltersOrSorter ? 1 : pagination.current,
      search,
      filters: newFilters,
      sorter: newSorter,
    })
  }

  const handleResetFilters = () =>
    fetchRuleSlugsFunc({
      search,
      page: 1,
      filters: {},
      sorter: {},
    })

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  return (
    <Wrapper>
      <SearchInTableBar
        placeholder="Search slug here"
        onSubmit={onSearchStringChange}
        value={search}
      >
        <Buttons>
          <Button
            onClick={handleCreateRuleSlug}
            type="primary"
            disabled={false}
            // disabled={!isLoaded}
          >
            Create
          </Button>
          <Button
            onClick={handleResetFilters}
            disabled={!isLoaded || !isDataFilteredOrSorted}
          >
            Reset filters
          </Button>
        </Buttons>
      </SearchInTableBar>
      <StyledTable
        onChange={onTableChange}
        showSorterTooltip={false}
        columns={getColumns(
          filters,
          sorter,
          handleEditRuleSlug,
          handleDeleteRuleSlug
        )}
        dataSource={dataSource}
        expandable={false}
        pagination={paginationConfig}
        sticky={isLoaded && HEADER_OFFSET}
        scroll={SCROLL}
        bordered
        loading={false}
        rowKey={getRowKey}
        rowClassName={getRegularRowClass}
      />
    </Wrapper>
  )
}

export default RuleSlugs
