import React from 'react'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { Button, Dropdown, Menu, Tooltip } from 'antd'
import { MenuItem } from './MenuAction.styles'
import { MoreOutlined } from '@ant-design/icons'
import { FIELD_NAMES } from 'const/forms'
import useAction from 'utils/useAction'
import {
  openConfirmModal,
  openConvertToRetailerModal,
  openCreateRetailerModal,
} from 'reducers/modals/actions'
import { convertToRetailer, deleteRetailer } from 'domains/retailers/actions'
import { retailerPropType } from 'const/propTypes'
import {
  RETAILER_TYPE_TO_LABEL,
  REMOVABLE_RETAILER_TYPES,
  CAN_BE_CONVERTED_TO_REGIONAL_RETAILER_TYPES,
  CAN_BE_CONVERTED_TO_MOBILE_RETAILER_TYPES,
  CAN_BE_CONVERTED_TO_GLOBAL_RETAILER_TYPES,
  CAN_BE_CONVERTED_TO_DOMAIN_RETAILER_TYPES,
  CAN_BE_MOVED_TO_ANOTHER_GLOBAL_TYPES,
} from 'const/retailers'
import { ENTITY_NAMES } from 'const/api'
import { HAS_RELATIONSHIPS } from 'const/errors'

const MoreActions = ({ retailer, checkFilter }) => {
  const openConvertToRetailerModalFunc = useAction(openConvertToRetailerModal)
  const openConfirmModalFunc = useAction(openConfirmModal)
  const openCreateRegionalRetailerModalFunc = useAction(openCreateRetailerModal)
  const deleteRetailerFunc = useAction(deleteRetailer)
  const convertToRetailerFunc = useAction(convertToRetailer)

  const onDirectConvert = (sourceType, destinationType) => () => {
    const callback = () =>
      convertToRetailerFunc({
        id: retailer[FIELD_NAMES.id],
        sourceType,
        destinationType,
      })
    const text =
      `Are you sure you want to convert ${retailer[FIELD_NAMES.name]} ` +
      `to ${RETAILER_TYPE_TO_LABEL[destinationType]}? This action can't be revoked.`
    openConfirmModalFunc({ callback, text })
  }

  const onConvert = (type) => () => {
    openConvertToRetailerModalFunc({
      retailer,
      type,
    })
  }

  const onDeleteRetailer = () => {
    const callback = () => deleteRetailerFunc(retailer)
    const text = `Are you sure you want to delete ${
      retailer[FIELD_NAMES.name]
    }? This action can't be revoked.`
    openConfirmModalFunc({ callback, text })
  }

  const onCreateCleanRetailer = () => {
    openCreateRegionalRetailerModalFunc({ retailer, clean: true })
  }

  const onCreateDirtyRetailer = () => {
    openCreateRegionalRetailerModalFunc({ retailer, clean: false })
  }

  const hasRelationships =
    retailer[FIELD_NAMES.hasDomainRetailers] ||
    retailer[FIELD_NAMES.hasMobileRetailers] ||
    !isEmpty(retailer[FIELD_NAMES.unavailableRegions])

  const canBeDeleted = REMOVABLE_RETAILER_TYPES.includes(retailer.type)
  const disableDeleting = retailer[FIELD_NAMES.cannotBeDeleted]
  const canBeMovedToAnotherGlobal =
    CAN_BE_MOVED_TO_ANOTHER_GLOBAL_TYPES.includes(retailer.type)
  const canBeConvertedToGlobal =
    CAN_BE_CONVERTED_TO_GLOBAL_RETAILER_TYPES.includes(retailer.type)
  const canBeConvertedToRegional =
    CAN_BE_CONVERTED_TO_REGIONAL_RETAILER_TYPES.includes(retailer.type)
  const canBeConvertedToDomain =
    CAN_BE_CONVERTED_TO_DOMAIN_RETAILER_TYPES.includes(retailer.type)
  const canBeConvertedToMobile =
    CAN_BE_CONVERTED_TO_MOBILE_RETAILER_TYPES.includes(retailer.type)
  const canBeCopiedToNewRegional = retailer.isRegional || retailer.isGlobal
  const typeForCopying =
    retailer.type === ENTITY_NAMES.main
      ? RETAILER_TYPE_TO_LABEL[ENTITY_NAMES.regional]
      : RETAILER_TYPE_TO_LABEL[retailer.type]
  const menu = (
    <Menu>
      {canBeConvertedToGlobal && (
        <MenuItem
          onClick={checkFilter(
            onDirectConvert(retailer.type, ENTITY_NAMES.main)
          )}
        >
          Convert to global retailer
        </MenuItem>
      )}
      {canBeConvertedToRegional && (
        <MenuItem
          disabled={hasRelationships}
          onClick={checkFilter(onConvert(ENTITY_NAMES.regional))}
        >
          <Tooltip title={hasRelationships && HAS_RELATIONSHIPS}>
            Convert to regional retailer
          </Tooltip>
        </MenuItem>
      )}
      {canBeConvertedToMobile && (
        <MenuItem
          disabled={hasRelationships}
          onClick={checkFilter(onConvert(ENTITY_NAMES.mobile))}
        >
          <Tooltip title={hasRelationships && HAS_RELATIONSHIPS}>
            Convert to mobile retailer
          </Tooltip>
        </MenuItem>
      )}
      {canBeConvertedToDomain && (
        <MenuItem
          disabled={hasRelationships}
          onClick={checkFilter(onConvert(ENTITY_NAMES.domain))}
        >
          <Tooltip title={hasRelationships && HAS_RELATIONSHIPS}>
            Convert to domain retailer
          </Tooltip>
        </MenuItem>
      )}
      {canBeMovedToAnotherGlobal && (
        <MenuItem onClick={checkFilter(onConvert(ENTITY_NAMES.regional))}>
          Connect to another global retailer
        </MenuItem>
      )}
      {canBeCopiedToNewRegional && (
        <>
          <MenuItem onClick={checkFilter(onCreateCleanRetailer)}>
            Create {typeForCopying} retailer (default)
          </MenuItem>
          <MenuItem onClick={checkFilter(onCreateDirtyRetailer)}>
            Create {typeForCopying} retailer (adjusted)
          </MenuItem>
        </>
      )}
      {canBeDeleted && (
        <MenuItem
          danger
          disabled={disableDeleting}
          onClick={checkFilter(onDeleteRetailer)}
        >
          <Tooltip title={disableDeleting && HAS_RELATIONSHIPS}>
            Delete retailer
          </Tooltip>
        </MenuItem>
      )}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button icon={<MoreOutlined />} shape="circle" size="small" />
    </Dropdown>
  )
}

MoreActions.propTypes = {
  retailer: retailerPropType.isRequired,
  checkFilter: PropTypes.func.isRequired,
}

export default MoreActions
