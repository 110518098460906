import React from 'react'
import PropTypes from 'prop-types'
import Search from '../Search'
import { Wrapper } from './SearchInTableBar.styles'

const SearchInTableBar = ({
  onSubmit,
  value,
  children,
  placeholder,
  isDisabled,
}) => {
  return (
    <Wrapper>
      <Search
        placeholder={placeholder}
        onSubmit={onSubmit}
        value={value}
        isDisabled={isDisabled}
      />
      {children}
    </Wrapper>
  )
}

SearchInTableBar.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  children: PropTypes.node,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
}

SearchInTableBar.defaultProps = {
  value: null,
  children: null,
  placeholder: null,
  isDisabled: false,
}

export default SearchInTableBar
