import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { createMobileNotification } from 'actions/views/mobileNotifications'
import useAction from 'utils/useAction'
import { Row, Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/Input'
import { FIELD_NAMES } from 'const/forms'
import { isValidEmailNotRequired, requiredNested } from 'helpers/validators'
import { selectors as mobileAppSelectors } from 'domains/mobileApp'
import { StyledButton, StyledForm, Note } from './MobileNotification.styles'
import Select from 'components/Select/Select'
import Checkbox from 'components/Checkbox/Checkbox'
import InputNumber from 'components/Input/InputNumber'
import {
  NOTIFICATION_TYPE_KEYS,
  NOTIFICATION_TYPE_OPTIONS,
} from 'const/mobileApp'

const initialValues = {
  [FIELD_NAMES.type]: NOTIFICATION_TYPE_OPTIONS[0].value,
}

const MobileNotification = ({ closeModal }) => {
  const isSavingActive = useSelector(mobileAppSelectors.getIsSavingActive)
  const createMobileNotificationFunc = useAction(createMobileNotification)

  const onSubmit = (values) => {
    createMobileNotificationFunc({ values, callback: closeModal })
  }

  return (
    <Form
      destroyOnUnregister
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, values }) => (
        <Spin spinning={isSavingActive} size="large">
          <StyledForm onSubmit={handleSubmit}>
            <Field
              required
              validate={requiredNested}
              name={FIELD_NAMES.type}
              options={NOTIFICATION_TYPE_OPTIONS}
              label="Notification types"
              component={Select}
            />
            {values[FIELD_NAMES.type] === NOTIFICATION_TYPE_KEYS.autoMuted && (
              <Row justify="center">
                <Note>
                  * Please note that archived notifications won't be displayed
                  in the table.
                </Note>
              </Row>
            )}
            <Field
              name={FIELD_NAMES.tagId}
              label="Tag ID"
              placeholder="Tag ID"
              component={InputNumber}
            />
            <Field
              validate={isValidEmailNotRequired}
              name={FIELD_NAMES.userEmail}
              label="User email"
              placeholder="User email"
              component={Input}
            />
            <Field
              name={FIELD_NAMES.immediate}
              label="Immediate notification"
              component={Checkbox}
            />
            <StyledButton
              type="primary"
              disabled={pristine}
              onClick={handleSubmit}
            >
              Send notification
            </StyledButton>
          </StyledForm>
        </Spin>
      )}
    />
  )
}

MobileNotification.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default MobileNotification
