import styled from 'styled-components'
import { Col } from 'antd'

export const Root = styled(Col)`
  display: flex;
  flex-direction: column;
  width: 50%;
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;

  &:last-child {
    border-right: none;
  }
`

export const Title = styled.span`
  font-weight: bold;
`

export const Value = styled.span``
