import React, { useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { message } from 'antd'
import useAction from 'utils/useAction'
import { actions as adPackageActions } from 'domains/adPackages'
import { FIELD_NAMES } from 'const/forms'
import { StyledInput } from './PriorityInput.styles'

const PriorityInput = ({ defaultValue, packageId }) => {
  const [value, setValue] = useState(defaultValue || '')
  const updateAdPackage = useAction(adPackageActions.updateAdPackageRequest)

  const updatePackage = async (value) => {
    try {
      if (value !== defaultValue) {
        const result = await updateAdPackage({
          id: packageId,
          [FIELD_NAMES.priority]: value,
        })
        if (result?.errors) {
          message.error(get(result.errors, '0.detail'))
        } else {
          message.success('Ad Priority has been updated')
        }
      }
    } catch (error) {
      message.error(error)
    }
  }

  const debouncedSave = useRef(
    debounce((nextValue) => updatePackage(nextValue), 1000)
  ).current

  const handleChange = (value) => {
    setValue(value)
    debouncedSave(value)
  }

  return (
    <StyledInput
      defaultValue={defaultValue}
      onChange={handleChange}
      value={value}
      min={0}
    />
  )
}

export default PriorityInput
