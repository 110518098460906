import styled from 'styled-components'
import { Button } from 'antd'

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
`

export const StyledButton = styled(Button)`
  margin-left: 10px;
`
