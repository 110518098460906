import React from 'react'
import { useSelector } from 'react-redux'
import { message, Checkbox as AntCheckbox } from 'antd'
import useAction from 'utils/useAction'
import {
  selectors as adPackageSelectors,
  actions as adPackageActions,
} from 'domains/adPackages'

const StatusCheckbox = ({ id }) => {
  const adPackages = useSelector(adPackageSelectors.adPackagesSelector)
  const adPackage = adPackages.find((adPackage) => adPackage.id === id)
  const updateAdPackage = useAction(adPackageActions.updateAdPackageRequest)

  return (
    <AntCheckbox
      checked={adPackage.status === 'active'}
      onChange={async (e) => {
        let status = e.target.checked ? 'active' : 'closed'

        await updateAdPackage({ id, status: status })

        let packageMessage = `Ad Package Status set to ${status}`

        if (status === 'active') {
          message.success(packageMessage)
        } else {
          message.error(packageMessage)
        }
      }}
    />
  )
}

export default StatusCheckbox
