import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { formatCampaigns } from 'domains/campaigns/formatters'
import { ENTITY_NAMES } from 'const/api'
import { areFiltersAndSorterEmpty } from 'helpers/filters'

export const campaignsState = ({ campaigns }) => {
  return campaigns
}
export const getIsLoaded = createSelector(
  campaignsState,
  ({ isLoaded }) => isLoaded
)

export const getIsSavingActive = createSelector(
  campaignsState,
  ({ isSavingActive }) => isSavingActive
)

export const isLoadingOrSavingActive = createSelector(
  [getIsLoaded, getIsSavingActive],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getEntities = createSelector(
  campaignsState,
  ({ entities }) => entities
)

export const getCampaigns = createSelector(getEntities, (entities) =>
  formatCampaigns(entities?.[ENTITY_NAMES.campaignDeals])
)

export const getCampaignById = createSelector(getCampaigns, (campaign) =>
  memoize((id) => (id ? campaign[id] : null))
)

export const getSearchString = createSelector(
  campaignsState,
  ({ search }) => search
)

export const getPage = createSelector(campaignsState, ({ page }) => page)

export const getFilters = createSelector(campaignsState, ({ filters }) => {
  return filters
})

export const getSorter = createSelector(campaignsState, ({ sorter }) => {
  return sorter
})

export const getIsDataFilteredOrSorted = createSelector(
  [getFilters, getSorter],
  areFiltersAndSorterEmpty
)

export const getPageCount = createSelector(
  campaignsState,
  ({ pageCount }) => pageCount
)

export const getSortedIds = createSelector(
  campaignsState,
  ({ sortedIds }) => sortedIds
)

export const getSortedCampaigns = createSelector(
  [getCampaigns, getSortedIds],
  (campaigns, ids) => ids?.map((id) => campaigns[id])
)
