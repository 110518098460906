import { ENTITY_NAMES } from 'const/api'
import { FIELD_NAMES } from 'const/forms'
import { mapFilters, mapSorters } from 'helpers/jsonAPI'

export const formatGiveaway = (giveaway) => {
  return {
    [FIELD_NAMES.id]: giveaway[FIELD_NAMES.id],
    [FIELD_NAMES.name]: giveaway.attributes[FIELD_NAMES.name],
    [FIELD_NAMES.retailers]: Object.entries(
      giveaway.attributes[FIELD_NAMES.retailers]
    ).reduce((acc, [key, item]) => {
      acc.push({
        label: item,
        value: key,
        key,
      })

      return acc
    }, []),
    [FIELD_NAMES.startDate]: giveaway.attributes[FIELD_NAMES.startDate],
    [FIELD_NAMES.endDate]: giveaway.attributes[FIELD_NAMES.endDate],
    [FIELD_NAMES.header]: giveaway.attributes[FIELD_NAMES.header],
    [FIELD_NAMES.body]: giveaway.attributes[FIELD_NAMES.body],
    [FIELD_NAMES.visible]: !!giveaway.attributes[FIELD_NAMES.visible],
    [FIELD_NAMES.productPreview]:
      giveaway.attributes[FIELD_NAMES.productPreview],
    [FIELD_NAMES.cta]: giveaway.attributes[FIELD_NAMES.cta],
    key: giveaway[FIELD_NAMES.id],
  }
}

export const formatGiveaways = (giveaways = {}) =>
  Object.values(giveaways).reduce(
    (result, giveaway) => ({
      ...result,
      [giveaway[FIELD_NAMES.id]]: formatGiveaway(giveaway),
    }),
    {}
  ) || {}

export const formatPayloadForFetchRequest = ({
  page,
  filters,
  sorter,
  search,
} = {}) => ({
  page: page || 1,
  filters: mapFilters(filters),
  sorter: mapSorters(sorter),
  search,
})

export const formatGiveawayForRequests = (
  {
    [FIELD_NAMES.id]: id,
    [FIELD_NAMES.productPreview]: productPreview,
    [FIELD_NAMES.retailers]: retailers,
    key,
    ...values
  },
  { isImageUpdate = false } = {}
) => {
  const attributes = isImageUpdate
    ? { [FIELD_NAMES.productPreview]: productPreview }
    : { ...values, retailers: retailers?.map((i) => i.value).join(',') ?? '' }

  return {
    ...(id && { id }),
    type: ENTITY_NAMES.giveaways,
    attributes,
  }
}
