import React from 'react'
import { Wrapper, StyledCollapse, Title } from './Payments.styles'
import PaymentsUpload from './PaymentsUpload'
import PaymentsUploads from './PaymentsUploads'
import PaymentsList from './PaymentsList'

export const KEYS = {
  PAYMENTS: 'payments',
  UPLOADS: 'uploads',
}

const DEFAULT_ACTIVE_KEYS = [KEYS.PAYMENTS, KEYS.UPLOADS]

const Payments = () => {
  return (
    <Wrapper>
      <PaymentsUpload />
      <StyledCollapse defaultActiveKey={DEFAULT_ACTIVE_KEYS}>
        <StyledCollapse.Panel
          header={<Title>Payments</Title>}
          key={KEYS.PAYMENTS}
        >
          <PaymentsList />
        </StyledCollapse.Panel>
        <StyledCollapse.Panel
          header={<Title>Uploads</Title>}
          key={KEYS.UPLOADS}
        >
          <PaymentsUploads />
        </StyledCollapse.Panel>
      </StyledCollapse>
    </Wrapper>
  )
}

export default Payments
