import React from 'react'
import { Col, Layout, Row, Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import useAction from 'utils/useAction'
import { FIELD_NAMES } from 'const/forms'
import { validUserEmail } from 'helpers/validators'
import { selectors as userSelectors } from 'domains/user'
import TextInput from 'components/TextInput'
import Title from 'components/Title/Title'
import { Buttons, StyledButton } from './MergeUser.styles'
import * as actions from 'actions/views/users'

const MergeUser = ({ id, closeModal }) => {
  const isSavingActive = useSelector(userSelectors.getIsSavingActive)
  const mergeUserFunc = useAction(actions.mergeUser)

  const onSubmit = (values) => {
    mergeUserFunc({ values, id, callback: closeModal })
  }
  return (
    <Layout.Content>
      <Form
        onSubmit={onSubmit}
        render={({ hasValidationErrors, handleSubmit }) => {
          return (
            <Spin spinning={isSavingActive} size="large">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Title>From email</Title>
                  <Field
                    validate={validUserEmail}
                    component={TextInput}
                    name={FIELD_NAMES.from}
                  />
                </Col>
              </Row>
              <Buttons>
                <StyledButton size="default" onClick={closeModal}>
                  Cancel
                </StyledButton>

                <StyledButton
                  disabled={hasValidationErrors}
                  size="default"
                  type="primary"
                  onClick={handleSubmit}
                >
                  Merge
                </StyledButton>
              </Buttons>
            </Spin>
          )
        }}
      />
    </Layout.Content>
  )
}

export default MergeUser
