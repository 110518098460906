import React from 'react'
import PropTypes from 'prop-types'
import { StyledButton, Text } from './OverrideModal.styles'
import { Modal } from 'antd'

const OverrideModal = ({ overrideAll, overrideEmpty, closeModal, show }) => {
  const handleOverride = (callback) => () => {
    callback()
    closeModal()
  }

  return (
    <Modal
      open={show}
      centered
      onCancel={handleOverride(overrideEmpty)}
      footer={[
        <StyledButton key="empty" onClick={handleOverride(overrideEmpty)}>
          Cancel
        </StyledButton>,
        <StyledButton
          key="all"
          onClick={handleOverride(overrideAll)}
          type="primary"
        >
          Override All
        </StyledButton>,
      ]}
    >
      <Text>Do you want to override all manually uploaded images?</Text>
    </Modal>
  )
}

OverrideModal.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  show: PropTypes.bool.isRequired,
  overrideAll: PropTypes.func.isRequired,
  overrideEmpty: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default OverrideModal
