import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import * as actions from './actions'

function* fetchAllLogsWorker({ payload }) {
  try {
    const { data } = yield call(api.getAllLogs, payload)
    if (data) {
      yield put(
        actions.fetchAllLogsSuccess({
          ...payload,
          page: payload.page,
          pageCount: data.meta.page_count,
          data: data,
        })
      )
    } else {
      yield put(actions.fetchAllLogsFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.fetchAllLogsFailure())
  }
}

export default function* () {
  yield all([takeEvery(actions.fetchAllLogs, fetchAllLogsWorker)])
}
