import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  .odd {
    background: #eeeef5;
    font-size: 12px;
  }

  .even {
    background: #f5f5f5;
    font-size: 12px;
  }

  .ant-divider-horizontal {
    margin: 15px 0;
  }

  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
    & > span {
      display: inline-block;
      line-height: 30px;
      height: 30px;
    }
  }

  .ant-menu-dark.ant-menu-horizontal {
    flex: 1;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }
  .crop-modal {
    .ant-modal-body {
      padding-bottom: 16px;
    }
    .crop-container {
      position: relative;
      width: 100%;
      height: 40vh;
      margin-bottom: 16px;
    }
    .crop-control {
      display: flex;
      align-items: center;
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34px;
        height: 34px;
        padding: 0;
        font-style: normal;
        background: transparent;
        border: 0;
        outline: 0;
        cursor: pointer;
        &[disabled] {
          cursor: default;
        }
      }
      &.zoom button {
        font-size: 18px;
      }
      &.rotate button {
        font-size: 16px;
        &:first-of-type {
          transform: rotate(-20deg);
        }
        &:last-of-type {
          transform: rotate(20deg);
        }
      }
      .ant-slider {
        flex: 1;
        margin: 0 8px;
      }
    }
  }

`
