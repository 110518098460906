import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  page_count: 0,
  currentPage: 1,
  loading: false,
  users: {},
  searchString: '',
  sort: '',
}

const userBanSlice = createSlice({
  name: 'userBan',
  initialState,
  reducers: {
    overwriteBans: (state, action) => {
      state.users = parceJson(action.payload)
      state.data = action.payload
      state.page_count = action.payload.meta.page_count
    },
    setLoading: (state, action) => {
      state.loading = action.payload ? true : false
    },
    setCurrentPage: (state, action) => {
      if (typeof action.payload === 'number') state.currentPage = action.payload
      else if (
        !(action.payload === '-' && state.currentPage === 1) ||
        !(action.payload === '+' && state.currentPage === state.page_count)
      )
        state.currentPage =
          action.payload === '-' ? state.currentPage - 1 : state.currentPage + 1
    },
    setSort: (state, action) => {
      state.sort = action.payload
    },
    addNewBan: (state, action) => {
      const { data, included } = action.payload
      const newUsers = parceJson({ data: [data], included })
      state.users[data.relationships.user.data.id] =
        newUsers[data.relationships.user.data.id]
    },
    deactivateBan: (state, action) => {
      state.users[action.payload].user_cashback_bans.attributes.active = false
    },
    setSearchString: (state, action) => {
      state.searchString = action.payload
    },
  },
})

// receiving data from the store:
export const selectUserBan = (store) => store.userBan

// actions:
export const {
  overwriteBans,
  setLoading,
  setCurrentPage,
  setSort,
  addNewBan,
  deactivateBan,
  setSearchString,
} = userBanSlice.actions

// reducer:
export default userBanSlice.reducer

function parceJson(res) {
  const data = res.included || []
  const users = {}

  data.forEach((item) => {
    if (item.type === 'users') {
      users[item.id] = {}
      users[item.id].attributes = item.attributes
      users[item.id].meta = item.meta
      users[item.id].user_id = item.id
      if (item.relationships.user_cashback_bans.data.length) {
        users[item.id].user_cashback_bans = user_cashback_bansFun(
          res,
          item.relationships.user_cashback_bans.data[0].id
        )
      }
    }
  })
  return users
}

function user_cashback_bansFun(res, id) {
  const curIndex = res.data.findIndex((item) => item.id === id)
  return {
    attributes: res.data[curIndex].attributes,
    meta: res.data[curIndex].meta,
    id: res.data[curIndex].id,
    amdin: get_admin(
      res.included,
      res.data[curIndex].relationships.admin.data.id
    ),
  }
}

function get_admin(data, id) {
  const curIndex = data.findIndex(
    (item) => item.id === id && item.type === 'admins'
  )
  return {
    id: data[curIndex].id,
    attributes: data[curIndex].attributes,
    meta: data[curIndex].meta,
  }
}
