import React from 'react'
import PropTypes from 'prop-types'
import RegionalAvatar from '../RegionalAvatar/RegionalAvatar'
import { Wrapper, Name } from './RegionItem.styles'
import { getFlagUrl } from 'helpers/flags'

const RegionItem = ({ country }) => (
  <Wrapper>
    <RegionalAvatar
      countryCode={country.code}
      country={getFlagUrl(country.code)}
    />
    {country.name && <Name>{country.name}</Name>}
  </Wrapper>
)

RegionItem.propTypes = {
  country: PropTypes.shape({
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
}

export default RegionItem
