import { useCallback, useState } from 'react'
import isEqual from 'lodash/isEqual'
import identity from 'lodash/identity'

export default (value, modified, callback, valueFormatter = identity) => {
  const [prevValue, setPrevValue] = useState(value)
  const newValue = valueFormatter(value, prevValue)

  return useCallback(() => {
    if (modified && !isEqual(prevValue, newValue)) {
      callback(newValue)
      setPrevValue(newValue)
    }
  }, [callback, modified, newValue, prevValue])
}
