import { createSlice } from '@reduxjs/toolkit'
import { FIELD_NAMES } from 'const/forms'

const initialStateForCollections = {
  search: '',
  sortedIds: [],
  entities: {},
  filters: {},
  sorter: {},
  isLoaded: false,
  page: 1,
  pageCount: 1,
}

const initialState = {
  rules: initialStateForCollections,
  [FIELD_NAMES.ruleSlugs]: initialStateForCollections,
  isSavingActive: false,
}

const rulesSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    fetchRuleSlugsSuccess: (state, { payload }) => ({
      ...state,
      [FIELD_NAMES.ruleSlugs]: {
        ...state[FIELD_NAMES.ruleSlugs],
        isLoaded: true,
        page: payload.page,
        pageCount: payload.pageCount,
        entities: payload.ruleSlugs,
        sortedIds: payload.sortedIds,
      },
    }),
    fetchRuleSlugsFailure: (state) => state,
    resetRuleSlugsFiltersAndSorter: (state) => ({
      ...state,
      [FIELD_NAMES.ruleSlugs]: {
        ...state[FIELD_NAMES.ruleSlugs],
        page: initialStateForCollections.page,
        filters: initialStateForCollections.filters,
        sorter: initialStateForCollections.sorter,
      },
    }),
    saveRuleSlugsFiltersAndSorter: (state, { payload = {} }) => ({
      ...state,
      [FIELD_NAMES.ruleSlugs]: {
        ...state[FIELD_NAMES.ruleSlugs],
        search: payload.search,
        filters: payload.filters || initialStateForCollections.filters,
        sorter: payload.sorter || initialStateForCollections.sorter,
      },
    }),
    startSaving: (state) => ({
      ...state,
      isSavingActive: true,
    }),
    finishSaving: (state) => ({
      ...state,
      isSavingActive: false,
    }),
    clearData: () => initialState,
  },
})

export default rulesSlice
