import React from 'react'
import Modal from 'components/Modal/Modal'
import Table from './Table'
import FeatureForm from './FeatureForm'

const Features = () => (
  <Modal.Provider>
    <Table />
    <Modal title="Feature Edit" id="featureForm">
      <FeatureForm />
    </Modal>
    <Modal title="Feature Create" id="featureFormCreate">
      <FeatureForm action="create" />
    </Modal>
  </Modal.Provider>
)

export default Features
