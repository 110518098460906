import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  fetchFeaturesSuccess,
  fetchFeaturesByPlatformSuccess,
  createFeatureSuccess,
  updateFeatureSuccess,
  deleteFeatureSuccess,
  createFeatureConfigurationOptionSuccess,
  deleteFeatureConfigurationOptionSuccess,
  clearData,
} = reducer.actions

export const fetchFeaturesRequest = createAction('fetchFeatures:request')
export const fetchFeatureSuccess = createAction(
  'fetchFeatures:success',
  (features) => ({ payload: { features } })
)
export const fetchFeaturesFailure = createAction(
  'fetchFeatures:failure',
  (error) => ({ error })
)

export const fetchFeaturesByPlatformRequest = createAction(
  'fetchFeaturesByPlatformRequest:request'
)
export const fetchFeaturesByPlatformsFailure = createAction(
  'fetchFeaturesByPlatforms:failure',
  (error) => ({ error })
)

export const updateFeatureRequest = createAction('updateFeature:request')
export const updateFeatureFailure = createAction(
  'updateFeature:failure',
  (error) => ({ error })
)

export const deleteFeatureRequest = createAction('deleteFeature:request')
export const deleteFeatureFailure = createAction(
  'deleteFeature:failure',
  (error) => ({ error })
)

export const createFeatureRequest = createAction(
  'createFeature:request',
  (feature) => ({ payload: { feature } })
)
export const createFeatureError = createAction(
  'createFeature:error',
  (error) => ({ error })
)

export const createFeatureConfigurationOptionRequest = createAction(
  'createFeatureConfigurationOption:request',
  (options, id) => ({ payload: { options, featureId: id } })
)
export const createFeatureConfigurationOptionError = createAction(
  'createFeatureConfigurationOption:error',
  (error) => ({ error })
)

export const deleteFeatureConfigurationOptionRequest = createAction(
  'deleteFeatureConfigurationOption:request',
  (id, featureId) => ({ payload: { id, featureId } })
)

export const deleteFeatureConfigurationOptionError = createAction(
  'deleteFeatureConfigurationOption:error',
  (error) => ({ error })
)
