import React from 'react'
import { Layout } from 'antd'
import { useSelector } from 'react-redux'
import useAction from 'utils/useAction'
import { selectors as userSelectors } from 'domains/user'
import { Button } from 'antd'
import * as actions from 'actions/views/users'

const User = ({ id }) => {
  const userState = useSelector(userSelectors.userState)
  const resetUserPassword = useAction(actions.resetUserPassword)

  const onSubmit = () => {
    if (id) {
      resetUserPassword(id)
    }
  }

  return (
    <Layout.Content>
      <Button onClick={onSubmit} type="primary">
        Reset Now
      </Button>
      <p>{userState.user.password}</p>
    </Layout.Content>
  )
}

export default User
