import styled from 'styled-components'
import { Layout, Button, Table } from 'antd'

export const Wrapper = styled(Layout.Content)`
  padding: 24px;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const StyledForm = styled.form`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .field-wrapper {
    margin-top: 0px;
  }
`

export const StyledButton = styled(Button)`
  width: 300px;
`

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
`

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledActionButton = styled(Button)`
  &:not(:first-child) {
    margin-top: 10px;
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`

export const StyledTable = styled(Table)`
  .processed-row {
    background-color: #e7fbe6;

    .ant-table-cell-row-hover {
      background-color: #e7fbe6 !important;
    }
  }

  .declined-row {
    background-color: #fead95;

    .ant-table-cell-row-hover {
      background-color: #fead95 !important;
    }
  }
`
