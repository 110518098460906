import { createAction } from '@reduxjs/toolkit'

export const fetchCreditCardsByDomain = createAction(
  'creditCardsComparisonView/fetchCreditCardsByDomain'
)

export const fetchAllCreditCards = createAction(
  'creditCardsComparisonView/fetchAllCreditCards'
)

export const validateCreditCardProgram = createAction(
  'creditCardsComparisonView/validateCreditCardProgram'
)
