import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import { Actions, StyledButton } from '../Shops.styles'
import { formatDate } from 'helpers/dates'
import {
  OFFER_TYPE_FILTERS,
  COUPONS_SOURCE_FILTERS,
  EXCLUSIVE_STATUS_FILTERS,
} from 'const/coupons'
import { formatFieldValue } from 'helpers/formatters/fields'
import UrlComponent from 'components/UrlComponent'
import Status from 'components/Status'
import ShortText from 'components/ShortText'
import { Tooltip } from 'antd'

export default (filters, sorter, handleEditOffer, handleDeleteOffer) => [
  {
    title: 'Offer ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 110,
    fixed: 'left',
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Code',
    fixed: 'left',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.code],
    key: FIELD_NAMES.code,
    width: 150,
    align: 'center',
  },
  {
    title: 'Description',
    width: 250,
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.description],
    key: FIELD_NAMES.description,
    ellipsis: true,
    render: (description) => (
      <Tooltip title={formatFieldValue(description)}>
        {formatFieldValue(description)}
      </Tooltip>
    ),
  },
  {
    title: 'Rate',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.rate],
    key: FIELD_NAMES.rate,
    width: 80,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.rate && sorter.order,
  },
  {
    title: 'Domain',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.domain],
    key: FIELD_NAMES.domain,
    render: (domain) => (
      <Tooltip title={formatFieldValue(domain)}>
        {formatFieldValue(domain)}
      </Tooltip>
    ),
    width: 120,
    ellipsis: true,
    align: 'center',
  },
  {
    title: 'Priority',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.priority],
    key: FIELD_NAMES.priority,
    width: 100,
    align: 'center',
    render: formatFieldValue,
  },
  {
    title: 'Exclusive Priority',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.exclusivePriority],
    key: FIELD_NAMES.exclusivePriority,
    width: 100,
    align: 'center',
    render: formatFieldValue,
  },
  {
    title: 'URL',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.url],
    key: FIELD_NAMES.url,
    width: 100,
    align: 'center',
    render: (_, { url }) => <UrlComponent url={url} />,
  },
  {
    title: 'Started at',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.startedAt],
    key: FIELD_NAMES.startedAt,
    width: 120,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.startedAt && sorter.order,
    render: formatDate,
  },
  {
    title: 'Expired at',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.expiredAt],
    key: FIELD_NAMES.expiredAt,
    width: 120,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.expiredAt && sorter.order,
    render: formatDate,
  },
  {
    title: 'Number of uses',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.totalUseCount],
    key: FIELD_NAMES.totalUseCount,
    width: 130,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.totalUseCount && sorter.order,
    render: formatFieldValue,
  },
  {
    title: 'Successful uses',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.successUseCount],
    key: FIELD_NAMES.successUseCount,
    width: 130,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.successUseCount && sorter.order,
    render: formatFieldValue,
  },
  {
    title: 'Last successful use',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.lastSuccessUse],
    key: FIELD_NAMES.lastSuccessUse,
    width: 120,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.lastSuccessUse && sorter.order,
    render: formatDate,
  },
  {
    title: 'Created at',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.createdAt],
    key: FIELD_NAMES.createdAt,
    width: 120,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.createdAt && sorter.order,
    render: formatDate,
  },
  {
    title: 'Source',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.source],
    key: FIELD_NAMES.source,
    width: 100,
    align: 'center',
    render: formatFieldValue,
    filters: COUPONS_SOURCE_FILTERS,
    filteredValue: filters[FIELD_NAMES.source] || null,
  },
  {
    title: 'Sales Rep',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.salesRep],
    key: FIELD_NAMES.salesRep,
    width: 180,
    align: 'center',
    render: (value) => <ShortText text={value} lines={4} />,
  },
  {
    title: 'Comments',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.comments],
    key: FIELD_NAMES.comments,
    width: 180,
    align: 'center',
    render: (value) => <ShortText text={value} lines={4} />,
  },
  {
    title: 'Temporary priority until',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.temporaryPriorityUntil],
    key: FIELD_NAMES.temporaryPriorityUntil,
    width: 120,
    sorter: true,
    align: 'center',
    fixed: 'right',
    sortOrder:
      sorter.columnKey === FIELD_NAMES.temporaryPriorityUntil && sorter.order,
    render: formatDate,
  },
  {
    title: 'Karma exclusive',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.exclusive],
    key: FIELD_NAMES.exclusive,
    width: 100,
    render: (value) => <Status value={value} />,
    align: 'center',
    fixed: 'right',
    filters: EXCLUSIVE_STATUS_FILTERS,
    filteredValue: filters[FIELD_NAMES.exclusive] || null,
  },
  {
    title: 'Offer type',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.offerType],
    key: FIELD_NAMES.offerType,
    width: 100,
    render: formatFieldValue,
    filters: OFFER_TYPE_FILTERS,
    fixed: 'right',
    align: 'center',
    filteredValue: filters[FIELD_NAMES.offerType] || null,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    fixed: 'right',
    width: 120,
    render: (_, offer) => {
      const onEdit = () => handleEditOffer(offer[FIELD_NAMES.id])
      const onDelete = () => handleDeleteOffer(offer)
      return (
        <Actions>
          <StyledButton onClick={onEdit}>Edit</StyledButton>
          <StyledButton onClick={onDelete} type="danger">
            Delete
          </StyledButton>
        </Actions>
      )
    },
  },
]
