import styled from 'styled-components'
import { Button, Layout, Table } from 'antd'
import { CheckCircleTwoTone } from '@ant-design/icons'

export const Wrapper = styled(Layout.Content)`
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
`

export const StyledTable = styled(Table)`
  margin-top: 20px;
  overflow: auto;
`

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledButton = styled(Button)`
  &:not(:first-child) {
    margin-top: 10px;
  }
`

export const Buttons = styled.div`
  display: flex;
  & > button:first-child {
    margin-right: 10px;
  }
`
export const Breadcrumbs = styled.div`
  padding: 12px;
`
export const SearchWrapper = styled.div`
  padding: 12px;
`

export const TabsWrapper = styled.div`
  max-width: 100%;
  .ant-tabs-nav-wrap {
    padding: 12px;
  }
`
export const StoreLink = styled.a`
  font-size: 10px;
`
export const TextAd = styled(CheckCircleTwoTone)`
  font-size: 20px;
`

export const Info = styled.h2`
  padding: 30px;
  align-self: center;
`
