import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  entities: {},
  page: 1,
  pageCount: 1,
  isLoaded: false,
  isSavingActive: false,
  sorter: {},
  size: 20,
}

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    fetchTransactionsSuccess: (state, { payload }) => ({
      ...state,
      isLoaded: true,
      entities: { ...state.entities, transactions: payload.transactions },
      page: payload.page,
      pageCount: payload.pageCount,
    }),
    fetchTransactionsFailure: (state) => state,
    saveFiltersAndSorter: (state, { payload = {} }) => ({
      ...state,
      sorter: payload.sorter || initialState.sorter,
      size: payload.size || initialState.size,
    }),
    startSaving: (state) => ({
      ...state,
      isSavingActive: true,
    }),
    finishSaving: (state) => ({
      ...state,
      isSavingActive: false,
    }),
    clearData: () => initialState,
  },
})

export default transactionsSlice
