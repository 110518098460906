import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { formatCoupons } from 'domains/coupons/formatters'
import { ENTITY_NAMES } from 'const/api'
import { areFiltersAndSorterEmpty } from 'helpers/filters'

export const couponsState = ({ coupons }) => {
  return coupons
}

export const getIsLoaded = createSelector(
  couponsState,
  ({ isLoaded }) => isLoaded
)

export const getIsSavingActive = createSelector(
  couponsState,
  ({ isSavingActive }) => isSavingActive
)

export const isLoadingOrSavingActive = createSelector(
  [getIsLoaded, getIsSavingActive],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getEntities = createSelector(
  couponsState,
  ({ entities }) => entities
)

export const getCoupons = createSelector(getEntities, (entities) =>
  formatCoupons(entities[ENTITY_NAMES.coupons])
)

export const getCouponById = createSelector(getCoupons, (coupons) =>
  memoize((id) => (id ? coupons[id] : null))
)

export const getSearchString = createSelector(
  couponsState,
  ({ search }) => search
)

export const getPage = createSelector(couponsState, ({ page }) => page)

export const getFilters = createSelector(couponsState, ({ filters }) => filters)

export const getSorter = createSelector(couponsState, ({ sorter }) => sorter)

export const getIsDataFilteredOrSorted = createSelector(
  [getFilters, getSorter],
  areFiltersAndSorterEmpty
)

export const getPageCount = createSelector(
  couponsState,
  ({ pageCount }) => pageCount
)

export const getSortedIds = createSelector(
  couponsState,
  ({ sortedIds }) => sortedIds
)

export const getSortedCoupons = createSelector(
  [getCoupons, getSortedIds],
  (coupons, ids) => ids.map((id) => coupons[id])
)
