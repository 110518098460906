import React from 'react'
import { Input as AntInput } from 'antd'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'

const Input = ({
  input,
  meta,
  label,
  description,
  hint,
  required,
  ...rest
}) => (
  <FieldWrapper hint={hint} label={label} meta={meta} required={required}>
    <AntInput.Password
      invalid={meta.modified && meta.error}
      placeholder={description}
      onChange={input.onChange}
      {...input}
      {...rest}
    />
  </FieldWrapper>
)

export default Input
