import { createAction } from '@reduxjs/toolkit'

export const fetchPaymentTransactions = createAction(
  'paymentTransaction/fetchPaymentTransactions'
)
export const fetchTransactionDetails = createAction(
  'paymentTransaction/fetchTransactionDetails'
)

export const updatePaymentPackage = createAction(
  'paymentTransaction/updatePaymentPackage'
)
export const createPaymentPackage = createAction(
  'paymentTransaction/createPaymentPackage'
)

export const getRules = createAction('paymentTransaction/getRules')
