import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  startSaving,
  finishSaving,
  setCreditCards,
  clearData,
  setCreditCardProcessed,
} = reducer.actions

export const fetchCreditCardsByDomain = createAction(
  'creditCardsComparison/fetchCreditCardsByDomain'
)

export const fetchAllCreditCards = createAction(
  'creditCardsComparison/fetchAllCreditCards'
)

export const validateCreditCardProgram = createAction(
  'creditCardsComparison/validateCreditCardProgram'
)
