import { FIELD_NAMES } from 'const/forms'
import { mapFilters, mapSorters } from 'helpers/jsonAPI'
import { RELATIONSHIPS_NAMES } from 'const/api'
import pickBy from 'lodash/pickBy'
import moment from 'moment'
import { GUEST_EMAIL_REG_EXP } from 'const/users'

export const formatPaymentTransaction = (item) => ({
  ...item,
  [FIELD_NAMES.countdownDate]: moment(item[FIELD_NAMES.paymentDate]).add(
    90,
    'days'
  ),
})

export const formatUser = (user) => ({
  key: user[FIELD_NAMES.id],
  isGuest: GUEST_EMAIL_REG_EXP.test(user[FIELD_NAMES.email]),
  [FIELD_NAMES.id]: user[FIELD_NAMES.id],
  [FIELD_NAMES.totalBalance]: user[FIELD_NAMES.totalBalance],
  [FIELD_NAMES.totalCash]: user[FIELD_NAMES.totalCash],
  [FIELD_NAMES.registrationDate]: user[FIELD_NAMES.registrationDate],
  [FIELD_NAMES.paypalConnected]: user[FIELD_NAMES.paypalConnected],
  [FIELD_NAMES.email]: user[FIELD_NAMES.email],
  [FIELD_NAMES.phone]: user[FIELD_NAMES.phone],
  [FIELD_NAMES.name]: user[FIELD_NAMES.name],
  [FIELD_NAMES.paypalEmail]: user[FIELD_NAMES.paypalEmail],
  [FIELD_NAMES.paypalConfirmed]: user[FIELD_NAMES.paypalConfirmed],
  [FIELD_NAMES.bonuses]: user[FIELD_NAMES.bonuses],
  [FIELD_NAMES.bonusesAmount]: user[FIELD_NAMES.bonuses]?.length || 0,
  [FIELD_NAMES.otherExtensionInstalled]:
    user[FIELD_NAMES.otherExtensionInstalled],
  [FIELD_NAMES.influencer]: user[FIELD_NAMES.influencer],
  [FIELD_NAMES.totalBonuses]: user[FIELD_NAMES.totalBonuses],
  [FIELD_NAMES.pendingCash]: user[FIELD_NAMES.pendingCash],
  [FIELD_NAMES.blockedFromPurchase]: user[FIELD_NAMES.blockedFromPurchase],
})

export const formatUsers = (users = []) => users.map(formatUser)

export const formatPayloadForFetchRequest = ({
  page,
  filters,
  sorter,
  search,
} = {}) => ({
  page: page || 1,
  filters: mapFilters(filters),
  sorter: mapSorters(sorter),
  search,
})

export const formatUserForUpdate = (values) => ({
  id: values[FIELD_NAMES.id],
  data: {
    [FIELD_NAMES.id]: values[FIELD_NAMES.id],
    type: RELATIONSHIPS_NAMES.users,
    attributes: pickBy(values, (_, key) =>
      [FIELD_NAMES.name, FIELD_NAMES.email].includes(key)
    ),
  },
})

export const formatUserForMerge = ({ values, id }) => ({
  id,
  data: values,
})
