import styled from 'styled-components'
import { Button, Layout, Table } from 'antd'

export const Wrapper = styled(Layout.Content)`
  min-height: calc(100vh - 64px);
`

export const StyledTable = styled(Table)`
  .ant-table-title {
    padding: 0;
  }
`

export const DescriptionColumn = styled.div`
  & span {
    background-color: transparent !important;
  }
`

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledButton = styled(Button)`
  &:first-child {
    margin-bottom: 10px;
  }
`

export const Buttons = styled.div`
  display: flex;
  & > button:first-child {
    margin-right: 10px;
  }
`
