import styled, { css } from 'styled-components'
import { Upload as AntUpload } from 'antd'
import { UndoOutlined } from '@ant-design/icons'

export const EmptyUpload = styled.div`
  width: 104px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const invalid = (props) => {
  if (props.invalid) {
    return css`
      border-color: rgb(255, 0, 0, 1);
      box-shadow: none;

      &:hover,
      &:focus {
        border-color: rgb(255, 0, 0, 1);
        box-shadow: 0 0 0 2px rgb(255, 0, 0, 0.5);
      }
    `
  }
}

export const Text = styled.span`
  margin-top: 8px;
`

export const Upload = styled(AntUpload)`
  .ant-upload-select-picture-card {
    margin: 0;
    ${invalid}
  }

  .ant-upload-list-picture-card-container {
    margin: 0;
    min-width: 104px;
    min-height: 104px;
  }
`

export const ResetButton = styled(UndoOutlined)`
  position: absolute;
  right: -7px;
  top: -7px;
  font-size: 17px;
  background: white;
  border-radius: 50%;
`

export const Wrapper = styled.div`
  display: inline;
  position: relative;
`
