import styled, { css } from 'styled-components'
import { Select } from 'antd'

const invalid = (props) => {
  if (props.invalid) {
    return css`
      border-color: rgb(255, 0, 0, 1);
      box-shadow: none;

      &:hover,
      &:focus {
        border-color: rgb(255, 0, 0, 1);
        box-shadow: 0 0 0 2px rgb(255, 0, 0, 0.5);
      }
      & > div {
        border-color: rgb(255, 0, 0, 1);
        box-shadow: 0 0 0 2px rgb(255, 0, 0, 0.5);
      }
    `
  }
}

export const StyledSelect = styled(Select)`
  width: 100%;

  ${invalid}
`

export const Id = styled.span`
  min-width: 50px;
  margin-right: 10px;
`
