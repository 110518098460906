import styled from 'styled-components'
import { Button } from 'antd'

export const Text = styled.div`
  margin-bottom: 24px;
  font-size: 14px;
  white-space: pre-wrap;
`

export const StyledButton = styled(Button)`
  margin-left: 10px;
`
