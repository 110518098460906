import { all, call, put, takeEvery } from 'redux-saga/effects'

import * as api from './api'
import * as actions from './actions'
import { formatGroups, formatUpdatedGroups } from './formatters'
import { formatters } from '.'

function* fetchGroupsWorker() {
  try {
    yield put(actions.startLoading())
    const { data } = yield call(api.getAllGroups)
    if (data) {
      yield put(actions.overwriteGroups(formatGroups(data.data)))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(actions.finishLoading())
  }
}

function* fetchCategoriesWorker() {
  try {
    yield put(actions.startLoading())
    const { data } = yield call(api.getAllCategories)
    if (data) {
      yield put(actions.saveCategories(data.data))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(actions.finishLoading())
  }
}

function* createCategoryWorker({ payload }) {
  try {
    const { data } = yield call(api.createCategory, payload)
    if (data) {
      yield put(actions.createCategorySuccess(data.data))
    } else {
      yield put(actions.createCategoryFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createCategoryFailure())
  }
}

function* editCategoryWorker({ payload }) {
  try {
    const { data } = yield call(api.editCategory, payload)
    if (data) {
      yield put(actions.editCategorySuccess(data.data))
    } else {
      yield put(actions.editCategoryFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.editCategoryFailure())
  }
}

function* deleteCategoryWorker({ payload }) {
  try {
    const { data } = yield call(api.deleteCategory, payload)
    if (data) {
      yield put(actions.deleteCategorySuccess(payload))
    } else {
      yield put(actions.deleteCategoryFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteCategoryFailure())
  }
}

function* updateGroupPositionsWorker({ payload }) {
  try {
    const { data } = yield call(api.updateGroupPositions, payload)
    if (data) {
      yield put(actions.overwriteGroups(formatUpdatedGroups(data.data)))
    }
  } catch (error) {
    console.error(error)
  }
}

function* convertLinkWorker({ payload }) {
  try {
    const { data } = yield call(api.convertLink, payload)
    if (data) {
      yield put(
        actions.convertLinkSuccess({
          started: payload.link,
          ...data?.data,
        })
      )
    } else {
      yield put(actions.convertLinkFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.convertLinkFailure())
  }
}

function* fetchMarketingCopyWorker() {
  try {
    yield put(actions.startLoading())
    const { data } = yield call(api.getMarketingCopy)
    yield put(
      actions.fetchMarketingCopySuccess({
        marketingCopy: data,
      })
    )
  } catch (error) {
    console.error(error)
  } finally {
    yield put(actions.finishLoading())
  }
}

function* updateMarketingCopyWorker({ payload }) {
  try {
    const formattedPayloadForRequest =
      formatters.formatMarketingCopyToUpdate(payload)
    const { data } = yield call(
      api.updateMarketingCopy,
      formattedPayloadForRequest
    )
    if (data) {
      yield put(actions.updateMarketingCopySuccess(payload))
    } else {
      yield put(actions.updateMarketingCopyFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateMarketingCopyFailure())
  }
}

export default function* () {
  yield all([
    takeEvery(actions.fetchGroups, fetchGroupsWorker),
    takeEvery(actions.fetchCategories, fetchCategoriesWorker),
    takeEvery(actions.updateGroupPositions, updateGroupPositionsWorker),
    takeEvery(actions.convertLink, convertLinkWorker),
    takeEvery(actions.createCategory, createCategoryWorker),
    takeEvery(actions.deleteCategory, deleteCategoryWorker),
    takeEvery(actions.editCategory, editCategoryWorker),
    takeEvery(actions.fetchMarketingCopy, fetchMarketingCopyWorker),
    takeEvery(actions.updateMarketingCopy, updateMarketingCopyWorker),
  ])
}
