import React from 'react'
import { Col, Input, Layout, Row } from 'antd'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import useAction from 'utils/useAction'
import { FIELD_NAMES } from 'const/forms'
import { required } from 'helpers/validators'
import { openConfirmModal } from 'reducers/modals/actions'
import { lastModalData } from 'reducers/modals/selectors'
import { getUnavailableRegions } from 'domains/retailers/selectors'
import {
  createCleanRetailer,
  createDirtyRetailer,
} from 'domains/retailers/actions'
import Select from 'components/RegionSelect/Select'
import Title from 'components/Title/Title'
import { StyledModalActions } from './CreateRetailerModal.styles'
import { RETAILER_TYPE_TO_LABEL } from 'const/retailers'
import { ENTITY_NAMES } from 'const/api'

const initialValues = {
  [FIELD_NAMES.countryCode]: '',
}

const CreateRetailerModal = ({ closeModal }) => {
  const { retailer, clean } = useSelector(lastModalData)
  const unavailableRegions = useSelector(getUnavailableRegions)(
    retailer[FIELD_NAMES.id],
    FIELD_NAMES.retailers
  )

  const createCleanRetailerFunc = useAction(createCleanRetailer)
  const createDirtyRetailerFunc = useAction(createDirtyRetailer)
  const openConfirmModalFunc = useAction(openConfirmModal)

  const onSubmit = (data) => {
    data = { ...data, [FIELD_NAMES.sourceId]: retailer[FIELD_NAMES.id] }
    const type =
      retailer.type === ENTITY_NAMES.main
        ? ENTITY_NAMES.regional
        : retailer.type
    const config = clean
      ? {
          callback: () => {
            createCleanRetailerFunc({
              data,
              retailerType: type,
            })
            closeModal()
          },
          text: `Are you sure you want to create ${RETAILER_TYPE_TO_LABEL[type]} retailer (default)?`,
        }
      : {
          callback: () => {
            createDirtyRetailerFunc({
              data,
              retailerType: type,
            })
            closeModal()
          },
          text: `Are you sure you want to create ${RETAILER_TYPE_TO_LABEL[type]} retailer (adjusted)?`,
        }

    openConfirmModalFunc(config)
  }

  return (
    <Layout.Content>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ hasValidationErrors, handleSubmit }) => (
          <>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Title>Retailer ID</Title>
                <Input value={retailer[FIELD_NAMES.id]} disabled />
              </Col>
              <Col span={12}>
                <Title>Name</Title>
                <Input value={retailer[FIELD_NAMES.name]} disabled />
              </Col>
              <Col span={12}>
                <Field
                  excludedRegions={unavailableRegions}
                  component={Select}
                  name={FIELD_NAMES.countryCode}
                  validate={required}
                />
              </Col>
            </Row>

            <StyledModalActions
              isConfirmDisabled={hasValidationErrors}
              onCancel={closeModal}
              onConfirm={handleSubmit}
            />
          </>
        )}
      />
    </Layout.Content>
  )
}

export default CreateRetailerModal
