import styled from 'styled-components'
import { Layout, Table } from 'antd'

export const Wrapper = styled(Layout.Content)`
  min-height: calc(100vh - 64px);
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
`

export const StyledTable = styled(Table)`
  margin-bottom: 30px;

  .ant-table-title {
    padding: 0;
  }

  .ant-table-cell {
    cursor: pointer;
  }
`
