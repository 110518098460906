import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  fetchBonusesSuccess,
  fetchBonusesFailure,
  clearData,
  startSaving,
  finishSaving,
} = reducer.actions

export const fetchBonuses = createAction('domain/bonuses/fetchBonuses')

export const saveFiltersAndSorter = createAction('bonuses/saveFiltersAndSorter')

export const updateBonus = createAction('domain/bonuses/updateBonus')
export const updateBonusSuccess = createAction(
  'domain/bonuses/updateBonusSuccess'
)
export const updateBonusFailure = createAction(
  'domain/bonuses/updateBonusFailure'
)

export const createBonus = createAction('domain/bonuses/createBonus')
export const createBonusSuccess = createAction(
  'domain/bonuses/createBonusSuccess'
)
export const createBonusFailure = createAction(
  'domain/bonuses/createBonusFailure'
)

export const deleteBonus = createAction('domain/bonuses/deleteBonus')
export const deleteBonusSuccess = createAction(
  'domain/bonuses/deleteBonusSuccess'
)
export const deleteBonusFailure = createAction(
  'domain/bonuses/deleteBonusFailure'
)
