import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: {
    password: 'Not generated yet...',
    verificationCode: { valid: '', code: '' },
  },
  selectedEmail: null,
  search: '',
  engagementMetrics: {
    data: [],
    loading: false,
  },
  paymentTransactions: {
    data: [],
    page: 1,
    pageCount: 1,
    loading: false,
  },
  users: {
    search: '',
    data: [],
    page: 1,
    pageCount: 1,
    filters: {},
    sorter: {},
    isLoaded: false,
    isSavingActive: false,
  },
  bonuses: [],
  areBonusesLoaded: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => ({
      ...state,
      user: { ...state.user, ...payload },
    }),
    setLoading: (state, { payload: { key, value } }) => ({
      ...state,
      [key]: {
        ...state[key],
        loading: value,
      },
    }),
    startSaving: (state) => ({
      ...state,
      users: {
        ...state.users,
        isSavingActive: true,
      },
      areBonusesLoaded: false,
    }),
    finishSaving: (state) => ({
      ...state,
      users: {
        ...state.users,
        isSavingActive: false,
      },
    }),
    saveSelectedEmail: (state, { payload }) => ({
      ...state,
      selectedEmail: payload,
    }),
    fetchPaymentTransactions: (state, { payload }) => ({
      ...state,
      selectedEmail: payload.email,
      search: payload.search,
      paymentTransactions: initialState.paymentTransactions,
    }),
    fetchEngagementMetricsSuccess: (state, { payload }) => ({
      ...state,
      engagementMetrics: {
        ...state.engagementMetrics,
        ...payload,
        data: payload.data || initialState.engagementMetrics.data,
      },
    }),
    fetchEngagementMetricsFailure: (state) => ({
      ...state,
      engagementMetrics: initialState.engagementMetrics,
    }),
    savePaymentTransactions: (state, { payload }) => ({
      ...state,
      paymentTransactions: {
        ...payload,
        data: payload.data || initialState.paymentTransactions.data,
      },
    }),
    resetAllTransactions: (state) => ({
      ...state,
      paymentTransactions: initialState.paymentTransactions,
      engagementMetrics: initialState.engagementMetrics,
    }),
    resetSelectedEmail: (state) => ({
      ...state,
      selectedEmail: initialState.selectedEmail,
    }),
    resetSearch: (state) => ({
      ...state,
      search: initialState.search,
    }),
    fetchUsersSuccess: (state, { payload }) => ({
      ...state,
      users: {
        ...state.users,
        isLoaded: true,
        page: payload.page,
        pageCount: payload.pageCount,
        data: payload.users,
      },
    }),
    sendSMSVerificationSuccess: (state, { payload }) => ({
      ...state,
      user: { ...state.user, verificationCode: { ...payload } },
    }),
    sendSMSVerificationFailure: (state) => state,
    fetchUsersFailure: (state) => state,
    fetchBonusesForUserSuccess: (state, { payload }) => ({
      ...state,
      bonuses: [...payload.bonuses],
      areBonusesLoaded: true,
    }),
    fetchBonusesForUserFailure: (state) => state,
    saveFiltersAndSorter: (state, { payload = {} }) => ({
      ...state,
      users: {
        ...state.users,
        search: payload.search,
        filters: payload.filters || initialState.users.filters,
        sorter: payload.sorter || initialState.users.sorter,
      },
    }),
    resetFiltersAndSorter: (state) => ({
      ...state,
      users: {
        ...state.users,
        page: initialState.users.page,
        filters: initialState.users.filters,
        sorter: initialState.users.sorter,
      },
    }),
    clearData: () => initialState,
  },
})

export default userSlice
