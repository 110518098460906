import omitBy from 'lodash/omitBy'
import { FIELD_NAMES } from 'const/forms'

export const hasSetTheSameImages = (set) => {
  const omittedSet = omitBy(set, (data) => !data)
  let hasTheSameImages = false
  const data = {}

  Object.values(omittedSet).forEach((item) => {
    if (data.hasOwnProperty(item)) {
      hasTheSameImages = true
    } else {
      data[item] = item
    }
  })
  return hasTheSameImages
}

export const hasAllGenders = ({ set }) => {
  if (!set) return

  const genders = new Set()
  set.forEach((item) => {
    if (item && !item[FIELD_NAMES.hidden]) {
      genders.add(item[FIELD_NAMES.gender]?.value)
    }
  })

  return genders.has('any') || (genders.has('female') && genders.has('male'))
}

export const getErrorText = ({
  hasAllGenders,
  hasSet,
  hasValidationErrors,
  isEmptyChanges,
}) => {
  if (hasValidationErrors) return 'Fill in all required fields to save changes'
  if (isEmptyChanges) return 'There are no changes to save'
  if (!hasSet) return 'Please notice you will delete all sets'
  if (!hasAllGenders)
    return 'Please notice that after click you will have visible image sets not for all genders'
}

export const getErrorTextForSync = ({
  hasValidationErrors,
  hasChanges,
  isNotAvailableNow,
  hasSet,
  hasLockedSets,
  hasRegional,
}) => {
  if (isNotAvailableNow) return 'This action is not available now'
  if (isNotAvailableNow) return 'This action is not available now'
  if (hasChanges) return 'Save or restore all changes to perform this action'
  if (hasValidationErrors)
    return 'Fill in all required fields to perform this action'
  if (hasLockedSets) return 'This retailer has locked sets'
  if (!hasSet) return "This retailer doesn't have sets"
  if (!hasRegional) return "This retailer doesn't have regional retailers"
}
