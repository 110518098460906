import { createSelector } from '@reduxjs/toolkit'

export const creditCardsComparisonState = ({ creditCardsComparison }) =>
  creditCardsComparison

export const getIsLoaded = createSelector(
  creditCardsComparisonState,
  ({ isLoaded }) => isLoaded
)

export const getIsSavingActive = createSelector(
  creditCardsComparisonState,
  ({ isSavingActive }) => isSavingActive
)

export const getIsLoadingOrSavingActive = createSelector(
  [getIsLoaded, getIsSavingActive],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getCreditCards = createSelector(
  creditCardsComparisonState,
  ({ creditCards }) => creditCards
)

export const getCurrentWebsite = createSelector(
  creditCardsComparisonState,
  ({ currentWebsite }) => currentWebsite
)
