import styled from 'styled-components'
import { Button, Layout, Table } from 'antd'

export const Wrapper = styled(Layout.Content)`
  margin-top: 10px;
`

export const StyledTable = styled(Table)`
  .ant-table-title {
    padding: 0;
  }
`

export const StyledButton = styled(Button)`
  &:last-child {
    margin: 10px 10px 0;
  }
`

export const Buttons = styled.div`
  display: flex;
  padding-bottom: 10px;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #ffffff;
`
