import { createSelector } from '@reduxjs/toolkit'
import { diff } from 'deep-object-diff'
import set from 'lodash/set'
import first from 'lodash/first'
import last from 'lodash/last'
import memoize from 'lodash/memoize'
import isEmpty from 'lodash/isEmpty'
import { formatChanges, formatFieldName } from 'helpers/formatters/forms'
import { mergeChanges, getDirtyFieldsFromChanges } from 'helpers/retailers'
import {
  FORM_NAME_TO_FORM_FIELDS,
  FORM_NAMES,
  getGroupFieldName,
} from 'const/forms'
import { selectors as generalSelectors } from 'domains/general'
import {
  getAllRetailersData,
  getChangedRetailers,
} from 'domains/retailers/selectors'

const formsState = (store) => store.forms

export const getFormSavedValues = createSelector(
  formsState,
  ({ savedValues }) => savedValues
)

export const getResetFormHandler = createSelector(
  formsState,
  ({ onResetForm }) => onResetForm
)

export const getChangeFieldHandler = createSelector(
  formsState,
  ({ onChangeFieldState }) => onChangeFieldState
)

export const formChanges = createSelector(
  [formsState, getChangedRetailers],
  (forms, retailers) =>
    memoize((formName) => {
      const state = forms[formName]

      return mergeChanges(
        retailers,
        state.values,
        diff(state.initialValues, state.values)
      )
    })
)

export const getChangeFormFieldHandler = createSelector(formsState, (forms) =>
  memoize((formName) => {
    const state = forms[formName]

    return state?.onChangeFieldState
  })
)

export const formValues = createSelector(formsState, (forms) =>
  memoize((formName) => {
    const state = forms[formName]

    return state?.values
  })
)

export const formDirtyFields = createSelector(formsState, (forms) =>
  memoize((formName) => {
    const state = forms[formName]

    return state?.dirtyFields
  })
)

export const formattedChanges = createSelector(
  [
    formsState,
    generalSelectors.getGroups,
    generalSelectors.categories,
    getAllRetailersData,
    getChangedRetailers,
  ],
  (forms, groups, categories, retailers, changedRetailers) =>
    memoize((formName) => {
      const state = forms[formName]
      const changes = mergeChanges(
        changedRetailers,
        state.values,
        diff(state.initialValues, state.values)
      )
      return formatChanges(
        changes,
        formName === FORM_NAMES.EDITORIAL_CHANGES
          ? [
              ...FORM_NAME_TO_FORM_FIELDS[formName],
              ...Object.keys(groups).map((groupId) =>
                getGroupFieldName(groupId)
              ),
            ]
          : FORM_NAME_TO_FORM_FIELDS[formName],
        groups,
        categories,
        retailers
      )
    })
)

export const initialValuesForSaveForm = createSelector(
  [
    formsState,
    generalSelectors.getGroups,
    generalSelectors.categories,
    getAllRetailersData,
    getChangedRetailers,
  ],
  (forms, groups, categories, retailers, changedRetailers) =>
    memoize((formName) => {
      const state = forms[formName]
      const changes = mergeChanges(
        changedRetailers,
        state.values,
        diff(state.initialValues, state.values)
      )

      return Object.entries(getDirtyFieldsFromChanges(changes)).reduce(
        (result, field) => {
          const fieldName = formatFieldName(first(field))
          return set(result, fieldName, last(field))
        },
        {}
      )
    })
)

export const isFormChangesEmpty = createSelector(
  [formsState, getChangedRetailers],
  (forms, changedRetailers) =>
    memoize((formName) => {
      const state = forms[formName]
      return (
        !state ||
        isEmpty(
          mergeChanges(
            changedRetailers,
            state.values,
            diff(state.initialValues, state.values)
          )
        )
      )
    })
)

export const isChangesEmpty = createSelector(formsState, (forms) =>
  memoize((formName) => {
    const state = forms[formName]
    return !state || isEmpty(diff(state.initialValues, state.values))
  })
)
