import styled from 'styled-components'
import { InputNumber, Select, Table } from 'antd'

export const StyledTable = styled(Table)`
  .ant-table-title {
    padding: 0;
  }
`

export const StyledSelect = styled(Select)`
  width: 40%;
  justify-content: space-between;
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 1;

  .ant-select-arrow {
    right: 20px;
  }
`

export const StyledButtonStack = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const StyledInputNumber = styled(InputNumber)`
  margin: 0 10px;
  width: 120px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
`
