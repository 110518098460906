import { apiInstance, apiInstance3 } from 'utils/request'
import { message } from 'antd'
import { BO_API_URL } from 'const/env'
import { RELATIONSHIPS_NAMES } from 'const/api'
import { FIELD_NAMES } from 'const/forms'
import { DURATION } from 'const/duration'
import parseError from 'helpers/parseServerError'
import { javaWithCookieInstance } from 'utils/requestJAVA'

export const getPaymentTransactions = ({ email, page = 1, search, sorter }) =>
  apiInstance
    .get(`${BO_API_URL}/customer_service/transactions`, {
      params: {
        email,
        page,
        search: search ? encodeURIComponent(search) : null,
        sort: sorter || FIELD_NAMES.paymentDate,
      },
    })
    .catch((error) => {
      parseError(error, 'Failed to load user payment transaction!')
      console.error(error)
    })

export const getEngagementMetrics = ({ email, page = 1 }) =>
  apiInstance
    .get(`${BO_API_URL}/customer_service/engagement_metrics`, {
      params: {
        email,
        page,
      },
    })
    .catch((error) => {
      parseError(error, 'Failed to load user engagement metrics!')
      console.error(error)
    })

export const deleteUser = (id) =>
  apiInstance
    .delete(`${BO_API_URL}/${RELATIONSHIPS_NAMES.users}/${id}`)
    .catch((error) => {
      parseError(error, 'Failed to delete user!')
      console.error(error)
    })

export const sendPayPalEmailToUser = (id) =>
  apiInstance
    .post(
      `${BO_API_URL}/${RELATIONSHIPS_NAMES.users}/${id}/send_verify_paypal_email`
    )
    .then(() => {
      message.success(
        'PayPal verification email has been successfully sent',
        DURATION.show
      )
      return { data: 'OK' }
    })
    .catch((error) => {
      parseError(error, 'Failed to send PayPal verification email!')
      console.error(error)
    })

export const confirmPayPal = (id) =>
  apiInstance
    .post(
      `${BO_API_URL}/${RELATIONSHIPS_NAMES.users}/${id}/confirm_paypal_account`
    )
    .then(() => {
      message.success('PayPal was confirmed successfully', DURATION.show)
      return { data: 'OK' }
    })
    .catch((error) => {
      parseError(error, 'Failed confirm PayPal')
      console.error(error)
    })

export const getUsers = ({ filters, search, sorter, page = 1 }) =>
  apiInstance
    .get(`${BO_API_URL}/${RELATIONSHIPS_NAMES.users}`, {
      params: {
        page,
        filter: {
          search: search ? encodeURIComponent(search) : null,
          ...filters,
        },
        sort: sorter || FIELD_NAMES.id,
      },
    })
    .catch((error) => {
      parseError(error, 'Failed to load users!')
      console.error(error)
    })

export const updateUser = ({ id, data }) =>
  apiInstance
    .patch(`/${RELATIONSHIPS_NAMES.users}/${id}`, {
      data,
    })
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "User hasn't been updated")
    })

export const resetUserPassword = (id) =>
  apiInstance3
    .post(
      `/${RELATIONSHIPS_NAMES.backoffice}/${RELATIONSHIPS_NAMES.users}/${id}/reset_password`,
      { id }
    )
    .then((data) => data.data)
    .catch((error) => {
      parseError(error, "Password hasn't been updated")
    })

export const unblockUserPayment = (id) => {
  apiInstance
    .post(`${BO_API_URL}/${RELATIONSHIPS_NAMES.users}/${id}/unblock_mfa`)
    .catch((error) => {
      parseError(error, 'Failed to unblock user payment!')
      console.error(error)
    })
}

export const mergeUser = ({ id, data }) =>
  apiInstance
    .post(`/${RELATIONSHIPS_NAMES.users}/${id}/merge_accounts`, data)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Accounts hasn't been merged.")
    })

export const sendSMSVerification = (id) =>
  javaWithCookieInstance
    .post(`/admin/v1/${RELATIONSHIPS_NAMES.user}/${id}/sms-code`)
    .then((data) => data.data)
    .catch(
      (error) =>
        error?.response?.data ||
        parseError(error, "Couldn't send verification code.")
    )

export const unsubscribeUser = (id) =>
  apiInstance
    .post(
      `${BO_API_URL}/${RELATIONSHIPS_NAMES.users}/${id}/unregister_from_notifications`
    )
    .then(() => {
      message.success(
        'User was successfully unsubscribed from all alerts',
        DURATION.show
      )
      return { data: 'OK' }
    })
    .catch((error) => {
      parseError(error, 'Failed to unsubscribe user from all alerts')
      console.error(error)
    })

export const fetchBonusesForUser = (id) =>
  apiInstance
    .get(`${BO_API_URL}/${RELATIONSHIPS_NAMES.users}/${id}/all_bonuses`)
    .catch((error) => {
      parseError(error, 'Failed to load bonuses for user')
      console.error(error)
    })
