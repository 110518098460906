import React, { useState, useEffect } from 'react'
import { FIELD_NAMES } from 'const/forms'
import { AddPlus, FieldName, FlexWrapper } from './Comments.styles'
import { Field } from 'react-final-form'
import { requiredContact } from 'helpers/validators'
import { FieldArray } from 'react-final-form-arrays'
import ContactsField from 'components/Contacts'

const Contacts = () => {
  const [hiddenFields, setHiddenFields] = useState([])
  useEffect(() => {
    return () => setHiddenFields([])
  }, [])
  return (
    <FieldArray name={FIELD_NAMES.contacts}>
      {({ fields }) => {
        const onAddHandler = () => fields.push({ contact: '', name: '' })
        return (
          <>
            {fields.length - hiddenFields.length ? (
              <FlexWrapper>
                <FieldName>Contact details</FieldName>
                <FieldName>Contacts name</FieldName>
              </FlexWrapper>
            ) : (
              <AddPlus onClick={onAddHandler} />
            )}
            {fields.map((name, index) => {
              const deleteHandler = () => {
                fields.update(index, null)
                setHiddenFields([...hiddenFields, index])
              }
              const hideField = hiddenFields.includes(index)
              return !hideField ? (
                <Field
                  AddComponent={() => <AddPlus onClick={onAddHandler} />}
                  validate={!hideField && requiredContact}
                  remove={deleteHandler}
                  key={name}
                  name={name}
                  component={ContactsField}
                />
              ) : null
            })}
          </>
        )
      }}
    </FieldArray>
  )
}

export default Contacts
