import { apiInstance } from 'utils/request'
import { ENTITY_NAMES } from 'const/api'
import parseError from 'helpers/parseServerError'

export const getPitaSettings = () =>
  apiInstance.get(`/${ENTITY_NAMES.superAdmin}/pita/stats`).catch((error) => {
    parseError(error, 'Cannot get pita')
  })

export const updatePitaMax = (data) =>
  apiInstance
    .post(`/${ENTITY_NAMES.superAdmin}/pita/max`, data)
    .catch((error) => {
      parseError(error, 'Cannot update pita max')
    })

export const uploadPita = (data) =>
  apiInstance
    .post(`/${ENTITY_NAMES.superAdmin}/pita/update`, data)
    .catch((error) => {
      parseError(error, 'Cannot upload pita file')
    })
