import React from 'react'
import PropTypes from 'prop-types'
import { StyledButton, Text } from './SkipChangesModal.styles'
import { Modal } from 'antd'

const SkipChangesModal = ({ callback, show, closeModal }) => {
  const handleConfirm = () => {
    callback()
    closeModal()
  }

  return (
    <Modal
      open={show}
      centered
      onCancel={closeModal}
      footer={[
        <StyledButton key="empty" onClick={closeModal}>
          Cancel
        </StyledButton>,
        <StyledButton key="all" onClick={handleConfirm} type="primary">
          Continue
        </StyledButton>,
      ]}
    >
      <Text>Do you want to continue? Unsaved changes can be lost.</Text>
    </Modal>
  )
}

SkipChangesModal.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  show: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default SkipChangesModal
