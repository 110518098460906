import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { formatMarketingPages } from './formatters'
import { areFiltersAndSorterEmpty } from 'helpers/filters'

export const marketingPagesState = ({ marketingPages }) => marketingPages

export const getIsLoaded = createSelector(
  marketingPagesState,
  ({ isLoaded }) => isLoaded
)

export const getIsSavingActive = createSelector(
  marketingPagesState,
  ({ isSavingActive }) => isSavingActive
)

export const isLoadingOrSavingActive = createSelector(
  [getIsLoaded, getIsSavingActive],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getEntities = createSelector(
  marketingPagesState,
  ({ entities }) => entities
)

export const getMarketingPages = createSelector(getEntities, (entities) =>
  formatMarketingPages(entities)
)
export const getMarketingPageById = createSelector(
  getMarketingPages,
  (marketingPage) => memoize((id) => (id ? marketingPage[id] : null))
)

export const getSearchString = createSelector(
  marketingPagesState,
  ({ search }) => search
)
export const getFilters = createSelector(
  marketingPagesState,
  ({ filters }) => filters
)
export const getSorter = createSelector(
  marketingPagesState,
  ({ sorter }) => sorter
)
export const getIsDataFilteredOrSorted = createSelector(
  [getFilters, getSorter],
  areFiltersAndSorterEmpty
)
export const getSortedIds = createSelector(
  marketingPagesState,
  ({ sortedIds }) => sortedIds
)

export const getSortedMarketingPages = createSelector(
  [getMarketingPages, getSortedIds],
  (marketingPages, ids) => ids.map((id) => marketingPages[id])
)

export const getPageCount = createSelector(
  marketingPagesState,
  ({ pageCount }) => pageCount
)
export const getPage = createSelector(marketingPagesState, ({ page }) => page)
