/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Select as AntSelect } from 'antd'
import { StyledSelect } from '../RegionSelect/RegionSelect.styles'
import { REGIONS_OPTIONS } from 'const/regions'
import { saveRegion } from 'domains/adPackages/actions'
import useAction from 'utils/useAction'

const RegionGroupSelect = ({ input: { value, onChange }, isDisabled }) => {
  const saveRegionFunc = useAction(saveRegion)
  // ! It's necessary to show placeholder if nothing selected
  value = value || null

  const handleChange = (value) => {
    saveRegionFunc(value)
    onChange(value)
  }

  useEffect(() => {
    if (isDisabled) {
      onChange([])
    }
  }, [isDisabled])

  return (
    <StyledSelect
      value={value}
      disabled={isDisabled}
      placeholder="Choose region"
      onChange={handleChange}
    >
      {REGIONS_OPTIONS.map((region) => {
        return (
          <AntSelect.Option
            key={region.value}
            value={region.value}
            name={region.label}
          >
            {region.label}
          </AntSelect.Option>
        )
      })}
    </StyledSelect>
  )
}

RegionGroupSelect.propTypes = {
  isDisabled: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
}

RegionGroupSelect.defaultProps = {
  isDisabled: false,
}

export default RegionGroupSelect
