import { all, put, race, select, take, takeLatest } from 'redux-saga/effects'
import * as actions from 'actions/views/deleted'
import {
  actions as retailersActions,
  actions as retailerActions,
  selectors as retailerSelectors,
} from 'domains/retailers'
import { finishLoading, startLoading } from 'reducers/layoutSlice'
import { TYPE_TO_PARAM } from 'const/retailers'

function* getRetailersWorker() {
  try {
    yield put(retailersActions.resetFiltersAndSorter())
    yield put(
      retailersActions.fetchMainRetailers({
        deleted: true,
      })
    )
  } catch (error) {
    console.error(error)
  }
}

function* restoreRetailerWorker({ payload }) {
  try {
    const { id, data } = payload
    const { type, ...rest } = data
    const body = {
      options: {
        ...rest,
      },
      type: TYPE_TO_PARAM[type],
    }
    yield all([
      put(startLoading()),
      put(retailerActions.restoreRetailer({ id, data: body })),
    ])

    const [success] = yield race([
      take(retailersActions.restoreRetailerSuccess),
      take(retailersActions.restoreRetailerFailure),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(retailerSelectors.getSearchString),
        select(retailerSelectors.getPage),
        select(retailerSelectors.getFilters),
        select(retailerSelectors.getSorter),
      ])
      yield put(
        retailersActions.fetchMainRetailers({
          deleted: true,
          search,
          page,
          filters,
          sorter,
        })
      )
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

export default function* () {
  yield all([
    getRetailersWorker(),
    takeLatest(actions.restoreRetailer, restoreRetailerWorker),
  ])
}
