import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { updateBonus, createBonus } from 'actions/views/bonuses'
import useAction from 'utils/useAction'
import arrayMutators from 'final-form-arrays'
import { Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import InputNumber from 'components/Input/InputNumber'
import Input from 'components/Input/Input'
import { FIELD_NAMES } from 'const/forms'
import { required } from 'helpers/validators'
import { selectors as BonusesSelectors } from 'domains/bonuses'
import { StyledButton, StyledForm } from './Bonuses.styles'
import { RELATIONSHIPS_NAMES } from 'const/api'
import FieldSwitch from 'components/FieldSwitch/FieldSwitch'

const BonusesModal = ({ id, closeModal }) => {
  const isSavingActive = useSelector(BonusesSelectors.getIsSavingActive)
  const initialValues = useSelector(BonusesSelectors.getBonusById)(id)

  const updateBonusFunc = useAction(updateBonus)
  const createBonusFunc = useAction(createBonus)

  const onSubmit = (data) => {
    id
      ? updateBonusFunc({ values: data, callback: closeModal })
      : createBonusFunc({ values: data, callback: closeModal })
  }

  const parsedInitialValues = {
    [FIELD_NAMES.id]: initialValues?.[FIELD_NAMES.id],
    [FIELD_NAMES.slug]: initialValues?.attributes?.[FIELD_NAMES.slug],
    [FIELD_NAMES.value]: initialValues?.attributes?.[FIELD_NAMES.value],
    [FIELD_NAMES.walletCopy]:
      initialValues?.attributes?.[FIELD_NAMES.walletCopy],
    [FIELD_NAMES.active]: initialValues?.attributes?.[FIELD_NAMES.active],
    [FIELD_NAMES.eligibleTimes]:
      initialValues?.attributes?.[FIELD_NAMES.eligibleTimes],
    [FIELD_NAMES.allowedForGuest]:
      initialValues?.attributes?.[FIELD_NAMES.allowedForGuest],
    [FIELD_NAMES.autoDeactivate]:
      initialValues?.attributes?.[FIELD_NAMES.autoDeactivate],
    [FIELD_NAMES.bonusTTL]: initialValues?.attributes?.[FIELD_NAMES.bonusTTL],
    [FIELD_NAMES.campaignTTL]:
      initialValues?.attributes?.[FIELD_NAMES.campaignTTL],
  }

  return (
    <Form
      destroyOnUnregister
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={parsedInitialValues}
      render={({ handleSubmit, pristine, values }) => {
        const isCampaignTTLEnabled = values[FIELD_NAMES.autoDeactivate]
        const eligibleTimesValue = values[FIELD_NAMES.eligibleTimes]
        return (
          <Spin spinning={isSavingActive} size="large">
            <StyledForm onSubmit={handleSubmit}>
              {id && (
                <Field
                  name={FIELD_NAMES.id}
                  label="ID"
                  disabled
                  component={Input}
                />
              )}
              <>
                <Field
                  name={FIELD_NAMES.slug}
                  required
                  validate={required}
                  label="Slug"
                  component={Input}
                  entity={RELATIONSHIPS_NAMES.slug}
                />
                <Field
                  name={FIELD_NAMES.walletCopy}
                  label="Wallet Copy"
                  component={Input}
                  entity={FIELD_NAMES.walletCopy}
                />
                <Field
                  name={FIELD_NAMES.value}
                  label="Value"
                  required
                  validate={required}
                  component={InputNumber}
                  entity={FIELD_NAMES.value}
                />
                <Field
                  name={FIELD_NAMES.active}
                  type="checkbox"
                  label="Active"
                  component={FieldSwitch}
                  defaultValue={false}
                />
                <Field
                  name={FIELD_NAMES.eligibleTimes}
                  label="Eligible Times"
                  component={InputNumber}
                  min={0}
                  formatter={(value) => (!eligibleTimesValue ? 'INF' : value)}
                  parser={(value) => (value === 'INF' ? 0 : value)}
                />
                <Field
                  name={FIELD_NAMES.allowedForGuest}
                  type="checkbox"
                  label="Allowed For Guest"
                  component={FieldSwitch}
                  defaultValue={false}
                />
                <Field
                  name={FIELD_NAMES.autoDeactivate}
                  type="checkbox"
                  label="Auto Deactivate"
                  component={FieldSwitch}
                  defaultValue={false}
                />
                <Field
                  name={FIELD_NAMES.bonusTTL}
                  label="Bonus TTL (Days)"
                  component={InputNumber}
                  min={0}
                />
                <Field
                  name={FIELD_NAMES.campaignTTL}
                  label="Campaign TTL (Days)"
                  component={InputNumber}
                  min={0}
                  disabled={!isCampaignTTLEnabled}
                />
              </>
              <StyledButton
                type="primary"
                disabled={pristine}
                onClick={handleSubmit}
              >
                Save changes
              </StyledButton>
            </StyledForm>
          </Spin>
        )
      }}
    />
  )
}

BonusesModal.propTypes = {
  id: PropTypes.any,
  // eslint-disable-next-line react/boolean-prop-naming
  closeModal: PropTypes.func.isRequired,
}

BonusesModal.defaultTypes = {
  id: null,
}

export default BonusesModal
