import React from 'react'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import { StyledRangePicker, StyledTable, Wrapper } from './Transactions.styles'
import { transactionsColumns } from './columns'
import { useSelector } from 'react-redux'
import {
  getPage,
  getPageCount,
  getTransactions,
  getSorter,
  getPageSize,
} from 'domains/transactions/selectors'
import { DATE_FORMAT_DASH_REVERSED } from 'const/dates'
import { fetchTransactions } from 'actions/views/transactions'
import useAction from 'utils/useAction'
import { isEqual } from 'lodash'
import { PAGE_SIZE } from 'const/tables'
import { useHistory } from 'react-router'

const Transactions = () => {
  const history = useHistory()
  const fetchTransactionsFunc = useAction(fetchTransactions)
  const dataSource = useSelector(getTransactions)
  const page = useSelector(getPage)
  const pageCount = useSelector(getPageCount)
  const pageSize = useSelector(getPageSize)
  const sorter = useSelector(getSorter)
  const dataSourceWithId = dataSource?.map((item, index) => ({
    ...item,
    id: index,
  }))

  const onDateChange = (e) => {
    const from = e?.[0]?.format(DATE_FORMAT_DASH_REVERSED)
    const to = e?.[1]?.format(DATE_FORMAT_DASH_REVERSED)

    return fetchTransactionsFunc({ from, to })
  }

  const onTableChange = (pagination, newFilters, newSorter) => {
    const pageChanged = page !== pagination.current
    const isSorterUpdated = !pageChanged && !isEqual(newSorter, sorter)

    fetchTransactionsFunc({
      page: isSorterUpdated ? 1 : pagination.current,
      sorter: newSorter,
      size: isSorterUpdated ? 20 : pagination.pageSize,
    })
  }

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: pageSize,
    current: page,
    showSizeChanger: true,
  }

  return (
    <Wrapper>
      <StyledRangePicker onChange={onDateChange} />
      <StyledTable
        onChange={onTableChange}
        bordered
        columns={transactionsColumns(sorter)}
        dataSource={dataSource?.length ? dataSourceWithId : []}
        expandable={false}
        loading={false}
        rowClassName={getRegularRowClass}
        rowKey={getRowKey}
        showSorterTooltip={false}
        pagination={paginationConfig}
        onRow={(record) => {
          return {
            onClick: () => {
              sessionStorage.setItem('paymentDate', record.date)
              history.replace({
                pathname: '/payment_transactions',
                search: `?date=${record.date}`,
              })
            },
          }
        }}
      />
    </Wrapper>
  )
}

export default Transactions
