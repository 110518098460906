import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  saveFiltersAndSorter,
  fetchCouponsSuccess,
  fetchCouponsFailure,
  startSaving,
  finishSaving,
  clearData,
} = reducer.actions

export const fetchCoupons = createAction('coupons/fetchCoupons')

export const deleteCoupon = createAction('coupons/deleteCoupon')
export const deleteCouponSuccess = createAction('coupons/deleteCouponSuccess')
export const deleteCouponFailure = createAction('coupons/deleteCouponFailure')

export const createCoupon = createAction('coupons/createCoupon')
export const createCouponSuccess = createAction('coupons/createCouponSuccess')
export const createCouponFailure = createAction('coupons/createCouponFailure')

export const updateCoupon = createAction('coupons/updateCoupon')
export const updateCouponSuccess = createAction('coupons/updateCouponSuccess')
export const updateCouponFailure = createAction('coupons/updateCouponFailure')
