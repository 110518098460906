import React from 'react'
import { Button } from 'antd'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/Input'
import { FIELD_NAMES } from 'const/forms'
import { validLink } from 'helpers/validators'
import { Title, Result, Link, Text } from 'components/Title/Title.styles'
import { Wrapper, StyledCol, FormWrapper } from './ConvertForm.styles'
import useAction from 'utils/useAction'
import { convertLink } from 'actions/views/linkConverter'
import { useSelector } from 'react-redux'
import { selectors as generalSelectors } from 'domains/general'

const ConvertForm = () => {
  const convertLinkFunc = useAction(convertLink)
  const onSubmit = (values) => convertLinkFunc(values)
  const { started, escaped, not_escaped } = useSelector(
    generalSelectors.getLinks
  )
  const initialValues = {
    [FIELD_NAMES.link]: started,
  }
  return (
    <Wrapper>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, hasValidationErrors }) => {
          return (
            <FormWrapper>
              <StyledCol>
                <Title>Link:</Title>
                <Field
                  validate={validLink}
                  component={Input}
                  name={FIELD_NAMES.link}
                />
              </StyledCol>
              <StyledCol>
                <Button
                  disabled={hasValidationErrors}
                  size="default"
                  type="primary"
                  onClick={handleSubmit}
                >
                  Convert
                </Button>
              </StyledCol>
            </FormWrapper>
          )
        }}
      />
      <Result>
        {escaped && (
          <Text>
            Escaped:
            <Link href={escaped} rel="noreferrer noopener" target="_blank">
              {escaped}
            </Link>
          </Text>
        )}
        {not_escaped && (
          <Text>
            Not escaped:
            <Link href={not_escaped} rel="noreferrer noopener" target="_blank">
              {not_escaped}
            </Link>
          </Text>
        )}
      </Result>
    </Wrapper>
  )
}

export default ConvertForm
