import React from 'react'
import capitalize from 'lodash/capitalize'
import PropTypes from 'prop-types'
import { Root, Title, Value } from './InfoItem.styles'
import CheckboxIcon from '../../../../components/CheckboxIcon'

const InfoItem = ({ data }) => {
  const [key, value] = data
  const name = capitalize(key).split('_').join(' ')
  const displayCheckBox = typeof value === 'boolean'

  return (
    <Root>
      <Title>{name}</Title>
      <Value>
        {displayCheckBox ? <CheckboxIcon checked={value} /> : value || '-'}
      </Value>
    </Root>
  )
}

InfoItem.propTypes = {
  data: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  }).isRequired,
}

export default InfoItem
