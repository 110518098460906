import React from 'react'
import moment from 'moment'
import { FIELD_DESCRIPTION } from 'const/retailers'
import { DATE_TIME_FORMAT } from 'const/dates'
import { FIELD_NAMES, GROUP_FIELD_NAME_PREFIX } from 'const/forms'
import { ADMIN, ROLES_LABELS } from 'const/roles'

export const formatFieldValue = (value) => value || '-'
export const formatAdminField = (value, { [FIELD_NAMES.role]: role }) =>
  role === ADMIN ? <strong>{value || '-'}</strong> : value || '-'

export const formatAdminRolesField = (authorizedRoles) => {
  return authorizedRoles
    ?.map((item) => (item in ROLES_LABELS ? ROLES_LABELS[item] : item))
    .join(', ')
}

export const formatUrl = (url) => {
  if (!url) return url

  const hasProtocol = /^((.+):\/\/)/.test(url)
  if (hasProtocol) return url

  return `//${url}`
}

export const formatBoolField = ({ name, fieldName, newValue }) =>
  newValue != null && {
    name: fieldName,
    description: `${FIELD_DESCRIPTION[name]} ${
      newValue ? 'activated' : 'deactivated'
    }`,
  }

export const formatSelectField = ({ name, fieldName, newValue }) =>
  newValue != null && {
    name: fieldName,
    description: `${FIELD_DESCRIPTION[name]} ${newValue.label}`,
  }

export const formatInputField = ({ name, fieldName, newValue }) =>
  newValue != null && {
    name: fieldName,
    description: `${FIELD_DESCRIPTION[name]} ${newValue}`,
  }

export const formatInputWithDatesField = ({ name, fieldName, newValue }) => {
  const { cb, dates } = newValue || {}

  const startData = dates?.[0] != null ? dates?.[0] : null
  const finishData = dates?.[1] != null ? dates?.[1] : null
  const newStartDate = startData
    ? moment(startData).format(DATE_TIME_FORMAT)
    : 'unset'
  const newFinishDate = finishData
    ? moment(finishData).format(DATE_TIME_FORMAT)
    : 'unset'
  const hasValue = cb != null

  return (
    newValue && {
      name: fieldName,
      description: [
        hasValue && `${FIELD_DESCRIPTION[name]} ${cb}`,
        dates?.[0] != null &&
          `${
            FIELD_DESCRIPTION.start[hasValue ? 'default' : name]
          } ${newStartDate}`,
        dates?.[1] != null &&
          `${
            FIELD_DESCRIPTION.finish[hasValue ? 'default' : name]
          } ${newFinishDate}`,
      ].filter(Boolean),
    }
  )
}

export const formatSelectCategoriesField = ({
  fieldName,
  newValue,
  categories,
}) => {
  if (newValue) {
    const { added, removed, main } = Object.entries(newValue).reduce(
      (result, [id, selected]) => {
        const categoryName = categories.find((category) => category.id === id)
          .attributes.name
        if (selected) {
          if (selected[FIELD_NAMES.mainCategory]) {
            result.main = categoryName
          } else {
            result.added.push(categoryName)
          }
        } else {
          result.removed.push(categoryName)
        }
        return result
      },
      {
        main: null,
        added: [],
        removed: [],
      }
    )
    const description = []
    if (main) {
      description.push(`${FIELD_DESCRIPTION[FIELD_NAMES.mainCategory]} ${main}`)
    }
    if (added.length) {
      description.push(`${FIELD_DESCRIPTION.added} ${added.join(', ')}`)
    }
    if (removed.length) {
      description.push(`${FIELD_DESCRIPTION.removed} ${removed.join(', ')}`)
    }
    return { name: fieldName, description }
  }
}

export const formatGroupCellField = ({ name, fieldName, newValue, groups }) => {
  if (newValue) {
    const groupName =
      groups[name.substring(GROUP_FIELD_NAME_PREFIX.length)].attributes.name
    const description = [groupName]
    const positionChanged = newValue.hasOwnProperty(FIELD_NAMES.position)
    const activeChanged = newValue.hasOwnProperty(FIELD_NAMES.active)
    const activeTruthy = newValue[FIELD_NAMES.active]
    if (activeChanged) {
      description.push(
        `${FIELD_DESCRIPTION.active} ${
          activeTruthy ? 'activated' : 'deactivated'
        }`
      )
    }
    if (
      positionChanged &&
      (!activeChanged || (activeChanged && activeTruthy))
    ) {
      description.push(
        `${FIELD_DESCRIPTION.position} ${newValue[FIELD_NAMES.position]}`
      )
    }
    return { name: fieldName, description }
  }
}

export const formatActionOnClickField = ({ name, fieldName, newValue }) =>
  newValue != null && {
    name: fieldName,
    description: `${FIELD_DESCRIPTION[name]} ${newValue ? 'show' : 'redirect'}`,
  }

export const formatMainCategory = ({ name, fieldName, newValue }) =>
  newValue !== undefined && {
    name: fieldName,
    description: `${FIELD_DESCRIPTION[name]} ${
      newValue ? newValue.label : 'unset'
    }`,
  }
