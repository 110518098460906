import React from 'react'
import { useSelector } from 'react-redux'
import * as pitaSettingsActions from 'actions/views/pitaPage'
import * as pitaSettingsSelectors from 'domains/pitaSettings/selectors'
import useAction from 'utils/useAction'
import {
  FlexWrapper,
  StatsWrapper,
  StyledButton,
  StyledForm,
  Wrapper,
} from './PitaSettings.styles'
import { Spin } from 'antd'
import InputNumber from 'components/Input/InputNumber'
import { Field, Form } from 'react-final-form'
import { FIELD_NAMES } from 'const/forms'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper'
import { required } from 'helpers/validators'
import CsvPicker from 'components/CsvPicker'

const PitaSettings = () => {
  const isSavingActive = useSelector(pitaSettingsSelectors.getIsSavingActive)
  const initialValues = useSelector(pitaSettingsSelectors.getData)

  const updatePitaMax = useAction(pitaSettingsActions.updatePitaMax)
  const uploadPita = useAction(pitaSettingsActions.uploadPita)

  return (
    <Wrapper>
      <StatsWrapper>
        <h1>PITA Stats</h1>
        <div>
          Current pita: <b>{initialValues[FIELD_NAMES.current]}</b>
        </div>
        <div>
          Max pita: <b>{initialValues[FIELD_NAMES.max]}</b>
        </div>
      </StatsWrapper>

      <h2>Modify PITA</h2>
      <Form
        destroyOnUnregister
        onSubmit={updatePitaMax}
        initialValues={{
          [FIELD_NAMES.max]: initialValues[FIELD_NAMES.max] || 0,
        }}
        render={({ handleSubmit, pristine }) => {
          return (
            <Spin spinning={isSavingActive} size="large">
              <StyledForm onSubmit={handleSubmit}>
                <Field
                  name={FIELD_NAMES.max}
                  min={0}
                  step={1}
                  label="Pita Max"
                  placeholder="Pita Max"
                  component={InputNumber}
                />
                <StyledButton
                  type="primary"
                  disabled={pristine}
                  onClick={handleSubmit}
                >
                  Save changes
                </StyledButton>
              </StyledForm>
            </Spin>
          )
        }}
      />
      <h2>Upload PITA file</h2>
      <Form
        destroyOnUnregister
        onSubmit={uploadPita}
        initialValues={{ [FIELD_NAMES.csv]: null }}
        render={({ handleSubmit, pristine }) => {
          return (
            <Spin spinning={isSavingActive} size="large">
              <StyledForm onSubmit={handleSubmit}>
                <FieldWrapper label="Csv File" required>
                  <FlexWrapper>
                    <Field
                      hasCropper
                      validate={required}
                      name={FIELD_NAMES.csv}
                      component={CsvPicker}
                    />
                  </FlexWrapper>
                </FieldWrapper>
                <StyledButton
                  type="primary"
                  disabled={pristine}
                  onClick={handleSubmit}
                >
                  Upload csv
                </StyledButton>
              </StyledForm>
            </Spin>
          )
        }}
      />
    </Wrapper>
  )
}

export default PitaSettings
