import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from './api'
import * as shopsSelectors from './selectors'
import { formatCampaignForRequests, formatShopPrograms } from './formatters'

function* fetchShopsWorker({ payload }) {
  try {
    const { data } = yield call(api.getShops, payload)
    yield put(
      actions.fetchShopsSuccess({
        ...payload,
        shops: data.data,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchShopsFailure())
  }
}

function* fetchLocalizedShopsWorker({ payload }) {
  try {
    yield put(actions.startSaving())
    const { data } = yield call(api.getLocalizedShops, payload)
    yield put(
      actions.fetchLocalizedShopsSuccess({
        ...payload,
        localizedShops: data,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchLocalizedShopsFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

function* fetchShopCampaignDealsWorker({ payload }) {
  try {
    yield put(actions.startSaving())
    const body = formatCampaignForRequests(payload)
    const { data } = yield call(api.getShopCampaignDeals, payload)

    yield put(
      actions.fetchShopCampaignDealsSuccess({
        ...body,
        campaignDeals: data,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchLocalizedShopsFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

function* fetchShopAdPackagesWorker({ payload }) {
  try {
    yield put(actions.startSaving())
    const { data } = yield call(api.getShopAdPackages, payload)

    yield put(
      actions.fetchShopAdPackagesSuccess({
        ...payload,
        adPackages: data,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchShopAdPackagesFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

function* fetchShopOffersWorker({ payload }) {
  try {
    yield put(actions.startSaving())

    const { data } = yield call(api.getShopOffers, payload) || {}
    yield put(
      actions.fetchShopOffersSuccess({
        ...payload,
        shopOffers: data,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchShopOffersFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

function* fetchShopProgramsWorker({ payload }) {
  try {
    const { data } = yield call(api.getShopPrograms, payload)

    yield put(
      actions.fetchShopProgramsSuccess({
        ...payload,
        programs: formatShopPrograms(data),
      })
    )

    yield put(actions.fetchShopProgramsFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

function* updateShopWorker({ payload }) {
  try {
    const { data } = yield call(api.updateShop, payload)
    const currentStore = yield select(shopsSelectors.getCurrentShopId)
    if (data) {
      yield put(actions.updateShopSuccess(payload))
      yield put(actions.fetchLocalizedShops({ search: currentStore }))
    } else {
      yield put(actions.updateShopFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateShopFailure())
  }
}

function* updateLocalizedShopWorker({ payload }) {
  try {
    const { data } = yield call(api.updateLocalizedShop, payload)
    if (data) {
      yield put(actions.updateLocalizedShopSuccess(payload))
    } else {
      yield put(actions.updateLocalizedShopFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateLocalizedShopFailure())
  }
}

function* updateShopCouponsSettingsWorker({ payload }) {
  try {
    const { data } = yield call(api.updateShopCouponsSettings, {
      id: payload.id,
      data: payload.data,
    })

    if (data) {
      const currentStore = yield select(shopsSelectors.getCurrentShopId)

      yield put(actions.updateShopCouponsSettingsSuccess(data))
      yield put(actions.fetchLocalizedShops({ search: currentStore }))
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateShopCouponsSettingsFailure())
  }
}

function* createShopCampaignWorker({ payload }) {
  try {
    const data = yield call(api.createShopCampaign, payload)
    if (data) {
      yield put(actions.createShopCampaignSuccess(payload))
    } else {
      yield put(actions.createShopCampaignFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createShopCampaignFailure())
  }
}

function* updateShopCampaignWorker({ payload }) {
  try {
    const { data: body } = payload
    const data = yield call(api.updateShopCampaign, body)
    if (data) {
      yield put(actions.updateShopCampaignSuccess(body))
    } else {
      yield put(actions.updateShopCampaignFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateShopCampaignFailure())
  }
}

function* createShopOfferWorker({ payload }) {
  try {
    const data = yield call(api.createShopOffer, payload)
    if (data) {
      yield put(actions.createShopOfferSuccess(payload))
    } else {
      yield put(actions.createShopOfferFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createShopOfferFailure())
  }
}

function* updateShopOfferWorker({ payload }) {
  try {
    const data = yield call(api.updateShopOffer, payload)
    if (data) {
      yield put(actions.updateShopOfferSuccess(payload))
    } else {
      yield put(actions.updateShopOfferFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateShopOfferFailure())
  }
}

function* deleteCampaignWorker({ payload }) {
  try {
    const data = yield call(api.deleteCampaign, payload)
    if (data) {
      yield put(actions.deleteCampaignSuccess(payload))
    } else {
      yield put(actions.deleteCampaignFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteCampaignFailure())
  }
}

function* deleteAdPackageWorker({ payload }) {
  try {
    const data = yield call(api.deleteAdPackage, payload)
    if (data) {
      yield put(actions.deleteAdPackageSuccess(payload))
    } else {
      yield put(actions.deleteAdPackageFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteAdPackageFailure())
  }
}

function* duplicateAdPackageWorker({ payload }) {
  try {
    const data = yield call(api.duplicateAdPackage, payload)
    if (data) {
      yield put(actions.duplicateAdPackageSuccess(payload))
    } else {
      yield put(actions.duplicateAdPackageFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.duplicateAdPackageFailure())
  }
}

function* deleteShopOfferWorker({ payload }) {
  try {
    const data = yield call(api.deleteShopOffer, payload)
    if (data) {
      yield put(actions.deleteShopOfferSuccess(payload))
    } else {
      yield put(actions.deleteShopOfferFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteShopOfferFailure())
  }
}

function* createShopGroupWorker({ payload }) {
  try {
    yield put(actions.startSaving())
    const data = yield call(api.createShopGroup, payload)
    if (data) {
      yield put(actions.createShopGroupSuccess())
    } else {
      yield put(actions.createShopGroupFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createShopGroupFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

function* updateShopGroupWorker({ payload }) {
  try {
    yield put(actions.startSaving())
    const data = yield call(api.updateShopGroup, payload)
    if (data) {
      yield put(actions.updateShopGroupSuccess())
    } else {
      yield put(actions.updateShopGroupFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateShopGroupFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

function* deleteShopGroupWorker({ payload }) {
  try {
    yield put(actions.startSaving())
    const data = yield call(api.deleteShopGroup, payload.values)
    if (data) {
      yield put(actions.deleteShopGroupSuccess())
    } else {
      yield put(actions.deleteShopGroupFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteShopGroupFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

function* createShopCategoryWorker({ payload }) {
  try {
    yield put(actions.startSaving())
    const data = yield call(api.createShopCategory, payload)
    if (data) {
      yield put(actions.createShopCategorySuccess())
    } else {
      yield put(actions.createShopCategoryFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createShopCategoryFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

function* updateShopCategoryWorker({ payload }) {
  try {
    yield put(actions.startSaving())
    const data = yield call(api.updateShopCategory, payload)
    if (data) {
      yield put(actions.updateShopCategorySuccess())
    } else {
      yield put(actions.updateShopCategoryFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateShopCategoryFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

function* deleteShopCategoryWorker({ payload }) {
  try {
    yield put(actions.startSaving())
    const data = yield call(api.deleteShopCategory, payload.values)
    if (data) {
      yield put(actions.deleteShopCategorySuccess())
    } else {
      yield put(actions.deleteShopCategoryFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteShopCategoryFailure())
  } finally {
    yield put(actions.finishSaving())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchShops, fetchShopsWorker),
    takeLatest(actions.fetchLocalizedShops, fetchLocalizedShopsWorker),
    takeLatest(actions.fetchShopCampaignDeals, fetchShopCampaignDealsWorker),
    takeLatest(actions.fetchShopAdPackages, fetchShopAdPackagesWorker),
    takeLatest(actions.fetchShopPrograms, fetchShopProgramsWorker),
    takeLatest(actions.updateShop, updateShopWorker),
    takeLatest(actions.updateLocalizedShop, updateLocalizedShopWorker),
    takeLatest(
      actions.updateShopCouponsSettings,
      updateShopCouponsSettingsWorker
    ),
    takeLatest(actions.createShopCampaign, createShopCampaignWorker),
    takeLatest(actions.updateShopCampaign, updateShopCampaignWorker),
    takeLatest(actions.fetchShopOffers, fetchShopOffersWorker),
    takeLatest(actions.createShopOffer, createShopOfferWorker),
    takeLatest(actions.updateShopOffer, updateShopOfferWorker),
    takeLatest(actions.deleteCampaign, deleteCampaignWorker),
    takeLatest(actions.deleteAdPackage, deleteAdPackageWorker),
    takeLatest(actions.duplicateAdPackage, duplicateAdPackageWorker),
    takeLatest(actions.deleteShopOffer, deleteShopOfferWorker),
    takeLatest(actions.createShopGroup, createShopGroupWorker),
    takeLatest(actions.updateShopGroup, updateShopGroupWorker),
    takeLatest(actions.deleteShopGroup, deleteShopGroupWorker),
    takeLatest(actions.createShopCategory, createShopCategoryWorker),
    takeLatest(actions.updateShopCategory, updateShopCategoryWorker),
    takeLatest(actions.deleteShopCategory, deleteShopCategoryWorker),
  ])
}
