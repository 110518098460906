import { createAction } from '@reduxjs/toolkit'

export const fetchBudget = createAction('payment/fetchBudget')
export const uploadPaymentList = createAction('payment/uploadPaymentList')
export const fetchPaymentUpload = createAction('payment/fetchPaymentUploads')
export const fetchPayments = createAction('payment/fetchPayments')
export const deletePayment = createAction('payment/deletePayment')
export const updatePayment = createAction('payment/updatePayment')
export const retractPayment = createAction('payment/retractPayment')
export const rejectPayment = createAction('payment/rejectPayment')
export const approvePayment = createAction('payment/approvePayment')
export const resendPayment = createAction('payment/resendPayment')
export const createPayment = createAction('payment/createPayment')
