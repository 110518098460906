import React from 'react'
import { Actions, StyledActionButton } from './CreditCardsComparison.styles'

const FIELD_NAMES = {
  categoryName: 'category_name',
  isCashBack: 'is_cash_back',
  rewardValue: 'reward_value',
  merchantDomains: 'merchant_domains',
  additionalInfo: 'additional_info',
  programName: 'program_name',
  programConditionsUrl: 'program_conditions_url',
  programPictureUrl: 'program_picture_url',
  programApplyUrl: 'program_apply_url',
}

export const getColumns = (
  onPositiveVote,
  onNegativeVote,
  onDeclineCreditCardProgram
) => [
  {
    title: 'Category Name',
    dataIndex: FIELD_NAMES.categoryName,
    key: FIELD_NAMES.categoryName,
    width: 150,
  },
  {
    title: 'Is Cash Back',
    dataIndex: FIELD_NAMES.isCashBack,
    key: FIELD_NAMES.isCashBack,
    width: 100,
    render: (value) => (value ? 'Yes' : 'No'),
  },
  {
    title: 'Reward Value',
    dataIndex: FIELD_NAMES.rewardValue,
    key: FIELD_NAMES.rewardValue,
    width: 100,
    sorter: (a, b) =>
      parseFloat(a[FIELD_NAMES.rewardValue]) -
      parseFloat(b[FIELD_NAMES.rewardValue]),
  },
  {
    title: 'Exclusive Merchant Domains',
    dataIndex: FIELD_NAMES.merchantDomains,
    key: FIELD_NAMES.merchantDomains,
    width: 150,
    render: (domains) => (domains?.length ? domains.join(', ') : 'N/A'),
  },
  {
    title: 'Additional Info',
    dataIndex: FIELD_NAMES.additionalInfo,
    key: FIELD_NAMES.additionalInfo,
    width: 300,
  },
  {
    title: 'Program Name',
    dataIndex: FIELD_NAMES.programName,
    key: FIELD_NAMES.programName,
    width: 200,
  },
  {
    title: 'Program Conditions URL',
    dataIndex: FIELD_NAMES.programConditionsUrl,
    key: FIELD_NAMES.programConditionsUrl,
    width: 250,
    render: (url) => (
      <a href={url} target="_blank" rel="noopener noreferrer">
        View program conditions
      </a>
    ),
  },
  {
    title: 'Program Picture',
    dataIndex: FIELD_NAMES.programPictureUrl,
    key: FIELD_NAMES.programPictureUrl,
    width: 200,
    render: (url) => (
      <img src={url} alt="Program" style={{ width: '100px', height: 'auto' }} />
    ),
  },
  {
    title: 'Program Apply URL',
    dataIndex: FIELD_NAMES.programApplyUrl,
    key: FIELD_NAMES.programApplyUrl,
    width: 250,
    render: (url) => (
      <a href={url} target="_blank" rel="noopener noreferrer">
        View apply URL
      </a>
    ),
  },
  {
    title: 'Is correct?',
    key: 'actions',
    render: (_, elem) => {
      return (
        <Actions>
          <StyledActionButton
            onClick={() => {
              onPositiveVote(elem)
            }}
          >
            <span role="img" aria-label="Like">
              👍
            </span>
          </StyledActionButton>
          <StyledActionButton
            onClick={() => {
              onNegativeVote(elem)
            }}
          >
            <span role="img" aria-label="Dislike">
              👎
            </span>
          </StyledActionButton>
          <StyledActionButton
            onClick={() => {
              onDeclineCreditCardProgram(elem)
            }}
            type="danger"
          >
            <span role="img" aria-label="Decline">
              ❌
            </span>
          </StyledActionButton>
        </Actions>
      )
    },
    align: 'center',
    width: 120,
  },
]
