import React from 'react'
import PropTypes from 'prop-types'
import { Buttons, StyledButton, Text } from './Choice.styles'

const Choice = ({ yesCallback, noCallback, closeModal, text }) => {
  const handleAction = (actionHandler) => () => {
    closeModal()
    actionHandler()
  }
  return (
    <>
      <Text>{text}</Text>
      <Buttons>
        <StyledButton onClick={handleAction(noCallback)}>No</StyledButton>
        <StyledButton onClick={handleAction(yesCallback)} type="primary">
          Yes
        </StyledButton>
      </Buttons>
    </>
  )
}

Choice.propTypes = {
  text: PropTypes.string.isRequired,
  yesCallback: PropTypes.func.isRequired,
  noCallback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default Choice
