import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  saveFiltersAndSorter,
  fetchMarketingPagesSuccess,
  fetchMarketingPagesFailure,
  startSaving,
  finishSaving,
  clearData,
} = reducer.actions

export const fetchMarketingPagesRequest = createAction(
  'marketingPages/fetchMarketingPagesRequest'
)

export const updateMarketingPageRequest = createAction(
  'marketingPages/updateMarketingPageRequest'
)
export const updateMarketingPageSuccess = createAction(
  'marketingPages/updateMarketingPageSuccess'
)
export const updateMarketingPageFailure = createAction(
  'marketingPages/updateMarketingPageFailure'
)

export const createMarketingPageRequest = createAction(
  'marketingPages/createMarketingPageRequest'
)
export const createMarketingPageSuccess = createAction(
  'marketingPages/createMarketingPageSuccess'
)
export const createMarketingPageFailure = createAction(
  'marketingPages/createMarketingPageFailure'
)

export const createMarketingPageWithMultipleUrlsRequest = createAction(
  'marketingPages/createMarketingPageWithMultipleUrlsRequest'
)
export const createMarketingPageWithMultipleUrlsSuccess = createAction(
  'marketingPages/createMarketingPageWithMultipleUrlsSuccess'
)
export const createMarketingPageWithMultipleUrlsFailure = createAction(
  'marketingPages/createMarketingPageWithMultipleUrlsFailure'
)

export const deleteMarketingPageRequest = createAction(
  'marketingPages/deleteMarketingPageRequest'
)
export const deleteMarketingPageSuccess = createAction(
  'marketingPages/deleteMarketingPageSuccess'
)
export const deleteMarketingPageFailure = createAction(
  'marketingPages/deleteMarketingPageFailure'
)
