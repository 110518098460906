import { FIELD_NAMES } from 'const/forms'

export default () => [
  {
    title: 'ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 60,
    align: 'center',
  },
  {
    title: 'User email',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.email],
    key: FIELD_NAMES.email,
    width: 60,
    align: 'center',
  },
  {
    title: 'Role',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.role],
    key: FIELD_NAMES.role,
    width: 60,
    align: 'center',
  },
  {
    title: 'Date',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.performedAt],
    key: FIELD_NAMES.date,
    width: 100,
    align: 'center',
  },
  {
    title: 'Event',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.event],
    key: FIELD_NAMES.event,
    width: 80,
    align: 'center',
  },
  {
    title: 'Changes',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.changes],
    key: FIELD_NAMES.changes,
    width: 80,
    align: 'center',
  },
]
