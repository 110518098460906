import { createAction } from '@reduxjs/toolkit'

export const fetchLandingPages = createAction(
  'landingPageImages/fetchLandingPages'
)
export const updateLandingPage = createAction(
  'landingPageImages/updateLandingPage'
)
export const createLandingPage = createAction(
  'landingPageImages/createLandingPage'
)
export const deleteLandingPage = createAction(
  'landingPageImages/deleteLandingPage'
)
