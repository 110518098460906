import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  page: 1,
  pageCount: 1,
  data: [],
  filters: {},
  sorter: {},
  isLoaded: false,
}

const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    fetchAllLogsSuccess: (state, { payload }) => ({
      ...state,
      filters: payload?.data?.data[0]?.attributes?.item_type,
      isLoaded: true,
      data: payload?.data?.data,
      pageCount: payload.data.meta.page_count,
      page: payload.page || initialState.page,
    }),
    fetchAllLogsFailure: (state) => state,
    saveLogsFiltersAndSorter: (state, { payload = {} }) => ({
      ...state,
      search: payload.search,
      filters: payload.filters || initialState.filters,
    }),
    startSaving: (state) => ({
      ...state,
      isSavingActive: true,
    }),
    finishSaving: (state) => ({
      ...state,
      isSavingActive: false,
    }),
  },
})

export default logsSlice
