import { notification } from 'antd'
import { RELATIONSHIPS_NAMES } from 'const/api'
import { FIELD_NAMES, JAVA_FIELD_NAMES } from 'const/forms'
import parseError from 'helpers/parseServerError'
import {
  javaGetCookieInstance,
  javaWithCookieInstance,
} from 'utils/requestJAVA'
import { PAGE_SIZE } from 'const/tables'

export const getPaymentTransactions = ({
  page,
  sorter,
  filters,
  search,
  select,
}) => {
  return javaWithCookieInstance
    .get(`/${RELATIONSHIPS_NAMES.paymentTransaction}`, {
      params: {
        page: page || 1,
        size: PAGE_SIZE,
        sort: sorter?.length ? sorter : FIELD_NAMES.createdAt,
        ...filters,
        [JAVA_FIELD_NAMES.ruleId]: search || null,
        [JAVA_FIELD_NAMES.commissionType]: select || null,
        [JAVA_FIELD_NAMES.date]: sessionStorage.getItem('paymentDate') || null,
      },
    })
    .catch((error) => {
      return error?.response?.status === 504
        ? notification.error({
            placement: 'bottomRight',
            duration: 10,
            message: 'Java Authentication failed',
            description:
              "Ensure you are accessing BO from the office. All routes that require JAVA won't work properly.",
          })
        : parseError(error, 'Cannot get payment transactions')
    })
}

export const getTransactionDetails = ({ id }) =>
  javaWithCookieInstance
    .get(`/${RELATIONSHIPS_NAMES.paymentTransaction}/${id}`)
    .catch((error) => {
      return error?.response?.status === 504
        ? notification.error({
            placement: 'bottomRight',
            duration: 10,
            message: 'Java Authentication failed',
            description:
              "Ensure you are accessing BO from the office. All routes that require JAVA won't work properly.",
          })
        : parseError(error, 'Cannot get payment transaction')
    })

export const updatePaymentAdPackage = (data) =>
  javaWithCookieInstance
    .put(
      `/${RELATIONSHIPS_NAMES.paymentTransactionAdPackage}/${data?.id}`,
      data
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Ad Package commission hasn't been edited")
    })

export const updatePaymentDataPackage = (data) =>
  javaWithCookieInstance
    .put(
      `/${RELATIONSHIPS_NAMES.paymentTransactionDataPackage}/${data?.id}`,
      data
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Data Package commission hasn't been edited")
    })

export const updatePaymentManual = (data) =>
  javaWithCookieInstance
    .put(
      `/${RELATIONSHIPS_NAMES.paymentTransactionManual}/${data?.id}`,
      data
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Manual commission hasn't been edited")
    })

export const createPaymentAdPackage = (data) =>
  javaGetCookieInstance
    .post(`/${RELATIONSHIPS_NAMES.paymentTransactionAdPackage}`, data)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Ad Package commission hasn't been created")
    })

export const createPaymentDataPackage = (data) =>
  javaGetCookieInstance
    .post(`/${RELATIONSHIPS_NAMES.paymentTransactionDataPackage}`, data)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Data Package commission hasn't been created")
    })

export const createPaymentManual = (data) =>
  javaGetCookieInstance
    .post(`/${RELATIONSHIPS_NAMES.paymentTransactionManual}`, data)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Manual commission hasn't been created")
    })