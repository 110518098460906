import React from 'react'
import { PageLayout } from 'components/styled/layout'
import Admins from './Admins'

const Roles = () => (
  <PageLayout>
    <Admins />
  </PageLayout>
)

export default Roles
