import { createSelector } from '@reduxjs/toolkit'
import {
  formatPaymentUploads,
  formatPayments,
} from 'domains/payments/formatters'
import { ENTITY_NAMES } from 'const/api'
import { areFiltersAndSorterEmpty } from 'helpers/filters'
import memoize from 'lodash/memoize'

export const budgetState = ({ payments }) => payments.budget
export const paymentsState = ({ payments }) => payments.payments
export const paymentsUploadsState = ({ payments }) => payments.paymentsUploads

export const getMonthlyBudget = createSelector(
  budgetState,
  ({ monthly_budget }) => monthly_budget
)

export const getRemainingBudget = createSelector(
  budgetState,
  ({ remaining_budget }) => remaining_budget
)

export const getIsLoadedPayments = createSelector(
  paymentsState,
  ({ isLoaded }) => isLoaded
)

export const getIsSavingActivePayments = createSelector(
  paymentsState,
  ({ isSavingActive }) => isSavingActive
)

export const isLoadingOrSavingActivePayments = createSelector(
  [getIsLoadedPayments, getIsSavingActivePayments],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getEntitiesPayments = createSelector(
  paymentsState,
  ({ entities }) => entities
)

export const getPayments = createSelector(getEntitiesPayments, (entities) =>
  formatPayments(entities[ENTITY_NAMES.payments])
)

export const getPaymentById = createSelector(getPayments, (payments) =>
  memoize((id) => (id ? payments[id] : null))
)

export const getSearchStringPayments = createSelector(
  paymentsState,
  ({ search }) => search
)

export const getPagePayments = createSelector(paymentsState, ({ page }) => page)

export const getFiltersPayments = createSelector(
  paymentsState,
  ({ filters }) => filters
)

export const getSorterPayments = createSelector(
  paymentsState,
  ({ sorter }) => sorter
)

export const getIsDataFilteredOrSortedPayments = createSelector(
  [getFiltersPayments, getSorterPayments],
  areFiltersAndSorterEmpty
)

export const getPageCountPayments = createSelector(
  paymentsState,
  ({ pageCount }) => pageCount
)

export const getSortedIdsPayments = createSelector(
  paymentsState,
  ({ sortedIds }) => sortedIds
)

export const getSortedPayments = createSelector(
  [getPayments, getSortedIdsPayments],
  (attempts, ids) => ids.map((id) => attempts[id])
)

export const getIsSavingActive = createSelector(
  paymentsState,
  ({ isSavingActive }) => isSavingActive
)

export const getIsLoadedPaymentsUploads = createSelector(
  paymentsUploadsState,
  ({ isLoaded }) => isLoaded
)

export const getIsSavingActivePaymentsUploads = createSelector(
  paymentsUploadsState,
  ({ isSavingActive }) => isSavingActive
)

export const isLoadingOrSavingActivePaymentsUploads = createSelector(
  [getIsLoadedPaymentsUploads, getIsSavingActivePaymentsUploads],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getEntitiesPaymentsUploads = createSelector(
  paymentsUploadsState,
  ({ entities }) => entities
)

export const getPaymentsUploadAttempts = createSelector(
  getEntitiesPaymentsUploads,
  (entities) => formatPaymentUploads(entities[ENTITY_NAMES.paymentUploads])
)

export const getSearchStringPaymentsUploads = createSelector(
  paymentsUploadsState,
  ({ search }) => search
)

export const getPagePaymentsUploads = createSelector(
  paymentsUploadsState,
  ({ page }) => page
)

export const getFiltersPaymentsUploads = createSelector(
  paymentsUploadsState,
  ({ filters }) => filters
)

export const getSorterPaymentsUploads = createSelector(
  paymentsUploadsState,
  ({ sorter }) => sorter
)

export const getIsDataFilteredOrSortedPaymentsUploads = createSelector(
  [getFiltersPaymentsUploads, getSorterPaymentsUploads],
  areFiltersAndSorterEmpty
)

export const getPageCountPaymentsUploads = createSelector(
  paymentsUploadsState,
  ({ pageCount }) => pageCount
)

export const getSortedIdsPaymentsUploads = createSelector(
  paymentsUploadsState,
  ({ sortedIds }) => sortedIds
)

export const getSortedPaymentsUploads = createSelector(
  [getPaymentsUploadAttempts, getSortedIdsPaymentsUploads],
  (attempts, ids) => ids.map((id) => attempts[id])
)
