import React, { useState } from 'react'
import { Avatar, Card, Divider, Typography } from 'antd'
import useAction from 'utils/useAction'
import {
  openShopEnrichedDataModal,
  openShopGroupsSettingsModal,
  openShopModal,
} from 'reducers/modals/actions'
import { EditOutlined } from '@ant-design/icons'
import CheckboxIcon from 'components/CheckboxIcon'
import {
  ActionButton,
  CardWrapper,
  Parameter,
  ParametersContainer,
} from './ShopCard.style'
import { useSelector } from 'react-redux'
import {
  getCurrentShopAttributes,
  getCurrentShopId,
} from 'domains/shops/selectors'

const { Paragraph, Title } = Typography

const ShopCard = () => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false)

  const id = useSelector(getCurrentShopId)
  const attributes = useSelector(getCurrentShopAttributes)

  const {
    description,
    logo,
    name,
    aggregated_tags_count,
    available_on_search,
    shop_page_available,
    verified,
    priority,
    aggregated_tags,
  } = attributes || {}

  const openShopModalFunc = useAction(openShopModal)
  const openShopGroupsSettingsModalFunc = useAction(openShopGroupsSettingsModal)
  const openShopEnrichedDataModalFunc = useAction(openShopEnrichedDataModal)
  const handleEditShop = () => openShopModalFunc({ id })
  const handleOpenShopGroupsSettings = () => openShopGroupsSettingsModalFunc()
  const handleOpenShopEnrichedData = () => openShopEnrichedDataModalFunc()

  const onExpandOrCollapse = () => setIsDescriptionExpanded((prev) => !prev)

  const renderDescription = () => {
    return (
      <div key={isDescriptionExpanded ? 1 : 0}>
        <Paragraph
          ellipsis={
            !isDescriptionExpanded
              ? {
                  rows: 1,
                  expandable: true,
                  onExpand: onExpandOrCollapse,
                  symbol: 'more',
                }
              : false
          }
        >
          {description}
          {isDescriptionExpanded && (
            <a onClick={onExpandOrCollapse} role="button">
              less
            </a>
          )}
        </Paragraph>
      </div>
    )
  }

  return (
    <CardWrapper>
      <Card
        actions={[
          <ActionButton key="edit" onClick={handleEditShop}>
            <EditOutlined /> Edit
          </ActionButton>,
          <ActionButton
            key="groups-categories"
            onClick={handleOpenShopGroupsSettings}
          >
            <EditOutlined /> Groups and categories settings
          </ActionButton>,
          <ActionButton
            key="enriched-data"
            onClick={handleOpenShopEnrichedData}
          >
            <EditOutlined /> Enriched data
          </ActionButton>,
        ]}
      >
        <Card.Meta
          avatar={<Avatar src={logo} size="large" />}
          title={
            <Title level={4} style={{ margin: '4px 0 0' }}>
              {name}{' '}
              <Divider
                type="vertical"
                style={{
                  height: '1.9em',
                  borderLeft: '2px solid rgba(0, 0, 0, 0.06)',
                }}
              />{' '}
              ID: {id}
              <Divider
                type="vertical"
                style={{
                  height: '1.9em',
                  borderLeft: '2px solid rgba(0, 0, 0, 0.06)',
                }}
              />{' '}
              ({aggregated_tags_count || 0} tags)
            </Title>
          }
          description={renderDescription()}
        />
        <ParametersContainer>
          <Parameter>
            Available on search:
            {<CheckboxIcon checked={available_on_search} />}
          </Parameter>
          <Divider type="vertical" style={{ height: '1.9em' }} />
          <Parameter>
            Shop page available:
            {<CheckboxIcon checked={shop_page_available} />}
          </Parameter>
          <Divider type="vertical" style={{ height: '1.9em' }} />
          <Parameter>
            Verified:
            {<CheckboxIcon checked={verified} />}
          </Parameter>

          {priority && (
            <>
              <Divider type="vertical" style={{ height: '1.9em' }} />
              <Parameter>Priority: &nbsp;{priority}</Parameter>
            </>
          )}
          {aggregated_tags && (
            <>
              <Divider type="vertical" style={{ height: '1.9em' }} />
              <Parameter>Aggregated tags: &nbsp;{aggregated_tags}</Parameter>
            </>
          )}
        </ParametersContainer>
      </Card>
    </CardWrapper>
  )
}

export default ShopCard
