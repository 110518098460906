import React from 'react'
import { useSelector } from 'react-redux'
import {
  getIsLoaded,
  getPage,
  getPageCount,
  getSortedGiveaways,
  getSearchString,
  getFilters,
  getSorter,
  getIsDataFilteredOrSorted,
} from 'domains/giveaways/selectors'
import {
  fetchGiveawaysPopups,
  deleteGiveawayPopup,
} from 'actions/views/giveawaysPopups'
import useAction from 'utils/useAction'
import { Buttons, StyledTable, Wrapper } from './Giveaways'
import getColumns from './columns'
import { getRowClassByTagID, getRowKey } from 'helpers/tables'
import { openConfirmModal, openGiveawayModal } from 'reducers/modals/actions'
import { HEADER_OFFSET, PAGE_SIZE, SCROLL } from 'const/tables'
import { Button } from 'antd'
import SearchInTableBar from 'components/SearchInTableBar'
import { FIELD_NAMES } from 'const/forms'
import { isEqual } from 'lodash'

const Giveaways = () => {
  const isLoaded = useSelector(getIsLoaded)
  const page = useSelector(getPage)
  const search = useSelector(getSearchString)
  const pageCount = useSelector(getPageCount)
  const filters = useSelector(getFilters)
  const sorter = useSelector(getSorter)

  const dataSource = useSelector(getSortedGiveaways)
  const isDataFilteredOrSorted = useSelector(getIsDataFilteredOrSorted)

  const openConfirmModalFunc = useAction(openConfirmModal)
  const openGiveawayModalFunc = useAction(openGiveawayModal)

  const fetchGiveawaysFunc = useAction(fetchGiveawaysPopups)
  const deleteGiveawayFunc = useAction(deleteGiveawayPopup)

  const handleEditGiveaway = (id) => openGiveawayModalFunc({ id })
  const handleCreateGiveaway = () => openGiveawayModalFunc()
  const handleDeleteGiveaway = (product) => {
    const callback = () => deleteGiveawayFunc(product[FIELD_NAMES.id])
    const text =
      `Are you sure you want to delete giveaway popup ${
        product[FIELD_NAMES.name]
      } (ID: ${product[FIELD_NAMES.id]})? ` + "This action can't be revoked."
    openConfirmModalFunc({ callback, text })
  }

  const onSearchStringChange = (search) =>
    fetchGiveawaysFunc({
      page: 1,
      search,
      filters,
      sorter,
    })

  const onTableChange = (pagination, newFilters, newSorter) => {
    const pageChanged = page !== pagination.current
    const newFiltersOrSorter =
      !pageChanged &&
      (!isEqual(newFilters, filters) || !isEqual(newSorter, sorter))
    fetchGiveawaysFunc({
      page: newFiltersOrSorter ? 1 : pagination.current,
      search,
      filters: newFilters,
      sorter: newSorter,
    })
  }

  const handleResetFilters = () =>
    fetchGiveawaysFunc({
      search,
      page: 1,
      filters: {},
      sorter: {},
    })

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  return (
    <Wrapper>
      <SearchInTableBar
        placeholder="Search by name and retailer"
        onSubmit={onSearchStringChange}
        value={search}
      >
        <Buttons>
          <Button
            onClick={handleCreateGiveaway}
            type="primary"
            disabled={!isLoaded}
          >
            Create
          </Button>
          <Button
            onClick={handleResetFilters}
            disabled={!isLoaded || !isDataFilteredOrSorted}
          >
            Reset filters
          </Button>
        </Buttons>
      </SearchInTableBar>
      <StyledTable
        onChange={onTableChange}
        showSorterTooltip={false}
        columns={getColumns(
          filters,
          sorter,
          handleEditGiveaway,
          handleDeleteGiveaway
        )}
        dataSource={dataSource}
        expandable={false}
        pagination={paginationConfig}
        sticky={isLoaded && HEADER_OFFSET}
        scroll={SCROLL}
        bordered
        loading={false}
        rowKey={getRowKey}
        rowClassName={getRowClassByTagID}
      />
    </Wrapper>
  )
}

export default Giveaways
