import styled from 'styled-components'
import { InboxOutlined } from '@ant-design/icons'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Text = styled.span`
  font-size: 16px;
`

export const UploadingIcon = styled(InboxOutlined)`
  color: #40a9ff;
  font-size: 48px;
`
