import React from 'react'
import { StyledTextArea } from './Comment.styles'
import { REQUIRED } from 'const/errors'
import PropTypes from 'prop-types'

const CommentInput = ({ input, meta, ...rest }) => (
  <StyledTextArea
    invalid={meta.modified && meta.error === REQUIRED}
    value={input.value}
    onChange={input.onChange}
    {...rest}
  />
)

CommentInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    modified: PropTypes.bool,
  }).isRequired,
}

export default CommentInput
