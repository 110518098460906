import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { StyledAvatar } from './RegionalAvatar.styles'
import { COUNTRY_CODE_TO_NAME } from 'const/country'

const RegionalAvatar = ({ country, countryCode, useNameInTooltip }) => {
  const countryName = `${COUNTRY_CODE_TO_NAME[countryCode]} (${countryCode})`
  return (
    <Tooltip title={useNameInTooltip ? countryName : countryCode}>
      <StyledAvatar shape="square" alt={countryCode} src={country} />
    </Tooltip>
  )
}

RegionalAvatar.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  useNameInTooltip: PropTypes.bool,
  country: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
}

RegionalAvatar.defaultProps = {
  useNameInTooltip: false,
}

export default RegionalAvatar
