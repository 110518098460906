import styled from 'styled-components'
import { Layout } from 'antd'
import { Link } from 'react-router-dom'

export const Wrapper = styled(Layout.Content)`
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Image = styled.img`
  width: 370px;
  height: 370px;
  object-fit: contain;
`

export const Text = styled.h1`
  margin-top: -80px;
  margin-bottom: 0;
  font-size: 50px;
  font-weight: 700;
  color: #338cee;
`

export const SubText = styled.h3`
  font-size: 20px;
  font-weight: 300;
`

export const BackButton = styled(Link)`
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  outline: none;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 10px;
  color: rgb(10, 58, 235);
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 25%) 0 20px 40px 0;
`
