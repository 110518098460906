import React, { useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import ReactDragListView from 'react-drag-listview'
import ListItem from './ListItem'
import { groupPropType } from 'const/propTypes'
import { ListWrapper, SaveButton } from './ListSort.styles'
import useAction from 'utils/useAction'
import {
  actions as generalActions,
  selectors as generalSelectors,
  formatters as generalFormatters,
  helpers as generalHelpers,
} from 'domains/general'

const ListSort = ({ data, onClick, selected }) => {
  const [internalData, setInternalData] = useState(data)
  const updateGroupPositionsFunc = useAction(
    generalActions.updateGroupPositions
  )
  const isLoaded = useSelector(generalSelectors.isLoadedSelector)

  const handleDragEnd = (fromIndex, toIndex) => {
    const orderedData = [...internalData]
    const item = orderedData.splice(fromIndex, 1)[0]
    orderedData.splice(toIndex, 0, item)
    setInternalData(orderedData)
  }

  //TODO: move formatting to the view's saga
  const handleUpdatePositions = () =>
    updateGroupPositionsFunc(
      generalFormatters.formatNewGroupOrder(internalData || [])
    )

  const orderChanged = !isEqual(data, internalData)

  useEffect(() => {
    if (internalData.length !== data.length) {
      setInternalData(generalHelpers.getUpdatedGroupData(internalData, data))
    }
  }, [data, internalData])

  useEffect(() => {
    if (isLoaded) {
      setInternalData(data)
    }
  }, [isLoaded, data])

  return (
    <ListWrapper>
      <ReactDragListView
        onDragEnd={handleDragEnd}
        nodeSelector="li"
        handleSelector="span"
        lineClassName="line"
        ignoreSelector="div"
      >
        {internalData.map((item, index) => (
          <ListItem
            index={index + 1}
            key={item.id}
            data={item}
            onClick={onClick}
            isSelected={selected === item.attributes.name}
          />
        ))}
      </ReactDragListView>
      {orderChanged && (
        <SaveButton onClick={handleUpdatePositions} type="primary">
          Save group order
        </SaveButton>
      )}
    </ListWrapper>
  )
}

ListSort.propTypes = {
  data: PropTypes.arrayOf(groupPropType).isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.string,
}

ListSort.defaultProps = {
  selected: null,
}

export default ListSort
