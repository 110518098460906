import React from 'react'
import Input, { TextArea } from 'components/styled/InputWithInvalidState'
import { MinusCircleOutlined } from '@ant-design/icons'
import { ENTITY_ATTRS_SUBFIELDS } from 'const/api'
import {
  getRequiredFieldError,
  INVALID_LINK,
  INVALID_MOBILE_LINK,
} from 'const/errors'

const autoSize = { minRows: 2, maxRows: 4 }

const invalidQuestion = getRequiredFieldError('question')
const invalidAnswer = getRequiredFieldError('answer')
const invalidLink = getRequiredFieldError('link')

const FieldFAQ = ({ input, removeHandler, meta }) => {
  const changeHandler = (key, event) =>
    input.onChange({ ...input.value, [key]: event.target.value })

  return (
    <div className="faq-item">
      <Input
        invalid={meta.error === invalidQuestion}
        value={input.value[ENTITY_ATTRS_SUBFIELDS.question]}
        placeholder="Question"
        onChange={(...args) =>
          changeHandler(ENTITY_ATTRS_SUBFIELDS.question, ...args)
        }
      />

      <TextArea
        invalid={meta.error === invalidAnswer}
        value={input.value[ENTITY_ATTRS_SUBFIELDS.answer]}
        placeholder="Answer"
        onChange={(...args) =>
          changeHandler(ENTITY_ATTRS_SUBFIELDS.answer, ...args)
        }
        autoSize={autoSize}
      />

      <Input
        invalid={[INVALID_LINK, invalidLink].includes(meta.error)}
        value={input.value[ENTITY_ATTRS_SUBFIELDS.link]}
        placeholder="Link"
        onChange={(...args) =>
          changeHandler(ENTITY_ATTRS_SUBFIELDS.link, ...args)
        }
      />

      <Input
        invalid={meta.error === INVALID_MOBILE_LINK}
        value={input.value[ENTITY_ATTRS_SUBFIELDS.mobileLink]}
        placeholder="Mobile Link"
        onChange={(...args) =>
          changeHandler(ENTITY_ATTRS_SUBFIELDS.mobileLink, ...args)
        }
      />

      <MinusCircleOutlined onClick={removeHandler} />
    </div>
  )
}

export default FieldFAQ
