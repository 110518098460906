import React from 'react'
import { useSelector } from 'react-redux'
import { selectors as userSelectors } from 'domains/user'
import { Wrapper, Title, StyledCollapse } from './CustomerService.styles'
import { FIELD_NAMES } from 'const/forms'
import PaymentTransactions from './PaymentTransactions'
import UserForm from './UserForm'
import EngagementMetrics from './EngagementMetrics'

export const SCROLL = { x: 1200 }
export const KEYS = {
  PAYMENT: 'payment',
  KARMA_PAY: 'karma_pay',
  ENGAGEMENT_METRICS: 'engagement_metrics',
}

const DEFAULT_ACTIVE_KEYS = [
  KEYS.PAYMENT,
  KEYS.KARMA_PAY,
  KEYS.ENGAGEMENT_METRICS,
]

const CustomerService = () => {
  const selectedEmail = useSelector(userSelectors.selectedEmail)
  const search = useSelector(userSelectors.search)
  const initialValues = {
    [FIELD_NAMES.email]: selectedEmail,
    [FIELD_NAMES.search]: search,
  }

  return (
    <Wrapper>
      <UserForm initialValues={initialValues} />
      <StyledCollapse defaultActiveKey={DEFAULT_ACTIVE_KEYS}>
        <StyledCollapse.Panel
          header={<Title>Karma Cash Transactions</Title>}
          key={KEYS.PAYMENT}
        >
          <PaymentTransactions />
        </StyledCollapse.Panel>

        <StyledCollapse.Panel
          header={<Title>Engagement Matrix</Title>}
          key={KEYS.ENGAGEMENT_METRICS}
        >
          <EngagementMetrics />
        </StyledCollapse.Panel>
      </StyledCollapse>
    </Wrapper>
  )
}

export default CustomerService
