import React from 'react'
import { Col, Layout, Row, Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import useAction from 'utils/useAction'
import { FIELD_NAMES } from 'const/forms'
import { required, validUserEmail } from 'helpers/validators'
import { selectors as userSelectors } from 'domains/user'
import TextInput from 'components/TextInput'
import Title from 'components/Title/Title'
import { Buttons, StyledButton } from './User.styles'
import { MEMBER } from 'const/roles'
import * as actions from 'actions/views/users'

const User = ({ id, closeModal }) => {
  const isSavingActive = useSelector(userSelectors.getIsSavingActive)
  const userData = useSelector(userSelectors.getUserById)(id)
  const createUserFunc = useAction(actions.createUser)
  const updateUserFunc = useAction(actions.updateUser)

  const initialValues = id
    ? userData
    : {
        [FIELD_NAMES.role]: MEMBER,
      }

  const onSubmit = (values) => {
    if (id) {
      updateUserFunc({ values, callback: closeModal })
    } else {
      createUserFunc({ values, callback: closeModal })
    }
  }
  return (
    <Layout.Content>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ hasValidationErrors, handleSubmit }) => {
          return (
            <Spin spinning={isSavingActive} size="large">
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Title>Name</Title>
                  <Field
                    validate={required}
                    component={TextInput}
                    name={FIELD_NAMES.name}
                  />
                </Col>
                <Col span={12}>
                  <Title>Email</Title>
                  <Field
                    disabled={userData.isGuest}
                    validate={validUserEmail}
                    component={TextInput}
                    name={FIELD_NAMES.email}
                  />
                </Col>
                {/* <Col span={12}>
                  <Title>Phone</Title>
                  <Field
                    component={TextInput}
                    name={FIELD_NAMES.phone}
                  />
                </Col> */}
              </Row>
              <Buttons>
                <StyledButton size="default" onClick={closeModal}>
                  Cancel
                </StyledButton>

                <StyledButton
                  disabled={hasValidationErrors}
                  size="default"
                  type="primary"
                  onClick={handleSubmit}
                >
                  Save
                </StyledButton>
              </Buttons>
            </Spin>
          )
        }}
      />
    </Layout.Content>
  )
}

export default User
