import { all, put, take, takeLatest, race, select } from 'redux-saga/effects'
import { startLoading, finishLoading } from 'reducers/layoutSlice'
import {
  actions as campaignsActions,
  formatters as campaignsFormatters,
  selectors as campaignsSelectors,
} from 'domains/campaigns'
import * as actions from 'actions/views/campaign'
import { FIELD_NAMES } from 'const/forms'
import { getCurrentShopId } from 'domains/shops/selectors'
import { fetchShopCampaignDeals } from 'actions/views/shops'

function* fetchCampaignsWorker({ payload } = {}) {
  try {
    const search = yield select(campaignsSelectors.getSearchString)
    const body = campaignsFormatters.formatPayloadForFetchRequest({
      search,
      ...payload,
    })
    yield all([put(startLoading()), put(campaignsActions.fetchCampaigns(body))])
    const [success] = yield race([take(campaignsActions.fetchCampaignsSuccess)])
    if (success) {
      yield put(campaignsActions.saveFiltersAndSorter(payload))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* createCampaignWorker({ payload }) {
  try {
    const body = campaignsFormatters.formatCampaignForRequests(payload.data)
    yield all([
      put(campaignsActions.startSaving()),
      put(campaignsActions.createCampaign(body)),
    ])
    const [success] = yield race([
      take(campaignsActions.createCampaignSuccess),
      take(campaignsActions.createCampaignFailure),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(campaignsSelectors.getSearchString),
        select(campaignsSelectors.getPage),
        select(campaignsSelectors.getFilters),
        select(campaignsSelectors.getSorter),
      ])
      yield put(
        actions.fetchCampaigns({
          search,
          page,
          filters,
          sorter,
        })
      )
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(campaignsActions.finishSaving())
  }
}

function* updateCampaignWorker({ payload }) {
  try {
    const body = campaignsFormatters.formatCampaignForRequests(payload.data)
    yield all([
      put(campaignsActions.startSaving()),
      put(
        campaignsActions.updateCampaign({
          data: body,
          id: payload.data[FIELD_NAMES.id],
        })
      ),
    ])
    const [success] = yield race([
      take(campaignsActions.updateCampaignSuccess),
      take(campaignsActions.updateCampaignFailure),
    ])

    if (success) {
      const [search, page, filters, sorter, currentShopId] = yield all([
        select(campaignsSelectors.getSearchString),
        select(campaignsSelectors.getPage),
        select(campaignsSelectors.getFilters),
        select(campaignsSelectors.getSorter),
        select(getCurrentShopId),
      ])
      yield put(
        actions.fetchCampaigns({
          search,
          page,
          filters,
          sorter,
        })
      )
      if (currentShopId) {
        yield put(fetchShopCampaignDeals({ currentShopId, filters, sorter }))
      }
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(campaignsActions.finishSaving())
  }
}

function* deleteCampaignWorker({ payload }) {
  try {
    yield all([
      put(startLoading()),
      put(campaignsActions.deleteCampaign(payload)),
    ])
    const [success] = yield race([
      take(campaignsActions.deleteCampaignSuccess),
      take(campaignsActions.deleteCampaignFailure),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(campaignsSelectors.getSearchString),
        select(campaignsSelectors.getPage),
        select(campaignsSelectors.getFilters),
        select(campaignsSelectors.getSorter),
      ])
      yield put(
        actions.fetchCampaigns({
          search,
          page,
          filters,
          sorter,
        })
      )
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

export default function* () {
  yield all([
    fetchCampaignsWorker(),
    takeLatest(actions.fetchCampaigns, fetchCampaignsWorker),
    takeLatest(actions.createCampaign, createCampaignWorker),
    takeLatest(actions.updateCampaign, updateCampaignWorker),
    takeLatest(actions.deleteCampaign, deleteCampaignWorker),
  ])
}
