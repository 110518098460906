import { FIELD_NAMES } from 'const/forms'

export const PAYMENT_COLUMN_KEY_TO_TITLE = {
  [FIELD_NAMES.id]: 'ID',
  [FIELD_NAMES.userId]: 'User ID',
  [FIELD_NAMES.userLocation]: 'User location',
  [FIELD_NAMES.commissionValue]: 'Commission value',
  [FIELD_NAMES.commissionOrderValue]: 'Order value',
  [FIELD_NAMES.kcValue]: 'KC value',
  [FIELD_NAMES.retailerId]: 'Retailer ID',
  [FIELD_NAMES.retailerName]: 'Retailer Name',
  [FIELD_NAMES.orderConfirmationNumber]: 'Order Confirmation Number',
  [FIELD_NAMES.tToken]: 'Transaction token',
  [FIELD_NAMES.processedAt]: 'Processed at',
  [FIELD_NAMES.paymentDate]: 'Payment date',
  [FIELD_NAMES.walletStatus]: 'Wallet status',
  [FIELD_NAMES.countdownDate]: 'Countdown date',
  // [FIELD_NAMES.commissionId]: 'Commission ID',
  // [FIELD_NAMES.network]: 'Network',
  // [FIELD_NAMES.ruleId]: 'Rule ID',
  // [FIELD_NAMES.clickId]: 'Click ID',
  // [FIELD_NAMES.status]: 'Status',
  // [FIELD_NAMES.kcPercentage]: 'KC percentage',
}
