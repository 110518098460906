import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { route } from 'selectors/routing'
import { ROUTE_TO_LABEL } from 'const/routes'
import { selectors as adminSelectors } from 'domains/admin'

const PageTitle = ({ title }) => {
  const currentRoute = useSelector(route)
  const isLogged = useSelector(adminSelectors.isLogged)
  const pageTitle = title || ROUTE_TO_LABEL[currentRoute]
  return (
    <Helmet>
      <title>{`Karma ${
        isLogged && pageTitle ? `| ${pageTitle}` : 'backoffice'
      }`}</title>
    </Helmet>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string,
}

PageTitle.defaultProps = {
  title: null,
}

export default PageTitle
