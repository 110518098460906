import { push } from 'react-router-redux'
import {
  all,
  put,
  race,
  select,
  take,
  takeLatest,
  call,
} from 'redux-saga/effects'
import * as actions from 'actions/views/roles'
import * as layoutActions from 'actions/layout'
import {
  actions as adminActions,
  selectors as adminSelectors,
  formatters as adminFormatters,
} from 'domains/admin'
import { finishLoading, startLoading } from 'reducers/layoutSlice'
import { FIELD_NAMES } from 'const/forms'
import ROUTES from 'const/routes'
import ROLES from 'const/roles'
import * as helpers from '../../domains/admin/helpers'

function* getAdmins() {
  const [search, page, filters, sorter] = yield all([
    select(adminSelectors.getSearchString),
    select(adminSelectors.getPage),
    select(adminSelectors.getFilters),
    select(adminSelectors.getSorter),
  ])
  yield put(
    actions.fetchAllAdmins({
      search,
      page,
      filters,
      sorter,
    })
  )
}

function* getAdminsWorker({ payload } = {}) {
  try {
    const id = yield select(adminSelectors.adminId)
    if (id) {
      const search = yield select(adminSelectors.getSearchString)
      const body = adminFormatters.formatPayloadForFetchRequest({
        search,
        ...payload,
      })
      yield all([put(startLoading()), put(adminActions.fetchAllAdmins(body))])
      const [success] = yield race([
        take(adminActions.fetchAllAdminsSuccess),
        take(adminActions.fetchAllAdminsFailure),
      ])
      if (success) {
        yield put(adminActions.saveAdminFiltersAndSorter(payload))
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* updateAdminWorker({ payload }) {
  try {
    const adminId = yield select(adminSelectors.adminId)
    const currentUserId = payload.values[FIELD_NAMES.id]
    const body = adminFormatters.formatAdminForRequests(payload.values)
    yield all([
      put(adminActions.startSaving()),
      put(
        adminActions.updateAdmin({
          data: body,
          id: currentUserId,
        })
      ),
    ])

    const [success] = yield race([
      take(adminActions.updateAdminSuccess),
      take(adminActions.updateAdminFailure),
    ])

    if (success) {
      const newRole = success.payload.data.attributes[FIELD_NAMES.role]
      if (currentUserId === adminId) {
        yield put(
          adminActions.login(adminFormatters.formatAdminData(success.payload))
        )
        localStorage.setItem('role', newRole)

        if (newRole !== ROLES.ADMIN) {
          yield put(push(ROUTES.HOME))
        }
      }
      yield call(getAdmins)
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(adminActions.finishSaving())
  }
}

function* createAdminWorker({ payload }) {
  try {
    const body = adminFormatters.formatAdminForRequests(payload.values)
    yield all([
      put(adminActions.startSaving()),
      put(adminActions.createAdmin(body)),
    ])
    const [success] = yield race([
      take(adminActions.createAdminSuccess),
      take(adminActions.createAdminFailure),
    ])

    if (success) {
      yield call(getAdmins)
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(adminActions.finishSaving())
  }
}

function* deleteAdminWorker({ payload }) {
  try {
    const adminId = yield select(adminSelectors.adminId)
    yield all([put(startLoading()), put(adminActions.deleteAdmin(payload))])
    const [success] = yield race([
      take(adminActions.deleteAdminSuccess),
      take(adminActions.deleteAdminFailure),
    ])

    if (success) {
      if (adminId === payload) {
        yield put(layoutActions.clearData())
        helpers.clearUserDataInLS()
      } else {
        yield call(getAdmins)
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* resetPassword({ payload }) {
  try {
    const body = adminFormatters.formatPasswordResetForRequests(payload.values)

    yield all([
      put(adminActions.startSaving()),
      put(adminActions.resetPassword(body)),
    ])

    const [success] = yield race([
      take(adminActions.resetPasswordSuccess),
      take(adminActions.resetPasswordFailure),
    ])

    if (success) {
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(adminActions.finishSaving())
  }
}

export default function* () {
  yield all([
    getAdminsWorker(),
    takeLatest(actions.fetchAllAdmins, getAdminsWorker),
    takeLatest(actions.deleteAdmin, deleteAdminWorker),
    takeLatest(actions.updateAdmin, updateAdminWorker),
    takeLatest(actions.createAdmin, createAdminWorker),
    takeLatest(actions.resetPassword, resetPassword),
  ])
}
