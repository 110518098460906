import map from 'lodash/map'
import { FIELD_NAMES } from 'const/forms'
import { mapFilters, mapSorters } from 'helpers/jsonAPI'
import reduce from 'lodash/reduce'

const formatMarketingPagesUrls = (pageUrl) => ({
  [FIELD_NAMES.id]: pageUrl.attributes[FIELD_NAMES.id],
  [FIELD_NAMES.url]: pageUrl.attributes[FIELD_NAMES.url],
  [FIELD_NAMES.trafficPercentage]:
    ~~pageUrl.attributes[FIELD_NAMES.trafficPercentage],
})

export const formatMarketingPage = (marketingPage) => ({
  [FIELD_NAMES.id]: marketingPage[FIELD_NAMES.id],
  [FIELD_NAMES.url]: marketingPage.attributes[FIELD_NAMES.url],
  [FIELD_NAMES.finalUrl]: marketingPage.attributes[FIELD_NAMES.finalUrl],
  [FIELD_NAMES.marketingPagesUrls]: map(
    marketingPage[FIELD_NAMES.marketingPagesUrls] || [],
    formatMarketingPagesUrls
  ),
  key: marketingPage[FIELD_NAMES.id],
})

export const formatMarketingPages = (marketingPages = {}) =>
  Object.values(marketingPages).reduce(
    (result, marketingPage) => ({
      ...result,
      [marketingPage[FIELD_NAMES.id]]: formatMarketingPage(marketingPage),
    }),
    {}
  ) || {}

export const formatPayloadForFetchRequest = ({
  page,
  filters,
  sorter,
  search,
} = {}) => ({
  page: page || 1,
  filters: mapFilters(filters),
  sorter: mapSorters(sorter),
  search,
})

export const formatMarketingPageForRequests = (values) => ({
  [FIELD_NAMES.id]: values[FIELD_NAMES.id],
  [FIELD_NAMES.marketingPagesUrls]: values[FIELD_NAMES.marketingPagesUrls].map(
    (item) => ({
      [FIELD_NAMES.url]: item[FIELD_NAMES.url],
      [FIELD_NAMES.trafficPercentage]: item[FIELD_NAMES.trafficPercentage],
    })
  ),
})

export const formatMarketingPagesResponse = (data) =>
  reduce(
    data,
    (result, item) => ({
      ...result,
      [item[FIELD_NAMES.id]]: item,
    }),
    {}
  )
