import styled, { css } from 'styled-components'
import { Select } from 'antd'

const invalid = (props) => {
  if (props.invalid) {
    return css`
      border-color: rgb(255, 0, 0, 1);
      box-shadow: none;

      &:hover,
      &:focus {
        border-color: rgb(255, 0, 0, 1);
        box-shadow: 0 0 0 2px rgb(255, 0, 0, 0.5);
      }
    `
  }
}

export const StyledSelect = styled(Select)`
  border-radius: 2px;
  ${invalid}
`
