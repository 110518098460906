import React from 'react'
import { Select as AntSelect } from 'antd'
import { StyledSelect } from './Select.styles'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'

const Select = ({
  input,
  meta,
  label,
  placeholder,
  defaultValue,
  options,
  hint,
  required,
  ...rest
}) => (
  <FieldWrapper hint={hint} label={label} meta={meta} required={required}>
    <StyledSelect
      invalid={meta.touched && meta.error}
      placeholder={placeholder}
      defaultValue={defaultValue}
      {...rest}
      {...input}
    >
      {options.map((option) => (
        <AntSelect.Option key={option.value} value={option.value}>
          {option.label}
        </AntSelect.Option>
      ))}
    </StyledSelect>
  </FieldWrapper>
)

Select.defaultProps = {
  options: [],
}

export default Select
