import React from 'react'
import PropTypes from 'prop-types'
import { Buttons, StyledButton, Text } from './Info.styles'

const Info = ({ closeModal, text, description }) => (
  <>
    <Text>{text}</Text>
    {description && <Text>{description}</Text>}
    <Buttons>
      <StyledButton onClick={closeModal} type="primary">
        Ok
      </StyledButton>
    </Buttons>
  </>
)

Info.propTypes = {
  text: PropTypes.string,
  description: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
}

Info.defaultProps = {
  text: '',
  description: null,
}

export default Info
