import { all, put, take, takeLatest, race } from 'redux-saga/effects'

import { startLoading, finishLoading } from 'reducers/layoutSlice'
import { actions as pitaSettingsActions } from 'domains/pitaSettings'
import * as actions from 'actions/views/pitaPage'

function* fetchPitaSettingsWorker() {
  try {
    yield all([
      put(startLoading()),
      put(pitaSettingsActions.fetchPitaSettings()),
    ])
    yield race([
      take(pitaSettingsActions.fetchPitaSettingsSuccess),
      take(pitaSettingsActions.fetchPitaSettingsFailure),
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* updatePitaMaxWorker({ payload }) {
  try {
    yield all([
      put(pitaSettingsActions.startSaving()),
      put(pitaSettingsActions.updatePitaMax(payload)),
    ])
    const [success] = yield all([
      race([
        take(pitaSettingsActions.updatePitaMaxSuccess),
        take(pitaSettingsActions.updatePitaMaxFailure),
      ]),
    ])

    if (success) {
      yield put(pitaSettingsActions.fetchPitaSettings())
      yield race([
        take(pitaSettingsActions.fetchPitaSettingsSuccess),
        take(pitaSettingsActions.fetchPitaSettingsFailure),
      ])
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(pitaSettingsActions.finishSaving())
  }
}

function* uploadPita({ payload }) {
  try {
    yield all([
      put(pitaSettingsActions.startSaving()),
      put(pitaSettingsActions.uploadPita(payload)),
    ])
    const [success] = yield all([
      race([
        take(pitaSettingsActions.uploadPitaFailure),
        take(pitaSettingsActions.uploadPitaSuccess),
      ]),
    ])

    if (success) {
      yield put(pitaSettingsActions.fetchPitaSettings())
      yield race([
        take(pitaSettingsActions.fetchPitaSettingsSuccess),
        take(pitaSettingsActions.fetchPitaSettingsFailure),
      ])
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(pitaSettingsActions.finishSaving())
  }
}

export default function* () {
  yield all([
    fetchPitaSettingsWorker(),
    takeLatest(actions.fetchPitaSettings, fetchPitaSettingsWorker),
    takeLatest(actions.updatePitaMax, updatePitaMaxWorker),
    takeLatest(actions.uploadPita, uploadPita),
  ])
}
