import styled from 'styled-components'
import { Button, Layout, Table } from 'antd'

export const Wrapper = styled(Layout.Content)`
  min-height: calc(100vh - 64px);
`

export const StyledTable = styled(Table)`
  .ant-table-title {
    padding: 0;
  }
`

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledButton = styled(Button)`
  width: 80px;
  &:first-child {
    margin-right: 10px;
  }
`

export const Buttons = styled.div`
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #ffffff;
  & > button:first-child {
    margin-right: 10px;
  }
`
