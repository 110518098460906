import { all, put, select, takeLatest, race, take } from 'redux-saga/effects'

import {
  actions as retailersActions,
  selectors as retailersSelectors,
} from 'domains/retailers'
import { selectors as adminSelectors } from 'domains/admin'
import { route } from 'selectors/routing'
import ROUTES from 'const/routes'
import * as actions from 'actions/views/retailers'
import { finishLoading, startLoading } from 'reducers/layoutSlice'
import { FIELD_NAMES } from 'const/forms'

function* getRetailersWorker() {
  const [token, currentRoute, search] = yield all([
    select(adminSelectors.token),
    select(route),
    select(retailersSelectors.getSearchString),
  ])
  if (token) {
    yield put(retailersActions.resetFiltersAndSorter())
    yield put(
      retailersActions.fetchMainRetailers({
        search,
        withCategories: currentRoute === ROUTES.EDITORIAL,
        deleted: currentRoute === ROUTES.DELETED_STORES,
      })
    )
  }
}

function* syncImagesWorker({ payload }) {
  try {
    const retailerId = payload[FIELD_NAMES.id]
    yield all([
      put(startLoading()),
      put(retailersActions.syncImages(retailerId)),
    ])
    const [success] = yield race([
      take(retailersActions.syncImagesSuccess),
      take(retailersActions.syncImagesFailure),
    ])
    if (success) {
      yield put(retailersActions.getRetailerImagesById({ id: retailerId }))
      yield take(retailersActions.updateNormalizedRetailers)
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* copyImagesWorker({ payload }) {
  try {
    const { [FIELD_NAMES.id]: id, ...data } = payload
    yield all([
      put(startLoading()),
      put(
        retailersActions.copyImages({
          id,
          data,
        })
      ),
    ])
    const [success] = yield race([
      take(retailersActions.copyImagesSuccess),
      take(retailersActions.copyImagesFailure),
    ])
    if (success) {
      yield put(retailersActions.getRetailerImagesById({ id }))
      yield take(retailersActions.updateNormalizedRetailers)
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* refreshImagesWorker({ payload }) {
  try {
    yield all([
      yield put(retailersActions.changeFlagReinitialize(false)),
      yield put(retailersActions.startLoading()),
      yield put(retailersActions.getRetailerImagesById(payload)),
    ])
    yield take([
      retailersActions.updateNormalizedRetailers,
      retailersActions.getRetailerImagesByIdFailure,
    ])
    yield put(retailersActions.changeFlagReinitialize(true))
  } catch (error) {
    console.error(error)
  } finally {
    yield put(retailersActions.finishLoading())
  }
}

export default function* () {
  yield all([
    getRetailersWorker(),
    takeLatest(actions.syncImages, syncImagesWorker),
    takeLatest(actions.copyImages, copyImagesWorker),
    takeLatest(actions.refreshImages, refreshImagesWorker),
  ])
}
