import { FIELD_NAMES } from 'const/forms'
import { getStyle } from 'helpers/colorGenerator'
import map from 'lodash/map'

export const getRowKey = (record) => record[FIELD_NAMES.id]

export const getRowClass = (record) => getStyle(record[FIELD_NAMES.mainId])
export const getRowClassByName = (record) => getStyle(record[FIELD_NAMES.name])
export const getRowProgramId = (record) =>
  record[FIELD_NAMES.programId] +
  record[FIELD_NAMES.ruleId] +
  record[FIELD_NAMES.domain]

export const getRegularRowClass = (record) => getStyle(record[FIELD_NAMES.id])
export const getRowClassByRetailerID = (record) =>
  getStyle(record[FIELD_NAMES.retailerId])
export const getRowClassByTagID = (record) =>
  getStyle(record[FIELD_NAMES.tagId])

export const getColumnFilters = (values, textMapper) =>
  map(values, (value) => ({
    text: textMapper ? textMapper[value] : value,
    value,
  }))

export const includingFilter = (key) => (query, record) =>
  record[key]?.toString().toLowerCase().includes(query.toLowerCase())
