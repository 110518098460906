import { createSlice } from '@reduxjs/toolkit'

const initialStateForCollections = {
  sortedIds: [],
  entities: {},
  filters: {},
  sorter: {},
  isLoaded: false,
  isSavingActive: false,
  search: '',
  page: 1,
  pageCount: 1,
}

const initialState = {
  budget: {
    monthly_budget: 0,
    remaining_budget: 0,
  },
  payments: initialStateForCollections,
  paymentsUploads: initialStateForCollections,
}

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    fetchBudgetSuccess: (state, { payload }) => ({
      ...state,
      budget: payload,
    }),
    fetchBudgetFailure: (state) => ({
      ...state,
      budget: initialState.budget,
    }),
    startSaving: (state) => ({
      ...state,
      payments: {
        ...state.payments,
        isSavingActive: true,
      },
    }),
    finishSaving: (state) => ({
      ...state,
      payments: {
        ...state.payments,
        isSavingActive: false,
      },
    }),
    fetchPaymentUploadsSuccess: (state, { payload }) => ({
      ...state,
      paymentsUploads: {
        ...state.paymentsUploads,
        isLoaded: true,
        page: payload.page,
        pageCount: payload.pageCount,
        entities: payload.paymentUploads,
        sortedIds: payload.sortedIds,
      },
    }),
    fetchPaymentUploadsFailure: (state) => state,
    fetchPaymentsSuccess: (state, { payload }) => ({
      ...state,
      payments: {
        ...state.payments,
        isLoaded: true,
        page: payload.page,
        pageCount: payload.pageCount,
        entities: payload.payments,
        sortedIds: payload.sortedIds,
      },
    }),
    fetchPaymentsFailure: (state) => state,
    resetFiltersAndSorterPaymentsUploads: (state) => ({
      ...state,
      paymentsUploads: {
        ...state.paymentsUploads,
        page: initialStateForCollections.page,
        filters: initialStateForCollections.filters,
        sorter: initialStateForCollections.sorter,
      },
    }),
    saveFiltersAndSorterPaymentsUploads: (state, { payload = {} }) => ({
      ...state,
      paymentsUploads: {
        ...state.paymentsUploads,
        search: payload.search,
        filters: payload.filters || initialStateForCollections.filters,
        sorter: payload.sorter || initialStateForCollections.sorter,
      },
    }),
    resetFiltersAndSorterPayments: (state) => ({
      ...state,
      payments: {
        ...state.payments,
        page: initialStateForCollections.page,
        filters: initialStateForCollections.filters,
        sorter: initialStateForCollections.sorter,
      },
    }),
    saveFiltersAndSorterPayments: (state, { payload = {} }) => ({
      ...state,
      payments: {
        ...state.payments,
        search: payload.search,
        filters: payload.filters || initialStateForCollections.filters,
        sorter: payload.sorter || initialStateForCollections.sorter,
      },
    }),
    clearData: () => initialState,
  },
})

export default paymentsSlice
