/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Table, Button } from 'antd'
import { getTransactionsColumns } from '../columns'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import Pagination from 'components/Pagination'
import DynamicColumns from 'components/DynamicColumns'
import { StyledRow } from './PaymentTransactions.styles'
import { SCROLL } from '../index'
import { useSelector } from 'react-redux'
import { selectors as userSelectors } from 'domains/user'
import {
  selectors as adminSelectors,
  helpers as adminHelpers,
} from 'domains/admin'
import useAction from 'utils/useAction'
import * as actions from 'actions/views/customerService'
import { isEmpty, isEqual } from 'lodash'

const PaymentTransactions = () => {
  const isLoading = useSelector(userSelectors.transactionsLoading)
  const settings = useSelector(adminSelectors.paymentColumns)
  const selected = adminHelpers.getDisplayedColumnsKeys(settings)
  const [selectedColumns, setSelectedColumns] = useState(
    adminHelpers.getDisplayedColumnsKeys(selected)
  )

  const selectedKeys = Object.keys(selectedColumns)

  const isConfigChanged = adminHelpers.getIsConfigChanged(
    selected,
    selectedColumns
  )

  const transactions = useSelector(userSelectors.transactions)
  const transactionPagination = useSelector(
    userSelectors.transactionsPagination
  )

  const fetchUserPaymentTransactionsFunc = useAction(
    actions.fetchUserPaymentTransactions
  )
  const updateUserPreferencesFunc = useAction(actions.updateUserPreferences)

  const handleSaveSettings = () =>
    updateUserPreferencesFunc(adminHelpers.getNewConfig(selectedColumns))

  const handleChangePage = (callback) => (page) => callback(page)

  useEffect(() => {
    if (!isEqual(selected, selectedColumns)) {
      setSelectedColumns(selected)
    }
  }, [settings])

  return (
    <>
      <StyledRow>
        <DynamicColumns
          options={settings}
          onChange={setSelectedColumns}
          selectedOptions={selectedColumns}
        />
        <Button
          type="primary"
          disabled={!isConfigChanged}
          onClick={handleSaveSettings}
        >
          Save displayed columns
        </Button>
      </StyledRow>
      <Table
        sticky
        scroll={SCROLL}
        loading={isLoading}
        pagination={false}
        dataSource={!isEmpty(selectedColumns) ? transactions : []}
        columns={getTransactionsColumns(selectedKeys)}
        rowKey={getRowKey}
        rowClassName={getRegularRowClass}
      />
      <Pagination
        page={transactionPagination.page}
        lastPage={transactionPagination.pageCount}
        onChange={handleChangePage(fetchUserPaymentTransactionsFunc)}
      />
    </>
  )
}

export default PaymentTransactions
