import { all, put, take, takeLatest, race, select } from 'redux-saga/effects'

import { startLoading, finishLoading } from 'reducers/layoutSlice'
import {
  actions as productsActions,
  formatters as productsFormatters,
  selectors as productsSelectors,
} from 'domains/products'
import * as actions from 'actions/views/insights'
import { FIELD_NAMES } from 'const/forms'

function* fetchProductsWorker({ payload } = {}) {
  try {
    const search = yield select(productsSelectors.getSearchString)
    const body = productsFormatters.formatPayloadForFetchRequest({
      search,
      ...payload,
    })
    yield all([put(startLoading()), put(productsActions.fetchProducts(body))])
    const [success] = yield race([
      take(productsActions.fetchProductsSuccess),
      take(productsActions.fetchProductsFailure),
    ])
    if (success) {
      yield put(productsActions.saveFiltersAndSorter(payload))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* deleteProductWorker({ payload }) {
  try {
    yield all([
      put(startLoading()),
      put(productsActions.deleteProduct(payload)),
    ])
    const [success] = yield race([
      take(productsActions.deleteProductSuccess),
      take(productsActions.deleteProductFailure),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(productsSelectors.getSearchString),
        select(productsSelectors.getPage),
        select(productsSelectors.getFilters),
        select(productsSelectors.getSorter),
      ])
      yield put(
        actions.fetchProducts({
          search,
          page,
          filters,
          sorter,
        })
      )
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* createProductWorker({ payload }) {
  try {
    const body = productsFormatters.formatProductForRequests(payload.values)
    yield all([
      put(productsActions.startSaving()),
      put(productsActions.createProduct(body)),
    ])
    const [success] = yield race([
      take(productsActions.createProductSuccess),
      take(productsActions.createProductFailure),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(productsSelectors.getSearchString),
        select(productsSelectors.getPage),
        select(productsSelectors.getFilters),
        select(productsSelectors.getSorter),
      ])
      yield put(
        actions.fetchProducts({
          search,
          page,
          filters,
          sorter,
        })
      )
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(productsActions.finishSaving())
  }
}

function* updateProductWorker({ payload }) {
  try {
    const body = productsFormatters.formatProductForRequests(payload.values)
    yield all([
      put(productsActions.startSaving()),
      put(
        productsActions.updateProduct({
          data: body,
          id: payload.values[FIELD_NAMES.id],
        })
      ),
    ])
    const [success] = yield race([
      take(productsActions.updateProductSuccess),
      take(productsActions.updateProductFailure),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(productsSelectors.getSearchString),
        select(productsSelectors.getPage),
        select(productsSelectors.getFilters),
        select(productsSelectors.getSorter),
      ])
      yield put(
        actions.fetchProducts({
          search,
          page,
          filters,
          sorter,
        })
      )
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(productsActions.finishSaving())
  }
}

export default function* () {
  yield all([
    fetchProductsWorker(),
    takeLatest(actions.fetchProducts, fetchProductsWorker),
    takeLatest(actions.deleteProduct, deleteProductWorker),
    takeLatest(actions.createProduct, createProductWorker),
    takeLatest(actions.updateProduct, updateProductWorker),
  ])
}
