import React from 'react'
import PropTypes from 'prop-types'
import { Warning, Success, Failure, Pending, Returned } from './Status.styles'

const mapValueToComponent = {
  true: <Success twoToneColor="#52c41a" />,
  checked: <Success twoToneColor="#52c41a" />,
  false: <Failure twoToneColor="#eb2f96" />,
  failed: <Failure twoToneColor="#eb2f96" />,
  canceled: <Failure twoToneColor="#eb2f96" />,
  pending: <Pending />,
  purchased: <Success twoToneColor="#52c41a" />,
  retracted: <Returned />,
  success: <Success twoToneColor="#52c41a" />,
  failure: <Failure twoToneColor="#eb2f96" />,
  warning: <Warning twoToneColor="#ebcc2f" />,
  notChecked: <Warning twoToneColor="#ebcc2f" />,
  rejected: <Failure twoToneColor="#eb2f96" />,
  paid: <Success twoToneColor="#52c41a" />,
}

const Status = ({ value }) => mapValueToComponent[value] || '-'

Status.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
}

export default Status
