import React from 'react'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import FieldFAQ from 'components/FieldFAQ/FieldFAQ'
import FormItem from 'components/FormItem/FormItem'
import { Field } from 'react-final-form'
import { ENTITY_ATTRS_SUBFIELDS, ENTITY_ATTRS } from 'const/api'
import { FieldArray } from 'react-final-form-arrays'
import { faqValidator } from 'helpers/validators'

const FieldFAQS = ({ isEmpty, isDirty }) => {
  const addRecord = (push) => () => {
    const newRecord = {
      [ENTITY_ATTRS_SUBFIELDS.question]: '',
      [ENTITY_ATTRS_SUBFIELDS.answer]: '',
      [ENTITY_ATTRS_SUBFIELDS.link]: '',
      [ENTITY_ATTRS_SUBFIELDS.mobileLink]: '',
    }
    push(newRecord)
  }

  return (
    <>
      <FieldArray name={ENTITY_ATTRS.faq}>
        {({ fields }) => (
          <>
            <FormItem label="FAQs" $isDirty={isDirty} $isEmpty={isEmpty}>
              {fields.map((name, index) => (
                <Field
                  key={name}
                  name={name}
                  removeHandler={() => fields.remove(index)}
                  validate={faqValidator}
                  component={FieldFAQ}
                />
              ))}
              <Button
                size="default"
                type="dashed"
                block
                onClick={addRecord(fields.push)}
              >
                <PlusOutlined /> Add new question
              </Button>
            </FormItem>
            {isEmpty && (
              <FormItem>
                <Button
                  size="default"
                  type="dashed"
                  block
                  onClick={addRecord(fields.push)}
                >
                  <PlusOutlined /> Add new question
                </Button>
              </FormItem>
            )}
          </>
        )}
      </FieldArray>
    </>
  )
}

export default FieldFAQS
