import styled from 'styled-components'
import { Row } from 'antd'

export const Wrapper = styled(Row)`
  justify-content: space-between;
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fafafa;
`
