import { all, put, take, takeLatest, race, select } from 'redux-saga/effects'

import { startLoading, finishLoading } from 'reducers/layoutSlice'
import {
  actions as couponsActions,
  formatters as couponsFormatters,
  selectors as couponsSelectors,
} from 'domains/coupons'
import * as actions from 'actions/views/coupon'
import { FIELD_NAMES } from 'const/forms'

function* fetchCouponsWorker({ payload } = {}) {
  try {
    const search = yield select(couponsSelectors.getSearchString)
    const body = couponsFormatters.formatPayloadForFetchRequest({
      search,
      ...payload,
    })
    yield all([put(startLoading()), put(couponsActions.fetchCoupons(body))])
    const [success] = yield race([
      take(couponsActions.fetchCouponsSuccess),
      take(couponsActions.fetchCouponsFailure),
    ])
    if (success) {
      yield put(couponsActions.saveFiltersAndSorter(payload))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* deleteCouponWorker({ payload }) {
  try {
    yield all([put(startLoading()), put(couponsActions.deleteCoupon(payload))])
    const [success] = yield race([
      take(couponsActions.deleteCouponSuccess),
      take(couponsActions.deleteCouponFailure),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(couponsSelectors.getSearchString),
        select(couponsSelectors.getPage),
        select(couponsSelectors.getFilters),
        select(couponsSelectors.getSorter),
      ])
      yield put(
        actions.fetchCoupons({
          search,
          page,
          filters,
          sorter,
        })
      )
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* createCouponWorker({ payload }) {
  try {
    const body = couponsFormatters.formatCouponForRequests(payload.values)
    yield all([
      put(couponsActions.startSaving()),
      put(couponsActions.createCoupon(body)),
    ])
    const [success] = yield race([
      take(couponsActions.createCouponSuccess),
      take(couponsActions.createCouponFailure),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(couponsSelectors.getSearchString),
        select(couponsSelectors.getPage),
        select(couponsSelectors.getFilters),
        select(couponsSelectors.getSorter),
      ])
      yield put(
        actions.fetchCoupons({
          search,
          page,
          filters,
          sorter,
        })
      )
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(couponsActions.finishSaving())
  }
}

function* updateCouponWorker({ payload }) {
  try {
    const body = couponsFormatters.formatCouponForRequests(payload.values)
    yield all([
      put(couponsActions.startSaving()),
      put(
        couponsActions.updateCoupon({
          data: body,
          id: payload.values[FIELD_NAMES.id],
        })
      ),
    ])
    const [success] = yield race([
      take(couponsActions.updateCouponSuccess),
      take(couponsActions.updateCouponFailure),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(couponsSelectors.getSearchString),
        select(couponsSelectors.getPage),
        select(couponsSelectors.getFilters),
        select(couponsSelectors.getSorter),
      ])
      yield put(
        actions.fetchCoupons({
          search,
          page,
          filters,
          sorter,
        })
      )
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(couponsActions.finishSaving())
  }
}

export default function* () {
  yield all([
    fetchCouponsWorker(),
    takeLatest(actions.fetchCoupons, fetchCouponsWorker),
    takeLatest(actions.deleteCoupon, deleteCouponWorker),
    takeLatest(actions.createCoupon, createCouponWorker),
    takeLatest(actions.updateCoupon, updateCouponWorker),
  ])
}
