import { createAction } from '@reduxjs/toolkit'

export const fetchGiveawaysPopups = createAction(
  'giveawaysPopups/fetchGiveawaysPopups'
)
export const updateGiveawayPopup = createAction(
  'giveawaysPopups/updateGiveawayPopup'
)
export const createGiveawayPopup = createAction(
  'giveawaysPopups/createGiveawayPopup'
)
export const deleteGiveawayPopup = createAction(
  'giveawaysPopups/deleteGiveawayPopup'
)
export const changeGiveawayPopupPublishState = createAction(
  'giveawaysPopups/changeGiveawayPopupPublishState'
)
