import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  saveFiltersAndSorter,
  fetchSEOPagesSuccess,
  fetchSEOPagesFailure,
  startSaving,
  finishSaving,
  clearData,
} = reducer.actions

export const fetchSEOPages = createAction('SEOPages/fetchSEOPages')

export const updateSEOPage = createAction('SEOPages/updateSEOPage')
export const updateSEOPagesuccess = createAction(
  'SEOPages/updateSEOPagesuccess'
)
export const updateSEOPageFailure = createAction(
  'SEOPages/updateSEOPageFailure'
)

export const createSEOPage = createAction('SEOPages/createSEOPage')
export const createSEOPagesuccess = createAction(
  'SEOPages/createSEOPagesuccess'
)
export const createSEOPageFailure = createAction(
  'SEOPages/createSEOPageFailure'
)
