import React, { forwardRef, useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import PropTypes from 'prop-types'
import { PKG, MEDIA_CLASS } from '../../const/cropper'

const EasyCrop = forwardRef((props, ref) => {
  const {
    src,
    aspect,
    shape,
    hasGrid,

    hasZoom,
    zoomVal,
    rotateVal,
    setZoomVal,
    setRotateVal,

    minZoom,
    maxZoom,
    onComplete,

    cropperProps,
  } = props

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [cropSize, setCropSize] = useState({ width: 0, height: 0 })

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      onComplete(croppedAreaPixels)
    },
    [onComplete]
  )

  const onMediaLoaded = useCallback(
    (mediaSize) => {
      const { width, height } = mediaSize
      const ratioWidth = height * aspect

      if (width > ratioWidth) {
        setCropSize({ width: ratioWidth, height })
      } else {
        setCropSize({ width, height: width / aspect })
      }
    },
    [aspect]
  )

  return (
    <Cropper
      {...cropperProps}
      ref={ref}
      image={src}
      crop={crop}
      cropSize={cropSize}
      onCropChange={setCrop}
      aspect={aspect}
      cropShape={shape}
      showGrid={hasGrid}
      zoomWithScroll={hasZoom}
      zoom={zoomVal}
      rotation={rotateVal}
      onZoomChange={setZoomVal}
      onRotationChange={setRotateVal}
      minZoom={minZoom}
      maxZoom={maxZoom}
      onCropComplete={onCropComplete}
      onMediaLoaded={onMediaLoaded}
      classes={{
        containerClassName: `${PKG}-container`,
        mediaClassName: MEDIA_CLASS,
      }}
    />
  )
})

EasyCrop.propTypes = {
  src: PropTypes.string,
  aspect: PropTypes.number,
  shape: PropTypes.string,
  hasGrid: PropTypes.bool,

  hasZoom: PropTypes.bool,
  zoomVal: PropTypes.number,
  rotateVal: PropTypes.number,
  setZoomVal: PropTypes.func,
  setRotateVal: PropTypes.func,

  minZoom: PropTypes.number,
  maxZoom: PropTypes.number,
  onComplete: PropTypes.func,

  cropperProps: PropTypes.object,
}

export default EasyCrop
