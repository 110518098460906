import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { formatProducts } from 'domains/products/formatters'
import { ENTITY_NAMES } from 'const/api'
import { areFiltersAndSorterEmpty } from 'helpers/filters'

export const productsState = ({ products }) => products

export const getIsLoaded = createSelector(
  productsState,
  ({ isLoaded }) => isLoaded
)

export const getIsSavingActive = createSelector(
  productsState,
  ({ isSavingActive }) => isSavingActive
)

export const isLoadingOrSavingActive = createSelector(
  [getIsLoaded, getIsSavingActive],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getEntities = createSelector(
  productsState,
  ({ entities }) => entities
)

export const getProducts = createSelector(getEntities, (entities) =>
  formatProducts(entities[ENTITY_NAMES.products], entities[ENTITY_NAMES.tags])
)

export const getProductById = createSelector(getProducts, (products) =>
  memoize((id) => (id ? products[id] : null))
)

export const getSearchString = createSelector(
  productsState,
  ({ search }) => search
)

export const getPage = createSelector(productsState, ({ page }) => page)

export const getFilters = createSelector(
  productsState,
  ({ filters }) => filters
)

export const getSorter = createSelector(productsState, ({ sorter }) => sorter)

export const getIsDataFilteredOrSorted = createSelector(
  [getFilters, getSorter],
  areFiltersAndSorterEmpty
)

export const getPageCount = createSelector(
  productsState,
  ({ pageCount }) => pageCount
)

export const getSortedIds = createSelector(
  productsState,
  ({ sortedIds }) => sortedIds
)

export const getSortedProducts = createSelector(
  [getProducts, getSortedIds],
  (products, ids) => ids.map((id) => products[id])
)
