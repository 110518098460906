import {
  all,
  put,
  race,
  select,
  take,
  takeLatest,
  call,
} from 'redux-saga/effects'
import * as actions from 'actions/views/users'
import { startLoading, finishLoading } from 'reducers/layoutSlice'
import {
  actions as userActions,
  formatters as userFormatters,
  selectors as userSelectors,
} from 'domains/user'

function* updateUsersWorker() {
  const [search, page, filters, sorter] = yield all([
    select(userSelectors.getSearchString),
    select(userSelectors.getPage),
    select(userSelectors.getFilters),
    select(userSelectors.getSorter),
  ])
  yield put(
    actions.fetchUsers({
      search,
      page,
      filters,
      sorter,
    })
  )
}

function* verifyUserPayPalWorker({ payload }) {
  try {
    yield all([put(startLoading()), put(userActions.verifyUserPayPal(payload))])
    const [success] = yield race([
      take(userActions.verifyUserPayPalSuccess),
      take(userActions.verifyUserPayPalFailure),
    ])
    if (success) {
      yield call(updateUsersWorker)
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* confirmPayPalWorker({ payload }) {
  try {
    yield all([put(startLoading()), put(userActions.confirmPayPal(payload))])
    const [success] = yield race([
      take(userActions.confirmPayPalSuccess),
      take(userActions.confirmPayPalFailure),
    ])
    if (success) {
      yield call(updateUsersWorker)
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* deleteUserWorker({ payload }) {
  try {
    yield all([put(startLoading()), put(userActions.deleteUser(payload))])
    const [success] = yield race([
      take(userActions.deleteUserSuccess),
      take(userActions.deleteUserFailure),
    ])
    if (success) {
      yield call(updateUsersWorker)
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* resetUserPasswordWorker({ payload }) {
  try {
    yield all([
      put(startLoading()),
      put(userActions.resetUserPassword(payload)),
    ])
    const [success] = yield race([
      take(userActions.resetUserPasswordSuccess),
      take(userActions.resetUserPasswordFailure),
    ])
    if (success) {
      yield call(updateUsersWorker)
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* updateUserWorker({ payload }) {
  try {
    const body = userFormatters.formatUserForUpdate(payload.values)
    yield all([
      put(userActions.startSaving()),
      put(userActions.updateUser(body)),
    ])
    const [success] = yield race([
      take(userActions.updateUserSuccess),
      take(userActions.updateUserFailure),
    ])
    if (success) {
      yield call(updateUsersWorker)
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(userActions.finishSaving())
  }
}

function* mergeUserWorker({ payload }) {
  try {
    const body = userFormatters.formatUserForMerge(payload)
    yield all([
      put(userActions.startSaving()),
      put(userActions.mergeUser(body)),
    ])
    const [success] = yield race([
      take(userActions.mergeUserSuccess),
      take(userActions.mergeUserFailure),
    ])
    if (success) {
      yield call(updateUsersWorker)
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(userActions.finishSaving())
  }
}

function* fetchUsersWorker({ payload } = {}) {
  try {
    const search = yield select(userSelectors.getSearchString)
    const body = userFormatters.formatPayloadForFetchRequest({
      search,
      ...payload,
    })
    yield all([put(startLoading()), put(userActions.fetchUsers(body))])
    const [success] = yield race([
      take(userActions.fetchUsersSuccess),
      take(userActions.fetchUsersFailure),
    ])
    if (success) {
      yield put(userActions.saveFiltersAndSorter(payload))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* sendSMSVerificationWorker({ payload }) {
  try {
    const id = payload
    yield all([
      put(userActions.startSaving()),
      put(userActions.sendSMSVerification(id)),
    ])
    yield race([
      take(userActions.sendSMSVerificationSuccess),
      take(userActions.sendSMSVerificationFailure),
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(userActions.finishSaving())
  }
}

function* unsubscribeUserWorker({ payload }) {
  try {
    yield all([
      put(userActions.startSaving()),
      put(userActions.unsubscribeUser(payload)),
    ])
    const [success] = yield race([
      take(userActions.unsubscribeUserSuccess),
      take(userActions.unsubscribeUserFailure),
    ])
    if (success) {
      yield call(updateUsersWorker)
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(userActions.finishSaving())
  }
}

function* fetchBonusesForUserWorker({ payload }) {
  try {
    yield all([
      put(userActions.startSaving()),
      put(userActions.fetchBonusesForUser(payload)),
    ])
    yield race([
      take(userActions.fetchBonusesForUserSuccess),
      take(userActions.fetchBonusesForUserFailure),
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(userActions.finishSaving())
  }
}

export default function* () {
  yield all([
    fetchUsersWorker(),
    takeLatest(actions.fetchUsers, fetchUsersWorker),
    takeLatest(actions.deleteUser, deleteUserWorker),
    takeLatest(actions.updateUser, updateUserWorker),
    takeLatest(actions.resetUserPassword, resetUserPasswordWorker),
    takeLatest(actions.mergeUser, mergeUserWorker),
    takeLatest(actions.verifyUserPayPal, verifyUserPayPalWorker),
    takeLatest(actions.sendSMSVerification, sendSMSVerificationWorker),
    takeLatest(actions.unsubscribeUser, unsubscribeUserWorker),
    takeLatest(actions.fetchBonusesForUser, fetchBonusesForUserWorker),
    takeLatest(actions.confirmPayPal, confirmPayPalWorker),
  ])
}
