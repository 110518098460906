import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as formatters from './formatters'
import * as api from './api'

function* fetchBonusesWorker({ payload } = {}) {
  try {
    const { data } = yield call(api.getBonuses, payload)

    yield put(
      actions.fetchBonusesSuccess({
        ...payload,
        bonuses: data.data,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchBonusesFailure())
  }
}

function* createBonusWorker({ payload }) {
  try {
    const formattedPayloadForRequest = formatters.formatBonusToCreate(payload)
    const { data } = yield call(api.createBonus, formattedPayloadForRequest)
    if (data) {
      yield put(actions.createBonusSuccess(payload))
    } else {
      yield put(actions.createBonusFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createBonusFailure())
  }
}

function* updateBonusWorker({ payload }) {
  try {
    const formattedPayloadForRequest = formatters.formatBonusToUpdate(payload)
    const { data } = yield call(api.updateBonus, formattedPayloadForRequest)
    if (data) {
      yield put(actions.updateBonusSuccess(payload))
    } else {
      yield put(actions.updateBonusFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateBonusFailure())
  }
}

function* deleteBonusWorker({ payload }) {
  try {
    const data = yield call(api.deleteBonus, payload)
    if (data) {
      yield put(actions.deleteBonusSuccess(payload))
    } else {
      yield put(actions.deleteBonusFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteBonusFailure())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchBonuses, fetchBonusesWorker),
    takeLatest(actions.createBonus, createBonusWorker),
    takeLatest(actions.updateBonus, updateBonusWorker),
    takeLatest(actions.deleteBonus, deleteBonusWorker),
  ])
}
