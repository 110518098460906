import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from './api'

function* fetchTransactionsWorker({ payload }) {
  try {
    const { data } = yield call(api.getTransactions, payload)

    yield put(
      actions.fetchTransactionsSuccess({
        ...payload,
        transactions: data.list,
        pageCount: data.pages,
        page: data.request.page,
        size: data.request.size,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchTransactionsFailure())
  }
}

export default function* () {
  yield all([takeLatest(actions.fetchTransactions, fetchTransactionsWorker)])
}
