import styled from 'styled-components'
import { Layout } from 'antd'

export const StyledLayout = styled(Layout)`
  .ant-row-middle {
    min-height: 100vh;
  }

  .ant-typography {
    margin-left: 8rem;
    margin-bottom: 1rem;
    display: flex;
  }

  .ant-spin-nested-loading {
    .ant-spin {
      height: 90px;
    }

    .ant-spin-blur {
      opacity: 0.2;

      &:after {
        background: none;
      }
    }
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 25%;

  button {
    padding: 0;
  }
`
