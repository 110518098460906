import ROUTES from './routes'

export const FORM_NAMES = {
  STORE_CHANGES: 'storeChanges',
  SAVE_CHANGES: 'saveChanges',
  EDITORIAL_CHANGES: 'editorialChanges',
  IMAGES: 'images',
}

export const FORM_COMMENTS_FIELD_PREFIX = 'comments'
export const FORM_CHANGES_FIELD_PREFIX = 'changes'
export const FORM_SAVE_FIELD_PREFIX = 'save'

export const FIELD_NAMES = {
  aps: 'aps',
  stake: 'stake',
  flags: 'flags',
  websiteId: 'website_id',
  features: 'features',
  upperBound: 'upper_bound',
  lowerBound: 'lower_bound',
  percentageRange: 'percentage_range',
  flatRange: 'flat_range',
  defaultPayout: 'default_payout',
  specificPayouts: 'specific_payouts',
  epcs: 'epcs',
  actions: 'actions',
  programId: 'program_id',
  role: 'role',
  roles: 'roles',
  authorizedRoles: 'authorized_roles',
  from: 'from',
  bonusId: 'bonus_id',
  password: 'password',
  immediate: 'immediate',
  rejectionReason: 'rejection_reason',
  approvedBy: 'approved_by',
  adminName: 'admin_name',
  error: 'error',
  paymentName: 'payment_name',
  notificationTypes: 'notification_types',
  walletTransactionId: 'wallet_transaction_id',
  code: 'code',
  countdownDate: 'countdown_date',
  rate: 'rate',
  link: 'link',
  source: 'source',
  paypalEmail: 'paypal_email',
  bonuses: 'bonuses',
  successUseCount: 'success_use_count',
  version: 'version',
  updateRequired: 'update_required',
  lastSuccessUse: 'last_success_use',
  totalUseCount: 'total_use_count',
  expiredAt: 'expired_at',
  description: 'description',
  regions: 'regions',
  lastSignIn: 'last_sign_in_at',
  numberOfSignIn: 'sign_in_count',
  displayPreference: 'display_preference',
  termsAndConditions: 'special_t_and_c',
  whatHappensNext: 'what_happens_next',
  bonusName: 'bonus_name',
  bonusType: 'bonus_type',
  offerType: 'offer_type',
  adminEmail: 'admin_email',
  csvLink: 'csv_file_link',
  result: 'result',
  retailerType: 'retailer_type',
  tagId: 'tag_id',
  maxKarmaGives: 'max_karma_gives',
  tagsCount: 'tags_count',
  karmaGivesCanBeChanged: 'karma_gives_can_be_changed',
  productType: 'product_type',
  company: 'company',
  url: 'url',
  finalUrl: 'final_url',
  trafficPercentage: 'traffic_percentage',
  marketingPagesUrls: 'marketing_page_urls',
  rule: 'rule',
  ruleName: 'rule_name',
  lastSuccessPercent: 'last_success_percent',
  minSuccessPercent: 'min_success_percent',
  maxSuccessPercent: 'max_success_percent',
  originalUrl: 'original_url',
  productPreview: 'image_url',
  cannotBeDeleted: 'has_transactions', //TODO: fix after api is ready
  isLocked: 'is_locked',
  isDraft: 'is_draft',
  isPublished: 'is_published',
  hasDomainRetailers: 'has_domain_retailers',
  hasMobileRetailers: 'has_mobile_retailers',
  createdAt: 'created_at',
  kcValue: 'kc_value',
  kcPercentage: 'kc_percentage',
  userLocation: 'user_location',
  type: 'type',
  deleted: 'is_deleted',
  id: 'id',
  domain: 'domain',
  email: 'email',
  slug: 'slug',
  ruleSlug: 'rule_slug',
  show: 'show',
  hide: 'hide',
  modulo: 'modulo',
  ruleSlugs: 'slugs',
  activeForAll: 'active_for_all',
  search: 'search',
  userId: 'user_id',
  commissionId: 'commission_id',
  commissionValue: 'commission_value',
  commissionCurrency: 'commission_currency',
  network: 'network',
  updatedAt: 'updated_at',
  delivered: 'delivered',
  paymentDate: 'payment_date',
  commissionOrderValue: 'order_value',
  retailerDomain: 'retailer_domain',
  uidToken: 'uid_token',
  ruleId: 'rule_id',
  ruleIds: 'rule_ids',
  tToken: 't_token',
  clickId: 'click_id',
  processedAt: 'processed_at',
  updatedBy: 'updated_by',
  retailerId: 'retailer_id',
  value: 'value',
  startedAt: 'started_at',
  finishedAt: 'finished_at',
  set: 'set',
  adPackageName: 'ad_package_name',
  manuallyUploaded: 'manually_uploaded',
  mainCategory: 'main_category',
  cbActive: 'cb_active',
  unavailableRegions: 'existing_regional_retailers_countries',
  textOnly: 'text_only',
  priority: 'priority',
  cpa: 'cashback_agreed_commission',
  cb: 'cb',
  platform: 'platform',
  targetRetailers: 'target_retailers',
  promoCb: 'promo_cb',
  plusCb: 'plus_cb',
  promoPlusCb: 'promo_plus_cb',
  contacts: 'contacts',
  comment: 'comment',
  active: 'active',
  categories: 'categories',
  position: 'position',
  showOnUi: 'show_on_ui',
  salesWeb1: 'sales_web_1',
  salesWeb2: 'sales_web_2',
  salesMobile1: 'sales_mobile_1',
  salesMobile2: 'sales_mobile_2',
  salesMobileSeeAll1: 'sales_mobile_see_all_1',
  salesMobileSeeAll2: 'sales_mobile_see_all_2',
  bannerImage: 'email_banner',
  bannerText: 'email_banner_text',
  bannerLink: 'email_banner_link',
  name: 'name',
  payout: 'payout',
  retailer: 'retailer',
  initialAmount: 'initial_amount',
  finalAmount: 'final_amount',
  purchaseStatus: 'purchase_status',
  customerStatus: 'customer_status',
  internalStatus: 'internal_status',
  status: 'status',
  amount: 'amount',
  state: 'state',
  userEmail: 'user_email',
  deviceToken: 'device_token',
  deliveredAt: 'delivered_at',
  createdBy: 'created_by',
  gender: 'gender',
  hidden: 'hidden',
  default: 'default',
  headerText: 'dashboard_image_h1_text',
  bodyText: 'dashboard_image_h2_text',
  mediaType: 'media_type',
  packageType: 'package_type',
  startDate: 'start_date',
  endDate: 'end_date',
  actionOnClick: 'action_on_click',
  hasHeaderWebImage: 'has_header_web_image',
  mainId: 'main_id',
  retailerName: 'retailer_name',
  mainRetailerId: 'main_retailer_id',
  countryCode: 'country_code',
  country: 'country',
  sourceId: 'source_id',
  paypalConnected: 'paypal_connected',
  paypalConfirmed: 'paypal_confirmed',
  totalBalance: 'total_balance',
  totalBonuses: 'total_bonuses',
  bonusesAmount: 'bonuses_amount',
  totalCash: 'total_cash',
  otherExtensionInstalled: 'other_extension_installed',
  registrationDate: 'registration_date',
  logo: 'logo_image_uri',
  logoUrl: 'logo_url',
  headerWeb: 'header_web',
  headerMobile: 'header_mobile',
  cashbackWeb: 'cashback_web',
  sound: 'sound',
  badge: 'badge',
  alert: 'alert',
  expiry: 'expiry',
  alertIsJson: 'alert_is_json',
  delayWhileIdle: 'delay_while_idle',
  processing: 'processing',
  contentAvailable: 'content_available',
  mutableContent: 'mutable_content',
  externalDeviceId: 'external_deviceId',
  collapseKey: 'collapse_key',
  threadId: 'thread_id',
  failed: 'failed',
  soundIsJson: 'sound_is_json',
  dryRun: 'dry_run',
  notification: 'notification',
  notificationData: 'notification_data',
  failedAt: 'failed_at',
  errorCode: 'error_code',
  errorDescription: 'error_description',
  deliverAfter: 'deliver_after',
  appId: 'app_id',
  retries: 'retries',
  uri: 'uri',
  failAfter: 'fail_after',
  urlArgs: 'url_args',
  category: 'category',
  registrationIds: 'registration_ids',
  cashbackMobile: 'cashback_mobile',
  featuredWeb: 'featured_web',
  featuredMobile: 'featured_mobile',
  trendingWeb: 'trending_web',
  trendingMobile: 'trending_mobile',
  pendingCash: 'pending_cash',
  walletStatus: 'wallet_status',
  geos: 'geos',
  salesRep: 'sales_rep',
  comments: 'comments',
  exclusive: 'exclusive',
  landingPageUrl: 'landing_page_url',
  title: 'title',
  activeDays: 'active_days',
  avgPurchaseAmout: 'avg_purchase_amount',
  current: 'current',
  max: 'max',
  currentConfirmend: 'current_confirmed',
  currentPending: 'current_pending',
  lastEmailClick: 'last_email_click',
  lastKarmaCashRecieved: 'last_karma_cash_received',
  lastListCreation: 'last_list_creation',
  lastPushClick: 'last_push_click',
  lastUseOfCoupons: 'last_use_of_coupons',
  pastYearConfirmed: 'past_year_confirmed',
  pastYearPaid: 'past_year_paid',
  sumOfPurchases: 'sum_of_purchases',
  tagsCountLifetime: 'tags_count_lifetime',
  tagsCountPastYear: 'tags_count_past_year',
  totalNumberOfPurchases: 'total_number_of_purchases',
  shopUsername: 'shop_username',
  shopPassword: 'shop_password',
  influencer: 'influencer',
  smallImage: 'small_image',
  largeImage: 'large_image',
  retailers: 'retailers',
  header: 'header',
  body: 'body',
  visible: 'visible',
  excerpt: 'excerpt',
  seoLink: 'seo_link',
  rulesCreated: 'rules_created',
  rulesUpdated: 'rules_updated',
  propertiesUpdated: 'properties_updated',
  phone: 'phone',
  cta: 'cta_copy',
  blockedFromPurchase: 'blocked',
  exclusivePriority: 'exclusive_priority',
  altText: 'alt_text',
  dealType: 'deal_type',
  duration: 'duration',
  flatFee: 'flat_fee',
  flatFeeCurrency: 'flat_fee_currency',
  currentCpaPercentage: 'current_cpa_percentage',
  existingCPA: 'current_cpa_percentage',
  newCpaPercentage: 'new_cpa_percentage',
  orderConfirmationNumber: 'order_confirmation_number',
  stores: 'stores',
  reportingAdmin: 'reporting_admin',
  freeTextSlack: 'free_text_slack',
  notifySlack: 'notify_slack',
  csv: 'csv',
  confirmed: 'confirmed',
  attributes: 'attributes',
  isDefault: 'is_default',
  availableOnSearch: 'available_on_search',
  shopPageAvailable: 'shop_page_available',
  verified: 'verified',
  aggregatedTagsCount: 'aggregated_tags_count',
  self: 'self',
  links: 'links',
  ruleType: 'rule_type',
  itemType: 'item_type',
  itemId: 'item_id',
  performedAt: 'performed_at',
  event: 'event',
  changes: 'changes',
  shopId: 'shop_id',
  walletCopy: 'wallet_copy',
  eligibleTimes: 'eligible_times',
  allowedForGuest: 'allowed_for_guest',
  autoDeactivate: 'auto_deactivate',
  bonusTTL: 'bonus_ttl',
  deactivationAt: 'deactivation_at',
  campaignTTL: 'campaign_ttl',
  brazeLink: 'braze_link',
  currentPassword: 'current_password',
  newPassword: 'new_password',
  confirmCurrentPassword: 'new_password_confirmation',
  expiresAt: 'expires_at',
  fixedFee: 'fixed_fee',
  fixedFeeCurrency: 'fixed_fee_currency',
  count: 'count',
  date: 'date',
  sales: 'sales',
  commissions: 'commissions',
  regionsGroups: 'regions_groups',
  couponsScanner: 'coupons_scanner',
  couponsScraping: 'coupons_scraping',
  showCoupons: 'show_coupons',
  coupons: 'coupons',
  temporaryPriorityUntil: 'temporary_priority_until',
  checked: 'checked',
  statuses: 'statuses',
  entities: 'entities',
  notChecked: 'not_checked',
  rows: 'rows',
  groupId: 'group_id',
  shopGroupId: 'shop_group_id',
  categoryId: 'category_id',
  main: 'main',
  minAge: 'min_age',
  maxAge: 'max_age',
  shivaCategories: 'shiva_categories',
  installedDate: 'installed_date',
  website: 'website',
  rewardValue: 'rewardValue',
  reward_value: 'reward_value',
  merchantDomains: 'merchantDomains',
  merchant_domains: 'merchant_domains',
  valid: 'valid',
  decline: 'decline',
}

export const JAVA_FIELD_NAMES = {
  autofixingEnabled: 'autofixing-enabled',
  useFakeCard: 'use-fake-card',
  humanApplyingEnabled: 'human-applying-for-properties-enabled',
  urgentNotifications: 'urgent-notifications-enabled',
  ruleId: 'rule-id',
  captcha: 'captcha',
  lastTestedAt: 'last-tested-at',
  testRetry: 'test-counter',
  ruleIds: 'rule_ids',
  completed: 'completed',
  countries: 'countries',
  noTagCountries: 'no-tags',
  id: 'id',
  name: 'name',
  url: 'url-regex',
  status: 'status',
  checkoutType: 'checkout-type',
  useRealEmail: 'use-real-email',
  singleProductOnly: 'single-product-only',
  hasVerification: 'has-verification',
  couponsWork: 'coupons-work',
  flowVerified: 'flow-verified',
  stripeVerified: 'stripe-verified',
  subject: 'subject',
  recipient: 'recipient',
  sender: 'sender',
  userName: 'user-name',
  userEmail: 'user-email',
  ruleName: 'rule-name',
  createdAt: 'created-at',
  updatedAt: 'updated-at',
  sentToUserAt: 'sent-to-user-at',
  sentToUser: 'send-to-user',
  orderId: 'order-number',
  commissionOrderValue: 'commission-order-value',
  commissionValue: 'commission-value',
  commissionCurrency: 'commission-currency',
  commissionId: 'commission-id',
  commissionType: 'commission-type',
  code: 'code',
  unitPrice: 'unit-price',
  quantity: 'quantity',
  sku: 'sku',
  category: 'category',
  currency: 'currency',
  userId: 'user-id',
  uidToken: 'uid-token',
  paymentDate: 'payment-date',
  processedAt: 'processed-at',
  retailerDomain: 'retailer-domain',
  network: 'network',
  tToken: 't-token',
  rule: 'rule',
  customer: 'customer',

  createdAtFrom: 'created-at-from',
  createdAtTo: 'created-at-to',
  sentAtFrom: 'sent-at-from',
  sentAtTo: 'sent-at-to',
  fromDate: 'from-date',
  toDate: 'to-date',
  date: 'date',
}

export const STORE_CHANGES_FIELD_NAMES = [
  FIELD_NAMES.maxKarmaGives,
  FIELD_NAMES.tagsCount,
  // FIELD_NAMES.cbActive,
  // FIELD_NAMES.cpa,
  // FIELD_NAMES.cb,
  // FIELD_NAMES.promoCb,
  // FIELD_NAMES.plusCb,
  // FIELD_NAMES.promoPlusCb,
]

export const EDITORIAL_CHANGES_FIELD_NAMES = [
  FIELD_NAMES.categories,
  FIELD_NAMES.mediaType,
  FIELD_NAMES.showOnUi,
  FIELD_NAMES.actionOnClick,
]

export const FIELDS_WITH_DATES = [FIELD_NAMES.promoCb, FIELD_NAMES.promoPlusCb]

export const DISABLED_FIELDS = [
  // FIELD_NAMES.cbActive,
  FIELD_NAMES.showOnUi,
  FIELD_NAMES.actionOnClick,
  FIELD_NAMES.maxKarmaGives,
]

export const FORM_NAME_TO_FORM_FIELDS = {
  [FORM_NAMES.STORE_CHANGES]: STORE_CHANGES_FIELD_NAMES,
  [FORM_NAMES.EDITORIAL_CHANGES]: EDITORIAL_CHANGES_FIELD_NAMES,
}

export const GROUP_FIELD_NAME_PREFIX = 'group_'

export const getGroupFieldName = (groupId) =>
  `${GROUP_FIELD_NAME_PREFIX}${groupId}`

export const ROUTER_PATH_TO_FORM_NAME = {
  [ROUTES.RETAILERS]: FORM_NAMES.STORE_CHANGES,
  [ROUTES.EDITORIAL]: FORM_NAMES.EDITORIAL_CHANGES,
}
