export const MAX_BODY_TEXT_LENGTH = 60
export const MAX_HEADER_TEXT_LENGTH = 20
export const DEFAULT_MEDIA_TYPE = 'none'
export const LINK_REGEXP =
  // eslint-disable-next-line max-len
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/

export const EMAIL_REGEXP =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export const APP_VERSION_REGEXP = /^(\d+\.)?(\d+\.)?(\d+\.)?(\*|\d+)?$/
