export const PLATFORM_TYPES = {
  web: 'web',
  extension: 'extension',
  mobile: 'mobile',
  any: 'any',
}

export const PLATFORM_FILTERS = [
  { text: 'Web', value: PLATFORM_TYPES.web },
  { text: 'Extension', value: PLATFORM_TYPES.extension },
  { text: 'Mobile', value: PLATFORM_TYPES.mobile },
  { text: 'Any', value: PLATFORM_TYPES.any },
]

export const STATUS_FILTERS = [
  { text: 'Active', value: true },
  { text: 'Inactive', value: false },
]

export const BETA = [
  { text: 'Beta', value: true },
  { text: 'Not beta', value: null },
]
