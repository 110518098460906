import React, { useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Info, SetName, Actions } from './SetInfo.styles'
import { setFullPropType } from 'const/propTypes'
import { DATE_FORMAT } from 'const/dates'
import { IMAGE_GENDER_OPTIONS } from 'const/retailers'
import { FIELD_NAMES } from 'const/forms'
import Select from 'components/Select'
import HideSwitch from 'components/HideSwitch'
import DeleteSwitch from 'components/DeleteSwitch'
import { Row, Tooltip } from 'antd'
import { Field } from 'react-final-form'

const SetInfo = ({
  fullData,
  setId,
  deleteHandler,
  restoreHandler,
  fieldName,
  isLocked,
  isDeleted,
  isNullable,
}) => {
  useEffect(() => {
    if (isNullable) {
      deleteHandler()
    }
  }, [isNullable, deleteHandler])
  return (
    <Info key={setId}>
      <Actions>
        <Tooltip title={fullData?.[setId]?.[FIELD_NAMES.id]}>
          <SetName>{`Set ${setId + 1}`}</SetName>
        </Tooltip>
        <Row>
          <Field
            disabled={isLocked || isDeleted}
            name={`${fieldName}[${FIELD_NAMES.hidden}]`}
            component={HideSwitch}
          />
          <Field
            hide
            disabled={isLocked}
            name={`${fieldName}[${FIELD_NAMES.isLocked}]`}
            component={HideSwitch}
          />
          <Field
            name={`${fieldName}[${FIELD_NAMES.deleted}]`}
            restoreHandler={restoreHandler}
            deleteHandler={deleteHandler}
            disabled={isLocked}
            component={DeleteSwitch}
          />
        </Row>
      </Actions>
      <SetName>
        {fullData?.[setId]?.[FIELD_NAMES.createdAt] ||
          moment().format(DATE_FORMAT)}
      </SetName>
      <Field
        disabled={isLocked || isDeleted}
        options={IMAGE_GENDER_OPTIONS}
        allowClear={false}
        name={`${fieldName}[${FIELD_NAMES.gender}]`}
        component={Select}
      />
    </Info>
  )
}

SetInfo.propTypes = {
  fieldName: PropTypes.string.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  restoreHandler: PropTypes.func.isRequired,
  fullData: PropTypes.arrayOf(setFullPropType),
  setId: PropTypes.number.isRequired,
  isLocked: PropTypes.bool.isRequired,
  isNullable: PropTypes.bool.isRequired,
  isDeleted: PropTypes.bool.isRequired,
}

export default SetInfo
