import styled from 'styled-components'

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Info = styled.div`
  padding: 10px 10px 0;
  width: 125px;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const SetName = styled.span`
  font-size: 14px;
`
