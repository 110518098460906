import { mapSorters } from 'helpers/javaAPI'

export const formatPayloadForFetchRequest = ({
  page,
  sorter,
  size,
  from,
  to,
} = {}) => ({
  page: page || 1,
  sorter: mapSorters(sorter, 'date', 'desc'),
  size: size || 20,
  from,
  to,
})
