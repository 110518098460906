import styled, { css } from 'styled-components'
import { Input, InputNumber } from 'antd'

const invalid = (props) => {
  if (props.invalid) {
    return css`
      border-color: rgb(255, 0, 0, 1);
      box-shadow: none;

      &:hover,
      &:focus {
        border-color: rgb(255, 0, 0, 1);
        box-shadow: 0 0 0 2px rgb(255, 0, 0, 0.5);
      }
    `
  }
}

export default styled(Input)`
  ${invalid}
  & input {
    ${invalid}
  }
`

export const StyledInputNumber = styled(InputNumber)`
  min-width: 60px;
  width: 65px;
  ${invalid}
`

export const TextArea = styled(Input.TextArea)`
  ${invalid}
`
