import React from 'react'
import { PageLayout } from 'components/styled/layout'
import { Title } from './LinkConverter.styles'
import ConvertForm from './ConvertForm'

const LinkConverter = () => (
  <PageLayout>
    <Title>Convert link to affiliate link</Title>
    <ConvertForm />
  </PageLayout>
)

export default LinkConverter
