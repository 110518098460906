import React from 'react'
import { FIELD_NAMES } from 'const/forms'
import useAction from 'utils/useAction'
import {
  openConfirmModal,
  openUserModal,
  openMergeUserModal,
  openResetPasswordModal,
  openSMSVerificationModal,
} from 'reducers/modals/actions'
import {
  confirmPayPal,
  deleteUser,
  sendSMSVerification,
  unsubscribeUser,
} from 'actions/views/users'
import { Button, Dropdown, Menu } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import MenuItem from 'antd/es/menu/MenuItem'

const UserActions = ({ data }) => {
  const isPayPalConfirmationAvailable =
    data[FIELD_NAMES.paypalConnected] && !data[FIELD_NAMES.paypalConfirmed]

  const deleteUserFunc = useAction(deleteUser)
  const sendSMSVerificationFunc = useAction(sendSMSVerification)
  const unsubscribeUserFunc = useAction(unsubscribeUser)
  const confirmPayPalFunc = useAction(confirmPayPal)

  const openUserModalFunc = useAction(openUserModal)
  const openConfirmModalFunc = useAction(openConfirmModal)
  const openMergeUserModalFunc = useAction(openMergeUserModal)
  const openResetPasswordFunc = useAction(openResetPasswordModal)
  const openSMSVerificationModalFunc = useAction(openSMSVerificationModal)

  const handleEditUser = () => openUserModalFunc(data)
  const handleMergeUser = () => openMergeUserModalFunc(data)
  const handleResetPassword = () => openResetPasswordFunc(data)

  const handleConfirmPayPal = () => {
    const callback = () => confirmPayPalFunc(data[FIELD_NAMES.id])
    const text =
      `Are you sure you want confirm PayPal for the user ${
        data[FIELD_NAMES.name]
      } ` + `(ID: ${data[FIELD_NAMES.id]})? This action can't be revoked.`
    openConfirmModalFunc({ callback, text })
  }

  const handleSMSVerification = () => {
    sendSMSVerificationFunc(data[FIELD_NAMES.id])
    openSMSVerificationModalFunc(data)
  }

  const handleDeleteUser = () => {
    const text =
      `You are about to delete user with id: ${data[FIELD_NAMES.id]}` +
      `${
        data[FIELD_NAMES.name]
          ? ', name: ' + data[FIELD_NAMES.name] + '. '
          : '. '
      }` +
      'If you are sure, please type the user’s email.'
    const text2 = `The user with email ${
      data[FIELD_NAMES.email]
    } will be deleted permanently. Do you want to proceed?`
    const callback2 = () => deleteUserFunc(data[FIELD_NAMES.id])
    const callback = () =>
      openConfirmModalFunc({ callback: callback2, text: text2 })
    openConfirmModalFunc({
      callback,
      text,
      inputToConfirm: data[FIELD_NAMES.email],
      textForConfirmation: "user's email",
      buttonText: 'Delete',
      isCopyAndPasteDisabled: true,
    })
  }
  const handleUnsubscribe = () => {
    const callback = () => unsubscribeUserFunc(data[FIELD_NAMES.id])
    const text = `Are you sure you want to unsubscribe ${
      data[FIELD_NAMES.name]
    } (ID: ${data[FIELD_NAMES.id]}) from all alerts? `
    openConfirmModalFunc({ callback, text })
  }

  const menu = (
    <Menu>
      <MenuItem onClick={handleEditUser}>Edit</MenuItem>
      <MenuItem onClick={handleMergeUser}>Merge</MenuItem>
      <MenuItem onClick={handleUnsubscribe}>Unsubscribe All Alerts</MenuItem>
      <MenuItem onClick={handleResetPassword}>Reset Password</MenuItem>
      {isPayPalConfirmationAvailable && (
        <MenuItem onClick={handleConfirmPayPal}>Confirm PayPal</MenuItem>
      )}
      <MenuItem onClick={handleSMSVerification}>SMS Verification</MenuItem>
      <MenuItem onClick={handleDeleteUser} danger>
        Delete
      </MenuItem>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button icon={<MoreOutlined />} shape="circle" size="small" />
    </Dropdown>
  )
}

export default UserActions
