import styled from 'styled-components'

export const MarkdownWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 0;
`

export const Label = styled.div`
  width: 20%;
  text-align: right;
  margin-right: 10px;
`

export const LabelCounter = styled.label`
  float: right;
  text-align: right;
  margin-right: 10px;
`

export const MarkdownComponent = styled.div`
  & .demo-wrapper {
    ${({ error }) => error && 'border: 1px solid red;'}
  }
`

export const Error = styled.span`
  color: red;
`
