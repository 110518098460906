import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { StyledButton, Text } from './ConfirmModal.styles'
import { FIELD_NAMES } from 'const/forms'
import { paymentPropTypes } from 'const/propTypes'

const ConfirmModal = ({ payment, closeModal, show, callback }) => {
  const handleConfirm = () => callback(payment)

  return (
    <Modal
      title="Payment confirmation"
      open={show}
      centered
      onCancel={closeModal}
      footer={[
        <StyledButton key="empty" onClick={closeModal}>
          Cancel
        </StyledButton>,
        <StyledButton key="all" onClick={handleConfirm} type="primary">
          Continue
        </StyledButton>,
      ]}
    >
      <Text>
        Are you sure you want to send {payment[FIELD_NAMES.amount]}$ to{' '}
        <strong>{payment[FIELD_NAMES.userEmail]}</strong>?
      </Text>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  payment: paymentPropTypes.isRequired,
}

export default ConfirmModal
