import first from 'lodash/first'
import { FIELD_NAMES } from './forms'
import { IMAGE_GENDER_OPTIONS, RETAILER_GROUP_SLUGS } from './retailers'

export const MAX_NUMBER_OF_SET = 15

export const LOGO = {
  label: 'Logo',
  name: FIELD_NAMES.logo,
  cropSize: {
    width: 150,
    height: 150,
  },
}

export const STARTING_IMAGES_NAMES = [
  FIELD_NAMES.headerWeb,
  FIELD_NAMES.headerMobile,
]

export const IMAGES_SET = [
  {
    label: 'Header',
    type: RETAILER_GROUP_SLUGS.web,
    name: FIELD_NAMES.headerWeb,
    cropSize: {
      width: 3200,
      height: 1040,
    },
  },
  {
    label: 'Header',
    type: RETAILER_GROUP_SLUGS.mobile,
    name: FIELD_NAMES.headerMobile,
    cropSize: {
      width: 670,
      height: 900,
    },
  },
  {
    slug: [
      RETAILER_GROUP_SLUGS.cashback,
      RETAILER_GROUP_SLUGS.newRetailers,
      RETAILER_GROUP_SLUGS.weAreLoving,
    ],
    type: RETAILER_GROUP_SLUGS.web,
    label: 'Cashback',
    name: FIELD_NAMES.cashbackWeb,
    cropSize: {
      width: 800,
      height: 500,
    },
  },
  {
    slug: [
      RETAILER_GROUP_SLUGS.cashback,
      RETAILER_GROUP_SLUGS.newRetailers,
      RETAILER_GROUP_SLUGS.weAreLoving,
    ],
    type: RETAILER_GROUP_SLUGS.mobile,
    label: 'Cashback',
    name: FIELD_NAMES.cashbackMobile,
    cropSize: {
      width: 670,
      height: 360,
    },
  },
  {
    slug: RETAILER_GROUP_SLUGS.featured,
    type: RETAILER_GROUP_SLUGS.web,
    label: 'Featured',
    name: FIELD_NAMES.featuredWeb,
    cropSize: {
      width: 1100,
      height: 600,
    },
  },
  {
    slug: RETAILER_GROUP_SLUGS.featured,
    type: RETAILER_GROUP_SLUGS.mobile,
    label: 'Featured',
    name: FIELD_NAMES.featuredMobile,
    cropSize: {
      width: 600,
      height: 300,
    },
  },
  {
    slug: RETAILER_GROUP_SLUGS.trending,
    type: RETAILER_GROUP_SLUGS.web,
    label: 'Trending',
    name: FIELD_NAMES.trendingWeb,
    cropSize: {
      width: 1100,
      height: 500,
    },
  },
  {
    slug: RETAILER_GROUP_SLUGS.trending,
    type: RETAILER_GROUP_SLUGS.mobile,
    label: 'Trending',
    name: FIELD_NAMES.trendingMobile,
    cropSize: {
      width: 885,
      height: 510,
    },
  },
]

export const IMAGES_TO_OVERRIDE = [
  FIELD_NAMES.headerMobile,
  FIELD_NAMES.cashbackWeb,
  FIELD_NAMES.cashbackMobile,
  FIELD_NAMES.featuredWeb,
  FIELD_NAMES.featuredMobile,
  FIELD_NAMES.trendingWeb,
  FIELD_NAMES.trendingMobile,
]

export const EMPTY_IMAGE_SET = {
  [FIELD_NAMES.gender]: first(IMAGE_GENDER_OPTIONS),
  [FIELD_NAMES.hidden]: false,
  [FIELD_NAMES.deleted]: false,
  [FIELD_NAMES.isLocked]: false,
  [FIELD_NAMES.headerWeb]: undefined,
  [FIELD_NAMES.headerMobile]: undefined,
  [FIELD_NAMES.cashbackWeb]: undefined,
  [FIELD_NAMES.cashbackMobile]: undefined,
  [FIELD_NAMES.featuredWeb]: undefined,
  [FIELD_NAMES.featuredMobile]: undefined,
  [FIELD_NAMES.trendingWeb]: undefined,
  [FIELD_NAMES.trendingMobile]: undefined,
}
