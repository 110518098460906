import styled from 'styled-components'

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};

  & > div {
    margin-top: 0;
    margin-right: 10px;
    .field-control {
      width: 100%;
    }
  }
`
