import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  EditorState,
  Modifier,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import './react-draft-wysiwyg.css'
import { MarkdownComponent, Error, LabelCounter } from './Markdown.styles'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper'

const convertToEditorState = (value) => {
  if (!value) return EditorState.createEmpty()

  try {
    const content = convertFromRaw(JSON.parse(value))
    value = EditorState.createWithContent(content)
  } catch (e) {
    const blocksFromHTML = convertFromHTML(value)
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )

    value = EditorState.createWithContent(state)
  }

  return value
}

const Markdown = ({
  meta,
  input: { value, onChange },
  label,
  maxChars,
  required,
}) => {
  const editorState = convertToEditorState(value)

  const [state, setState] = useState(editorState)

  const onEditorStateChange = (contentState) => {
    const jsonContentString = JSON.stringify(
      convertToRaw(contentState.getCurrentContent())
    )

    setState(contentState)
    onChange(jsonContentString)
  }

  const handleBeforeInput = (input) => {
    const inputLength = state.getCurrentContent().getPlainText().length
    if (maxChars && input && inputLength >= maxChars) {
      return 'handled'
    }
  }

  const handlePastedText = (input) => {
    const inputLength = state.getCurrentContent().getPlainText().length
    const remainingLength = maxChars - inputLength

    if (maxChars && input.length + inputLength >= maxChars) {
      const newContent = Modifier.insertText(
        state.getCurrentContent(),
        state.getSelection(),
        input.slice(0, remainingLength)
      )

      onEditorStateChange(
        EditorState.push(state, newContent, 'insert-characters')
      )

      return true
    } else {
      return false
    }
  }

  return (
    <FieldWrapper meta={meta} label={label} required={required}>
      <MarkdownComponent error={meta.error}>
        <Editor
          editorState={state}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onEditorStateChange}
          handleBeforeInput={handleBeforeInput}
          handlePastedText={handlePastedText}
          handleReturn={() => handlePastedText('\n')}
          toolbar={{
            options: ['inline', 'blockType', 'emoji', 'link'],
            inline: {
              inDropdown: true,
              options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
            textAlign: {
              inDropdown: true,
            },
          }}
        />
        {meta.error && <Error>{meta.error}</Error>}
        {maxChars && (
          <LabelCounter>
            {state && state.getCurrentContent().getPlainText().length}/
            {maxChars}
          </LabelCounter>
        )}
      </MarkdownComponent>
    </FieldWrapper>
  )
}

Markdown.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }),
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  maxChars: PropTypes.number,
  // eslint-disable-next-line react/boolean-prop-naming
  required: PropTypes.bool,
}

Markdown.defaultProps = {
  label: '',
  input: { value: null },
  meta: { error: false },
  maxChars: null,
  required: false,
}

export default Markdown
