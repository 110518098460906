import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import { Wrapper } from './Search.styles'

const Search = ({ placeholder, onSubmit, value, isDisabled }) => {
  const searchHandler = (value) => {
    onSubmit(value)
  }

  return (
    <Wrapper>
      <Input.Search
        defaultValue={value}
        placeholder={placeholder}
        onSearch={searchHandler}
        disabled={isDisabled}
      />
    </Wrapper>
  )
}

Search.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
}

Search.defaultProps = {
  placeholder: 'Search retailer here',
  value: null,
  isDisabled: false,
}

export default Search
