import React from 'react'
import { useSelector } from 'react-redux'
import { selectors as generalSelectors } from 'domains/general'
import MarketingSectionCopyComponent from './MarketingSectionCopy'

const MarketingSectionCopy = () => {
  const copies = useSelector(generalSelectors.getMarketingCopy)

  return <MarketingSectionCopyComponent data={copies} />
}

export default MarketingSectionCopy
