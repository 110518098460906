import { all, put, takeLatest } from 'redux-saga/effects'
import * as actions from 'actions/views/emailNotifications'
import { actions as notificationsActions } from 'domains/notifications'
import { finishLoading, startLoading } from 'reducers/layoutSlice'

function* sendEmailNotificationWorker({ payload }) {
  try {
    yield all([
      put(startLoading()),
      put(notificationsActions.sendEmailNotification(payload)),
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.sendEmailNotification, sendEmailNotificationWorker),
  ])
}
