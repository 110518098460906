import styled, { css } from 'styled-components'
import { Select } from 'antd'

export const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    max-height: 100px;
    overflow-y: auto;
  }
`

export const Wrapper = styled.div`
  width: 150px;
  display: flex;
  flex-direction: column;

  & > div:last-child {
    margin-top: 10px;
  }
`

const invalid = (props) => {
  if (props.invalid) {
    return css`
      border-color: rgb(255, 0, 0, 1);
      box-shadow: none;

      &:hover,
      &:focus {
        border-color: rgb(255, 0, 0, 1);
        box-shadow: 0 0 0 2px rgb(255, 0, 0, 0.5);
      }
      & > div {
        border-color: rgb(255, 0, 0, 1);
        box-shadow: 0 0 0 2px rgb(255, 0, 0, 0.5);
      }
    `
  }
}

export const StyledMainSelect = styled(Select)`
  border-radius: 2px;
  ${invalid}

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    ${invalid}
  }
`
