import React from 'react'
import PropTypes from 'prop-types'
import { StyledImage } from './Image.styles'

const Image = ({ width, height, src, fallback }) => (
  <StyledImage width={width} height={height} src={src} fallback={fallback} />
)

Image.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  src: PropTypes.string.isRequired,
  fallback: PropTypes.string,
}

Image.defaultProps = {
  width: 200,
  height: 200,
  fallback: '/images/fallback.png',
}

export default Image
