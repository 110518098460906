import React from 'react'
import { Table } from 'antd'
import { getUserBonuses, getBonusesLoaded } from 'domains/user/selectors'
import { useSelector } from 'react-redux'
import getColumns from './columns'

const UserBonusesModal = () => {
  const bonusesData = useSelector(getUserBonuses)
  const areBonusesLoaded = useSelector(getBonusesLoaded)

  return (
    <Table
      dataSource={bonusesData}
      columns={getColumns()}
      loading={!areBonusesLoaded}
      pagination={false}
    />
  )
}
export default UserBonusesModal
