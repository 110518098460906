import React from 'react'
import { useSelector } from 'react-redux'
import { selectors as generalSelectors } from 'domains/general'
import CategoriesComponent from './CategoriesComponent'
import useAction from 'utils/useAction'
import * as actions from 'actions/views/settings'

const Categories = () => {
  const currentNames = useSelector(generalSelectors.categoryNames)
  const categories = useSelector(generalSelectors.categories)

  const addCategory = useAction(actions.createCategory)
  const editCategory = useAction(actions.editCategory)
  const deleteCategory = useAction(actions.deleteCategory)

  return (
    <CategoriesComponent
      addMethod={addCategory}
      editMethod={editCategory}
      deleteMethod={deleteCategory}
      data={categories}
      currentNames={currentNames}
    />
  )
}

export default Categories
