import styled from 'styled-components'

export const Container = styled.div``

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 10px;
  justify-content: space-around;
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
