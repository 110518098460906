import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import { Actions, StyledButton } from './RuleSlugs.styles'
import { formatDate } from 'helpers/dates'
import { formatFieldValue } from 'helpers/formatters/fields'

export default (filters, sorter, handleEditRuleSlug, handleDeleteRuleSlug) => [
  {
    title: 'ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 110,
    fixed: 'left',
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Slug',
    dataIndex: FIELD_NAMES.ruleSlug,
    key: FIELD_NAMES.ruleSlug,
    width: 110,
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Rule ID',
    dataIndex: FIELD_NAMES.ruleId,
    key: FIELD_NAMES.ruleId,
    render: formatFieldValue,
    width: 120,
    ellipsis: true,
    align: 'center',
    // sorter: true,
    // sortOrder: sorter.columnKey === FIELD_NAMES.ruleId && sorter.order,
  },
  {
    title: 'Rule',
    dataIndex: FIELD_NAMES.ruleName,
    key: FIELD_NAMES.ruleName,
    render: formatFieldValue,
    width: 120,
    ellipsis: true,
    align: 'center',
  },
  {
    title: 'Created at',
    dataIndex: FIELD_NAMES.createdAt,
    key: FIELD_NAMES.createdAt,
    width: 120,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.createdAt && sorter.order,
    render: formatDate,
  },
  {
    title: 'Updated at',
    dataIndex: FIELD_NAMES.updatedAt,
    key: FIELD_NAMES.updatedAt,
    width: 120,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.updatedAt && sorter.order,
    render: formatDate,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    fixed: 'right',
    width: 60,
    render: (_, ruleSlug) => {
      const onEdit = () => handleEditRuleSlug(ruleSlug[FIELD_NAMES.id])
      const onDelete = () => handleDeleteRuleSlug(ruleSlug)
      return (
        <Actions>
          <StyledButton onClick={onEdit}>Edit</StyledButton>
          <StyledButton onClick={onDelete} type="danger">
            Delete
          </StyledButton>
        </Actions>
      )
    },
  },
]
