import React from 'react'
import { Button } from 'antd'
import { SubmitWrapper } from './SubmitWrapper.styles.js'

const SubmitButton = ({ handleSubmit, pristine, text }) => (
  <SubmitWrapper>
    <Button type="primary" onClick={handleSubmit} disabled={pristine}>
      {text}
    </Button>
  </SubmitWrapper>
)

export default SubmitButton
