import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectors as adminSelectors } from 'domains/admin'
import ROUTES, { ROUTES_PERMISSIONS } from 'const/routes'
import { isLoadedSelector } from 'domains/general/selectors'

const RoleRoute = ({ component, path, redirectTo = ROUTES.ROLES, ...rest }) => {
  const currentRoles = useSelector(adminSelectors.newAdminRoles)
  const routePermissions = ROUTES_PERMISSIONS[path]
  const isAvailable =
    currentRoles.some((role) => routePermissions.includes(role)) ||
    routePermissions.length === 0
  const isLoaded = useSelector(isLoadedSelector)

  if (!isLoaded) return null

  return isAvailable ? (
    <Route {...rest} path={path} component={component} />
  ) : (
    <Redirect to={redirectTo} />
  )
}

RoleRoute.propTypes = {
  path: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired,
  ]),
  redirectTo: PropTypes.string,
}

RoleRoute.defaultProps = {
  redirectTo: ROUTES.ROLES,
  roles: [],
}

export default RoleRoute
