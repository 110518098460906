import { FIELD_NAMES } from './forms'

export const SUCCESS_STATUSES = [200, 201, 204]

export const ENTITY_NAMES = {
  main: 'main_retailers',
  domain: 'domain_retailers',
  mobile: 'mobile_retailers',
  retailers: 'retailers',
  rules: 'rules',
  backoffice: 'backoffice',
  ruleSlugs: 'rule_slugs',
  admin: 'admin',
  program: 'program',
  shop: 'shop',
  superAdmin: 'super_admin',
  products: 'available_products',
  mobileAppVersions: 'mobile_app_versions',
  extensionVersions: 'extension_versions',
  mobileNotifications: 'mobile_notifications',
  tags: 'tags',
  payments: 'payments',
  users: 'users',
  adminPayments: 'admin_payments',
  paymentUploads: 'payment_uploads',
  regional: 'regional_retailers',
  comments: 'retailer_comments',
  contacts: 'retailer_contacts',
  mainCategory: 'retailer_categories',
  retailerCategory: 'retailer_categories',
  cb: 'cashback_stakes',
  promoCb: 'cashback_promoted_stakes',
  plusCb: 'cashback_plus_stakes',
  promoPlusCb: 'cashback_plus_promoted_stakes',
  adPackage: 'ad_packages',
  adPackageAsset: 'ad_package_assets',
  categories: 'categories',
  groups: 'groups',
  coupons: 'shop_offers',
  admins: 'admins',
  images: 'image_sets',
  retailerGroups: 'retailer_groups',
  retailerCategories: 'retailer_categories',
  bonusesUploadAttempts: 'admin_bonus_uploads',
  landingPages: 'landing_pages',
  marketingPages: 'marketing_pages',
  SEOPages: 'product_families',
  developers: 'developers',
  giveaways: 'giveaways',
  campaignDeals: 'campaign_deals',
  blockedFromPurchase: 'blocked',
  backofficeUsers: 'backoffice_users',
  roles: 'roles',
  shops: 'shops',
  localizedShops: 'localized_shops',
  adminHistory: 'activity_logs',
  bonuses: 'bonuses',
  marketingCopy: 'marketing_copy',
  updateSections: 'update_sections',
  sectionName: 'section_name',
  bonusName: 'bonus_name',
  aggregatedTransaction: 'aggregated-transaction',
  updateCouponsUsage: 'update_coupons_usage',
}

export const ENTITY_ATTRS = {
  [FIELD_NAMES.id]: 'id',
  [FIELD_NAMES.tagId]: 'tag_id',
  [FIELD_NAMES.email]: 'email',
  [FIELD_NAMES.role]: 'role',
  [FIELD_NAMES.code]: 'code',
  [FIELD_NAMES.roles]: 'roles',
  [FIELD_NAMES.ruleId]: 'rule_id',
  [FIELD_NAMES.lastSignIn]: 'last_sign_in_at',
  [FIELD_NAMES.source]: 'source',
  [FIELD_NAMES.initialAmount]: 'initial_amount',
  [FIELD_NAMES.finalAmount]: 'final_amount',
  [FIELD_NAMES.purchaseStatus]: 'status',
  [FIELD_NAMES.customerStatus]: 'customer_status',
  [FIELD_NAMES.internalStatus]: 'internal_status',
  [FIELD_NAMES.numberOfSignIn]: 'sign_in_count',
  [FIELD_NAMES.deliveredAt]: 'delivered_at',
  [FIELD_NAMES.delivered]: 'delivered',
  [FIELD_NAMES.paypalConnected]: 'paypal_connected',
  [FIELD_NAMES.paypalConfirmed]: 'paypal_confirmed',
  [FIELD_NAMES.otherExtensionInstalled]: 'other_extension_installed',
  [FIELD_NAMES.totalBalance]: 'total_balance',
  [FIELD_NAMES.totalCash]: 'total_cash',
  [FIELD_NAMES.pendingCash]: 'pending_cash',
  [FIELD_NAMES.registrationDate]: 'registration_date',
  [FIELD_NAMES.paymentName]: 'payment_name',
  [FIELD_NAMES.walletTransactionId]: 'wallet_transaction_id',
  [FIELD_NAMES.countdownDate]: 'countdown_date',
  [FIELD_NAMES.mainRetailerId]: 'main_retailer_id',
  [FIELD_NAMES.cpa]: 'cashback_agreed_commission',
  [FIELD_NAMES.cb]: 'cashback_status',
  [FIELD_NAMES.stake]: 'stake',
  [FIELD_NAMES.rate]: 'rate',
  [FIELD_NAMES.startedAt]: 'started_at',
  [FIELD_NAMES.expiredAt]: 'expired_at',
  [FIELD_NAMES.updatedAt]: 'updated_at',
  [FIELD_NAMES.offerType]: 'type',
  [FIELD_NAMES.amount]: 'amount',
  [FIELD_NAMES.userId]: 'user_id',
  [FIELD_NAMES.amount]: 'amount',
  [FIELD_NAMES.state]: 'state',
  [FIELD_NAMES.successUseCount]: 'success_use_count',
  [FIELD_NAMES.lastSuccessUse]: 'last_success_use',
  [FIELD_NAMES.lastSuccessPercent]: 'last_success_percent',
  [FIELD_NAMES.totalUseCount]: 'total_use_count',
  [FIELD_NAMES.position]: 'position',
  [FIELD_NAMES.showOnUi]: 'visible',
  [FIELD_NAMES.actionOnClick]: 'action_on_click',
  [FIELD_NAMES.retailerName]: 'retailer_name',
  [FIELD_NAMES.priority]: 'priority',
  [FIELD_NAMES.createdAt]: 'created_at',
  [FIELD_NAMES.startDate]: 'start_date',
  [FIELD_NAMES.endDate]: 'end_date',
  [FIELD_NAMES.adPackageName]: 'name',
  [FIELD_NAMES.mediaType]: 'media_type',
  [FIELD_NAMES.packageType]: 'package_type',
  [FIELD_NAMES.textOnly]: 'text_only',
  [FIELD_NAMES.status]: 'status',
  [FIELD_NAMES.hidden]: 'is_hidden',
  [FIELD_NAMES.isLocked]: 'is_locked',
  [FIELD_NAMES.platform]: 'platform',
  [FIELD_NAMES.version]: 'version',
  [FIELD_NAMES.gender]: 'gender',
  [FIELD_NAMES.updateRequired]: 'update_required',
  [FIELD_NAMES.maxKarmaGives]: 'max_karma_gives',
  [FIELD_NAMES.tagsCount]: 'tags_count',
  [FIELD_NAMES.countryCode]: 'country_code',
  [FIELD_NAMES.exclusive]: 'exclusive',
  [FIELD_NAMES.blockedFromPurchase]: 'blocked',
  [FIELD_NAMES.confirmed]: 'confirmed',
  [FIELD_NAMES.flatFee]: 'flat_fee',
  [FIELD_NAMES.fixedFee]: 'fixed_fee',
  [FIELD_NAMES.active]: 'active',
  [FIELD_NAMES.dealType]: 'deal_type',
  [FIELD_NAMES.temporaryPriorityUntil]: 'temporary_priority_until',

  retailerName: 'retailer_name',
  retailerId: 'retailer_id',
  name: 'name',
  setId: 'image_set_id',
  main: 'main',
  termsAndConditions: 'special_t_and_c',
  description: 'description',
  metaDescription: 'meta_description',
  whatHappensNext: 'what_happens_next',
  faq: 'faq',
  hasHeaderWebImage: 'has_header_web_image',
  logoImageUri: 'logo_image_uri',
  brandImage1: 'brand_image_1',
  brandImage2: 'brand_image_2',
  brandImage3: 'brand_image_3',
  brandImage4: 'brand_image_4',
  brandImage5: 'brand_image_5',
  brandImage6: 'brand_image_6',
  brandImage7: 'brand_image_7',
  brandImage8: 'brand_image_8',
  brandImage9: 'brand_image_9',
  brandImage10: 'brand_image_10',
  brandImage11: 'brand_image_11',
  brandImage12: 'brand_image_12',
}

export const RELATIONSHIPS_NAMES = {
  developers: 'developers',
  bonusesUploadAttempts: 'admin_bonus_uploads',
  products: 'available_products',
  mobileNotifications: 'mobile_notifications',
  mobileAppVersions: 'mobile_app_versions',
  extensionVersions: 'extension_versions',
  tag: 'tag',
  users: 'users',
  rules: 'rules',
  ruleSlugs: 'rule_slugs',
  payments: 'payments',
  rule: 'rule',
  coupons: 'shop_offers',
  admins: 'admins',
  images: 'image_sets',
  imagesData: 'images',
  packages: 'ad_packages',
  packageAsset: 'ad_package_asset',
  packageAssets: 'ad_package_assets',
  comment: 'retailer_comment',
  contacts: 'retailer_contacts',
  admin: 'admin',
  retailer: 'retailer',
  retailerId: 'retailer_id',
  main: 'main_retailers',
  mainRetailer: 'main_retailer',
  regional: 'regional_retailers',
  mobile: 'mobile_retailers',
  cb: 'current_cashback_stake',
  promoCb: 'current_cashback_promoted_stake',
  plusCb: 'current_cashback_plus_stake',
  promoPlusCb: 'current_cashback_plus_promoted_stake',
  categories: 'categories',
  retailersCategories: 'retailers_categories',
  groups: 'groups',
  group: 'group',
  category: 'category',
  retailerGroups: 'retailer_groups',
  retailerCategories: 'retailer_categories',
  retailers: 'retailers',
  landingPages: 'landing_pages',
  backoffice: 'backoffice',
  campaignDeals: 'campaign_deals',
  lead: 'lead',
  user: 'user',
  shops: 'shops',
  localizedShops: 'localized_shops',
  resetPassword: 'reset_password',
  sendResetPasswordEmail: 'send_reset_password_email',
  shopOffers: 'shop_offers',
  adPackages: 'ad_packages',
  shopGroups: 'shop_groups',
  shopCategories: 'shop_categories',

  //java
  adminJava: 'admin/v1',
  aggregatedTransaction: 'admin/v1/aggregated-transaction',
  paymentTransaction: 'admin/v1/payment-transaction',
  paymentTransactionAdPackage: 'admin/v1/payment-transaction/ad-package',
  paymentTransactionDataPackage: 'admin/v1/payment-transaction/data-package',
  paymentTransactionManual: 'admin/v1/payment-transaction/manual',
}

// export const CASHBACK_ENTITIES = [
//   ENTITY_NAMES.cb,
//   ENTITY_NAMES.promoCb,
//   ENTITY_NAMES.plusCb,
//   ENTITY_NAMES.promoPlusCb,
// ]

// export const CASHBACK_ENTITIES_WITH_DATES = [
//   ENTITY_NAMES.promoCb,
//   ENTITY_NAMES.promoPlusCb,
// ]

export const ENTITY_ATTRS_SUBFIELDS = {
  question: 'question',
  answer: 'answer',
  link: 'link',
  mobileLink: 'mobile_link',
}

export const RETAILER_ATTRS_RESET_VALUES = {
  [ENTITY_ATTRS.termsAndConditions]: '',
  [ENTITY_ATTRS.whatHappensNext]: '',
  [ENTITY_ATTRS.description]: '',
  [ENTITY_ATTRS.metaDescription]: '',
}
