import styled from 'styled-components'
import { Collapse, Layout } from 'antd'

export const Wrapper = styled(Layout.Content)`
  min-height: calc(100vh - 64px);
  padding: 10px;
`

export const StyledCollapse = styled(Collapse)`
  margin-top: 10px;

  .ant-collapse-content-box {
    padding: 0;

    .ant-layout-content {
      margin-top: 0;
    }
  }

  .ant-collapse-item .ant-collapse-header-collapsible-only {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fafafa;
  }
`

export const Title = styled.h2`
  margin: 0;
  font-size: 16px;
`
