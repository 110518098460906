import last from 'lodash/last'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import {
  FIELD_NAMES,
  GROUP_FIELD_NAME_PREFIX,
  JAVA_FIELD_NAMES,
} from 'const/forms'
import { ENTITY_ATTRS, ENTITY_NAMES } from 'const/api'
import {
  RETAILER_TYPES_IN_ATTRS,
  RETAILER_TYPES,
  REGION_VALUES,
  RETAILER_TYPES_WITH_CATEGORIES,
} from 'const/retailers'
import omitBy from 'lodash/omitBy'
import { ROUTES_PERMISSIONS } from 'const/routes'

export const MAP_FILTER_TO_FILTER_NAME = {
  [FIELD_NAMES.country]: ENTITY_ATTRS[FIELD_NAMES.countryCode],
  [FIELD_NAMES.role]: ENTITY_ATTRS[FIELD_NAMES.role],
  type: 'type',
  [FIELD_NAMES.showOnUi]: ENTITY_ATTRS[FIELD_NAMES.showOnUi],
  [FIELD_NAMES.mediaType]: ENTITY_ATTRS[FIELD_NAMES.mediaType],
  [FIELD_NAMES.packageType]: ENTITY_ATTRS[FIELD_NAMES.packageType],
  [FIELD_NAMES.paypalConnected]: ENTITY_ATTRS[FIELD_NAMES.paypalConnected],
  [FIELD_NAMES.paypalConfirmed]: ENTITY_ATTRS[FIELD_NAMES.paypalConfirmed],
  [FIELD_NAMES.otherExtensionInstalled]:
    ENTITY_ATTRS[FIELD_NAMES.otherExtensionInstalled],
  [FIELD_NAMES.delivered]: ENTITY_ATTRS[FIELD_NAMES.delivered],
  [FIELD_NAMES.userEmail]: ENTITY_ATTRS[FIELD_NAMES.userEmail],
  [FIELD_NAMES.offerType]: ENTITY_ATTRS[FIELD_NAMES.offerType],
  [FIELD_NAMES.code]: ENTITY_ATTRS[FIELD_NAMES.code],
  [FIELD_NAMES.exclusive]: ENTITY_ATTRS[FIELD_NAMES.exclusive],
  [FIELD_NAMES.temporaryPriorityUntil]:
    ENTITY_ATTRS[FIELD_NAMES.temporaryPriorityUntil],
  [FIELD_NAMES.textOnly]: ENTITY_ATTRS[FIELD_NAMES.textOnly],
  [FIELD_NAMES.status]: ENTITY_ATTRS[FIELD_NAMES.status],
  [FIELD_NAMES.actionOnClick]: ENTITY_ATTRS[FIELD_NAMES.actionOnClick],
  [FIELD_NAMES.source]: ENTITY_ATTRS[FIELD_NAMES.source],
  [FIELD_NAMES.state]: ENTITY_ATTRS[FIELD_NAMES.state],
  [FIELD_NAMES.platform]: ENTITY_ATTRS[FIELD_NAMES.platform],
  [FIELD_NAMES.updateRequired]: ENTITY_ATTRS[FIELD_NAMES.updateRequired],
  [FIELD_NAMES.customerStatus]: ENTITY_ATTRS[FIELD_NAMES.customerStatus],
  [FIELD_NAMES.internalStatus]: ENTITY_ATTRS[FIELD_NAMES.internalStatus],
  [FIELD_NAMES.confirmed]: ENTITY_ATTRS[FIELD_NAMES.confirmed],
  [FIELD_NAMES.active]: ENTITY_ATTRS[FIELD_NAMES.active],
  [FIELD_NAMES.dealType]: ENTITY_ATTRS[FIELD_NAMES.dealType],
  [FIELD_NAMES.authorizedRoles]: ENTITY_ATTRS[FIELD_NAMES.roles],
}

const formatCountryCodeFilter = (values, withoutDomainRetailers) => {
  const filters = values?.reduce(
    (result, filter) => {
      if (
        RETAILER_TYPES.includes(filter) &&
        !(filter === REGION_VALUES.domain && withoutDomainRetailers)
      ) {
        result.type.push(filter)
        result.code.push('null')
      } else if (values.includes(REGION_VALUES.regional)) {
        result.type.push(RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.regional])
        result.code.push(REGION_VALUES.regional)
      } else {
        result.type.push(RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.regional])
        result.code.push(filter)
      }
      return result
    },
    {
      type: [],
      code: [],
    }
  )

  return values
    ? `${
        filters.type.length
          ? `&filter[${MAP_FILTER_TO_FILTER_NAME['type']}]=${uniq(
              filters.type
            )}`
          : ''
      }` +
        `${
          filters.code.length
            ? `&filter[${
                MAP_FILTER_TO_FILTER_NAME[FIELD_NAMES.country]
              }]=${uniq(filters.code)}`
            : ''
        }`
    : ''
}

export const MAP_JAVA_FILTER_TO_FILTER_NAME = {
  [JAVA_FIELD_NAMES.stripeVerified]: JAVA_FIELD_NAMES.stripeVerified,
  [JAVA_FIELD_NAMES.flowVerified]: JAVA_FIELD_NAMES.flowVerified,
  [JAVA_FIELD_NAMES.sentToUser]: 'sent',
  [JAVA_FIELD_NAMES.commissionType]: JAVA_FIELD_NAMES.commissionType,
}

export const MAP_SORTER_TO_FILTER_NAME = {
  [FIELD_NAMES.id]: ENTITY_ATTRS[FIELD_NAMES.id],
  [FIELD_NAMES.maxKarmaGives]: ENTITY_ATTRS[FIELD_NAMES.maxKarmaGives],
  [FIELD_NAMES.tagId]: ENTITY_ATTRS[FIELD_NAMES.tagId],
  [FIELD_NAMES.startedAt]: ENTITY_ATTRS[FIELD_NAMES.startedAt],
  [FIELD_NAMES.expiredAt]: ENTITY_ATTRS[FIELD_NAMES.expiredAt],
  [FIELD_NAMES.updatedAt]: ENTITY_ATTRS[FIELD_NAMES.updatedAt],
  [FIELD_NAMES.successUseCount]: ENTITY_ATTRS[FIELD_NAMES.successUseCount],
  [FIELD_NAMES.lastSuccessUse]: ENTITY_ATTRS[FIELD_NAMES.lastSuccessUse],
  [FIELD_NAMES.totalUseCount]: ENTITY_ATTRS[FIELD_NAMES.totalUseCount],
  [FIELD_NAMES.countdownDate]: ENTITY_ATTRS[FIELD_NAMES.countdownDate],
  [FIELD_NAMES.deliveredAt]: ENTITY_ATTRS[FIELD_NAMES.deliveredAt],
  [FIELD_NAMES.rate]: ENTITY_ATTRS[FIELD_NAMES.rate],
  [FIELD_NAMES.userId]: ENTITY_ATTRS[FIELD_NAMES.userId],
  [FIELD_NAMES.walletTransactionId]:
    ENTITY_ATTRS[FIELD_NAMES.walletTransactionId],
  [FIELD_NAMES.paymentName]: ENTITY_ATTRS[FIELD_NAMES.paymentName],
  [FIELD_NAMES.amount]: ENTITY_ATTRS[FIELD_NAMES.amount],
  [FIELD_NAMES.createdBy]: ENTITY_ATTRS[FIELD_NAMES.createdBy],
  [FIELD_NAMES.userEmail]: ENTITY_ATTRS[FIELD_NAMES.userEmail],
  [FIELD_NAMES.mainId]: ENTITY_ATTRS[FIELD_NAMES.mainRetailerId],
  [FIELD_NAMES.name]: ENTITY_ATTRS[FIELD_NAMES.name],
  [FIELD_NAMES.version]: ENTITY_ATTRS[FIELD_NAMES.version],
  [FIELD_NAMES.email]: ENTITY_ATTRS[FIELD_NAMES.email],
  [FIELD_NAMES.retailerName]: ENTITY_ATTRS[FIELD_NAMES.retailerName],
  [FIELD_NAMES.adPackageName]: ENTITY_ATTRS[FIELD_NAMES.adPackageName],
  [FIELD_NAMES.lastSignIn]: ENTITY_ATTRS[FIELD_NAMES.lastSignIn],
  [FIELD_NAMES.numberOfSignIn]: ENTITY_ATTRS[FIELD_NAMES.numberOfSignIn],
  [FIELD_NAMES.registrationDate]: ENTITY_ATTRS[FIELD_NAMES.registrationDate],
  [FIELD_NAMES.totalCash]: ENTITY_ATTRS[FIELD_NAMES.totalCash],
  [FIELD_NAMES.totalBalance]: ENTITY_ATTRS[FIELD_NAMES.totalBalance],
  [FIELD_NAMES.finalAmount]: ENTITY_ATTRS[FIELD_NAMES.finalAmount],
  [FIELD_NAMES.initialAmount]: ENTITY_ATTRS[FIELD_NAMES.initialAmount],
  [FIELD_NAMES.stake]: ENTITY_ATTRS[FIELD_NAMES.stake],
  [FIELD_NAMES.priority]: ENTITY_ATTRS[FIELD_NAMES.priority],
  [FIELD_NAMES.createdAt]: ENTITY_ATTRS[FIELD_NAMES.createdAt],
  [FIELD_NAMES.startDate]: ENTITY_ATTRS[FIELD_NAMES.startDate],
  [FIELD_NAMES.endDate]: ENTITY_ATTRS[FIELD_NAMES.endDate],
  [FIELD_NAMES.pendingCash]: ENTITY_ATTRS[FIELD_NAMES.pendingCash],
  [FIELD_NAMES.ruleId]: ENTITY_ATTRS[FIELD_NAMES.ruleId],
  [FIELD_NAMES.flatFee]: ENTITY_ATTRS[FIELD_NAMES.flatFee],
  [FIELD_NAMES.fixedFee]: ENTITY_ATTRS[FIELD_NAMES.fixedFee],
  [FIELD_NAMES.temporaryPriorityUntil]:
    ENTITY_ATTRS[FIELD_NAMES.temporaryPriorityUntil],
}

export const REDUCE_SORT_ORDER = {
  ascend: '',
  descend: '-',
  undefined: '',
}

export const JAVA_REDUCE_SORT_ORDER = {
  ascend: 'asc',
  descend: 'desc',
  undefined: '',
}

export const mapFilters = (filters = {}, withCategories) => {
  if (isEmpty(filters) && withCategories) {
    return `&filter[${
      MAP_FILTER_TO_FILTER_NAME[FIELD_NAMES.type]
    }]=${RETAILER_TYPES_WITH_CATEGORIES}`
  }
  const formattedFilters = Object.entries(filters).reduce(
    (result, [name, selectedValue]) => {
      let value = selectedValue
      if (name === FIELD_NAMES.country) {
        value = formatCountryCodeFilter(selectedValue, withCategories)
        return {
          ...result,
          query: result.query.concat(value),
        }
      }
      if (name.includes(GROUP_FIELD_NAME_PREFIX) && value) {
        value.forEach((item) => {
          result[item === 'true' ? 'active' : 'inactive'].push(
            last(name.split('_'))
          )
        })
        return result
      }
      return {
        ...result,
        query: result.query.concat(
          value !== null
            ? `&filter[${MAP_FILTER_TO_FILTER_NAME[name]}]=${value || ''}`
            : ''
        ),
      }
    },
    { query: '', active: [], inactive: [] }
  )
  return formattedFilters.query.concat(
    !isEmpty(formattedFilters.active)
      ? `&filter[groups_active]=${formattedFilters.active}`
      : '',
    !isEmpty(formattedFilters.inactive)
      ? `&filter[groups_inactive]=${formattedFilters.inactive}`
      : ''
  )
}

export const mapSorters = (sorter) => {
  if (!sorter?.order) return ''
  const { order, columnKey } = sorter
  if (columnKey.includes(GROUP_FIELD_NAME_PREFIX)) {
    const groupId = last(columnKey.split('_'))
    return `&sort=${REDUCE_SORT_ORDER[order]}group_priority&sort_params[group_id]=${groupId}`
  }
  return `&sort=${REDUCE_SORT_ORDER[order]}${MAP_SORTER_TO_FILTER_NAME[columnKey]}`
}

export const areFiltersAndSorterEmpty = (filters, sorter) => {
  const areFiltersEmpty = isEmpty(omitBy(filters, isEmpty))
  const isSorterActive = !!sorter?.column
  return !areFiltersEmpty || isSorterActive
}

export const filterLinksByRole = (links, roles) =>
  links.reduce((result, link) => {
    const routePermissions = ROUTES_PERMISSIONS[link]
    const isAvailable =
      roles?.some((userRole) => routePermissions?.includes(userRole)) ||
      routePermissions?.length === 0

    return isAvailable ? [...result, link] : result
  }, [])

export const getAvailableHeaderLinks = (links, roles) =>
  links.reduce((result, link) => {
    const children = link.items ? filterLinksByRole(link.items, roles) : null
    const isAvailable = !!children.length

    return isAvailable
      ? [
          ...result,
          {
            ...link,
            children,
          },
        ]
      : result
  }, [])
