import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  saveFiltersAndSorter,
  fetchGiveawaysSuccess,
  fetchGiveawaysFailure,
  startSaving,
  finishSaving,
  clearData,
} = reducer.actions

export const fetchGiveaways = createAction('giveaways/fetchGiveaways')

export const updateGiveaway = createAction('giveaways/updateGiveaway')
export const updateGiveawaySuccess = createAction(
  'giveaways/updateGiveawaySuccess'
)
export const updateGiveawayFailure = createAction(
  'giveaways/updateGiveawayFailure'
)

export const createGiveaway = createAction('giveaways/createGiveaway')
export const createGiveawaySuccess = createAction(
  'giveaways/createGiveawaySuccess'
)
export const createGiveawayFailure = createAction(
  'giveaways/createGiveawayFailure'
)

export const deleteGiveaway = createAction('giveaways/deleteGiveaway')
export const deleteGiveawaySuccess = createAction(
  'giveaways/deleteGiveawaySuccess'
)
export const deleteGiveawayFailure = createAction(
  'giveaways/deleteGiveawayFailure'
)
