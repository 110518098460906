import React, { useEffect } from 'react'
import { Layout, Typography, Col, Row, Table } from 'antd'
import { JAVA_FIELD_NAMES } from 'const/forms'
import { useSelector } from 'react-redux'
import { getTransactionDetails } from 'domains/paymentTransactions/selectors'
import { transactionsDetailsColumns } from './columns'
import useAction from '../../../utils/useAction'
import { fetchTransactionDetails } from '../../../actions/views/paymentTransactions'
import { clearTransactionDetails } from '../../../domains/paymentTransactions/actions'

const { Title } = Typography

const TransactionDetailsModal = ({ id }) => {
  const transactionDetails = useSelector(getTransactionDetails)
  const fetchTransactionDetailsFunc = useAction(fetchTransactionDetails)
  const clearTransactionDetailsFunc = useAction(clearTransactionDetails)

  useEffect(() => {
    fetchTransactionDetailsFunc({ id })

    return () => {
      clearTransactionDetailsFunc()
    }
  }, [])

  return (
    <Layout.Content style={{ minWidth: '650px' }}>
      <Title level={3}>#{id}</Title>
      {!!transactionDetails && (
        <>
          <Row>
            <Col span={12}>
              <Title level={4}>User:</Title>
              User Id: {transactionDetails?.[JAVA_FIELD_NAMES.userId]}
              <br />
              UID Token: {transactionDetails?.[JAVA_FIELD_NAMES.uidToken]}
              <br />
              Email: {transactionDetails?.[JAVA_FIELD_NAMES.userEmail]}
              <br />
              Name: {transactionDetails?.[JAVA_FIELD_NAMES.userName]}
            </Col>
            <Col span={12}>
              <Title level={4}>Dates:</Title>
              Payment Date: {transactionDetails?.[JAVA_FIELD_NAMES.paymentDate]}
              <br />
              Created At: {transactionDetails?.[JAVA_FIELD_NAMES.createdAt]}
              <br />
              Updated At: {transactionDetails?.[JAVA_FIELD_NAMES.updatedAt]}
              <br />
              Processed At: {transactionDetails?.[JAVA_FIELD_NAMES.processedAt]}
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Title level={4}>Commission:</Title>
              Commission Id:{' '}
              {transactionDetails?.[JAVA_FIELD_NAMES.commissionId]}
              <br />
              Sale Value:{' '}
              {transactionDetails?.[JAVA_FIELD_NAMES.commissionOrderValue]}
              <br />
              Commission Value:{' '}
              {transactionDetails?.[JAVA_FIELD_NAMES.currency]}&nbsp;
              {transactionDetails?.[JAVA_FIELD_NAMES.commissionValue]}
              <br />
              Commission Type:{' '}
              {transactionDetails?.[JAVA_FIELD_NAMES.commissionType]}
              <br />
              Network: {transactionDetails?.[JAVA_FIELD_NAMES.network]}
              <br />
              Status: {transactionDetails?.[JAVA_FIELD_NAMES.status]}
              <br />
            </Col>
            <Col span={12}>
              <Title level={4}>Retailer:</Title>
              Retailer Domain:{' '}
              {transactionDetails?.[JAVA_FIELD_NAMES.retailerDomain]}
              <br />
              Rule: {transactionDetails?.[JAVA_FIELD_NAMES.ruleName]}&nbsp; (
              {transactionDetails?.[JAVA_FIELD_NAMES.ruleId]})<br />
              T-Token: {transactionDetails?.[JAVA_FIELD_NAMES.tToken]}
              <br />
            </Col>
          </Row>
          {!!transactionDetails?.products?.length && (
            <Table
              columns={transactionsDetailsColumns()}
              dataSource={transactionDetails.products}
            />
          )}
        </>
      )}
    </Layout.Content>
  )
}

export default TransactionDetailsModal
