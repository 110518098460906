import { ENTITY_NAMES } from 'const/api'
import { FIELD_NAMES } from 'const/forms'
import { mapFilters, mapSorters } from 'helpers/jsonAPI'

export const formatSEOPage = (SEOPage) => {
  return {
    [FIELD_NAMES.id]: SEOPage[FIELD_NAMES.id],
    [FIELD_NAMES.name]: SEOPage.attributes[FIELD_NAMES.name],
    [FIELD_NAMES.description]: SEOPage.attributes[FIELD_NAMES.description],
    [FIELD_NAMES.isPublished]: !SEOPage.attributes[FIELD_NAMES.isDraft],
    [FIELD_NAMES.smallImage]: SEOPage.attributes[FIELD_NAMES.smallImage],
    [FIELD_NAMES.largeImage]: SEOPage.attributes[FIELD_NAMES.largeImage],
    [FIELD_NAMES.title]: SEOPage.attributes[FIELD_NAMES.title],
    [FIELD_NAMES.excerpt]: SEOPage.attributes[FIELD_NAMES.excerpt],
    [FIELD_NAMES.seoLink]: SEOPage.attributes[FIELD_NAMES.seoLink],
    [FIELD_NAMES.altText]: SEOPage.attributes[FIELD_NAMES.altText],
    key: SEOPage[FIELD_NAMES.id],
  }
}

export const formatSEOPages = (SEOPages = {}) =>
  Object.values(SEOPages).reduce(
    (result, SEOPage) => ({
      ...result,
      [SEOPage[FIELD_NAMES.id]]: formatSEOPage(SEOPage),
    }),
    {}
  ) || {}

export const formatPayloadForFetchRequest = ({
  page,
  filters,
  sorter,
  search,
} = {}) => ({
  page: page || 1,
  filters: mapFilters(filters),
  sorter: mapSorters(sorter),
  search,
})

export const formatSEOPageForRequests = (
  {
    [FIELD_NAMES.id]: id,
    [FIELD_NAMES.isPublished]: isPublished,
    [FIELD_NAMES.smallImage]: smallImage,
    [FIELD_NAMES.largeImage]: largeImage,
    [FIELD_NAMES.seoLink]: _seoLink,
    key,
    ...values
  },
  { isImageUpdate = false, isPublish = false } = {}
) => {
  const attributes = isPublish
    ? {
        [FIELD_NAMES.isDraft]: isPublished,
      }
    : {
        ...values,
        ...(isImageUpdate
          ? {
              [FIELD_NAMES.smallImage]: smallImage,
              [FIELD_NAMES.largeImage]: largeImage,
            }
          : {}),
      }

  return {
    ...(id && { id }),
    type: ENTITY_NAMES.SEOPages,
    attributes,
  }
}
