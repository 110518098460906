import React from 'react'
import { Col, Layout, Row, Spin } from 'antd'
import arrayMutators from 'final-form-arrays'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import useAction from 'utils/useAction'
import { FIELD_NAMES } from 'const/forms'
import { required, validUserEmail } from 'helpers/validators'
import { selectors as adminSelectors } from 'domains/admin'
import TextInput from 'components/TextInput'
import Select from 'components/Select'
import Title from 'components/Title/Title'
import { Buttons, StyledButton } from './Admin.styles'
import { ROLES_OPTIONS } from 'const/roles'
import * as actions from 'actions/views/roles'

const Admin = ({ id, closeModal }) => {
  const isSavingActive = useSelector(adminSelectors.getIsSavingActive)
  const adminData = useSelector(adminSelectors.getAdminById)(id)
  const createAdminFunc = useAction(actions.createAdmin)
  const updateAdminFunc = useAction(actions.updateAdmin)

  const initialValues = id ? adminData : {}

  const onSubmit = (values) => {
    if (id) {
      updateAdminFunc({ values, callback: closeModal })
    } else {
      createAdminFunc({ values, callback: closeModal })
    }
  }

  return (
    <Layout.Content>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        render={({ hasValidationErrors, handleSubmit }) => {
          return (
            <Spin spinning={isSavingActive} size="large">
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Title>Name</Title>
                  <Field
                    validate={required}
                    component={TextInput}
                    name={FIELD_NAMES.name}
                  />
                </Col>
                <Col span={12}>
                  <Title>Email</Title>
                  <Field
                    validate={validUserEmail}
                    component={TextInput}
                    name={FIELD_NAMES.email}
                    disabled={id}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Title>Role</Title>
                  <Field
                    labelInValue={false}
                    validate={required}
                    options={ROLES_OPTIONS}
                    component={Select}
                    name={FIELD_NAMES.authorizedRoles}
                    mode="multiple"
                  />
                </Col>
              </Row>
              <Buttons>
                <StyledButton size="default" onClick={closeModal}>
                  Cancel
                </StyledButton>

                <StyledButton
                  disabled={hasValidationErrors}
                  size="default"
                  type="primary"
                  onClick={handleSubmit}
                >
                  Save
                </StyledButton>
              </Buttons>
            </Spin>
          )
        }}
      />
    </Layout.Content>
  )
}

export default Admin
