import { all, put, take, takeLatest, race, select } from 'redux-saga/effects'

import { startLoading, finishLoading } from 'reducers/layoutSlice'
import {
  actions as landingPagesActions,
  formatters as landingPagesFormatters,
  selectors as landingPagesSelectors,
} from 'domains/landingPages'
import * as actions from 'actions/views/landingPageImages'

function* fetchLandingPagesWorker({ payload } = {}) {
  try {
    const search = yield select(landingPagesSelectors.getSearchString)
    const body = landingPagesFormatters.formatPayloadForFetchRequest(payload)
    yield all([
      put(startLoading()),
      put(landingPagesActions.fetchLandingPages({ search, ...body })),
    ])
    const [success] = yield race([
      take(landingPagesActions.fetchLandingPagesSuccess),
      take(landingPagesActions.fetchLandingPagesFailure),
    ])
    if (success) {
      yield put(landingPagesActions.saveFiltersAndSorter(payload))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* deleteLandingPageWorker({ payload }) {
  try {
    yield all([
      put(startLoading()),
      put(landingPagesActions.deleteLandingPage(payload)),
    ])
    const [success] = yield race([
      take(landingPagesActions.deleteLandingPageSuccess),
      take(landingPagesActions.deleteLandingPageFailure),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(landingPagesSelectors.getSearchString),
        select(landingPagesSelectors.getPage),
        select(landingPagesSelectors.getFilters),
        select(landingPagesSelectors.getSorter),
      ])
      yield put(
        actions.fetchLandingPages({
          search,
          page,
          filters,
          sorter,
        })
      )
      yield race([
        take(landingPagesActions.fetchLandingPagesSuccess),
        take(landingPagesActions.fetchLandingPagesFailure),
      ])
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* createLandingPageWorker({ payload }) {
  try {
    const body = landingPagesFormatters.formatLandingPageForRequests(
      payload.values,
      { isCreate: true }
    )
    yield all([
      put(landingPagesActions.startSaving()),
      put(landingPagesActions.createLandingPage(body)),
    ])
    const [success] = yield race([
      take(landingPagesActions.createLandingPageSuccess),
      take(landingPagesActions.createLandingPageFailure),
    ])

    if (success) {
      if (payload.values.image_url) {
        const imageBody = landingPagesFormatters.formatLandingPageForRequests(
          {
            id: success.payload.data.id,
            image_url: payload.values.image_url,
          },
          { isImageUpdate: true }
        )

        yield put(
          landingPagesActions.updateLandingPage({
            data: imageBody,
            updateImage: true,
          })
        )

        yield race([
          take(landingPagesActions.updateLandingPageSuccess),
          take(landingPagesActions.updateLandingPageFailure),
        ])
      }

      const [search, page, filters, sorter] = yield all([
        select(landingPagesSelectors.getSearchString),
        select(landingPagesSelectors.getPage),
        select(landingPagesSelectors.getFilters),
        select(landingPagesSelectors.getSorter),
      ])
      yield put(
        actions.fetchLandingPages({
          search,
          page,
          filters,
          sorter,
        })
      )
      yield race([
        take(landingPagesActions.fetchLandingPagesSuccess),
        take(landingPagesActions.fetchLandingPagesFailure),
      ])
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(landingPagesActions.finishSaving())
  }
}

function* updateLandingPageWorker({ payload }) {
  try {
    const body = landingPagesFormatters.formatLandingPageForRequests(
      payload.values
    )
    const imageBody = landingPagesFormatters.formatLandingPageForRequests(
      payload.values,
      { isImageUpdate: true }
    )

    yield all([
      put(landingPagesActions.startSaving()),
      put(landingPagesActions.updateLandingPage({ data: body })),
    ])
    const [success] = yield all([
      race([
        take(landingPagesActions.updateLandingPageSuccess),
        take(landingPagesActions.updateLandingPageFailure),
      ]),
    ])

    if (success) {
      yield put(
        landingPagesActions.updateLandingPage({
          data: imageBody,
          updateImage: true,
        })
      )
      const successImage = yield race([
        take(landingPagesActions.updateLandingPageSuccess),
        take(landingPagesActions.updateLandingPageFailure),
      ])

      if (successImage) {
        const [search, page, filters, sorter] = yield all([
          select(landingPagesSelectors.getSearchString),
          select(landingPagesSelectors.getPage),
          select(landingPagesSelectors.getFilters),
          select(landingPagesSelectors.getSorter),
        ])
        yield put(
          actions.fetchLandingPages({
            search,
            page,
            filters,
            sorter,
          })
        )
        yield race([
          take(landingPagesActions.fetchLandingPagesSuccess),
          take(landingPagesActions.fetchLandingPagesFailure),
        ])
        payload.callback()
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(landingPagesActions.finishSaving())
  }
}

export default function* () {
  yield all([
    fetchLandingPagesWorker(),
    takeLatest(actions.fetchLandingPages, fetchLandingPagesWorker),
    takeLatest(actions.createLandingPage, createLandingPageWorker),
    takeLatest(actions.updateLandingPage, updateLandingPageWorker),
    takeLatest(actions.deleteLandingPage, deleteLandingPageWorker),
  ])
}
