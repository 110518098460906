import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  saveFiltersAndSorter,
  fetchCampaignsSuccess,
  fetchCampaignFailure,
  startSaving,
  finishSaving,
  clearData,
} = reducer.actions

export const fetchCampaigns = createAction('campaigns/fetchCampaigns')

export const createCampaign = createAction('campaigns/createCampaign')
export const createCampaignSuccess = createAction(
  'campaigns/createCampaignSuccess'
)
export const createCampaignFailure = createAction(
  'campaigns/createCampaignFailure'
)

export const updateCampaign = createAction('campaigns/updateCampaign')
export const updateCampaignSuccess = createAction(
  'campaigns/updateCampaignSuccess'
)
export const updateCampaignFailure = createAction(
  'campaigns/updateCampaignFailure'
)

export const deleteCampaign = createAction('campaigns/deleteCampaign')
export const deleteCampaignSuccess = createAction(
  'campaigns/deleteCampaignSuccess'
)
export const deleteCampaignFailure = createAction(
  'campaigns/deleteCampaignFailure'
)
