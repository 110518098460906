import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    startLoading: (state) => ({ ...state, isLoading: true }),
    finishLoading: (state) => ({ ...state, isLoading: false }),
  },
})

export const { startLoading, finishLoading } = layoutSlice.actions

export default layoutSlice.reducer
