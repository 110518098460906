import React, { useContext, useCallback } from 'react'
import Context from './Context/Context'
import Provider from './Provider/Provider'
import { StyledModal } from './Modal.styles.js'

const Modal = ({ id, footer, ...props }) => {
  const modal = useContext(Context)
  const isVisible = modal.activeID === id

  const cancelCallback = useCallback(() => {
    modal.close()
  }, [modal])

  return (
    <StyledModal
      width={700}
      onCancel={cancelCallback}
      open={isVisible}
      centered
      footer={null}
      {...props}
    />
  )
}

Modal.Provider = Provider
Modal.Context = Context

export default Modal
