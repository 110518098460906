import { mainApiInstance } from 'utils/request'

export const getFeatures = () => mainApiInstance.get('/features')

export const getFeaturesByPlatform = (platform) =>
  mainApiInstance.get(`configuration/${platform}/features`)

export const updateFeature = (values) =>
  mainApiInstance.put(`/feature/${values.id}`, values)

export const deleteFeature = (id) => mainApiInstance.delete(`/feature/${id}`)

export const createFeature = (values) =>
  mainApiInstance.post('/feature', values)

export const createFeatureConfigurationOption = (values) =>
  mainApiInstance.post('/feature/feature_configuration_option', values)

export const deleteFeatureConfigurationOption = (id) =>
  mainApiInstance.delete(`/feature/feature_configuration_option/${id}`)
