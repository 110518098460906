import styled from 'styled-components'
import { Button } from 'antd'

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const StyledButton = styled(Button)`
  align-self: flex-end;
`
