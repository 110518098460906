import { useRef } from 'react'
import noop from 'lodash/noop'
import useAction from './useAction'
import { openLeavingPageModal } from 'reducers/modals/actions'
import { ROUTER_PATH_TO_FORM_NAME } from 'const/forms'
import { useSelector } from 'react-redux'
import { isModalOpened } from 'reducers/modals/selectors'
import {
  getChangeFieldHandler,
  isFormChangesEmpty,
} from 'reducers/forms/selectors'
import useInternalClick from './useInternalClick'

export default (pathname, callback = noop) => {
  const contentRef = useRef(null)
  const openLeavingPageModalFunc = useAction(openLeavingPageModal)
  const formName = ROUTER_PATH_TO_FORM_NAME[pathname]
  const modalOpened = useSelector(isModalOpened)
  const changeFieldState = useSelector(getChangeFieldHandler)
  const isChangesEmpty = useSelector(isFormChangesEmpty)(formName)

  const handlePreventLeaving = () => {
    if (!modalOpened) {
      openLeavingPageModalFunc({
        isLeavingPageProcess: true,
        changeFieldState,
        formName,
        callback,
      })
    }
  }
  useInternalClick(contentRef, handlePreventLeaving, !isChangesEmpty)

  return contentRef
}
