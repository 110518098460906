import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import { diff } from 'deep-object-diff'
import { FIELD_NAMES } from 'const/forms'
import { transactionsColumns } from 'pages/CustomerService/columns'

export const loginHandler = (data) => {
  if (data && data.token && data.id) {
    localStorage.setItem('token', data.token)
    localStorage.setItem('role', data.attributes.role)
    localStorage.setItem('admin_id', data.id)
  }
}

export const clearUserDataInLS = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('cookie')
  localStorage.removeItem('admin_id')
  localStorage.removeItem('role')
}

export const getDisplayedColumnsKeys = (settings) =>
  omitBy(settings, (action) => action !== FIELD_NAMES.show)

export const prepareSettings = (config) =>
  config.reduce(
    (result, { value }) => ({
      ...result,
      [value]: 'show',
    }),
    {}
  )

export const getIsConfigChanged = (oldConfig, newConfig) =>
  !isEmpty(diff(oldConfig, newConfig))

export const getNewConfig = (newConfig) =>
  Object.keys(transactionsColumns).reduce(
    (result, key) => ({
      ...result,
      [key]: newConfig[key] ? FIELD_NAMES.show : FIELD_NAMES.hide,
    }),
    {}
  )
