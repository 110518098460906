import { createSelector } from '@reduxjs/toolkit'
import { areFiltersAndSorterEmpty } from 'helpers/filters'
import { FIELD_NAMES } from 'const/forms'
import ROLES from 'const/roles'
import { formatPaymentTransaction, formatUsers } from './formatters'
import memoize from 'lodash/memoize'

export const userState = ({ user }) => user

export const userId = createSelector(userState, ({ id }) => id)

export const token = createSelector(userState, ({ token }) => token)

export const userData = createSelector(userState, ({ data }) => data)

export const userRole = createSelector(
  userData,
  (data) => data.attributes?.[FIELD_NAMES.role]
)

export const isAdmin = createSelector(userRole, (role) => role === ROLES.ADMIN)

export const isModerator = createSelector(
  userRole,
  (role) => role === ROLES.MODERATOR
)

export const isMember = createSelector(
  userRole,
  (role) => role === ROLES.MEMBER
)

export const isLogged = createSelector(token, (token) => !!token)

export const transactionsLoading = createSelector(
  userState,
  ({ paymentTransactions }) => paymentTransactions.loading
)

export const transactions = createSelector(
  userState,
  ({ paymentTransactions }) =>
    paymentTransactions.data.map(formatPaymentTransaction)
)

export const paymentColumns = createSelector(
  userData,
  (data) => data.attributes[FIELD_NAMES.displayPreference]
)

export const transactionsPagination = createSelector(
  userState,
  ({ paymentTransactions }) => ({
    page: paymentTransactions.page,
    pageCount: paymentTransactions.pageCount,
  })
)

export const selectedEmail = createSelector(
  userState,
  ({ selectedEmail }) => selectedEmail
)

export const search = createSelector(userState, ({ search }) => search)

export const usersState = createSelector(userState, ({ users }) => users)

export const getAllUsers = createSelector(usersState, ({ data }) =>
  formatUsers(data)
)

export const getUserById = createSelector(getAllUsers, (users) =>
  memoize((id) =>
    id ? users.find((user) => user[FIELD_NAMES.id] === id) : null
  )
)

export const getIsSavingActive = createSelector(
  usersState,
  ({ isSavingActive }) => isSavingActive
)

export const getSearchString = createSelector(
  usersState,
  ({ search }) => search
)

export const getPage = createSelector(usersState, ({ page }) => page)

export const getFilters = createSelector(usersState, ({ filters }) => filters)

export const getSorter = createSelector(usersState, ({ sorter }) => sorter)

export const getPageCount = createSelector(
  usersState,
  ({ pageCount }) => pageCount
)

export const getIsDataFilteredOrSorted = createSelector(
  [getFilters, getSorter],
  areFiltersAndSorterEmpty
)

export const getIsLoaded = createSelector(
  usersState,
  ({ isLoaded }) => isLoaded
)

export const engagementMetricsLoading = createSelector(
  userState,
  ({ engagementMetrics }) => engagementMetrics.loading
)

export const engagementMetricsState = createSelector(
  userState,
  ({ engagementMetrics }) => engagementMetrics
)

export const engagementMetrics = createSelector(
  engagementMetricsState,
  ({ data }) => data
)

export const getVerification = createSelector(
  userState,
  ({ user }) => user.verificationCode
)

export const getIsVerificationCodeValid = createSelector(
  getVerification,
  ({ valid }) => valid
)

export const getVerificationCode = createSelector(
  getVerification,
  ({ code }) => code
)

export const getInvalidMessage = createSelector(
  getVerification,
  ({ message }) => message
)

export const getUserBonuses = createSelector(
  userState,
  ({ bonuses }) => bonuses
)

export const getBonusesLoaded = createSelector(
  userState,
  ({ areBonusesLoaded }) => areBonusesLoaded
)
