import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from './api'
import { getSortedIds } from 'helpers/jsonAPI'

function* fetchRuleSlugsWorker({ payload }) {
  try {
    const { data } = yield call(api.getRuleSlugs, payload)

    yield put(
      actions.fetchRuleSlugsSuccess({
        ...payload,
        page: payload.page,
        pageCount: data.meta.page_count,
        ruleSlugs: data,
        sortedIds: getSortedIds(data.data),
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchRuleSlugsFailure())
  }
}

function* deleteRuleSlugWorker({ payload }) {
  try {
    const { data } = yield call(api.deleteRuleSlug, payload)
    if (data) {
      yield put(actions.deleteRuleSlugSuccess(payload))
    } else {
      yield put(actions.deleteRuleSlugFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteRuleSlugFailure())
  }
}

function* updateRuleSlugWorker({ payload }) {
  try {
    const { data } = yield call(api.updateRuleSlug, payload)
    if (data) {
      yield put(actions.updateRuleSlugSuccess(payload))
    } else {
      yield put(actions.updateRuleSlugFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateRuleSlugFailure())
  }
}

function* createRuleSlugWorker({ payload }) {
  try {
    const { data } = yield call(api.createRuleSlug, payload)
    if (data) {
      yield put(actions.createRuleSlugSuccess(payload))
    } else {
      yield put(actions.createRuleSlugFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createRuleSlugFailure())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchRuleSlugs, fetchRuleSlugsWorker),
    takeLatest(actions.deleteRuleSlug, deleteRuleSlugWorker),
    takeLatest(actions.createRuleSlug, createRuleSlugWorker),
    takeLatest(actions.updateRuleSlug, updateRuleSlugWorker),
  ])
}
