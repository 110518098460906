import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  features: [],
  platformFeatures: {},
}

const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    fetchFeaturesSuccess: (state, { payload }) => ({
      ...state,
      features: payload,
    }),
    fetchFeaturesByPlatformSuccess: (state, { payload }) => ({
      ...state,
      platformFeatures: payload,
    }),
    createFeatureSuccess: (state, { payload }) => {
      state.features.unshift(payload)
    },
    deleteFeatureSuccess: (state, { payload }) => {
      state.features = state.features.filter(
        (feature) => feature.id !== payload
      )
    },
    createFeatureConfigurationOptionSuccess: (
      state,
      { payload: { data, featureId } }
    ) => {
      state.features
        .find((feature) => feature.id === featureId)
        .feature_configuration_options.push(data)
    },

    deleteFeatureConfigurationOptionSuccess: (
      state,
      { payload: { id, featureId } }
    ) => {
      let feature = state.features.find((feature) => feature.id === featureId)
      feature.feature_configuration_options =
        feature.feature_configuration_options.filter(
          (option) => option.id !== id
        )
    },
    updateFeatureSuccess: (state, { payload }) => {
      if (payload.action === 'create') {
        state.features.unshift(payload.feature)
      } else {
        state.features = state.features.map((feature) =>
          feature.id === payload.feature.id ? payload.feature : feature
        )
      }
    },
    clearData: () => initialState,
  },
})

export default featuresSlice
