import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'

const rootSelector = ({ features }) => features

export const featuresSelector = createSelector(
  [rootSelector],
  ({ features }) => features
)
export const platformFeaturesSelectors = createSelector(
  rootSelector,
  ({ platformFeatures }) => platformFeatures
)

export const someFeaturesSelector = createSelector(
  featuresSelector,
  (features) =>
    memoize((slugs) =>
      features.reduce(
        (result, item) =>
          slugs?.includes(item.slug)
            ? {
                ...result,
                [item.slug]: item,
              }
            : result,
        {}
      )
    )
)
