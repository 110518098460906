import { FIELD_NAMES } from 'const/forms'
import { mapFilters, mapSorters } from 'helpers/jsonAPI'
import { DEFAULT_PAYMENT_NAME } from '../../const/payments'
import { ENTITY_NAMES } from '../../const/api'

export const formatPaymentUploadAttempt = (attempt) => ({
  [FIELD_NAMES.id]: attempt[FIELD_NAMES.id],
  [FIELD_NAMES.createdAt]: attempt.attributes[FIELD_NAMES.createdAt],
  [FIELD_NAMES.csvLink]: attempt.attributes[FIELD_NAMES.csvLink],
  [FIELD_NAMES.status]: attempt.attributes[FIELD_NAMES.status],
  [FIELD_NAMES.error]: attempt.attributes[FIELD_NAMES.error],
  [FIELD_NAMES.adminName]: attempt.attributes[FIELD_NAMES.adminName],
  key: attempt[FIELD_NAMES.id],
})

export const formatPayment = (payment) => ({
  [FIELD_NAMES.id]: payment[FIELD_NAMES.id],
  [FIELD_NAMES.paymentName]:
    payment.attributes[FIELD_NAMES.paymentName] || DEFAULT_PAYMENT_NAME,
  [FIELD_NAMES.createdAt]: payment.meta[FIELD_NAMES.createdAt],
  [FIELD_NAMES.createdBy]: payment.attributes[FIELD_NAMES.createdBy],
  [FIELD_NAMES.walletTransactionId]:
    payment.attributes[FIELD_NAMES.walletTransactionId],
  [FIELD_NAMES.userId]: payment.attributes[FIELD_NAMES.userId],
  [FIELD_NAMES.countdownDate]: payment.attributes[FIELD_NAMES.countdownDate],
  [FIELD_NAMES.approvedBy]: payment.attributes[FIELD_NAMES.approvedBy],
  [FIELD_NAMES.rejectionReason]:
    payment.attributes[FIELD_NAMES.rejectionReason],
  [FIELD_NAMES.state]: payment.attributes[FIELD_NAMES.state],
  [FIELD_NAMES.amount]: payment.attributes[FIELD_NAMES.amount],
  [FIELD_NAMES.userEmail]: payment.attributes[FIELD_NAMES.userEmail],
  key: payment[FIELD_NAMES.id],
})

export const formatPaymentUploads = (attempts = {}) =>
  Object.values(attempts).reduce(
    (result, attempt) => ({
      ...result,
      [attempt[FIELD_NAMES.id]]: formatPaymentUploadAttempt(attempt),
    }),
    {}
  ) || {}

export const formatPayments = (attempts = {}) =>
  Object.values(attempts).reduce(
    (result, payment) => ({
      ...result,
      [payment[FIELD_NAMES.id]]: formatPayment(payment),
    }),
    {}
  ) || {}

export const formatPayloadForFetchRequest = ({
  page,
  filters,
  sorter,
  search,
} = {}) => ({
  page: page || 1,
  filters: mapFilters(filters),
  sorter: mapSorters(sorter, FIELD_NAMES.createdAt),
  search,
})

export const formatPaymentForUpdate = ({
  [FIELD_NAMES.id]: id,
  key,
  ...values
}) => ({
  [FIELD_NAMES.id]: id,
  type: ENTITY_NAMES.adminPayments,
  attributes: values,
})

export const formatPaymentForRequests = ({ key, ...values }) => ({
  ...values,
})
