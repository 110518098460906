import { createAction } from '@reduxjs/toolkit'

export const fetchShops = createAction('shop/fetchShops')
export const fetchLocalizedShops = createAction('shop/fetchLocalizedShops')
export const updateShop = createAction('shop/updateShop')
export const updateLocalizedShop = createAction('shop/updateLocalizedShop')
export const updateShopCouponsSettings = createAction(
  'shop/updateShopCouponsSettings'
)
export const fetchShopCampaignDeals = createAction(
  'shop/fetchShopCampaignDeals'
)
export const fetchShopAdPackages = createAction('shop/fetchShopAdPackages')
export const fetchShopOffers = createAction('shop/fetchShopOffers')

export const createShopCampaign = createAction('shop/campaign/createCampaign')
export const updateShopCampaign = createAction('shop/campaign/updateCampaign')

export const createShopOffer = createAction('shop/offer/createOffer')
export const updateShopOffer = createAction('shop/offer/updateOffer')

export const createShopGroup = createAction('shop/group/createGroup')
export const updateShopGroup = createAction('shop/group/updateGroup')
export const deleteShopGroup = createAction('shop/group/deleteGroup')

export const createShopCategory = createAction('shop/category/createCategory')
export const updateShopCategory = createAction('shop/category/updateCategory')
export const deleteShopCategory = createAction('shop/category/deleteCategory')

export const deleteCampaign = createAction('shop/campaign/deleteCampaign')
export const deleteAdPackage = createAction('shop/adPackage/deleteAdPackage')
export const duplicateAdPackage = createAction('shop/adPackage/duplicateAdPackage')

export const deleteShopOffer = createAction('shop/offer/deleteOffer')
export const fetchShopPrograms = createAction('shop/fetchShopPrograms')
