import { all, fork } from 'redux-saga/effects'
import search from '../domains/search/saga'
import routerSaga from './routerSaga'
import layoutSaga from './layout'

import { saga as user } from 'domains/user'
import { saga as admin } from 'domains/admin'
import { saga as general } from 'domains/general'
import { saga as retailers } from 'domains/retailers'
import { saga as adPackages } from 'domains/adPackages'
import { saga as features } from 'domains/features'
import { saga as products } from 'domains/products'
import { saga as bonuses } from 'domains/bonuses'
import { saga as coupons } from 'domains/coupons'
import { saga as campaigns } from 'domains/campaigns'
import { saga as payments } from 'domains/payments'
import { saga as rules } from 'domains/rules'
import { saga as mobileApp } from 'domains/mobileApp'
import { saga as extensionVersions } from 'domains/extensionVersions'
import { saga as algorithms } from 'domains/algorithms'
import { saga as notifications } from 'domains/notifications'
import { saga as landingPages } from 'domains/landingPages'
import { saga as marketingPages } from 'domains/marketingPages'
import { saga as SEOPages } from 'domains/SEOPages'
import { saga as giveaways } from 'domains/giveaways'
import { saga as developers } from 'domains/developers'
import { saga as pitaSettings } from 'domains/pitaSettings'
import { saga as shops } from 'domains/shops'
import { saga as adminLogs } from 'domains/adminLogs'
import { saga as transactions } from 'domains/transactions'
import { saga as paymentTransactions } from 'domains/paymentTransactions'
import { saga as creditCardsComparison } from 'domains/creditCardsComparison'

export default function* () {
  yield all([
    routerSaga(),
    layoutSaga(),
    fork(user),
    fork(admin),
    fork(general),
    fork(retailers),
    fork(bonuses),
    fork(products),
    fork(coupons),
    fork(campaigns),
    fork(payments),
    fork(adPackages),
    fork(mobileApp),
    fork(extensionVersions),
    fork(algorithms),
    fork(notifications),
    fork(features),
    fork(search),
    fork(rules),
    fork(landingPages),
    fork(marketingPages),
    fork(SEOPages),
    fork(giveaways),
    fork(developers),
    fork(pitaSettings),
    fork(shops),
    fork(adminLogs),
    fork(transactions),
    fork(paymentTransactions),
    fork(creditCardsComparison),
  ])
}
