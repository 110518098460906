import { all, put, take, takeLatest, race } from 'redux-saga/effects'

import { startLoading, finishLoading } from 'reducers/layoutSlice'
import { actions as bonusesActions } from 'domains/bonuses'
import * as actions from 'actions/views/bonuses'
import { FIELD_NAMES } from 'const/forms'
import { message } from 'antd'

function* fetchBonusesWorker({ payload } = {}) {
  try {
    yield all([put(startLoading()), put(bonusesActions.fetchBonuses(payload))])
    const [success] = yield race([
      take(bonusesActions.fetchBonusesSuccess),
      take(bonusesActions.fetchBonusesFailure),
    ])
    if (success) {
      yield put(bonusesActions.saveFiltersAndSorter(payload))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* createBonusWorker({ payload }) {
  try {
    const body = payload.values
    yield all([
      put(bonusesActions.startSaving()),
      put(
        bonusesActions.createBonus({
          data: body,
        })
      ),
    ])
    const [success] = yield race([
      take(bonusesActions.createBonusSuccess),
      take(bonusesActions.createBonusFailure),
    ])

    if (success) {
      yield put(bonusesActions.fetchBonuses())
      message.success('Bonus created')
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(bonusesActions.finishSaving())
  }
}

function* deleteBonusWorker({ payload }) {
  try {
    yield all([put(startLoading()), put(bonusesActions.deleteBonus(payload))])
    const [success] = yield race([
      take(bonusesActions.deleteBonusSuccess),
      take(bonusesActions.deleteBonusFailure),
    ])

    if (success) {
      yield put(actions.fetchBonuses())
      message.success('Bonus deleted')
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* updateBonusWorker({ payload }) {
  try {
    const body = payload.values
    yield all([
      put(bonusesActions.startSaving()),
      put(
        bonusesActions.updateBonus({
          data: body,
          id: payload.values[FIELD_NAMES.id],
        })
      ),
    ])
    const [success] = yield race([
      take(bonusesActions.updateBonusSuccess),
      take(bonusesActions.updateBonusFailure),
    ])

    if (success) {
      yield put(bonusesActions.fetchBonuses())
      message.success('Bonus updated')
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(bonusesActions.finishSaving())
  }
}

export default function* () {
  yield all([
    fetchBonusesWorker(),
    takeLatest(actions.fetchBonuses, fetchBonusesWorker),
    takeLatest(actions.deleteBonus, deleteBonusWorker),
    takeLatest(actions.updateBonus, updateBonusWorker),
    takeLatest(actions.createBonus, createBonusWorker),
  ])
}
