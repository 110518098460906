import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import { Actions, StyledButton } from './SEOPages.styles'
import { formatFieldValue } from 'helpers/formatters/fields'

import UrlComponent from 'components/UrlComponent'
import Status from 'components/Status'
import Image from 'components/Image'

export default (
  filters,
  sorter,
  handleEditSEOPage,
  handleChangeSEOPagePublishState
) => [
  {
    title: 'ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 100,
    align: 'center',
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Product Family',
    dataIndex: FIELD_NAMES.name,
    key: FIELD_NAMES.name,
    width: 120,
    fixed: 'left',
    align: 'center',
    render: formatFieldValue,
  },
  {
    title: 'Small Image',
    dataIndex: FIELD_NAMES.smallImage,
    key: FIELD_NAMES.smallImage,
    width: 180,
    align: 'center',
    render: (src) => (
      <Image
        src={src ? `${src}?${Date.now()}` : '/images/fallback.png'}
        width={200}
        height={95}
      />
    ),
  },
  {
    title: 'Large Image',
    dataIndex: FIELD_NAMES.largeImage,
    key: FIELD_NAMES.largeImage,
    width: 180,
    align: 'center',
    render: (src) => (
      <Image
        src={src ? `${src}?${Date.now()}` : '/images/fallback.png'}
        width={200}
        height={63}
      />
    ),
  },
  {
    title: 'Preview',
    dataIndex: FIELD_NAMES.seoLink,
    key: FIELD_NAMES.seoLink,
    width: 70,
    align: 'center',
    render: (seoLink) => <UrlComponent url={seoLink} />,
  },
  {
    title: 'Published',
    dataIndex: FIELD_NAMES.isPublished,
    key: FIELD_NAMES.isPublished,
    width: 100,
    align: 'center',
    render: (value) => <Status value={value} />,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    fixed: 'right',
    width: 80,
    render: (_, product) => {
      const { [FIELD_NAMES.isPublished]: isPublished } = product
      const onEdit = () => handleEditSEOPage(product[FIELD_NAMES.id])
      const onPublish = () => handleChangeSEOPagePublishState(product)

      return (
        <Actions>
          <StyledButton onClick={onEdit}>Edit</StyledButton>
          <StyledButton onClick={onPublish} type="primary">
            {isPublished ? 'Unpublish' : 'Publish'}
          </StyledButton>
        </Actions>
      )
    },
  },
]
