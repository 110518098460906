import React from 'react'
import { Col, Input, Layout, Row } from 'antd'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import useAction from 'utils/useAction'
import { FIELD_NAMES } from 'const/forms'
import { required } from 'helpers/validators'
import { openConfirmModal } from 'reducers/modals/actions'
import { lastModalData } from 'reducers/modals/selectors'
import * as retailersActions from 'actions/views/deleted'
import RadioGroup from 'components/RadioGroup'
import RegionSelect from 'components/RegionSelect'
import Title from 'components/Title/Title'
import { Buttons, StyledButton } from './RestoreRetailer.styles'
import { RETAILER_TYPE_TO_LABEL, RETAILER_TYPE_OPTIONS } from 'const/retailers'
import { ENTITY_NAMES, RELATIONSHIPS_NAMES } from 'const/api'
import SearchSelect from 'components/SearchSelect'

const RestoreRetailer = ({ closeModal }) => {
  const { retailer } = useSelector(lastModalData)
  const {
    [FIELD_NAMES.name]: retailerName,
    [FIELD_NAMES.id]: id,
    [FIELD_NAMES.mainId]: mainId,
  } = retailer

  const initialValues = {
    [FIELD_NAMES.type]: ENTITY_NAMES.main,
    [FIELD_NAMES.mainRetailerId]: mainId,
    [FIELD_NAMES.countryCode]: null,
  }

  const restoreRetailerFunc = useAction(retailersActions.restoreRetailer)
  const openConfirmModalFunc = useAction(openConfirmModal)

  const onSubmit = (data) => {
    const callback = () => {
      restoreRetailerFunc({ id, data })
      closeModal()
    }
    const text = `Are you sure you want to restore ${retailerName} with ${
      RETAILER_TYPE_TO_LABEL[data[FIELD_NAMES.type]]
    } type?`
    openConfirmModalFunc({ callback, text })
  }

  return (
    <Layout.Content>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ hasValidationErrors, handleSubmit, values }) => {
          const isGlobalTypeSelected =
            values[FIELD_NAMES.type] === ENTITY_NAMES.main
          const isRegionalTypeSelected =
            values[FIELD_NAMES.type] === ENTITY_NAMES.regional
          return (
            <>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Title>Retailer ID</Title>
                  <Input value={id} disabled />
                </Col>
                <Col span={12}>
                  <Title>Name</Title>
                  <Input value={retailerName} disabled />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col>
                  <Title>Retailer Type</Title>
                  <Field
                    options={RETAILER_TYPE_OPTIONS}
                    component={RadioGroup}
                    name={FIELD_NAMES.type}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                {!isGlobalTypeSelected && (
                  <Col span={12}>
                    <Field
                      title="Global retailer"
                      component={SearchSelect}
                      entity={RELATIONSHIPS_NAMES.main}
                      name={FIELD_NAMES.mainRetailerId}
                      validate={required}
                    />
                  </Col>
                )}
                {isRegionalTypeSelected && (
                  <Col span={12}>
                    <RegionSelect
                      disabled={!values[FIELD_NAMES.mainRetailerId]}
                    />
                  </Col>
                )}
              </Row>

              <Buttons>
                <StyledButton size="default" onClick={closeModal}>
                  Cancel
                </StyledButton>

                <StyledButton
                  disabled={hasValidationErrors}
                  size="default"
                  type="primary"
                  onClick={handleSubmit}
                >
                  Restore
                </StyledButton>
              </Buttons>
            </>
          )
        }}
      />
    </Layout.Content>
  )
}

export default RestoreRetailer
