import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  saveFiltersAndSorter,
  fetchAppVersionsSuccess,
  fetchAppVersionsFailure,
  startSaving,
  finishSaving,
  fetchMobileNotificationsSuccess,
  fetchMobileNotificationsFailure,
} = reducer.actions

export const fetchAppVersions = createAction('mobileApp/fetchAppVersions')

export const deleteAppVersion = createAction('mobileApp/deleteAppVersion')
export const deleteAppVersionSuccess = createAction(
  'mobileApp/deleteAppVersionSuccess'
)
export const deleteAppVersionFailure = createAction(
  'mobileApp/deleteAppVersionFailure'
)

export const createAppVersion = createAction('mobileApp/createAppVersion')
export const createAppVersionSuccess = createAction(
  'mobileApp/createAppVersionSuccess'
)
export const createAppVersionFailure = createAction(
  'mobileApp/createAppVersionFailure'
)

export const updateAppVersion = createAction('mobileApp/updateAppVersion')
export const updateAppVersionSuccess = createAction(
  'mobileApp/updateAppVersionSuccess'
)
export const updateAppVersionFailure = createAction(
  'mobileApp/updateAppVersionFailure'
)

export const fetchMobileNotifications = createAction(
  'mobileApp/fetchMobileNotifications'
)

export const createMobileNotification = createAction(
  'mobileApp/createMobileNotification'
)
export const createMobileNotificationSuccess = createAction(
  'mobileApp/createMobileNotificationSuccess'
)
export const createMobileNotificationFailure = createAction(
  'mobileApp/createMobileNotificationFailure'
)
