import { apiInstance } from 'utils/request'
import { ENTITY_NAMES } from 'const/api'
import { FIELD_NAMES } from 'const/forms'
import parseError from 'helpers/parseServerError'

export const getDevelopers = ({ from, to, sorter }) => {
  return apiInstance
    .get(`/${ENTITY_NAMES.admin}/shop_dev_logs`, {
      params: { from, to },
      sort: sorter || FIELD_NAMES.rulesCreated,
    })
    .catch((error) => {
      parseError(error, 'Cannot get developers')
    })
}

export const getCSV = ({ from, to }) => {
  return apiInstance
    .get(`/${ENTITY_NAMES.admin}/shop_dev_logs/csv`, {
      params: { from, to },
    })
    .catch((error) => {
      parseError(error, 'Cannot get CSV')
    })
}
