import React from 'react'
import { Divider, Row } from 'antd'
import { FIELD_NAMES } from 'const/forms'
import { Name, Wrapper } from './Payout.styles'

const Payout = ({ payout }) => {
  return (
    payout && (
      <Wrapper>
        {payout[FIELD_NAMES.name] && <Name>{payout[FIELD_NAMES.name]}</Name>}
        <Row align="middle">
          <span>Percentage range</span>
          <Divider type="vertical" />
          {`${
            payout[FIELD_NAMES.percentageRange]?.[FIELD_NAMES.lowerBound] || 0
          } - ${
            payout[FIELD_NAMES.percentageRange]?.[FIELD_NAMES.upperBound] || 0
          }`}
        </Row>

        <Row align="middle">
          <span>Flat range</span>
          <Divider type="vertical" />
          {`${payout[FIELD_NAMES.flatRange]?.[FIELD_NAMES.lowerBound] || 0} - ${
            payout[FIELD_NAMES.flatRange]?.[FIELD_NAMES.upperBound] || 0
          }`}
        </Row>
      </Wrapper>
    )
  )
}

export default Payout
