import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Context from '../Context/Context'

const Provider = ({ children }) => {
  const [activeID, setActiveID] = useState(null)
  const [data, setData] = useState({})

  const openCallback = useCallback(
    (id, data) => {
      setActiveID(id)
      if (data) setData(data)
    },
    [setActiveID, setData]
  )

  const closeCallback = useCallback(() => {
    setActiveID(null)
  }, [setActiveID])

  return (
    <Context.Provider
      value={{ activeID, open: openCallback, close: closeCallback, data }}
    >
      {children}
    </Context.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Provider
