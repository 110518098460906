import React from 'react'
import { FIELD_NAMES, FORM_CHANGES_FIELD_PREFIX } from 'const/forms'
import { Field } from 'react-final-form'
import MoreActions from 'components/MoreActions'
import { Actions, StyledButton, Wrapper } from '../Retailers.styles'
import RetailerCountry from 'components/RetailerCountry'
import {
  getRetailerTypeFilters,
  inputKGValueParser,
  inputNumberValueParserTagsCount,
} from 'helpers/retailers'
import StakeInfo from 'components/StakeInfo'
import Image from 'components/Image'
import { getGlobalUpdateOptions } from 'helpers/globalUpdate'
import { KG_MAX_VALUE } from 'const/retailers'
import { formatUrl } from 'helpers/formatters/fields'
import NumberInput from 'components/GlobalUpdate/NumberInput'

export const getColumns = ({
  openCommentsModal,
  checkFilter,
  // values,
  handleGlobalChange,
  openEditStoreContentModal,
  filters,
  sorter,
}) => [
  {
    title: 'Retailer ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    fixed: 'left',
    width: 90,
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Country',
    dataIndex: FIELD_NAMES.country,
    key: FIELD_NAMES.country,
    render: (country, retailer) => <RetailerCountry retailer={retailer} />,
    filters: getRetailerTypeFilters(),
    filteredValue: filters[FIELD_NAMES.country] || null,
    fixed: 'left',
    width: 100,
    align: 'center',
  },
  {
    title: 'Name/Rule ID',
    dataIndex: FIELD_NAMES.name,
    key: FIELD_NAMES.name,
    render: (name, { url, rule_id }) => (
      <Wrapper>
        <a rel="noreferrer noopener" href={formatUrl(url)} target="_blank">
          {name}
        </a>
        <span>{rule_id}</span>
      </Wrapper>
    ),
    fixed: 'left',
    width: 150,
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.name && sorter.order,
  },
  {
    title: '',
    dataIndex: 'more_actions',
    key: 'more_actions',
    render: (_, retailer) =>
      !retailer.isInvalid && (
        <MoreActions retailer={retailer} checkFilter={checkFilter} />
      ),
    width: 56,
    fixed: 'left',
  },
  {
    width: 100,
    title: 'KG (%)',
    align: 'center',
    fixed: 'left',
    dataIndex: FIELD_NAMES.stake,
    key: FIELD_NAMES.stake,
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.stake && sorter.order,
    render: (value) => <StakeInfo value={value} hideStatus />,
  },
  {
    width: 130,
    title: 'Max KG (%)',
    dataIndex: FIELD_NAMES.maxKarmaGives,
    key: FIELD_NAMES.maxKarmaGives,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.maxKarmaGives && sorter.order,
    render: (cb, data) => (
      <Field
        max={KG_MAX_VALUE}
        maxLength={5}
        step={0.1}
        parser={inputKGValueParser}
        disabled={data.isDisabled}
        name={`${FORM_CHANGES_FIELD_PREFIX}_${data[FIELD_NAMES.id]}[${
          FIELD_NAMES.maxKarmaGives
        }]`}
        changeCallback={handleGlobalChange(
          getGlobalUpdateOptions(data, FIELD_NAMES.maxKarmaGives)
        )}
        component={NumberInput}
      />
    ),
  },
  {
    width: 130,
    title: 'Tags Count',
    dataIndex: FIELD_NAMES.tagsCount,
    key: FIELD_NAMES.tagsCount,
    align: 'center',
    render: (_, data) => (
      <Field
        step={1}
        disabled={data[FIELD_NAMES.tagsCount] > 1}
        parser={inputNumberValueParserTagsCount}
        name={`${FORM_CHANGES_FIELD_PREFIX}_${data[FIELD_NAMES.id]}[${
          FIELD_NAMES.tagsCount
        }]`}
        changeCallback={handleGlobalChange(
          getGlobalUpdateOptions(data, FIELD_NAMES.tagsCount)
        )}
        component={NumberInput}
        {...(data[FIELD_NAMES.tagsCount] === 0 && { max: 1 })}
      />
    ),
  },
  {
    title: 'Logo',
    dataIndex: FIELD_NAMES.logo,
    key: FIELD_NAMES.logo,
    width: 100,
    align: 'center',
    render: (src) => <Image src={src} width={80} height={80} />,
  },
  {
    title: 'About',
    align: 'center',
    dataIndex: FIELD_NAMES.description,
    key: FIELD_NAMES.description,
    ellipsis: { showTitle: false },
    render: (name) => name || '-',
  },
  {
    title: 'Terms & Conditions',
    align: 'center',
    dataIndex: FIELD_NAMES.termsAndConditions,
    key: FIELD_NAMES.termsAndConditions,
    ellipsis: { showTitle: false },
    render: (name) => name || '-',
  },
  {
    title: 'What happens next',
    align: 'center',
    dataIndex: FIELD_NAMES.whatHappensNext,
    key: FIELD_NAMES.whatHappensNext,
    ellipsis: { showTitle: false },
    render: (name) => name || '-',
  },
  // {
  //   width: 100,
  //   title: 'CB active',
  //   align: 'center',
  //   dataIndex: FIELD_NAMES.cbActive,
  //   key: FIELD_NAMES.cbActive,
  //   render: (active, data) => {
  //     const name = `${FORM_CHANGES_FIELD_PREFIX}_${data[FIELD_NAMES.id]}[${FIELD_NAMES.cbActive}]`
  //     return (
  //       data.isDisabled ? (
  //         <StakeInfo isActive={active} hideValue />
  //       ) : (
  //         <Field
  //           disabled={isCBStatusDisabled(values, data[FIELD_NAMES.id])}
  //           disabledReason={DISABLED_IF_CB_UNSET}
  //           changeCallback={handleGlobalChange(getGlobalUpdateOptions(data, FIELD_NAMES.cbActive))}
  //           name={name}
  //           fieldName={name}
  //           component={SwitchWithDisabledReasonTooltip}
  //         />
  //       )
  //     )
  //   },
  //   filters: STATUS_FILTERS,
  //   filteredValue: filters[FIELD_NAMES.cbActive] || null,
  // },
  // {
  //   width: 100,
  //   title: 'CPA',
  //   dataIndex: FIELD_NAMES.cpa,
  //   key: FIELD_NAMES.cpa,
  //   sorter: true,
  //   sortOrder: sorter.columnKey === FIELD_NAMES.cpa && sorter.order,
  //   render: (cpa, data) => (
  //     <Field
  //       disabled={data.isDisabled}
  //       name={`${FORM_CHANGES_FIELD_PREFIX}_${data[FIELD_NAMES.id]}[${FIELD_NAMES.cpa}]`}
  //       component={CashbackInput}
  //       changeCallback={handleGlobalChange(getGlobalUpdateOptions(data, FIELD_NAMES.cpa))}
  //     />
  //   ),
  // },
  // {
  //   width: 100,
  //   title: 'CB',
  //   dataIndex: FIELD_NAMES.cb,
  //   key: FIELD_NAMES.cb,
  //   sorter: true,
  //   sortOrder: sorter.columnKey === FIELD_NAMES.cb && sorter.order,
  //   render: (cb, data) => (
  //     <Field
  //       disabled={data.isDisabled}
  //       validate={validCBs(`${FORM_CHANGES_FIELD_PREFIX}_${data[FIELD_NAMES.id]}`)}
  //       name={`${FORM_CHANGES_FIELD_PREFIX}_${data[FIELD_NAMES.id]}[${FIELD_NAMES.cb}]`}
  //       changeCallback={handleGlobalChange(getGlobalUpdateOptions(data, FIELD_NAMES.cb))}
  //       component={CashbackInput}
  //     />
  //   )
  // },
  // {
  //   title: 'Promo CB',
  //   dataIndex: FIELD_NAMES.promoCb,
  //   key: FIELD_NAMES.promoCb,
  //   sorter: true,
  //   sortOrder: sorter.columnKey === FIELD_NAMES.promoCb && sorter.order,
  //   render: (cb, data) => (
  //     <Field
  //       disabled={data.isDisabled}
  //       name={`${FORM_CHANGES_FIELD_PREFIX}_${data[FIELD_NAMES.id]}[${FIELD_NAMES.promoCb}]`}
  //       component={CashbackInputWithDates}
  //       changeCallback={handleGlobalChange(getGlobalUpdateOptions(data, FIELD_NAMES.promoCb))}
  //       validate={promoPeriodCashback(`${FORM_CHANGES_FIELD_PREFIX}_${data[FIELD_NAMES.id]}`)}
  //     />
  //   ),
  //   width: 430,
  // },
  // {
  //   width: 100,
  //   title: 'Plus CB',
  //   dataIndex: FIELD_NAMES.plusCb,
  //   key: FIELD_NAMES.plusCb,
  //   sorter: true,
  //   sortOrder: sorter.columnKey === FIELD_NAMES.plusCb && sorter.order,
  //   render: (cb, data) => (
  //     <Field
  //       disabled={data.isDisabled}
  //       validate={validPlusCBs(`${FORM_CHANGES_FIELD_PREFIX}_${data[FIELD_NAMES.id]}`)}
  //       name={`${FORM_CHANGES_FIELD_PREFIX}_${data[FIELD_NAMES.id]}[${FIELD_NAMES.plusCb}]`}
  //       changeCallback={handleGlobalChange(getGlobalUpdateOptions(data, FIELD_NAMES.plusCb))}
  //       component={CashbackInput}
  //     />
  //   )
  // },
  // {
  //   title: 'Promo plus CB',
  //   dataIndex: FIELD_NAMES.promoPlusCb,
  //   key: FIELD_NAMES.promoPlusCb,
  //   sorter: true,
  //   sortOrder: sorter.columnKey === FIELD_NAMES.promoPlusCb && sorter.order,
  //   render: (cb, data) => (
  //     <Field
  //       disabled={data.isDisabled}
  //       name={`${FORM_CHANGES_FIELD_PREFIX}_${data[FIELD_NAMES.id]}[${FIELD_NAMES.promoPlusCb}]`}
  //       component={CashbackInputWithDates}
  //       changeCallback={handleGlobalChange(getGlobalUpdateOptions(data, FIELD_NAMES.promoPlusCb))}
  //       validate={promoPlusPeriodCashback(`${FORM_CHANGES_FIELD_PREFIX}_${data[FIELD_NAMES.id]}`)}
  //     />
  //   ),
  //   width: 430,
  // },
  {
    title: 'Actions',
    dataIndex: 'edit',
    align: 'center',
    key: 'edit',
    render: (_, retailer) => {
      const { isGlobal } = retailer
      const openModalFunc = () => openCommentsModal({ retailer })
      const openEditStoreContentModalFunc = () =>
        openEditStoreContentModal({
          id: retailer.id,
          hideTermsAndConditions: true,
          type: retailer.type,
        })
      return (
        isGlobal && (
          <Actions>
            <StyledButton onClick={openModalFunc}>Comments</StyledButton>
            <StyledButton onClick={openEditStoreContentModalFunc}>
              Edit T&C
            </StyledButton>
          </Actions>
        )
      )
    },
    fixed: 'right',
    width: 150,
  },
]
