import React from 'react'
import PropTypes from 'prop-types'
import { Col, Input, Modal, Row } from 'antd'
import { StyledButton, Buttons } from './CopyModal.styles'
import useAction from 'utils/useAction'
import { retailerPropType } from 'const/propTypes'
import { FIELD_NAMES } from 'const/forms'
import { copyImages } from 'actions/views/retailers'
import Title from 'components/Title/Title'
import { Field, Form } from 'react-final-form'
import { requiredNested } from 'helpers/validators'
import { AVAILABLE_RETAILER_TYPES_FOR_IMAGES } from 'const/retailers'
import { RELATIONSHIPS_NAMES } from 'const/api'
import SearchSelect from 'components/SearchSelect'

const CopyModal = ({ retailer, closeModal, show }) => {
  const copyImagesFunc = useAction(copyImages)

  const initialValues = {
    [FIELD_NAMES.id]: retailer[FIELD_NAMES.id],
    [FIELD_NAMES.targetRetailers]: [],
  }

  const onSubmit = (values) => {
    closeModal()
    copyImagesFunc(values)
  }

  return (
    <Modal
      open={show}
      centered
      title="Copying images"
      onCancel={closeModal}
      footer={null}
      width={700}
    >
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ hasValidationErrors, handleSubmit }) => {
          return (
            <>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Title>Retailer ID</Title>
                  <Input value={retailer[FIELD_NAMES.id]} disabled />
                </Col>
                <Col span={12}>
                  <Title>Name</Title>
                  <Input value={retailer[FIELD_NAMES.name]} disabled />
                </Col>
              </Row>
              <Field
                title="Target Retailers"
                mode="multiple"
                types={AVAILABLE_RETAILER_TYPES_FOR_IMAGES}
                except={retailer[FIELD_NAMES.id]}
                validate={requiredNested}
                component={SearchSelect}
                entity={RELATIONSHIPS_NAMES.retailers}
                name={FIELD_NAMES.targetRetailers}
              />

              <Buttons>
                <StyledButton onClick={closeModal}>Cancel</StyledButton>

                <StyledButton
                  disabled={hasValidationErrors}
                  type="primary"
                  onClick={handleSubmit}
                >
                  Copy images
                </StyledButton>
              </Buttons>
            </>
          )
        }}
      />
    </Modal>
  )
}

CopyModal.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  show: PropTypes.bool.isRequired,
  retailer: retailerPropType.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default CopyModal
