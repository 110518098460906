export const ADMIN = 'admin'
export const MODERATOR = 'admin'
export const MEMBER = 'admin'

export const ROLES = {
  super: 'super',
  lead: 'lead',
  marketing: 'marketing',
  finance: 'finance',
  customerService: 'customer_service',
  product: 'product',
  operations: 'operations',
  dev: 'dev',
  beta: 'beta',
  payments: 'payments',
  editorial: 'editorial',
  shop_dev: 'shop_dev',
  admin: 'admin',
  bo: 'bo',
}

export const ROLES_LABELS = {
  [ROLES.super]: 'Supervisor',
  [ROLES.lead]: 'Team Lead',
  [ROLES.marketing]: 'Marketing',
  [ROLES.finance]: 'Finance',
  [ROLES.customerService]: 'Customer Service',
  [ROLES.product]: 'Product manager',
  [ROLES.operations]: 'Operations',
  [ROLES.dev]: 'Developer',
  [ROLES.beta]: 'Beta',
  [ROLES.payments]: 'Payments',
  [ROLES.editorial]: 'Editorial',
  [ROLES.shop_dev]: 'Shop developer',
  [ROLES.admin]: 'Legacy Admin',
  [ROLES.bo]: 'General',
}

export const ALL_ROLES = [ADMIN, MODERATOR, MEMBER, ...Object.keys(ROLES)]
export const ANY_ROLE = []

export default {
  ADMIN,
  MEMBER,
  MODERATOR,
}

export const ROLES_FILTERS = Object.entries(ROLES_LABELS).map(
  ([value, text]) => ({ value, text })
)
export const ROLES_OPTIONS = Object.entries(ROLES_LABELS).map(
  ([value, label]) => ({ value, label })
)
