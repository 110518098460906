import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import Actions from './Actions'
import { formatDate } from 'helpers/dates'
import { STATUS_FILTERS } from 'const/users'
import { formatFieldValue } from 'helpers/formatters/fields'
import CheckboxIcon from 'components/CheckboxIcon'
import { Button } from 'antd'

export default (
  filters,
  sorter,
  handleDeleteUser,
  handleVerifyUserPayPal,
  openBonusesModalFunc
) => [
  {
    title: 'ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 100,
    fixed: 'left',
    render: formatFieldValue,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Name',
    dataIndex: FIELD_NAMES.name,
    key: FIELD_NAMES.name,
    width: 150,
    render: formatFieldValue,
    align: 'center',
  },
  {
    title: 'Email',
    dataIndex: FIELD_NAMES.email,
    key: FIELD_NAMES.email,
    width: 180,
    align: 'center',
    render: formatFieldValue,
  },
  {
    title: 'Phone',
    dataIndex: FIELD_NAMES.phone,
    key: FIELD_NAMES.phone,
    width: 150,
    align: 'center',
    render: formatFieldValue,
  },
  {
    title: 'Total balance',
    dataIndex: FIELD_NAMES.totalBalance,
    key: FIELD_NAMES.totalBalance,
    width: 120,
    align: 'center',
    render: formatFieldValue,
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.totalBalance && sorter.order,
  },
  {
    title: 'Total Bonuses',
    dataIndex: FIELD_NAMES.totalBonuses,
    key: FIELD_NAMES.totalBonuses,
    width: 120,
    align: 'center',
  },
  {
    title: 'Bonuses',
    dataIndex: FIELD_NAMES.bonuses,
    key: FIELD_NAMES.bonuses,
    width: 120,
    align: 'center',
    render: (_, user) => {
      const handleClick = () => openBonusesModalFunc(user)
      return user.bonuses ? (
        <Button type="link" onClick={handleClick}>
          {user.bonuses.join(', ')}
        </Button>
      ) : (
        '-'
      )
    },
  },
  {
    title: 'Total KarmaCash',
    dataIndex: FIELD_NAMES.totalCash,
    key: FIELD_NAMES.totalCash,
    width: 120,
    align: 'center',
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.totalCash && sorter.order,
    render: formatFieldValue,
  },
  {
    title: 'Pending Cash',
    dataIndex: FIELD_NAMES.pendingCash,
    key: FIELD_NAMES.pendingCash,
    width: 120,
    align: 'center',
    sorter: false,
    sortOrder: sorter.columnKey === FIELD_NAMES.pendingCash && sorter.order,
    render: formatFieldValue,
  },
  {
    title: 'Registration date',
    align: 'center',
    dataIndex: FIELD_NAMES.registrationDate,
    key: FIELD_NAMES.registrationDate,
    width: 130,
    render: formatDate,
    sorter: true,
    sortOrder:
      sorter.columnKey === FIELD_NAMES.registrationDate && sorter.order,
  },
  {
    title: 'PayPal connected',
    dataIndex: FIELD_NAMES.paypalConnected,
    key: FIELD_NAMES.paypalConnected,
    width: 120,
    align: 'center',
    render: (checked) => <CheckboxIcon checked={checked} />,
    filters: STATUS_FILTERS,
    filteredValue: filters[FIELD_NAMES.paypalConnected] || null,
  },
  {
    title: 'PayPal confirmed',
    dataIndex: FIELD_NAMES.paypalConfirmed,
    key: FIELD_NAMES.paypalConfirmed,
    width: 180,
    align: 'center',
    render: (checked, user) => {
      const onVerifyPayPal = () => handleVerifyUserPayPal(user)
      const isVerifyingAvailable =
        user[FIELD_NAMES.paypalConnected] && !user[FIELD_NAMES.paypalConfirmed]
      return (
        <>
          {checked || !isVerifyingAvailable ? (
            <CheckboxIcon checked={checked} />
          ) : (
            <Button onClick={onVerifyPayPal} disabled={!isVerifyingAvailable}>
              Verify PayPal
            </Button>
          )}
        </>
      )
    },
    filters: STATUS_FILTERS,
    filteredValue: filters[FIELD_NAMES.paypalConfirmed] || null,
  },
  {
    title: 'PayPal email',
    dataIndex: FIELD_NAMES.paypalEmail,
    key: FIELD_NAMES.paypalEmail,
    width: 150,
    align: 'center',
    render: formatFieldValue,
  },
  {
    title: 'Other extension installed',
    dataIndex: FIELD_NAMES.otherExtensionInstalled,
    key: FIELD_NAMES.otherExtensionInstalled,
    width: 120,
    align: 'center',
    render: (list) => (!list?.length ? <CheckboxIcon checked={false} /> : list),
    filters: STATUS_FILTERS,
    filteredValue: filters[FIELD_NAMES.otherExtensionInstalled] || null,
  },
  {
    title: 'Influencer',
    dataIndex: FIELD_NAMES.influencer,
    key: FIELD_NAMES.influencer,
    width: 120,
    align: 'center',
    render: (value) => <CheckboxIcon checked={value} />,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    fixed: 'right',
    width: 90,
    render: (_, user) => <Actions data={user} />,
  },
]
