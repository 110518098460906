import React, { useEffect, useState } from 'react'
import { Spin, Select as AntSelect } from 'antd'
import { Field } from 'react-final-form'
import { apiInstance3 } from 'utils/request'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'
import { StyledSelect } from './SearchRules.styles'
import { getCurrentShopId } from 'domains/shops/selectors'
import { useSelector } from 'react-redux'

const SearchRules = ({ name, validate, initialValues, disabled }) => {
  const [data, setData] = useState([])
  const [defaultValue, setDefaultValue] = useState([])
  const [fetching, setFetching] = useState([])

  const currentShopId = useSelector(getCurrentShopId)

  const fetchRules = () => {
    setData([])
    setFetching(true)
    apiInstance3
      .get(`/backoffice/shops/${currentShopId}/rules`)
      .then((response) => {
        setData(response?.data)
        setDefaultValue(response?.data?.map((item) => item[0]))
      })
    setData(data)
    setFetching(false)
  }

  useEffect(() => {
    fetchRules()
  }, [])

  return (
    <Field
      name={name}
      validate={validate}
      initialValue={initialValues || defaultValue}
    >
      {({ meta, input }) => (
        <>
          <FieldWrapper label="Rules" meta={meta}>
            <StyledSelect
              mode="multiple"
              disabled={disabled}
              invalid={meta.touched && meta.error}
              labelInValue
              value={input?.value || undefined}
              showSearch
              placeholder={
                input.value?.length > 0 ? 'Search rules...' : 'All rules'
              }
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onChange={(value) => {
                input.onChange(value?.length === 0 ? undefined : value)
              }}
            >
              {data?.map((item) => {
                return (
                  <AntSelect.Option
                    key={item[0]}
                    value={item[0]}
                    name={item[1]}
                  >
                    {item[0]} - {item[1]}
                  </AntSelect.Option>
                )
              })}
            </StyledSelect>
          </FieldWrapper>
        </>
      )}
    </Field>
  )
}

export default SearchRules
