import { RELATIONSHIPS_NAMES } from 'const/api'
import { apiInstance } from 'utils/request'
import { FIELD_NAMES } from 'const/forms'

export const getMainRetailers = ({
  except,
  page: number,
  search,
  exceptCodes,
}) =>
  apiInstance.get(`/${RELATIONSHIPS_NAMES.main}`, {
    params: {
      filter: {
        search,
        [FIELD_NAMES.deleted]: false,
        ...(except && { except }),
        ...(exceptCodes && { except_country_codes: exceptCodes }),
      },
      page: {
        number,
        size: 20,
      },
    },
  })

export const getRetailers = ({ except, page: number, search, types }) =>
  apiInstance.get(`/${RELATIONSHIPS_NAMES.retailers}`, {
    params: {
      filter: {
        search,
        type: types?.join(','),
        [FIELD_NAMES.deleted]: false,
        ...(except && { except }),
      },
      page: {
        number,
        size: 20,
      },
      sort: RELATIONSHIPS_NAMES.mainRetailer,
    },
  })

export const getRules = ({ page: number, search }) =>
  apiInstance.get(`/${RELATIONSHIPS_NAMES.rules}`, {
    params: {
      filter: {
        search,
      },
      page: {
        number,
        size: 20,
      },
      sort: FIELD_NAMES.id,
    },
  })
