// fully transfered from antd
// should be rewrited
import React, { useRef, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { Spin, Select as AntSelect } from 'antd'
import { Field } from 'react-final-form'
import { apiInstance } from 'utils/request'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'
import debounce from 'lodash/debounce'
import { StyledSelect } from './SearchRetailers.styles'
import { FIELD_NAMES } from 'const/forms'
import { RETAILER_TYPES_IN_ATTRS } from 'const/retailers'
import { ENTITY_NAMES } from '../../const/api'

const SearchRetailers = ({ name, validate, required, disabled }) => {
  const lastFetchId = useRef(0)
  const [data, setData] = useState([])
  const [fetching, setFetching] = useState([])

  const fetchRetailers = (value) => {
    lastFetchId.current += 1
    const fetchId = lastFetchId.current
    setData([])
    setFetching(true)
    apiInstance
      .get(
        '/retailers' +
          '?page[number]=1' +
          '&page[size]=20' +
          // `&filter[type]=${AVAILABLE_RETAILER_TYPES_FOR_AD_PACKAGES}` +
          `&filter[${FIELD_NAMES.deleted}]=false` +
          '&filter[search]=' +
          value
      )
      .then((response) => response.data.data)
      .then((body) => {
        if (fetchId !== lastFetchId.current) return

        const data = body
          .filter((item) => !isEmpty(item))
          .map((item) => ({
            value: item.id,
            isGlobal:
              item.attributes.type ===
              RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.main],
            id: item[FIELD_NAMES.id],
            label: `${item.attributes?.[FIELD_NAMES.name]}${
              item.attributes?.[FIELD_NAMES.countryCode]
                ? ` (${item.attributes?.[FIELD_NAMES.countryCode]})`
                : ''
            }`,
          }))

        setData(data)
        setFetching(false)
      })
  }

  const fetchRetailersFunc = debounce(fetchRetailers, 800)
  return (
    <Field name={name} validate={validate}>
      {({ meta, input }) => (
        <>
          <FieldWrapper label="Retailers" meta={meta} required={required}>
            <StyledSelect
              disabled={disabled}
              mode="multiple"
              invalid={meta.touched && meta.error}
              labelInValue
              value={input.value || undefined} // to fix bug with placeholder's displaying
              showSearch
              placeholder={
                input.value?.length > 0
                  ? 'Search retailers...'
                  : 'All retailers'
              }
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={fetchRetailersFunc}
              onFocus={() => fetchRetailers('')}
              onChange={(value) => {
                input.onChange(value?.length === 0 ? undefined : value)
              }}
            >
              {data.map(({ value, label, id }) => {
                return (
                  <AntSelect.Option key={value} value={value} name={label}>
                    {id} - {label}
                  </AntSelect.Option>
                )
              })}
            </StyledSelect>
          </FieldWrapper>
        </>
      )}
    </Field>
  )
}

export default SearchRetailers
