import styled from 'styled-components'
import { Button, Col } from 'antd'

export const Aside = styled.div`
  margin-left: 30px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const AddItemWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-self: flex-end;
`

export const EditItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const DeleteButton = styled(Button)`
  width: 100%;
  margin: 10px 0;
  white-space: normal;
  height: auto;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledCol = styled(Col)`
  width: 287px;
`

export const List = styled.ul`
  max-height: 500px;
  overflow-y: overlay;
  padding: 0;
  padding-right: 18px;
`
