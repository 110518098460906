import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { ENTITY_NAMES } from 'const/api'
import { formatGiveaways } from './formatters'
import { areFiltersAndSorterEmpty } from 'helpers/filters'

export const giveawaysState = ({ giveaways }) => giveaways

export const getIsLoaded = createSelector(
  giveawaysState,
  ({ isLoaded }) => isLoaded
)

export const getIsSavingActive = createSelector(
  giveawaysState,
  ({ isSavingActive }) => isSavingActive
)

export const isLoadingOrSavingActive = createSelector(
  [getIsLoaded, getIsSavingActive],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getEntities = createSelector(
  giveawaysState,
  ({ entities }) => entities
)

export const getGiveaways = createSelector(getEntities, (entities) =>
  formatGiveaways(entities[ENTITY_NAMES.giveaways])
)

export const getGiveawayById = createSelector(getGiveaways, (giveaway) =>
  memoize((id) => (id ? giveaway[id] : null))
)

export const getSearchString = createSelector(
  giveawaysState,
  ({ search }) => search
)
export const getFilters = createSelector(
  giveawaysState,
  ({ filters }) => filters
)
export const getSorter = createSelector(giveawaysState, ({ sorter }) => sorter)
export const getIsDataFilteredOrSorted = createSelector(
  [getFilters, getSorter],
  areFiltersAndSorterEmpty
)
export const getSortedIds = createSelector(
  giveawaysState,
  ({ sortedIds }) => sortedIds
)

export const getSortedGiveaways = createSelector(
  [getGiveaways, getSortedIds],
  (giveaways, ids) => ids.map((id) => giveaways[id])
)

export const getPageCount = createSelector(
  giveawaysState,
  ({ pageCount }) => pageCount
)
export const getPage = createSelector(giveawaysState, ({ page }) => page)
