import styled, { css } from 'styled-components'
import { Form } from 'antd'

export const FormItem = styled(Form.Item)`
  ${({ $isDirty }) =>
    $isDirty &&
    css`
      .ant-form-item-label::after {
        content: ' (edited)';
        color: rgba(250, 173, 20, 1);
        font-weight: normal;
      }
    `}
  ${({ $isEmpty }) =>
    $isEmpty &&
    css`
      .ant-form-item-control {
        display: none;
      }
    `}
  
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`
