import styled, { css } from 'styled-components'
import { InputNumber, DatePicker } from 'antd'
const { RangePicker } = DatePicker

const invalid = (props) => {
  if (props.invalid) {
    return css`
      border-color: rgb(255, 0, 0, 1);
      box-shadow: none;

      &:hover,
      &:focus {
        border-color: rgb(255, 0, 0, 1);
        box-shadow: 0 0 0 2px rgb(255, 0, 0, 0.5);
      }
    `
  }
}

export const StyledInput = styled(InputNumber)`
  min-width: 50px;
  width: 60px;
  ${invalid}
`

export const StyledRangePicker = styled(RangePicker)`
  margin-right: 10px;
  ${invalid}
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
