import { createAction } from '@reduxjs/toolkit'

export const fetchUsers = createAction('users/fetchUsers')
export const updateUser = createAction('users/updateUser')
export const mergeUser = createAction('users/mergeUser')
export const createUser = createAction('users/createUser')
export const resetUserPassword = createAction('users/resetUserPassword')
export const deleteUser = createAction('users/deleteUser')
export const unblockUserPayment = createAction('users/unblockUserPayment')
export const verifyUserPayPal = createAction('users/verifyUserPayPal')
export const confirmPayPal = createAction('users/confirmPayPal')
export const sendSMSVerification = createAction('users/SMSVerification')
export const unsubscribeUser = createAction('users/unsubscribeUser')
export const fetchBonusesForUser = createAction('users/fetchBonusesForUser')
