import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import { Actions, StyledButton, StoreLink } from './Campaign.styles'
import { formatUrl } from 'helpers/formatters/fields'
import {
  CONFIRMED_FILTERS,
  ACTIVE_FILTERS,
  DEALTYPE_FILTERS,
} from 'const/campaigns'
export default (filters, sorter, handleEditCampaign, handleDeleteCampaign) => [
  {
    title: 'Deal ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 70,
    fixed: 'left',
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Brand Name',
    dataIndex: FIELD_NAMES.stores,
    key: FIELD_NAMES.stores,
    width: 110,
    sorter: false,
    align: 'center',
    render: (_, { stores }) => {
      return stores.map((store, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <StoreLink
          key={idx}
          rel="noreferrer noopener"
          href={formatUrl(store.link)}
          target="_blank"
        >
          {store.label}
          <span>, </span>
        </StoreLink>
      ))
    },
    sortOrder: sorter.columnKey === 'brand_name' && sorter.order,
  },
  {
    title: 'Network',
    dataIndex: FIELD_NAMES.network,
    key: FIELD_NAMES.network,
    width: 110,
    align: 'center',
    render: (_, { network }) => network.value,
  },
  {
    title: 'Type',
    dataIndex: FIELD_NAMES.dealType,
    key: FIELD_NAMES.dealType,
    width: 110,
    filters: DEALTYPE_FILTERS,
    align: 'center',
    sorter: false,
    filteredValue: filters[FIELD_NAMES.dealType] || null,
  },
  {
    title: 'Flat Fee',
    dataIndex: FIELD_NAMES.flatFee,
    key: FIELD_NAMES.flatFee,
    width: 110,
    align: 'center',
    render: (flat_fee, { flat_fee_currency }) =>
      flat_fee === null ? `` : `${flat_fee} ${flat_fee_currency}`,
    sortOrder: sorter.columnKey === FIELD_NAMES.flatFee && sorter.order,
    sorter: true,
  },
  {
    title: 'New CPA',
    dataIndex: FIELD_NAMES.newCpaPercentage,
    key: FIELD_NAMES.newCpaPercentage,
    width: 110,
    align: 'center',
    render: (_, { new_cpa_percentage }) =>
      new_cpa_percentage === null ? `` : `${new_cpa_percentage} %`,
  },
  {
    title: 'Started At',
    dataIndex: FIELD_NAMES.startedAt,
    key: FIELD_NAMES.startedAt,
    width: 110,
    align: 'center',
    render: (_, { start_date }) => start_date,
  },
  {
    title: 'Expired At',
    dataIndex: FIELD_NAMES.expiredAt,
    key: FIELD_NAMES.expiredAt,
    width: 110,
    align: 'center',
    render: (_, { end_date }) => end_date,
  },
  {
    title: 'Admin',
    dataIndex: FIELD_NAMES.reportingAdmin,
    key: FIELD_NAMES.reportingAdmin,
    width: 110,
    align: 'center',
  },
  {
    title: 'Confirmed',
    dataIndex: FIELD_NAMES.confirmed,
    key: FIELD_NAMES.confirmed,
    width: 110,
    render: (_, { confirmed }) => (confirmed ? 'Yes' : 'No'),
    filters: CONFIRMED_FILTERS,
    align: 'center',
    filteredValue: filters[FIELD_NAMES.confirmed] || null,
  },
  {
    title: 'Active',
    dataIndex: FIELD_NAMES.active,
    key: FIELD_NAMES.active,
    width: 110,
    render: (_, { active }) => (active ? 'Yes' : 'No'),
    filters: ACTIVE_FILTERS,
    align: 'center',
    filteredValue: filters[FIELD_NAMES.active] || null,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    fixed: 'right',
    width: 120,
    render: (_, campaign) => {
      const onEdit = () => handleEditCampaign(campaign[FIELD_NAMES.id])
      const onDelete = () => handleDeleteCampaign(campaign)

      return (
        <Actions>
          <StyledButton onClick={onEdit}>Edit</StyledButton>
          <StyledButton onClick={onDelete} type="danger">
            Delete
          </StyledButton>
        </Actions>
      )
    },
  },
]
