import React, { useContext } from 'react'
import { Form, Field } from 'react-final-form'
import { Button, Drawer, message } from 'antd'
import Modal from 'components/Modal/Modal'
import Input from 'components/Input/Input'
import Select from 'components/Select/Select'
import MultipleSelect from 'components/Select/MultipleSelect'
import useAction from 'utils/useAction'
import { actions as featuresActions } from 'domains/features'
import Condition from 'components/formAdapters/Condition.js'
import { validate } from './validate.js'

const fieldTypeOptions = [
  {
    value: 'string',
    label: 'string',
  },
  {
    value: 'integer',
    label: 'integer',
  },
  {
    value: 'boolean',
    label: 'boolean',
  },
  {
    value: 'enumerator',
    label: 'enumerator',
  },
  {
    value: 'array',
    label: 'array',
  },
]

const FeatureConfigurationOptionsForm = ({ visible, onClose }) => {
  const modal = useContext(Modal.Context)
  const createFeatureConfigurationOption = useAction(
    featuresActions.createFeatureConfigurationOptionRequest
  )

  return (
    <Drawer
      title="Feature Configuration Options Form"
      closable={false}
      placement="right"
      open={visible}
      onClose={onClose}
      getContainer="#featureForm"
      style={{ position: 'absolute' }}
    >
      <Form
        onSubmit={async (values, form) => {
          const errors = await createFeatureConfigurationOption(
            { feature_configuration_option: values },
            modal.data.id
          )
          if (errors && errors.length > 0) {
            return { errors: errors }
          } else {
            onClose()
            message.success('Saved')
            setTimeout(form.reset)
          }
        }}
        validate={(values) => validate(values)}
        render={({
          handleSubmit,
          pristine,
          form,
          reset,
          submitting,
          values,
        }) => {
          return (
            <form>
              <Field name="name" placeholder="Name" component={Input} />
              <Field
                placeholder="Field Type"
                options={fieldTypeOptions}
                defaultValue="string"
                name="field_type"
                component={Select}
              />
              <Condition when="field_type" is="enumerator">
                <Field name="custom_enum" component={MultipleSelect} />
              </Condition>
              <Field
                placeholder="Description"
                name="description"
                component={Input}
              />
              <Button
                type="primary"
                disabled={submitting || pristine}
                onClick={() => {
                  handleSubmit(values, form)
                }}
              >
                Submit
              </Button>
            </form>
          )
        }}
      />
    </Drawer>
  )
}

export default FeatureConfigurationOptionsForm
