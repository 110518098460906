import React from 'react'
import { Button } from 'antd'
import Input from 'components/Input/Input'
import { Field, Form } from 'react-final-form'
import { ENTITY_NAMES } from 'const/api'
import { Wrapper } from './MarketingSectionCopy.styles'
import useAction from 'utils/useAction'
import { updateMarketingCopy } from 'actions/views/settings'
import { openConfirmModal } from 'reducers/modals/actions'
import { requiredMaxLength } from 'helpers/validators'

const MarketingSectionCopyComponent = ({ data }) => {
  const editMarketingCopyFunc = useAction(updateMarketingCopy)
  const openConfirmModalFunc = useAction(openConfirmModal)

  const handleEditMarketingCopy = (values) => {
    const callback = () =>
      editMarketingCopyFunc(values?.[ENTITY_NAMES?.marketingCopy])
    const text = `Are you sure you want to change the marketing section copy to "${
      values?.[ENTITY_NAMES?.marketingCopy]
    }"?`
    openConfirmModalFunc({ callback, text })
  }

  return (
    <Form
      initialValues={data}
      onSubmit={handleEditMarketingCopy}
      render={({ handleSubmit, pristine }) => {
        return (
          <Wrapper>
            <Field
              name={ENTITY_NAMES?.marketingCopy}
              component={Input}
              entity={ENTITY_NAMES?.marketingCopy}
              validate={requiredMaxLength(16)}
              required
            />

            <Button disabled={pristine} onClick={handleSubmit} type="primary">
              Update
            </Button>
          </Wrapper>
        )
      }}
    />
  )
}

export default MarketingSectionCopyComponent
