import styled from 'styled-components'

export const Wrapper = styled.li`
  min-width: 200px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: white;
  margin: 10px 0;
  padding: 10px;

  &:hover {
    background: #1890ff;
    color: white;
  }

  ${({ isSelected }) =>
    isSelected ? 'border: 1px solid #1890ff' : 'border: 1px solid transparent'}
`
