import React, { useState } from 'react'
import useAction from 'utils/useAction'
import { Spin, Typography } from 'antd'
import { markExtensionPassedReview } from 'actions/views/extensionVersions'
import { Wrapper } from './ExtensionVersions.styles'
import { Field, Form } from 'react-final-form'
import { validAppVersion } from '../../helpers/validators'
import { FIELD_NAMES } from '../../const/forms'
import Input from '../../components/Input/Input'
import { useSelector } from 'react-redux'
import { getIsSavingActive } from 'domains/extensionVersions/selectors'
import { StyledButton, StyledForm } from './ExtensionVersions.styles'
import { getExtensionVersions } from 'domains/extensionVersions/api'


const ExtensionVersions = () => {
  const isSavingActive = useSelector(getIsSavingActive)

  const markExtensionPassedReviewFunc = useAction(markExtensionPassedReview)

  const [counter, setCounter] = useState(null)
  const [lastSubmittedVersion, setLastSubmittedVersion] = useState('')
  const [showMarkAsPassed, setShowMarkAsPassed] = useState(false)



  const onSubmit = async (values) => {
    const version = values[FIELD_NAMES.version]
    if (version) {
      const result = await getExtensionVersions(version)
      setCounter(result)
      setLastSubmittedVersion(version)
      setShowMarkAsPassed(result <= 100 && result > 0)
    }
  }

  const handleMarkAsPassed = async () => {
    try {
      if (!lastSubmittedVersion) {
        throw new Error("No version submitted")
      }
      const values = { value: {version: lastSubmittedVersion}}
      await markExtensionPassedReviewFunc(values)
      setTimeout(async () => {
        await onSubmit({ [FIELD_NAMES.version]: lastSubmittedVersion })
      }, 1000)
    } catch (error) {
      console.error("Error marking extension as passed:", error)
    }
  }

  return (
    <Wrapper>
      <Typography.Title level={3}>
        Mark extension as passed review
      </Typography.Title>
      <Form
        destroyOnUnregister
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine, values }) => (
          <Spin spinning={isSavingActive} size="large">
            <StyledForm onSubmit={handleSubmit}>
              <Field
                required
                validate={(value) => {
                  setShowMarkAsPassed(false)
                  return validAppVersion(value)
                }}
                name={FIELD_NAMES.version}
                label="Version"
                placeholder="Version"
                component={Input}
              />
              <StyledButton
                type="primary"
                disabled={pristine || (values[FIELD_NAMES.version] === lastSubmittedVersion)}
                onClick={handleSubmit}
              >
                Get Counter
              </StyledButton>
                <div>
                  {counter !== null && (
                    <p>Counter: {counter}</p>
                  )}
                  {showMarkAsPassed && (
                    <StyledButton
                      type="primary"
                      onClick={handleMarkAsPassed}
                    >
                      Mark as Passed
                    </StyledButton>
                  )}
                </div>
            </StyledForm>
          </Spin>
        )}
      />
    </Wrapper>
  )
}

export default ExtensionVersions
