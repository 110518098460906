import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import { Actions, StyledButton } from './MobileAppVersions.styles'
import { STATUS_FILTERS, MOBILE_APP_PLATFORM_FILTERS } from 'const/mobileApp'
import CheckboxIcon from 'components/CheckboxIcon'

export default (
  filters,
  sorter,
  handleEditAppVersion,
  handleDeleteAppVersion
) => [
  {
    title: 'ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 110,
    fixed: 'left',
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Platform',
    dataIndex: FIELD_NAMES.platform,
    key: FIELD_NAMES.platform,
    width: 200,
    align: 'center',
    filters: MOBILE_APP_PLATFORM_FILTERS,
    filteredValue: filters[FIELD_NAMES.platform] || null,
  },
  {
    title: 'Update required',
    width: 200,
    align: 'center',
    dataIndex: FIELD_NAMES.updateRequired,
    key: FIELD_NAMES.updateRequired,
    filters: STATUS_FILTERS,
    filteredValue: filters[FIELD_NAMES.updateRequired] || null,
    render: (checked) => <CheckboxIcon checked={checked} />,
  },
  {
    title: 'Version',
    dataIndex: FIELD_NAMES.version,
    key: FIELD_NAMES.version,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.version && sorter.order,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    fixed: 'right',
    width: 200,
    render: (_, version) => {
      const onEdit = () => handleEditAppVersion(version[FIELD_NAMES.id])
      const onDelete = () => handleDeleteAppVersion(version)
      return (
        <Actions>
          <StyledButton onClick={onEdit}>Edit</StyledButton>
          <StyledButton onClick={onDelete} type="danger">
            Delete
          </StyledButton>
        </Actions>
      )
    },
  },
]
