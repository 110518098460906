import { FIELD_NAMES } from 'const/forms'

export default (sorter) => [
  {
    title: 'Email',
    dataIndex: FIELD_NAMES.email,
    key: FIELD_NAMES.email,
    align: 'center',
    width: 100,
    sorter: false,
    sortOrder: sorter.columnKey === FIELD_NAMES.email && sorter.order,
  },
  {
    title: 'Rules Created',
    dataIndex: FIELD_NAMES.rulesCreated,
    key: FIELD_NAMES.rulesCreated,
    align: 'center',
    width: 80,
    sorter: false,
    sortOrder: sorter.columnKey === FIELD_NAMES.rulesCreated && sorter.order,
  },
  {
    title: 'Rules Updated',
    dataIndex: FIELD_NAMES.rulesUpdated,
    key: FIELD_NAMES.rulesUpdated,
    align: 'center',
    width: 80,
    sorter: false,
    sortOrder: sorter.columnKey === FIELD_NAMES.rulesUpdated && sorter.order,
  },
  {
    title: 'Properties Updated',
    dataIndex: FIELD_NAMES.propertiesUpdated,
    key: FIELD_NAMES.propertiesUpdated,
    align: 'center',
    width: 80,
    sorter: false,
    sortOrder:
      sorter.columnKey === FIELD_NAMES.propertiesUpdated && sorter.order,
  },
]
