import styled from 'styled-components'
import { Button } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

export const Wrapper = styled.li`
  min-width: 200px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: white;
  margin: 10px 0;

  &:hover {
    background: #1890ff;
    color: white;
  }

  ${({ isSelected }) =>
    isSelected ? 'border: 1px solid #1890ff' : 'border: 1px solid transparent'}
`

export const Content = styled.div`
  display: flex;
  padding: 10px;
  width: 100%;
  height: 100%;
  align-items: center;
  user-select: none;
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SaveButton = styled(Button)``

export const DNDIcon = styled(MenuOutlined)`
  padding: 0 10px;
  cursor: move;
`
