import React from 'react'
import { useSelector } from 'react-redux'
import { selectors as generalSelectors } from 'domains/general'
import useAction from 'utils/useAction'
import {
  getCurrentShopMainCategory,
  getCurrentShopRegularCategories,
} from 'domains/shops/selectors'
import {
  createShopCategory,
  updateShopCategory,
  deleteShopCategory,
} from 'actions/views/shops'
import {
  StyledSelect,
  Wrapper,
  StyledMainSelect,
} from './ShopCategoriesSelect.styles'

const ShopCategoriesSelect = () => {
  const currentShopMainCategory = useSelector(getCurrentShopMainCategory)
  const currentShopRegularCategories = useSelector(
    getCurrentShopRegularCategories
  )

  const categoryMainValue = currentShopMainCategory?.category_id
  const categoriesRegularValue = currentShopRegularCategories.map(
    (category) => category.category_id
  )

  const options = useSelector(generalSelectors.categoriesOptions)
  const regularOptions = useSelector(
    generalSelectors.filteredCategoriesOptions
  )(categoryMainValue)

  const createShopCategoryFunc = useAction(createShopCategory)
  const updateShopCategoryFunc = useAction(updateShopCategory)
  const deleteShopCategoryFunc = useAction(deleteShopCategory)

  const categoriesChangeHandler = (updatedIds) => {
    for (let id of updatedIds) {
      if (!categoriesRegularValue.includes(id)) {
        const addedCategoryId = id

        createShopCategoryFunc({
          values: {
            categoryId: addedCategoryId,
            main: false,
          },
        })

        return
      }
    }

    for (let id of categoriesRegularValue) {
      if (!updatedIds.includes(id)) {
        const removedId = currentShopRegularCategories.find(
          (category) => category.category_id === id
        )?.id

        deleteShopCategoryFunc({
          values: removedId,
        })

        return
      }
    }
  }

  const handleChangeMainCategory = (updatedCategoryId) => {
    const updatedShopCategoryId = currentShopRegularCategories.find(
      (category) => category.category_id === updatedCategoryId
    )?.id

    const isReplaceMainCategory = !!currentShopMainCategory?.id

    if (isReplaceMainCategory) {
      updateShopCategoryFunc({
        values: {
          main: false,
          id: currentShopMainCategory.id,
        },
      })
    }

    if (updatedShopCategoryId) {
      updateShopCategoryFunc({
        values: {
          main: true,
          id: updatedShopCategoryId,
        },
      })

      return
    }

    if (updatedCategoryId) {
      createShopCategoryFunc({
        values: {
          categoryId: updatedCategoryId,
          main: true,
        },
      })
    }
  }

  return (
    <Wrapper>
      <StyledMainSelect
        options={options}
        placeholder="Main category"
        value={categoryMainValue}
        allowClear
        onChange={handleChangeMainCategory}
      />
      <StyledSelect
        value={categoriesRegularValue}
        mode="multiple"
        options={regularOptions}
        placeholder="Please select"
        onChange={categoriesChangeHandler}
      />
    </Wrapper>
  )
}

export default ShopCategoriesSelect
