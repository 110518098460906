import styled from 'styled-components'
import { Col } from 'antd'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;
  margin: 10px auto;
  width: 50%;

  @media (max-width: 700px) {
    width: 90%;
  }
`

export const StyledCol = styled(Col)`
  padding-bottom: 10px;
  & > div {
    margin: 0;
    width: 100%;
    justify-content: flex-start;

    & .field-control {
      max-width: 100%;
      width: 100%;
    }
  }

  &:last-child > button {
    margin-top: 26px;
    width: 100%;
  }
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
