import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { createSEOPage, updateSEOPage } from 'actions/views/productFamilies'
import useAction from 'utils/useAction'
import { Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/Input'
import { FIELD_NAMES } from 'const/forms'
import { required, validFilename } from 'helpers/validators'
import { selectors as landinPageSelectors } from 'domains/SEOPages'
import { FlexWrapper, StyledButton, StyledForm } from './SEOPage.styles'
import {
  SEO_LARGE_IMAGE_CROP_SIZE,
  SEO_SMALL_IMAGE_CROP_SIZE,
} from 'const/retailers'
import ImagePicker from 'components/ImagePicker'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper'
import Markdown from 'components/Markdown/Markdown'
import Status from 'components/Status'

const SEOPage = ({ id, closeModal }) => {
  const isSavingActive = useSelector(landinPageSelectors.getIsSavingActive)
  const initialValues = useSelector(landinPageSelectors.getSEOPageById)(id)
  const createSEOPageFunc = useAction(createSEOPage)
  const updateSEOPageFunc = useAction(updateSEOPage)
  const onSubmit = (values) => {
    if (id) {
      const { [FIELD_NAMES.productPreview]: image, ...restValues } = values
      updateSEOPageFunc({
        values: {
          ...restValues,
          ...(image !== initialValues[FIELD_NAMES.productPreview] && {
            [FIELD_NAMES.productPreview]: image,
          }),
        },
        callback: closeModal,
      })
    } else {
      createSEOPageFunc({ values, callback: closeModal })
    }
  }

  return (
    <>
      <Form
        destroyOnUnregister
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, pristine, valid }) => (
          <Spin spinning={isSavingActive} size="large">
            <StyledForm onSubmit={handleSubmit}>
              <FieldWrapper>
                <Status value={initialValues.is_published} />
                {initialValues.is_published ? (
                  <p>Published</p>
                ) : (
                  <p>Unpublished</p>
                )}
              </FieldWrapper>
              {id && <Field name="id" label="ID" disabled component={Input} />}
              <Field
                required
                name={FIELD_NAMES.name}
                validate={validFilename}
                label="Product Family"
                placeholder="Product Family"
                component={Input}
              />
              <Field
                required
                name={FIELD_NAMES.title}
                validate={required}
                label="Title"
                placeholder="Title"
                component={Input}
              />
              <Field
                name={FIELD_NAMES.excerpt}
                validate={required}
                label="Excerpt"
                placeholder="Excerpt"
                component={Markdown}
              />
              <Field
                name={FIELD_NAMES.description}
                validate={required}
                label="Description"
                placeholder="Description"
                component={Markdown}
                maxChars={2500}
              />
              <Field
                name={FIELD_NAMES.altText}
                label="Alt Text"
                placeholder="Alt Text"
                component={Input}
              />
              <FieldWrapper label="Small Image" required>
                <FlexWrapper>
                  <Field
                    hasCropper
                    validate={required}
                    cropSize={SEO_SMALL_IMAGE_CROP_SIZE}
                    name={FIELD_NAMES.smallImage}
                    component={ImagePicker}
                  />
                </FlexWrapper>
              </FieldWrapper>
              <FieldWrapper label="Large Image" required>
                <FlexWrapper>
                  <Field
                    hasCropper
                    validate={required}
                    cropSize={SEO_LARGE_IMAGE_CROP_SIZE}
                    name={FIELD_NAMES.largeImage}
                    component={ImagePicker}
                  />
                </FlexWrapper>
              </FieldWrapper>
              <StyledButton
                type="primary"
                disabled={pristine || !valid}
                onClick={handleSubmit}
              >
                Save changes
              </StyledButton>
            </StyledForm>
          </Spin>
        )}
      />
    </>
  )
}

SEOPage.propTypes = {
  id: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
}

SEOPage.defaultTypes = {
  id: null,
}

export default SEOPage
