import { createSlice } from '@reduxjs/toolkit'
import setWith from 'lodash/setWith'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import pickBy from 'lodash/pickBy'
import isEmpty from 'lodash/isEmpty'
import { mergeDirtyFields } from 'helpers/retailers'
import { mergeAddedRetailers } from './helpers'

const initialState = {
  sortedIds: [],
  entities: {},
  allEntities: {},
  filters: {},
  sorter: {},
  changedRetailers: {},
  dirtyFields: {},
  changedEntities: {},
  isLoaded: false,
  deletedSets: [],
  isSavingActive: false,
  search: '',
  page: 1,
  pageCount: 1,
  isReinitialize: true,
}

const normalizedRetailersSlice = createSlice({
  name: 'retailers',
  initialState,
  reducers: {
    saveNormalizedRetailers: (state, { payload }) => payload,
    resetFiltersAndSorter: (state) => ({
      ...state,
      filters: initialState.filters,
      sorter: initialState.sorter,
    }),
    updateNormalizedRetailers: (state, { payload }) => payload,
    updateDirtyFields: (state, { payload }) => ({
      ...state,
      dirtyFields: mergeDirtyFields(
        JSON.parse(JSON.stringify(state.dirtyFields)),
        payload.dirtyFields,
        payload.values
      ),
    }),
    addNormalizedRetailers: (state, { payload }) =>
      mergeAddedRetailers(state, payload),
    saveChangedValues: (state, { payload }) => ({
      ...state,
      changedRetailers: merge(
        JSON.parse(JSON.stringify(state.changedRetailers)),
        payload.changes
      ),
      dirtyFields: merge(
        JSON.parse(JSON.stringify(state.dirtyFields)),
        payload.dirtyFields
      ),
    }),
    resetRetailerChanges: (state, { payload }) => ({
      ...state,
      changedRetailers: pickBy(
        omit(JSON.parse(JSON.stringify(state.changedRetailers)), payload),
        (item) => !isEmpty(item)
      ),
      dirtyFields: omit(JSON.parse(JSON.stringify(state.dirtyFields)), payload),
    }),
    replaceChangedValues: (state, { payload }) => ({
      ...state,
      changedRetailers: payload.changes,
      dirtyFields: payload.dirtyFields,
    }),
    resetChangedValues: (state) => ({
      ...state,
      changedRetailers: initialState.changedRetailers,
      dirtyFields: initialState.dirtyFields,
    }),
    changeEntity: (state, { payload: { path, value } }) => {
      setWith(state.changedEntities, path, value, Object)
      return state
    },
    setSearchString: (state, action) => ({
      ...state,
      search: action.payload,
    }),
    setPage: (state, action) => ({
      ...state,
      page: action.payload,
    }),
    startSaving: (state) => ({
      ...state,
      isSavingActive: true,
    }),
    finishSaving: (state) => ({
      ...state,
      isSavingActive: false,
    }),
    saveDeletedSets: (state, { payload }) => ({
      ...state,
      deletedSets: payload,
    }),
    startLoading: (state) => ({
      ...state,
      isLoaded: false,
    }),
    finishLoading: (state) => ({
      ...state,
      isLoaded: true,
    }),
    setRetailerAttributes: (state, { payload: { id, type, attributes } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [type]: {
          ...state.entities[type],
          [id]: {
            ...state.entities[type][id],
            attributes,
          },
        },
      },
    }),
    setReinitialize: (state, { payload }) => ({
      ...state,
      isReinitialize: payload,
    }),
    clearData: () => initialState,
  },
})

export default normalizedRetailersSlice
