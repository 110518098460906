import { all, put, takeLatest } from 'redux-saga/effects'
import { startLoading, finishLoading } from 'reducers/layoutSlice'
import { actions as extensionVersionActions } from 'domains/extensionVersions'
import * as actions from 'actions/views/extensionVersions'

function* markExtensionPassedReviewWorker({ payload }) {
  try {
    yield all([
      put(startLoading()),
      put(extensionVersionActions.markExtensionPassedReview(payload.value)),
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

export default function* () {
  yield all([
    takeLatest(
      actions.markExtensionPassedReview,
      markExtensionPassedReviewWorker
    ),
  ])
}
