import modalsSlice from './slice'
import { MODAL_TYPES } from 'const/modals'

export const { close, open, closePrev, clearModalsStack } = modalsSlice.actions

export const openRestoreRetailerModal = (data) =>
  open({ type: MODAL_TYPES.RESTORE_RETAILER, data })
export const openStoreChangesModal = (data) =>
  open({ type: MODAL_TYPES.STORE_CHANGES, data })
export const openEditStoreImagesModal = (data) =>
  open({ type: MODAL_TYPES.STORE_IMAGES, data })
export const openInfoModal = (data) => open({ type: MODAL_TYPES.INFO, data })
export const openLeavingPageModal = (data) =>
  open({ type: MODAL_TYPES.STORE_CHANGES_LEAVING_PAGE, data })
export const openCommentsModal = (data) =>
  open({ type: MODAL_TYPES.COMMENTS, data })
export const openAdPackageModal = (data) =>
  open({ type: MODAL_TYPES.AD_PACKAGE, data })
export const openEditStoreContentModal = (data) =>
  open({ type: MODAL_TYPES.EDIT_STORE_CONTENT, data })
export const openConfirmModal = (data) =>
  open({ type: MODAL_TYPES.CONFIRM, data })
export const openMobileAppVersionModal = (data) =>
  open({ type: MODAL_TYPES.MOBILE_APP_VERSION, data })
export const openMergeUserModal = (data) =>
  open({ type: MODAL_TYPES.MERGE_USER, data })
export const openChoiceModal = (data) =>
  open({ type: MODAL_TYPES.CHOICE, data })
export const openUserModal = (data) => open({ type: MODAL_TYPES.USER, data })
export const openResetPasswordModal = (data) =>
  open({ type: MODAL_TYPES.RESET_USER_PASS, data })
export const openGlobalUpdateModal = (data) =>
  open({ type: MODAL_TYPES.GLOBAL_UPDATE, data })
export const openConvertToRetailerModal = (data) =>
  open({ type: MODAL_TYPES.CONVERT_TO_RETAILER, data })
export const openCreateRetailerModal = (data) =>
  open({ type: MODAL_TYPES.CREATE_RETAILER, data })
export const openProductModal = (data) =>
  open({ type: MODAL_TYPES.PRODUCT, data })
export const openLandingPageModal = (data) =>
  open({ type: MODAL_TYPES.LANDING_PAGE_IMAGES, data })
export const openMarketingPageModal = (data) =>
  open({ type: MODAL_TYPES.MARKETING_PAGES, data })
export const openSEOPageModal = (data) =>
  open({ type: MODAL_TYPES.SEO_PAGE, data })
export const openCouponModal = (data) =>
  open({ type: MODAL_TYPES.COUPON, data })
export const openCampaignModal = (data) =>
  open({ type: MODAL_TYPES.CAMPAIGN, data })
export const openRuleSlugModal = (data) =>
  open({ type: MODAL_TYPES.RULE_SLUG, data })
export const openAdminModal = (data) => open({ type: MODAL_TYPES.ADMIN, data })
export const openPaymentModal = (data) =>
  open({ type: MODAL_TYPES.PAYMENT, data })
export const openMobileNotificationModal = (data) =>
  open({ type: MODAL_TYPES.MOBILE_NOTIFICATION, data })
export const openGiveawayModal = (data) =>
  open({ type: MODAL_TYPES.GIVEAWAYS, data })
export const openSMSVerificationModal = (data) =>
  open({ type: MODAL_TYPES.SMS_VERIFICATION, data })
export const openShopModal = (data) => open({ type: MODAL_TYPES.SHOPS, data })
export const openCouponsSettingsModal = (data) =>
  open({ type: MODAL_TYPES.COUPONS_SETTINGS, data })
export const openBonusModal = (data) =>
  open({ type: MODAL_TYPES.BONUSES, data })
export const openChangePasswordModal = (data) =>
  open({ type: MODAL_TYPES.CHANGE_PASSWORD, data })
export const openUserBonusesModal = (data) =>
  open({ type: MODAL_TYPES.USER_BONUSES, data })
export const openTransactionDetailsModal = (data) =>
  open({ type: MODAL_TYPES.TRANSACTION_DETAILS, data })
export const openPaymentPackageModal = (data) =>
  open({ type: MODAL_TYPES.PAYMENT_PACKAGE, data })
export const openShopAdPackageModal = (data) =>
  open({ type: MODAL_TYPES.SHOP_AD_PACKAGE, data })
export const openShopCampaignDealModal = (data) =>
  open({ type: MODAL_TYPES.SHOP_CAMPAIGN_DEAL, data })
export const openShopOffersModal = (data) =>
  open({ type: MODAL_TYPES.SHOP_OFFERS, data })
export const openShopGroupsSettingsModal = (data) =>
  open({ type: MODAL_TYPES.SHOP_GROUPS_CATEGORIES, data })
export const openShopEnrichedDataModal = (data) =>
  open({ type: MODAL_TYPES.SHOP_ENRICHED_DATA, data })
export const openCreditCardNegativeFeedbackModal = (data) =>
  open({ type: MODAL_TYPES.CREDIT_CARD_NEGATIVE_FEEDBACK, data })
