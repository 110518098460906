import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  saveFiltersAndSorter,
  fetchProductsSuccess,
  fetchProductsFailure,
  clearProduct,
  startSaving,
  finishSaving,
  clearData,
} = reducer.actions

export const fetchProducts = createAction('products/fetchProducts')

export const deleteProduct = createAction('products/deleteProduct')
export const deleteProductSuccess = createAction(
  'products/deleteProductSuccess'
)
export const deleteProductFailure = createAction(
  'products/deleteProductFailure'
)

export const createProduct = createAction('products/createProduct')
export const createProductSuccess = createAction(
  'products/createProductSuccess'
)
export const createProductFailure = createAction(
  'products/createProductFailure'
)

export const updateProduct = createAction('products/updateProduct')
export const updateProductSuccess = createAction(
  'products/updateProductSuccess'
)
export const updateProductFailure = createAction(
  'products/updateProductFailure'
)
