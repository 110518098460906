import {
  all,
  put,
  take,
  takeLatest,
  race,
  select,
  call,
} from 'redux-saga/effects'

import { startLoading, finishLoading } from 'reducers/layoutSlice'
import { formatBase64ForRequest } from 'helpers/files'
import {
  actions as paymentsActions,
  selectors as paymentsSelectors,
  formatters as paymentsFormatters,
} from 'domains/payments'
import * as actions from 'actions/views/payments'
import { FIELD_NAMES } from 'const/forms'

function* uploadPaymentListWorker({ payload }) {
  try {
    const body = {
      csv: formatBase64ForRequest(payload),
    }
    yield all([
      put(startLoading()),
      put(paymentsActions.uploadPaymentList(body)),
    ])
    const [success] = yield race([
      take(paymentsActions.uploadPaymentListSuccess),
      take(paymentsActions.uploadPaymentListFailure),
    ])

    if (success) {
      yield all([put(actions.fetchPaymentUpload()), yield call(getPayments)])
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* fetchPaymentUploadsWorker({ payload } = {}) {
  try {
    const search = yield select(
      paymentsSelectors.getSearchStringPaymentsUploads
    )
    const body = paymentsFormatters.formatPayloadForFetchRequest({
      search,
      ...payload,
    })
    yield all([
      put(startLoading()),
      put(paymentsActions.fetchPaymentUploads(body)),
    ])
    const [success] = yield race([
      take(paymentsActions.fetchPaymentUploadsSuccess),
      take(paymentsActions.fetchPaymentUploadsFailure),
    ])
    if (success) {
      yield put(paymentsActions.saveFiltersAndSorterPaymentUploads(payload))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* fetchBudgetWorker() {
  try {
    yield all([put(startLoading()), put(paymentsActions.fetchBudget())])
    yield take([
      paymentsActions.fetchBudgetSuccess,
      paymentsActions.fetchBudgetFailure,
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* fetchPaymentsWorker({ payload } = {}) {
  try {
    const search = yield select(paymentsSelectors.getSearchStringPayments)
    const body = paymentsFormatters.formatPayloadForFetchRequest({
      search,
      ...payload,
    })
    yield all([put(startLoading()), put(paymentsActions.fetchPayments(body))])
    const [success] = yield race([
      take(paymentsActions.fetchPaymentsSuccess),
      take(paymentsActions.fetchPaymentsFailure),
    ])
    if (success) {
      yield put(paymentsActions.saveFiltersAndSorterPayments(payload))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* getPayments() {
  const [search, page, filters, sorter] = yield all([
    select(paymentsSelectors.getSearchStringPayments),
    select(paymentsSelectors.getPagePayments),
    select(paymentsSelectors.getFiltersPayments),
    select(paymentsSelectors.getSorterPayments),
  ])
  yield put(
    actions.fetchPayments({
      search,
      page,
      filters,
      sorter,
    })
  )
}

function* deletePaymentWorker({ payload }) {
  try {
    yield all([
      put(startLoading()),
      put(paymentsActions.deletePayment(payload)),
    ])
    const [success] = yield race([
      take(paymentsActions.deletePaymentSuccess),
      take(paymentsActions.deletePaymentFailure),
    ])

    if (success) {
      yield call(getPayments)
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* approvePaymentWorker({ payload }) {
  try {
    yield all([
      put(startLoading()),
      put(paymentsActions.approvePayment(payload)),
    ])
    const [success] = yield race([
      take(paymentsActions.approvePaymentSuccess),
      take(paymentsActions.approvePaymentFailure),
    ])

    if (success) {
      yield call(getPayments)
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* resendPaymentWorker({ payload }) {
  try {
    yield all([
      put(startLoading()),
      put(paymentsActions.resendPayment(payload)),
    ])
    const [success] = yield race([
      take(paymentsActions.resendPaymentSuccess),
      take(paymentsActions.resendPaymentFailure),
    ])

    if (success) {
      yield call(getPayments)
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* retractPaymentWorker({ payload }) {
  try {
    yield all([
      put(startLoading()),
      put(paymentsActions.retractPayment(payload)),
    ])
    const [success] = yield race([
      take(paymentsActions.retractPaymentSuccess),
      take(paymentsActions.retractPaymentFailure),
    ])

    if (success) {
      yield call(getPayments)
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* rejectPaymentWorker({ payload }) {
  try {
    yield all([
      put(startLoading()),
      put(paymentsActions.rejectPayment(payload)),
    ])
    const [success] = yield race([
      take(paymentsActions.rejectPaymentSuccess),
      take(paymentsActions.rejectPaymentFailure),
    ])

    if (success) {
      yield call(getPayments)
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* updatePaymentWorker({ payload }) {
  try {
    const body = paymentsFormatters.formatPaymentForUpdate(payload.values)
    yield all([
      put(paymentsActions.startSaving()),
      put(
        paymentsActions.updatePayment({
          data: body,
          id: payload.values[FIELD_NAMES.id],
        })
      ),
    ])
    const [success] = yield race([
      take(paymentsActions.updatePaymentSuccess),
      take(paymentsActions.updatePaymentFailure),
    ])

    if (success) {
      yield call(getPayments)
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(paymentsActions.finishSaving())
  }
}

function* createPaymentWorker({ payload }) {
  try {
    const body = paymentsFormatters.formatPaymentForRequests(payload.values)
    yield all([
      put(paymentsActions.startSaving()),
      put(paymentsActions.createPayment(body)),
    ])
    const [success] = yield race([
      take(paymentsActions.createPaymentSuccess),
      take(paymentsActions.createPaymentFailure),
    ])

    if (success) {
      yield call(getPayments)
      if (payload.callback) {
        payload.callback()
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(paymentsActions.finishSaving())
  }
}

export default function* () {
  yield all([
    fetchPaymentUploadsWorker(),
    fetchPaymentsWorker(),
    fetchBudgetWorker(),
    takeLatest(actions.uploadPaymentList, uploadPaymentListWorker),
    takeLatest(actions.fetchPaymentUpload, fetchPaymentUploadsWorker),
    takeLatest(actions.fetchBudget, fetchBudgetWorker),
    takeLatest(actions.fetchPayments, fetchPaymentsWorker),
    takeLatest(actions.deletePayment, deletePaymentWorker),
    takeLatest(actions.approvePayment, approvePaymentWorker),
    takeLatest(actions.rejectPayment, rejectPaymentWorker),
    takeLatest(actions.retractPayment, retractPaymentWorker),
    takeLatest(actions.resendPayment, resendPaymentWorker),
    takeLatest(actions.updatePayment, updatePaymentWorker),
    takeLatest(actions.createPayment, createPaymentWorker),
  ])
}
