import React from 'react'
import Input from 'components/styled/InputWithInvalidState'
import FormItem from 'components/FormItem/FormItem'

const FieldInput = ({
  input: { value, onChange },
  meta: { dirty, modified, error },
  label,
  ...rest
}) => {
  const changeHandler = (e) => onChange(e.target.value)

  return (
    <FormItem label={label} $isDirty={dirty}>
      <Input
        invalid={modified && error}
        value={value}
        onChange={changeHandler}
        {...rest}
      />
    </FormItem>
  )
}

export default FieldInput
