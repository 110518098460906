import styled, { css } from 'styled-components'
import { Layout, Table } from 'antd'
import { DatePicker } from 'antd'

const { RangePicker } = DatePicker

const invalid = (props) => {
  if (props.invalid) {
    return css`
      border-color: rgb(255, 0, 0, 1);
      box-shadow: none;

      &:hover,
      &:focus {
        border-color: rgb(255, 0, 0, 1);
        box-shadow: 0 0 0 2px rgb(255, 0, 0, 0.5);
      }
    `
  }
}

export const Wrapper = styled(Layout.Content)`
  min-height: calc(100vh - 64px);
`

export const StyledTable = styled(Table)`
  margin-bottom: 30px;

  .ant-table-title {
    padding: 0;
  }

  .ant-table-cell {
    cursor: pointer;
  }
`

export const StyledRangePicker = styled(RangePicker)`
  margin: 10px;
  ${invalid}
`
