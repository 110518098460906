import React from 'react'
import { render } from 'react-dom'
import { ConnectedRouter } from 'connected-react-router'
import 'antd/dist/antd.css'
import GlobalStyles from './styles'
import App from './pages/App'
import createStore, { history } from './store'
import { Provider } from 'react-redux'

export const store = createStore()
const root = document.getElementById('root')

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
      <GlobalStyles />
    </ConnectedRouter>
  </Provider>,
  root
)

if (module.hot) {
  module.hot.accept('./pages/App', () => {
    const NextApp = require('./pages/App').default

    render(
      <Provider store={store}>
        <NextApp />
      </Provider>,
      root
    )
  })
}
