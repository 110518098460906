import React from 'react'
import { Checkbox as AntCheckbox } from 'antd'

const Checkbox = ({ input, meta, ...rest }) => (
  <AntCheckbox
    checked={!!input.value}
    onChange={(e) => input.onChange(e.target.checked)}
    {...rest}
  />
)

export default Checkbox
