import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .field-wrapper {
    justify-content: flex-start;
    height: 32px;
  }

  .field-control {
    max-width: 180px;
    width: 100%;
  }
`
