import React from 'react'
import { Button, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { SubmitWrapper } from './SubmitWrapper.styles.js'

const SubmitButton = ({ handleSubmit, submitting, pristine, text }) => (
  <SubmitWrapper>
    <Spin spinning={submitting} indicator={<LoadingOutlined />}>
      <Button
        type="primary"
        onClick={handleSubmit}
        disabled={submitting || pristine}
      >
        {text}
      </Button>
    </Spin>
  </SubmitWrapper>
)

export default SubmitButton
