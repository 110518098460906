import styled from 'styled-components'
import { InputNumber, Switch } from 'antd'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const PriorityInputNumber = styled(InputNumber)`
  width: 90px;
  margin-left: 10px;
`

export const StyledSwitch = styled(Switch)``
