import styled, { css } from 'styled-components'
import { Input } from 'antd'
const { TextArea } = Input

const invalid = (props) => {
  if (props.invalid) {
    return css`
      border-color: rgb(255, 0, 0, 1);
      box-shadow: none;

      &:hover,
      &:focus {
        border-color: rgb(255, 0, 0, 1);
        box-shadow: 0 0 0 2px rgb(255, 0, 0, 0.5);
      }
    `
  }
}

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  ${invalid}
`
