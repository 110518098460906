import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  saveNormalizedRetailers,
  resetFiltersAndSorter,
  updateNormalizedRetailers,
  addNormalizedRetailers,
  setSearchString,
  setPage,
  changeEntity,
  startLoading,
  finishLoading,
  startSaving,
  finishSaving,
  saveChangedValues,
  resetChangedValues,
  setRetailerAttributes,
  resetRetailerChanges,
  replaceChangedValues,
  clearData,
  saveDeletedSets,
  setReinitialize,
} = reducer.actions
export const resetSavedValues = createAction('retailers/resetSavedValues')
export const syncImages = createAction('retailers/syncImages')
export const syncImagesSuccess = createAction('retailers/syncImagesSuccess')
export const syncImagesFailure = createAction('retailers/syncImagesFailure')
export const copyImages = createAction('retailers/copyImages')
export const copyImagesSuccess = createAction('retailers/copyImagesSuccess')
export const copyImagesFailure = createAction('retailers/copyImagesFailure')
export const getRetailerImagesById = createAction(
  'retailers/getRetailerImagesById'
)
export const getRetailerImagesByIdFailure = createAction(
  'retailers/getRetailerImagesByIdFailure'
)
export const changeGlobal = createAction('retailers/changeGlobal')
export const changePage = createAction('retailers/changePage')
export const saveChanges = createAction('retailers/saveChanges')
export const updateRetailerImages = createAction(
  'retailers/updateRetailerImages'
)
export const changeSearchString = createAction('retailers/changeSearchString')
export const changeFiltersOrSorter = createAction(
  'retailers/changeFiltersOrSorter'
)
export const fetchMainRetailers = createAction('retailers/fetchMainRetailers')
export const saveRetailersChanges = createAction(
  'retailers/saveRetailersChanges'
)
export const saveRetailersCommentsChanges = createAction(
  'retailers/saveRetailersComments'
)
export const updateRetailer = createAction('retailers/updateRetailer')
export const convertToRetailer = createAction('retailers/convertToRetailer')
export const deleteRetailer = createAction('retailers/deleteRetailer')
export const restoreRetailer = createAction('retailers/restoreRetailer')
export const restoreRetailerSuccess = createAction(
  'retailers/restoreRetailerSuccess'
)
export const restoreRetailerFailure = createAction(
  'retailers/restoreRetailerFailure'
)
export const createCleanRetailer = createAction('retailers/createCleanRetailer')
export const createDirtyRetailer = createAction('retailers/createDirtyRetailer')
export const changeFlagReinitialize = createAction(
  'retailers/changeFlagReinitialize'
)
