import React from 'react'
import { PageLayout } from 'components/styled/layout'
import AdminHistory from './AdminHistory'

const Logs = () => (
  <PageLayout>
    <AdminHistory />
  </PageLayout>
)

export default Logs
