import styled from 'styled-components'

export const CardWrapper = styled.div`
  margin: 12px 12px 0 12px;
`

export const ParametersContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: 56px;
`

export const Parameter = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
`

export const ActionButton = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
`
