import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { createPayment, updatePayment } from 'actions/views/payments'
import useAction from 'utils/useAction'
import { Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/Input'
import { FIELD_NAMES } from 'const/forms'
import { MAX_SINGLE_PAYMENT_AMOUNT, DEFAULT_PAYMENT_NAME } from 'const/payments'
import { required, validUserEmail } from 'helpers/validators'
import { selectors as paymentsSelectors } from 'domains/payments'
import { StyledButton, StyledForm } from './Payment.styles'
import InputNumber from 'components/Input/InputNumber'
import ConfirmModal from './ConfirmModal'

const Payment = ({ id, closeModal }) => {
  const [showModal, setShowModal] = useState(false)
  const isSavingActive = useSelector(paymentsSelectors.getIsSavingActive)
  const initialValues = useSelector(paymentsSelectors.getPaymentById)(id)
  const createPaymentFunc = useAction(createPayment)
  const updatePaymentFunc = useAction(updatePayment)

  const onSubmit = () => setShowModal(true)

  const handleCreatePayment = (values) => {
    if (id) {
      updatePaymentFunc({ values: { ...values, id }, callback: closeModal })
    } else {
      createPaymentFunc({ values, callback: closeModal })
    }
    setShowModal(false)
  }
  const hideModal = () => setShowModal(false)

  return (
    <Form
      destroyOnUnregister
      onSubmit={onSubmit}
      initialValues={
        initialValues || {
          [FIELD_NAMES.paymentName]: DEFAULT_PAYMENT_NAME,
        }
      }
      render={({ handleSubmit, pristine, values }) => (
        <Spin spinning={isSavingActive} size="large">
          <StyledForm onSubmit={handleSubmit}>
            {id && (
              <Field
                name={FIELD_NAMES.id}
                label="ID"
                disabled
                component={Input}
              />
            )}
            <Field
              disabled
              required
              name={FIELD_NAMES.paymentName}
              validate={required}
              label="Name"
              placeholder="Name"
              component={Input}
            />
            <Field
              required
              validate={validUserEmail}
              name={FIELD_NAMES.userEmail}
              label="User email"
              placeholder="User email"
              component={Input}
            />
            <Field
              name={FIELD_NAMES.amount}
              min={1}
              max={MAX_SINGLE_PAYMENT_AMOUNT}
              step={0.1}
              required
              validate={required}
              label="Amount"
              placeholder="Amount"
              component={InputNumber}
            />
            <StyledButton
              type="primary"
              disabled={pristine}
              onClick={handleSubmit}
            >
              Save
            </StyledButton>
            <ConfirmModal
              show={showModal}
              payment={values}
              callback={handleCreatePayment}
              closeModal={hideModal}
            />
          </StyledForm>
        </Spin>
      )}
    />
  )
}

Payment.propTypes = {
  id: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
}

Payment.defaultTypes = {
  id: null,
}

export default Payment
