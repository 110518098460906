import {
  all,
  call,
  put,
  select,
  takeLatest,
  take,
  delay,
} from 'redux-saga/effects'
import isEqual from 'lodash/isEqual'
import pickBy from 'lodash/pickBy'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import get from 'lodash/get'
import merge from 'lodash/merge'
import capitalize from 'lodash/capitalize'
import cloneDeep from 'lodash/cloneDeep'
import { diff } from 'deep-object-diff'
import { destringifyKeys } from 'helpers/formatters/forms'
import {
  mapRetailerChangesToRequestData,
  mapRetailerCommentsToRequestData,
} from 'helpers/formatters/requests'
import { mapFilters, mapSorters } from 'helpers/filters'
import { formatSetChangesForRequest } from 'helpers/formatters/images'
import normalized from 'json-api-normalizer'
import * as actions from './actions'
import * as api from './api'
import {
  getPage,
  getFilters,
  getSorter,
  getIdsForRetailers,
  getAllRetailersInitialValues,
  getAllEditorialRetailersInitialValues,
  getSearchString,
  getRetailersGroupsRelationships,
  getRetailersCategoriesRelationships,
  getDirtyFields,
  getChangedRetailers,
  getRetailerGroups,
  getRetailerCategories,
  retailersState,
  deletedSets,
  getInitialValuesRetailerImages,
} from './selectors'
import {
  // CASHBACK_ENTITIES,
  ENTITY_NAMES,
} from 'const/api'
import { SUCCESS_STATUSES } from 'const/api'
import {
  FIELD_NAMES,
  FORM_NAMES,
  GROUP_FIELD_NAME_PREFIX,
  EDITORIAL_CHANGES_FIELD_NAMES,
  DISABLED_FIELDS,
} from 'const/forms'
import { DURATION } from 'const/duration'
import { message } from 'antd'
import { resetValues, saveValues } from 'reducers/forms/actions'
import {
  fetchMainRetailers,
  resetChangedValues,
  saveChangedValues,
  replaceChangedValues,
  setPage,
  setSearchString,
  saveDeletedSets,
} from './actions'
import {
  mergeChanges,
  getSuccessIds,
  getDirtyFieldsFromChanges,
  mergeDirtyFields,
  getRetailerIdsForUpdate,
  filterDeletedRetailers,
} from 'helpers/retailers'
import { selectors as generalSelectors } from 'domains/general'
import {
  formChanges,
  getFormSavedValues,
  formValues,
  formDirtyFields,
  getChangeFormFieldHandler,
} from 'reducers/forms/selectors'
import {
  filterAvailableChanges,
  formatGlobalChanges,
  formatDirtyFields,
  replaceDoubleFields,
} from 'helpers/globalUpdate'
import { RETAILER_TYPE_TO_LABEL } from 'const/retailers'
import * as helpers from './helpers'
import { route } from 'selectors/routing'
import ROUTES from 'const/routes'

function* getRequestOptionsForRetailersFetching(payload) {
  const [oldSearch, oldPage, filters, sorter, state] = yield all([
    select(getSearchString),
    select(getPage),
    select(getFilters),
    select(getSorter),
    select(retailersState),
  ])
  const hasFiltersOrSorter =
    !payload?.ids &&
    (!isEqual(payload?.filters, filters) || !isEqual(payload?.sorter, sorter))
  const newPage = hasFiltersOrSorter ? 1 : payload?.page || oldPage

  const withCategories = payload?.withCategories

  const appliedSearch =
    payload?.search !== oldSearch ? payload?.search || '' : oldSearch
  const appliedFilters = payload?.filters || filters
  const appliedSorter = payload?.sorter || sorter

  return {
    state,
    search: appliedSearch,
    page: newPage,
    withCategories,
    hasFiltersOrSorter,
    shouldUpdatePage: !payload?.ids && oldPage !== newPage,
    shouldUpdateSearch: !payload?.ids && oldSearch !== appliedSearch,
    filters: {
      default: appliedFilters,
      formatted: payload?.ids ? '' : mapFilters(appliedFilters, withCategories),
    },
    sorter: {
      default: appliedSorter,
      formatted: payload?.ids ? '' : mapSorters(appliedSorter),
    },
  }
}

function* fetchMainRetailersHandler({ payload }) {
  try {
    yield put(actions.startLoading())
    const {
      withCategories,
      search,
      page,
      filters,
      sorter,
      state,
      shouldUpdatePage,
      shouldUpdateSearch,
    } = yield call(getRequestOptionsForRetailersFetching, payload)

    const { data } = yield call(api.getMainRetailers, {
      ...payload,
      withCategories,
      search,
      page,
      filters: filters.formatted,
      sorter: sorter.formatted,
    })
    const sortedIds = data.data.map((retailer) => retailer[FIELD_NAMES.id])
    const normalizedRetailers = normalized(data, {
      camelizeKeys: false,
      camelizeTypeValues: false,
    })
    const action = payload?.ids
      ? actions.updateNormalizedRetailers
      : actions.saveNormalizedRetailers
    const mergeRetailersFunc = payload?.ids
      ? helpers.mergeUpdatedRetailers
      : helpers.mergeFetchedRetailers

    const updatedRetailers = mergeRetailersFunc(state, {
      sortedIds,
      filteredIds: payload?.ids,
      data: normalizedRetailers,
      page_count: data.meta.page_count,
      filters: filters.default,
      sorter: sorter.default,
    })

    yield all([
      put(action(updatedRetailers)),
      shouldUpdatePage && put(setPage(page)),
      shouldUpdateSearch && put(setSearchString(search)),
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(actions.finishLoading())
  }
}

function* saveRetailersChangesWorker({ payload }) {
  try {
    yield put(actions.startSaving())
    const [
      entityIds,
      oldValues,
      retailersGroupsRelationships,
      retailersCategoriesRelationships,
      oldDirtyFields,
      getFormValues,
      getFormDirtyFields,
      savedValues,
    ] = yield all([
      select(getIdsForRetailers),
      select(getAllRetailersInitialValues),
      select(getRetailersGroupsRelationships),
      select(getRetailersCategoriesRelationships),
      select(getDirtyFields),
      select(formValues),
      select(formDirtyFields),
      select(getFormSavedValues),
    ])
    const { changes, valuesToSave, formName } = payload
    const values = getFormValues(formName)
    const dirtyFields = getFormDirtyFields(formName)
    const formattedChanges = destringifyKeys(changes)
    const formattedSaveInfo = destringifyKeys(valuesToSave)
    const formattedInitialInfo = destringifyKeys(oldValues)
    const formattedDirtyFields = mergeDirtyFields(
      oldDirtyFields,
      dirtyFields,
      values
    )

    const [requestData, requestDataWithAttrs] = mapRetailerChangesToRequestData(
      {
        changes: formattedChanges,
        valuesToSave: formattedSaveInfo,
        entityIds,
        oldValues: formattedInitialInfo,
        retailersGroupsRelationships,
        retailersCategoriesRelationships,
      }
    )

    const omittedProps = ['path', 'retailerId', FIELD_NAMES.ruleId]

    const requests = requestData.map((data) => {
      const body = omit(data, omittedProps)

      if (body.method && body.url) {
        return call(api.request, body)
      }
      // if (!CASHBACK_ENTITIES.includes(body.type)) {
      return call(api.updateEntity, body)
      // }
      // return call(body.id ? api.declineCashback : api.createEntity, body)
    })
    // const requestsWithCPA = requestDataWithCPA.map(data => call(api.updateEntity, omit(data, omittedProps)))
    const requestsWithAttrs = requestDataWithAttrs.map((data) => {
      const isTagsCount = data.attributes[FIELD_NAMES.tagsCount] !== undefined
      const bodyData = isTagsCount
        ? data[FIELD_NAMES.ruleId]
        : omit(data, omittedProps)
      const updateFunc = isTagsCount
        ? api.updateRetailerTagsCount
        : api.updateEntity

      return call(updateFunc, bodyData)
    })

    // const resultsWithCPA = yield all(requestsWithCPA)
    const results = yield all(requests)
    const resultsWithAttrs = yield all(requestsWithAttrs)

    const successIds = [
      // ...getSuccessIds(resultsWithCPA, requestDataWithCPA),
      ...getSuccessIds(results, requestData),
      ...getSuccessIds(resultsWithAttrs, requestDataWithAttrs),
    ]

    const idsForUpdate = getRetailerIdsForUpdate(successIds)

    const changedValues = {
      dirtyFields: successIds.reduce(
        (result, data) =>
          idsForUpdate.includes(data.retailerId)
            ? omit(result, data.path)
            : result,
        formattedDirtyFields
      ),
      changes: pickBy(
        successIds.reduce(
          (result, data) =>
            idsForUpdate.includes(data.retailerId)
              ? omit(result, data.path)
              : result,
          cloneDeep(changes)
        ),
        (item) => !isEmpty(item)
      ),
    }

    if (idsForUpdate.length) {
      yield all([
        put(actions.replaceChangedValues(changedValues)),
        put(saveValues(merge(cloneDeep(savedValues), values, changes))),
        put(
          actions.fetchMainRetailers({
            withCategories: formName === FORM_NAMES.EDITORIAL_CHANGES,
            search: '',
            page: 1,
            ids: idsForUpdate,
          })
        ),
      ])
      yield take([
        actions.saveNormalizedRetailers,
        actions.updateNormalizedRetailers,
      ])
    }
    yield delay(300)
    yield put(actions.finishSaving())
  } catch (error) {
    console.error(error)
  } finally {
    if (payload.callback) {
      payload.callback()
    }
  }
}

function* saveRetailersCommentsAndContactsWorker({ payload }) {
  try {
    yield put(actions.startSaving())
    const { retailerId, changes, mainId } = payload
    const ids = yield select(getIdsForRetailers)
    const requests = mapRetailerCommentsToRequestData({
      retailerId,
      changes,
      ids,
    })

    yield all(
      requests.map((request) =>
        call(request.api, omit(request.data, 'retailerId'))
      )
    )

    const [search, page] = yield all([select(getSearchString), select(getPage)])

    yield put(
      actions.fetchMainRetailers({
        search,
        page,
        ids: [mainId],
      })
    )

    yield take([
      actions.saveNormalizedRetailers,
      actions.updateNormalizedRetailers,
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(actions.finishSaving())
  }
}

function* updateRetailerWorker({ payload }) {
  try {
    yield put(actions.startSaving())
    const {
      data: { data },
    } = yield call(api.updateEntity, {
      id: payload.id,
      type: payload.type,
      attributes: payload.attributes,
    })
    yield put(
      actions.setRetailerAttributes({
        id: data.id,
        type: ENTITY_NAMES.retailers,
        attributes: data.attributes,
      })
    )

    const [search, page, filters, sorter, currentRoute] = yield all([
      select(getSearchString),
      select(getPage),
      select(getFilters),
      select(getSorter),
      select(route),
    ])
    yield put(
      actions.fetchMainRetailers({
        withCategories: currentRoute === ROUTES.EDITORIAL,
        search,
        page,
        filters,
        sorter,
      })
    )
    message.success(payload.successMessage, DURATION.show)
    payload.closeModal()
  } catch (error) {
    console.error(error)
  } finally {
    yield put(actions.finishSaving())
  }
}

function* getRetailerImagesByIdWorker({ payload }) {
  try {
    const state = yield select(retailersState)
    const { id } = payload
    const response = yield call(api.getRetailerImagesById, { id })
    if (response.data) {
      const { data } = response
      const normalizedRetailers = normalized(data, {
        camelizeKeys: false,
        camelizeTypeValues: false,
      })
      yield put(
        actions.updateNormalizedRetailers(
          helpers.mergeUpdatedRetailers(state, {
            sortedIds: [id],
            filteredIds: [id],
            data: normalizedRetailers,
          })
        )
      )
    } else {
      yield put(actions.getRetailerImagesByIdFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.getRetailerImagesByIdFailure())
  }
}

function* convertToRetailerWorker({
  payload: { id, data, sourceType, destinationType },
}) {
  try {
    yield put(actions.startSaving())
    try {
      yield call(api.convertToRetailer, id, data, sourceType, destinationType)
      message.success('Retailer has been successfully converted', DURATION.show)
    } catch (error) {
      message.error("Retailer hasn't been converted")
      console.log(error)
    }
    const [search, page, filters, sorter] = yield all([
      select(getSearchString),
      select(getPage),
      select(getFilters),
      select(getSorter),
    ])
    yield put(actions.fetchMainRetailers({ search, page, filters, sorter }))
  } catch (error) {
    console.log(error)
  } finally {
    yield put(actions.finishSaving())
  }
}

function* restoreRetailerWorker({ payload }) {
  try {
    const { data } = yield call(api.restoreRetailer, payload)
    if (data) {
      message.success('Retailer has been successfully restored', DURATION.show)
      yield put(actions.restoreRetailerSuccess())
    } else {
      yield put(actions.restoreRetailerFailure())
    }
  } catch (error) {
    message.error("Retailer hasn't been restored")
    yield put(actions.restoreRetailerFailure())
    console.log(error)
  }
}

function* deleteRetailerWorker({ payload: { id } }) {
  try {
    yield put(actions.startSaving())
    try {
      yield call(api.deleteRetailer, id)
      message.success('Retailer has been successfully deleted', DURATION.show)
    } catch (error) {
      message.error("Retailer hasn't been deleted")
      console.log(error)
    }
    const [search, page, filters, sorter] = yield all([
      select(getSearchString),
      select(getPage),
      select(getFilters),
      select(getSorter),
    ])
    yield put(actions.fetchMainRetailers({ search, page, filters, sorter }))
  } catch (error) {
    console.log(error)
  } finally {
    yield put(actions.finishSaving())
  }
}

function* syncImagesWorker({ payload }) {
  try {
    const response = yield call(api.syncImagesForRegional, payload)
    if (response?.data) {
      message.success('Sync process has been started', DURATION.show)
      yield put(actions.syncImagesSuccess())
    } else {
      message.error("Sync process hasn't been started")
      yield put(actions.syncImagesFailure())
    }
  } catch (error) {
    message.error("Sync process hasn't been started")
    yield put(actions.syncImagesFailure())
    console.log(error)
  }
}

function* copyImagesWorker({ payload }) {
  try {
    const response = yield call(api.copyImagesBetweenRetailers, payload)
    if (response.data) {
      message.success('Copying process has been started', DURATION.show)
      yield put(actions.copyImagesSuccess())
    } else {
      message.error("Copying process hasn't been started")
      yield put(actions.copyImagesFailure())
    }
  } catch (error) {
    message.error("Copying process hasn't been started")
    yield put(actions.copyImagesFailure())
    console.log(error)
  }
}

function* createCleanRetailerWorker({ payload: { data, retailerType } }) {
  try {
    yield put(actions.startSaving())
    try {
      yield call(api.createCleanRetailer, data, retailerType)
      message.success(
        `${capitalize(
          RETAILER_TYPE_TO_LABEL[retailerType]
        )} retailer has been successfully created`,
        DURATION.show
      )
    } catch (error) {
      message.error(
        `${capitalize(
          RETAILER_TYPE_TO_LABEL[retailerType]
        )} retailer hasn't been created`
      )
      console.log(error)
    }
    const [search, page, filters, sorter] = yield all([
      select(getSearchString),
      select(getPage),
      select(getFilters),
      select(getSorter),
    ])
    yield put(actions.fetchMainRetailers({ search, page, filters, sorter }))
  } catch (error) {
    console.log(error)
  } finally {
    yield put(actions.finishSaving())
  }
}

function* createDirtyRetailerWorker({ payload: { data, retailerType } }) {
  try {
    yield put(actions.startSaving())
    try {
      yield call(api.createDirtyRetailer, data, retailerType)
      message.success(
        `${capitalize(
          RETAILER_TYPE_TO_LABEL[retailerType]
        )} retailer has been successfully created`,
        DURATION.show
      )
    } catch (error) {
      message.error(
        `${capitalize(
          RETAILER_TYPE_TO_LABEL[retailerType]
        )} retailer hasn't been created`
      )
      console.log(error)
    }
    const [search, page, filters, sorter] = yield all([
      select(getSearchString),
      select(getPage),
      select(getFilters),
      select(getSorter),
    ])
    yield put(actions.fetchMainRetailers({ search, page, filters, sorter }))
  } catch (error) {
    console.log(error)
  } finally {
    yield put(actions.finishSaving())
  }
}

function* changeGlobalRetailerWorker({ payload }) {
  try {
    yield put(actions.startLoading())
    const { id, fieldName, value } = payload

    const withCategories =
      EDITORIAL_CHANGES_FIELD_NAMES.includes(fieldName) ||
      fieldName.startsWith(GROUP_FIELD_NAME_PREFIX)
    const [
      initialValues,
      groups,
      categories,
      savedValues,
      activeChanges,
      activeValues,
    ] = yield all([
      select(
        withCategories
          ? getAllEditorialRetailersInitialValues
          : getAllRetailersInitialValues
      ),
      select(generalSelectors.getGroups),
      select(generalSelectors.categories),
      select(getFormSavedValues),
      select(formChanges),
      select(formValues),
    ])

    const activeFormChanges = activeChanges(
      withCategories ? FORM_NAMES.EDITORIAL_CHANGES : FORM_NAMES.STORE_CHANGES
    )
    const activeFormValues = activeValues(
      withCategories ? FORM_NAMES.EDITORIAL_CHANGES : FORM_NAMES.STORE_CHANGES
    )
    const changedRetailersData = merge(
      cloneDeep(initialValues),
      activeFormChanges
    )

    const { data } = yield call(api.getMainRetailerById, {
      id,
      withCategories,
    })

    const normalizedRetailer = normalized(data, {
      camelizeKeys: false,
      camelizeTypeValues: false,
    })

    yield put(actions.addNormalizedRetailers({ data: normalizedRetailer }))
    const retailerGroups = yield select(getRetailerGroups)
    const retailerCategories = yield select(getRetailerCategories)

    const newValues = formatGlobalChanges({
      data: {
        ...normalizedRetailer,
        [ENTITY_NAMES.regional]: filterDeletedRetailers(
          normalizedRetailer[ENTITY_NAMES.regional]
        ),
      },
      fieldName,
      value,
      activeChanges: changedRetailersData,
      withCategories,
      retailerGroups,
      groups,
      retailerCategories,
      categories,
    })

    let regionalChanges = pickBy(
      diff(
        merge(cloneDeep(initialValues), changedRetailersData, activeFormValues),
        newValues.changes
      ),
      (item) => !isEmpty(item)
    )

    if (
      value &&
      (DISABLED_FIELDS.includes(fieldName) ||
        fieldName.startsWith(GROUP_FIELD_NAME_PREFIX))
    ) {
      regionalChanges = filterAvailableChanges(
        regionalChanges,
        fieldName,
        merge(cloneDeep(newValues.formatted), activeFormValues),
        groups
      )
    }

    regionalChanges = replaceDoubleFields(
      fieldName,
      regionalChanges,
      value,
      changedRetailersData
    )

    const newChangesSinceLastSaving = diff(activeFormValues, regionalChanges)

    const dirty = formatDirtyFields(regionalChanges, fieldName)

    yield all([
      put(
        saveChangedValues({
          changes: newChangesSinceLastSaving,
          dirtyFields: dirty,
        })
      ),
      put(
        saveValues(
          merge(cloneDeep(savedValues), activeFormValues, regionalChanges)
        )
      ),
    ])
  } catch (error) {
    console.error(error)
    message.error(error)
  } finally {
    yield put(actions.finishLoading())
  }
}

function* changePageOrSearchWorker({ payload }) {
  const { values, changes, page, withCategories, deleted, search } = payload
  const [changedRetailers, filters, sorter, oldSearch] = yield all([
    select(getChangedRetailers),
    select(getFilters),
    select(getSorter),
    select(getSearchString),
  ])

  const newChanges = mergeChanges(changedRetailers, values, changes)
  const dirtyFields = getDirtyFieldsFromChanges(newChanges)

  const baseActions = !deleted
    ? [
        put(saveValues(values)),
        put(replaceChangedValues({ changes: newChanges, dirtyFields })),
      ]
    : []

  yield all(
    baseActions.concat(
      put(
        fetchMainRetailers({
          page: search !== oldSearch ? 1 : page,
          search,
          withCategories,
          filters,
          sorter,
          deleted,
        })
      )
    )
  )
}

function* changeFiltersOrSorterWorker({ payload }) {
  const { values, changes, filters, sorter, withCategories, deleted, search } =
    payload
  const [changedRetailers, savedValues] = yield all([
    select(getChangedRetailers),
    select(getFormSavedValues),
  ])

  const newChanges = mergeChanges(changedRetailers, values, changes)
  const dirtyFields = getDirtyFieldsFromChanges(newChanges)

  const baseActions = !deleted
    ? [
        put(saveValues(merge(cloneDeep(savedValues), values))),
        put(replaceChangedValues({ changes: newChanges, dirtyFields })),
      ]
    : []

  yield all(
    baseActions.concat(
      put(
        fetchMainRetailers({
          page: 1,
          withCategories,
          filters,
          sorter,
          search,
          deleted,
        })
      )
    )
  )
}

function* resetSavedValuesWorker({ payload }) {
  yield all([
    put(resetValues()),
    put(resetChangedValues()),
    !payload?.doNotResetPage && put(setPage(1)),
  ])
}

function* updateRetailerImagesWorker({ payload }) {
  try {
    const resetHandler = yield select(getChangeFormFieldHandler)
    const deletedFields = yield select(deletedSets)
    const resetSet = resetHandler(FORM_NAMES.IMAGES)
    let newDeletedFields = [...deletedFields]

    let requests = []

    yield put(actions.startSaving())
    const requestData = formatSetChangesForRequest(payload)
    requests = requests.concat(
      requestData.map((data) => call(api.request, data))
    )

    if (payload.values.hasOwnProperty(FIELD_NAMES.logo)) {
      requests.push(
        call(api.updateEntity, {
          id: payload.retailerId,
          type: ENTITY_NAMES.retailers,
          attributes: {
            [FIELD_NAMES.logo]: payload.values[FIELD_NAMES.logo],
          },
        })
      )
    }
    if (requests.length) {
      const results = yield all(requests)
      yield put(actions.getRetailerImagesById({ id: payload.retailerId }))
      yield take(actions.updateNormalizedRetailers)
      const getInitialValues = yield select(getInitialValuesRetailerImages)
      const initialValues = getInitialValues(payload.retailerId)
      const newSetValue = get(initialValues, FIELD_NAMES.set)
      results.forEach((result, index) => {
        const isUpdateLogoRequest = !requestData[index]
        const fieldName = !isUpdateLogoRequest
          ? `${FIELD_NAMES.set}[${requestData[index].index}]`
          : FIELD_NAMES.logo
        if (SUCCESS_STATUSES.includes(result?.status)) {
          if (isUpdateLogoRequest) {
            resetSet(fieldName, get(initialValues, fieldName))
          } else {
            newSetValue[requestData[index].index] = get(
              initialValues,
              fieldName
            )
            newDeletedFields = newDeletedFields.filter(
              (item) => item !== result.config.index
            )
          }
        }
      })
      resetSet(FIELD_NAMES.set, newSetValue.filter(Boolean))
      yield put(saveDeletedSets(newDeletedFields))
    }
  } finally {
    yield put(actions.finishSaving())
  }
}

function* changeFlagReinitializeWorker({ payload }) {
  yield put(actions.setReinitialize(payload))
}

export default function* () {
  yield all([
    takeLatest(actions.fetchMainRetailers, fetchMainRetailersHandler),
    takeLatest(actions.saveRetailersChanges, saveRetailersChangesWorker),
    takeLatest(
      actions.saveRetailersCommentsChanges,
      saveRetailersCommentsAndContactsWorker
    ),
    takeLatest(actions.updateRetailer, updateRetailerWorker),
    takeLatest(actions.convertToRetailer, convertToRetailerWorker),
    takeLatest(actions.restoreRetailer, restoreRetailerWorker),
    takeLatest(actions.deleteRetailer, deleteRetailerWorker),
    takeLatest(actions.createCleanRetailer, createCleanRetailerWorker),
    takeLatest(actions.createDirtyRetailer, createDirtyRetailerWorker),
    takeLatest(actions.resetSavedValues, resetSavedValuesWorker),
    takeLatest(actions.getRetailerImagesById, getRetailerImagesByIdWorker),
    takeLatest(actions.changePage, changePageOrSearchWorker),
    takeLatest(actions.changeGlobal, changeGlobalRetailerWorker),
    takeLatest(actions.changeSearchString, changePageOrSearchWorker),
    takeLatest(actions.updateRetailerImages, updateRetailerImagesWorker),
    takeLatest(actions.changeFiltersOrSorter, changeFiltersOrSorterWorker),
    takeLatest(actions.syncImages, syncImagesWorker),
    takeLatest(actions.copyImages, copyImagesWorker),
    takeLatest(actions.changeFlagReinitialize, changeFlagReinitializeWorker),
  ])
}
