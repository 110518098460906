import styled from 'styled-components'
import { Button, Layout, Table } from 'antd'

export const Wrapper = styled(Layout.Content)`
  margin-top: 10px;
`

export const StyledTable = styled(Table)`
  .ant-table-title {
    padding: 0;
  }
`

export const StyledButton = styled(Button)`
  &:first-child {
    margin-bottom: 10px;
  }
`

export const Buttons = styled.div`
  display: flex;
  margin-right: 10px;
  & > button {
    margin-left: 10px;
  }
`
