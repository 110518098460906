import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  startSaving,
  finishSaving,
  setKProjectInstalledData,
  setIntervalTestData,
  clearData,
  setScoreData,
} = reducer.actions

export const postKProjectDates = createAction('algorithms/postKProjectDates')
export const postIntervalTest = createAction('algorithms/postIntervalTest')
