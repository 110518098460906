import { Upload, Button, message } from 'antd'
import React from 'react'
import axios from 'axios'
import { BO_API_URL } from 'const/env'

const props = {
  action: `${BO_API_URL}/retailers/import`,
  listType: 'file',
  previewFile(file) {
    return axios
      .post(`${BO_API_URL}/retailers/import`, file, {
        'Content-Type': 'multipart/form-data',
      })
      .catch((e) => {
        message.error(e.message)
      })
  },
  onChange(info) {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`)
    }
  },
}

const ImportBtn = () => {
  return (
    <Upload {...props}>
      <Button type="primary">Import store list</Button>
    </Upload>
  )
}

export default ImportBtn
