import { FIELD_NAMES } from 'const/forms'
import { mapFilters, mapSorters } from 'helpers/jsonAPI'
import { ENTITY_NAMES, RELATIONSHIPS_NAMES } from 'const/api'

export const formatProduct = (product, tags) => {
  const tag =
    tags[product.relationships[RELATIONSHIPS_NAMES.tag].data[FIELD_NAMES.id]]
  return {
    [FIELD_NAMES.id]: product[FIELD_NAMES.id],
    [FIELD_NAMES.tagId]: product.attributes[FIELD_NAMES.tagId],
    [FIELD_NAMES.productPreview]: tag.attributes[FIELD_NAMES.productPreview],
    [FIELD_NAMES.originalUrl]: product.attributes[FIELD_NAMES.originalUrl],
    [FIELD_NAMES.name]: product.attributes[FIELD_NAMES.name],
    [FIELD_NAMES.productType]: product.attributes[FIELD_NAMES.productType],
    [FIELD_NAMES.createdAt]: product.attributes[FIELD_NAMES.createdAt],
    [FIELD_NAMES.company]: product.attributes[FIELD_NAMES.company],
    key: product[FIELD_NAMES.id],
  }
}

export const formatProducts = (products = {}, tags = {}) =>
  Object.values(products).reduce(
    (result, product) => ({
      ...result,
      [product[FIELD_NAMES.id]]: formatProduct(product, tags),
    }),
    {}
  ) || {}

export const formatPayloadForFetchRequest = ({
  page,
  filters,
  sorter,
  search,
} = {}) => ({
  page: page || 1,
  filters: mapFilters(filters),
  sorter: mapSorters(sorter),
  search,
})

export const formatProductForRequests = ({
  [FIELD_NAMES.tagId]: tagId,
  [FIELD_NAMES.id]: id,
  [FIELD_NAMES.productPreview]: preview,
  key,
  ...values
}) => ({
  ...(id && { id }),
  type: ENTITY_NAMES.products,
  attributes: {
    ...values,
    [FIELD_NAMES.originalUrl]: values?.hasOwnProperty(FIELD_NAMES.originalUrl)
      ? values[FIELD_NAMES.originalUrl]
      : null,
  },
  relationships: {
    [RELATIONSHIPS_NAMES.tag]: {
      data: {
        id: tagId,
        type: ENTITY_NAMES.tags,
      },
    },
  },
})
