import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearData: () => initialState,
  },
})

export default notificationsSlice
