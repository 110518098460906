import React from 'react'
import { Wrapper } from './LoadDataInfo.styles'
import { Button } from 'antd'
import useAction from 'utils/useAction'
import { openConfirmModal } from 'reducers/modals/actions'
import { useSelector } from 'react-redux'
import { getCurrentShopId, getIsProgramsLoaded } from 'domains/shops/selectors'
import * as shopActions from 'actions/views/shops'

const LoadDataInfo = ({ children }) => {
  const isLoaded = useSelector(getIsProgramsLoaded)
  const currentShopId = useSelector(getCurrentShopId)
  const openConfirmModalFunc = useAction(openConfirmModal)
  const fetchShopProgramsFunc = useAction(shopActions.fetchShopPrograms)

  const handleLoadPrograms = ({ children }) => {
    const callback = () => fetchShopProgramsFunc(currentShopId)
    const text = `Are you sure you want to load shop programs? This action may require a lot of resources.`
    openConfirmModalFunc({ callback, text })
  }

  return (
    <Wrapper>
      {children}
      <Button
        type="primary"
        disabled={!currentShopId || !isLoaded}
        onClick={handleLoadPrograms}
      >
        Reload Shop Programs
      </Button>
    </Wrapper>
  )
}

export default LoadDataInfo
