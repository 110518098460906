import React from 'react'
import moment from 'moment'
import partialRight from 'lodash/partialRight'
import { Field } from 'react-final-form'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'
import {
  AD_PACKAGE_TIME_DELAY,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
} from 'const/dates'
import { disabledDate, disabledTime, getDefaultDates } from 'helpers/dates'
import { StyledRangePicker } from 'components/CashbackInput/CashbackInput.styles'
import { FIELD_NAMES } from 'const/forms'
import { Button } from 'antd'
import ROUTES from 'const/routes'

const showTime = {
  format: TIME_FORMAT,
  defaultValue: getDefaultDates(AD_PACKAGE_TIME_DELAY),
}

const allowEmpty = [true, true]

const CustomRangePicker = ({ label, placeholder, keys, createMode }) => {
  let today = moment()

  const handlePreviewClick = (startDate) => {
    const formattedDate = moment(startDate)
    const time = formattedDate.format('LT')

    if (time === '12:00 AM') {
      window.open(
        `${ROUTES.BASE_WEB_DASHBOARD}?biscotti=${formattedDate.format(
          'YYYY-MM-DD'
        )}`
      )
    } else {
      window.open(
        `${ROUTES.BASE_WEB_DASHBOARD}?biscotti=${formattedDate
          .add(1, 'days')
          .format('YYYY-MM-DD')}`
      )
    }
  }

  return (
    <Field name={keys ? keys[0] : FIELD_NAMES.startDate}>
      {(startDateProps) => (
        <Field name={keys ? keys[1] : FIELD_NAMES.endDate}>
          {(endDateProps) => {
            const touched =
              startDateProps.meta.touched || endDateProps.meta.touched
            const modified =
              startDateProps.meta.modified || endDateProps.meta.modified
            const error = startDateProps.meta.error || endDateProps.meta.error
            const startDate = startDateProps.input.value
              ? moment(startDateProps.input.value)
              : ''
            const finishDate = endDateProps.input.value
              ? moment(endDateProps.input.value)
              : ''
            return (
              <FieldWrapper
                label={label || 'Date Range'}
                meta={{
                  touched: touched || modified,
                  error,
                }}
              >
                <StyledRangePicker
                  placeholder={placeholder}
                  invalid={(modified || touched) && error}
                  showTime={showTime}
                  allowEmpty={allowEmpty}
                  disabledDate={disabledDate}
                  disabledTime={partialRight(
                    disabledTime,
                    AD_PACKAGE_TIME_DELAY
                  )}
                  renderExtraFooter={() =>
                    `UTC${moment().format('Z')} time zone`
                  }
                  format={DATE_TIME_FORMAT}
                  value={[startDate, finishDate]}
                  onChange={(date) => {
                    if (date) {
                      const startDate = date[0]
                        ? moment(date[0]).toISOString()
                        : ''
                      const endDate = date[1]
                        ? moment(date[1]).toISOString()
                        : ''
                      startDateProps.input.onChange(startDate)
                      endDateProps.input.onChange(endDate)
                    } else {
                      startDateProps.input.onChange(null)
                      endDateProps.input.onChange(null)
                    }
                  }}
                />
                {!createMode && (
                  <Button
                    type="primary"
                    style={{ marginTop: '10px' }}
                    disabled={startDate && today.isSameOrAfter(startDate)}
                    onClick={() => handlePreviewClick(startDate)}
                  >
                    Preview
                  </Button>
                )}
              </FieldWrapper>
            )
          }}
        </Field>
      )}
    </Field>
  )
}

export default CustomRangePicker
