import reducer from './reducer'
import { createAction } from '@reduxjs/toolkit'

export const {
  finishFetching,
  resetRecords,
  saveRecords,
  saveMoreRecords,
  setSearch,
  setPage,
  startFetching,
} = reducer.actions

export const fetchRecords = createAction('search/fetchRecords')
export const fetchMoreRecords = createAction('search/fetchMoreRecords')
