import { all, call, put, takeLatest } from 'redux-saga/effects'
import normalized from 'json-api-normalizer'
import * as actions from './actions'
import * as api from './api'
import { getSortedIds } from 'helpers/jsonAPI'

function* fetchCampaignsWorker({ payload }) {
  try {
    const { data } = yield call(api.getCampaigns, payload)
    const normalizedData = normalized(data, {
      camelizeKeys: false,
      camelizeTypeValues: false,
    })
    yield put(
      actions.fetchCampaignsSuccess({
        ...payload,
        page: payload.page,
        pageCount: data.meta.page_count,
        campaigns: normalizedData,
        sortedIds: getSortedIds(data.data),
      })
    )
  } catch (error) {
    console.error(error)
  }
}

function* createCampaignWorker({ payload }) {
  try {
    const data = yield call(api.createCampaign, payload)
    if (data) {
      yield put(actions.createCampaignSuccess(payload))
    } else {
      yield put(actions.createCampaignFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createCampaignFailure())
  }
}

function* updateCampaignWorker({ payload }) {
  try {
    const { data: body } = payload
    const data = yield call(api.updateCampaign, body)
    if (data) {
      yield put(actions.updateCampaignSuccess(body))
    } else {
      yield put(actions.updateCampaignFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateCampaignFailure())
  }
}

function* deleteCampaignWorker({ payload }) {
  try {
    const data = yield call(api.deleteCampaign, payload)
    if (data) {
      yield put(actions.deleteCampaignSuccess(payload))
    } else {
      yield put(actions.deleteCampaignFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteCampaignFailure())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchCampaigns, fetchCampaignsWorker),
    takeLatest(actions.createCampaign, createCampaignWorker),
    takeLatest(actions.updateCampaign, updateCampaignWorker),
    takeLatest(actions.deleteCampaign, deleteCampaignWorker),
  ])
}
