import styled from 'styled-components'
import { Layout, Button } from 'antd'

export const Wrapper = styled(Layout.Content)`
  padding: 24px;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const StyledForm = styled.form`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledButton = styled(Button)`
  width: 300px;
`
