import React from 'react'
import { ENTITY_NAMES } from 'const/api'
import RegionalAvatar from '../RegionalAvatar/RegionalAvatar'
import { Mobile, Wallet, Global, Invalid } from './RegionalCountry.styles'
import { Tooltip } from 'antd'
import { retailerPropType } from 'const/propTypes'
import { FIELD_NAMES } from 'const/forms'

const RetailerCountry = ({ retailer }) => {
  const { type, country, [FIELD_NAMES.countryCode]: countryCode } = retailer

  switch (type) {
    case ENTITY_NAMES.regional:
      return (
        <RegionalAvatar
          useNameInTooltip
          country={country}
          countryCode={countryCode}
        />
      )
    case ENTITY_NAMES.mobile:
      return (
        <Tooltip title="Mobile retailer">
          <Mobile />
        </Tooltip>
      )
    case ENTITY_NAMES.domain:
      return (
        <Tooltip title="Domain retailer">
          <Wallet />
        </Tooltip>
      )
    case ENTITY_NAMES.main:
      return (
        <Tooltip title="Global retailer">
          <Global />
        </Tooltip>
      )
    default:
      return (
        <Tooltip title="Invalid retailer">
          <Invalid twoToneColor="#eb2f96" />
        </Tooltip>
      )
  }
}

RetailerCountry.propTypes = {
  retailer: retailerPropType.isRequired,
}

export default RetailerCountry
