import { all, call, put, takeLatest } from 'redux-saga/effects'
import normalized from 'json-api-normalizer'
import * as actions from './actions'
import * as api from './api'
import { getSortedIds } from 'helpers/jsonAPI'

function* fetchSEOPagesWorker({ payload }) {
  try {
    const { data } = yield call(api.getSEOPages, payload)

    const normalizedData = normalized(data, {
      camelizeKeys: false,
      camelizeTypeValues: false,
    })

    yield put(
      actions.fetchSEOPagesSuccess({
        ...payload,
        page: payload.page,
        pageCount: data.meta.page_count,
        product_families: normalizedData,
        sortedIds: getSortedIds(data.data),
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchSEOPagesFailure())
  }
}

function* createSEOPageWorker({ payload }) {
  try {
    const { data } = yield call(api.createSEOPage, payload)
    if (data) {
      yield put(actions.createSEOPagesuccess(data))
    } else {
      yield put(actions.createSEOPageFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createSEOPageFailure())
  }
}

function* updateSEOPageWorker({ payload }) {
  try {
    const { data } = yield call(api.updateSEOPage, payload)
    if (data) {
      yield put(actions.updateSEOPagesuccess(payload))
    } else {
      yield put(actions.updateSEOPageFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateSEOPageFailure())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchSEOPages, fetchSEOPagesWorker),
    takeLatest(actions.createSEOPage, createSEOPageWorker),
    takeLatest(actions.updateSEOPage, updateSEOPageWorker),
  ])
}
