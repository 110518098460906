import { all, put, race, select, take, takeLatest } from 'redux-saga/effects'
import * as actions from 'actions/views/customerService'
import { startLoading, finishLoading } from 'reducers/layoutSlice'
import {
  actions as userActions,
  selectors as userSelectors,
} from 'domains/user'
import {
  actions as adminActions,
  formatters as adminFormatters,
  selectors as adminSelectors,
} from 'domains/admin'

function* getTransactionsWorker({ payload } = {}) {
  try {
    const userEmail = yield select(userSelectors.selectedEmail)
    const email = payload?.email || userEmail
    const search = payload?.search
    if (email) {
      yield all([
        put(userActions.saveSelectedEmail(email)),
        put(userActions.fetchPaymentTransactions({ email, search })),
        put(userActions.fetchEngagementMetrics({ email })),
      ])
    }
  } catch (error) {
    console.error(error)
  }
}

function* getEngagementMetricsWorker() {
  try {
    const email = yield select(userSelectors.selectedEmail)
    yield put(userActions.fetchEngagementMetrics({ email }))

    yield race([
      take(userActions.fetchEngagementMetricsSuccess),
      take(userActions.fetchEngagementMetricsFailure),
    ])
  } catch (error) {
    console.error(error)
  }
}

function* getPaymentTransactionsWorker({ payload } = {}) {
  try {
    const email = yield select(userSelectors.selectedEmail)
    yield put(userActions.fetchPaymentTransactions({ email, page: payload }))

    yield take(userActions.savePaymentTransactions)
  } catch (error) {
    console.error(error)
  }
}

function* resetUserEmailWorker() {
  try {
    yield all([
      put(userActions.resetSelectedEmail()),
      put(userActions.resetAllTransactions()),
    ])
  } catch (error) {
    console.error(error)
  }
}

function* resetSearchWorker() {
  try {
    const email = yield select(userSelectors.selectedEmail)
    if (email) {
      yield all([
        put(userActions.resetSearch()),
        put(userActions.fetchPaymentTransactions({ email })),
        put(userActions.fetchEngagementMetrics({ email })),
      ])
    }
  } catch (error) {
    console.error(error)
  }
}

function* updateUserPreferencesWorker({ payload }) {
  try {
    const id = yield select(adminSelectors.adminId)
    const body = adminFormatters.formatPreferences(id, payload)

    yield all([
      put(startLoading()),
      put(adminActions.updatePaymentSettings({ id, data: body })),
    ])
    const [success] = yield race([
      take(adminActions.updatePaymentSettingsSuccess),
      take(adminActions.updatePaymentSettingsFailure),
    ])
    if (success) {
      yield put(adminActions.fetchAdminData(id))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

export default function* () {
  yield all([
    getTransactionsWorker(),
    takeLatest(actions.fetchUserTransactions, getTransactionsWorker),
    takeLatest(
      actions.fetchUserPaymentTransactions,
      getPaymentTransactionsWorker
    ),
    takeLatest(actions.fetchUserEngagmentMetrics, getEngagementMetricsWorker),
    takeLatest(actions.resetSelectedEmail, resetUserEmailWorker),
    takeLatest(actions.resetSearch, resetSearchWorker),
    takeLatest(actions.updateUserPreferences, updateUserPreferencesWorker),
  ])
}
