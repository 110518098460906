import styled from 'styled-components'
import { Table } from 'antd'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`

export const StyledTable = styled(Table)`
  margin-top: 20px;
`

export const Header = styled.h3`
  padding: 20px;
  margin: 0;
`
