import React, { useCallback, useEffect } from 'react'
import noop from 'lodash/noop'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useAction from 'utils/useAction'
import {
  formChanges,
  formattedChanges,
  isFormChangesEmpty,
  initialValuesForSaveForm,
  getResetFormHandler,
} from 'reducers/forms/selectors'
import { saveRetailersChanges } from 'domains/retailers/actions'
import Changes from './Changes'
import { Buttons, StyledButton, EmptyState } from './StoreChanges.styles'
import { getIsSavingActive } from 'domains/retailers/selectors'
import { Spin } from 'antd'

const StoreChanges = ({
  closeModal,
  changeFieldState,
  formName,
  isLeavingPageProcess,
  callback,
}) => {
  const saveRetailersChangesFunc = useAction(saveRetailersChanges)
  const isSavingActive = useSelector(getIsSavingActive)
  const resetFormHandler = useSelector(getResetFormHandler)
  const isEmptyChanges = useSelector(isFormChangesEmpty)(formName)
  const changes = useSelector(formattedChanges)(formName)
  const unFormattedChanges = useSelector(formChanges)(formName)
  const initialValues = useSelector(initialValuesForSaveForm)(formName)

  const onSuccess = () => {
    if (isLeavingPageProcess) {
      closeModal()
      callback()
    }
  }

  const onSubmit = (values) => {
    saveRetailersChangesFunc({
      valuesToSave: values,
      changes: unFormattedChanges,
      formName,
      callback: onSuccess,
    })
  }

  const onClose = useCallback(() => {
    if (isLeavingPageProcess) {
      resetFormHandler()
      closeModal()
      callback()
    } else {
      closeModal()
    }
  }, [isLeavingPageProcess, callback, closeModal, resetFormHandler])

  useEffect(() => {
    if (isEmptyChanges) {
      onClose()
    }
  }, [isEmptyChanges, onClose])

  return (
    <Spin spinning={isSavingActive} size="large">
      {isEmptyChanges ? (
        <EmptyState>There are no changes to save</EmptyState>
      ) : (
        <Form
          keepDirtyOnReinitialize
          destroyOnUnregister
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              {changes.map((data) => (
                <Changes
                  key={data.id}
                  data={data}
                  changeFieldState={changeFieldState}
                  formName={formName}
                />
              ))}
              <Buttons>
                <StyledButton
                  onClick={onClose}
                  type={isLeavingPageProcess ? 'danger' : 'default'}
                >
                  {isLeavingPageProcess ? 'Discard changes' : 'Cancel'}
                </StyledButton>
                <StyledButton
                  type="primary"
                  htmlType="submit"
                  disabled={submitting || isSavingActive}
                >
                  Save changes
                </StyledButton>
              </Buttons>
            </form>
          )}
        />
      )}
    </Spin>
  )
}

StoreChanges.propTypes = {
  isLeavingPageProcess: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  callback: PropTypes.func,
  changeFieldState: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
}

StoreChanges.defaultProps = {
  isLeavingPageProcess: false,
  callback: noop,
}

export default StoreChanges
