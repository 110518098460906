import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from './api'
import { formatDatesForRequest } from './formatters'

function* postKProjectDatesWorker({ payload }) {
  try {
    yield put(actions.clearData())
    yield put(actions.startSaving())
    const formattedParams = formatDatesForRequest(payload)
    const data = yield call(api.getMonkeyV1, formattedParams)

    if (data) {
      yield put(actions.setKProjectInstalledData(data.dates[0]))
      yield put(
        actions.setScoreData({
          scoreTwoDays: data.scoreTwoDays,
          scoreWeek: data.scoreWeek,
        })
      )
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(actions.finishSaving())
  }
}

function* postIntervalTestWorker() {
  try {
    yield put(actions.clearData())
    yield put(actions.startSaving())
    const data = yield call(api.getMonkeyV1)

    if (data) {
      yield put(actions.setIntervalTestData(data.dates))
      yield put(
        actions.setScoreData({
          scoreTwoDays: data.scoreTwoDays,
          scoreWeek: data.scoreWeek,
        })
      )
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(actions.finishSaving())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.postKProjectDates, postKProjectDatesWorker),
    takeLatest(actions.postIntervalTest, postIntervalTestWorker),
  ])
}
