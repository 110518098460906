import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { ENTITY_NAMES } from 'const/api'
import { formatSEOPages } from './formatters'
import { areFiltersAndSorterEmpty } from 'helpers/filters'

export const SEOPagesState = ({ SEOPages }) => SEOPages

export const getIsLoaded = createSelector(
  SEOPagesState,
  ({ isLoaded }) => isLoaded
)
export const getIsSavingActive = createSelector(
  SEOPagesState,
  ({ isSavingActive }) => isSavingActive
)

export const isLoadingOrSavingActive = createSelector(
  [getIsLoaded, getIsSavingActive],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getEntities = createSelector(
  SEOPagesState,
  ({ entities }) => entities
)
export const getSEOPages = createSelector(getEntities, (entities) =>
  formatSEOPages(entities[ENTITY_NAMES.SEOPages])
)
export const getSEOPageById = createSelector(getSEOPages, (SEOPage) =>
  memoize((id) => (id ? SEOPage[id] : null))
)
export const getSearchString = createSelector(
  SEOPagesState,
  ({ search }) => search
)
export const getFilters = createSelector(
  SEOPagesState,
  ({ filters }) => filters
)
export const getSorter = createSelector(SEOPagesState, ({ sorter }) => sorter)
export const getIsDataFilteredOrSorted = createSelector(
  [getFilters, getSorter],
  areFiltersAndSorterEmpty
)
export const getSortedIds = createSelector(
  SEOPagesState,
  ({ sortedIds }) => sortedIds
)
export const getSortedSEOPages = createSelector(
  [getSEOPages, getSortedIds],
  (SEOPages, ids) => ids.map((id) => SEOPages[id])
)
export const getPageCount = createSelector(
  SEOPagesState,
  ({ pageCount }) => pageCount
)
export const getPage = createSelector(SEOPagesState, ({ page }) => page)
