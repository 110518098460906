import { mapFilters as mapJavaFilters, mapSorters } from 'helpers/javaAPI'
import { DATE_FORMAT_DASH_REVERSED } from 'const/dates'
import { JAVA_FIELD_NAMES } from 'const/forms'
import moment from 'moment'

export const formatPayloadForFetchRequest = ({
  page,
  filters,
  sorter,
  search,
  select,
  size,
  date,
} = {}) => ({
  page: page || 1,
  sorter: mapSorters(sorter, 'created-at', 'desc'),
  filters: mapJavaFilters(filters),
  search: search || null,
  select: select || null,
  size: size || 20,
  date,
})

export const formatPaymentPackageToCreate = (data) => {
  return {
    paymentDate: moment(data?.[JAVA_FIELD_NAMES.paymentDate]).format(
      DATE_FORMAT_DASH_REVERSED
    ),
    network: data?.[JAVA_FIELD_NAMES.network],
    customer: data?.[JAVA_FIELD_NAMES.customer],
    uidToken: data?.[JAVA_FIELD_NAMES.uidToken],
    ruleId: data?.[JAVA_FIELD_NAMES.rule],
    commissionValue: data?.[JAVA_FIELD_NAMES.commissionValue],
    commissionOrderValue: data?.[JAVA_FIELD_NAMES.commissionOrderValue],
    commissionId: data?.[JAVA_FIELD_NAMES.commissionId],
  }
}

export const formatPaymentPackageToUpdate = (data) => {
  return {
    id: data?.[JAVA_FIELD_NAMES.id],
    paymentDate: moment(data?.[JAVA_FIELD_NAMES.paymentDate]).format(
      DATE_FORMAT_DASH_REVERSED
    ),
    network: data?.[JAVA_FIELD_NAMES.network],
    customer: data?.[JAVA_FIELD_NAMES.customer],
    uidToken: data?.[JAVA_FIELD_NAMES.uidToken],
    ruleId: data?.[JAVA_FIELD_NAMES.rule],
    commissionValue: data?.[JAVA_FIELD_NAMES.commissionValue],
    commissionOrderValue: data?.[JAVA_FIELD_NAMES.commissionOrderValue],
  }
}
