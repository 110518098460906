import { put, select, all, take } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import ROUTES from 'const/routes'
import { actions as generalActions } from 'domains/general'
import {
  actions as adminActions,
  selectors as adminSelectors,
} from 'domains/admin'

import { route } from 'selectors/routing'

export function* initStoreSaga() {
  try {
    yield put(generalActions.startLoading())
    const id = yield select(adminSelectors.adminId)
    if (id) {
      yield put(adminActions.fetchAdminData(id))
      yield take(adminActions.login)

      yield all([
        put(generalActions.fetchCategories()),
        put(generalActions.fetchGroups()),
      ])
    }
  } finally {
    yield put(generalActions.finishLoading())
  }
}
export function* routingSaga() {
  let currentRoute = yield select(route)
  const id = yield select(adminSelectors.adminId)
  if (!id) {
    currentRoute = ROUTES.HOME
  }
  yield put(push(currentRoute))
}
