import React, { useState } from 'react'
import LoadDataInfo from './LoadDataInfo'
import { Wrapper } from './ProgramsTab.styles'
import { StyledTable } from '../../ShopDev/ShopDev.styles'
import { PAGE_SIZE, SCROLL } from 'const/tables'
import { getRowClassByName, getRowProgramId } from 'helpers/tables'
import getColumns from './columns'
import TitledList from 'components/TitledList'
import { FIELD_NAMES } from 'const/forms'
import { SHOP_PROGRAM_ROW_STATUS_KEYS } from 'const/shops'
import { Button, Divider } from 'antd'
import { getIsProgramsLoaded, getShopPrograms } from 'domains/shops/selectors'
import { useSelector } from 'react-redux'
import ExpandableRow from './ExpandableRow'
import { Buttons } from '../Shops.styles'
import { areFiltersAndSorterEmpty } from '../../../helpers/filters'

const paginationConfig = {
  position: ['bottomCenter'],
  showQuickJumper: true,
  pageSize: PAGE_SIZE,
  showSizeChanger: false,
}

const ProgramsTab = () => {
  const [filters, setFilters] = useState({})
  const [sorter, setSorter] = useState({})
  const isLoaded = useSelector(getIsProgramsLoaded)
  const dataSource = useSelector(getShopPrograms)

  const isDataFilteredOrSorted = areFiltersAndSorterEmpty(filters, sorter)

  const handleChange = (pagination, filters, sorter) => {
    setFilters(filters)
    setSorter(sorter)
  }

  const handleResetFilters = () => {
    setFilters({})
    setSorter({})
  }

  return (
    <Wrapper>
      <LoadDataInfo>
        <Buttons>
          <Button
            onClick={handleResetFilters}
            disabled={!isLoaded || !isDataFilteredOrSorted}
            style={{ marginLeft: '12px' }}
          >
            Reset filters
          </Button>
        </Buttons>
      </LoadDataInfo>
      <StyledTable
        onChange={handleChange}
        showSorterTooltip={false}
        columns={getColumns({
          filters,
          sorter,
          networks: dataSource[FIELD_NAMES.checked],
          statuses: Object.values(SHOP_PROGRAM_ROW_STATUS_KEYS),
        })}
        dataSource={dataSource[FIELD_NAMES.rows]}
        sticky={isLoaded}
        scroll={SCROLL}
        bordered
        loading={!isLoaded}
        rowKey={getRowProgramId}
        rowClassName={getRowClassByName}
        pagination={paginationConfig}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (record) => {
            const settings =
              dataSource[FIELD_NAMES.entities][record[FIELD_NAMES.network]]

            return (
              <ExpandableRow
                record={record}
                features={settings[FIELD_NAMES.features]}
                flags={settings[FIELD_NAMES.flags]}
                epcs={settings[FIELD_NAMES.epcs]}
              />
            )
          },
        }}
      />
      {isLoaded && (
        <>
          <Divider />
          <TitledList
            title="Checked"
            items={dataSource[[FIELD_NAMES.checked]]}
          />
          <Divider />
          <TitledList title="Failed" items={dataSource[FIELD_NAMES.failed]} />
          <Divider />
          <TitledList
            title="Not checked"
            items={dataSource[FIELD_NAMES.notChecked]}
          />
          <Divider />
        </>
      )}
    </Wrapper>
  )
}

export default ProgramsTab
