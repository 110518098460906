import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { areFiltersAndSorterEmpty } from 'helpers/filters'

const adPackagesState = ({ adPackages }) => adPackages

export const adPackagesSelector = createSelector(
  adPackagesState,
  ({ adPackages }) => adPackages
)

export const adPackageSelector = createSelector(
  adPackagesSelector,
  (adPackages) =>
    memoize((id) => adPackages.find((adPackage) => adPackage.id === id) || {})
)

export const getPage = createSelector(adPackagesState, ({ page }) => page)

export const getSearchString = createSelector(
  adPackagesState,
  ({ search }) => search
)

export const getFilters = createSelector(
  adPackagesState,
  ({ filters }) => filters
)

export const getSorter = createSelector(adPackagesState, ({ sorter }) => sorter)

export const getIsDataFilteredOrSorted = createSelector(
  [getFilters, getSorter],
  areFiltersAndSorterEmpty
)

export const getPageCount = createSelector(
  adPackagesState,
  ({ pageCount }) => pageCount
)

export const getRegion = createSelector(adPackagesState, ({ region }) => region)
