import { apiInstance } from 'utils/request'
import { BO_API_URL } from 'const/env'
import { RELATIONSHIPS_NAMES } from 'const/api'
import parseError from 'helpers/parseServerError'

export const getAllLogs = (props) => {
  return apiInstance
    .get(`${BO_API_URL}/${RELATIONSHIPS_NAMES.admin}/activity_logs`, {
      params: {
        page: {
          number: props.page,
          size: 20,
        },
        filter: {
          item_type: props.itemType,
          item_id: props.itemId,
        },
      },
    })
    .catch((error) => {
      parseError(error, 'Cannot get logs')
    })
}
