const { FIELD_NAMES } = require('const/forms')

export const formatBonusToUpdate = ({ id, data }) => {
  return {
    data: {
      type: 'bonuses',
      id: id,
      attributes: {
        [FIELD_NAMES.slug]: data[FIELD_NAMES.slug],
        [FIELD_NAMES.value]: data[FIELD_NAMES.value],
        [FIELD_NAMES.walletCopy]: data[FIELD_NAMES.walletCopy],
        [FIELD_NAMES.active]: data[FIELD_NAMES.active],
        [FIELD_NAMES.eligibleTimes]: data[FIELD_NAMES.eligibleTimes],
        [FIELD_NAMES.allowedForGuest]: data[FIELD_NAMES.allowedForGuest],
        [FIELD_NAMES.autoDeactivate]: data[FIELD_NAMES.autoDeactivate],
        [FIELD_NAMES.bonusTTL]: data[FIELD_NAMES.bonusTTL],
        [FIELD_NAMES.campaignTTL]: data[FIELD_NAMES.campaignTTL],
      },
    },
  }
}

export const formatBonusToCreate = ({ data }) => {
  return {
    data: {
      type: 'bonuses',
      attributes: {
        [FIELD_NAMES.slug]: data[FIELD_NAMES.slug],
        [FIELD_NAMES.value]: data[FIELD_NAMES.value],
        [FIELD_NAMES.walletCopy]: data[FIELD_NAMES.walletCopy],
        [FIELD_NAMES.active]: data[FIELD_NAMES.active],
        [FIELD_NAMES.eligibleTimes]: data[FIELD_NAMES.eligibleTimes],
        [FIELD_NAMES.allowedForGuest]: data[FIELD_NAMES.allowedForGuest],
        [FIELD_NAMES.autoDeactivate]: data[FIELD_NAMES.autoDeactivate],
        [FIELD_NAMES.bonusTTL]: data[FIELD_NAMES.bonusTTL],
        [FIELD_NAMES.campaignTTL]: data[FIELD_NAMES.campaignTTL],
      },
    },
  }
}
