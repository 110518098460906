import styled from 'styled-components'
import { Table } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledTable = styled(Table)`
  margin-top: 20px;
`

export const InfoIcon = styled(InfoCircleOutlined)`
  margin-left: 10px;

  &:hover {
    cursor: pointer;
  }
`
