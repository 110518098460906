import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectors as generalSelectors } from 'domains/general'
import {
  StyledSelect,
  Wrapper,
  StyledMainSelect,
} from './CategoriesSelect.styles'
import { FIELD_NAMES } from 'const/forms'

const CategoriesSelect = ({ input: { value, onChange } }) => {
  const categoriesValue = Object.entries(value || {}).reduce(
    (result, [_, item]) => {
      if (item[FIELD_NAMES.mainCategory]) {
        result.main = item
      } else {
        result.regular.push(item)
      }
      return result
    },
    {
      main: null,
      regular: [],
    }
  )
  const options = useSelector(generalSelectors.categoriesOptions)
  const regularOptions = useSelector(
    generalSelectors.filteredCategoriesOptions
  )(categoriesValue.main?.value)

  const categoriesChangeHandler = (ids) => {
    const newValues = ids.reduce(
      (result, item) => {
        result[item.value] = {
          ...item,
          [FIELD_NAMES.mainCategory]: false,
        }
        return result
      },
      categoriesValue.main
        ? {
            [categoriesValue.main.value]: categoriesValue.main,
          }
        : {}
    )
    onChange(newValues)
  }

  const handleChangeMainCategory = (newValue) => {
    const newValues = Object.entries(value).reduce((result, [id, item]) => {
      result[id] = {
        ...item,
        [FIELD_NAMES.mainCategory]: item.value === newValue?.value,
      }
      return result
    }, {})
    const mainCategory =
      newValue && !value[newValue.value]
        ? {
            [newValue.value]: {
              ...newValue,
              [FIELD_NAMES.mainCategory]: true,
            },
          }
        : {}
    onChange({ ...newValues, ...mainCategory })
  }

  return (
    <Wrapper>
      <StyledMainSelect
        labelInValue
        options={options}
        placeholder="Main category"
        value={categoriesValue.main || undefined} // to fix bug with placeholder's displaying
        allowClear
        onChange={handleChangeMainCategory}
      />
      <StyledSelect
        labelInValue
        value={categoriesValue.regular}
        mode="multiple"
        options={regularOptions}
        placeholder="Please select"
        onChange={categoriesChangeHandler}
      />
    </Wrapper>
  )
}

CategoriesSelect.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
}

export default CategoriesSelect
