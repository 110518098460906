import styled from 'styled-components'

export const Title = styled.div`
  padding-bottom: 4px;
  font-weight: 500;
`

export const Text = styled.span`
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
`

export const Result = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Link = styled.a`
  text-decoration: none;
  margin-left: 10px;
  word-break: break-all;
`
