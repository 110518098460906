import React from 'react'
import PropTypes from 'prop-types'
import { StyledInput, Wrapper, CloseSquare } from './Contacts.styles'

const Contacts = ({ input, meta, remove, AddComponent, ...rest }) => {
  const contactChangeHandler = ({ target }) =>
    input.onChange({ ...input.value, contact: target.value })
  const nameChangeHandler = ({ target }) =>
    input.onChange({ ...input.value, name: target.value })
  return (
    <Wrapper>
      <StyledInput
        placeholder="Email, tel"
        invalid={
          ['Required', 'Required field "contact"'].includes(meta.error) &&
          meta.error
        }
        value={input.value?.contact}
        onChange={contactChangeHandler}
        {...rest}
      />
      <StyledInput
        placeholder="Name"
        invalid={
          ['Required', 'Required field "name"'].includes(meta.error) &&
          meta.error
        }
        value={input.value?.name}
        onChange={nameChangeHandler}
        {...rest}
      />
      <CloseSquare onClick={remove} />
      <AddComponent />
    </Wrapper>
  )
}

Contacts.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.shape({
      contact: PropTypes.string,
      name: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
  }).isRequired,
  remove: PropTypes.func,
  AddComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
    .isRequired,
}

export default Contacts
