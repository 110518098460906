import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  overwriteGroups,
  addNewGroup,
  deleteGroup,
  changeGroup,
  startLoading,
  finishLoading,
  saveCategories,
  clearData,
  createCategorySuccess,
  createCategoryFailure,
  deleteCategorySuccess,
  deleteCategoryFailure,
  editCategorySuccess,
  editCategoryFailure,
  fetchMarketingCopySuccess,
  fetchMarketingCopyFailure,
  updateMarketingCopySuccess,
  updateMarketingCopyFailure,
} = reducer.actions

export const fetchGroups = createAction('general/fetchGroups')
export const fetchCategories = createAction('general/fetchCategories')
export const updateGroupPositions = createAction('general/updateGroupPositions')

export const convertLink = createAction('general/convertLink')
export const convertLinkSuccess = createAction('general/convertLinkSuccess')
export const convertLinkFailure = createAction('general/convertLinkFailure')

export const createCategory = createAction('general/createCategory')
export const deleteCategory = createAction('general/deleteCategory')
export const editCategory = createAction('general/editCategory')

export const fetchMarketingCopy = createAction('general/fetchMarketingCopy')
export const updateMarketingCopy = createAction('general/updateMarketingCopy')
