const FIELD_NAMES = {
  installationDate: 'installationDate',
  totalUsers: 'totalUsers',
  totalUsersAfterTwoDays: 'totalUsersAfterTwoDays',
  totalUsersAfterWeek: 'totalUsersAfterWeek',
  totalUninstallations: 'totalUninstallations',
  monkeyHideAfterTwoDays: 'monkeyHideAfterTwoDays',
  monkeyHideAfterWeek: 'monkeyHideAfterWeek',
  monkeyKeepAfterTwoDays: 'monkeyKeepAfterTwoDays',
  monkeyKeepAfterWeek: 'monkeyKeepAfterWeek',
  monkeyErrorAfterTwoDays: 'monkeyErrorAfterTwoDays',
  monkeyErrorAfterWeek: 'monkeyErrorAfterWeek',
}

export const COLUMNS = [
  {
    title: 'Installation Date',
    dataIndex: FIELD_NAMES.installationDate,
    key: FIELD_NAMES.installationDate,
    width: 150,
    sorter: (a, b) =>
      new Date(a[FIELD_NAMES.installationDate]) -
      new Date(b[FIELD_NAMES.installationDate]),
  },
  {
    title: 'Total Users',
    dataIndex: FIELD_NAMES.totalUsers,
    key: FIELD_NAMES.totalUsers,
    width: 150,
    sorter: (a, b) => a[FIELD_NAMES.totalUsers] - b[FIELD_NAMES.totalUsers],
  },
  {
    title: 'Total Users After Two Days',
    dataIndex: FIELD_NAMES.totalUsersAfterTwoDays,
    key: FIELD_NAMES.totalUsersAfterTwoDays,
    width: 180,
    sorter: (a, b) =>
      a[FIELD_NAMES.totalUsersAfterTwoDays] -
      b[FIELD_NAMES.totalUsersAfterTwoDays],
  },
  {
    title: 'Total Users After Week',
    dataIndex: FIELD_NAMES.totalUsersAfterWeek,
    key: FIELD_NAMES.totalUsersAfterWeek,
    width: 180,
    sorter: (a, b) =>
      a[FIELD_NAMES.totalUsersAfterWeek] - b[FIELD_NAMES.totalUsersAfterWeek],
  },
  {
    title: 'Total Uninstallations',
    dataIndex: FIELD_NAMES.totalUninstallations,
    key: FIELD_NAMES.totalUninstallations,
    width: 180,
    sorter: (a, b) =>
      a[FIELD_NAMES.totalUninstallations] - b[FIELD_NAMES.totalUninstallations],
  },
  {
    title: 'Monkey Hide After Two Days',
    dataIndex: FIELD_NAMES.monkeyHideAfterTwoDays,
    key: FIELD_NAMES.monkeyHideAfterTwoDays,
    width: 180,
    sorter: (a, b) =>
      a[FIELD_NAMES.monkeyHideAfterTwoDays] -
      b[FIELD_NAMES.monkeyHideAfterTwoDays],
  },
  {
    title: 'Monkey Hide After Week',
    dataIndex: FIELD_NAMES.monkeyHideAfterWeek,
    key: FIELD_NAMES.monkeyHideAfterWeek,
    width: 180,
    sorter: (a, b) =>
      a[FIELD_NAMES.monkeyHideAfterWeek] - b[FIELD_NAMES.monkeyHideAfterWeek],
  },
  {
    title: 'Monkey Keep After Two Days',
    dataIndex: FIELD_NAMES.monkeyKeepAfterTwoDays,
    key: FIELD_NAMES.monkeyKeepAfterTwoDays,
    width: 180,
    sorter: (a, b) =>
      a[FIELD_NAMES.monkeyKeepAfterTwoDays] -
      b[FIELD_NAMES.monkeyKeepAfterTwoDays],
  },
  {
    title: 'Monkey Keep After Week',
    dataIndex: FIELD_NAMES.monkeyKeepAfterWeek,
    key: FIELD_NAMES.monkeyKeepAfterWeek,
    width: 180,
    sorter: (a, b) =>
      a[FIELD_NAMES.monkeyKeepAfterWeek] - b[FIELD_NAMES.monkeyKeepAfterWeek],
  },
  {
    title: 'Monkey Error After Two Days',
    dataIndex: FIELD_NAMES.monkeyErrorAfterTwoDays,
    key: FIELD_NAMES.monkeyErrorAfterTwoDays,
    width: 180,
    sorter: (a, b) =>
      a[FIELD_NAMES.monkeyErrorAfterTwoDays] -
      b[FIELD_NAMES.monkeyErrorAfterTwoDays],
  },
  {
    title: 'Monkey Error After Week',
    dataIndex: FIELD_NAMES.monkeyErrorAfterWeek,
    key: FIELD_NAMES.monkeyErrorAfterWeek,
    width: 180,
    sorter: (a, b) =>
      a[FIELD_NAMES.monkeyErrorAfterWeek] - b[FIELD_NAMES.monkeyErrorAfterWeek],
  },
]
