import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import useLeavingPage from 'utils/useLeavingPage'

const MenuLink = ({ to, href, label, pathname, push, ...rest }) => {
  const ref = useLeavingPage(pathname, () => push(to || href))

  if (to) {
    return (
      <NavLink ref={ref} to={to} {...rest}>
        {label}
      </NavLink>
    )
  }

  return (
    <a ref={ref} href={href} {...rest} rel="noopener noreferer">
      {label}
    </a>
  )
}

MenuLink.propTypes = {
  pathname: PropTypes.string.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
  label: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
}

export default MenuLink
