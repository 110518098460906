import { apiInstance } from 'utils/request'
import { ENTITY_NAMES, RELATIONSHIPS_NAMES } from 'const/api'
import { FIELD_NAMES } from 'const/forms'
import parseError from 'helpers/parseServerError'

export const getLandingPages = ({ page, filters, sorter, search }) =>
  apiInstance
    .get(`/${RELATIONSHIPS_NAMES.landingPages}`, {
      params: {
        filter: {
          search: search ? encodeURIComponent(search) : null,
          ...filters,
        },
        sort: sorter || FIELD_NAMES.id,
        page: {
          number: page,
          size: 20,
        },
      },
    })
    .catch((error) => {
      parseError(error, 'Cannot get landing pages')
    })

export const createLandingPage = (data) =>
  apiInstance
    .post(`/${ENTITY_NAMES.landingPages}`, {
      data,
    })
    .catch((error) => {
      parseError(error, "Landing page hasn't been created")
    })

export const updateLandingPage = ({ data, updateImage }) =>
  apiInstance
    .patch(`/${ENTITY_NAMES.landingPages}/${data.id}`, {
      data,
    })
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(
        error,
        updateImage
          ? "Landing page image hasn't been updated"
          : "Landing page hasn't been updated"
      )
    })

export const deleteLandingPage = (id) =>
  apiInstance
    .delete(`/${ENTITY_NAMES.landingPages}/${id}`)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Landing page hasn't been deleted")
    })
