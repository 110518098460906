import { all, call, put, takeLatest } from 'redux-saga/effects'
import normalized from 'json-api-normalizer'
import * as actions from './actions'
import * as api from './api'
import { getSortedIds } from 'helpers/jsonAPI'

function* fetchAppVersionsWorker({ payload }) {
  try {
    const { data } = yield call(api.getMobileAppVersions, payload)
    const normalizedData = normalized(data, {
      camelizeKeys: false,
      camelizeTypeValues: false,
    })

    yield put(
      actions.fetchAppVersionsSuccess({
        ...payload,
        page: payload.page,
        pageCount: data.meta.page_count,
        appVersions: normalizedData,
        sortedIds: getSortedIds(data.data),
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchAppVersionsFailure())
  }
}

function* fetchMobileNotificationsWorker({ payload }) {
  try {
    const { data } = yield call(api.getMobileNotifications, payload)

    if (data) {
      yield put(
        actions.fetchMobileNotificationsSuccess({
          ...payload,
          page: payload.page,
          pageCount: data.meta.page_count,
          mobileNotifications: data.data,
          sortedIds: getSortedIds(data.data),
        })
      )
    } else {
      yield put(actions.fetchMobileNotificationsFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.fetchMobileNotificationsFailure())
  }
}

function* deleteAppVersionWorker({ payload }) {
  try {
    const { data } = yield call(api.deleteAppVersion, payload)
    if (data) {
      yield put(actions.deleteAppVersionSuccess(payload))
    } else {
      yield put(actions.deleteAppVersionFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteAppVersionFailure())
  }
}

function* updateAppVersionWorker({ payload }) {
  try {
    const { data } = yield call(api.updateAppVersion, payload)
    if (data) {
      yield put(actions.updateAppVersionSuccess(payload))
    } else {
      yield put(actions.updateAppVersionFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateAppVersionFailure())
  }
}

function* createAppVersionWorker({ payload }) {
  try {
    const { data } = yield call(api.createAppVersion, payload)
    if (data) {
      yield put(actions.createAppVersionSuccess(payload))
    } else {
      yield put(actions.createAppVersionFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createAppVersionFailure())
  }
}

function* createMobileNotificationWorker({ payload }) {
  try {
    const { data } = yield call(api.createMobileNotification, payload)
    if (data) {
      yield put(actions.createMobileNotificationSuccess(payload))
    } else {
      yield put(actions.createMobileNotificationFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createMobileNotificationFailure())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchAppVersions, fetchAppVersionsWorker),
    takeLatest(actions.deleteAppVersion, deleteAppVersionWorker),
    takeLatest(actions.createAppVersion, createAppVersionWorker),
    takeLatest(actions.updateAppVersion, updateAppVersionWorker),
    takeLatest(
      actions.fetchMobileNotifications,
      fetchMobileNotificationsWorker
    ),
    takeLatest(
      actions.createMobileNotification,
      createMobileNotificationWorker
    ),
  ])
}
