import { Button } from 'antd'
import styled from 'styled-components'

export const Text = styled.div`
  margin-bottom: 24px;
  font-size: 14px;
  white-space: pre-wrap;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const FormContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .field-label {
    width: auto;
  }
`

export const StyledButton = styled(Button)`
  width: 200px;
`
