import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { ENTITY_NAMES } from 'const/api'
import { formatLandingPages } from './formatters'
import { areFiltersAndSorterEmpty } from 'helpers/filters'

export const landingPagesState = ({ landingPages }) => landingPages

export const getIsLoaded = createSelector(
  landingPagesState,
  ({ isLoaded }) => isLoaded
)

export const getIsSavingActive = createSelector(
  landingPagesState,
  ({ isSavingActive }) => isSavingActive
)

export const isLoadingOrSavingActive = createSelector(
  [getIsLoaded, getIsSavingActive],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getEntities = createSelector(
  landingPagesState,
  ({ entities }) => entities
)

export const getLandingPages = createSelector(getEntities, (entities) =>
  formatLandingPages(entities[ENTITY_NAMES.landingPages])
)

export const getLandingPageById = createSelector(
  getLandingPages,
  (landingPage) => memoize((id) => (id ? landingPage[id] : null))
)

export const getSearchString = createSelector(
  landingPagesState,
  ({ search }) => search
)
export const getFilters = createSelector(
  landingPagesState,
  ({ filters }) => filters
)
export const getSorter = createSelector(
  landingPagesState,
  ({ sorter }) => sorter
)
export const getIsDataFilteredOrSorted = createSelector(
  [getFilters, getSorter],
  areFiltersAndSorterEmpty
)
export const getSortedIds = createSelector(
  landingPagesState,
  ({ sortedIds }) => sortedIds
)

export const getSortedLandingPages = createSelector(
  [getLandingPages, getSortedIds],
  (landingPages, ids) => ids.map((id) => landingPages[id])
)

export const getPageCount = createSelector(
  landingPagesState,
  ({ pageCount }) => pageCount
)
export const getPage = createSelector(landingPagesState, ({ page }) => page)
