import { apiInstance } from 'utils/request'
import { ENTITY_NAMES, RELATIONSHIPS_NAMES } from 'const/api'
import { FIELD_NAMES } from 'const/forms'
import parseError from 'helpers/parseServerError'

export const getProducts = ({ page, search, filters, sorter }) =>
  apiInstance
    .get(`/${RELATIONSHIPS_NAMES.products}`, {
      params: {
        filter: {
          search: search ? encodeURIComponent(search) : null,
          ...filters,
        },
        sort: sorter || `-${FIELD_NAMES.id}`,
        page: {
          number: page,
          size: 20,
        },
        include: RELATIONSHIPS_NAMES.tag,
      },
    })
    .catch((error) => {
      parseError(error, 'Cannot get products')
    })

export const deleteProduct = (id) =>
  apiInstance
    .delete(`/${ENTITY_NAMES.products}/${id}`)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Product hasn't been deleted")
    })

export const createProduct = (data) =>
  apiInstance
    .post(`/${ENTITY_NAMES.products}`, {
      data,
    })
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Product hasn't been created")
    })

export const updateProduct = ({ id, data }) =>
  apiInstance
    .patch(`/${ENTITY_NAMES.products}/${id}`, {
      data,
    })
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Product hasn't been updated")
    })
