import styled from 'styled-components'
import { Button, Layout } from 'antd'

export const Wrapper = styled(Layout.Content)`
  min-height: calc(100vh - 64px);
  max-width: 500px;
  padding: 30px;
`
export const StyledButton = styled(Button)`
  margin-top: 10px;
  align-self: flex-end;
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`
export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};

  & > div {
    margin-top: 0;
    margin-right: 10px;
    .field-control {
      width: 100%;
    }
  }
`
