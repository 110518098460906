import React from 'react'
import Select from './index'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'

const RetailerField = ({ input, meta, required, ...rest }) => (
  <FieldWrapper
    hint={rest.hint}
    label={rest.label}
    required={required}
    meta={meta}
  >
    <Select input={input} meta={meta} {...rest} />
  </FieldWrapper>
)

export default RetailerField
