import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  isFetching: false,
  page: 1,
  pageCount: 1,
  search: '',
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    finishFetching: (state, { payload: { key } }) => ({
      ...state,
      [key]: {
        ...state[key],
        isFetching: false,
      },
    }),
    resetRecords: () => initialState,
    saveRecords: (state, { payload: { data, page, pageCount, key } }) => ({
      ...state,
      [key]: { ...state[key], data, page, pageCount },
    }),
    saveMoreRecords: (state, { payload: { data, page, pageCount, key } }) => ({
      ...state,
      [key]: {
        ...state[key],
        data: [...state[key]?.data, ...data],
        page,
        pageCount,
      },
    }),
    setSearch: (state, { payload: { key, value } }) => ({
      ...state,
      [key]: {
        ...initialState,
        ...(state[key] || {}),
        search: value,
      },
    }),
    setPage: (state, { payload: { key, value } }) => ({
      ...state,
      [key]: {
        ...initialState,
        ...(state[key] || {}),
        page: value,
      },
    }),
    startFetching: (state, { payload: { key } }) => ({
      ...state,
      [key]: {
        ...state[key],
        isFetching: true,
      },
    }),
  },
})

export default searchSlice
