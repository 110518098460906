import { selectors as paymentsSelectors } from 'domains/payments'
import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { FIELD_NAMES } from 'const/forms'
import { OVER_THE_BUDGET_ERROR, PAYMENT_STATUSES } from 'const/payments'

export const isPaymentApproveAvailable = createSelector(
  [paymentsSelectors.getPayments],
  (payments) =>
    memoize((id) => {
      const payment = payments[id]

      return (
        payment?.[FIELD_NAMES.state] === PAYMENT_STATUSES.REJECTED &&
        payment?.[FIELD_NAMES.rejectionReason] === OVER_THE_BUDGET_ERROR
      )
    })
)

export const isPaymentResendingAvailable = createSelector(
  paymentsSelectors.getPayments,
  (payments) =>
    memoize((id) => {
      const payment = payments[id]

      return (
        payment?.[FIELD_NAMES.state] === PAYMENT_STATUSES.REJECTED &&
        payment?.[FIELD_NAMES.rejectionReason] !== OVER_THE_BUDGET_ERROR
      )
    })
)

export const isPaymentRetractingAvailable = createSelector(
  [paymentsSelectors.getPayments],
  (payments) =>
    memoize((id) => payments[id]?.[FIELD_NAMES.state] === PAYMENT_STATUSES.PAID)
)

export const isPaymentDeletingAvailable = createSelector(
  paymentsSelectors.getPayments,
  (payments) =>
    memoize(
      (id) => payments[id]?.[FIELD_NAMES.state] === PAYMENT_STATUSES.REJECTED
    )
)
