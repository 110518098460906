import React from 'react'
import { Divider, Row } from 'antd'
import ImportBth from './ImportBtn'
import ExportBtn from './ExportBtn'
import Categories from './Categories'
import Groups from './Groups'
import MarketingSectionCopy from './MarketingSectionCopy'
import { Wrapper, Title, StyledCol, StyledRow } from './Settings.styles'

const Settings = () => {
  return (
    <Wrapper>
      <StyledRow>
        <StyledCol>
          <ImportBth />
        </StyledCol>
        <StyledCol>
          <ExportBtn />
        </StyledCol>
      </StyledRow>
      <Row justify="space-between">
        <StyledCol>
          <Title>Categories</Title>
          <Categories />
        </StyledCol>
        <StyledCol>
          <Title>Groups</Title>
          <Groups />
          <Divider />
          <Title>Marketing Section Copy</Title>
          <MarketingSectionCopy />
        </StyledCol>
      </Row>
    </Wrapper>
  )
}

export default Settings
