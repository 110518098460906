import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import { formatDate } from 'helpers/dates'
import Status from 'components/Status'
import { formatFieldValue } from 'helpers/formatters/fields'
import { PAYMENT_STATES_FILTERS } from 'const/payments'
import Countdown from 'react-countdown'
import PaymentActions from './PaymentActions'

export default (filters, sorter, handleEndCountDown) => [
  {
    title: '',
    dataIndex: FIELD_NAMES.state,
    key: FIELD_NAMES.state,
    width: 80,
    fixed: 'left',
    align: 'center',
    render: (state) => <Status value={state} />,
  },
  {
    title: 'State',
    dataIndex: FIELD_NAMES.state,
    key: FIELD_NAMES.state,
    width: 100,
    fixed: 'left',
    align: 'center',
    filters: PAYMENT_STATES_FILTERS,
    filteredValue: filters[FIELD_NAMES.state] || null,
  },
  {
    title: 'Payment ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 100,
    fixed: 'left',
    align: 'center',
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Payment Name',
    dataIndex: FIELD_NAMES.paymentName,
    key: FIELD_NAMES.paymentName,
    width: 150,
    align: 'center',
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.paymentName && sorter.order,
    render: formatFieldValue,
  },
  {
    title: 'Amount ($)',
    dataIndex: FIELD_NAMES.amount,
    key: FIELD_NAMES.amount,
    width: 100,
    align: 'center',
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.amount && sorter.order,
  },
  {
    title: 'User ID',
    dataIndex: FIELD_NAMES.userId,
    key: FIELD_NAMES.userId,
    width: 100,
    align: 'center',
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.userId && sorter.order,
    render: formatFieldValue,
  },
  {
    title: 'User',
    dataIndex: FIELD_NAMES.userEmail,
    key: FIELD_NAMES.userEmail,
    width: 150,
    align: 'center',
  },
  {
    title: 'Approved by',
    dataIndex: FIELD_NAMES.approvedBy,
    key: FIELD_NAMES.approvedBy,
    width: 150,
    align: 'center',
    render: formatFieldValue,
  },
  {
    title: 'Reason of rejection',
    dataIndex: FIELD_NAMES.rejectionReason,
    key: FIELD_NAMES.rejectionReason,
    width: 200,
    align: 'center',
    render: formatFieldValue,
  },
  {
    title: 'Date Assigned',
    dataIndex: FIELD_NAMES.createdAt,
    key: FIELD_NAMES.createdAt,
    width: 150,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.createdAt && sorter.order,
    render: formatDate,
  },
  {
    title: 'Created by',
    dataIndex: FIELD_NAMES.createdBy,
    key: FIELD_NAMES.createdBy,
    width: 150,
    align: 'center',
  },
  {
    title: 'Transaction ID',
    dataIndex: FIELD_NAMES.walletTransactionId,
    key: FIELD_NAMES.walletTransactionId,
    width: 120,
    align: 'center',
    sorter: true,
    sortOrder:
      sorter.columnKey === FIELD_NAMES.walletTransactionId && sorter.order,
    render: formatFieldValue,
  },
  {
    title: 'Countdown date',
    dataIndex: FIELD_NAMES.countdownDate,
    key: FIELD_NAMES.countdownDate,
    width: 150,
    align: 'center',
    sorter: true,
    fixed: 'right',
    sortOrder: sorter.columnKey === FIELD_NAMES.countdownDate && sorter.order,
    render: (date) =>
      date ? <Countdown date={date} onComplete={handleEndCountDown} /> : '-',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    fixed: 'right',
    width: 100,
    render: (_, payment) => <PaymentActions data={payment} />,
  },
]
