import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from './api'
import { getSortedIds } from 'helpers/jsonAPI'

function* fetchDevelopersWorker({ payload }) {
  try {
    const { data } = yield call(api.getDevelopers, payload)

    yield put(
      actions.fetchDevelopersSuccess({
        ...payload,
        developers: data.data,
        sortedIds: getSortedIds(data.data),
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchDevelopersFailure())
  }
}

function* fetchCSVWorker({ payload }) {
  try {
    const { data } = yield call(api.getCSV, payload || {})

    window.open(data.csv_link, '_blank')
  } catch (error) {
    console.error(error)
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchDevelopers, fetchDevelopersWorker),
    takeLatest(actions.fetchCSV, fetchCSVWorker),
  ])
}
