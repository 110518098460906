import cloneDeep from 'lodash/cloneDeep'
import { FIELD_NAMES } from 'const/forms'
import { ENTITY_NAMES } from 'const/api'

export const getUpdatedGroupData = (internalData, data) => {
  let newGroups = cloneDeep(internalData)
  if (data.length > internalData.length) {
    newGroups = data?.reduce((result, group) => {
      if (
        !internalData.find(
          (item) => item[FIELD_NAMES.id] === group[FIELD_NAMES.id]
        )
      ) {
        result.push(group)
      }
      return result
    }, newGroups)
  } else {
    newGroups = internalData?.reduce((result, group) => {
      const groupItem = data.find(
        (item) => item[FIELD_NAMES.id] === group[FIELD_NAMES.id]
      )
      if (!groupItem) {
        const groupIndex = internalData.findIndex(
          (item) => item[FIELD_NAMES.id] === group[FIELD_NAMES.id]
        )
        result.splice(groupIndex, 1)
      }
      return result
    }, newGroups)
  }

  return newGroups
}

export const formatUpdateSectionParams = (data, sectionName) => {
  return { ...data, [ENTITY_NAMES.sectionName]: sectionName }
}
