import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import { Actions, StyledButton } from './MarketingPages.styles'
import { formatUrl } from '../../helpers/formatters/fields'
import { Divider } from 'antd'

const renderPageUrls = (key, numberOfColumns) =>
  Array(numberOfColumns)
    .fill({})
    .map((item, index) => ({
      title: `URL ${index + 1}`,
      dataIndex: [key, index],
      key: key,
      align: 'center',
      width: 100,
      render: (page) =>
        page ? (
          <>
            {`${page[FIELD_NAMES.trafficPercentage]}%`}
            <Divider />
            <a
              rel="noreferrer noopener"
              href={formatUrl(page.url)}
              target="_blank"
            >
              {page[FIELD_NAMES.url]}
            </a>
          </>
        ) : (
          '-'
        ),
    }))

export default (
  filters,
  sorter,
  handleEditLandingPage,
  handleDeleteLandingPage
) => [
  {
    title: 'ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 80,
    fixed: 'left',
    align: 'center',
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Final URL',
    dataIndex: FIELD_NAMES.finalUrl,
    key: FIELD_NAMES.finalUrl,
    width: 120,
    fixed: 'left',
    align: 'center',
    render: (url) => (
      <a rel="noreferrer noopener" href={formatUrl(url)} target="_blank">
        {url}
      </a>
    ),
  },
  ...renderPageUrls(FIELD_NAMES.marketingPagesUrls, 5),
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    fixed: 'right',
    width: 50,
    render: (_, product) => {
      const onEdit = () => handleEditLandingPage(product[FIELD_NAMES.id])
      const onDelete = () => handleDeleteLandingPage(product)
      return (
        <Actions>
          <StyledButton onClick={onEdit}>Edit</StyledButton>
          <StyledButton onClick={onDelete} type="danger">
            Delete
          </StyledButton>
        </Actions>
      )
    },
  },
]
