import React from 'react'
import { Radio as AntRadioGroup } from 'antd'

const RadioGroup = ({ input, options }) => {
  const onChange = (e) => {
    input.onChange(e.target.value)
  }

  return (
    <AntRadioGroup.Group onChange={onChange} value={input.value}>
      {options.map((item) => (
        <AntRadioGroup key={item.value} value={item.value}>
          {item.label}
        </AntRadioGroup>
      ))}
    </AntRadioGroup.Group>
  )
}

export default RadioGroup
