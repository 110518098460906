import React, { useContext, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Table as AntTable, Button } from 'antd'
import { Wrapper } from './Table.styles'
import Modal from 'components/Modal/Modal'
import { OptionButtonStyled } from '../FeatureForm/OptionButton/OptionButton.styles.js'
import { selectors as featuresSelectors } from 'domains/features'
import { getColumns } from './config'
import { SCROLL } from 'pages/Retailers/const'
import { getRowKey } from 'helpers/tables'
import { areFiltersAndSorterEmpty } from 'helpers/filters'

const Table = () => {
  const [filters, setFilters] = useState({})
  const [sorter, setSorter] = useState({})
  const features = useSelector(featuresSelectors.featuresSelector)
  const modal = useContext(Modal.Context)
  const getIsDataFilteredOrSorted = areFiltersAndSorterEmpty(filters, sorter)

  const handleChange = (pagination, filters, sorter) => {
    setFilters(filters)
    setSorter(sorter)
  }

  const handleResetFilters = () => {
    setFilters({})
    setSorter({})
  }

  const openModalCallback = useCallback(() => {
    modal.open('featureFormCreate')
  }, [modal])

  return (
    <Wrapper>
      <OptionButtonStyled>
        <Button onClick={openModalCallback} type="primary">
          Create
        </Button>
        <Button
          onClick={handleResetFilters}
          disabled={!getIsDataFilteredOrSorted}
        >
          Reset filters
        </Button>
      </OptionButtonStyled>
      <AntTable
        onChange={handleChange}
        sticky
        pagination={{ position: ['bottomCenter'], showQuickJumper: true }}
        rowKey={getRowKey}
        scroll={SCROLL}
        dataSource={features}
        columns={getColumns(filters, sorter)}
        size="medium"
        bordered
      />
    </Wrapper>
  )
}

export default Table
