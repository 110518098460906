import React from 'react'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { CheckboxIconWrapper } from './CheckboxIconWrapper.styles.js'

const CheckboxIcon = ({ checked }) => (
  <CheckboxIconWrapper>
    {checked ? (
      <CheckCircleTwoTone twoToneColor="#52c41a" />
    ) : (
      <CloseCircleTwoTone twoToneColor="#FF0000" />
    )}
  </CheckboxIconWrapper>
)
export default CheckboxIcon
