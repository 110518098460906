import styled, { css } from 'styled-components'
import { Input } from 'antd'
import { CloseSquareOutlined } from '@ant-design/icons'

const invalid = (props) => {
  if (props.invalid) {
    return css`
      border-color: rgb(255, 0, 0, 1);
      box-shadow: none;

      &:hover,
      &:focus {
        border-color: rgb(255, 0, 0, 1);
        box-shadow: 0 0 0 2px rgb(255, 0, 0, 0.5);
      }
    `
  }
}

export const CloseSquare = styled(CloseSquareOutlined)`
  margin-left: 10px;
  font-size: 24px;
  cursor: pointer;
  &:hover {
    color: red;
  }
`

export const StyledInput = styled(Input)`
  width: 30%;
  margin-right: 24px;
  ${invalid}
`

export const Wrapper = styled.div`
  padding: 5px 0;
  display: flex;
  align-items: center;
`
