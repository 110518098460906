import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    flex: 1;
  }
`

export const Budget = styled.div`
  height: 105px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  border: 1px dashed #d9d9d9;
  margin-left: 10px;
`

export const Title = styled.h2`
  text-align: center;
`

export const RemainingAmount = styled.span`
  ${({ $low }) => ($low ? 'color: red' : '')};
`

export const TotalAmount = styled.span``
