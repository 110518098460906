import React from 'react'
import { Wrapper, Title, Items } from './TitledList.styles'
import PropTypes from 'prop-types'

const TitledList = ({ title, items, separator, replacement }) => (
  <Wrapper>
    <Title>{title}</Title>
    <Items>{!items?.length ? replacement : items.join(separator)}</Items>
  </Wrapper>
)

TitledList.propTypes = {
  separator: PropTypes.string,
  title: PropTypes.string.isRequired,
  replacement: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
}

TitledList.defaultProps = {
  separator: ', ',
  replacement: '-',
}

export default TitledList
