import { FIELD_NAMES } from 'const/forms'

export const transactionsColumns = (sorter) => [
  {
    title: 'Date',
    dataIndex: FIELD_NAMES.date,
    key: FIELD_NAMES.date,
    sorter: true,
    sortOrder: sorter?.columnKey === FIELD_NAMES.date && sorter.order,
  },
  {
    title: 'Count',
    dataIndex: FIELD_NAMES.count,
    key: FIELD_NAMES.count,
    sorter: true,
    sortOrder: sorter?.columnKey === FIELD_NAMES.count && sorter.order,
  },
  {
    title: 'Sales',
    dataIndex: FIELD_NAMES.sales,
    key: FIELD_NAMES.sales,
    render: (sales) =>
      sales.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    sorter: true,
    sortOrder: sorter?.columnKey === FIELD_NAMES.sales && sorter.order,
  },
  {
    title: 'Commissions',
    dataIndex: FIELD_NAMES.commissions,
    key: FIELD_NAMES.commissions,
    render: (commissions) =>
      commissions.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    sorter: true,
    sortOrder: sorter?.columnKey === FIELD_NAMES.commissions && sorter.order,
  },
]
