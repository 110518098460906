import { FORM_NAMES } from 'const/forms'
import {
  getEditorialTableData,
  getEditorialTableInitialValues,
  getRetailersData,
  getRetailersInitialValues,
} from 'domains/retailers/selectors'
import { getColumns as editorialColumns } from './configs/editorial'
import { getColumns as storesColumns } from './configs/stores'

export const MAP_FORM_NAME_TO_DATA_SOURCE = {
  [FORM_NAMES.EDITORIAL_CHANGES]: getEditorialTableData,
  [FORM_NAMES.STORE_CHANGES]: getRetailersData,
}

export const MAP_FORM_NAME_TO_INITIAL_VALUES = {
  [FORM_NAMES.EDITORIAL_CHANGES]: getEditorialTableInitialValues,
  [FORM_NAMES.STORE_CHANGES]: getRetailersInitialValues,
}

export const MAP_FORM_NAME_TO_COLUMNS = {
  [FORM_NAMES.EDITORIAL_CHANGES]: editorialColumns,
  [FORM_NAMES.STORE_CHANGES]: storesColumns,
}

export const SCROLL = { x: 1200 }
