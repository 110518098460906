import styled from 'styled-components'
import { Button, Layout, Table } from 'antd'

export const Wrapper = styled(Layout.Content)`
  min-height: calc(100vh - 64px);
`

export const StyledTable = styled(Table)`
  margin-bottom: 30px;

  .ant-table-title {
    padding: 0;
  }
`

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledButton = styled(Button)`
  &:not(:first-child) {
    margin-top: 10px;
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  & > button:first-child {
    margin-right: 10px;
  }
`
export const Breadcrumbs = styled.div`
  padding: 12px;
`
