import React from 'react'
import PropTypes from 'prop-types'
import CategoriesSelect from 'components/CategoriesSelect'
import { Field } from 'react-final-form'
import { FIELD_NAMES } from 'const/forms'

const Categories = ({ name }) => {
  return (
    <Field
      name={`${name}[${FIELD_NAMES.categories}]`}
      component={CategoriesSelect}
    />
  )
}

Categories.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Categories
