import { apiInstance } from 'utils/request'
import { message } from 'antd'
import { DURATION } from 'const/duration'

export const sendEmailNotification = (data) =>
  apiInstance
    .post(`/email_notifications/send_notification`, {
      data,
    })
    .then(({ data }) => message.success(data.notice, DURATION.show))
    .catch((data) => {
      message.error(`Email hasn't been sent. ${data}`)
    })
