import Input from 'components/Input/Input'
import InputNumber from 'components/Input/InputNumber'
import Select from 'components/Select/Select'
import Checkbox from 'components/Checkbox/Checkbox'
import TextArea from 'components/TextArea/TextArea'

export const chooseComponent = (type) => {
  switch (type) {
    case 'string':
      return Input
    case 'integer':
      return InputNumber
    case 'boolean':
      return Checkbox
    case 'enumerator':
      return Select
    case 'array':
      return TextArea
    default:
      return Input
  }
}
