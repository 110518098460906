import React from 'react'
import { FIELD_NAMES } from 'const/forms'
import { Field, Form } from 'react-final-form'
import { Input as AntdInput } from 'antd'
import {
  FormContent,
  ModalContent,
  StyledButton,
  Text,
} from './CreditCardNegativeFeedback.styles'
import Input from 'components/Input/Input'
import InputNumber from 'components/Input/InputNumber'
import { areValidDomains } from 'helpers/validators'

const CreditCardNegativeFeedback = ({
  callback,
  creditCardProgram,
  closeModal,
}) => {
  const merchantDomains = creditCardProgram?.[FIELD_NAMES.merchant_domains]
  const rewardValue = creditCardProgram?.[FIELD_NAMES.reward_value]

  const onSubmit = (values) => {
    try {
      const parsedValues = {
        ...values,
        [FIELD_NAMES.merchant_domains]: values[FIELD_NAMES.merchant_domains]
          ? values[FIELD_NAMES.merchant_domains]
              .split(',')
              .map((domain) => domain.trim())
          : {},
      }
      closeModal()
      callback(parsedValues)
    } catch (error) {
      console.error(error)
    }
  }

  const initialValues = {
    ...creditCardProgram,
    [FIELD_NAMES.comment]: '',
    [FIELD_NAMES.merchant_domains]: merchantDomains?.length
      ? merchantDomains.join(', ')
      : '',
    [FIELD_NAMES.reward_value]: rewardValue,
  }

  return (
    <ModalContent>
      <Text>Please tell what is wrong with the credit card program.</Text>

      <Form
        destroyOnUnregister
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, pristine }) => (
          <FormContent>
            <Field
              required
              name={FIELD_NAMES.comment}
              render={({ input }) => (
                <AntdInput.TextArea
                  placeholder="Comment"
                  autoSize={{ minRows: 2, maxRows: 3 }}
                  {...input}
                />
              )}
            />
            <Field
              label="Exclusive Merchant Domains"
              validate={areValidDomains}
              name={FIELD_NAMES.merchant_domains}
              component={Input}
            />
            <Field
              label="Reward Value"
              name={FIELD_NAMES.reward_value}
              component={InputNumber}
            />
            <StyledButton
              type="primary"
              disabled={pristine}
              onClick={handleSubmit}
            >
              Send
            </StyledButton>
          </FormContent>
        )}
      />
    </ModalContent>
  )
}

export default CreditCardNegativeFeedback
