import {
  MobileTwoTone,
  ShoppingTwoTone,
  GlobalOutlined,
  WarningTwoTone,
} from '@ant-design/icons'
import styled from 'styled-components'

export const Mobile = styled(MobileTwoTone)`
  font-size: 28px;
`

export const Wallet = styled(ShoppingTwoTone)`
  font-size: 28px;
`

export const Global = styled(GlobalOutlined)`
  font-size: 28px;
  color: #1a90ff;
`

export const Invalid = styled(WarningTwoTone)`
  font-size: 28px;
`
