import React from 'react'
import arrayMutators from 'final-form-arrays'
import { useSelector } from 'react-redux'
import { diff } from 'deep-object-diff'
import isEqual from 'lodash/isEqual'
import { getIsLoaded, getIsSavingActive } from 'domains/retailers/selectors'
import { getRetailerCommentsInitialValues } from 'domains/retailers/selectors'
import { saveRetailersCommentsChanges } from 'domains/retailers/actions'
import { commentValidator } from 'helpers/validators'
import { FIELD_NAMES } from 'const/forms'
import { Field, Form } from 'react-final-form'
import { Spin } from 'antd'
import Contacts from './Contacts'
import {
  Buttons,
  StyledButton,
  Text,
  Divider,
  Title,
  FlexWrapper,
} from './Comments.styles'
import Comment from 'components/Comment'
import useAction from 'utils/useAction'

const Comments = ({ retailer }) => {
  const onSubmit = useAction(saveRetailersCommentsChanges)
  const isLoaded = useSelector(getIsLoaded)
  const isSavingActive = useSelector(getIsSavingActive)
  const initialValues = useSelector(getRetailerCommentsInitialValues)(
    retailer[FIELD_NAMES.id]
  )
  const submitHandler = (values) =>
    onSubmit({
      mainId: retailer[FIELD_NAMES.mainId],
      changes: diff(initialValues, values),
      retailerId: retailer[FIELD_NAMES.id],
    })

  return (
    <Spin spinning={!isLoaded || isSavingActive} size="large">
      <FlexWrapper>
        <Title>Retailer name:</Title>
        <Text>{retailer[FIELD_NAMES.name]}</Text>
      </FlexWrapper>
      <FlexWrapper>
        <Title>Retailer ID:</Title>
        <Text>{retailer[FIELD_NAMES.id]}</Text>
      </FlexWrapper>
      <Divider />
      <Form
        keepDirtyOnReinitialize
        initialValues={initialValues}
        onSubmit={submitHandler}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, values, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            <Title>Contacts:</Title>
            <Contacts />
            <Divider />
            <Title>Comment:</Title>
            <Field
              validate={commentValidator}
              name={FIELD_NAMES.comment}
              component={Comment}
            />
            <Buttons>
              <StyledButton
                type="primary"
                htmlType="submit"
                disabled={
                  isEqual(initialValues, {
                    comment: values.comment,
                    contacts: values.contacts.filter(Boolean),
                  }) || hasValidationErrors
                }
              >
                Save
              </StyledButton>
            </Buttons>
          </form>
        )}
      />
    </Spin>
  )
}

export default Comments
