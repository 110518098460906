import { api as paymentTransactionsApi } from 'domains/paymentTransactions'

export const TRANSACTION_STATUSES = [
  { text: 'Active', value: 'active' },
  { text: 'Cancelled', value: 'cancelled' },
]

export const COMMISSION_TYPES_KEYS = {
  unknown: 'unknown',
  lead: 'lead',
  sale: 'sale',
  cpc: 'cpc',
  performance: 'performance',
  adPackage: 'adPackage',
  dataPackage: 'dataPackage',
  manual: 'manual',
}

export const MAP_COMMISSION_TYPES_TO_USER_TEXT = {
  [COMMISSION_TYPES_KEYS.unknown]: 'Unknown',
  [COMMISSION_TYPES_KEYS.lead]: 'Lead',
  [COMMISSION_TYPES_KEYS.sale]: 'Sale',
  [COMMISSION_TYPES_KEYS.cpc]: 'CPC',
  [COMMISSION_TYPES_KEYS.performance]: 'Performance',
  [COMMISSION_TYPES_KEYS.adPackage]: 'Ad package',
  [COMMISSION_TYPES_KEYS.dataPackage]: 'Data package',
  [COMMISSION_TYPES_KEYS.manual]: 'Manual',
}

export const COMMISSION_TYPES_VALUES = {
  [COMMISSION_TYPES_KEYS.unknown]: 0,
  [COMMISSION_TYPES_KEYS.lead]: 1,
  [COMMISSION_TYPES_KEYS.sale]: 2,
  [COMMISSION_TYPES_KEYS.cpc]: 3,
  [COMMISSION_TYPES_KEYS.performance]: 4,
  [COMMISSION_TYPES_KEYS.adPackage]: 5,
  [COMMISSION_TYPES_KEYS.dataPackage]: 6,
  [COMMISSION_TYPES_KEYS.manual]: 7,
}

export const SUPPORTED_COMMISSION_TYPES_VALUES = [
  COMMISSION_TYPES_VALUES[COMMISSION_TYPES_KEYS.adPackage],
  COMMISSION_TYPES_VALUES[COMMISSION_TYPES_KEYS.dataPackage],
  COMMISSION_TYPES_VALUES[COMMISSION_TYPES_KEYS.manual],
]

export const COMMISSION_TYPES = [
  {
    text: MAP_COMMISSION_TYPES_TO_USER_TEXT[COMMISSION_TYPES_KEYS.unknown],
    value: COMMISSION_TYPES_VALUES.unknown,
  },
  {
    text: MAP_COMMISSION_TYPES_TO_USER_TEXT[COMMISSION_TYPES_KEYS.lead],
    value: COMMISSION_TYPES_VALUES.lead,
  },
  {
    text: MAP_COMMISSION_TYPES_TO_USER_TEXT[COMMISSION_TYPES_KEYS.sale],
    value: COMMISSION_TYPES_VALUES.sale,
  },
  {
    text: MAP_COMMISSION_TYPES_TO_USER_TEXT[COMMISSION_TYPES_KEYS.cpc],
    value: COMMISSION_TYPES_VALUES.cpc,
  },
  {
    text: MAP_COMMISSION_TYPES_TO_USER_TEXT[COMMISSION_TYPES_KEYS.performance],
    value: COMMISSION_TYPES_VALUES.performance,
  },
  {
    text: MAP_COMMISSION_TYPES_TO_USER_TEXT[COMMISSION_TYPES_KEYS.adPackage],
    value: COMMISSION_TYPES_VALUES.adPackage,
  },
  {
    text: MAP_COMMISSION_TYPES_TO_USER_TEXT[COMMISSION_TYPES_KEYS.dataPackage],
    value: COMMISSION_TYPES_VALUES.dataPackage,
  },
  {
    text: MAP_COMMISSION_TYPES_TO_USER_TEXT[COMMISSION_TYPES_KEYS.manual],
    value: COMMISSION_TYPES_VALUES.manual,
  },
]

export const SUPPORTED_COMMISSION_TYPES = [
  {
    label: MAP_COMMISSION_TYPES_TO_USER_TEXT[COMMISSION_TYPES_KEYS.adPackage],
    value: COMMISSION_TYPES_VALUES.adPackage,
  },
  {
    label: MAP_COMMISSION_TYPES_TO_USER_TEXT[COMMISSION_TYPES_KEYS.dataPackage],
    value: COMMISSION_TYPES_VALUES.dataPackage,
  },
  {
    label: MAP_COMMISSION_TYPES_TO_USER_TEXT[COMMISSION_TYPES_KEYS.manual],
    value: COMMISSION_TYPES_VALUES.manual,
  },
]

export const CREATE_PAYMENT_PACKAGES_API = {
  [COMMISSION_TYPES_VALUES.adPackage]:
    paymentTransactionsApi.createPaymentAdPackage,
  [COMMISSION_TYPES_VALUES.dataPackage]:
    paymentTransactionsApi.createPaymentDataPackage,
  [COMMISSION_TYPES_VALUES.manual]:
    paymentTransactionsApi.createPaymentManual,
}

export const UPDATE_PAYMENT_PACKAGES_API = {
  [COMMISSION_TYPES_VALUES.adPackage]:
    paymentTransactionsApi.updatePaymentAdPackage,
  [COMMISSION_TYPES_VALUES.dataPackage]:
    paymentTransactionsApi.updatePaymentDataPackage,
  [COMMISSION_TYPES_VALUES.manual]:
    paymentTransactionsApi.updatePaymentManual,
}
