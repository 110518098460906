import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  saveFiltersAndSorter,
  startSaving,
  finishSaving,
  clearData,
  fetchTransactionsSuccess,
  fetchTransactionsFailure,
} = reducer.actions

export const fetchTransactions = createAction(
  'domain/transactions/fetchTransactions'
)
