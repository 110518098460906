import styled from 'styled-components'
import { Layout, Row, Button, Col } from 'antd'

export const Wrapper = styled(Layout.Content)`
  margin: 5px 10px;
  display: flex;
  align-items: center;
`

export const StyledButton = styled(Button)`
  min-width: 140px;
  height: 40px;
  margin-left: 10px;
`

export const StyledCol = styled(Col)`
  flex: 1;
`

export const StyledRow = styled(Row)`
  flex-flow: nowrap;
  justify-content: space-between;
  margin: 5px 0;
`

export const Title = styled.h2`
  margin: 0;
  font-size: 16px;
`
