import React, { useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { Wrapper, StyledButton } from './SyncImages.styles'
import { retailerPropType } from 'const/propTypes'
import { FIELD_NAMES } from 'const/forms'
import { Tooltip } from 'antd'
import { refreshImages } from 'actions/views/retailers'
import SyncModal from './SyncModal'
import CopyModal from './CopyModal'
import useAction from 'utils/useAction'

const SyncImages = ({
  retailer,
  disabled,
  disabledReason,
  disableRefreshButton,
}) => {
  const refreshImagesFunc = useAction(refreshImages)
  const [showSyncModal, setShowSyncModal] = useState(false)
  const [showCopyModal, setShowCopyModal] = useState(false)
  const disableSyncButton =
    disabled || isEmpty(retailer[FIELD_NAMES.unavailableRegions])
  const disableCopyButton = disabled

  const handleOpenSyncModal = () => setShowSyncModal(true)
  const handleCloseSyncModal = () => setShowSyncModal(false)
  const handleOpenCopyModal = () => setShowCopyModal(true)
  const handleCloseCopyModal = () => setShowCopyModal(false)
  const handleRefreshImages = () => refreshImagesFunc({ id: retailer.id })

  return (
    <Wrapper>
      <Tooltip title={disableCopyButton && disabledReason}>
        <StyledButton
          disabled={disableRefreshButton}
          onClick={handleRefreshImages}
        >
          Refresh images
        </StyledButton>
      </Tooltip>
      {retailer.isGlobal && (
        <Tooltip title={disableSyncButton && disabledReason}>
          <StyledButton
            disabled={disableSyncButton}
            onClick={handleOpenSyncModal}
          >
            Sync with regionals
          </StyledButton>
        </Tooltip>
      )}
      <Tooltip title={disableCopyButton && disabledReason}>
        <StyledButton
          disabled={disableCopyButton}
          onClick={handleOpenCopyModal}
        >
          Copy sets
        </StyledButton>
      </Tooltip>
      <SyncModal
        retailer={retailer}
        show={showSyncModal}
        closeModal={handleCloseSyncModal}
      />
      <CopyModal
        retailer={retailer}
        show={showCopyModal}
        closeModal={handleCloseCopyModal}
      />
    </Wrapper>
  )
}

SyncImages.propTypes = {
  retailer: retailerPropType.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: PropTypes.bool.isRequired,
  disabledReason: PropTypes.string.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  disableRefreshButton: PropTypes.bool.isRequired,
}

SyncImages.defaultProps = {
  disabledReason: null,
}

export default SyncImages
