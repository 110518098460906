import React, { useState } from 'react'
import { Tabs } from 'antd'
import { TabsWrapper } from './Shops.styles'
import { SHOP_SUB_ENTITIES } from 'domains/shops/const'
import { TABS } from 'const/shops'
import useAction from 'utils/useAction'
import { openConfirmModal } from 'reducers/modals/actions'
import * as shopActions from 'actions/views/shops'

const ShopsTabs = () => {
  const [activeKey, setActiveKey] = useState(SHOP_SUB_ENTITIES.localizedShops)
  const openConfirmModalFunc = useAction(openConfirmModal)
  const fetchShopProgramsFunc = useAction(shopActions.fetchShopPrograms)

  const handleLoadPrograms = () => {
    const callback = () => {
      setActiveKey(SHOP_SUB_ENTITIES.programs)
      fetchShopProgramsFunc()
    }
    const text = `Are you sure you want to load shop programs? This action may require a lot of resources.`
    openConfirmModalFunc({ callback, text })
  }

  const handleTabChange = (key) => {
    if (key === SHOP_SUB_ENTITIES.programs) {
      handleLoadPrograms()
    } else {
      setActiveKey(key)
    }
  }

  return (
    <TabsWrapper>
      <Tabs
        defaultActiveKey={SHOP_SUB_ENTITIES.localizedShops}
        activeKey={activeKey}
        items={TABS}
        onChange={handleTabChange}
      />
    </TabsWrapper>
  )
}

export default ShopsTabs
