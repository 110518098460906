import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './Category.styles'

const Category = ({ onClick, label }) => {
  const handleSelect = () => onClick(label)
  return <Wrapper onClick={handleSelect}>{label}</Wrapper>
}

Category.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}

export default Category
