import { all, put, take, takeLatest, race, select } from 'redux-saga/effects'

import { startLoading, finishLoading } from 'reducers/layoutSlice'
import {
  actions as mobileActions,
  formatters as mobileFormatters,
  selectors as mobileSelectors,
} from 'domains/mobileApp'
import * as actions from 'actions/views/mobileNotifications'

function* fetchMobileNotificationsWorker({ payload } = {}) {
  try {
    const search = yield select(mobileSelectors.getSearchString)
    const body = mobileFormatters.formatPayloadForFetchRequest({
      search,
      ...payload,
    })
    yield all([
      put(startLoading()),
      put(mobileActions.fetchMobileNotifications(body)),
    ])
    const [success] = yield race([
      take(mobileActions.fetchMobileNotificationsSuccess),
      take(mobileActions.fetchMobileNotificationsFailure),
    ])
    if (success) {
      yield put(mobileActions.saveFiltersAndSorter(payload))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* createMobileNotificationWorker({ payload }) {
  try {
    const body = mobileFormatters.formatNotificationForRequests(payload.values)
    yield all([
      put(mobileActions.startSaving()),
      put(mobileActions.createMobileNotification(body)),
    ])
    const [success] = yield race([
      take(mobileActions.createMobileNotificationSuccess),
      take(mobileActions.createMobileNotificationFailure),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(mobileSelectors.getSearchString),
        select(mobileSelectors.getPage),
        select(mobileSelectors.getFilters),
        select(mobileSelectors.getSorter),
      ])
      yield put(
        actions.fetchMobileNotifications({
          search,
          page,
          filters,
          sorter,
        })
      )
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(mobileActions.finishSaving())
  }
}

export default function* () {
  yield all([
    fetchMobileNotificationsWorker(),
    takeLatest(
      actions.fetchMobileNotifications,
      fetchMobileNotificationsWorker
    ),
    takeLatest(
      actions.createMobileNotification,
      createMobileNotificationWorker
    ),
  ])
}
