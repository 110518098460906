import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Switch as AntSwitch } from 'antd'

const Switch = ({ input, disabled, fieldName, changeCallback, ...rest }) => {
  useEffect(() => {
    if (input.value && disabled) {
      input.onChange(false)
    }
  }, [input, disabled, fieldName])

  const handleChange = (value) => {
    input.onChange(value)
    changeCallback(value)
  }

  return (
    <AntSwitch
      checked={!!input.value}
      onChange={handleChange}
      disabled={disabled}
      {...rest}
    />
  )
}

Switch.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  changeCallback: PropTypes.func,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.string.isRequired,
    ]),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
}

Switch.defaultProps = {
  disabled: false,
  fieldName: null,
  changeCallback: noop,
}

// ! Fixes tooltip visibility if Switch disabled
Switch.__ANT_BUTTON = true

export default Switch
