import React from 'react'
import COUNTRIES from 'const/country'
import { ENTITY_NAMES } from './api'
import { DEFAULT_MEDIA_TYPE } from './adPackage'
import { FIELD_NAMES } from './forms'
import RegionItem from 'components/RegionItem'

export const CB_MAX_VALUE = 99
export const KG_MAX_VALUE = 99
export const CPA_PERCENT_FOR_CB = 0.5
export const CPA_PERCENT_FOR_PLUS_CB = 1

export const RETAILER_GROUP_SLUGS = {
  cashback: 'cashback',
  trending: 'trending',
  featured: 'featured',
  newRetailers: 'new-retailers',
  weAreLoving: 'we-re-loving',
  web: 'web',
  mobile: 'mobile',
}

export const RETAILER_TYPES_IN_ATTRS = {
  [ENTITY_NAMES.regional]: 'Retailer::Regional',
  [ENTITY_NAMES.domain]: 'Retailer::Domain',
  [ENTITY_NAMES.mobile]: 'Retailer::Mobile',
  [ENTITY_NAMES.main]: 'Retailer::Main',
}

export const AVAILABLE_RETAILER_TYPES_FOR_AD_PACKAGES = [
  RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.main],
]

export const AVAILABLE_RETAILER_TYPES_FOR_IMAGES = [
  RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.main],
  RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.regional],
]

export const REGION_VALUES = {
  global: RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.main],
  regional: 'any',
  mobile: RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.mobile],
  domain: RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.domain],
}

export const RETAILER_TYPES = [
  RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.main],
  RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.regional],
  RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.mobile],
  RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.domain],
]

export const RETAILER_TYPES_WITH_CATEGORIES = [
  RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.main],
  RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.regional],
]

export const REMOVABLE_RETAILER_TYPES = [
  ENTITY_NAMES.regional,
  ENTITY_NAMES.mobile,
  ENTITY_NAMES.domain,
]

export const CAN_BE_CONVERTED_TO_REGIONAL_RETAILER_TYPES = [
  ENTITY_NAMES.main,
  ENTITY_NAMES.mobile,
  ENTITY_NAMES.domain,
]

export const CAN_BE_CONVERTED_TO_DOMAIN_RETAILER_TYPES = [
  ENTITY_NAMES.mobile,
  ENTITY_NAMES.main,
  ENTITY_NAMES.regional,
]

export const CAN_BE_CONVERTED_TO_MOBILE_RETAILER_TYPES = [
  ENTITY_NAMES.main,
  ENTITY_NAMES.domain,
  ENTITY_NAMES.regional,
]

export const CAN_BE_CONVERTED_TO_GLOBAL_RETAILER_TYPES = [
  // ENTITY_NAMES.regional, //TODO: keep logic fo the future requests
]

export const CAN_BE_MOVED_TO_ANOTHER_GLOBAL_TYPES = [ENTITY_NAMES.regional]

export const RETAILER_TYPE_TO_ENTITY_NAME = {
  [RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.main]]: ENTITY_NAMES.main,
  [RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.regional]]: ENTITY_NAMES.regional,
  [RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.domain]]: ENTITY_NAMES.domain,
  [RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.mobile]]: ENTITY_NAMES.mobile,
}

export const RETAILER_TYPE_TO_LABEL = {
  [ENTITY_NAMES.main]: 'global',
  [ENTITY_NAMES.regional]: 'regional',
  [ENTITY_NAMES.domain]: 'domain',
  [ENTITY_NAMES.mobile]: 'mobile',
}

export const TYPE_TO_PARAM = {
  ...RETAILER_TYPE_TO_LABEL,
  [ENTITY_NAMES.main]: 'main',
}

export const FIELD_DESCRIPTION = {
  [FIELD_NAMES.cbActive]: 'Cashback status',
  [FIELD_NAMES.mediaType]: 'Media type changed to',
  [FIELD_NAMES.mainCategory]: 'Main category changed to',
  [FIELD_NAMES.cb]: 'Cashback percent changed to',
  [FIELD_NAMES.cpa]: 'Agreed commission percent changed to',
  [FIELD_NAMES.maxKarmaGives]: 'Max Karma Gives value changed to',
  [FIELD_NAMES.tagsCount]: 'Tags count changed to',
  [FIELD_NAMES.promoCb]: 'Promoted cashback percent changed to',
  [FIELD_NAMES.plusCb]: 'Plus cashback percent changed to',
  [FIELD_NAMES.promoPlusCb]: 'Promoted plus cashback percent changed to',
  start: {
    [FIELD_NAMES.promoCb]: 'Promoted cashback start date changed to',
    [FIELD_NAMES.promoPlusCb]: 'Promoted plus cashback start date changed to',
    default: 'Start date changed to',
  },
  finish: {
    [FIELD_NAMES.promoCb]: 'Promoted cashback end date changed to',
    [FIELD_NAMES.promoPlusCb]: 'Promoted plus cashback end date changed to',
    default: 'End date changed to',
  },
  [FIELD_NAMES.showOnUi]: 'Show on UI status changed to',
  [FIELD_NAMES.active]: 'Status changed to',
  [FIELD_NAMES.position]: 'Priority changed to',
  added: 'Added regular categories:',
  removed: 'Removed regular categories:',
  [FIELD_NAMES.actionOnClick]: 'Action on click changed to',
}

export const NAME_FOR_CASHBACK_CREATION = 'changed by backoffice'

export const STAKE_MINIMUM_LIFE_DURATION_IN_HOURS = 1

export const IMAGE_SIZE_LIMIT_IN_MB = 10
export const IMAGE_TYPES = ['image/jpeg', 'image/png']

export const GIVEAWAYS_IMAGE_CROP_SIZE = {
  width: 410,
  height: 300,
}

export const SEO_SMALL_IMAGE_CROP_SIZE = {
  width: 670,
  height: 320,
}

export const SEO_LARGE_IMAGE_CROP_SIZE = {
  width: 1170,
  height: 370,
}

export const LANDING_PAGE_CROP_SIZE = {
  width: 570,
  height: 628,
}

export const WEB_CROP_SIZE = {
  width: 1100,
  height: 915,
}
export const MOBILE_CROP_SIZE = {
  width: 600,
  height: 561,
}
export const MOBILE_SEE_ALL_CROP_SIZE = {
  width: 915,
  height: 561,
}
export const BANNER_CROP_SIZE = {
  width: 1000,
  height: 220,
}

export const PACKAGE_STATUS_OPTIONS = [
  { value: 'active', label: 'Active' },
  { value: 'closed', label: 'Closed' },
  { value: 'not_opened', label: 'Not Opened' },
]

export const GENDER_OPTIONS = [
  { value: 'all_gender', label: 'All Gender' },
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
]

export const IMAGE_GENDER_OPTIONS = [
  { value: 'any', label: 'Any' },
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
]

export const MEDIA_TYPE_OPTIONS = [
  { value: DEFAULT_MEDIA_TYPE, label: 'None' },
  { value: 'advertisement', label: 'Advertisement' },
  { value: 'sponsored', label: 'Sponsored' },
]

export const ACTION_ON_CLICK_FILTERS = [
  { text: 'Show', value: 'show' },
  { text: 'Redirect', value: 'redirect' },
]

export const RETAILER_TYPE_FILTERS = [
  { text: 'Global', value: REGION_VALUES.global },
  {
    text: 'Regional',
    children: [
      {
        text: 'Any region',
        value: REGION_VALUES.regional,
      },
      ...COUNTRIES.map((country) => ({
        text: <RegionItem country={country} />,
        value: country.code,
      })),
    ],
  },
]

export const ADDITIONAL_RETAILER_TYPE_FILTER = [
  { text: 'Mobile', value: REGION_VALUES.mobile },
  { text: 'Domain', value: REGION_VALUES.domain },
]

export const STATUS_FILTERS = [
  { text: 'Active', value: 'true' },
  { text: 'Inactive', value: 'false' },
]

export const SHOW_ON_UI_FILTERS = [
  { text: 'Active', value: 'true' },
  { text: 'Inactive', value: 'false' },
]

export const MEDIA_TYPE_FILTERS = [
  { text: 'Advertisement', value: 'advertisement' },
  { text: 'Sponsored', value: 'sponsored' },
  { text: 'None', value: 'null' },
]

export const AD_PACKAGE_STATUS_FILTERS = [
  { text: 'Active', value: 'active' },
  { text: 'Closed', value: 'closed' },
  { text: 'Not opened', value: 'not_opened' },
]

export const AD_PACKAGE_TYPE_FILTERS = [
  { text: 'Text', value: 'true' },
  { text: 'Sales', value: 'false' },
]

export const RETAILER_TYPE_OPTIONS = [
  {
    value: ENTITY_NAMES.main,
    label: RETAILER_TYPE_TO_LABEL[ENTITY_NAMES.main],
  },
  {
    value: ENTITY_NAMES.regional,
    label: RETAILER_TYPE_TO_LABEL[ENTITY_NAMES.regional],
  },
  {
    value: ENTITY_NAMES.mobile,
    label: RETAILER_TYPE_TO_LABEL[ENTITY_NAMES.mobile],
  },
  {
    value: ENTITY_NAMES.domain,
    label: RETAILER_TYPE_TO_LABEL[ENTITY_NAMES.domain],
  },
]
