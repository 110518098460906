import { createAction } from '@reduxjs/toolkit'

export const fetchMarketingPages = createAction(
  'marketingPage/fetchMarketingPages'
)
export const updateMarketingPage = createAction(
  'marketingPage/updateMarketingPage'
)
export const createMarketingPage = createAction(
  'marketingPage/createMarketingPage'
)
export const deleteMarketingPage = createAction(
  'marketingPage/deleteMarketingPage'
)
