import LocalizedShopsTab from '../pages/Shops/LocalizedShopsTab/LocalizedShopsTab'
import CampaignDealsTab from '../pages/Shops/CampaignDealsTab/CampaignDealsTab'
import AdPackagesTab from '../pages/Shops/AdPackagesTab/AdPackagesTab'
import ProgramsTab from '../pages/Shops/ProgramsTab'
import ShopOffersTab from '../pages/Shops/ShopOffersTab/ShopOffersTab'
import React from 'react'
import { SHOP_SUB_ENTITIES } from 'domains/shops/const'

export const SHOP_PROGRAM_ROW_STATUS_KEYS = {
  notSignedUp: 'not_signed_up',
  pending: 'pending',
  accepted: 'accepted',
  rejected: 'rejected',
  onHold: 'on_hold',
  suspended: 'suspended',
  signed_out: 'signed_out',
  notJoinedUnknown: 'not_joined_unknown',
}

export const AD_PACKAGE_TYPE_KEYS = {
  regular: 'regular_ad',
  coupon: 'coupon',
  extensionBanner: 'extension_banner',
}

export const RULE_TYPE_FILTERS = [
  { text: 'Regular', value: 'regular' },
  { text: 'Mobile', value: 'mobile' },
  { text: 'Checkout', value: 'checkout' },
  { text: 'Derivative', value: 'derivative' },
]

export const PACKAGE_TYPE_FILTERS = [
  { text: 'Regular', value: AD_PACKAGE_TYPE_KEYS.regular },
  { text: 'Coupon', value: AD_PACKAGE_TYPE_KEYS.coupon },
  { text: 'Extension banner', value: AD_PACKAGE_TYPE_KEYS.extensionBanner },
]

export const MAP_AD_PACKAGE_TYPE_TO_USER_TEXT = {
  [AD_PACKAGE_TYPE_KEYS.regular]: 'Regular',
  [AD_PACKAGE_TYPE_KEYS.coupon]: 'Coupon',
  [AD_PACKAGE_TYPE_KEYS.extensionBanner]: 'Extension banner',
}

export const PACKAGE_TYPE_OPTIONS = [
  {
    value: AD_PACKAGE_TYPE_KEYS.regular,
    label: MAP_AD_PACKAGE_TYPE_TO_USER_TEXT[AD_PACKAGE_TYPE_KEYS.regular],
  },
  {
    value: AD_PACKAGE_TYPE_KEYS.coupon,
    label: MAP_AD_PACKAGE_TYPE_TO_USER_TEXT[AD_PACKAGE_TYPE_KEYS.coupon],
  },
  {
    value: AD_PACKAGE_TYPE_KEYS.extensionBanner,
    label:
      MAP_AD_PACKAGE_TYPE_TO_USER_TEXT[AD_PACKAGE_TYPE_KEYS.extensionBanner],
  },
]

export const formatCountryCodesForFiltering = (countryCodes) => {
  return countryCodes?.map((code) => ({ text: code, value: code }))
}

export const TABS = [
  {
    key: SHOP_SUB_ENTITIES.localizedShops,
    label: 'Localized shops',
    children: <LocalizedShopsTab />,
  },
  {
    key: SHOP_SUB_ENTITIES.campaignDeals,
    label: 'Campaign deals',
    children: <CampaignDealsTab />,
  },
  {
    key: SHOP_SUB_ENTITIES.adPackages,
    label: 'Ad packages',
    children: <AdPackagesTab />,
  },
  {
    key: SHOP_SUB_ENTITIES.shopOffers,
    label: 'Shop offers',
    children: <ShopOffersTab />,
  },
  {
    key: SHOP_SUB_ENTITIES.programs,
    label: 'Programs',
    children: <ProgramsTab />,
  },
]

export const PROGRAM_STATUSES = {
  checked: 'checked',
  failed: 'failed',
  notChecked: 'notChecked',
}

export const STATUS_FILTERS = [
  { text: 'Checked', value: PROGRAM_STATUSES.checked },
  { text: 'Failed', value: PROGRAM_STATUSES.failed },
  { text: 'Not checked', value: PROGRAM_STATUSES.notChecked },
]

export const ACTIVE_FILTERS = [
  { text: 'Active', value: true },
  { text: 'Not Active', value: false },
]

export const MAP_STATUS_TO_USER_TEXT = {
  [SHOP_PROGRAM_ROW_STATUS_KEYS.notSignedUp]: 'Not signed up',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.pending]: 'Pending',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.accepted]: 'Accepted',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.rejected]: 'Rejected',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.onHold]: 'On hold',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.suspended]: 'Suspended',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.signed_out]: 'Signed out',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.notJoinedUnknown]: 'Not joined unknown',
}

export const MAP_STATUS_TO_EXPLANATION = {
  [SHOP_PROGRAM_ROW_STATUS_KEYS.notSignedUp]: 'Karma can join the program',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.pending]: 'Karma has asked to join',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.accepted]: 'The program is active for Karma',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.rejected]: 'They refused to accept Karma',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.onHold]: 'Temporary suspension',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.suspended]: 'They removed Karma',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.signed_out]: 'Karma removed the program',
  [SHOP_PROGRAM_ROW_STATUS_KEYS.notJoinedUnknown]:
    'Karma is not joined, reason unknown',
}

export const GENDER_OPTIONS = [
  {
    value: null,
    label: '',
  },
  {
    value: 'M',
    label: 'Male',
  },
  {
    value: 'F',
    label: 'Female',
  },
  {
    value: 'U',
    label: 'Unisex',
  },
]

export const ENRICHED_CATEGORIES = {
  Marketplace: [],
  'Pet Supplies': [],
  'Arts & Crafts Supplies': [],
  'Dating Services': [],
  Adult: [],
  "Baby & Children's Products": [
    "Baby & Children's Clothing",
    'Baby & Toddler Feeding',
    'Child Car Seats',
    'Childcare & Education',
    'Nappies & Baby Hygiene Products',
    'Pushchairs & Prams',
    'Toys',
  ],
  'Beauty & Personal Care': [
    'Bath & Body Products',
    'Hair Care Products',
    'Make-up & Cosmetics',
    'Perfumes & Fragrances',
    'Skin Care Products',
    'Spas & Beauty Services',
    'Tanning & Sun Care Products',
  ],
  'Business Services': [
    'Advertising & Marketing Services',
    'Business Financial Services',
    'Enterprise Software',
    'Network Systems & Services',
    'Web Services',
    'Corporate Event Planning',
    'Office Supplies',
    'Payment Processing & Merchant Services',
    'Payroll Services',
    'Physical Security & Access Control',
    'Staffing & Recruitment Services',
  ],
  'Cars & Vehicles': [
    'Car Loans',
    'Car Parts & Accessories',
    'Automotive Electronic Components',
    'High Performance & Aftermarket Car Parts',
    'Wheels & Tyres',
    'Car Repair & Maintenance',
    'Motorcycles & Scooters',
    'Bicycles & Accessories',
    'Boats & Watercraft',
    'Campers & Motorhomes',
    'Commercial Vehicles',
  ],
  'Clothing & Accessories': [
    'Activewear',
    'Running Clothes',
    'Yoga Clothing',
    'Costumes',
    'Eyewear',
    'Formal Wear',
    'Bridal Wear',
    'Suits & Business Attire',
    'Handbags',
    'Jewellery & Watches',
    'Fine Jewellery',
    'Necklaces',
    'Watches',
    'Wedding & Engagement Rings',
    'Lingerie',
    "Men's Clothing",
    "Women's Clothing",
    'Outerwear',
    'Shoes & Boots',
    'Swimwear',
    'Briefcases & Leather Goods',
  ],
  'Computers & Peripherals': [
    'Computer Accessories & Components',
    'Desktop Computers',
    'Laptops & Notebooks',
    'Tablets & Ultraportable Devices',
    'Printers & Scanners',
  ],
  'Consumer Electronics': [
    'Headphones & Headsets',
    'Pro Musician & DJ Equipment',
    'Speakers',
    'Stereo Systems',
    'Cameras',
    'Game Consoles',
    'Gaming Peripherals & Accessories',
    'Home Cinema Systems',
    'Mobile Phone Accessories',
    'Mobile Phones',
    'Android Phones',
    'iOS Phones',
    'Power Adapters & Chargers',
    'Televisions',
  ],
  Education: [
    'Educational Resources',
    'Exam Preparation & Tutoring',
    'Foreign Language Study',
    'Open Online Courses',
    'Arts & Design Education',
    'Business Education',
    'Health Care Education',
    'Nursing Education',
    'Technology Education',
    'Primary & Secondary Schools',
    'Study Abroad Programmes',
  ],
  'Event Tickets': [
    'Concert & Music Festival Tickets',
    'Performing Arts Tickets',
    'Sports Tickets',
  ],
  'Financial Services': [
    'Banking Services',
    'Debit & Cheque Services',
    'Savings Accounts',
    'Credit & Lending',
    'Business Loans',
    'Credit Cards',
    'Credit Reports & Monitoring Services',
    'Mortgage',
    'Personal Loans',
    'Student Loans',
    'Financial Planning',
    'Estate Planning',
    'Retirement Planning',
  ],
  Insurance: [
    'Car Insurance',
    'Health Insurance',
    'Home Insurance',
    'Life Insurance',
    'Travel Insurance',
  ],
  Food: [
    'Baked Goods',
    'Sweets & Chocolate',
    'Oriental Cooking',
    'Vegan & Vegetarian Cooking',
    'Fast Food Meals',
    'Grocery Delivery',
    'Restaurant Delivery & Takeaway',
  ],
  'Gifts & Occasions': [
    'Flowers',
    'Gift Baskets',
    'Holiday Items & Decorations',
    'Party Supplies & Planning',
    'Event Planning Services',
    'Party Supplies',
    'Personalised Gifts',
  ],
  'Photo & Video Services': [
    'Event Photographers & Studios',
    'Photo Printing Services',
    'Wedding Planning',
  ],
  'Home & Garden': [
    'Cookware & Bakeware',
    'Home & Garden Services',
    'Tools & Home Improvement',
    'Landscape Design',
    'Lawn & Garden Maintenance',
    'Locksmith Services',
    'Painting Services',
    'Pest Control Services',
    'Plumbing Services',
    'Roofing Services',
    'Home Appliances',
    'Climate Control & Air Quality',
    'Home Furnishings',
    'Bedroom',
    'Home Office',
    'Home Security',
    'Household Cleaning Supplies',
    'Pest Control Supplies',
    'Interior Design',
    'Curtains & Drapes',
    'Lights & Fixtures',
    'Linens',
    'Rugs & Carpets',
    'Outdoor Items',
    'BBQs & Grills',
    'Garden & Outdoor Furniture',
    'Lawn Care & Gardening Supplies',
    'Pools & Spas',
  ],
  'Media and Entertainment': [
    'Audio Streaming Subscription Services',
    'Board Games',
    'Books',
    'TV and Video Streaming Subscription Services',
    'Video Game Streaming Services',
    'Video Games',
    'Musical Instruments & Accessories',
  ],
  Property: [
    'Commercial Properties',
    'Moving & Relocation',
    'Residential Properties',
  ],
  Software: [
    'Accounting Software',
    'Antivirus & Security Software',
    'Audio & Music Software',
    'Business & Productivity Software',
    'Design Software',
    'Drawing & Animation Software',
    'Photo Software',
    'Video Chat Software',
    'Video Editing & Production Software',
  ],
  'Sports & Fitness': [
    'Fitness Products & Services',
    'Exercise Equipment',
    'Online Fitness Classes',
    'Gyms & Athletic Clubs',
    'Outdoor Recreational Equipment',
  ],
  Telecom: [
    'Cable & Satellite TV Providers',
    'Internet Service Providers',
    'Mobile Phone Service Providers',
  ],
  Travel: [
    'Air Travel',
    'Bus & Rail Travel',
    'Car Hire',
    'Cruises',
    'Hotels & Accommodation',
    'Trips by Destination (various countries and cities)',
  ],
}

export const EXCLUSIVE_ENRICHED_CATEGORIES = ['Marketplace']
