/* eslint-disable */
import React from 'react'
import { Table } from 'antd'
import { SCROLL } from '../index'
import { useSelector } from 'react-redux'
import { selectors as userSelectors } from 'domains/user'
import { getEngagementMetricsColumns } from '../columns'

const EngagementMetrics = () => {
  const isLoading = useSelector(userSelectors.engagementMetricsLoading)
  const engagementMetrics = useSelector(userSelectors.engagementMetrics)

  return (
    <>
      <Table
        sticky
        scroll={SCROLL}
        dataSource={engagementMetrics}
        columns={getEngagementMetricsColumns()}
        loading={isLoading}
      />
    </>
  )
}

export default EngagementMetrics
