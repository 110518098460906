export const COUNTRIES_BY_REGION = {
  'Asia-Pacific': [
    'Indonesia',
    'Singapore',
    'Bangladesh',
    'Nepal',
    'Malaysia',
    'Pakistan',
    'Zealand',
    'China',
    'Korea',
    'Kiribati',
    'Solomon',
    'Tonga',
    'Islands',
    'Taiwan',
    'Qatar',
    'Hong Kong',
    'Guam',
    'Christmas Island',
    'Macao',
    'Tajikistan',
    'Nauru',
    'Niue',
    'Afghanistan',
    'Marshall Islands',
    'Vanuatu',
    'Papua New Guinea',
    'Laos',
    'Fiji',
    'Brunei',
    'Maldives',
    'Cambodia',
    'Philippines',
    'Sri Lanka',
    'India',
    'Japan',
    'Thailand',
    'Vietnam',
    'Timor-Leste',
    'Australia',
    'Mongolia',
    'South Korea',
    'Myanmar',
    'Tuvalu',
    'Bhutan',
    'Palau',
    'Micronesia',
    'Samoa',
    'New Caledonia',
    'Kyrgyzstan',
    'Tokelau',
    'Cocos',
  ],
  EMEA: [
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Austria',
    'Bahrain',
    'Belarus',
    'Belgium',
    'Benin',
    'Bosnia/Herzegovina',
    'Botswana',
    'Bouvet Island',
    'British Indian Ocean Territory',
    'Bulgaria',
    'Burkina',
    'Burundi',
    'Cameroon',
    'Cape Verde',
    'Central African Republic',
    'Chad',
    'Comoros',
    'Congo',
    'Cote D’Ivoire',
    'Croatia',
    'Cyprus',
    'Czech Republic',
    'Democratic Republic of Congo',
    'Denmark',
    'Djibouti',
    'Egypt',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Faroe Islands',
    'Finland',
    'France',
    'France (European Territory)',
    'Gabon',
    'Gambia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Guinea',
    'Guinea Bissau',
    'Hungary',
    'Iceland',
    'Iran',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    'Jordan',
    'Kenya',
    'Kuwait',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Mali',
    'Malta',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Moldavia',
    'Monaco',
    'Morocco',
    'Mozambique',
    'Namibia',
    'Netherlands',
    'Niger',
    'Nigeria',
    'Norway',
    'Oman',
    'Other Europe',
    'Poland',
    'Portugal',
    'Qatar',
    'Reunion',
    'Romania',
    'Russia - Russian Federation',
    'Rwanda',
    'San Marino',
    'Saudi Arabia',
    'Senegal',
    'Seychelles',
    'Sierra Leone',
    'Slovakia',
    'Slovenia',
    'Somalia',
    'South Africa',
    'Spain',
    'St Helena',
    'Sudan',
    'Svalbard',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Tanzania',
    'Togo',
    'Tunisia',
    'Turkey',
    'Uganda',
    'United Arab Emirates',
    'United Kingdom',
    'Vatican City',
    'Western Sahara',
    'Yemen',
    'Yugoslavia',
    'Zambia',
    'Zimbabwe',
  ],
  LATAM: [
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Austria',
    'Bahrain',
    'Belarus',
    'Belgium',
    'Benin',
    'Bosnia/Herzegovina',
    'Botswana',
    'Bouvet Island',
    'British Indian Ocean Territory',
    'Bulgaria',
    'Burkina',
    'Burundi',
    'Cameroon',
    'Cape Verde',
    'Central African Republic',
    'Chad',
    'Comoros',
    'Congo',
    'Cote D’Ivoire',
    'Croatia',
    'Cyprus',
    'Czech Republic',
    'Democratic Republic of Congo',
    'Denmark',
    'Djibouti',
    'Egypt',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Faroe Islands',
    'Finland',
    'France',
    'France (European Territory)',
    'Gabon',
    'Gambia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Guinea',
    'Guinea Bissau',
    'Hungary',
    'Iceland',
    'Iran',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    'Jordan',
    'Kenya',
    'Kuwait',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Mali',
    'Malta',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Moldavia',
    'Monaco',
    'Morocco',
    'Mozambique',
    'Namibia',
    'Netherlands',
    'Niger',
    'Nigeria',
    'Norway',
    'Oman',
    'Other Europe',
    'Poland',
    'Portugal',
    'Qatar',
    'Reunion',
    'Romania',
    'Russia - Russian Federation',
    'Rwanda',
    'San Marino',
    'Saudi Arabia',
    'Senegal',
    'Seychelles',
    'Sierra Leone',
    'Slovakia',
    'Slovenia',
    'Somalia',
    'South Africa',
    'Spain',
    'St Helena',
    'Sudan',
    'Svalbard',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Tanzania',
    'Togo',
    'Tunisia',
    'Turkey',
    'Uganda',
    'United Arab Emirates',
    'United Kingdom',
    'Vatican City',
    'Western Sahara',
    'Yemen',
    'Yugoslavia',
    'Zambia',
    'Zimbabwe',
  ],
  'Middle East': [
    'Israel',
    'Lebanon',
    'Oman',
    'Iraq',
    'Jordan',
    'Palestine',
    'Qatar',
    'Iran',
    'Egypt',
    'Kuwait',
    'Syria',
    'Cyprus',
    'Saudi Arabia',
    'United Arab Emirates',
    'Bahrain',
    'Yemen',
    'Türkiye',
  ],
  Europe: [
    'Albania',
    'Andorra',
    'Austria',
    'Belarus',
    'Belgium',
    'Bosnia and Herzegovina',
    'Bulgaria',
    'Croatia',
    'Cyprus',
    'Czech',
    'Denmark',
    'European Union',
    'Estonia',
    'Finland',
    'France',
    'Germany',
    'Greece',
    'Hungary',
    'Iceland',
    'Ireland',
    'Italy',
    'Latvia',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Malta',
    'Moldova',
    'Monaco',
    'Montenegro',
    'Netherlands',
    'North Macedonia',
    'Norway',
    'Poland',
    'Portugal',
    'Romania',
    'Russia',
    'San Marino',
    'Serbia',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Sweden',
    'Switzerland',
    'Ukraine',
    'United Kingdom',
  ],
}

export const REGIONS_OPTIONS = [
  { value: 'Asia-Pacific', label: 'Asia-Pacific' },
  { value: 'EMEA', label: 'EMEA' },
  { value: 'LATAM', label: 'LATAM' },
  { value: 'Middle East', label: 'Middle East' },
  { value: 'Europe', label: 'Europe' },
]
