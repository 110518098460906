import moment from 'moment'
import { STAKE_MINIMUM_LIFE_DURATION_IN_HOURS } from 'const/retailers'
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_DELAY } from 'const/dates'
import { FIELD_NAMES } from '../const/forms'
import { ADMIN } from '../const/roles'
import React from 'react'

const range = (start, end) => {
  const result = []

  for (let i = start; i < end; i += 1) {
    result.push(i)
  }

  return result
}

export const getDateHoursLater = (hours, date) =>
  (date || moment()).clone().add(hours, 'hours')

export const disabledDate = (current, delay = TIME_DELAY) =>
  getDateHoursLater(delay, current).isBefore()

export const getDefaultDates = (delay = TIME_DELAY) => {
  const defaultStart = getDateHoursLater(delay).startOf('hours')
  return [
    defaultStart,
    getDateHoursLater(STAKE_MINIMUM_LIFE_DURATION_IN_HOURS, defaultStart),
  ]
}

export const disabledTime = (selectedDate, _, delay = TIME_DELAY) => {
  if (selectedDate) {
    const firstAvailableHour = getDateHoursLater(delay).format('HH')
    const firstAvailableMinute = getDateHoursLater(delay).format('mm')
    // Disables hours in TimePicker if selected date is today
    if (selectedDate.isSame(moment(), 'day')) {
      return {
        disabledHours: () => range(0, firstAvailableHour),
        disabledMinutes: () => range(0, firstAvailableMinute),
      }
    }

    // Enables hours, minutes, seconds in other cases
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    }
  }

  // Disables hours, minutes, seconds in TimePicker if no date is selected
  return {
    disabledHours: () => range(0, 24),
    disabledMinutes: () => range(0, 60),
  }
}

export const regularFormatDate = (date) =>
  date ? moment(date).format(DATE_TIME_FORMAT) : '-'

export const formatDate = (date, { [FIELD_NAMES.role]: role } = {}) => {
  const value = date ? moment(date).format(DATE_TIME_FORMAT) : '-'
  return role === ADMIN ? <strong>{value}</strong> : value
}

export const formatTestRetry = (value) => (
  <strong style={{ color: value >= 2 ? 'red' : 'black' }}>{value}</strong>
)

export const formatTestDate = (value) => {
  const date = formatDate(value)
  const warning = moment().subtract(12, 'hours').isAfter(value)
  return <strong style={{ color: warning ? 'red' : 'black' }}>{date}</strong>
}

export const formatDay = (date) =>
  date ? moment(date).format(DATE_FORMAT) : '-'

export const sortDates = (key) => (a, b) =>
  moment(a[key]).valueOf() - moment(b[key]).valueOf()
