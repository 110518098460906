import { createSelector } from '@reduxjs/toolkit'
import { isEmpty, omitBy } from 'lodash'

const areFiltersAndSorterEmpty = (filters, sorter, search, select) => {
  const areFiltersEmpty = isEmpty(omitBy(filters, isEmpty))
  const isSorterActive = !!sorter?.column
  return !areFiltersEmpty || isSorterActive || !!search || !!select
}

export const paymentTransactionsState = ({ paymentTransactions }) => {
  return paymentTransactions
}
export const getIsLoaded = createSelector(
  paymentTransactionsState,
  ({ isLoaded }) => isLoaded
)

export const getEntities = createSelector(
  paymentTransactionsState,
  ({ entities }) => entities
)

export const getPaymentTransactions = createSelector(
  getEntities,
  ({ paymentTransactions }) => paymentTransactions
)

export const getPage = createSelector(
  paymentTransactionsState,
  ({ page }) => page
)

export const getPageCount = createSelector(
  paymentTransactionsState,
  ({ pageCount }) => pageCount
)

export const getFilters = createSelector(
  paymentTransactionsState,
  ({ filters }) => filters
)

export const getSorter = createSelector(
  paymentTransactionsState,
  ({ sorter }) => sorter
)

export const getSearch = createSelector(
  paymentTransactionsState,
  ({ search }) => search
)

export const getPageSize = createSelector(
  paymentTransactionsState,
  ({ size }) => size
)

export const getNetworks = createSelector(
  paymentTransactionsState,
  ({ networks }) => networks
)

export const getCustomers = createSelector(
  paymentTransactionsState,
  ({ customers }) => customers
)

export const getTransactionDetails = createSelector(
  getEntities,
  ({ transactionDetails }) => transactionDetails
)

export const getIsSavingActive = createSelector(
  paymentTransactionsState,
  ({ isSavingActive }) => isSavingActive
)

export const getSelect = createSelector(
  paymentTransactionsState,
  ({ select }) => select
)

export const getIsDataFilteredOrSorted = createSelector(
  [getFilters, getSorter, getSearch, getSelect],
  areFiltersAndSorterEmpty
)
