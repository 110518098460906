import sortBy from 'lodash/sortBy'
import {
  FIELD_NAMES,
  FORM_CHANGES_FIELD_PREFIX,
  FORM_SAVE_FIELD_PREFIX,
  GROUP_FIELD_NAME_PREFIX,
} from 'const/forms'
import {
  formatBoolField,
  formatInputField,
  formatSelectField,
  formatInputWithDatesField,
  formatSelectCategoriesField,
  formatGroupCellField,
  formatActionOnClickField,
} from './fields'

export const formatFieldName = (fieldNameFromForm) =>
  `${FORM_SAVE_FIELD_PREFIX}_${fieldNameFromForm.substring(
    FORM_CHANGES_FIELD_PREFIX.length + 1
  )}`

export const destringifyKeys = (values) =>
  Object.keys(values).reduce((result, key) => {
    result[key.substring(key.indexOf('_') + 1)] = values[key]
    return result
  }, {})

export const DESCRIPTION_FORMATTERS = {
  [FIELD_NAMES.mediaType]: formatSelectField,
  [FIELD_NAMES.cbActive]: formatBoolField,
  [FIELD_NAMES.cpa]: formatInputField,
  [FIELD_NAMES.maxKarmaGives]: formatInputField,
  [FIELD_NAMES.tagsCount]: formatInputField,
  [FIELD_NAMES.cb]: formatInputField,
  [FIELD_NAMES.promoCb]: formatInputWithDatesField,
  [FIELD_NAMES.plusCb]: formatInputField,
  [FIELD_NAMES.promoPlusCb]: formatInputWithDatesField,
  [FIELD_NAMES.categories]: formatSelectCategoriesField,
  [FIELD_NAMES.showOnUi]: formatBoolField,
  [FIELD_NAMES.actionOnClick]: formatActionOnClickField,
}

const formatDescriptions = (fields, item, changes, groups, categories) =>
  fields
    .map((fieldName) => {
      let descriptionFormatter

      if (fieldName.includes(GROUP_FIELD_NAME_PREFIX)) {
        descriptionFormatter = formatGroupCellField
      } else {
        descriptionFormatter = DESCRIPTION_FORMATTERS[fieldName]
      }

      return descriptionFormatter({
        name: fieldName,
        fieldName: `${item}[${fieldName}]`,
        newValue: changes[fieldName],
        groups,
        categories,
      })
    })
    .filter(Boolean)

export const formatChanges = (changes, fields, groups, categories, retailers) =>
  sortBy(Object.keys(changes), (name) =>
    name.substring(FORM_CHANGES_FIELD_PREFIX.length + 1)
  ).reduce((result, item) => {
    const newItemChanges = formatDescriptions(
      fields,
      item,
      changes[item],
      groups,
      categories
    )
    if (newItemChanges.length) {
      const id = item.substring(FORM_CHANGES_FIELD_PREFIX.length + 1)
      const retailer = retailers.find((item) => item.id === id)
      return [
        ...result,
        {
          id,
          mainId: retailer[FIELD_NAMES.mainId],
          name: retailer[FIELD_NAMES.name],
          changes: newItemChanges,
        },
      ]
    }
    return result
  }, [])
