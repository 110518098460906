import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import { Actions, StyledButton } from './Admins.styles'
import {
  formatAdminField,
  formatAdminRolesField,
} from 'helpers/formatters/fields'
import { ROLES_FILTERS } from 'const/roles'

export default (filters, sorter, handleEditUser, handleDeleteUser) => [
  {
    title: 'User ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 60,
    fixed: 'left',
    sorter: true,
    align: 'center',
    sortOrder: sorter?.columnKey === FIELD_NAMES.id && sorter?.order,
    render: formatAdminField,
  },
  {
    title: 'Name',
    dataIndex: FIELD_NAMES.name,
    key: FIELD_NAMES.name,
    width: 100,
    fixed: 'left',
    align: 'center',
    sorter: true,
    sortOrder: sorter?.columnKey === FIELD_NAMES.name && sorter?.order,
    render: formatAdminField,
  },
  {
    title: 'Role',
    dataIndex: FIELD_NAMES.authorizedRoles,
    key: FIELD_NAMES.authorizedRoles,
    width: 80,
    align: 'center',
    filters: ROLES_FILTERS,
    filteredValue: filters?.[FIELD_NAMES.authorizedRoles] || null,
    render: formatAdminRolesField,
  },
  {
    title: 'Email',
    dataIndex: FIELD_NAMES.email,
    key: FIELD_NAMES.email,
    width: 180,
    align: 'center',
    sorter: true,
    sortOrder: sorter?.columnKey === FIELD_NAMES.email && sorter?.order,
    render: formatAdminField,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    fixed: 'right',
    width: 90,
    render: (_, user) => {
      const onEdit = () => handleEditUser(user[FIELD_NAMES.id])
      // const onDelete = () => handleDeleteUser(user)
      return (
        <Actions>
          <StyledButton onClick={onEdit}>Edit</StyledButton>
          {/* <StyledButton onClick={onDelete} type="danger">
            Delete
          </StyledButton> */}
        </Actions>
      )
    },
  },
]
