import styled from 'styled-components'

export const OptionButtonStyled = styled.div`
  justify-content: flex-end;
  display: flex;
  margin: 10px;
  & > button:first-child {
    margin-right: 10px;
  }
`
