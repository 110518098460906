import parseError from 'helpers/parseServerError'

const API_URL = 'https://us-east1-karmaai-425607.cloudfunctions.net/'

export const fetchCreditCardsByDomain = async (website, sample = 7) => {
  try {
    const response = await fetch(
      `${API_URL}getConditionsByWebsite?website=${encodeURIComponent(
        website
      )}&sample=${sample}`
    )

    if (!response.ok) {
      throw new Error(`Failed to fetch credit cards by domain`)
    }

    return await response.json()
  } catch (error) {
    parseError(error, 'Failed to fetch credit cards by domain')
  }
}

export const fetchAllCreditCards = async () => {
  try {
    const response = await fetch(`${API_URL}getAllCashbackConditions`)

    if (!response.ok) {
      throw new Error(`Failed to fetch credit cards by domain`)
    }

    return await response.json()
  } catch (error) {
    parseError(error, 'Failed to fetch credit cards by domain')
  }
}

export const validateCreditCardProgram = async (body) => {
  try {
    const response = await fetch(`${API_URL}validateCashback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      throw new Error(`Failed to validate credit card program`)
    }

    return true
  } catch (error) {
    parseError(error, 'Failed to validate credit card program')
    return false
  }
}
