import { apiInstance } from 'utils/request'
import { ENTITY_NAMES } from 'const/api'
import { FIELD_NAMES } from 'const/forms'
import parseError from 'helpers/parseServerError'
import { formatUpdateSectionParams } from './helpers'

export const getAllGroups = () =>
  apiInstance
    .get(`${ENTITY_NAMES.groups}?sort=${FIELD_NAMES.position}`)
    .catch((error) => {
      parseError(error, 'Cannot get groups data')
    })

export const createCategory = (data) =>
  apiInstance.post(`${ENTITY_NAMES.categories}`, { data }).catch((error) => {
    parseError(error, "Category hasn't been created")
  })

export const editCategory = ({ id, data }) =>
  apiInstance
    .patch(`${ENTITY_NAMES.categories}/${id}`, { data })
    .catch((error) => {
      parseError(error, "Category hasn't been updated")
    })

export const deleteCategory = (id) =>
  apiInstance
    .delete(`${ENTITY_NAMES.categories}/${id}`)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Category hasn't been created")
    })

export const updateGroupPositions = (data) =>
  apiInstance
    .patch(
      `/${ENTITY_NAMES.groups}/switch_positions`,
      { data },
      { headers: { 'Content-Type': 'application/vnd.api+json' } }
    )
    .catch((error) => {
      parseError(error, "Group order hasn't been updated")
    })

export const convertLink = (data) =>
  apiInstance
    .post(
      `/${ENTITY_NAMES.admin}/convert_link_to_affiliate`,
      { data },
      { headers: { 'Content-Type': 'application/vnd.api+json' } }
    )
    .catch((error) => {
      parseError(error, "Link hasn't been converted")
    })

export const getAllCategories = () =>
  apiInstance
    .get(`${ENTITY_NAMES.categories}?page[size]=1000&sort=${FIELD_NAMES.name}`)
    .catch((error) => {
      parseError(error, 'Cannot get categories')
    })

export const getMarketingCopy = () =>
  apiInstance
    .get(`${ENTITY_NAMES.groups}/${ENTITY_NAMES.marketingCopy}`)
    .catch((error) => {
      parseError(error, 'Cannot get marketing copy data')
    })

export const updateMarketingCopy = (data) =>
  apiInstance
    .post(
      `${ENTITY_NAMES.groups}/${ENTITY_NAMES.updateSections}`,
      formatUpdateSectionParams(data, ENTITY_NAMES.marketingCopy)
    )
    .catch((error) => {
      parseError(error, 'Cannot update marketing copy data')
    })
