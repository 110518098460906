import React from 'react'
import { Button } from 'antd'
import { Field, Form } from 'react-final-form'
import InputNumber from 'components/Input/InputNumber'
import { FIELD_NAMES } from 'const/forms'
import { required } from 'helpers/validators'
import { Title } from 'components/Title/Title.styles'
import Select from 'components/Select/Select'
import { ALERT_TYPE_OPTIONS } from 'const/tag'
import { Wrapper, StyledCol, FormWrapper } from './SendEmailNotification.styles'
import useAction from 'utils/useAction'
import { sendEmailNotification } from 'actions/views/emailNotifications'

const initialValues = {
  [FIELD_NAMES.type]: ALERT_TYPE_OPTIONS[0].value,
}

const SendEmailNotification = () => {
  const sendEmailNotificationFunc = useAction(sendEmailNotification)
  const onSubmit = (values) => sendEmailNotificationFunc(values)
  return (
    <Wrapper>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, hasValidationErrors }) => {
          return (
            <FormWrapper>
              <StyledCol>
                <Title>Notification type:</Title>
                <Field
                  validate={required}
                  options={ALERT_TYPE_OPTIONS}
                  component={Select}
                  name={FIELD_NAMES.type}
                />
              </StyledCol>
              <StyledCol>
                <Title>Tag ID:</Title>
                <Field
                  validate={required}
                  component={InputNumber}
                  name={FIELD_NAMES.tagId}
                />
              </StyledCol>
              <StyledCol>
                <Button
                  disabled={hasValidationErrors}
                  size="default"
                  type="primary"
                  onClick={handleSubmit}
                >
                  Send email
                </Button>
              </StyledCol>
            </FormWrapper>
          )
        }}
      />
    </Wrapper>
  )
}

export default SendEmailNotification
