import { apiInstance3 } from 'utils/request'
import { ENTITY_NAMES } from 'const/api'
import { FIELD_NAMES } from 'const/forms'
import parseError from 'helpers/parseServerError'

export const getMarketingPages = ({ page, filters, sorter, search }) =>
  apiInstance3
    .get(`/${ENTITY_NAMES.backoffice}/${ENTITY_NAMES.marketingPages}`, {
      params: {
        search: search ? encodeURIComponent(search) : null,
        ...filters,
        sort: sorter || FIELD_NAMES.id,
        page: page,
        per_page: 20,
      },
    })
    .catch((error) => {
      parseError(error, 'Cannot get marketing pages')
    })

export const createMarketingPage = (data) =>
  apiInstance3
    .post(`${ENTITY_NAMES.backoffice}/${ENTITY_NAMES.marketingPages}`, data)
    .catch((error) => {
      parseError(error, "Marketing page hasn't been created")
    })

export const updateMarketingPage = ({ data }) => {
  const { [FIELD_NAMES.id]: id, ...rest } = data
  return apiInstance3
    .put(
      `${ENTITY_NAMES.backoffice}/${ENTITY_NAMES.marketingPages}/${data.id}`,
      {
        ...rest,
      }
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Marketing page hasn't been updated")
    })
}

export const deleteMarketingPage = (id) =>
  apiInstance3
    .delete(`${ENTITY_NAMES.backoffice}/${ENTITY_NAMES.marketingPages}/${id}`)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Marketing page hasn't been deleted")
    })
