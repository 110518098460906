import styled from 'styled-components'
import { Layout, Row } from 'antd'

export const Wrapper = styled(Layout.Content)`
  min-height: calc(100vh - 64px);
  padding: 10px 50px;
`

export const Title = styled.div`
  padding-bottom: 4px;
  font-weight: 500;
  padding-left: 10px;
`

export const StyledCol = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  min-width: 45%;
`

export const StyledRow = styled(Row)`
  margin-bottom: 30px;

  button {
    width: 200px;
  }
`
