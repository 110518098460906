import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  createLandingPage,
  updateLandingPage,
} from 'actions/views/landingPageImages'
import useAction from 'utils/useAction'
import { Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/Input'
import { FIELD_NAMES } from 'const/forms'
import { required, validFilename } from 'helpers/validators'
import { selectors as landinPageSelectors } from 'domains/landingPages'
import {
  FlexWrapper,
  StyledButton,
  StyledForm,
} from './LandingPageImages.styles'
import { LANDING_PAGE_CROP_SIZE } from 'const/retailers'
import ImagePicker from 'components/ImagePicker'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper'

const LandingPageImages = ({ id, closeModal }) => {
  const isSavingActive = useSelector(landinPageSelectors.getIsSavingActive)
  const initialValues = useSelector(landinPageSelectors.getLandingPageById)(id)
  const createLandingPageFunc = useAction(createLandingPage)
  const updateLandingPageFunc = useAction(updateLandingPage)

  const onSubmit = (values) => {
    if (id) {
      const { [FIELD_NAMES.productPreview]: image, ...restValues } = values
      updateLandingPageFunc({
        values: {
          ...restValues,
          ...(image !== initialValues[FIELD_NAMES.productPreview] && {
            [FIELD_NAMES.productPreview]: image,
          }),
        },
        callback: closeModal,
      })
    } else {
      createLandingPageFunc({ values, callback: closeModal })
    }
  }

  return (
    <Form
      destroyOnUnregister
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, valid }) => (
        <Spin spinning={isSavingActive} size="large">
          <StyledForm onSubmit={handleSubmit}>
            {id && <Field name="id" label="ID" disabled component={Input} />}
            <Field
              required
              name={FIELD_NAMES.name}
              validate={validFilename}
              label="Name"
              disabled={!!id}
              placeholder="Name"
              component={Input}
            />
            <Field
              required
              name={FIELD_NAMES.title}
              validate={required}
              label="Title"
              placeholder="Title"
              component={Input}
            />
            <FieldWrapper label="Image" required>
              <FlexWrapper>
                <Field
                  hasCropper
                  validate={required}
                  cropSize={LANDING_PAGE_CROP_SIZE}
                  name={FIELD_NAMES.productPreview}
                  component={ImagePicker}
                />
              </FlexWrapper>
            </FieldWrapper>
            <StyledButton
              type="primary"
              disabled={pristine || !valid}
              onClick={handleSubmit}
            >
              Save changes
            </StyledButton>
          </StyledForm>
        </Spin>
      )}
    />
  )
}

LandingPageImages.propTypes = {
  id: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
}

LandingPageImages.defaultTypes = {
  id: null,
}

export default LandingPageImages
