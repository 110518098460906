import styled from 'styled-components'
import { Button } from 'antd'

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const StyledButton = styled(Button)`
  align-self: flex-end;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: 'space-start';
  width: 70%;
  margin-left: 20px;
`
