import React from 'react'
import { Row, Pagination } from 'antd'

const Nav = ({ page, lastPage, onChange }) => {
  return (
    <Row justify="center" style={{ padding: 20 }}>
      <Pagination
        current={page}
        total={lastPage}
        defaultPageSize={1}
        onChange={onChange}
        showQuickJumper
        showSizeChanger={false}
      />
    </Row>
  )
}

export default Nav
