import parseError from 'helpers/parseServerError'

const IS_PRODUCTION = window.location.hostname === 'backoffice.karmanow.com'

const API_URL = IS_PRODUCTION
  ? 'https://www.karmanow.com/api/monkey/v1'
  : 'https://test.k8s.karmanow.com/api/monkey/v1'

export const getMonkeyV1 = async (params = {}) => {
  try {
    const response = await fetch(`${API_URL}?${new URLSearchParams(params)}`)

    if (!response.ok) {
      throw new Error('Failed to send the request')
    }

    const data = await response.json()

    return data
  } catch (error) {
    parseError(error, 'Failed to send the request')
  }

  return null
}
