import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import { Actions, StyledButton } from './LandingPages.styles'
import Image from 'components/Image'
import { formatFieldValue } from 'helpers/formatters/fields'
import UrlComponent from 'components/UrlComponent'

export default (
  filters,
  sorter,
  handleEditLandingPage,
  handleDeleteLandingPage
) => [
  {
    title: 'ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 100,
    align: 'center',
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Name',
    dataIndex: FIELD_NAMES.name,
    key: FIELD_NAMES.name,
    width: 120,
    fixed: 'left',
    align: 'center',
    render: formatFieldValue,
  },
  {
    title: 'Title',
    dataIndex: FIELD_NAMES.title,
    key: FIELD_NAMES.title,
    width: 150,
    align: 'center',
    render: formatFieldValue,
  },
  {
    title: 'Landing Page URL',
    dataIndex: FIELD_NAMES.landingPageUrl,
    key: FIELD_NAMES.landingPageUrl,
    width: 150,
    align: 'center',
    render: (url) => <UrlComponent url={url} />,
  },
  {
    title: 'Preview',
    dataIndex: FIELD_NAMES.productPreview,
    key: FIELD_NAMES.productPreview,
    width: 180,
    align: 'center',
    render: (src) => (
      <Image
        src={src ? `${src}?${Date.now()}` : '/images/fallback.png'}
        width={136}
        height={150}
      />
    ),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    fixed: 'right',
    width: 120,
    render: (_, product) => {
      const onEdit = () => handleEditLandingPage(product[FIELD_NAMES.id])
      const onDelete = () => handleDeleteLandingPage(product)
      return (
        <Actions>
          <StyledButton onClick={onEdit}>Edit</StyledButton>
          <StyledButton onClick={onDelete} type="danger">
            Delete
          </StyledButton>
        </Actions>
      )
    },
  },
]
