import React from 'react'
import { useSelector } from 'react-redux'
import { getIsCurrentShopAvailable } from 'domains/shops/selectors'
import { SearchWrapper, Wrapper, Info } from './Shops.styles'

import SearchSelectShops from 'components/SearchSelect/SearchSelectShops'
import ShopsTabs from './ShopsTabs'
import ShopCard from './ShopCard/ShopCard'

const Shops = () => {
  const isCurrentShopAvailable = useSelector(getIsCurrentShopAvailable)

  return (
    <Wrapper>
      <SearchWrapper>
        <SearchSelectShops />
      </SearchWrapper>
      {isCurrentShopAvailable ? (
        <>
          <ShopCard />
          <ShopsTabs />
        </>
      ) : (
        <Info>Choose shop to see all features</Info>
      )}
    </Wrapper>
  )
}

export default Shops
