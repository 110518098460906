import { DATE_FORMAT_DASH_REVERSED } from 'const/dates'
import { FIELD_NAMES } from 'const/forms'
import { mapFilters, mapSorters } from 'helpers/jsonAPI'
import moment from 'moment'

export const formatCampaign = (campaign) => {
  return {
    key: campaign[FIELD_NAMES.id],
    [FIELD_NAMES.id]: campaign[FIELD_NAMES.id],
    [FIELD_NAMES.stores]: campaign.attributes[FIELD_NAMES.stores].map(
      ({ store_id, store_name, store_link }) => ({
        key: store_id,
        value: store_id,
        label: store_name,
        link: store_link,
      })
    ),
    [FIELD_NAMES.network]: { value: campaign.attributes[FIELD_NAMES.network] },
    [FIELD_NAMES.dealType]: campaign.attributes[FIELD_NAMES.dealType],
    [FIELD_NAMES.flatFee]: campaign.attributes[FIELD_NAMES.flatFee],
    [FIELD_NAMES.flatFeeCurrency]:
      campaign.attributes[FIELD_NAMES.flatFeeCurrency],
    [FIELD_NAMES.fixedFee]: campaign.attributes[FIELD_NAMES.flatFee],
    [FIELD_NAMES.fixedFeeCurrency]:
      campaign.attributes[FIELD_NAMES.flatFeeCurrency],
    [FIELD_NAMES.currentCpaPercentage]:
      campaign.attributes[FIELD_NAMES.currentCpaPercentage],
    [FIELD_NAMES.newCpaPercentage]:
      campaign.attributes[FIELD_NAMES.newCpaPercentage],
    [FIELD_NAMES.startDate]: moment(
      campaign.attributes[FIELD_NAMES.startDate]
    ).format(DATE_FORMAT_DASH_REVERSED),
    [FIELD_NAMES.endDate]: moment(
      campaign.attributes[FIELD_NAMES.endDate]
    ).format(DATE_FORMAT_DASH_REVERSED),
    [FIELD_NAMES.reportingAdmin]:
      campaign.attributes[FIELD_NAMES.reportingAdmin],
    [FIELD_NAMES.freeTextSlack]: campaign.attributes[FIELD_NAMES.freeTextSlack],
    [FIELD_NAMES.confirmed]: campaign.attributes[FIELD_NAMES.confirmed],
    [FIELD_NAMES.active]: campaign.attributes[FIELD_NAMES.active],
  }
}

export const formatCampaigns = (campaigns = {}) =>
  Object.values(campaigns).reduce(
    (result, campaign) => ({
      ...result,
      [campaign[FIELD_NAMES.id]]: formatCampaign(campaign),
    }),
    {}
  ) || {}

export const formatPayloadForFetchRequest = ({
  page,
  filters,
  sorter,
  search,
} = {}) => {
  const formatedPayload = {
    page: page || 1,
    filters: mapFilters(filters),
    sorter: mapSorters(sorter),
    search,
  }
  return formatedPayload
}

export const formatCampaignForRequests = ({
  // Names according to the form - columns file
  [FIELD_NAMES.id]: id,
  [FIELD_NAMES.network]: network,
  [FIELD_NAMES.stores]: stores,
  [FIELD_NAMES.dealType]: deal_type,
  [FIELD_NAMES.flatFee]: flat_fee,
  [FIELD_NAMES.flatFeeCurrency]: flat_fee_currency,
  [FIELD_NAMES.fixedFee]: fixed_fee,
  [FIELD_NAMES.fixedFeeCurrency]: fixed_fee_currency,
  [FIELD_NAMES.currentCpaPercentage]: current_cpa_percentage,
  [FIELD_NAMES.newCpaPercentage]: new_cpa_percentage,
  [FIELD_NAMES.startDate]: start_date,
  [FIELD_NAMES.endDate]: end_date,
  [FIELD_NAMES.freeTextSlack]: free_text_slack,
  [FIELD_NAMES.notifySlack]: notify_slack,
}) => {
  // Convert values to the format that is expected by the API
  const fixed_start_date = moment(start_date).format(DATE_FORMAT_DASH_REVERSED)
  const fixed_end_date = moment(end_date).format(DATE_FORMAT_DASH_REVERSED)
  const fixed_network = network.value
  const fixed_retailer = stores.map((item) => item.key).join(',')

  const flatFee = flat_fee ?? fixed_fee
  const flatFeeCurrency = flat_fee_currency ?? fixed_fee_currency

  // API names <- form / fixed names
  return {
    ...(id && { id }),
    type: 'campaign_deals',
    attributes: {
      [FIELD_NAMES.network]: fixed_network,
      [FIELD_NAMES.stores]: fixed_retailer,
      [FIELD_NAMES.dealType]: deal_type,
      [FIELD_NAMES.flatFee]: flatFee,
      [FIELD_NAMES.flatFeeCurrency]: flatFeeCurrency,
      [FIELD_NAMES.currentCpaPercentage]: current_cpa_percentage,
      [FIELD_NAMES.newCpaPercentage]: new_cpa_percentage,
      [FIELD_NAMES.startDate]: fixed_start_date,
      [FIELD_NAMES.endDate]: fixed_end_date,
      [FIELD_NAMES.freeTextSlack]: free_text_slack,
      [FIELD_NAMES.notifySlack]: notify_slack,
    },
  }
}
