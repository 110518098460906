import React, { useEffect } from 'react'
import useAction from 'utils/useAction'
import { Divider, Spin, Table, Typography } from 'antd'
import { postIntervalTest, postKProjectDates } from 'actions/views/algorithms'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import {
  getIntervalTestData,
  getIsSavingActive,
  getKProjectInstalledData,
  getScoreData,
} from 'domains/algorithms/selectors'
import DatePicker from 'components/DatePicker/DatePicker'
import { DATE_FORMAT_DASH_REVERSED } from 'const/dates'
import { FIELD_NAMES } from 'const/forms'
import { clearData } from 'domains/algorithms/actions'
import {
  DataContainer,
  Label,
  Row,
  StyledButton,
  StyledForm,
  Value,
  ColumnContainer,
  IntervalTestButton,
  Wrapper,
  RowContainer,
} from './KProject.styles'
import { COLUMNS } from './KProject.utils'

function camelCaseToRegularText(camelCaseString) {
  return camelCaseString
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^./, (str) => str.toUpperCase())
}

const KProject = () => {
  const isSavingActive = useSelector(getIsSavingActive)
  const kProjectInstalledData = useSelector(getKProjectInstalledData)
  const intervalTestData = useSelector(getIntervalTestData)
  const scoreData = useSelector(getScoreData)

  const postKProjectDatesFunc = useAction(postKProjectDates)
  const postIntervalTestFunc = useAction(postIntervalTest)
  const clearDataFunc = useAction(clearData)

  const onSubmit = (values) => {
    postKProjectDatesFunc({ values })
  }

  const onIntervalTestHandler = () => {
    postIntervalTestFunc()
  }

  useEffect(() => {
    return () => {
      clearDataFunc()
    }
  }, [clearDataFunc])

  return (
    <Wrapper>
      <Typography.Title level={3}>K Project</Typography.Title>
      <Spin spinning={isSavingActive} size="large">
        <RowContainer>
          <ColumnContainer>
            <Form
              destroyOnUnregister
              onSubmit={onSubmit}
              render={({ handleSubmit, pristine }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <Typography.Title level={4}>Installed test</Typography.Title>
                  <Field
                    required
                    name={FIELD_NAMES.installedDate}
                    render={({ input }) => (
                      <DatePicker
                        required
                        input={input}
                        format={DATE_FORMAT_DASH_REVERSED}
                        placeholder="Installed date"
                        label="Installed date"
                        style={{
                          width: '300px',
                        }}
                      />
                    )}
                  />

                  <StyledButton
                    type="primary"
                    disabled={pristine}
                    onClick={handleSubmit}
                  >
                    Send
                  </StyledButton>
                </StyledForm>
              )}
            />
          </ColumnContainer>

          <ColumnContainer>
            <Typography.Title level={4}>Interval test</Typography.Title>
            <IntervalTestButton type="primary" onClick={onIntervalTestHandler}>
              Send interval test
            </IntervalTestButton>
          </ColumnContainer>
        </RowContainer>
        <Divider
          style={{
            margin: '20px 0',
          }}
        />
        <RowContainer>
          {scoreData ? (
            <DataContainer>
              {Object.keys(scoreData).map((key) => (
                <Row key={key}>
                  <Label>{camelCaseToRegularText(key)}</Label>
                  <Value>{scoreData[key]}</Value>
                </Row>
              ))}
            </DataContainer>
          ) : null}
        </RowContainer>
        <RowContainer>
          {kProjectInstalledData ? (
            <DataContainer>
              {Object.keys(kProjectInstalledData).map((key) => (
                <Row key={key}>
                  <Label>{camelCaseToRegularText(key)}</Label>
                  <Value>{kProjectInstalledData[key]}</Value>
                </Row>
              ))}
            </DataContainer>
          ) : null}
          {intervalTestData ? (
            <Table
              style={{
                marginTop: '20px',
              }}
              dataSource={intervalTestData}
              columns={COLUMNS}
              size="medium"
              bordered
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                defaultPageSize: 50,
              }}
            />
          ) : null}
        </RowContainer>
      </Spin>
    </Wrapper>
  )
}

export default KProject
