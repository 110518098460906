import React from 'react'
import PropTypes from 'prop-types'
import { StyledLink } from './SubMenu.styles'
import useLeavingPage from 'utils/useLeavingPage'
import noop from 'lodash/noop'
import { useHistory, useLocation } from 'react-router'

const Submenu = ({ route, label }) => {
  const { pathname } = useLocation()
  const { push } = useHistory()
  const handleTitleClick = route ? () => push(route) : noop
  const ref = useLeavingPage(pathname, handleTitleClick)

  return (
    <StyledLink ref={ref} to={route}>
      {label}
    </StyledLink>
  )
}

Submenu.propTypes = {
  route: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default Submenu
