import { FIELD_NAMES } from 'const/forms'
import { SearchOutlined } from '@ant-design/icons'
import Search from '../../Features/Table/Search'
import React from 'react'
import { formatFieldValue, formatUrl } from 'helpers/formatters/fields'
import { getColumnFilters, includingFilter } from 'helpers/tables'
import { MAP_STATUS_TO_USER_TEXT, MAP_STATUS_TO_EXPLANATION } from 'const/shops'
import { Tooltip } from 'antd'
import { InfoIcon } from './ProgramsTab.styles'

export default ({ sorter, filters, networks, statuses }) => [
  {
    width: 80,
    filters: getColumnFilters(statuses, MAP_STATUS_TO_USER_TEXT),
    filteredValue: filters[FIELD_NAMES.status] || null,
    onFilter: (value, record) => record[FIELD_NAMES.status] === value,
    sorter: (a, b) =>
      a[FIELD_NAMES.status].localeCompare(b[FIELD_NAMES.status]),
    sortOrder: sorter.columnKey === FIELD_NAMES.status && sorter.order,
    title: 'Status',
    dataIndex: FIELD_NAMES.status,
    render: (status) => (
      <>
        {status
          ? MAP_STATUS_TO_USER_TEXT[status] || status
          : MAP_STATUS_TO_USER_TEXT.accepted}
        <Tooltip
          placement="topLeft"
          title={
            status
              ? MAP_STATUS_TO_EXPLANATION[status] || status
              : MAP_STATUS_TO_EXPLANATION.accepted
          }
        >
          <InfoIcon />
        </Tooltip>
      </>
    ),
    align: 'center',
    fixed: 'left',
    key: FIELD_NAMES.status,
  },
  {
    title: 'Website Id',
    dataIndex: FIELD_NAMES.websiteId,
    key: FIELD_NAMES.websiteId,
    width: 100,
    sorter: (a, b) => a[FIELD_NAMES.websiteId] - b[FIELD_NAMES.websiteId],
    sortOrder: sorter.columnKey === FIELD_NAMES.websiteId && sorter.order,
    render: formatFieldValue,
  },
  {
    title: 'Program Id',
    dataIndex: FIELD_NAMES.programId,
    key: FIELD_NAMES.programId,
    width: 100,
    sorter: (a, b) => a[FIELD_NAMES.programId] - b[FIELD_NAMES.programId],
    sortOrder: sorter.columnKey === FIELD_NAMES.programId && sorter.order,
  },
  {
    title: 'Network',
    dataIndex: FIELD_NAMES.network,
    key: FIELD_NAMES.network,
    width: 100,
    sorter: (a, b) =>
      a[FIELD_NAMES.network].localeCompare(b[FIELD_NAMES.network]),
    sortOrder: sorter.columnKey === FIELD_NAMES.network && sorter.order,
    filters: getColumnFilters(networks),
    filteredValue: filters[FIELD_NAMES.network] || null,
    onFilter: (value, record) => record[FIELD_NAMES.network] === value,
  },
  {
    title: 'Name',
    dataIndex: FIELD_NAMES.name,
    key: FIELD_NAMES.name,
    width: 100,
    sorter: (a, b) => a[FIELD_NAMES.name].localeCompare(b.name),
    sortOrder: sorter.columnKey === FIELD_NAMES.name && sorter.order,
    filterIcon: (filtered) => (
      <SearchOutlined color={filtered ? 'blue' : undefined} />
    ),
    filteredValue: filters[FIELD_NAMES.name] || null,
    onFilter: includingFilter(FIELD_NAMES.name),
    filterDropdown: (props) => <Search {...props} />,
  },
  {
    title: 'Rule Id',
    dataIndex: FIELD_NAMES.ruleId,
    key: FIELD_NAMES.ruleId,
    width: 100,
    sorter: (a, b) => a[FIELD_NAMES.ruleId] - b[FIELD_NAMES.ruleId],
    sortOrder: sorter.columnKey === FIELD_NAMES.ruleId && sorter.order,
  },
  {
    title: 'Domain',
    dataIndex: FIELD_NAMES.domain,
    key: FIELD_NAMES.domain,
    width: 150,
    sorter: (a, b) =>
      a[FIELD_NAMES.domain].localeCompare(b[FIELD_NAMES.domain]),
    sortOrder: sorter.columnKey === FIELD_NAMES.domain && sorter.order,
    render: (domain) => (
      <a rel="noreferrer noopener" href={formatUrl(domain)} target="_blank">
        {domain}
      </a>
    ),
  },
]
