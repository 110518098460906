import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { formatRuleSlugs } from 'domains/rules/formatters'
import { areFiltersAndSorterEmpty } from 'helpers/filters'
import { FIELD_NAMES } from 'const/forms'

export const rulesState = ({ rules }) => rules

export const ruleSlugsState = createSelector(
  rulesState,
  ({ [FIELD_NAMES.ruleSlugs]: slugs }) => slugs
)

export const getIsLoadedSlugs = createSelector(
  ruleSlugsState,
  ({ isLoaded }) => isLoaded
)

export const getIsSavingActive = createSelector(
  rulesState,
  ({ isSavingActive }) => isSavingActive
)

export const isLoadingOrSavingActiveSlugs = createSelector(
  [getIsLoadedSlugs, getIsSavingActive],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getSlugEntities = createSelector(
  ruleSlugsState,
  ({ entities }) => entities
)

export const getRulesSlugs = createSelector(getSlugEntities, ({ data }) =>
  formatRuleSlugs(data)
)

export const getRuleSlugById = createSelector(getRulesSlugs, (slugs) =>
  memoize((id) => (id ? slugs[id] : null))
)

export const getSlugSearchString = createSelector(
  ruleSlugsState,
  ({ search }) => search
)

export const getSlugPage = createSelector(ruleSlugsState, ({ page }) => page)

export const getSlugFilters = createSelector(
  ruleSlugsState,
  ({ filters }) => filters
)

export const getSlugSorter = createSelector(
  ruleSlugsState,
  ({ sorter }) => sorter
)

export const getIsDataFilteredOrSorted = createSelector(
  [getSlugFilters, getSlugSorter],
  areFiltersAndSorterEmpty
)

export const getSlugPageCount = createSelector(
  ruleSlugsState,
  ({ pageCount }) => pageCount
)

export const getSlugSortedIds = createSelector(
  ruleSlugsState,
  ({ sortedIds }) => sortedIds
)

export const getSortedRuleSlugs = createSelector(
  [getRulesSlugs, getSlugSortedIds],
  (slugs, ids) => ids.map((id) => slugs[id])
)
