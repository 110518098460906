import React from 'react'
import moment from 'moment'
import StatusCheckbox from './StatusCheckbox'
import PriorityInput from './PriorityInput'
import { DATE_TIME_FORMAT } from 'const/dates'
import { DEFAULT_MEDIA_TYPE } from 'const/adPackage'
import {
  AD_PACKAGE_STATUS_FILTERS,
  MEDIA_TYPE_FILTERS,
  AD_PACKAGE_TYPE_FILTERS,
} from 'const/retailers'
import { Actions, StyledButton, TextAd } from '../Shops.styles'
import { FIELD_NAMES } from 'const/forms'
import {
  MAP_AD_PACKAGE_TYPE_TO_USER_TEXT,
  PACKAGE_TYPE_FILTERS,
} from 'const/shops'

export const getColumns = (
  handleEditAdPackage,
  handleDeleteAdPackage,
  handleDuplicateAdPackage,
  filters,
  sorter
) => [
  {
    title: 'ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    fixed: 'left',
    width: 100,
  },
  {
    title: 'Ad Package Name',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.name],
    key: FIELD_NAMES.name,
    fixed: 'left',
    width: 200,
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.name && sorter.order,
  },
  {
    title: 'Media Type',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.mediaType],
    key: FIELD_NAMES.mediaType,
    align: 'center',
    filters: MEDIA_TYPE_FILTERS,
    filteredValue: filters[FIELD_NAMES.mediaType] || null,
    render: (mediaType) => {
      if (!mediaType || mediaType === DEFAULT_MEDIA_TYPE) return '-'

      return <>{mediaType === 'sponsored' ? 'Sponsored' : 'Advertisement'}</>
    },
    width: 130,
  },
  {
    title: 'Package Type',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.packageType],
    key: FIELD_NAMES.packageType,
    align: 'center',
    filters: PACKAGE_TYPE_FILTERS,
    filteredValue: filters[FIELD_NAMES.packageType] || null,
    render: (type) => {
      return <>{type ? MAP_AD_PACKAGE_TYPE_TO_USER_TEXT[type] : '-'}</>
    },
    width: 130,
  },
  {
    title: 'Retailer Name',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.retailerName],
    key: FIELD_NAMES.retailerName,
    width: 150,
  },
  {
    title: 'Retailer id',
    dataIndex: [
      FIELD_NAMES.attributes,
      FIELD_NAMES.retailer,
      FIELD_NAMES.value,
    ],
    key: 'retailerId',
    width: 100,
  },
  {
    title: 'Starts at',
    align: 'center',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.startDate],
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.startDate && sorter.order,
    render: (startDate) => moment(startDate).format(DATE_TIME_FORMAT),
    key: FIELD_NAMES.startDate,
    width: 150,
  },
  {
    title: 'Ends at',
    align: 'center',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.endDate],
    key: FIELD_NAMES.endDate,
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.endDate && sorter.order,
    render: (endDate) => {
      if (!endDate) return '-'

      return moment(endDate).format(DATE_TIME_FORMAT)
    },
    width: 150,
  },
  {
    title: 'Status',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.status],
    filters: AD_PACKAGE_STATUS_FILTERS,
    filteredValue: filters[FIELD_NAMES.status] || null,
    render: (_, { id, status }) => <StatusCheckbox id={id} status={status} />,
    align: 'center',
    key: FIELD_NAMES.status,
    width: 100,
  },
  {
    title: 'Text Ad',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.textOnly],
    key: FIELD_NAMES.textOnly,
    filters: AD_PACKAGE_TYPE_FILTERS,
    filteredValue: filters[FIELD_NAMES.textOnly] || null,
    render: (value) => (value ? <TextAd twoToneColor="#52c41a" /> : '-'),
    align: 'center',
    width: 100,
  },
  {
    title: 'Priority',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.priority],
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.priority && sorter.order,
    render: (value, { id }) => (
      <PriorityInput packageId={id} defaultValue={value} />
    ),
    key: FIELD_NAMES.priority,
    width: 100,
    align: 'center',
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (_, id) => {
      const onEdit = () => handleEditAdPackage(id)
      const onDelete = () => handleDeleteAdPackage(id)
      const onDuplicate = () => handleDuplicateAdPackage(id)

      return (
        <Actions>
          <StyledButton onClick={onEdit}>Edit</StyledButton>
          <StyledButton onClick={onDuplicate}>Duplicate</StyledButton>

          <StyledButton onClick={onDelete} type="danger">
            Delete
          </StyledButton>
        </Actions>
      )
    },
    align: 'center',
    fixed: 'right',
    width: 120,
  },
]
