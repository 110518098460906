import styled from 'styled-components'
import { Button } from 'antd'

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledButton = styled(Button)`
  align-self: flex-end;
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};

  & > div {
    margin-top: 0;
    margin-right: 10px;
    .field-control {
      width: 100%;
    }
  }
`
