import styled from 'styled-components'
import { Layout, Table } from 'antd'

export const Wrapper = styled(Layout.Content)`
  min-height: calc(100vh - 64px);
`

export const StyledTable = styled(Table)`
  .ant-table-title {
    padding: 0;
  }
`
