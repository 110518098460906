import { createSelector } from '@reduxjs/toolkit'

export const algorithmsState = ({ algorithms }) => algorithms

export const getIsSavingActive = createSelector(
  algorithmsState,
  ({ isSavingActive }) => isSavingActive
)

export const getKProjectInstalledData = createSelector(
  algorithmsState,
  ({ kProjectInstalledData }) => kProjectInstalledData
)

export const getIntervalTestData = createSelector(
  algorithmsState,
  ({ intervalTestData }) => intervalTestData
)

export const getScoreData = createSelector(
  algorithmsState,
  ({ scoreData }) => scoreData
)
