import styled from 'styled-components'
import { Layout, Row, Button, Collapse } from 'antd'

export const Wrapper = styled(Layout.Content)`
  min-height: calc(100vh - 64px);
`

export const StyledButton = styled(Button)`
  min-width: 140px;
`

export const StyledCollapse = styled(Collapse)`
  .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse-item .ant-collapse-header-collapsible-only {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fafafa;
  }
`

export const StyledRow = styled(Row)`
  margin: 10px 10px;
  flex-flow: nowrap;
  justify-content: space-between;
  & > span {
    width: calc(100% - 200px);
  }
`

export const Title = styled.h2`
  margin: 0;
  font-size: 16px;
`
