import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import { ENTITY_NAMES } from 'const/api'
import { filterDeletedRetailers, insertRegionalIds } from 'helpers/retailers'

export const mergeRetailers = (oldRetailers, newRetailers) =>
  Object.keys(oldRetailers).reduce(
    (result, id) => ({
      ...result,
      [id]: newRetailers?.[id] || oldRetailers?.[id],
    }),
    {}
  )

export const mergeSortedIds = (oldIds, filteredIds, newIds) =>
  oldIds.filter((id) => !(filteredIds.includes[id] && !newIds.includes(id)))

export const mergeUpdatedRetailers = (state, payload) => ({
  ...state,
  isLoaded: true,
  sortedIds: mergeSortedIds(
    JSON.parse(JSON.stringify(state.sortedIds)),
    payload.filteredIds,
    payload.sortedIds
  ),
  entities: {
    ...merge(JSON.parse(JSON.stringify(state.entities)), payload.data),
    [ENTITY_NAMES.retailers]: mergeRetailers(
      JSON.parse(JSON.stringify(state.entities[ENTITY_NAMES.retailers])),
      payload.data[ENTITY_NAMES.retailers]
    ),
  },
  allEntities: {
    ...merge(
      { ...JSON.parse(JSON.stringify(state.allEntities)) },
      payload.data
    ),
    [ENTITY_NAMES.retailers]: mergeRetailers(
      JSON.parse(JSON.stringify(state.allEntities[ENTITY_NAMES.retailers])),
      payload.data[ENTITY_NAMES.retailers]
    ),
  },
})

export const mergeAddedRetailers = (state, payload) => ({
  ...state,
  sortedIds: insertRegionalIds(
    JSON.parse(JSON.stringify(state.sortedIds || [])),
    Object.keys({
      ...filterDeletedRetailers(payload.data[ENTITY_NAMES.regional]),
    }),
    Object.keys(payload.data[ENTITY_NAMES.main] || {})
  ),
  entities: {
    ...merge(JSON.parse(JSON.stringify(state.entities)), payload.data),
    [ENTITY_NAMES.retailers]: merge(
      { ...JSON.parse(JSON.stringify(state.entities[ENTITY_NAMES.retailers])) },
      filterDeletedRetailers(payload.data[ENTITY_NAMES.regional])
    ),
    [ENTITY_NAMES.retailerGroups]: {
      ...JSON.parse(
        JSON.stringify(state.entities[ENTITY_NAMES.retailerGroups] || {})
      ),
      ...payload.data[ENTITY_NAMES.retailerGroups],
    },
  },
  allEntities: {
    ...merge(
      { ...JSON.parse(JSON.stringify(state.allEntities)) },
      payload.data
    ),
    [ENTITY_NAMES.retailers]: merge(
      {
        ...JSON.parse(
          JSON.stringify(state.allEntities[ENTITY_NAMES.retailers])
        ),
      },
      filterDeletedRetailers(payload.data[ENTITY_NAMES.regional])
    ),
  },
})

export const mergeFetchedRetailers = (state, payload) => ({
  ...state,
  isLoaded: true,
  isSavingActive: false,
  sortedIds: payload.sortedIds,
  entities: payload.data,
  allEntities: merge(cloneDeep(state.allEntities), payload.data),
  pageCount: payload.page_count,
  filters: payload.filters,
  sorter: payload.sorter,
})
