import { all, put, take, takeLatest, race, select } from 'redux-saga/effects'

import { startLoading, finishLoading } from 'reducers/layoutSlice'
import {
  actions as giveawaysActions,
  formatters as giveawaysFormatters,
  selectors as giveawaysSelectors,
} from 'domains/giveaways'
import * as actions from 'actions/views/giveawaysPopups'
import { FIELD_NAMES } from 'const/forms'

function* reloadProducts() {
  const [search, page, filters, sorter] = yield all([
    select(giveawaysSelectors.getSearchString),
    select(giveawaysSelectors.getPage),
    select(giveawaysSelectors.getFilters),
    select(giveawaysSelectors.getSorter),
  ])
  yield put(
    actions.fetchGiveawaysPopups({
      search,
      page,
      filters,
      sorter,
    })
  )
  yield race([
    take(giveawaysActions.fetchGiveawaysSuccess),
    take(giveawaysActions.fetchGiveawaysFailure),
  ])
}

function* fetchGiveawaysWorker({ payload } = {}) {
  try {
    const search = yield select(giveawaysSelectors.getSearchString)
    const body = giveawaysFormatters.formatPayloadForFetchRequest(payload)

    yield all([
      put(startLoading()),
      put(giveawaysActions.fetchGiveaways({ search, ...body })),
    ])
    const [success] = yield race([
      take(giveawaysActions.fetchGiveawaysSuccess),
      take(giveawaysActions.fetchGiveawaysFailure),
    ])

    if (success) {
      yield put(giveawaysActions.saveFiltersAndSorter(payload))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* deleteGiveawayWorker({ payload }) {
  try {
    yield all([
      put(startLoading()),
      put(giveawaysActions.deleteGiveaway(payload)),
    ])
    const [success] = yield race([
      take(giveawaysActions.deleteGiveawaySuccess),
      take(giveawaysActions.deleteGiveawayFailure),
    ])

    if (success) {
      yield reloadProducts()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* createGiveawayWorker({ payload }) {
  try {
    const body = giveawaysFormatters.formatGiveawayForRequests(payload.values, {
      isCreate: true,
    })
    yield all([
      put(giveawaysActions.startSaving()),
      put(giveawaysActions.createGiveaway(body)),
    ])
    const [success] = yield race([
      take(giveawaysActions.createGiveawaySuccess),
      take(giveawaysActions.createGiveawayFailure),
    ])

    if (success) {
      if (payload.values[FIELD_NAMES.productPreview]) {
        const imageBody = giveawaysFormatters.formatGiveawayForRequests(
          {
            id: success.payload.data.id,
            [FIELD_NAMES.productPreview]:
              payload.values[FIELD_NAMES.productPreview],
          },
          { isImageUpdate: true }
        )

        yield put(
          giveawaysActions.updateGiveaway({
            data: imageBody,
            updateImage: true,
          })
        )

        yield race([
          take(giveawaysActions.updateGiveawaySuccess),
          take(giveawaysActions.updateGiveawayFailure),
        ])
      }

      yield reloadProducts()
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(giveawaysActions.finishSaving())
  }
}

function* updateGiveawayWorker({ payload }) {
  try {
    const body = giveawaysFormatters.formatGiveawayForRequests(payload.values)
    const imageBody = giveawaysFormatters.formatGiveawayForRequests(
      payload.values,
      { isImageUpdate: true }
    )

    yield all([
      put(giveawaysActions.startSaving()),
      put(giveawaysActions.updateGiveaway({ data: body })),
    ])
    const [success] = yield all([
      race([
        take(giveawaysActions.updateGiveawaySuccess),
        take(giveawaysActions.updateGiveawayFailure),
      ]),
    ])

    if (success) {
      yield put(
        giveawaysActions.updateGiveaway({ data: imageBody, updateImage: true })
      )
      const successImage = yield race([
        take(giveawaysActions.updateGiveawaySuccess),
        take(giveawaysActions.updateGiveawayFailure),
      ])

      if (successImage) {
        yield reloadProducts()
        payload.callback()
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(giveawaysActions.finishSaving())
  }
}

export default function* () {
  yield all([
    fetchGiveawaysWorker(),
    takeLatest(actions.fetchGiveawaysPopups, fetchGiveawaysWorker),
    takeLatest(actions.createGiveawayPopup, createGiveawayWorker),
    takeLatest(actions.updateGiveawayPopup, updateGiveawayWorker),
    takeLatest(actions.deleteGiveawayPopup, deleteGiveawayWorker),
  ])
}
