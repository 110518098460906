import React from 'react'
import { Field } from 'react-final-form'
import Categories from 'components/Categories'
import GroupCell from 'components/GlobalUpdate/GroupCell'
import StakeInfo from 'components/StakeInfo'
import {
  FORM_CHANGES_FIELD_PREFIX,
  FIELD_NAMES,
  getGroupFieldName,
} from 'const/forms'
import withDisabledReasonTooltip from 'hocs/withDisabledReasonTooltip'
import {
  requiredHeaderImage,
  requiredKarmaGivesAndImages,
} from 'helpers/validators'
import RetailerCountry from 'components/RetailerCountry'
import Switch from 'components/GlobalUpdate/Switch'
import { Actions, StyledButton, Wrapper } from '../Retailers.styles'
import {
  RETAILER_TYPE_FILTERS,
  ACTION_ON_CLICK_FILTERS,
  SHOW_ON_UI_FILTERS,
  STATUS_FILTERS,
  MEDIA_TYPE_FILTERS,
  MEDIA_TYPE_OPTIONS,
} from 'const/retailers'
import Select from 'components/GlobalUpdate/Select'
import { getGlobalUpdateOptions } from 'helpers/globalUpdate'
import { formatUrl } from 'helpers/formatters/fields'
const GroupCellWithDisabledReasonTooltip = withDisabledReasonTooltip(GroupCell)
const SwitchWithDisabledReasonTooltip = withDisabledReasonTooltip(Switch)

const getGroupsColumns = (groups, handleGlobalChange, filters, sorter) =>
  groups.reduce((result, group) => {
    const fieldName = getGroupFieldName(group.id)

    result.push({
      title: group.attributes.name,
      dataIndex: fieldName,
      key: fieldName,
      width: 150,
      filters: STATUS_FILTERS,
      filteredValue: filters[fieldName] || null,
      render: (_, data) => {
        const disabledReason =
          group.meta.slug === 'cashback'
            ? requiredKarmaGivesAndImages(data)
            : ''

        return (
          <Field
            changeCallback={handleGlobalChange(
              getGlobalUpdateOptions(data, fieldName)
            )}
            isShadow={!data[FIELD_NAMES.showOnUi]}
            name={`${FORM_CHANGES_FIELD_PREFIX}_${
              data[FIELD_NAMES.id]
            }[${fieldName}]`}
            component={GroupCellWithDisabledReasonTooltip}
            disabled={!!disabledReason}
            disabledReason={disabledReason}
          />
        )
      },
      sorter: true,
      sortOrder: sorter.columnKey === fieldName && sorter.order,
    })
    return result
  }, [])

export const getColumns = ({
  groups,
  openEditStoreContentModal,
  openEditStoreImagesModal,
  handleGlobalChange,
  filters,
  sorter,
}) => [
  {
    title: 'Retailer ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 90,
    fixed: 'left',
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Country',
    dataIndex: FIELD_NAMES.country,
    key: FIELD_NAMES.country,
    filters: RETAILER_TYPE_FILTERS,
    filteredValue: filters[FIELD_NAMES.country] || null,
    render: (country, retailer) => <RetailerCountry retailer={retailer} />,
    width: 100,
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Name/Rule ID',
    dataIndex: FIELD_NAMES.name,
    key: FIELD_NAMES.name,
    render: (name, { url, rule_id }) => (
      <Wrapper>
        <a rel="noreferrer noopener" href={formatUrl(url)} target="_blank">
          {name}
        </a>
        <span>{rule_id}</span>
      </Wrapper>
    ),
    width: 130,
    fixed: 'left',
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.name && sorter.order,
  },
  {
    title: 'Category',
    dataIndex: FIELD_NAMES.categories,
    key: FIELD_NAMES.categories,
    fixed: 'left',
    render: (_, { id, isGlobal }) =>
      isGlobal && <Categories name={`${FORM_CHANGES_FIELD_PREFIX}_${id}`} />,
    width: 180,
  },
  {
    width: 170,
    title: 'Media type',
    dataIndex: FIELD_NAMES.mediaType,
    key: FIELD_NAMES.mediaType,
    filters: MEDIA_TYPE_FILTERS,
    filteredValue: filters[FIELD_NAMES.mediaType] || null,
    render: (type, data) => (
      <Field
        name={`${FORM_CHANGES_FIELD_PREFIX}_${data[FIELD_NAMES.id]}[${
          FIELD_NAMES.mediaType
        }]`}
        options={MEDIA_TYPE_OPTIONS}
        component={Select}
        changeCallback={handleGlobalChange(
          getGlobalUpdateOptions(data, FIELD_NAMES.mediaType)
        )}
      />
    ),
  },
  {
    title: 'Show on UI',
    dataIndex: FIELD_NAMES.showOnUi,
    key: FIELD_NAMES.showOnUi,
    render: (_, data) => {
      const disabledReason = requiredHeaderImage(data)
      return (
        <Field
          name={`${FORM_CHANGES_FIELD_PREFIX}_${data[FIELD_NAMES.id]}[${
            FIELD_NAMES.showOnUi
          }]`}
          component={SwitchWithDisabledReasonTooltip}
          disabled={!!disabledReason}
          disabledReason={disabledReason}
          changeCallback={handleGlobalChange(
            getGlobalUpdateOptions(data, FIELD_NAMES.showOnUi)
          )}
        />
      )
    },
    filters: SHOW_ON_UI_FILTERS,
    filteredValue: filters[FIELD_NAMES.showOnUi] || null,
    width: 100,
  },
  {
    title: 'Action on click Redirect/Show',
    dataIndex: FIELD_NAMES.actionOnClick,
    key: FIELD_NAMES.actionOnClick,
    render: (_, data) => {
      const disabledReason = requiredHeaderImage(data)
      return (
        <Field
          name={`${FORM_CHANGES_FIELD_PREFIX}_${data[FIELD_NAMES.id]}[${
            FIELD_NAMES.actionOnClick
          }]`}
          component={SwitchWithDisabledReasonTooltip}
          disabled={!!disabledReason}
          disabledReason={disabledReason}
          changeCallback={handleGlobalChange(
            getGlobalUpdateOptions(data, FIELD_NAMES.actionOnClick)
          )}
        />
      )
    },
    filters: ACTION_ON_CLICK_FILTERS,
    filteredValue: filters[FIELD_NAMES.actionOnClick] || null,
    width: 150,
  },
  ...getGroupsColumns(groups, handleGlobalChange, filters, sorter),
  {
    width: 60,
    title: 'KG %',
    align: 'center',
    fixed: 'right',
    dataIndex: FIELD_NAMES.stake,
    key: FIELD_NAMES.stake,
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.stake && sorter.order,
    render: (value) => <StakeInfo value={value} hideStatus />,
  },
  {
    title: 'Actions',
    dataIndex: 'store_edit',
    key: 'store_edit',
    align: 'center',
    render: (_, { type, id }) => {
      const handleEditStore = () => openEditStoreContentModal({ type, id })
      const handleEditImages = () => openEditStoreImagesModal({ id })
      return (
        <Actions>
          <StyledButton onClick={handleEditStore}>Edit store</StyledButton>
          <StyledButton onClick={handleEditImages}>Images</StyledButton>
        </Actions>
      )
    },
    fixed: 'right',
    width: 120,
  },
]
