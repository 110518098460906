import { apiInstance } from 'utils/request'
import { message } from 'antd'
import { ENTITY_NAMES, RELATIONSHIPS_NAMES } from 'const/api'
import { FIELD_NAMES } from 'const/forms'
import { TYPE_TO_PARAM } from 'const/retailers'
import parseError from 'helpers/parseServerError'

// const includedCBRelationships =
//   `${RELATIONSHIPS_NAMES.cb},` +
//   `${RELATIONSHIPS_NAMES.promoCb},` +
//   `${RELATIONSHIPS_NAMES.plusCb},` +
//   `${RELATIONSHIPS_NAMES.promoPlusCb},`

const includedRelationships =
  '?include=' +
  // `${includedCBRelationships}` +
  `${RELATIONSHIPS_NAMES.comment},` +
  `${RELATIONSHIPS_NAMES.contacts}`

const includedEditorialRelationships =
  `?include=${RELATIONSHIPS_NAMES.categories},` +
  // `${includedCBRelationships}` +
  `${RELATIONSHIPS_NAMES.retailerCategories}.${RELATIONSHIPS_NAMES.category},` +
  `${RELATIONSHIPS_NAMES.retailerGroups}.${RELATIONSHIPS_NAMES.group}`

const includedRelationshipsForRegional =
  `?include=${RELATIONSHIPS_NAMES.regional},` +
  `${RELATIONSHIPS_NAMES.regional}.${RELATIONSHIPS_NAMES.cb},` +
  `${RELATIONSHIPS_NAMES.regional}.${RELATIONSHIPS_NAMES.promoCb},` +
  `${RELATIONSHIPS_NAMES.regional}.${RELATIONSHIPS_NAMES.plusCb},` +
  `${RELATIONSHIPS_NAMES.regional}.${RELATIONSHIPS_NAMES.promoPlusCb}`

const includedEditorialRelationshipsForRegional =
  `?include=${RELATIONSHIPS_NAMES.regional},` +
  `${RELATIONSHIPS_NAMES.regional}.${RELATIONSHIPS_NAMES.categories},` +
  `${RELATIONSHIPS_NAMES.regional}.${RELATIONSHIPS_NAMES.retailerGroups}.${RELATIONSHIPS_NAMES.group}`

export const getMainRetailers = ({
  page,
  search,
  ids,
  withCategories,
  filters = '',
  sorter = '',
  deleted = false,
}) =>
  apiInstance.get(
    `/${RELATIONSHIPS_NAMES.retailers}${
      withCategories ? includedEditorialRelationships : includedRelationships
    }` + filters,
    {
      params: {
        filter: {
          [FIELD_NAMES.deleted]: deleted,
          search: ids || !search ? null : encodeURIComponent(search),
          [FIELD_NAMES.id]: ids || undefined,
        },
        sort: sorter || RELATIONSHIPS_NAMES.mainRetailer,
        page: {
          number: ids ? undefined : page,
          size: Math.max(~~ids?.length, 10),
        },
      },
    }
  )

export const getMainRetailerById = ({ id, withCategories }) =>
  apiInstance.get(
    `/${RELATIONSHIPS_NAMES.main}/${id}` +
      `${
        withCategories
          ? includedEditorialRelationshipsForRegional
          : includedRelationshipsForRegional
      }` +
      `&filter[${FIELD_NAMES.deleted}]=false`
  )

export const getRetailerImagesById = ({ id }) =>
  apiInstance.get(
    `/${RELATIONSHIPS_NAMES.retailers}/${id}${includedEditorialRelationships},${RELATIONSHIPS_NAMES.images}`
  )

export const updateEntity = (data) =>
  apiInstance
    .patch(
      `/${data.type}/${data.id}`,
      { data },
      { headers: { 'Content-Type': 'application/vnd.api+json' } }
    )
    .catch((error) => {
      parseError(error, `${data.type} hasn't been updated`)
    })

export const updateRetailerTagsCount = (ruleId) =>
  apiInstance
    .patch(`/${ENTITY_NAMES.rules}/${ruleId}/update_counter`)
    .catch((error) => {
      parseError(error, `Tag count hasn't been updated`)
    })

export const createEntity = (data) =>
  apiInstance
    .post(
      `/${data.type}`,
      { data },
      { headers: { 'Content-Type': 'application/vnd.api+json' } }
    )
    .catch((error) => {
      parseError(error, `${data.type} hasn't been created`)
    })

export const declineCashback = (data) =>
  apiInstance.put(`/${data.type}/${data.id}/finish`).catch((error) => {
    parseError(error, `${data.type} cashback hasn't been declined`)
  })

export const deleteEntity = (data) =>
  apiInstance
    .delete(`/${data.type}/${data.id}?include=admin`)
    .catch((error) => {
      parseError(error, `${data.type} hasn't been deleted`)
    })

export const request = ({ errorMessage, ...config }) =>
  apiInstance({
    ...config,
    headers: { 'Content-Type': 'application/vnd.api+json' },
  }).catch(() => {
    message.error(errorMessage)
  })

export const convertToRetailer = (id, data, sourceType, destinationType) =>
  apiInstance.put(`/${ENTITY_NAMES.retailers}/${id}/convert_type`, {
    data: {
      options: {
        ...data,
      },
      type: TYPE_TO_PARAM[destinationType],
    },
  })

export const restoreRetailer = ({ id, data }) =>
  apiInstance.patch(`/${ENTITY_NAMES.retailers}/${id}/restore`, {
    data,
  })

export const syncImagesForRegional = (id) =>
  apiInstance.post(`/${ENTITY_NAMES.retailers}/${id}/sync_with_regionals`)

export const copyImagesBetweenRetailers = ({ id, data }) =>
  apiInstance.post(`/${ENTITY_NAMES.retailers}/${id}/copy_all_images`, {
    data,
  })

export const deleteRetailer = (id) =>
  apiInstance.delete(`/${ENTITY_NAMES.retailers}/${id}/soft_delete`)

export const createCleanRetailer = (data, retailerType) =>
  apiInstance.post(`/${retailerType}/copy`, data)

export const createDirtyRetailer = (data, retailerType) =>
  apiInstance.post(`/${retailerType}/copy_with_relations`, data)
