export const REQUIRED = 'Required'
export const ZERO_OR_NATURAL = 'Must be >= 0'
export const VALID_NUMBER = 'Must be a valid number'
export const INVALID_TEXT = 'Invalid text'
export const INVALID_LINK = 'Invalid link'
export const INVALID_VERSION = 'Invalid format'
export const INVALID_EMAIL = 'Invalid email'
export const INVALID_MOBILE_LINK = 'Invalid mobile link'
export const DISABLED_IF_CB_UNSET =
  'Set cashback stake or valid date to enable activation'
export const getRequiredFieldError = (field) => `Required field "${field}"`
export const getCBWithCPAError = (value) => `CB can't be > ${Math.floor(value)}`
export const getMaxLengthAndCurrentLengthError = (max, currentLenght) =>
  `Max length is ${max}. Now ${currentLenght}`
export const getMaxLengthError = (max) => `Max length is ${max}`
export const getMinLengthError = (min) => `Min length is ${min}`
export const REQUIRED_HEADER_IMAGE = "Header image isn't set"
export const REQUIRED_KG = 'KarmaGives value should be > 0'
export const CORRECT_DATA =
  'Please correct or clear invalid fields to perform this action.'
export const HAS_RELATIONSHIPS =
  'You cannot perform this action. Retailer has relationships'
export const ILLEGAL_CHARACTERS = 'String contains illegar characters'
export const INVALID_EXCLUSIVE_PRIORITY = 'Has to be between 1 - 50'
export const INVALID_PERCENTAGE = 'Has to be between 1 - 100'
export const INVALID_PERCENTAGE_SUM =
  'Sum of percentages has to be equal to 100%'
