import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  createAppVersion,
  updateAppVersion,
} from 'actions/views/mobileVersions'
import useAction from 'utils/useAction'
import { Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/Input'
import { FIELD_NAMES } from 'const/forms'
import { validAppVersion, requiredNested } from 'helpers/validators'
import { selectors as mobileAppSelectors } from 'domains/mobileApp'
import { StyledButton, StyledForm } from './AppVersion.styles'
import { PLATFORM_TYPES } from 'const/mobileApp'
import Select from 'components/Select/Select'
import Checkbox from 'components/Checkbox/Checkbox'

const AppVersion = ({ id, closeModal }) => {
  const isSavingActive = useSelector(mobileAppSelectors.getIsSavingActive)
  const initialValues = useSelector(mobileAppSelectors.getAppVersionById)(id)
  const createAppVersionFunc = useAction(createAppVersion)
  const updateAppVersionFunc = useAction(updateAppVersion)

  const onSubmit = (values) => {
    if (id) {
      updateAppVersionFunc({ values, callback: closeModal })
    } else {
      createAppVersionFunc({ values, callback: closeModal })
    }
  }

  return (
    <Form
      destroyOnUnregister
      onSubmit={onSubmit}
      initialValues={
        initialValues || {
          [FIELD_NAMES.platform]: [PLATFORM_TYPES[0].value],
        }
      }
      render={({ handleSubmit, pristine }) => (
        <Spin spinning={isSavingActive} size="large">
          <StyledForm onSubmit={handleSubmit}>
            {id && (
              <Field
                name={FIELD_NAMES.id}
                label="ID"
                disabled
                component={Input}
              />
            )}
            <Field
              required
              validate={requiredNested}
              name={FIELD_NAMES.platform}
              options={PLATFORM_TYPES}
              label="Platform"
              component={Select}
              mode={!id ? 'multiple' : null}
            />
            <Field
              name={FIELD_NAMES.updateRequired}
              label="Update required"
              component={Checkbox}
            />
            <Field
              required
              validate={validAppVersion}
              name={FIELD_NAMES.version}
              label="Version"
              placeholder="Version"
              component={Input}
            />
            <StyledButton
              type="primary"
              disabled={pristine}
              onClick={handleSubmit}
            >
              Save changes
            </StyledButton>
          </StyledForm>
        </Spin>
      )}
    />
  )
}

AppVersion.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  closeModal: PropTypes.func.isRequired,
}

AppVersion.defaultTypes = {
  id: null,
}

export default AppVersion
