import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  saveFiltersAndSorter,
  fetchLandingPagesSuccess,
  fetchLandingPagesFailure,
  startSaving,
  finishSaving,
  clearData,
} = reducer.actions

export const fetchLandingPages = createAction('landingPages/fetchLandingPages')

export const updateLandingPage = createAction('landingPages/updateLandingPage')
export const updateLandingPageSuccess = createAction(
  'landingPages/updateLandingPageSuccess'
)
export const updateLandingPageFailure = createAction(
  'landingPages/updateLandingPageFailure'
)

export const createLandingPage = createAction('landingPages/createLandingPage')
export const createLandingPageSuccess = createAction(
  'landingPages/createLandingPageSuccess'
)
export const createLandingPageFailure = createAction(
  'landingPages/createLandingPageFailure'
)

export const deleteLandingPage = createAction('landingPages/deleteLandingPage')
export const deleteLandingPageSuccess = createAction(
  'landingPages/deleteLandingPageSuccess'
)
export const deleteLandingPageFailure = createAction(
  'landingPages/deleteLandingPageFailure'
)
