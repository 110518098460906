import styled from 'styled-components'
import { CheckCircleTwoTone } from '@ant-design/icons'

export const TextAd = styled(CheckCircleTwoTone)`
  font-size: 20px;
`

export const Actions = styled.div`
  display: flex;
  & > button:first-child {
    margin-right: 10px;
  }
`
