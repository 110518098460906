import styled from 'styled-components'
import { MinusCircleOutlined } from '@ant-design/icons'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const RemoveIcon = styled(MinusCircleOutlined)`
  margin-left: 10px;
`

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
`
