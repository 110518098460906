import { notification } from 'antd'

const DEFAULT_ERROR_DESCRIPTION = 'An error occured during request'

const parseError = (error, defaultMessage) => {
  let errorMessages = []

  if (error?.response?.data) {
    const errorData = error?.response?.data
    if (errorData?.error)
      errorMessages.push({
        message: defaultMessage,
        description: errorData?.error,
      })
    else if (Array.isArray(errorData?.errors)) {
      errorData.errors.forEach(
        (errorItem) =>
          errorItem?.title &&
          errorItem?.detail &&
          errorMessages.push({
            description: errorItem?.detail,
            message: errorItem?.title,
          })
      )
    }
  }

  if (!errorMessages.length)
    errorMessages.push({
      message: defaultMessage,
      description: DEFAULT_ERROR_DESCRIPTION,
    })

  errorMessages.forEach(({ message, description }) =>
    notification.error({
      message,
      description,
      duration: 4,
      placement: 'bottom',
    })
  )
}

export default parseError
