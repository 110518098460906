import { all, put, take, takeLatest, race, select } from 'redux-saga/effects'

import { startLoading, finishLoading } from 'reducers/layoutSlice'
import {
  actions as SEOPagesActions,
  formatters as SEOPagesFormatters,
  selectors as SEOPagesSelectors,
} from 'domains/SEOPages'
import * as actions from 'actions/views/productFamilies'
import { FIELD_NAMES } from 'const/forms'

function* reloadProducts() {
  const [search, page, filters, sorter] = yield all([
    select(SEOPagesSelectors.getSearchString),
    select(SEOPagesSelectors.getPage),
    select(SEOPagesSelectors.getFilters),
    select(SEOPagesSelectors.getSorter),
  ])
  yield put(
    actions.fetchSEOPages({
      search,
      page,
      filters,
      sorter,
    })
  )
  yield race([
    take(SEOPagesActions.fetchSEOPagesSuccess),
    take(SEOPagesActions.fetchSEOPagesFailure),
  ])
}

function* fetchSEOPagesWorker({ payload } = {}) {
  try {
    const search = yield select(SEOPagesSelectors.getSearchString)
    const body = SEOPagesFormatters.formatPayloadForFetchRequest(payload)

    yield all([
      put(startLoading()),
      put(SEOPagesActions.fetchSEOPages({ search, ...body })),
    ])
    const [success] = yield race([
      take(SEOPagesActions.fetchSEOPagesSuccess),
      take(SEOPagesActions.fetchSEOPagesFailure),
    ])

    if (success) {
      yield put(SEOPagesActions.saveFiltersAndSorter(payload))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* createSEOPageWorker({ payload }) {
  try {
    const body = SEOPagesFormatters.formatSEOPageForRequests(payload.values, {
      isCreate: true,
    })
    yield all([
      put(SEOPagesActions.startSaving()),
      put(SEOPagesActions.createSEOPage(body)),
    ])
    const [success] = yield race([
      take(SEOPagesActions.createSEOPagesuccess),
      take(SEOPagesActions.createSEOPageFailure),
    ])

    if (success) {
      if (
        payload.values[FIELD_NAMES.smallImage] ||
        payload.values[FIELD_NAMES.largeImage]
      ) {
        const imageBody = SEOPagesFormatters.formatSEOPageForRequests(
          {
            id: success.payload.data.id,
            [FIELD_NAMES.smallImage]: payload.values[FIELD_NAMES.smallImage],
            [FIELD_NAMES.largeImage]: payload.values[FIELD_NAMES.largeImage],
          },
          { isImageUpdate: true }
        )

        yield put(
          SEOPagesActions.updateSEOPage({ data: imageBody, updateImage: true })
        )

        yield race([
          take(SEOPagesActions.updateSEOPagesuccess),
          take(SEOPagesActions.updateSEOPageFailure),
        ])
      }

      yield reloadProducts()
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(SEOPagesActions.finishSaving())
  }
}

function* updateSEOPageWorker({ payload }) {
  try {
    const body = SEOPagesFormatters.formatSEOPageForRequests(payload.values)
    const imageBody = SEOPagesFormatters.formatSEOPageForRequests(
      payload.values,
      { isImageUpdate: true }
    )

    yield all([
      put(SEOPagesActions.startSaving()),
      put(SEOPagesActions.updateSEOPage({ data: body })),
    ])
    const [success] = yield all([
      race([
        take(SEOPagesActions.updateSEOPagesuccess),
        take(SEOPagesActions.updateSEOPageFailure),
      ]),
    ])

    if (success) {
      yield put(
        SEOPagesActions.updateSEOPage({ data: imageBody, updateImage: true })
      )
      const successImage = yield race([
        take(SEOPagesActions.updateSEOPagesuccess),
        take(SEOPagesActions.updateSEOPageFailure),
      ])

      if (successImage) {
        yield reloadProducts()
        payload.callback()
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(SEOPagesActions.finishSaving())
  }
}

function* changeSEOPagePublishStateWorker({ payload }) {
  try {
    const body = SEOPagesFormatters.formatSEOPageForRequests(payload, {
      isPublish: true,
    })

    yield all([
      put(SEOPagesActions.startSaving()),
      put(SEOPagesActions.updateSEOPage({ data: body })),
    ])
    const [success] = yield all([
      race([
        take(SEOPagesActions.updateSEOPagesuccess),
        take(SEOPagesActions.updateSEOPageFailure),
      ]),
    ])

    if (success) {
      yield reloadProducts()
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(SEOPagesActions.finishSaving())
  }
}

export default function* () {
  yield all([
    fetchSEOPagesWorker(),
    takeLatest(actions.fetchSEOPages, fetchSEOPagesWorker),
    takeLatest(actions.createSEOPage, createSEOPageWorker),
    takeLatest(actions.updateSEOPage, updateSEOPageWorker),
    takeLatest(
      actions.changeSEOPagePublishState,
      changeSEOPagePublishStateWorker
    ),
  ])
}
