import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Divider, message, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Form, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import useAction from 'utils/useAction'
import Input from 'components/Input/Input'
import Select from 'components/Select/Select'
import Checkbox from 'components/Checkbox/Checkbox'
import Modal from 'components/Modal/Modal'
import FeatureConfigurationOptionsForm from './FeatureConfigurationOptionsForm'
import FeatureConfiguration from './FeatureConfiguration/FeatureConfiguration.js'
import SubmitButton from './SubmitButton/SubmitButton.js'
import { OptionButtonStyled } from './OptionButton/OptionButton.styles.js'
import {
  selectors as featuresSelectors,
  actions as featuresActions,
} from 'domains/features'
import { selectors as adminSelectors } from 'domains/admin'
import { validate } from './validate.js'

const FeatureForm = ({ action }) => {
  const userId = useSelector(adminSelectors.adminId)
  const modal = useContext(Modal.Context)
  const [visible, setVisible] = React.useState(null)
  const features = useSelector(featuresSelectors.featuresSelector)
  const updateFeature = useAction(featuresActions.updateFeatureRequest)
  const feature =
    action === 'create'
      ? { modulo: 2 }
      : features.find((feature) => feature.id === modal.data.id)

  return (
    <Form
      onSubmit={async (values) => {
        const errors = await updateFeature({ feature: values, action, userId })

        if (errors) {
          message.error('Error occurred')
        } else {
          message.success('Saved')
          modal.close()
        }
      }}
      mutators={{
        ...arrayMutators,
      }}
      validate={(values) => validate(values)}
      subscription={{ submitting: true, pristine: true }}
      initialValues={feature}
      render={({
        handleSubmit,
        submitting,
        pristine,
        form: {
          mutators: { push },
        },
      }) => (
        <form
          onSubmit={handleSubmit}
          id={`${action === 'create' ? '' : 'featureForm'}`}
        >
          <Field
            name="name"
            label="Name"
            hint="Name should be several words without special characters Example Name"
            component={Input}
            required
          />
          <Field
            name="modulo"
            label="Modulo"
            type="number"
            component={Input}
            required
          />
          <Field name="active" label="Active" component={Checkbox} />
          <Field
            name="active_for_all"
            label="Active For All"
            component={Checkbox}
          />
          <Field name="beta" label="Beta" component={Checkbox} />
          <Field
            label="Platform"
            options={[
              { label: 'Web', value: 'web' },
              { label: 'Extension', value: 'extension' },
              { label: 'Mobile', value: 'mobile' },
              { label: 'Any', value: 'any' },
            ]}
            defaultValue="any"
            name="platform"
            component={Select}
          />
          {action !== 'create' && (
            <>
              <Divider>Feature Configurations</Divider>
              <OptionButtonStyled>
                <Button type="primary" onClick={() => setVisible(true)}>
                  <PlusOutlined /> Add Option
                </Button>
              </OptionButtonStyled>
              <FeatureConfiguration feature={feature} />
              <Button
                type="dashed"
                onClick={() =>
                  push('feature_configurations', { predicate_rule: 'Blank' })
                }
              >
                <PlusOutlined /> Add Configuration
              </Button>
            </>
          )}
          <FeatureConfigurationOptionsForm
            onClose={() => setVisible(false)}
            visible={visible}
          />
          <SubmitButton
            handleSubmit={handleSubmit}
            submitting={submitting}
            pristine={pristine}
            text="Submit"
          />
        </form>
      )}
    />
  )
}

export default FeatureForm
