import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  fetchPitaSettingsSuccess,
  fetchPitaSettingsFailure,
  startSaving,
  finishSaving,
  clearData,
} = reducer.actions

export const fetchPitaSettings = createAction('pitaSettings/fetchPitaSettings')

export const updatePitaMax = createAction('pitaSettings/updatePitaMax')
export const updatePitaMaxSuccess = createAction(
  'pitaSettings/updatePitaMaxSuccess'
)
export const updatePitaMaxFailure = createAction(
  'pitaSettings/updatePitaMaxFailure'
)

export const uploadPita = createAction('pitaSettings/uploadPita')
export const uploadPitaSuccess = createAction('pitaSettings/uploadPitaSuccess')
export const uploadPitaFailure = createAction('pitaSettings/uploadPitaFailure')
