import React from 'react'
import { useSelector } from 'react-redux'
import { Table as AntTable, Button, Layout } from 'antd'
import { Actions } from './Table.styles'
import useAction from 'utils/useAction'
import { selectors as adPackageSelectors } from 'domains/adPackages'
import { getColumns } from './columns'
import { openAdPackageModal } from 'reducers/modals/actions'
import { FIELD_NAMES } from 'const/forms'
import { getStyle } from 'helpers/colorGenerator'
import Pagination from 'components/Pagination'
import SearchInTableBar from 'components/SearchInTableBar'
import { HEADER_OFFSET } from 'const/tables'
import * as actions from 'actions/views/adPackages'

const Table = () => {
  const search = useSelector(adPackageSelectors.getSearchString)
  const page = useSelector(adPackageSelectors.getPage)
  const sorter = useSelector(adPackageSelectors.getSorter)
  const filters = useSelector(adPackageSelectors.getFilters)
  const issDataFilteredOrSorted = useSelector(
    adPackageSelectors.getIsDataFilteredOrSorted
  )
  const pageCount = useSelector(adPackageSelectors.getPageCount)
  const fetchAdPackages = useAction(actions.fetchAdPackages)
  const adPackages = useSelector(adPackageSelectors.adPackagesSelector)
  const openAdPackageModalFunc = useAction(openAdPackageModal)
  const handleCreateAdPackage = () =>
    openAdPackageModalFunc({ createMode: true })

  const changePageHandler = (page) =>
    fetchAdPackages({ page, sorter, filters, search })

  const onTableChange = (pagination, filters, sorter) =>
    fetchAdPackages({
      search,
      filters,
      sorter,
    })

  const handleResetFilters = () =>
    fetchAdPackages({
      search,
      filters: {},
      sorter: {},
    })

  const onSearch = (search) => fetchAdPackages({ search })

  return (
    <Layout.Content>
      <SearchInTableBar
        onSubmit={onSearch}
        value={search}
        placeholder="Search ad package here"
      >
        <Actions>
          <Button onClick={handleCreateAdPackage} type="primary">
            Create
          </Button>
          <Button
            onClick={handleResetFilters}
            disabled={!issDataFilteredOrSorted}
          >
            Reset filters
          </Button>
        </Actions>
      </SearchInTableBar>
      <AntTable
        sticky={HEADER_OFFSET}
        onChange={onTableChange}
        dataSource={adPackages}
        columns={getColumns(openAdPackageModalFunc, filters, sorter)}
        size="medium"
        pagination={false}
        rowKey={(record) => record[FIELD_NAMES.id]}
        rowClassName={(record) => getStyle(record.id)}
        scroll={{ x: 1300 }}
        bordered
      />
      <Pagination
        page={page}
        lastPage={pageCount}
        onChange={changePageHandler}
      />
    </Layout.Content>
  )
}

export default Table
