import React from 'react'
import AntCheckbox from './index'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'

const Checkbox = ({ input, meta, ...rest }) => (
  <FieldWrapper hint={rest.hint} label={rest.label}>
    <AntCheckbox input={input} meta={meta} {...rest} />
  </FieldWrapper>
)

export default Checkbox
