import { createAction } from '@reduxjs/toolkit'

import reducer from './reducer'

export const {
  fetchAdPackagesSuccess,
  resetFiltersAndSorter,
  createAdPackageSuccess,
  updateAdPackageSuccess,
  deleteAdPackageSuccess,
  duplicateAdPackageSuccess,
  saveRegion,
  resetRegion,
  clearData,
} = reducer.actions

export const fetchAdPackagesRequest = createAction(
  'adPackage/fetchAdPackages:request'
)
export const fetchAdPackagesFailure = createAction(
  'adPackage/fetchAdPackages:failure',
  (error) => ({ error })
)

export const updateAdPackageRequest = createAction(
  'adPackage/updateAdPackage:request',
  (adPackage, action) => ({ payload: { adPackage } })
)
export const updateAdPackageFailure = createAction(
  'adPackage/updateAdPackage:failure',
  (error) => ({ error })
)

export const updateAdPackageAssetRequest = createAction(
  'adPackage/updateAdPackageAsset:request',
  (adPackage) => ({ payload: { adPackage } })
)
export const updateAdPackageAssetSuccess = createAction(
  'adPackage/updateAdPackageAsset:success',
  (adPackage) => ({ payload: { adPackage } })
)
export const updateAdPackageAssetFailure = createAction(
  'adPackage/updateAdPackageAsset:failure',
  (error) => ({ error })
)

export const createAdPackageRequest = createAction(
  'adPackage/createAdPackage:request',
  (adPackage) => ({ payload: { adPackage } })
)
export const createAdPackageError = createAction(
  'adPackage/createAdPackage:error',
  (error) => ({ error })
)

export const deleteAdPackageRequest = createAction(
  'adPackage/deleteAdPackage:request',
  (id) => ({ payload: { id } })
)
export const deleteAdPackageError = createAction(
  'adPackage/deleteAdPackage:error',
  (error) => ({ error })
)

export const duplicateAdPackageRequest = createAction(
  'adPackage/duplicateAdPackage:request',
  (id) => ({ payload: { id } })
)
export const duplicateAdPackageError = createAction(
  'adPackage/duplicateAdPackage:error',
  (error) => ({ error })
)



export const createAdPackageAssetRequest = createAction(
  'adPackage/createAdPackageAsset:request',
  (adPackage) => ({ payload: { adPackage } })
)
export const createAdPackageAssetSuccess = createAction(
  'adPackage/createAdPackageAsset:success',
  (adPackage) => ({ payload: { adPackage } })
)
export const createAdPackageAssetError = createAction(
  'adPackage/createAdPackageAsset:error',
  (error) => ({ error })
)
