import { createSlice } from '@reduxjs/toolkit'
import ROLES from 'const/roles'
import { DEFAULT_PAYMENT_COLUMNS } from 'pages/CustomerService/columns'
import { FIELD_NAMES } from 'const/forms'

const initialState = {
  admins: {
    search: '',
    page: 1,
    pageCount: 1,
    data: [],
    sortedIds: [],
    filters: {},
    sorter: {},
    isLoaded: false,
    isSavingActive: false,
  },
  id: localStorage.getItem('admin_id') || null,
  data: {
    attributes: {
      [FIELD_NAMES.displayPreference]: DEFAULT_PAYMENT_COLUMNS,
      email: null,
      role: localStorage.getItem('role') || ROLES.MEMBER,
      name: null,
      id: null,
      token: null,
    },
    meta: {
      sign_in_count: 0,
      current_sign_in_at: null,
      last_sign_in_at: null,
      current_sign_in_ip: null,
      last_sign_in_ip: null,
      created_at: null,
      updated_at: null,
    },
    links: {
      self: null,
    },
  },
  token: localStorage.getItem('token') || null,
}

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    fetchAllAdminsSuccess: (state, { payload }) => ({
      ...state,
      admins: {
        ...state.admins,
        isLoaded: true,
        ...payload,
      },
    }),
    fetchAllAdminsFailure: (state) => state,
    saveAdminFiltersAndSorter: (state, { payload = {} }) => ({
      ...state,
      admins: {
        ...state.admins,
        search: payload.search,
        filters: payload.filters || initialState.filters,
        sorter: payload.sorter || initialState.sorter,
      },
    }),
    startSaving: (state) => ({
      ...state,
      admins: {
        ...state.admins,
        isSavingActive: true,
      },
    }),
    finishSaving: (state) => ({
      ...state,
      admins: {
        ...state.admins,
        isSavingActive: false,
      },
    }),
    login: (state, action) => ({ ...state, ...action.payload }),
    clearData: () => ({ ...initialState, id: null, token: null }),
  },
})

export default adminSlice
