import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import { Actions, StyledButton } from './Insights.styles'
import { formatDate } from 'helpers/dates'
import Image from 'components/Image'
import ShortText from 'components/ShortText'
import { formatUrl } from 'helpers/formatters/fields'

export default (filters, sorter, handleEditProduct, handleDeleteProduct) => [
  {
    title: 'Product ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 100,
    fixed: 'left',
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Tag ID',
    dataIndex: FIELD_NAMES.tagId,
    key: FIELD_NAMES.tagId,
    width: 120,
    fixed: 'left',
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.tagId && sorter.order,
  },
  {
    title: 'Name',
    dataIndex: FIELD_NAMES.name,
    key: FIELD_NAMES.name,
    width: 150,
    align: 'center',
  },
  {
    title: 'Company',
    dataIndex: FIELD_NAMES.company,
    key: FIELD_NAMES.company,
    width: 120,
    align: 'center',
    render: (name) => <ShortText text={name} />,
  },
  {
    title: 'Type',
    dataIndex: FIELD_NAMES.productType,
    key: FIELD_NAMES.productType,
    width: 120,
    align: 'center',
  },
  {
    title: 'Url',
    dataIndex: FIELD_NAMES.originalUrl,
    key: FIELD_NAMES.originalUrl,
    width: 150,
    align: 'center',
    render: (url) => (
      <a rel="noreferrer noopener" href={formatUrl(url)} target="_blank">
        <ShortText text={url} />
      </a>
    ),
  },
  {
    title: 'Date & Time',
    dataIndex: FIELD_NAMES.createdAt,
    key: FIELD_NAMES.createdAt,
    width: 200,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.createdAt && sorter.order,
    render: formatDate,
  },
  {
    title: 'Preview',
    dataIndex: FIELD_NAMES.productPreview,
    key: FIELD_NAMES.productPreview,
    width: 180,
    align: 'center',
    render: (src) => <Image src={src} width={150} height={150} />,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    fixed: 'right',
    width: 120,
    render: (_, product) => {
      const onEdit = () => handleEditProduct(product[FIELD_NAMES.id])
      const onDelete = () => handleDeleteProduct(product)
      return (
        <Actions>
          <StyledButton onClick={onEdit}>Edit</StyledButton>
          <StyledButton onClick={onDelete} type="danger">
            Delete
          </StyledButton>
        </Actions>
      )
    },
  },
]
