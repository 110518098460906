import { apiInstance } from 'utils/request'
import { ENTITY_NAMES, RELATIONSHIPS_NAMES } from 'const/api'
import { FIELD_NAMES } from 'const/forms'
import parseError from 'helpers/parseServerError'

export const getMobileAppVersions = ({ page, search, filters, sorter }) =>
  apiInstance
    .get(
      `/${RELATIONSHIPS_NAMES.admin}/${RELATIONSHIPS_NAMES.mobileAppVersions}`,
      {
        params: {
          filter: {
            search: search ? encodeURIComponent(search) : null,
            ...filters,
          },
          sort: sorter || FIELD_NAMES.id,
          page: {
            number: page,
            size: 20,
          },
        },
      }
    )
    .catch((error) => {
      parseError(error, 'Cannot get mobile app versions')
    })

export const getMobileNotifications = ({ page, search, filters, sorter }) =>
  apiInstance
    .get(
      `/${RELATIONSHIPS_NAMES.admin}/${RELATIONSHIPS_NAMES.mobileNotifications}`,
      {
        params: {
          filter: {
            search: search ? encodeURIComponent(search) : null,
            ...filters,
          },
          sort: sorter || FIELD_NAMES.id,
          page: {
            number: page,
            size: 20,
          },
        },
      }
    )
    .catch((error) => {
      parseError(error, 'Cannot get mobile notifications')
    })

export const deleteAppVersion = (id) =>
  apiInstance
    .delete(
      `/${RELATIONSHIPS_NAMES.admin}/${ENTITY_NAMES.mobileAppVersions}/${id}`
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "App version hasn't been deleted")
    })

export const createAppVersion = (data) =>
  apiInstance
    .post(`/${RELATIONSHIPS_NAMES.admin}/${ENTITY_NAMES.mobileAppVersions}`, {
      data,
    })
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "App version hasn't been created")
    })

export const createMobileNotification = (data) =>
  apiInstance
    .post(`/${RELATIONSHIPS_NAMES.admin}/${ENTITY_NAMES.mobileNotifications}`, {
      data,
    })
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Notification hasn't been created")
    })

export const updateAppVersion = ({ id, data }) =>
  apiInstance
    .patch(
      `/${RELATIONSHIPS_NAMES.admin}/${ENTITY_NAMES.mobileAppVersions}/${id}`,
      {
        data,
      }
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "App version hasn't been updated")
    })
