import { createSelector } from '@reduxjs/toolkit'

export const transactionsState = ({ transactions }) => {
  return transactions
}
export const getIsLoaded = createSelector(
  transactionsState,
  ({ isLoaded }) => isLoaded
)

export const getEntities = createSelector(
  transactionsState,
  ({ entities }) => entities
)

export const getTransactions = createSelector(
  getEntities,
  ({ transactions }) => transactions
)

export const getPage = createSelector(transactionsState, ({ page }) => page)

export const getPageCount = createSelector(
  transactionsState,
  ({ pageCount }) => pageCount
)

export const getSorter = createSelector(
  transactionsState,
  ({ sorter }) => sorter
)

export const getPageSize = createSelector(transactionsState, ({ size }) => size)
