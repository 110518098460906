import { createSelector } from '@reduxjs/toolkit'
import { IMAGES_SET, STARTING_IMAGES_NAMES } from 'const/images'
import isArray from 'lodash/isArray'
import first from 'lodash/first'
import memoize from 'lodash/memoize'
import sortBy from 'lodash/sortBy'

const generalState = ({ general }) => general

export const isLoadedSelector = createSelector(
  generalState,
  ({ isLoaded }) => isLoaded
)

export const getUnformattedGroups = createSelector(
  generalState,
  ({ groups }) => groups
)

export const getGroups = createSelector(generalState, ({ groups }) =>
  groups.reduce((result, group) => {
    result[group.id] = group
    return result
  }, {})
)

export const getGroupIdsAndNames = createSelector(
  getUnformattedGroups,
  (groups) =>
    groups.map((group) => ({
      id: group.id,
      name: group.attributes.name,
    }))
)

export const getMarketingCopy = createSelector(
  generalState,
  ({ marketingCopy }) => marketingCopy
)

export const getImageSetWithNames = createSelector(getGroups, (groups) =>
  IMAGES_SET.map((image) => {
    let group
    let tooltip = ''
    if (isArray(image.slug)) {
      const filteredGroups = sortBy(
        Object.values(groups).filter((group) =>
          image.slug.includes(group.meta.slug)
        ),
        (group) => group.attributes.position
      )
      group = first(filteredGroups)
      tooltip = filteredGroups.map((group) => group.attributes.name).join(', ')
    } else {
      group = Object.values(groups).find(
        (group) => group.meta.slug === image.slug
      )
    }
    return {
      ...image,
      tooltip,
      position: STARTING_IMAGES_NAMES.includes(image.name)
        ? -1
        : group?.attributes.position,
      label: `${group?.attributes.name || image.label}\n${image.type || ''}`,
    }
  })
)

export const getSortedImagesByGroupPositions = createSelector(
  getImageSetWithNames,
  (images) => sortBy(images, (image) => image.position)
)

export const categories = createSelector(
  generalState,
  (state) => state.categories
)

export const getLinks = createSelector(generalState, ({ links }) => links)

export const getStartedLink = createSelector(getLinks, ({ started }) => started)

export const getCategories = createSelector(categories, (items) =>
  items.reduce((result, category) => {
    result[category.id] = category
    return result
  }, {})
)

export const categoriesOptions = createSelector(
  categories,
  (items) =>
    items?.map((category) => ({
      value: category.id,
      key: category.id,
      label: category.attributes.name,
    })) || []
)

export const filteredCategoriesOptions = createSelector(
  categoriesOptions,
  (items) =>
    memoize((excludedValue) =>
      items.filter((category) => category.value !== excludedValue)
    )
)

export const categoryNames = createSelector(categories, (items) =>
  items.map((item) => item.attributes.name)
)
