import styled from 'styled-components'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
`

export const StyledButton = styled(Button)`
  margin-left: 10px;
`

export const Text = styled.span`
  display: block;
  font-size: 14px;
`

export const FieldName = styled.span`
  font-size: 13px;
  width: 30%;
  margin-right: 24px;
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
`

export const Title = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
`

export const Divider = styled.div`
  width: 100%;
  margin: 24px 0;
  background-color: #f0f0f0;
  height: 1px;
`

export const AddPlus = styled(PlusOutlined)`
  margin-left: 10px;
  font-size: 24px;
  cursor: pointer;
`
