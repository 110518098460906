import React from 'react'
import { Helmet } from 'react-helmet'

const HeadFavicon = () => (
  <Helmet>
    {window.matchMedia('(prefers-color-scheme: dark)').matches === true ? (
      <link rel="icon" href="/favicon.png" />
    ) : (
      <link rel="icon" href="/favicon.ico" />
    )}
  </Helmet>
)

export default HeadFavicon
