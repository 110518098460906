import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  forwardRef,
} from 'react'
import PropTypes from 'prop-types'
import LocaleReceiver from 'antd/es/locale-provider/LocaleReceiver'
import { Modal, Slider } from 'antd'
import noop from 'lodash/noop'
import EasyCrop from './EasyCrop'

import {
  MEDIA_CLASS,
  ZOOM_STEP,
  MIN_ROTATE,
  MAX_ROTATE,
  ROTATE_STEP,
  PKG,
} from 'const/cropper'

const ImgCrop = forwardRef((props, ref) => {
  const {
    aspect,
    shape,
    hasGrid,
    quality,

    hasZoom,
    hasRotate,
    minZoom,
    maxZoom,
    fillColor,

    modalTitle,
    modalWidth,
    modalOk,
    modalCancel,

    beforeCrop,
    children,

    cropperProps,
  } = props

  const [src, setSrc] = useState('')
  const [zoomVal, setZoomVal] = useState(1)
  const [rotateVal, setRotateVal] = useState(0)

  const beforeUploadRef = useRef()
  const fileRef = useRef()
  const resolveRef = useRef(noop)
  const rejectRef = useRef(noop)

  const cropPixelsRef = useRef()

  /**
   * Upload
   */
  const renderUpload = useCallback(() => {
    const upload = Array.isArray(children) ? children[0] : children
    const { beforeUpload, accept, ...restUploadProps } = upload.props
    beforeUploadRef.current = beforeUpload

    return {
      ...upload,
      props: {
        ...restUploadProps,
        accept: accept || 'image/*',
        beforeUpload: (file, fileList) =>
          new Promise(async (resolve, reject) => {
            if (beforeCrop && !(await beforeCrop(file, fileList))) {
              reject()
              return
            }

            fileRef.current = file
            resolveRef.current = resolve
            rejectRef.current = reject

            const reader = new FileReader()
            reader.addEventListener('load', () => {
              setSrc(reader.result)
            })
            reader.readAsDataURL(file)
          }),
      },
    }
  }, [beforeCrop, children])

  /**
   * EasyCrop
   */
  const onComplete = useCallback((croppedAreaPixels) => {
    cropPixelsRef.current = croppedAreaPixels
  }, [])

  /**
   * Controls
   */
  const isMinZoom = zoomVal - ZOOM_STEP < minZoom
  const isMaxZoom = zoomVal + ZOOM_STEP > maxZoom
  const isMinRotate = rotateVal === MIN_ROTATE
  const isMaxRotate = rotateVal === MAX_ROTATE

  const subZoomVal = useCallback(() => {
    if (!isMinZoom) setZoomVal(zoomVal - ZOOM_STEP)
  }, [isMinZoom, zoomVal])

  const addZoomVal = useCallback(() => {
    if (!isMaxZoom) setZoomVal(zoomVal + ZOOM_STEP)
  }, [isMaxZoom, zoomVal])

  const subRotateVal = useCallback(() => {
    if (!isMinRotate) setRotateVal(rotateVal - ROTATE_STEP)
  }, [isMinRotate, rotateVal])

  const addRotateVal = useCallback(() => {
    if (!isMaxRotate) setRotateVal(rotateVal + ROTATE_STEP)
  }, [isMaxRotate, rotateVal])

  /**
   * Modal
   */
  const modalProps = useMemo(() => {
    const obj = { width: modalWidth, okText: modalOk, cancelText: modalCancel }
    Object.keys(obj).forEach((key) => {
      if (!obj[key]) delete obj[key]
    })
    return obj
  }, [modalCancel, modalOk, modalWidth])

  const onClose = useCallback(() => {
    setSrc('')
    setZoomVal(1)
    setRotateVal(0)
  }, [])

  const onOk = useCallback(async () => {
    onClose()

    const naturalImg = document.querySelector(`.${MEDIA_CLASS}`)
    const { naturalWidth, naturalHeight } = naturalImg
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    // create a max canvas to cover the source image after rotated
    const maxLen = Math.sqrt(
      Math.pow(naturalWidth - 2, 2) + Math.pow(naturalHeight - 2, 2)
    )
    canvas.width = maxLen
    canvas.height = maxLen

    // rotate the image
    if (hasRotate && rotateVal > 0 && rotateVal < 360) {
      const halfMax = maxLen / 2
      ctx.translate(halfMax, halfMax)
      ctx.rotate((rotateVal * Math.PI) / 180)
      ctx.translate(-halfMax, -halfMax)
    }

    ctx.fillStyle = fillColor
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    // draw the source image in the center of the max canvas
    const left = (maxLen - naturalWidth) / 2
    const top = (maxLen - naturalHeight) / 2
    ctx.drawImage(naturalImg, left, top)

    // shrink the max canvas to the crop area size, then align two center points
    const maxImgData = ctx.getImageData(0, 0, maxLen, maxLen)
    const { width, height, x, y } = cropPixelsRef.current
    canvas.width = width
    canvas.height = height
    ctx.putImageData(maxImgData, Math.round(-left - x), Math.round(-top - y))

    // get the new image
    const { type, name, uid } = fileRef.current
    canvas.toBlob(
      async (blob) => {
        let newFile = new File([blob], name, { type })
        newFile.uid = uid

        if (typeof beforeUploadRef.current !== 'function')
          return resolveRef.current(newFile)

        const res = beforeUploadRef.current(newFile, [newFile])

        if (typeof res !== 'boolean' && !res) {
          console.error('beforeUpload must return a boolean or Promise')
          return
        }

        if (res === true) return resolveRef.current(newFile)
        if (res === false) return rejectRef.current('not upload')
        if (res && typeof res.then === 'function') {
          try {
            const passedFile = await res
            const type = Object.prototype.toString.call(passedFile)
            if (type === '[object File]' || type === '[object Blob]')
              newFile = passedFile
            resolveRef.current(newFile)
          } catch (err) {
            rejectRef.current(err)
          }
        }
      },
      type,
      quality
    )
  }, [hasRotate, onClose, quality, rotateVal, fillColor])

  const renderComponent = (titleOfModal) => (
    <>
      {renderUpload()}
      {src && (
        <Modal
          open={true}
          wrapClassName={`${PKG}-modal`}
          title={titleOfModal}
          onOk={onOk}
          onCancel={onClose}
          maskClosable={false}
          destroyOnClose
          {...modalProps}
        >
          <EasyCrop
            ref={ref}
            src={src}
            aspect={aspect}
            shape={shape}
            grid={hasGrid}
            hasZoom={hasZoom}
            zoomVal={zoomVal}
            rotateVal={rotateVal}
            setZoomVal={setZoomVal}
            setRotateVal={setRotateVal}
            minZoom={minZoom}
            maxZoom={maxZoom}
            onComplete={onComplete}
            cropperProps={cropperProps}
          />
          {hasZoom && (
            <div className={`${PKG}-control zoom`}>
              <button onClick={subZoomVal} disabled={isMinZoom}>
                －
              </button>
              <Slider
                min={minZoom}
                max={maxZoom}
                step={ZOOM_STEP}
                value={zoomVal}
                onChange={setZoomVal}
              />
              <button onClick={addZoomVal} disabled={isMaxZoom}>
                ＋
              </button>
            </div>
          )}
          {hasRotate && (
            <div className={`${PKG}-control rotate`}>
              <button onClick={subRotateVal} disabled={isMinRotate}>
                ↺
              </button>
              <Slider
                min={MIN_ROTATE}
                max={MAX_ROTATE}
                step={ROTATE_STEP}
                value={rotateVal}
                onChange={setRotateVal}
              />
              <button onClick={addRotateVal} disabled={isMaxRotate}>
                ↻
              </button>
            </div>
          )}
        </Modal>
      )}
    </>
  )
  if (modalTitle) return renderComponent(modalTitle)
  return (
    <LocaleReceiver>
      {(locale, localeCode) =>
        renderComponent(localeCode === 'zh-cn' ? '编辑图片' : 'Edit image')
      }
    </LocaleReceiver>
  )
})

ImgCrop.propTypes = {
  aspect: PropTypes.number,
  shape: PropTypes.oneOf(['rect', 'round']),
  quality: PropTypes.number,
  hasGrid: PropTypes.bool,
  hasZoom: PropTypes.bool,
  hasRotate: PropTypes.bool,
  minZoom: PropTypes.number,
  maxZoom: PropTypes.number,
  fillColor: PropTypes.string,
  modalTitle: PropTypes.string,
  modalWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  modalOk: PropTypes.string,
  modalCancel: PropTypes.string,
  beforeCrop: PropTypes.func,
  cropperProps: PropTypes.object,
  children: PropTypes.node,
}

ImgCrop.defaultProps = {
  aspect: 1,
  shape: 'rect',
  hasGrid: false,
  quality: 0.4,
  hasZoom: true,
  hasRotate: false,
  minZoom: 1,
  maxZoom: 3,
  fillColor: 'white',
}

export default ImgCrop
