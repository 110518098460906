import React from 'react'
import { useSelector } from 'react-redux'
import {
  getFilters,
  getIsDataFilteredOrSorted,
  getIsLoaded,
  getPage,
  getPageCount,
  getSearchString,
  getSorter,
  getSortedAdmins,
} from 'domains/admin/selectors'
import useAction from 'utils/useAction'
import { deleteAdmin, fetchAllAdmins } from 'actions/views/roles'
import { StyledTable, Buttons } from './Admins.styles'
import SearchInTableBar from 'components/SearchInTableBar'
import { Button } from 'antd'
import getColumns from './columns'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import { FIELD_NAMES } from 'const/forms'
import { openConfirmModal, openAdminModal } from 'reducers/modals/actions'
import isEqual from 'lodash/isEqual'
import { HEADER_OFFSET, PAGE_SIZE, SCROLL } from 'const/tables'

const Admins = () => {
  const isLoaded = useSelector(getIsLoaded)
  const page = useSelector(getPage)
  const search = useSelector(getSearchString)
  const pageCount = useSelector(getPageCount)
  const filters = useSelector(getFilters)
  const sorter = useSelector(getSorter)
  const dataSource = useSelector(getSortedAdmins)
  const isDataFilteredOrSorted = useSelector(getIsDataFilteredOrSorted)

  const fetchAdminsFunc = useAction(fetchAllAdmins)
  const deleteAdminFunc = useAction(deleteAdmin)
  const openConfirmModalFunc = useAction(openConfirmModal)
  const openAdminModalFunc = useAction(openAdminModal)

  const onSearchStringChange = (search) =>
    fetchAdminsFunc({
      page: 1,
      search,
      filters,
      sorter,
    })
  const handleEditAdmin = (id) => openAdminModalFunc({ id })
  const handleCreateAdmin = () => openAdminModalFunc()
  const handleDeleteAdmin = (admin) => {
    const callback = () => deleteAdminFunc(admin[FIELD_NAMES.id])
    const text =
      `Are you sure you want to delete admin ${admin[FIELD_NAMES.name]} (ID: ${
        admin[FIELD_NAMES.id]
      })? ` + "This action can't be revoked."
    openConfirmModalFunc({ callback, text })
  }

  const onTableChange = (pagination, newFilters, newSorter) => {
    const pageChanged = page !== pagination.current
    const newFiltersOrSorter =
      !pageChanged &&
      (!isEqual(newFilters, filters) || !isEqual(newSorter, sorter))
    fetchAdminsFunc({
      page: newFiltersOrSorter ? 1 : pagination.current,
      search,
      filters: newFilters,
      sorter: newSorter,
    })
  }

  const handleResetFilters = () =>
    fetchAdminsFunc({
      search,
      page: 1,
      filters: {},
      sorter: {},
    })

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  return (
    <>
      <SearchInTableBar
        placeholder="Search admin here"
        onSubmit={onSearchStringChange}
        value={search}
      >
        <Buttons>
          <Button
            onClick={handleCreateAdmin}
            type="primary"
            disabled={!isLoaded}
          >
            Create
          </Button>
          <Button
            onClick={handleResetFilters}
            disabled={!isLoaded || !isDataFilteredOrSorted}
          >
            Reset filters
          </Button>
        </Buttons>
      </SearchInTableBar>
      <StyledTable
        onChange={onTableChange}
        showSorterTooltip={false}
        columns={getColumns(
          filters,
          sorter,
          handleEditAdmin,
          handleDeleteAdmin
        )}
        dataSource={dataSource}
        expandable={false}
        pagination={paginationConfig}
        sticky={isLoaded && HEADER_OFFSET}
        scroll={SCROLL}
        bordered
        loading={false}
        rowKey={getRowKey}
        rowClassName={getRegularRowClass}
      />
    </>
  )
}

export default Admins
