import React from 'react'
import PropTypes from 'prop-types'
import { HideButton, ShowButton } from './HideSwitch.styles'
import { Tooltip } from 'antd'

const HideSwitch = ({ input, hide, disabled, ...rest }) => {
  if (hide) {
    return null
  }
  const handleClick = () => input.onChange(!input.value)
  const Icon = input.value ? HideButton : ShowButton
  return (
    <Tooltip title={`Click to ${input.value ? 'show' : 'hide'} this set on UI`}>
      <Icon
        disabled={disabled}
        isLocked={disabled}
        onClick={handleClick}
        {...rest}
      />
    </Tooltip>
  )
}

HideSwitch.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/boolean-prop-naming
  hide: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
}

HideSwitch.defaultProps = {
  disabled: false,
}

// ! Fixes tooltip visibility if HideSwitch disabled
HideSwitch.__ANT_BUTTON = true

export default HideSwitch
