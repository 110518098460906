import React from 'react'
import PropTypes from 'prop-types'
import arrayMutators from 'final-form-arrays'
import { Field, Form } from 'react-final-form'
import { FIELD_NAMES } from 'const/forms'
import { StyledButton, StyledForm } from './ShopCouponsSettings.styles'
import FieldSwitch from 'components/FieldSwitch/FieldSwitch'
import useAction from 'utils/useAction'
import { updateShopCouponsSettings } from 'actions/views/shops'
import { useSelector } from 'react-redux'
import {
  getIsSavingActive,
  getLocalizedShopById,
} from 'domains/shops/selectors'
import { Spin } from 'antd'

const ShopCouponsSettings = ({ id, closeModal }) => {
  const currentShopData = useSelector(getLocalizedShopById)(id)
  const isSavingActive = useSelector(getIsSavingActive)
  const updateShopCouponsSettingsFunc = useAction(updateShopCouponsSettings)

  const onSubmit = (data) => {
    updateShopCouponsSettingsFunc({
      id: currentShopData?.attributes?.[FIELD_NAMES.ruleId],
      data,
      callback: closeModal,
    })
  }

  const parsedInitialValues = {
    [FIELD_NAMES.coupons]:
      currentShopData?.attributes?.[FIELD_NAMES.showCoupons],
    [FIELD_NAMES.couponsScanner]:
      currentShopData?.attributes?.[FIELD_NAMES.couponsScanner],
    [FIELD_NAMES.couponsScraping]:
      currentShopData?.attributes?.[FIELD_NAMES.couponsScraping],
  }

  return (
    <Form
      destroyOnUnregister
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={parsedInitialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <Spin spinning={isSavingActive} size="large">
            <StyledForm onSubmit={handleSubmit}>
              <>
                <Field
                  type="checkbox"
                  name={FIELD_NAMES.coupons}
                  label="Show coupons"
                  component={FieldSwitch}
                  defaultValue={false}
                />
                <Field
                  type="checkbox"
                  name={FIELD_NAMES.couponsScanner}
                  label="Coupons scanner"
                  component={FieldSwitch}
                  defaultValue={false}
                />
                <Field
                  type="checkbox"
                  name={FIELD_NAMES.couponsScraping}
                  label="Coupons scraping"
                  component={FieldSwitch}
                  defaultValue={false}
                />
              </>
              <StyledButton
                type="primary"
                disabled={pristine}
                onClick={handleSubmit}
              >
                Save changes
              </StyledButton>
            </StyledForm>
          </Spin>
        )
      }}
    />
  )
}

ShopCouponsSettings.propTypes = {
  id: PropTypes.any,
  closeModal: PropTypes.func.isRequired,
}

ShopCouponsSettings.defaultTypes = {
  id: null,
}

export default ShopCouponsSettings
