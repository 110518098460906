import moment from 'moment'
import first from 'lodash/first'
import last from 'lodash/last'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import merge from 'lodash/merge'
import findIndex from 'lodash/findIndex'
import uniq from 'lodash/uniq'
import {
  CB_MAX_VALUE,
  KG_MAX_VALUE,
  ADDITIONAL_RETAILER_TYPE_FILTER,
  RETAILER_TYPE_FILTERS,
} from 'const/retailers'
import { SUCCESS_STATUSES } from 'const/api'
import get from 'lodash/get'
import { FIELD_NAMES, FORM_CHANGES_FIELD_PREFIX } from 'const/forms'

export const getRetailerTypeFilters = () => {
  const filters = [...RETAILER_TYPE_FILTERS]
  filters.splice(1, 0, ...ADDITIONAL_RETAILER_TYPE_FILTER)
  return filters
}

export const inputNumberValueParser = (value) => Math.min(CB_MAX_VALUE, ~~value)
export const inputNumberValueParserTagsCount = (value) => Math.max(~~value, 1)

export const inputKGValueParser = (value) => {
  const formattedValue = value.replace(/[^0-9.]/g, '')
  return formattedValue < KG_MAX_VALUE ? formattedValue : KG_MAX_VALUE
}

export const insertRegionalIds = (oldIds, idsToAdd, [mainId]) => {
  const startPosition = findIndex(oldIds, (id) => id === mainId)
  const newIds = [...oldIds]
  newIds.splice(startPosition + 1, 0, ...idsToAdd)
  return uniq(newIds)
}

export const mergeChanges = (savedChangesFromOtherPages, values, diff) => {
  const oldChanges = omit(savedChangesFromOtherPages, Object.keys(values || {}))
  return merge(oldChanges, diff)
}

export const getDirtyFieldsFromChanges = (changes) =>
  Object.keys(changes).reduce((result, key) => {
    Object.keys(changes[key]).forEach(
      (field) => (result[`${key}[${field}]`] = true)
    )
    return result
  }, {})

export const getSuccessIds = (results, requestData) =>
  results.reduce((result, item, index) => {
    if (SUCCESS_STATUSES.includes(item?.status)) {
      result.push({
        ...requestData[index],
        id:
          result[index]?.retailerId ||
          requestData[index]?.id ||
          requestData[index]?.data?.data?.id,
      })
    }
    return result
  }, [])

export const getRetailerIdsForUpdate = (data) =>
  uniq(data?.map((item) => item?.retailerId || item?.id || item.data?.data?.id))

export const isCBStatusDisabled = (values, id) => {
  const promoCb = get(
    values,
    `${FORM_CHANGES_FIELD_PREFIX}_${id}[${FIELD_NAMES.promoCb}]`
  )
  const promoCbStartDate = first(promoCb?.dates)
  const promoCbEndDate = last(promoCb?.dates)
  const promoPlusCb = get(
    values,
    `${FORM_CHANGES_FIELD_PREFIX}_${id}[${FIELD_NAMES.promoPlusCb}]`
  )
  const promoPlusCbFirstDate = first(promoPlusCb?.dates)
  const promoPlusCbEndDate = last(promoPlusCb?.dates)

  const sumOfCBs =
    get(values, `${FORM_CHANGES_FIELD_PREFIX}_${id}[${FIELD_NAMES.cb}]`) +
    get(values, `${FORM_CHANGES_FIELD_PREFIX}_${id}[${FIELD_NAMES.plusCb}]`)

  const isPromoCBValid =
    promoCb?.cb && promoCbStartDate
      ? promoCbEndDate
        ? moment(promoCbEndDate).isAfter()
        : true
      : false
  const isPromoPlusCBValid =
    promoPlusCb?.cb && promoPlusCbFirstDate
      ? promoPlusCbEndDate
        ? moment(promoPlusCbEndDate).isAfter()
        : true
      : false

  return !sumOfCBs && !isPromoCBValid && !isPromoPlusCBValid
}

export const mergeDirtyFields = (
  oldDirtyFields,
  actualFormDirtyFields,
  values
) =>
  merge(
    Object.keys(oldDirtyFields).reduce((result, key) => {
      if (get(values, key) !== undefined && !get(actualFormDirtyFields, key)) {
        return omit(result, key)
      }
      return result
    }, oldDirtyFields),
    actualFormDirtyFields
  )

export const filterDeletedRetailers = (retailers) =>
  omitBy(retailers, (retailer) => retailer.attributes[FIELD_NAMES.deleted])
