import { FIELD_NAMES } from 'const/forms'
import { mapFilters, mapSorters } from 'helpers/jsonAPI'
import { ENTITY_NAMES } from 'const/api'

export const formatRule = (rule) => {
  return {
    [FIELD_NAMES.id]: rule[FIELD_NAMES.id],
    key: rule[FIELD_NAMES.id],
    [FIELD_NAMES.createdAt]: rule.attributes[FIELD_NAMES.createdAt],
  }
}

export const formatRules = (rules = {}) =>
  Object.values(rules).reduce(
    (result, rule) => ({
      ...result,
      [rule[FIELD_NAMES.id]]: formatRule(rule),
    }),
    {}
  ) || {}

export const formatRuleSlug = (slug) => {
  return {
    [FIELD_NAMES.id]: slug[FIELD_NAMES.id],
    key: slug[FIELD_NAMES.id],
    [FIELD_NAMES.createdAt]: slug[FIELD_NAMES.createdAt],
    [FIELD_NAMES.updatedAt]: slug[FIELD_NAMES.updatedAt],
    [FIELD_NAMES.ruleSlug]: slug[FIELD_NAMES.ruleSlug],
    [FIELD_NAMES.ruleName]: slug[FIELD_NAMES.ruleName],
    [FIELD_NAMES.ruleId]: slug[FIELD_NAMES.ruleId],
  }
}

export const formatRuleSlugs = (slugs = []) =>
  slugs.reduce(
    (result, slug) => ({
      ...result,
      [slug[FIELD_NAMES.id]]: formatRuleSlug(slug),
    }),
    {}
  ) || {}

export const formatPayloadForFetchRequest = ({
  page,
  filters,
  sorter,
  search,
} = {}) => ({
  page: page || 1,
  filters: mapFilters(filters),
  sorter: mapSorters(sorter),
  search,
})

export const formatEntityForRequests = ({
  [FIELD_NAMES.id]: id,
  [FIELD_NAMES.createdAt]: createdAt,
  [FIELD_NAMES.updatedAt]: updatedAt,
  [FIELD_NAMES.ruleName]: ruleName,
  key,
  ...values
}) => ({
  ...(id && { id }),
  type: ENTITY_NAMES.ruleSlugs,
  attributes: {
    ...values,
  },
})
