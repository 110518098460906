import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import CheckboxIcon from 'components/CheckboxIcon'
import { formatDate } from '../../helpers/dates'
import {
  MAP_NOTIFICATION_TYPE_TO_USER_TEXT,
  STATUS_FILTERS,
} from '../../const/mobileApp'
import { formatFieldValue } from '../../helpers/formatters/fields'
import get from 'lodash/get'

export default (filters, sorter) => [
  {
    title: 'ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 110,
    fixed: 'left',
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Delivered',
    width: 200,
    align: 'center',
    dataIndex: FIELD_NAMES.delivered,
    key: FIELD_NAMES.delivered,
    filters: STATUS_FILTERS,
    filteredValue: filters[FIELD_NAMES.delivered] || null,
    render: (checked) => <CheckboxIcon checked={checked} />,
  },
  {
    title: 'Delivered At',
    dataIndex: FIELD_NAMES.deliveredAt,
    key: FIELD_NAMES.deliveredAt,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.deliveredAt && sorter.order,
    render: formatDate,
  },
  {
    title: 'User Email',
    dataIndex: FIELD_NAMES.userEmail,
    key: FIELD_NAMES.userEmail,
    align: 'center',
  },
  {
    title: 'User ID',
    dataIndex: FIELD_NAMES.userId,
    key: FIELD_NAMES.userId,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.userId && sorter.order,
  },
  {
    title: 'Type',
    width: 200,
    align: 'center',
    dataIndex: FIELD_NAMES.notificationData,
    key: FIELD_NAMES.type,
    // filters: NOTIFICATION_TYPE_OPTIONS,
    // filteredValue: filters[FIELD_NAMES.type] || null,
    render: (data) =>
      MAP_NOTIFICATION_TYPE_TO_USER_TEXT[
        get(
          JSON.parse(data),
          FIELD_NAMES.type,
          get(JSON.parse(data), [FIELD_NAMES.aps, FIELD_NAMES.type], '-')
        )
      ],
  },
  {
    title: 'Device Token',
    dataIndex: FIELD_NAMES.deviceToken,
    key: FIELD_NAMES.deviceToken,
    align: 'center',
    ellipsis: true,
    render: formatFieldValue,
  },
]
