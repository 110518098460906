import { FIELD_NAMES } from 'const/forms'
import { mapFilters, mapSorters } from 'helpers/jsonAPI'
import { ENTITY_NAMES } from 'const/api'
import { OFFER_TYPES } from 'const/coupons'

export const formatCoupon = (coupon) => {
  return {
    [FIELD_NAMES.id]: coupon[FIELD_NAMES.id],
    key: coupon[FIELD_NAMES.id],
    [FIELD_NAMES.code]: coupon.attributes[FIELD_NAMES.code],
    [FIELD_NAMES.description]: coupon.attributes[FIELD_NAMES.description],
    [FIELD_NAMES.createdAt]: coupon.attributes[FIELD_NAMES.createdAt],
    [FIELD_NAMES.expiredAt]: coupon.attributes[FIELD_NAMES.expiredAt],
    [FIELD_NAMES.startedAt]: coupon.attributes[FIELD_NAMES.startedAt],
    [FIELD_NAMES.offerType]: coupon.attributes[FIELD_NAMES.offerType],
    [FIELD_NAMES.ruleName]: coupon.attributes[FIELD_NAMES.ruleName],
    [FIELD_NAMES.source]: coupon.attributes[FIELD_NAMES.source],
    [FIELD_NAMES.ruleId]: coupon.attributes[FIELD_NAMES.ruleId],
    [FIELD_NAMES.successUseCount]:
      coupon.attributes[FIELD_NAMES.successUseCount],
    [FIELD_NAMES.lastSuccessUse]: coupon.attributes[FIELD_NAMES.lastSuccessUse],
    [FIELD_NAMES.totalUseCount]: coupon.attributes[FIELD_NAMES.totalUseCount],
    [FIELD_NAMES.rate]: coupon.attributes[FIELD_NAMES.rate],
    [FIELD_NAMES.mainRetailerId]: coupon.attributes[FIELD_NAMES.mainRetailerId],
    [FIELD_NAMES.lastSuccessPercent]:
      coupon.attributes[FIELD_NAMES.lastSuccessPercent],
    [FIELD_NAMES.minSuccessPercent]:
      coupon.attributes[FIELD_NAMES.minSuccessPercent],
    [FIELD_NAMES.maxSuccessPercent]:
      coupon.attributes[FIELD_NAMES.maxSuccessPercent],
    [FIELD_NAMES.retailerName]: coupon.attributes[FIELD_NAMES.retailerName],
    [FIELD_NAMES.priority]: coupon.attributes[FIELD_NAMES.priority],
    [FIELD_NAMES.geos]: coupon.attributes[FIELD_NAMES.geos],
    [FIELD_NAMES.comments]: coupon.attributes[FIELD_NAMES.comments],
    [FIELD_NAMES.url]: coupon.attributes[FIELD_NAMES.url],
    [FIELD_NAMES.salesRep]: coupon.attributes[FIELD_NAMES.salesRep],
    [FIELD_NAMES.exclusive]: coupon.attributes[FIELD_NAMES.exclusive],
    [FIELD_NAMES.exclusivePriority]:
      coupon.attributes[FIELD_NAMES.exclusivePriority],
    [FIELD_NAMES.temporaryPriorityUntil]:
      coupon.attributes[FIELD_NAMES.temporaryPriorityUntil],
  }
}

export const formatCoupons = (coupons = {}) =>
  Object.values(coupons).reduce(
    (result, coupon) => ({
      ...result,
      [coupon[FIELD_NAMES.id]]: formatCoupon(coupon),
    }),
    {}
  ) || {}

export const formatPayloadForFetchRequest = ({
  page,
  filters,
  sorter,
  search,
} = {}) => {
  const formatedPayload = {
    page: page || 1,
    filters: mapFilters(filters),
    sorter: mapSorters(sorter),
    search,
  }
  return formatedPayload
}

export const formatCouponForRequests = ({
  [FIELD_NAMES.id]: id,
  [FIELD_NAMES.ruleName]: rule,
  [FIELD_NAMES.retailerName]: retailer,
  [FIELD_NAMES.description]: description,
  [FIELD_NAMES.offerType]: offerType,
  [FIELD_NAMES.priority]: priority,
  [FIELD_NAMES.geos]: geos,
  [FIELD_NAMES.exclusive]: exclusive,
  [FIELD_NAMES.url]: url,
  [FIELD_NAMES.salesRep]: salesRep,
  [FIELD_NAMES.comments]: comments,
  key,
  ...values
}) => {
  const isOfferTypeCoupons = offerType === OFFER_TYPES[0].value
  return {
    ...(id && { id }),
    type: ENTITY_NAMES.coupons,
    attributes: {
      ...(!id && { source: 'backoffice' }),
      description: description || null,
      [FIELD_NAMES.offerType]: offerType,
      [FIELD_NAMES.priority]: isOfferTypeCoupons ? priority : null,
      [FIELD_NAMES.geos]: isOfferTypeCoupons ? geos : null,
      [FIELD_NAMES.exclusive]: isOfferTypeCoupons ? exclusive : false,
      [FIELD_NAMES.url]: isOfferTypeCoupons ? url : null,
      [FIELD_NAMES.salesRep]: isOfferTypeCoupons ? salesRep : null,
      [FIELD_NAMES.comments]: isOfferTypeCoupons ? comments : null,
      ...values,
    },
  }
}
