import { JAVA_FIELD_NAMES } from 'const/forms'

export const transactionsDetailsColumns = () => [
  {
    title: 'Code',
    dataIndex: JAVA_FIELD_NAMES.code,
    key: JAVA_FIELD_NAMES.code,
  },
  {
    title: 'Name',
    dataIndex: JAVA_FIELD_NAMES.name,
    key: JAVA_FIELD_NAMES.name,
  },
  {
    title: 'Unit Price',
    dataIndex: JAVA_FIELD_NAMES.unitPrice,
    key: JAVA_FIELD_NAMES.unitPrice,
    render: (value, data) =>
      value.toLocaleString('en-US', {
        style: 'currency',
        currency: data[JAVA_FIELD_NAMES.currency] || 'USD',
      }),
  },
  {
    title: 'Qty',
    dataIndex: JAVA_FIELD_NAMES.quantity,
    key: JAVA_FIELD_NAMES.quantity,
  },
  {
    title: 'SKU',
    dataIndex: JAVA_FIELD_NAMES.sku,
    key: JAVA_FIELD_NAMES.sku,
  },
  {
    title: 'Category',
    dataIndex: JAVA_FIELD_NAMES.category,
    key: JAVA_FIELD_NAMES.category,
  },
]
