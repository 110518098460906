import React, { useState } from 'react'
import { Input, Button } from 'antd'
import useAction from 'utils/useAction'
import { openConfirmModal } from 'reducers/modals/actions'
import {
  Aside,
  DeleteButton,
  EditItemWrapper,
  AddItemWrapper,
  Wrapper,
  Content,
  List,
  StyledCol,
} from './Categories.styles'
import Category from './Category'

const CategoriesComponent = ({
  addMethod,
  editMethod,
  deleteMethod,
  data,
  currentNames,
}) => {
  const [name, nameSet] = useState('')
  const [curData, curDataSet] = useState('')
  const [curDataID, curDataIDSet] = useState('')
  const [curDataEdit, curDataEditSet] = useState('')
  const openConfirmModalFunc = useAction(openConfirmModal)

  const onNameChange = (e) => {
    nameSet(e.target.value)
  }

  const addItem = () => {
    addMethod({ name })
    nameSet('')
  }

  const deleteItem = (id) => deleteMethod(id)

  const handleDelete = () => {
    const category = data.find(({ attributes }) => attributes.name === curData)
    const callback = () => {
      deleteItem(category.id)
      curDataSet('')
    }
    const text =
      `Are you sure you want to delete the` +
      `${curData} category? This category will be reset for all retailers.`
    openConfirmModalFunc({ callback, text })
  }

  const changeCategoryName = () =>
    editMethod({
      id: curDataID,
      name: curDataEdit,
      callback: () => {
        curDataSet('')
      },
    })

  const onKeyPressHandler = (e) => {
    if (e.key === 'Enter') {
      changeCategoryName()
    }
  }

  const handleSelect = (value) => {
    curDataSet(value)
    curDataEditSet(value)
    data.forEach((item) => {
      if (item.attributes.name === value) {
        curDataIDSet(item.id)
      }
    })
  }

  return (
    <Wrapper>
      <Content>
        <StyledCol>
          <List>
            {data.map((item) => (
              <Category
                key={item.id}
                onClick={handleSelect}
                label={item.attributes.name}
              />
            ))}
          </List>
          <AddItemWrapper>
            <Input
              style={{ marginRight: 10 }}
              value={name}
              onChange={onNameChange}
            />
            <Button onClick={addItem} type="primary">
              Add category
            </Button>
          </AddItemWrapper>
        </StyledCol>
        <Aside>
          {curData && (
            <EditItemWrapper>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  onChange={(e) => curDataEditSet(e.target.value)}
                  onKeyPress={onKeyPressHandler}
                  value={curDataEdit}
                  style={{ marginRight: 10 }}
                />
                <Button onClick={changeCategoryName} type="primary">
                  Save
                </Button>
              </div>
              <DeleteButton
                onClick={handleDelete}
                type="danger"
              >{`Delete ${curData}`}</DeleteButton>
            </EditItemWrapper>
          )}
        </Aside>
      </Content>
    </Wrapper>
  )
}

export default CategoriesComponent
