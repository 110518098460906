import React, { useContext } from 'react'
import { Divider } from 'antd'
import { Field } from 'react-final-form'
import { MinusCircleOutlined } from '@ant-design/icons'
import { actions as featuresActions } from 'domains/features'
import useAction from 'utils/useAction'
import Modal from 'components/Modal/Modal'
import { chooseComponent } from './utils.js'
import './index.css'

const Options = ({ feature, name }) => {
  const modal = useContext(Modal.Context)
  const deleteFeatureConfigurationOption = useAction(
    featuresActions.deleteFeatureConfigurationOptionRequest
  )

  return (
    <>
      {feature?.feature_configuration_options?.map((option, index) => (
        <React.Fragment key={option.id}>
          {index === 0 && <Divider>Options</Divider>}
          <Field
            hint={option.description}
            key={option.name}
            name={`${name}.fields_configuration.${option.name}`}
            options={option.custom_enum}
            label={option.name.split('_').join(' ')}
            component={chooseComponent(option.field_type)}
          />
          <MinusCircleOutlined
            className="options-circle-outlined"
            onClick={() => {
              deleteFeatureConfigurationOption(option.id, modal.data.id)
            }}
          />
        </React.Fragment>
      ))}
    </>
  )
}

export default Options
