import { all, takeLatest, put, take } from 'redux-saga/effects'
import * as actions from 'actions/views/linkConverter'
import { actions as generalActions } from 'domains/general'

function* convertLinkWorker({ payload }) {
  try {
    yield put(generalActions.convertLink(payload))
    yield take([
      generalActions.convertLinkSuccess,
      generalActions.convertLinkFailure,
    ])
  } catch (error) {
    console.error(error)
  }
}

export default function* () {
  yield all([takeLatest(actions.convertLink, convertLinkWorker)])
}
