import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import { formatDate } from 'helpers/dates'
import Status from 'components/Status'
import { ATTEMPT_STATUS_FILTERS } from 'const/payments'

export default (filters, sorter) => [
  {
    title: 'Status',
    dataIndex: FIELD_NAMES.status,
    key: FIELD_NAMES.status,
    width: 100,
    fixed: 'left',
    align: 'center',
    filters: ATTEMPT_STATUS_FILTERS,
    filteredValue: filters[FIELD_NAMES.status] || null,
    render: (status) => <Status value={status} />,
  },
  {
    title: 'Attempt ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 100,
    fixed: 'left',
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Created at',
    dataIndex: FIELD_NAMES.createdAt,
    key: FIELD_NAMES.createdAt,
    width: 150,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.createdAt && sorter.order,
    render: formatDate,
  },
  {
    title: 'Created by',
    dataIndex: FIELD_NAMES.adminName,
    key: FIELD_NAMES.adminName,
    width: 150,
    align: 'center',
  },
  {
    title: 'CSV Url',
    dataIndex: FIELD_NAMES.csvLink,
    key: FIELD_NAMES.csvLink,
    width: 150,
    ellipsis: true,
    align: 'center',
    render: (url, { [FIELD_NAMES.id]: id }) => (
      <a target="_blank" rel="noopener noreferrer" download={id} href={url}>
        {url}
      </a>
    ),
  },
]
