import React from 'react'
import { Input as AntInput } from 'antd'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'

const TextArea = ({
  input,
  meta,
  label,
  description,
  hint,
  required,
  ...rest
}) => (
  <FieldWrapper hint={hint} label={label} meta={meta} required={required}>
    <AntInput.TextArea
      placeholder={description}
      onChange={input.onChange}
      type={`${input.type || 'text'}`}
      {...input}
      {...rest}
    />
  </FieldWrapper>
)

export default TextArea
