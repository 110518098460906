import reducer from './reducer'
import { createAction } from '@reduxjs/toolkit'

export const {
  setUser,
  savePaymentTransactions,
  fetchEngagementMetricsSuccess,
  fetchEngagementMetricsFailure,
  resetSearch,
  resetSelectedEmail,
  saveSelectedEmail,
  resetAllTransactions,
  fetchUsersSuccess,
  fetchUsersFailure,
  saveFiltersAndSorter,
  startSaving,
  finishSaving,
  clearData,
  setLoading,
  fetchBonusesForUserSuccess,
  fetchBonusesForUserFailure,
} = reducer.actions

export const fetchPaymentTransactions = createAction(
  'user/fetchPaymentTransactions'
)

export const fetchEngagementMetrics = createAction(
  'user/fetchEngagementMetrics'
)
export const fetchUserDataByEmail = createAction('user/fetchUserDataByEmail')

export const deleteUser = createAction('user/deleteUser')
export const deleteUserSuccess = createAction('user/deleteUserSuccess')
export const deleteUserFailure = createAction('user/deleteUserFailure')

export const verifyUserPayPal = createAction('user/verifyUserPayPal')
export const verifyUserPayPalSuccess = createAction(
  'user/verifyUserPayPalSuccess'
)
export const verifyUserPayPalFailure = createAction(
  'user/verifyUserPayPalFailure'
)

export const confirmPayPal = createAction('user/confirmPayPal')
export const confirmPayPalSuccess = createAction('user/confirmPayPalSuccess')
export const confirmPayPalFailure = createAction('user/confirmPayPalFailure')

export const updateUser = createAction('user/updateUser')
export const updateUserSuccess = createAction('user/updateUserSuccess')
export const updateUserFailure = createAction('user/updateUserFailure')

export const mergeUser = createAction('user/mergeUser')
export const mergeUserSuccess = createAction('user/mergeUserSuccess')
export const mergeUserFailure = createAction('user/mergeUserFailure')

export const resetUserPassword = createAction('user/resetUserPassword')
export const resetUserPasswordSuccess = createAction(
  'user/resetUserPasswordSuccess'
)
export const resetUserPasswordFailure = createAction(
  'user/resetUserPasswordFailure'
)

export const unblockUserPayment = createAction('user/unblockUserPayment')
export const unblockUserPaymentSuccess = createAction(
  'user/unblockUserPaymentSuccess'
)
export const unblockUserPaymentFailure = createAction(
  'user/unblockUserPaymentFailure'
)

export const sendSMSVerification = createAction('user/sendSMSVerification')
export const sendSMSVerificationSuccess = createAction(
  'user/sendSMSVerificationSuccess'
)
export const sendSMSVerificationFailure = createAction(
  'user/sendSMSVerificationFailure'
)

export const unsubscribeUser = createAction('user/unsubscribeUser')
export const unsubscribeUserSuccess = createAction(
  'user/unsubscribeUserSuccess'
)
export const unsubscribeUserFailure = createAction(
  'user/unsubscribeUserFailure'
)

export const fetchUsers = createAction('user/fetchUsers')
export const fetchBonusesForUser = createAction('user/fetchBonusesForUser')
