import React from 'react'
import { useSelector } from 'react-redux'
import {
  getFilters,
  getIsDataFilteredOrSorted,
  getIsLoaded,
  getPage,
  getPageCount,
  getSearchString,
  getSorter,
  getSortedMobileNotifications,
} from 'domains/mobileApp/selectors'
import useAction from 'utils/useAction'
import { fetchMobileNotifications } from 'actions/views/mobileNotifications'
import { StyledTable, Wrapper, Buttons } from './MobileNotifications.styles'
import { Button } from 'antd'
import getColumns from './columns'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import { openMobileNotificationModal } from 'reducers/modals/actions'
import isEqual from 'lodash/isEqual'
import SearchInTableBar from 'components/SearchInTableBar'
import MobileNotification from './MobileNotification'

const PAGE_SIZE = 20
const SCROLL = { x: 900 }
export const HEADER_OFFSET = {
  offsetHeader: 49,
}

const expandable = {
  expandRowByClick: true,
  expandedRowRender: (record) => <MobileNotification data={record} />,
}

const MobileNotifications = () => {
  const isLoaded = useSelector(getIsLoaded)
  const page = useSelector(getPage)
  const search = useSelector(getSearchString)
  const pageCount = useSelector(getPageCount)
  const filters = useSelector(getFilters)
  const sorter = useSelector(getSorter)
  const dataSource = useSelector(getSortedMobileNotifications)
  const isDataFilteredOrSorted = useSelector(getIsDataFilteredOrSorted)

  const fetchMobileNotificationsFunc = useAction(fetchMobileNotifications)
  const openMobileNotificationModalFunc = useAction(openMobileNotificationModal)

  const handleCreateMobileNotification = () => openMobileNotificationModalFunc()

  const onTableChange = (pagination, newFilters, newSorter) => {
    const pageChanged = page !== pagination.current
    const newFiltersOrSorter =
      !pageChanged &&
      (!isEqual(newFilters, filters) || !isEqual(newSorter, sorter))
    fetchMobileNotificationsFunc({
      page: newFiltersOrSorter ? 1 : pagination.current,
      search,
      filters: newFilters,
      sorter: newSorter,
    })
  }
  const onSearchStringChange = (search) =>
    fetchMobileNotificationsFunc({
      page: 1,
      search,
      filters,
      sorter,
    })

  const handleResetFilters = () =>
    fetchMobileNotificationsFunc({
      search,
      page: 1,
      filters: {},
      sorter: {},
    })

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  return (
    <Wrapper>
      <SearchInTableBar
        onSubmit={onSearchStringChange}
        value={search}
        placeholder="Search notification here"
      >
        <Buttons>
          <Button
            onClick={handleCreateMobileNotification}
            type="primary"
            disabled={!isLoaded}
          >
            Create
          </Button>
          <Button
            onClick={handleResetFilters}
            disabled={!isDataFilteredOrSorted}
          >
            Reset filters
          </Button>
        </Buttons>
      </SearchInTableBar>
      <StyledTable
        onChange={onTableChange}
        showSorterTooltip={false}
        columns={getColumns(filters, sorter)}
        dataSource={dataSource}
        expandable={expandable}
        pagination={paginationConfig}
        sticky={isLoaded && HEADER_OFFSET}
        scroll={SCROLL}
        bordered
        loading={false}
        rowKey={getRowKey}
        rowClassName={getRegularRowClass}
      />
    </Wrapper>
  )
}

export default MobileNotifications
