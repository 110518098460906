import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'

import {
  createAdPackageAssetData,
  denormalizeAdPackage,
  denormalizeAdPackageAsset,
  normalizeAdPackages,
} from './helpers'
import * as api from './api'
import { DEFAULT_MEDIA_TYPE } from 'const/adPackage'
import { mapFilters, mapSorters } from 'helpers/filters'
import { fetchShopAdPackages } from 'actions/views/shops'
import { getCurrentShopId } from 'domains/shops/selectors'

function* fetchAdPackagesWorker({ payload = {} }) {
  try {
    const { data } = yield call(api.getAdPackages, {
      ...payload,
      filters: mapFilters(payload.filters),
      sorter: mapSorters(payload.sorter),
    })

    const adPackages = normalizeAdPackages(data)

    yield put(
      actions.fetchAdPackagesSuccess({
        adPackages,
        search: payload?.search,
        pageCount: data.meta.page_count,
        page: payload.page,
        filters: payload.filters,
        sorter: payload.sorter,
      })
    )
  } catch (e) {
    yield put(actions.fetchAdPackagesFailure(e))
  }
}

function* deleteAdPackageWorker({ payload, promise }) {
  try {
    const { id } = payload
    yield call(api.deleteAdPackage, id)

    yield put(actions.deleteAdPackageSuccess(id))

    yield call(promise.resolve)
  } catch (error) {
    yield call(promise.resolve, error?.response?.data)
  }
}

function* duplicateAdPackageWorker({ payload, promise }) {
  try {
    const { id } = payload
    yield call(api.duplicateAdPackage, id)

    yield put(actions.duplicateAdPackageSuccess(id))

    yield call(promise.resolve)
  } catch (error) {
    yield call(promise.resolve, error?.response?.data)
  }
}

function* createAdPackageWorker({ payload, promise }) {
  try {
    const { adPackage } = payload
    const {
      dashboard_image_h2_text,
      dashboard_image_h1_text,
      email_banner,
      email_banner_text,
      email_banner_link,
      sales_web_1,
      sales_web_2,
      sales_mobile_1,
      sales_mobile_2,
      sales_mobile_see_all_1,
      sales_mobile_see_all_2,
      media_type,
      ...destructuredAdPackage
    } = adPackage

    let denormalizedAdPackageAsset = createAdPackageAssetData({
      dashboard_image_h2_text,
      dashboard_image_h1_text,
      email_banner,
      email_banner_text,
      email_banner_link,
    })
    const response = yield call(
      api.createAdPackageAsset,
      denormalizedAdPackageAsset
    )
    const assetId = response.data.data.id

    let denormalizedAdPackage = denormalizeAdPackage({
      ...destructuredAdPackage,
      assetId,
      media_type: media_type === DEFAULT_MEDIA_TYPE ? null : media_type,
    })

    const { data } = yield call(api.createAdPackage, denormalizedAdPackage)
    const adPackages = normalizeAdPackages(data)

    yield put(actions.createAdPackageSuccess(adPackages[0]))

    let denormalizedAdPackageImages = denormalizeAdPackage({
      id: adPackages[0].id,
      sales_web_1,
      sales_web_2,
      sales_mobile_1,
      sales_mobile_2,
      sales_mobile_see_all_1,
      sales_mobile_see_all_2,
    })

    const { data: updatedPackages } = yield call(
      api.updateAdPackage,
      denormalizedAdPackageImages
    )
    const adPackagesWithImages = normalizeAdPackages(updatedPackages)

    yield put(actions.updateAdPackageSuccess(adPackagesWithImages[0]))

    yield call(promise.resolve)
  } catch (error) {
    yield call(promise.resolve, error?.response?.data)
  }
}

function* updateAdPackageWorker({ payload, promise }) {
  try {
    const { adPackage } = payload
    const {
      dashboard_image_h2_text,
      dashboard_image_h1_text,
      ad_package_asset_id,
      email_banner,
      email_banner_link,
      email_banner_text,
      media_type,
      ...destructuredAdPackage
    } = adPackage

    if (ad_package_asset_id) {
      let denormalizedAdPackageAsset = denormalizeAdPackageAsset({
        dashboard_image_h2_text,
        dashboard_image_h1_text,
        ad_package_asset_id,
        email_banner,
        email_banner_text,
        email_banner_link,
      })
      yield call(api.updateAdPackageAsset, denormalizedAdPackageAsset)
    }

    let denormalizedAdPackage = denormalizeAdPackage({
      ...destructuredAdPackage,
      assetId: ad_package_asset_id,
      media_type: media_type === DEFAULT_MEDIA_TYPE ? null : media_type,
    })

    const { data } = yield call(api.updateAdPackage, denormalizedAdPackage)
    const adPackages = normalizeAdPackages(data)

    yield put(actions.updateAdPackageSuccess(adPackages[0]))

    yield call(promise.resolve)

    const currentShopId = yield select(getCurrentShopId)
    if (currentShopId) yield put(fetchShopAdPackages(currentShopId))
  } catch (error) {
    yield call(promise.resolve, error?.response?.data)
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchAdPackagesRequest, fetchAdPackagesWorker),
    takeLatest(actions.updateAdPackageRequest, updateAdPackageWorker),
    takeLatest(actions.createAdPackageRequest, createAdPackageWorker),
    takeLatest(actions.deleteAdPackageRequest, deleteAdPackageWorker),
    takeLatest(actions.duplicateAdPackageRequest, duplicateAdPackageWorker),
  ])
}
