import React from 'react'
import PropTypes from 'prop-types'
import { Action, Actions } from './ModalActions.styles'

const ModalActions = ({
  isCancelDisabled,
  isConfirmDisabled,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  ...rest
}) => {
  return (
    <Actions {...rest}>
      <Action disabled={isCancelDisabled} size="default" onClick={onCancel}>
        {cancelText}
      </Action>

      <Action
        disabled={isConfirmDisabled}
        size="default"
        type="primary"
        onClick={onConfirm}
      >
        {confirmText}
      </Action>
    </Actions>
  )
}

ModalActions.propTypes = {
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  isCancelDisabled: PropTypes.bool,
  isConfirmDisabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

ModalActions.defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Save changes',
  cancelDisabled: false,
  submitDisabled: false,
}

export default ModalActions
