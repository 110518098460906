import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import ButtonGroup from './ButtonGroup'
import Search from './Search'
import CheckboxIcon from './CheckboxIcon'
import { PLATFORM_FILTERS, STATUS_FILTERS, BETA } from 'const/features'
import { FIELD_NAMES } from 'const/forms'
import { formatDate, sortDates } from 'helpers/dates'
import { formatFieldValue } from 'helpers/formatters/fields'

export const getColumns = (filters, sorter) => [
  {
    title: 'ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    fixed: 'left',
    width: 80,
    sorter: (a, b) => a[FIELD_NAMES.id] - b[FIELD_NAMES.id],
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Feature name',
    dataIndex: FIELD_NAMES.name,
    key: FIELD_NAMES.name,
    fixed: 'left',
    width: 200,
    filteredValue: filters[FIELD_NAMES.name] || null,
    sortOrder: sorter.columnKey === FIELD_NAMES.name && sorter.order,
    sorter: (a, b) => a[FIELD_NAMES.name].localeCompare(b.name),
    filterIcon: (filtered) => (
      <SearchOutlined color={filtered ? 'blue' : undefined} />
    ),
    onFilter: (value, record) =>
      record[FIELD_NAMES.name]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    filterDropdown: (props) => <Search {...props} />,
  },
  {
    sortOrder: sorter.columnKey === FIELD_NAMES.slug && sorter.order,
    sorter: (a, b) => a[FIELD_NAMES.slug].localeCompare(b[FIELD_NAMES.slug]),
    title: 'Slug',
    width: 200,
    dataIndex: FIELD_NAMES.slug,
    key: FIELD_NAMES.slug,
  },
  {
    width: 90,
    align: 'center',
    title: 'Modulo',
    dataIndex: FIELD_NAMES.modulo,
    key: FIELD_NAMES.modulo,
    sortOrder: sorter.columnKey === FIELD_NAMES.modulo && sorter.order,
    sorter: (a, b) => a[FIELD_NAMES.modulo] - b[FIELD_NAMES.modulo],
  },
  {
    width: 100,
    filters: PLATFORM_FILTERS,
    filteredValue: filters[FIELD_NAMES.platform] || null,
    onFilter: (value, record) => record[FIELD_NAMES.platform] === value,
    title: 'Platform',
    dataIndex: FIELD_NAMES.platform,
    key: FIELD_NAMES.platform,
  },
  {
    width: 100,
    filters: STATUS_FILTERS,
    filteredValue: filters[FIELD_NAMES.active] || null,
    onFilter: (value, record) => record[FIELD_NAMES.active] === value,
    title: 'Active',
    dataIndex: FIELD_NAMES.active,
    render: (checked) => <CheckboxIcon checked={checked} />,
    align: 'center',
    key: FIELD_NAMES.active,
  },
  {
    width: 100,
    filters: BETA,
    filteredValue: filters[FIELD_NAMES.beta] || null,
    onFilter: (value, record) => record[FIELD_NAMES.beta] === value,
    title: 'Beta',
    dataIndex: FIELD_NAMES.beta,
    render: (checked) => <CheckboxIcon checked={checked} />,
    align: 'center',
    key: FIELD_NAMES.beta,
  },
  {
    width: 100,
    filters: STATUS_FILTERS,
    filteredValue: filters[FIELD_NAMES.activeForAll] || null,
    onFilter: (value, record) => record[FIELD_NAMES.activeForAll] === value,
    title: 'Active For All',
    dataIndex: FIELD_NAMES.activeForAll,
    render: (checked) => <CheckboxIcon checked={checked} />,
    align: 'center',
    key: FIELD_NAMES.activeForAll,
  },
  {
    width: 160,
    align: 'center',
    title: 'Created at',
    dataIndex: FIELD_NAMES.createdAt,
    key: FIELD_NAMES.createdAt,
    sorter: sortDates(FIELD_NAMES.createdAt),
    sortOrder: sorter.columnKey === FIELD_NAMES.createdAt && sorter.order,
    render: formatDate,
  },
  {
    width: 160,
    align: 'center',
    title: 'Updated at',
    dataIndex: FIELD_NAMES.updatedAt,
    key: FIELD_NAMES.updatedAt,
    sorter: sortDates(FIELD_NAMES.updatedAt),
    sortOrder: sorter.columnKey === FIELD_NAMES.updatedAt && sorter.order,
    render: formatDate,
  },
  {
    width: 160,
    align: 'center',
    title: 'Updated by',
    dataIndex: FIELD_NAMES.updatedBy,
    key: FIELD_NAMES.updatedBy,
    filteredValue: filters[FIELD_NAMES.updatedBy] || null,
    filterIcon: (filtered) => (
      <SearchOutlined color={filtered ? 'blue' : undefined} />
    ),
    onFilter: (value, record) =>
      record[FIELD_NAMES.updatedBy]
        ?.toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    filterDropdown: (props) => <Search {...props} />,
    render: formatFieldValue,
  },
  {
    fixed: 'right',
    width: 100,
    title: 'Actions',
    key: 'actions',
    render: (_, { id, name }) => <ButtonGroup id={id} name={name} />,
    align: 'center',
  },
]
