import React from 'react'
import { StyledSelect } from './Select.styles'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'

const MultipleSelect = ({
  required,
  input: { value, onChange },
  meta,
  ...rest
}) => (
  <FieldWrapper label={rest.label} required={required}>
    <StyledSelect
      invalid={meta.modified && meta.error}
      onChange={onChange}
      mode="tags"
      placeholder="Type Options"
      value={value || []}
    ></StyledSelect>
  </FieldWrapper>
)

export default MultipleSelect
