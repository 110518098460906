import { all, put, take, takeLatest, race } from 'redux-saga/effects'

import { startLoading, finishLoading } from 'reducers/layoutSlice'
import { actions as developersActions } from 'domains/developers'
import * as actions from 'actions/views/developers'

function* fetchDevelopersWorker({ payload } = {}) {
  try {
    yield all([
      put(startLoading()),
      put(developersActions.fetchDevelopers({ ...payload })),
    ])

    yield race([
      take(developersActions.fetchDevelopersSuccess),
      take(developersActions.fetchDevelopersFailure),
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* fetchCSVWorker({ payload } = {}) {
  try {
    yield put(startLoading())
    yield put(developersActions.fetchCSV(payload))
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

export default function* () {
  yield all([
    fetchDevelopersWorker(),
    takeLatest(actions.fetchDevelopers, fetchDevelopersWorker),
    takeLatest(actions.fetchCSV, fetchCSVWorker),
  ])
}
