import React from 'react'
import DNDFilePicker from 'components/DNDFilePicker'
import useAction from 'utils/useAction'
import * as paymentsAction from 'actions/views/payments'
import {
  Wrapper,
  Budget,
  Title,
  RemainingAmount,
  TotalAmount,
} from './PaymentsUpload.styles'
import { useSelector } from 'react-redux'
import { selectors as paymentsSelectors } from 'domains/payments'
import { Row } from 'antd'

const CSV = ['text/csv']

const PaymentsUpload = () => {
  const uploadPaymentsFunc = useAction(paymentsAction.uploadPaymentList)
  const monthlyBudget = useSelector(paymentsSelectors.getMonthlyBudget)
  const remainingBudget = useSelector(paymentsSelectors.getRemainingBudget)

  const low = monthlyBudget && remainingBudget < 100

  return (
    <Wrapper>
      <DNDFilePicker onUpload={uploadPaymentsFunc} accept={CSV} />
      <Budget>
        <Title>Budget</Title>
        <Row>
          <RemainingAmount $low={low}>{remainingBudget}$</RemainingAmount>/
          <TotalAmount>{monthlyBudget}$</TotalAmount>
        </Row>
      </Budget>
    </Wrapper>
  )
}

export default PaymentsUpload
