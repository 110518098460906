import axios from 'axios'
import paramsSerializer from 'helpers/paramsSerializer'
import { JAVA_API_URL } from '../const/env'

export const javaWithCookie = axios.create({
  baseURL: JAVA_API_URL,
  withCredentials: true,
})

const javaGetCookie = axios.create({
  baseURL: JAVA_API_URL,
  paramsSerializer,
  withCredentials: true,
})

javaGetCookie.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
    }

    return config
  },
  (error) => Promise.reject(error)
)

export const javaWithCookieInstance = javaWithCookie
export const javaGetCookieInstance = javaGetCookie
