// fully transfered from antd
// should be rewrited
import React, { useRef, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { Col, Row, Spin } from 'antd'
import { Field } from 'react-final-form'
import { apiInstance } from 'utils/request'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'
import debounce from 'lodash/debounce'
import { requiredNested } from 'helpers/validators'
import { StyledSelect } from './SearchRetailers.styles'
import { FIELD_NAMES } from 'const/forms'
import {
  AVAILABLE_RETAILER_TYPES_FOR_AD_PACKAGES,
  RETAILER_TYPES_IN_ATTRS,
} from 'const/retailers'
import { ENTITY_NAMES } from '../../../../const/api'
import PackageRegions from '../../../PackageRegions'

const { Option } = StyledSelect

const SearchRetailers = ({ isGlobalSelected }) => {
  const lastFetchId = useRef(0)
  const [data, setData] = useState([])
  const [fetching, setFetching] = useState([])
  const [isGlobal, setIsGlobal] = useState(isGlobalSelected)

  const fetchRetailers = (value) => {
    lastFetchId.current += 1
    const fetchId = lastFetchId.current
    setData([])
    setFetching(true)
    apiInstance
      .get(
        '/retailers' +
          '?page[number]=1' +
          '&page[size]=20' +
          `&filter[type]=${AVAILABLE_RETAILER_TYPES_FOR_AD_PACKAGES}` +
          `&filter[${FIELD_NAMES.deleted}]=false` +
          '&filter[search]=' +
          value
      )
      .then((response) => response.data.data)
      .then((body) => {
        if (fetchId !== lastFetchId.current) {
          return
        }
        const data = body
          .filter((item) => !isEmpty(item))
          .map((item) => ({
            value: item.id,
            isGlobal:
              item.attributes.type ===
              RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.main],
            id: item[FIELD_NAMES.id],
            label: `${item.attributes?.[FIELD_NAMES.name]}${
              item.attributes?.[FIELD_NAMES.countryCode]
                ? ` (${item.attributes?.[FIELD_NAMES.countryCode]})`
                : ''
            }`,
          }))
        setData(data)
        setFetching(false)
      })
  }

  const fetchRetailersFunc = debounce(fetchRetailers, 800)

  return (
    <Field name={FIELD_NAMES.retailer} validate={requiredNested}>
      {(props) => (
        <>
          <FieldWrapper label="Retailer" meta={props.meta} required>
            <StyledSelect
              invalid={props.meta.touched && props.meta.error}
              labelInValue
              value={props.input.value || undefined} // to fix bug with placeholder's displaying
              showSearch
              placeholder="Search Retailer"
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={fetchRetailersFunc}
              onFocus={() => fetchRetailers('')}
              onChange={(value) => {
                const item = data?.find((item) => item.value === value.value)
                setFetching(false)
                setIsGlobal(!!item?.isGlobal)
                setData([])
                props.input.onChange(value)
              }}
            >
              {data.map(({ value, label, id }) => (
                <Option key={value} value={value}>
                  <Row>
                    <Col span={3}>{id}</Col>
                    <Col span={21}>- {label}</Col>
                  </Row>
                </Option>
              ))}
            </StyledSelect>
          </FieldWrapper>
          <PackageRegions isDisabled={props.input.value && !isGlobal} />
        </>
      )}
    </Field>
  )
}

export default SearchRetailers
