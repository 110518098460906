import React from 'react'
import PropTypes from 'prop-types'
import { RestoreButton, DeleteButton } from './DeleteSwitch.styles'
import { Tooltip } from 'antd'

const DeleteSwitch = ({ input, disabled, restoreHandler, deleteHandler }) => {
  const Icon = input.value ? RestoreButton : DeleteButton
  const handleChange = () => {
    if (input.value) {
      restoreHandler()
    } else {
      deleteHandler()
    }
  }
  return (
    <Tooltip title={`Click to ${input.value ? 'restore' : 'delete'} this set`}>
      <Icon
        isDisabled={disabled}
        disabled={disabled}
        isLocked={disabled}
        onClick={handleChange}
      />
    </Tooltip>
  )
}

DeleteSwitch.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  restoreHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
}

DeleteSwitch.defaultProps = {
  disabled: false,
}

// ! Fixes tooltip visibility if HideSwitch disabled
DeleteSwitch.__ANT_BUTTON = true

export default DeleteSwitch
