import React from 'react'
import { useSelector } from 'react-redux'
import {
  getAllUsers,
  getFilters,
  getIsDataFilteredOrSorted,
  getIsLoaded,
  getPage,
  getPageCount,
  getSearchString,
  getSorter,
} from 'domains/user/selectors'
import useAction from 'utils/useAction'
import {
  deleteUser,
  fetchUsers,
  verifyUserPayPal,
  fetchBonusesForUser,
} from 'actions/views/users'
import { StyledTable, Wrapper, Buttons } from './Users.styles'
import SearchInTableBar from 'components/SearchInTableBar'
import { Button } from 'antd'
import getColumns from './columns'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import { FIELD_NAMES } from 'const/forms'
import { openConfirmModal, openUserBonusesModal } from 'reducers/modals/actions'
import isEqual from 'lodash/isEqual'

const PAGE_SIZE = 20
const SCROLL = { x: 900 }
export const HEADER_OFFSET = {
  offsetHeader: 49,
}

const Users = () => {
  const isLoaded = useSelector(getIsLoaded)
  const page = useSelector(getPage)
  const search = useSelector(getSearchString)
  const pageCount = useSelector(getPageCount)
  const filters = useSelector(getFilters)
  const sorter = useSelector(getSorter)
  const dataSource = useSelector(getAllUsers)
  const isDataFilteredOrSorted = useSelector(getIsDataFilteredOrSorted)

  const fetchUsersFunc = useAction(fetchUsers)
  const deleteUserFunc = useAction(deleteUser)
  const verifyUserPayPalFunc = useAction(verifyUserPayPal)
  const fetchBonusesForUserFunc = useAction(fetchBonusesForUser)
  const openConfirmModalFunc = useAction(openConfirmModal)
  const openUserBonusesModalFunc = useAction(openUserBonusesModal)

  const onSearchStringChange = (search) =>
    fetchUsersFunc({
      page: 1,
      search,
      filters,
      sorter,
    })
  const handleDeleteUser = (user) => {
    const callback = () => deleteUserFunc(user[FIELD_NAMES.id])
    const text =
      `Are you sure you want to delete user ${user[FIELD_NAMES.name]} ` +
      `(ID: ${user[FIELD_NAMES.id]}, email: ${user[FIELD_NAMES.email]})? ` +
      "This action can't be revoked."
    openConfirmModalFunc({ callback, text })
  }

  const handleVerifyUserPayPal = (user) => {
    const callback = () => verifyUserPayPalFunc(user[FIELD_NAMES.id])
    const text =
      `Are you sure you want to send PayPal verification email to the user ${
        user[FIELD_NAMES.name]
      } ` + `(ID: ${user[FIELD_NAMES.id]})? This action can't be revoked.`
    openConfirmModalFunc({ callback, text })
  }

  const handleBonusesClick = (user) => {
    fetchBonusesForUserFunc(user?.[FIELD_NAMES?.id])
    openUserBonusesModalFunc()
  }

  const onTableChange = (pagination, newFilters, newSorter) => {
    const pageChanged = page !== pagination.current
    const newFiltersOrSorter =
      !pageChanged &&
      (!isEqual(newFilters, filters) || !isEqual(newSorter, sorter))
    fetchUsersFunc({
      page: newFiltersOrSorter ? 1 : pagination.current,
      search,
      filters: newFilters,
      sorter: newSorter,
    })
  }

  const handleResetFilters = () =>
    fetchUsersFunc({
      search,
      page: 1,
      filters: {},
      sorter: {},
    })

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  return (
    <Wrapper>
      <SearchInTableBar
        placeholder="Search user here"
        onSubmit={onSearchStringChange}
        value={search}
      >
        <Buttons>
          <Button
            onClick={handleResetFilters}
            disabled={!isLoaded || !isDataFilteredOrSorted}
          >
            Reset filters
          </Button>
        </Buttons>
      </SearchInTableBar>
      <StyledTable
        onChange={onTableChange}
        showSorterTooltip={false}
        columns={getColumns(
          filters,
          sorter,
          handleDeleteUser,
          handleVerifyUserPayPal,
          handleBonusesClick
        )}
        dataSource={dataSource}
        expandable={false}
        pagination={paginationConfig}
        sticky={isLoaded && HEADER_OFFSET}
        scroll={SCROLL}
        bordered
        loading={false}
        rowKey={getRowKey}
        rowClassName={getRegularRowClass}
      />
    </Wrapper>
  )
}

export default Users
