import last from 'lodash/last'
import omit from 'lodash/omit'
import merge from 'lodash/merge'
import isObject from 'lodash/isObject'
import cloneDeep from 'lodash/cloneDeep'
import { ENTITY_NAMES } from 'const/api'
import {
  FIELD_NAMES,
  FIELDS_WITH_DATES,
  FORM_CHANGES_FIELD_PREFIX,
  GROUP_FIELD_NAME_PREFIX,
} from 'const/forms'
import { formatData, formatRetailerData } from 'domains/retailers/formatters'
import pickBy from 'lodash/pickBy'
import isEmpty from 'lodash/isEmpty'

export const formatGlobalChanges = ({
  data,
  fieldName,
  value,
  withCategories,
  activeChanges,
  retailerGroups,
  groups,
  retailerCategories,
  categories,
}) =>
  Object.values({
    ...data[ENTITY_NAMES.regional],
  }).reduce(
    (result, retailer) => ({
      ...result,
      changes: {
        ...result.changes,
        [`${FORM_CHANGES_FIELD_PREFIX}_${retailer.id}`]: {
          ...activeChanges?.[`${FORM_CHANGES_FIELD_PREFIX}_${retailer.id}`],
          [fieldName]: isObject(value)
            ? {
                ...activeChanges?.[
                  `${FORM_CHANGES_FIELD_PREFIX}_${retailer.id}`
                ]?.[fieldName],
                ...value,
              }
            : value,
        },
      },
      formatted: {
        ...result.formatted,
        [`${FORM_CHANGES_FIELD_PREFIX}_${retailer.id}`]: withCategories
          ? formatData({
              retailer,
              retailerGroups,
              groups,
              retailerCategories,
              categories,
            })
          : formatRetailerData(retailer),
      },
    }),
    { changes: {}, formatted: {} }
  )

export const filterAvailableChanges = (
  changes,
  fieldName,
  retailers,
  groups
) => {
  const filteredChanges = Object.entries(changes).reduce(
    (result, [key, retailer]) => {
      const data = retailers[key]
      const omitReasons = {
        [FIELD_NAMES.showOnUi]: !data[FIELD_NAMES.hasHeaderWebImage],
        [FIELD_NAMES.actionOnClick]: !data[FIELD_NAMES.hasHeaderWebImage],
        [FIELD_NAMES.maxKarmaGives]: !data[FIELD_NAMES.karmaGivesCanBeChanged],
      }
      let shouldOmitChanges = omitReasons[fieldName]
      if (
        shouldOmitChanges === undefined &&
        fieldName.startsWith(GROUP_FIELD_NAME_PREFIX)
      ) {
        const group = groups[last(fieldName.split('_'))]
        shouldOmitChanges =
          group?.meta?.slug === 'cashback'
            ? !(
                data[FIELD_NAMES.cbActive] &&
                data[FIELD_NAMES.hasHeaderWebImage]
              )
            : retailer[fieldName][FIELD_NAMES.position] &&
              !data[fieldName][FIELD_NAMES.active]
      }
      return shouldOmitChanges ? omit(result, `${key}.${fieldName}`) : result
    },
    changes
  )

  return pickBy(filteredChanges, (item) => !isEmpty(item))
}

export const formatDirtyFields = (changes, fieldName) =>
  Object.keys(changes).reduce((result, key) => {
    return {
      ...result,
      [`${key}[${fieldName}]`]: true,
    }
  }, {})

export const replaceDateValues = (changes, fieldName, value, initialValues) =>
  Object.keys(changes).reduce((result, item) => {
    result[item][fieldName] = {
      ...initialValues[item]?.[fieldName],
      ...value,
      dates: merge(
        [...(initialValues[item]?.[fieldName].dates || [])],
        value?.dates
      ),
    }
    return result
  }, cloneDeep(changes))

export const replaceGroupValues = (changes, fieldName, value) =>
  Object.keys(changes).reduce((result, item) => {
    result[item][fieldName] = {
      ...changes[item][fieldName],
      ...value,
      [FIELD_NAMES.position]: value[FIELD_NAMES.position] || 0,
    }
    return result
  }, cloneDeep(changes))

export const replaceDoubleFields = (
  fieldName,
  changes,
  value,
  changedRetailers
) => {
  let replacedChanges = changes
  if (FIELDS_WITH_DATES.includes(fieldName)) {
    replacedChanges = replaceDateValues(
      replacedChanges,
      fieldName,
      value,
      changedRetailers
    )
  }
  if (fieldName.startsWith(GROUP_FIELD_NAME_PREFIX)) {
    return replaceGroupValues(replacedChanges, fieldName, value)
  }
  return replacedChanges
}

export const getGlobalUpdateOptions = (retailer, fieldName) => ({
  fieldName,
  id: retailer[FIELD_NAMES.id],
  isGlobal: retailer.isGlobal,
  name: retailer[FIELD_NAMES.name],
  hasRegionalRetailers: retailer.hasRegionalRetailers,
})
