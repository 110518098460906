import { createSelector } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import memoize from 'lodash/memoize'
import {
  formatAppVersions,
  formatNotifications,
} from 'domains/mobileApp/formatters'
import { ENTITY_NAMES } from 'const/api'
import { areFiltersAndSorterEmpty } from 'helpers/filters'

export const mobileAppState = ({ mobileApp }) => mobileApp

export const getIsLoaded = createSelector(
  mobileAppState,
  ({ isLoaded }) => isLoaded
)

export const getIsSavingActive = createSelector(
  mobileAppState,
  ({ isSavingActive }) => isSavingActive
)

export const isLoadingOrSavingActive = createSelector(
  [getIsLoaded, getIsSavingActive],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getEntities = createSelector(
  mobileAppState,
  ({ entities }) => entities
)

export const getAppVersion = createSelector(getEntities, (entities) =>
  formatAppVersions(entities[ENTITY_NAMES.mobileAppVersions])
)

export const getNotifications = createSelector(getEntities, (entities) =>
  formatNotifications(entities[ENTITY_NAMES.mobileNotifications])
)

export const getAppVersionById = createSelector(getAppVersion, (appVersions) =>
  memoize((id) => (id ? appVersions[id] : null))
)

export const getSearchString = createSelector(
  mobileAppState,
  ({ search }) => search
)

export const getPage = createSelector(mobileAppState, ({ page }) => page)

export const getFilters = createSelector(
  mobileAppState,
  ({ filters }) => filters
)

export const getSorter = createSelector(mobileAppState, ({ sorter }) => sorter)

export const getIsDataFilteredOrSorted = createSelector(
  [getFilters, getSorter],
  areFiltersAndSorterEmpty
)

export const getPageCount = createSelector(
  mobileAppState,
  ({ pageCount }) => pageCount
)

export const getSortedIds = createSelector(
  mobileAppState,
  ({ sortedIds }) => sortedIds
)

export const getSortedAppVersions = createSelector(
  [getAppVersion, getSortedIds],
  (appVersions, ids) =>
    !isEmpty(appVersions) ? ids.map((id) => appVersions[id]) : []
)

export const getSortedMobileNotifications = createSelector(
  [getNotifications, getSortedIds],
  (notifications, ids) =>
    !isEmpty(notifications) ? ids.map((id) => notifications[id]) : []
)
