import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  entities: {},
  page: 1,
  pageCount: 1,
  isLoaded: false,
  isSavingActive: false,
  sorter: {},
  size: 20,
  search: '',
  select: null,
  filters: {},
  customers: [],
  networks: [],
}

const transactionsSlice = createSlice({
  name: 'paymentTransactions',
  initialState,
  reducers: {
    fetchPaymentTransactionsSuccess: (state, { payload }) => ({
      ...state,
      isLoaded: true,
      entities: {
        ...state.entities,
        paymentTransactions: payload.paymentTransactions,
      },
      page: payload.page,
      pageCount: payload.pageCount,
      networks: payload.networks,
      customers: payload.customers,
    }),
    fetchPaymentTransactionsFailure: (state) => state,
    fetchTransactionDetailsSuccess: (state, { payload }) => ({
      ...state,
      entities: {
        ...state.entities,
        transactionDetails: payload.transactionDetails,
      },
    }),
    fetchTransactionDetailsFailure: (state) => state,
    clearTransactionDetails: (state) =>
      (state.entities.transactionDetails =
        initialState.entities.transactionDetails),
    saveFiltersAndSorter: (state, { payload = {} }) => ({
      ...state,
      sorter: payload.sorter || initialState.sorter,
      filters: payload.filters || initialState.filters,
      size: payload.size || initialState.size,
      search: payload.search || initialState.search,
      select: payload.select || initialState.select,
    }),
    startSaving: (state) => ({
      ...state,
      isSavingActive: true,
    }),
    finishSaving: (state) => ({
      ...state,
      isSavingActive: false,
    }),
    clearData: () => initialState,
  },
})

export default transactionsSlice
