import styled from 'styled-components'
import { Button } from 'antd'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  flex-direction: column;
`

export const StyledButton = styled(Button)`
  width: 180px;
  &:first-child {
    margin-bottom: 5px;
  }
`
