import { all, put, take, takeLatest, race, select } from 'redux-saga/effects'

import { startLoading, finishLoading } from 'reducers/layoutSlice'
import {
  actions as appVersionsActions,
  formatters as appVersionsFormatters,
  selectors as appVersionsSelectors,
} from 'domains/mobileApp'
import * as actions from 'actions/views/mobileVersions'
import { FIELD_NAMES } from 'const/forms'

function* fetchAppVersionsWorker({ payload } = {}) {
  try {
    const search = yield select(appVersionsSelectors.getSearchString)
    const body = appVersionsFormatters.formatPayloadForFetchRequest({
      search,
      ...payload,
    })
    yield all([
      put(startLoading()),
      put(appVersionsActions.fetchAppVersions(body)),
    ])
    const [success] = yield race([
      take(appVersionsActions.fetchAppVersionsSuccess),
      take(appVersionsActions.fetchAppVersionsFailure),
    ])
    if (success) {
      yield put(appVersionsActions.saveFiltersAndSorter(payload))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* deleteAppVersionWorker({ payload }) {
  try {
    yield all([
      put(startLoading()),
      put(appVersionsActions.deleteAppVersion(payload)),
    ])
    const [success] = yield race([
      take(appVersionsActions.deleteAppVersionSuccess),
      take(appVersionsActions.deleteAppVersionFailure),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(appVersionsSelectors.getSearchString),
        select(appVersionsSelectors.getPage),
        select(appVersionsSelectors.getFilters),
        select(appVersionsSelectors.getSorter),
      ])
      yield put(
        actions.fetchAppVersions({
          search,
          page,
          filters,
          sorter,
        })
      )
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* createAppVersionWorker({ payload }) {
  try {
    const body = appVersionsFormatters.formatAppVersionForRequests(
      payload.values
    )
    yield all([
      put(appVersionsActions.startSaving()),
      put(appVersionsActions.createAppVersion(body)),
    ])
    const [success] = yield race([
      take(appVersionsActions.createAppVersionSuccess),
      take(appVersionsActions.createAppVersionFailure),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(appVersionsSelectors.getSearchString),
        select(appVersionsSelectors.getPage),
        select(appVersionsSelectors.getFilters),
        select(appVersionsSelectors.getSorter),
      ])
      yield put(
        actions.fetchAppVersions({
          search,
          page,
          filters,
          sorter,
        })
      )
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(appVersionsActions.finishSaving())
  }
}

function* updateAppVersionWorker({ payload }) {
  try {
    const body = appVersionsFormatters.formatAppVersionForRequests(
      payload.values
    )
    yield all([
      put(appVersionsActions.startSaving()),
      put(
        appVersionsActions.updateAppVersion({
          data: body,
          id: payload.values[FIELD_NAMES.id],
        })
      ),
    ])
    const [success] = yield race([
      take(appVersionsActions.updateAppVersionSuccess),
      take(appVersionsActions.updateAppVersionFailure),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(appVersionsSelectors.getSearchString),
        select(appVersionsSelectors.getPage),
        select(appVersionsSelectors.getFilters),
        select(appVersionsSelectors.getSorter),
      ])
      yield put(
        actions.fetchAppVersions({
          search,
          page,
          filters,
          sorter,
        })
      )
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(appVersionsActions.finishSaving())
  }
}

export default function* () {
  yield all([
    fetchAppVersionsWorker(),
    takeLatest(actions.fetchAppVersions, fetchAppVersionsWorker),
    takeLatest(actions.deleteAppVersion, deleteAppVersionWorker),
    takeLatest(actions.createAppVersion, createAppVersionWorker),
    takeLatest(actions.updateAppVersion, updateAppVersionWorker),
  ])
}
