import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Input, message } from 'antd'
import axios from 'axios'
import { BO_API_URL } from 'const/env'
import ListSort from './ListSort'
import {
  Aside,
  Content,
  Wrapper,
  DeleteButton,
  AddItemWrapper,
  EditItemWrapper,
  StyledCol,
} from './GroupComponent.styles'
import { SaveButton } from './ListSort/ListSort.styles'
import { ENTITY_NAMES } from 'const/api'
import useAction from 'utils/useAction'
import { openConfirmModal } from 'reducers/modals/actions'

const GroupComponent = ({ addMethod, editMethod, deleteMethod, data }) => {
  const dispatch = useDispatch()
  const [name, nameSet] = useState('')
  const [curData, curDataSet] = useState('')
  const [curDataID, curDataIDSet] = useState('')
  const [curDataEdit, curDataEditSet] = useState('')
  const openConfirmModalFunc = useAction(openConfirmModal)
  const currentNames = data ? data.map((item) => item.attributes.name) : []

  const onNameChange = (e) => {
    nameSet(e.target.value)
  }

  const addItem = () => {
    if (name.trim() && !currentNames.includes(name.trim())) {
      axios
        .post(
          `${BO_API_URL}/${ENTITY_NAMES.groups}`,
          {
            data: {
              type: ENTITY_NAMES.groups,
              attributes: {
                name: name.trim(),
              },
            },
          },
          { headers: { 'Content-Type': 'application/vnd.api+json' } }
        )
        .then((res) => {
          dispatch(addMethod(res.data.data))
          message.success(`Created ${name}`)
          nameSet('')
        })
        .catch((err) => {
          message.error(`Failed to create...`)
        })
    } else {
      message.error(`You must enter valid name or name already exist...`)
    }
  }

  const deleteItem = () => {
    data.forEach((item) => {
      if (item.attributes.name === curData) {
        axios
          .delete(`${BO_API_URL}/${ENTITY_NAMES.groups}/${item.id}`)
          .then(() => {
            message.success(`${curData} deleted`)
            curDataSet('')
            dispatch(deleteMethod(item.id))
          })
          .catch((err) => {
            console.error(err)
            message.error(`Failed to delete...`)
          })
      }
    })
  }

  const handleDelete = () => {
    const callback = () => {
      deleteItem()
    }
    const text = `Are you sure you want to delete the ${curData} group? This action can't be revoked.`
    openConfirmModalFunc({ callback, text })
  }

  const changeGroupName = () => {
    if (curDataID && !currentNames.includes(curDataEdit.trim())) {
      axios
        .patch(
          `${BO_API_URL}/${ENTITY_NAMES.groups}/${curDataID}`,
          {
            data: {
              id: curDataID,
              type: ENTITY_NAMES.groups,
              attributes: {
                name: curDataEdit.trim(),
              },
            },
          },
          { headers: { 'Content-Type': 'application/vnd.api+json' } }
        )
        .then((res) => {
          message.success(`${curDataEdit} saved`)
          curDataSet('')
          dispatch(editMethod(res.data.data))
        })
        .catch((err) => {
          message.error(`Failed to change name...`)
        })
    } else {
      message.error('Name already exists')
    }
  }

  const onKeyPressHandler = (e) => {
    if (e.key === 'Enter') {
      changeGroupName()
    }
  }

  const handleSelect = (value) => {
    curDataSet(value)
    curDataEditSet(value)
    data.forEach((item) => {
      if (item.attributes.name === value) {
        curDataIDSet(item.id)
      }
    })
  }

  return (
    <Wrapper>
      <Content>
        <StyledCol>
          <ListSort data={data} onClick={handleSelect} selected={curData} />
          <AddItemWrapper>
            <Input
              style={{ marginRight: 10 }}
              value={name}
              onChange={onNameChange}
            />
            <SaveButton onClick={addItem} type="primary">
              Add group{' '}
            </SaveButton>
          </AddItemWrapper>
        </StyledCol>
        <Aside>
          {curData && (
            <EditItemWrapper>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  onChange={(e) => curDataEditSet(e.target.value)}
                  onKeyPress={onKeyPressHandler}
                  value={curDataEdit}
                  style={{ marginRight: 10 }}
                />
                <SaveButton onClick={changeGroupName} type="primary">
                  Save
                </SaveButton>
              </div>
              <DeleteButton
                onClick={handleDelete}
                type="danger"
              >{`Delete ${curData}`}</DeleteButton>
            </EditItemWrapper>
          )}
        </Aside>
      </Content>
    </Wrapper>
  )
}

export default GroupComponent
