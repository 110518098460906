import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import StyledInput from 'components/styled/InputWithInvalidState'

const TextInput = ({ onClear, input, meta, ...rest }) => {
  const handleChange = ({ target }) => {
    const { value } = target
    input.onChange(value)
    if (onClear && !value) {
      onClear()
    }
  }
  return (
    <Tooltip title={meta.modified && meta.error} color="red">
      <StyledInput
        invalid={meta.modified && meta.error}
        value={input.value}
        onChange={handleChange}
        {...rest}
      />
    </Tooltip>
  )
}

TextInput.propTypes = {
  onClear: PropTypes.func,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    modified: PropTypes.bool,
  }).isRequired,
}

TextInput.defaultProps = {
  onClear: null,
}

export default TextInput
