import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Text, CopyButton, Wrapper } from './CopyText.styles'
import { CheckCircleTwoTone, CopyTwoTone } from '@ant-design/icons'
import copy from 'copy-to-clipboard'
import { message } from 'antd'

const CopyText = ({ value, noText }) => {
  const [copied, setCopied] = useState(false)

  const copyText = () => {
    copy(value)
    setCopied(true)
    message.success('Text copied!')
  }

  useEffect(() => {
    let timer
    if (copied) {
      timer = setTimeout(() => setCopied(false), 2000)
    }

    return () => clearTimeout(timer)
  }, [copied])

  return (
    <Wrapper noText={noText}>
      {!noText && <Text>{value || '-'}</Text>}
      {value && (
        <CopyButton role="button" onClick={copyText}>
          {copied ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CopyTwoTone />
          )}
        </CopyButton>
      )}
    </Wrapper>
  )
}

CopyText.propTypes = {
  value: PropTypes.string,
  // eslint-disable-next-line react/boolean-prop-naming
  noText: PropTypes.bool,
}

export default CopyText
