import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import { Actions, StyledButton } from './Giveaways'
import { formatFieldValue } from 'helpers/formatters/fields'
import Status from 'components/Status'
import Image from 'components/Image'
import { formatDay } from 'helpers/dates'

export default (filters, sorter, handleEditGiveaway, handleDeleteGiveaway) => [
  {
    title: 'ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 100,
    align: 'center',
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Name',
    dataIndex: FIELD_NAMES.name,
    key: FIELD_NAMES.name,
    width: 120,
    fixed: 'left',
    align: 'center',
    render: formatFieldValue,
  },
  {
    title: 'Image',
    dataIndex: FIELD_NAMES.productPreview,
    key: FIELD_NAMES.productPreview,
    width: 200,
    align: 'center',
    render: (src) => (
      <Image
        src={src ? `${src}?${Date.now()}` : '/images/fallback.png'}
        width={200}
        height={95}
      />
    ),
  },
  {
    title: 'Start Date',
    dataIndex: FIELD_NAMES.startDate,
    key: FIELD_NAMES.startDate,
    width: 100,
    align: 'center',
    render: formatDay,
  },
  {
    title: 'End Date',
    dataIndex: FIELD_NAMES.endDate,
    key: FIELD_NAMES.endDate,
    width: 100,
    align: 'center',
    render: formatDay,
  },
  {
    title: 'Is Visible',
    dataIndex: FIELD_NAMES.visible,
    key: FIELD_NAMES.visible,
    width: 100,
    align: 'center',
    render: (value) => <Status value={value} />,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    fixed: 'right',
    width: 80,
    render: (_, giveaway) => {
      const onEdit = () => handleEditGiveaway(giveaway[FIELD_NAMES.id])
      const onDelete = () => handleDeleteGiveaway(giveaway)

      return (
        <Actions>
          <StyledButton onClick={onEdit}>Edit</StyledButton>
          <StyledButton onClick={onDelete} type="danger">
            Delete
          </StyledButton>
        </Actions>
      )
    },
  },
]
