import { all, put, race, take, takeLatest } from 'redux-saga/effects'
import { startLoading, finishLoading } from 'reducers/layoutSlice'
import { actions as transactionsActions } from 'domains/transactions'
import * as actions from 'actions/views/transactions'
import { formatPayloadForFetchRequest } from 'domains/transactions/formatters'

function* fetchTransactionsWorker({ payload } = {}) {
  try {
    yield all([
      put(startLoading()),
      put(
        transactionsActions.fetchTransactions(
          formatPayloadForFetchRequest(payload)
        )
      ),
    ])
    const [success] = yield race([
      take(transactionsActions.fetchTransactionsSuccess),
      take(transactionsActions.fetchTransactionsFailure),
    ])
    if (success) {
      yield put(transactionsActions.saveFiltersAndSorter(payload))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

export default function* () {
  yield all([
    fetchTransactionsWorker(),
    takeLatest(actions.fetchTransactions, fetchTransactionsWorker),
  ])
}
