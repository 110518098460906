import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useAction from 'utils/useAction'
import useLeavingPage from 'utils/useLeavingPage'
import { UserIcon, Info, Text, Name } from './RightMenu.styles'
import { Button, Drawer, Divider } from 'antd'
import { withRouter } from 'react-router-dom'
import { selectors as adminSelectors } from 'domains/admin'
import * as actions from 'actions/layout'
import { useSelector } from 'react-redux'
import { FIELD_NAMES } from 'const/forms'
import { openChangePasswordModal } from 'reducers/modals/actions'

const RightMenu = ({ location }) => {
  const userData = useSelector(adminSelectors.adminData)
  const { pathname } = location
  const [visible, setVisible] = useState(false)
  const userLogoutFunc = useAction(actions.logout)
  const openChangePasswordModalFunc = useAction(openChangePasswordModal)
  const ref = useLeavingPage(pathname, userLogoutFunc)

  const showDrawer = () => setVisible(true)

  const hideDrawer = () => setVisible(false)

  const changePassword = () => openChangePasswordModalFunc()

  const handleLogout = () => {
    userLogoutFunc()
    hideDrawer()
  }

  return (
    <>
      <UserIcon onClick={showDrawer} />
      <Drawer
        width={400}
        title="Profile"
        placement="right"
        onClose={hideDrawer}
        open={visible}
      >
        <Info>
          <Name>
            <Text $bold>
              {userData?.attributes?.[FIELD_NAMES.name] ||
                userData?.[FIELD_NAMES.id]}
            </Text>
            <Text>({userData?.attributes?.[FIELD_NAMES.role]})</Text>
          </Name>
          <Text>{userData?.attributes?.[FIELD_NAMES.email]}</Text>
          <Divider />
          <Button
            type="ghost"
            ref={ref}
            onClick={changePassword}
            style={{ marginBottom: '1rem' }}
          >
            Change Password
          </Button>
          <Button type="ghost" ref={ref} onClick={() => handleLogout()}>
            Log out
          </Button>
        </Info>
      </Drawer>
    </>
  )
}

RightMenu.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default withRouter(RightMenu)
