import { createSlice } from '@reduxjs/toolkit'
import { SHOP_SUB_ENTITIES } from './const'

const initialState = {
  isSavingActive: false,
  [SHOP_SUB_ENTITIES.shops]: {
    search: '',
    isLoaded: false,
    currentShop: null,
  },
  [SHOP_SUB_ENTITIES.localizedShops]: {
    filters: {},
    sorter: {},
    page: 1,
    isLoaded: false,
    data: null,
    pageCount: 1,
  },
  [SHOP_SUB_ENTITIES.campaignDeals]: {
    filters: {},
    sorter: {},
    page: 1,
    isLoaded: false,
    data: null,
    pageCount: 1,
  },
  [SHOP_SUB_ENTITIES.adPackages]: {
    filters: {},
    sorter: {},
    page: 1,
    isLoaded: false,
    data: null,
    pageCount: 1,
  },
  [SHOP_SUB_ENTITIES.shopOffers]: {
    filters: {},
    sorter: {},
    page: 1,
    isLoaded: false,
    data: null,
  },
  [SHOP_SUB_ENTITIES.programs]: {
    filters: {},
    sorter: {},
    page: 1,
    isLoaded: false,
    data: null,
    pageCount: 1,
  },
}

const shopsSlice = createSlice({
  name: 'shops',
  initialState,
  reducers: {
    fetchShopsSuccess: (state, { payload }) => ({
      ...state,
      [SHOP_SUB_ENTITIES.shops]: {
        ...state[SHOP_SUB_ENTITIES.shops],
        search: payload.search,
        isLoaded: true,
        data: payload.shops,
      },
    }),
    fetchShopsFailure: (state) => state,
    fetchLocalizedShopsSuccess: (state, { payload }) => ({
      ...state,
      [SHOP_SUB_ENTITIES.localizedShops]: {
        ...state[SHOP_SUB_ENTITIES.localizedShops],
        data: payload.localizedShops.included,
        pageCount: payload.localizedShops.page_count,
        isLoaded: true,
      },
      [SHOP_SUB_ENTITIES.shops]: {
        ...state[SHOP_SUB_ENTITIES.shops],
        currentShop: payload.localizedShops.data,
      },
    }),
    fetchLocalizedShopsFailure: (state) => state,
    fetchShopCampaignDealsSuccess: (state, { payload }) => ({
      ...state,
      [SHOP_SUB_ENTITIES.campaignDeals]: {
        ...state[SHOP_SUB_ENTITIES.campaignDeals],
        data: payload.campaignDeals.included,
        pageCount: payload.campaignDeals.page_count,
        isLoaded: true,
      },
    }),
    fetchShopCampaignDealsFailure: (state) => state,
    fetchShopAdPackagesSuccess: (state, { payload }) => ({
      ...state,
      [SHOP_SUB_ENTITIES.adPackages]: {
        ...state[SHOP_SUB_ENTITIES.adPackages],
        data: payload.adPackages.included,
        page: payload.page,
        pageCount: payload.adPackages.page_count,
        isLoaded: true,
      },
    }),
    fetchShopAdPackagesFailure: (state) => state,
    fetchShopOffersSuccess: (state, { payload }) => ({
      ...state,
      [SHOP_SUB_ENTITIES.shopOffers]: {
        ...state[SHOP_SUB_ENTITIES.shopOffers],
        data: payload.shopOffers.included,
        pageCount: payload.shopOffers.page_count,
        isLoaded: true,
      },
    }),
    fetchShopOffersFailure: (state) => state,
    fetchShopProgramsSuccess: (state, { payload }) => ({
      ...state,
      [SHOP_SUB_ENTITIES.programs]: {
        ...state[SHOP_SUB_ENTITIES.programs],
        data: payload.programs,
        isLoaded: true,
      },
      isSavingActive: false,
    }),
    fetchShopProgramsFailure: (state) => state,
    startSaving: (state) => ({
      ...state,
      isSavingActive: true,
    }),
    finishSaving: (state) => ({
      ...state,
      isSavingActive: false,
    }),
    saveFiltersAndSorter: (state, { payload }) => ({
      ...state,
      [payload.category]: {
        ...state[payload.category],
        filters: payload.filters || {},
        sorter: payload.sorter || {},
        page: payload.page,
      },
    }),
    clearShops: (state) => ({
      ...state,
      [SHOP_SUB_ENTITIES.shops]: {
        ...state[SHOP_SUB_ENTITIES.shops],
        data: {},
      },
    }),
    removeShopGroup: (state, { payload }) => {
      return {
        ...state,
        [SHOP_SUB_ENTITIES.shops]: {
          ...state[SHOP_SUB_ENTITIES.shops],
          currentShop: {
            ...state[SHOP_SUB_ENTITIES.shops].currentShop,
            shop_groups: state[
              SHOP_SUB_ENTITIES.shops
            ].currentShop.shop_groups.filter(
              (group) => group.id !== payload.id
            ),
          },
        },
      }
    },
    addShopGroup: (state, { payload }) => ({
      ...state,
      [SHOP_SUB_ENTITIES.shops]: {
        ...state[SHOP_SUB_ENTITIES.shops],
        currentShop: {
          ...state[SHOP_SUB_ENTITIES.shops].currentShop,
          shop_groups: [
            ...state[SHOP_SUB_ENTITIES.shops].currentShop.shop_groups,
            payload,
          ],
        },
      },
    }),
    updateShopGroupPosition: (state, { payload }) => {
      const { id, position } = payload

      return {
        ...state,
        [SHOP_SUB_ENTITIES.shops]: {
          ...state[SHOP_SUB_ENTITIES.shops],
          currentShop: {
            ...state[SHOP_SUB_ENTITIES.shops].currentShop,
            shop_groups: state[
              SHOP_SUB_ENTITIES.shops
            ].currentShop.shop_groups.map((group) =>
              group.id === id
                ? {
                    ...group,
                    position,
                  }
                : group
            ),
          },
        },
      }
    },
    clearData: () => initialState,
  },
})

export default shopsSlice
