import React from 'react'
import { useSelector } from 'react-redux'
import {
  getIsLoaded,
  getPage,
  getPageCount,
  getSearchString,
  getDeletedRetailersData,
  getIsDataFilteredOrSorted,
  getFilters,
  getSorter,
} from 'domains/retailers/selectors'
import { openRestoreRetailerModal } from 'reducers/modals/actions'
import {
  changePage,
  changeSearchString,
  changeFiltersOrSorter,
} from 'domains/retailers/actions'
import { Button } from 'antd'
import Pagination from 'components/Pagination'
import SearchInTableBar from 'components/SearchInTableBar'
import { StyledTable, Wrapper } from './DeletedRetailers.styles'
import useAction from 'utils/useAction'
import { HEADER_OFFSET } from 'const/tables'
import { getRowKey, getRowClass } from 'helpers/tables'
import getColumns from './columns'

const SCROLL = { x: 900 }

const DeletedRetailers = () => {
  const isLoaded = useSelector(getIsLoaded)
  const page = useSelector(getPage)
  const search = useSelector(getSearchString)
  const pageCount = useSelector(getPageCount)
  const filters = useSelector(getFilters)
  const sorter = useSelector(getSorter)
  const dataSource = useSelector(getDeletedRetailersData)
  const isDataFilteredOrSorted = useSelector(getIsDataFilteredOrSorted)

  const changeSearchStringFunc = useAction(changeSearchString)
  const changeFiltersOrSorterFunc = useAction(changeFiltersOrSorter)
  const openRestoreRetailerModalFunc = useAction(openRestoreRetailerModal)
  const changePageFunc = useAction(changePage)

  const handleRestoreRetailer = (retailer) => () =>
    openRestoreRetailerModalFunc(retailer)

  const onPageChange = (page) =>
    changePageFunc({
      deleted: true,
      search,
      page,
    })

  const onSearchStringChange = (search) =>
    changeSearchStringFunc({
      deleted: true,
      search,
    })

  const onTableChange = (pagination, filters, sorter) =>
    changeFiltersOrSorterFunc({
      search,
      deleted: true,
      filters,
      sorter,
    })

  const handleResetFilters = () =>
    changeFiltersOrSorterFunc({
      search,
      deleted: true,
      filters: {},
      sorter: {},
    })

  return (
    <Wrapper>
      <SearchInTableBar
        placeholder="Search retailer here"
        onSubmit={onSearchStringChange}
        value={search}
      >
        <Button
          onClick={handleResetFilters}
          disabled={!isLoaded || !isDataFilteredOrSorted}
        >
          Reset filters
        </Button>
      </SearchInTableBar>
      <StyledTable
        onChange={onTableChange}
        showSorterTooltip={false}
        columns={getColumns(handleRestoreRetailer, filters, sorter)}
        dataSource={dataSource}
        pagination={false}
        expandable={false}
        sticky={isLoaded && HEADER_OFFSET}
        scroll={SCROLL}
        rowKey={getRowKey}
        loading={!isLoaded}
        rowClassName={getRowClass}
      />
      <Pagination page={page} lastPage={pageCount} onChange={onPageChange} />
    </Wrapper>
  )
}

export default DeletedRetailers
