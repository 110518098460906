import {
  createStore,
  getDefaultMiddleware,
  applyMiddleware,
} from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import promise from '../middlewares/promise.js'
import reducer from '../reducers'
import saga from '../saga'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { setAuthorizationToken } from '../helpers/axios'

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()
const middleware = [
  ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
  promise,
  sagaMiddleware,
  logger,
  routerMiddleware(history),
]

const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
})

export default () => {
  setAuthorizationToken(() => localStorage.getItem('token'))
  const store = createStore(
    reducer(history),
    composeEnhancers(applyMiddleware(...middleware))
  )
  sagaMiddleware.run(saga)
  return store
}
