import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Spin } from 'antd'
import useAction from 'utils/useAction'
import { getColumns } from './adPackagesColumns'
import {
  openConfirmModal,
  openShopAdPackageModal,
} from 'reducers/modals/actions'
import {
  getAdPackagesPage,
  getAdPackagesPageCount,
  getCurrentShopId,
  getIsAdPackagesLoaded,
  getIsAdPackagesDataFilteredOrSorted,
  getAdPackagesFilters,
  getAdPackagesSorter,
  getIsSavingActive,
  getShopAdPackages,
} from 'domains/shops/selectors'
import { useEffect } from 'react'
import { deleteAdPackage, fetchShopAdPackages, duplicateAdPackage } from 'actions/views/shops'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import { Buttons, StyledTable } from '../Shops.styles'
import { FIELD_NAMES } from 'const/forms'
import { PAGE_SIZE } from '../../../const/tables'

function AdPackagesTab() {
  const currentShopId = useSelector(getCurrentShopId)
  const fetchAdPackages = useAction(fetchShopAdPackages)
  const deleteAdPackageFunc = useAction(deleteAdPackage)
  const duplicateAdPackageFunc = useAction(duplicateAdPackage)
  const openConfirmModalFunc = useAction(openConfirmModal)
  const adPackages = useSelector(getShopAdPackages)
  const isLoaded = useSelector(getIsAdPackagesLoaded)
  const isSavingActive = useSelector(getIsSavingActive)
  const page = useSelector(getAdPackagesPage)
  const pageCount = useSelector(getAdPackagesPageCount)
  const openAdPackageModalFunc = useAction(openShopAdPackageModal)
  const filters = useSelector(getAdPackagesFilters)
  const sorter = useSelector(getAdPackagesSorter)
  const issDataFilteredOrSorted = useSelector(
    getIsAdPackagesDataFilteredOrSorted
  )

  const onTableChange = (pagination, filters, sorter) => {
    fetchAdPackages({
      filters,
      sorter,
      page: pagination.current,
      perPage: pagination.pageSize,
    })
  }

  const handleResetFilters = () => {
    fetchAdPackages({
      page: 1,
      filters: {},
      sorter: {},
    })
  }

  const handleDeleteAdPackage = (adPackage) => {
    const callback = () => deleteAdPackageFunc(adPackage[FIELD_NAMES.id])
    const text =
      `Are you sure you want to delete package (ID: ${
        adPackage[FIELD_NAMES.id]
      })? ` + "This action can't be revoked."
    openConfirmModalFunc({ callback, text })
  }

  const handleDuplicateAdPackage = (adPackage) => {
    const callback = () => duplicateAdPackageFunc(adPackage[FIELD_NAMES.id])
    const text =
      `Are you sure you want to duplicate package (ID: ${
        adPackage[FIELD_NAMES.id]
      })? ` + "This action can't be revoked."
    openConfirmModalFunc({ callback, text })
  }

  const handleAdPackage = () => {
    openAdPackageModalFunc()
  }

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  useEffect(() => {
    if (currentShopId) {
      fetchAdPackages({
        filters,
        sorter,
      })
    }
  }, [currentShopId])

  return (
    <Spin spinning={isSavingActive} size="large">
      <Buttons>
        <Button
          onClick={handleResetFilters}
          disabled={!isLoaded || !issDataFilteredOrSorted}
          style={{ marginLeft: '12px' }}
        >
          Reset filters
        </Button>
        <Button
          onClick={handleAdPackage}
          disabled={!isLoaded || !currentShopId}
          style={{ marginBottom: '10px' }}
        >
          Create package
        </Button>
      </Buttons>

      <StyledTable
        onChange={onTableChange}
        dataSource={adPackages}
        columns={getColumns(
          openAdPackageModalFunc,
          handleDeleteAdPackage,
          handleDuplicateAdPackage,
          filters,
          sorter
        )}
        size="medium"
        pagination={paginationConfig}
        rowKey={getRowKey}
        rowClassName={getRegularRowClass}
        bordered
        sticky={isLoaded}
      />
    </Spin>
  )
}

export default AdPackagesTab
