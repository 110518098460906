import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import get from 'lodash/get'
import { FIELD_NAMES, FORM_COMMENTS_FIELD_PREFIX } from 'const/forms'
import {
  getInitialValues,
  getRetailerData,
  getDeletedRetailerData,
  getRetailersWithChanges,
  getIds,
  getCommentsValues,
  formatEditorialTableData,
  formatEditorialTableInitialValues,
  formatRetailersGroupsRelationships,
  formatRetailersCategoriesRelationships,
  formatEditStoreContentInitialValues,
  getGeneralRetailerAttrs,
} from 'domains/retailers/formatters'
import {
  formatImageInitialValue,
  formatSet,
  formatSetLogo,
} from 'helpers/formatters/images'
import { selectors as generalSelectors } from 'domains/general'
import { ENTITY_NAMES, RELATIONSHIPS_NAMES } from 'const/api'
import { EMPTY_IMAGE_SET } from 'const/images'
import { areFiltersAndSorterEmpty } from 'helpers/filters'

export const retailersState = ({ retailers }) => retailers

export const getIsLoaded = createSelector(
  [retailersState],
  ({ isLoaded }) => isLoaded
)

export const getFlagReinitialize = createSelector(
  [retailersState],
  ({ isReinitialize }) => isReinitialize
)

export const getIsSavingActive = createSelector(
  retailersState,
  ({ isSavingActive }) => isSavingActive
)

export const deletedSets = createSelector(
  retailersState,
  ({ deletedSets }) => deletedSets
)

export const isLoadingOrSavingActive = createSelector(
  [getIsLoaded, getIsSavingActive],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getMainRetailers = createSelector(
  [retailersState],
  ({ entities: { main_retailers } }) => main_retailers
)

export const getSortedIds = createSelector(
  [retailersState],
  ({ sortedIds }) => sortedIds
)

export const getEntities = createSelector(
  [retailersState],
  ({ entities }) => entities
)

export const getAllEntities = createSelector(
  [retailersState],
  ({ allEntities }) => allEntities
)

export const getRetailerGroups = createSelector(
  getEntities,
  ({ [ENTITY_NAMES.retailerGroups]: groups }) => groups
)

export const getRetailerCategories = createSelector(
  getEntities,
  ({ [ENTITY_NAMES.retailerCategories]: categories }) => categories
)

export const getCB = createSelector(
  getEntities,
  (entities) => entities[ENTITY_NAMES.cb]
)

export const getRetailers = createSelector(
  getEntities,
  (entities) => entities[ENTITY_NAMES.retailers]
)

export const getCBValueById = createSelector(getCB, (cb) =>
  memoize((id) => cb?.[id]?.attributes.value || 0)
)

export const getUnavailableRegions = createSelector(
  getEntities,
  ({ retailers }) =>
    memoize(
      (id) =>
        get(retailers, [id, 'attributes', FIELD_NAMES.unavailableRegions]) || []
    )
)

export const getRetailerById = createSelector(getRetailers, (retailers) =>
  memoize((id) => getGeneralRetailerAttrs(retailers[id]))
)

export const getRegionalRetailers = createSelector(
  [retailersState],
  ({ allEntities: { regional_retailers } }) => regional_retailers || {}
)

export const getIncludedData = createSelector(
  [retailersState],
  ({ entities }) =>
    memoize(
      ({ type, id }) => type && id && entities[type] && entities[type][id]
    )
)

export const getRetailersData = createSelector(
  [getEntities, getSortedIds],
  (entities, ids) => getRetailerData(entities, ids)
)

export const getDeletedRetailersData = createSelector(
  [getEntities, getSortedIds],
  (entities, ids) => getDeletedRetailerData(entities, ids)
)

export const getChangedRetailers = createSelector(
  retailersState,
  ({ changedRetailers }) => changedRetailers
)

export const getDirtyFields = createSelector(
  retailersState,
  ({ dirtyFields }) => dirtyFields
)

export const getAllRetailersData = createSelector(
  [retailersState, getSortedIds],
  ({ allEntities }, ids) => getRetailersWithChanges(allEntities, ids)
)

export const getIdsForRetailers = createSelector(
  retailersState,
  ({ allEntities }) => getIds(allEntities)
)

export const getRetailersInitialValues = createSelector(
  retailersState,
  ({ entities }) => getInitialValues(entities)
)

export const getAllRetailersInitialValues = createSelector(
  retailersState,
  ({ allEntities }) => getInitialValues(allEntities)
)

export const getAllEditorialRetailersInitialValues = createSelector(
  [retailersState, generalSelectors.getGroups],
  ({ allEntities }, groups) =>
    formatEditorialTableInitialValues(allEntities, groups)
)

export const getCommentsInitialValues = createSelector(
  retailersState,
  ({ entities }) => getCommentsValues(entities)
)

export const getRetailerCommentsInitialValues = createSelector(
  [getCommentsInitialValues],
  (values) =>
    memoize((id) => get(values, `${FORM_COMMENTS_FIELD_PREFIX}_${id}`))
)

export const getFieldInitialValue = createSelector(
  [getRetailersInitialValues],
  (values) => memoize((name) => get(values, name))
)

const formatSetsData = (entities, imageFormatter) => (id) => {
  const retailer = get(entities, [ENTITY_NAMES.retailers, id])
  const retailerSets =
    get(retailer, ['relationships', RELATIONSHIPS_NAMES.images, 'data']) || []
  return retailerSets.reduce(
    (result, set) => {
      const setData = entities[ENTITY_NAMES.images]?.[set.id]
      const item = formatSet(setData, imageFormatter)
      if (result[FIELD_NAMES.set] && item) {
        result[FIELD_NAMES.set].push(item)
      } else {
        result[FIELD_NAMES.set] = [item]
      }
      return result
    },
    {
      [FIELD_NAMES.logo]: formatSetLogo(retailer, imageFormatter),
      [FIELD_NAMES.set]: [],
    }
  )
}

export const getInitialValuesRetailerImages = createSelector(
  retailersState,
  ({ entities }) => memoize(formatSetsData(entities, formatImageInitialValue))
)

export const getRetailerImages = createSelector(
  retailersState,
  ({ entities }) => memoize(formatSetsData(entities))
)

// TODO: replace with actual data
export const getRetailerImagesImpressions = createSelector(
  getRetailersInitialValues,
  (values) =>
    memoize((id) => ({
      male: EMPTY_IMAGE_SET,
      female: EMPTY_IMAGE_SET,
    }))
)

export const getSearchString = createSelector(
  [retailersState],
  ({ search }) => search
)

export const getPage = createSelector([retailersState], ({ page }) => page)

export const getDisplayedData = createSelector(
  [getEntities, getAllEntities, getSortedIds],
  (entities, allEntities, sortedIds) => ({
    entities,
    allEntities,
    sortedIds,
  })
)

export const getFilters = createSelector(
  retailersState,
  ({ filters }) => filters
)

export const getSorter = createSelector(retailersState, ({ sorter }) => sorter)

export const getIsDataFilteredOrSorted = createSelector(
  [getFilters, getSorter],
  areFiltersAndSorterEmpty
)

export const getPageCount = createSelector(
  [retailersState],
  ({ pageCount }) => pageCount
)

// export const getRules = createSelector(
//   [retailersState],
//   ({ entities }) => (
//     memoize(rules => (
//       rules?.map(({ id }) => entities.rules && entities.rules[id]))
//     )
//   )
// )
//
// export const getCategories = createSelector(
//   [retailersState],
//   ({ entities }) => (
//     memoize(categories => (
//       categories?.map(({ id }) => entities.categories && entities.categories[id]))
//     )
//   )
// )

export const getRetailersGroupsRelationships = createSelector(
  retailersState,
  ({ allEntities }) => {
    return formatRetailersGroupsRelationships(allEntities)
  }
)

export const getRetailersCategoriesRelationships = createSelector(
  retailersState,
  ({ allEntities }) => {
    return formatRetailersCategoriesRelationships(allEntities)
  }
)

export const getEditorialTableData = createSelector(
  [getEntities, getSortedIds],
  (entities, ids) => formatEditorialTableData(entities, ids)
)

export const getEditorialTableInitialValues = createSelector(
  retailersState,
  generalSelectors.getGroups,
  ({ entities }, groups) => formatEditorialTableInitialValues(entities, groups)
)

export const getEditorialTabFieldInitialValue = createSelector(
  [getEditorialTableInitialValues],
  (values) => memoize((name) => get(values, name))
)

export const getEditStoreContentInitialValues = createSelector(
  retailersState,
  ({ entities }) =>
    memoize((id) => formatEditStoreContentInitialValues(entities, id))
)
