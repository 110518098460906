import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`

export const Title = styled.h3`
  font-weight: 600;
`

export const Items = styled.span`
  font-size: 14px;
`
