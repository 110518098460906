import { createAction } from '@reduxjs/toolkit'

export const fetchUserTransactions = createAction(
  'customerService/fetchUserTransactions'
)
export const fetchUserPaymentTransactions = createAction(
  'customerService/fetchUserPaymentTransactions'
)
export const fetchUserEngagmentMetrics = createAction(
  'customerService/fetchUserEngagmentMetrics'
)
export const resetSelectedEmail = createAction(
  'customerService/resetSelectedEmail'
)
export const resetSearch = createAction('customerService/resetSearch')
export const updateUserPreferences = createAction(
  'customerService/updateUserPreferences'
)
