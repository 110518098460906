import { apiInstance } from 'utils/request'
import { ENTITY_NAMES, RELATIONSHIPS_NAMES } from 'const/api'
import { FIELD_NAMES } from '../../const/forms'
import parseError from 'helpers/parseServerError'

export const getCoupons = ({ page, search, filters, sorter }) =>
  apiInstance
    .get(`/${RELATIONSHIPS_NAMES.coupons}`, {
      params: {
        filter: {
          search: search ? encodeURIComponent(search) : null,
          ...filters,
        },
        sort: sorter || FIELD_NAMES.id,
        page: {
          number: page,
          size: 20,
        },
      },
    })
    .catch((error) => {
      parseError(error, 'Cannot get coupons')
    })

export const deleteCoupon = (id) =>
  apiInstance
    .delete(`/${ENTITY_NAMES.coupons}/${id}`)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Coupon hasn't been deleted")
    })

export const createCoupon = (data) =>
  apiInstance
    .post(`/${ENTITY_NAMES.coupons}`, {
      data,
    })
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Coupon hasn't been created")
    })

export const updateCoupon = ({ id, data }) => {
  return apiInstance
    .patch(`/${ENTITY_NAMES.coupons}/${id}`, {
      data,
    })
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Coupon hasn't been updated")
    })
}
