import React, { useEffect, useRef, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import useAction from 'utils/useAction'
import { useSelector } from 'react-redux'
import { getShops } from 'domains/shops/selectors'
import { fetchLocalizedShops, fetchShops } from 'actions/views/shops'
import { clearShops } from 'domains/shops/actions'

const Option = Select.Option

const SearchSelectShops = ({
  input,
  title,
  mode,
  callback,
  isEditing,
  ...rest
}) => {
  const timerRef = useRef(null)
  const [searchValue, setSearchValue] = useState('')
  const dataSource = useSelector(getShops)
  const fetchShopsFunc = useAction(fetchShops)
  const fetchLocalizedShopsFunc = useAction(fetchLocalizedShops)
  const clearShopsFunc = useAction(clearShops)

  const onSearchStringChange = (search) => {
    setSearchValue(search)
    if (searchValue?.length > 1 && search.length > 2) {
      clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => fetchShopsFunc({ search }), 500)
    }
  }

  const onSearchClick = (id) => {
    setSearchValue('')
    clearShopsFunc()
    if (isEditing) {
      input.onChange(id)
    } else {
      return (
        id &&
        fetchLocalizedShopsFunc({
          search: id,
        })
      )
    }
  }

  useEffect(() => {
    return () => clearTimeout(timerRef.current)
  }, [])

  return (
    <Select
      onSearch={onSearchStringChange}
      onSelect={onSearchClick}
      value={searchValue || undefined}
      showSearch
      showArrow={false}
      filterOption={false}
      dropdownMatchSelectWidth={false}
      style={{ width: '100%' }}
      notFoundContent={null}
      placeholder={
        <>
          <SearchOutlined /> &nbsp; Search shops here
        </>
      }
    >
      {(dataSource.length && searchValue?.length > 2 ? dataSource : []).map(
        (d) => {
          return (
            <Option key={d.id} value={d.id}>
              {isEditing ? d.name + ' - ' + d.id : d.name}
            </Option>
          )
        }
      )}
    </Select>
  )
}

export default SearchSelectShops
