import React from 'react'
import { FIELD_NAMES } from 'const/forms'
import { Field } from 'react-final-form'
import Regions from './Select'
import FieldWrapper from '../FieldWrapper/FieldWrapper'
import RegionGroupSelect from './RegionGroupSelect'

const PackageRegions = ({ isDisabled }) => {
  return (
    <>
      <FieldWrapper label="Regions">
        <Field
          isDisabled={!!isDisabled}
          defaultValue=""
          placeholder="Choose region"
          component={RegionGroupSelect}
          name={FIELD_NAMES.regionsGroups}
        />
      </FieldWrapper>
      <FieldWrapper label="Countries">
        <Field
          isDisabled={!!isDisabled}
          component={Regions}
          name={FIELD_NAMES.regions}
        />
      </FieldWrapper>
    </>
  )
}

export default PackageRegions
