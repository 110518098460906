import RetailerCountry from 'components/RetailerCountry'
import { getRetailerTypeFilters } from 'helpers/retailers'
import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import { Button } from 'antd'
import { formatUrl } from 'helpers/formatters/fields'

export default (handleRestore, filters, sorter) => [
  {
    title: 'Retailer ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 120,
    fixed: 'left',
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Country',
    dataIndex: FIELD_NAMES.country,
    key: FIELD_NAMES.country,
    width: 100,
    render: (country, retailer) => <RetailerCountry retailer={retailer} />,
    filters: getRetailerTypeFilters(),
    filteredValue: filters[FIELD_NAMES.country] || null,
    align: 'center',
  },
  {
    title: 'Rule ID',
    dataIndex: FIELD_NAMES.ruleId,
    key: FIELD_NAMES.ruleId,
    width: 100,
    sorter: true,
    sortOrder: sorter.ruleId === FIELD_NAMES.ruleId && sorter.order,
    align: 'center',
  },
  {
    title: 'Global ID',
    dataIndex: FIELD_NAMES.mainId,
    key: FIELD_NAMES.mainId,
    width: 115,
    align: 'center',
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.mainId && sorter.order,
  },
  {
    title: 'Name',
    dataIndex: FIELD_NAMES.name,
    key: FIELD_NAMES.name,
    render: (name, { url }) => (
      <a rel="noreferrer noopener" href={formatUrl(url)} target="_blank">
        {name}
      </a>
    ),
    width: 120,
    sorter: true,
    sortOrder: sorter.columnKey === FIELD_NAMES.name && sorter.order,
  },
  {
    title: 'Domain',
    dataIndex: FIELD_NAMES.domain,
    key: FIELD_NAMES.domain,
    render: (domain, { url }) => (
      <a rel="noreferrer noopener" href={formatUrl(url)} target="_blank">
        {domain}
      </a>
    ),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    fixed: 'right',
    width: 120,
    render: (_, retailer) => (
      <Button onClick={handleRestore({ retailer })}>Restore</Button>
    ),
  },
]
