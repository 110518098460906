import { all, put, take } from 'redux-saga/effects'

import { startLoading, finishLoading } from 'reducers/layoutSlice'
import { actions as featuresActions } from 'domains/features'

function* getFeaturesWorker() {
  try {
    yield all([
      put(startLoading()),
      put(featuresActions.fetchFeaturesRequest()),
    ])
    yield take([
      featuresActions.fetchFeaturesSuccess,
      featuresActions.fetchFeaturesFailure,
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

export default function* () {
  yield all([getFeaturesWorker()])
}
