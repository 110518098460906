import React from 'react'
import { Select as AntSelect } from 'antd'
import { StyledSelect } from './Select.styles'

const Select = ({
  input,
  meta,
  placeholder,
  options,
  allowClear,
  disabled,
  labelInValue,
  ...rest
}) => {
  const handleChange = (option) => input.onChange(option || null)
  return (
    <StyledSelect
      disabled={disabled}
      labelInValue={labelInValue}
      invalid={meta.error}
      placeholder={placeholder}
      value={input.value || undefined} // to fix bug with placeholder's displaying
      allowClear={allowClear}
      onChange={handleChange}
      {...rest}
    >
      {options.map((option) => (
        <AntSelect.Option key={option.value} value={option.value}>
          {option.label}
        </AntSelect.Option>
      ))}
    </StyledSelect>
  )
}

Select.defaultProps = {
  options: [],
  allowClear: true,
  labelInValue: true,
  disabled: false,
}

export default Select
