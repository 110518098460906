import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  fetchPaymentUploadsSuccess,
  fetchPaymentUploadsFailure,
  fetchPaymentsSuccess,
  fetchPaymentsFailure,
  startSaving,
  finishSaving,
  clearData,
  fetchBudgetSuccess,
  fetchBudgetFailure,
} = reducer.actions

export const fetchPaymentUploads = createAction('payments/fetchPaymentUploads')
export const fetchPayments = createAction('payments/fetchPayments')
export const fetchBudget = createAction('payments/fetchBudget')

export const saveFiltersAndSorterPayments = createAction(
  'payments/saveFiltersAndSorterPayments'
)
export const saveFiltersAndSorterPaymentUploads = createAction(
  'payments/saveFiltersAndSorterPaymentsUploads'
)

export const uploadPaymentList = createAction('payments/uploadPaymentList')
export const uploadPaymentListSuccess = createAction(
  'payments/uploadPaymentListSuccess'
)
export const uploadPaymentListFailure = createAction(
  'payments/uploadPaymentListFailure'
)

export const deletePayment = createAction('payments/deletePayment')
export const deletePaymentSuccess = createAction(
  'payments/deletePaymentSuccess'
)
export const deletePaymentFailure = createAction(
  'payments/deletePaymentFailure'
)

export const approvePayment = createAction('payments/approvePayment')
export const approvePaymentSuccess = createAction(
  'payments/approvePaymentSuccess'
)
export const approvePaymentFailure = createAction(
  'payments/approvePaymentFailure'
)

export const retractPayment = createAction('payments/retractPayment')
export const retractPaymentSuccess = createAction(
  'payments/retractPaymentSuccess'
)
export const retractPaymentFailure = createAction(
  'payments/retractPaymentFailure'
)

export const resendPayment = createAction('payments/resendPayment')
export const resendPaymentSuccess = createAction(
  'payments/resendPaymentSuccess'
)
export const resendPaymentFailure = createAction(
  'payments/resendPaymentFailure'
)

export const rejectPayment = createAction('payments/rejectPayment')
export const rejectPaymentSuccess = createAction(
  'payments/rejectPaymentSuccess'
)
export const rejectPaymentFailure = createAction(
  'payments/rejectPaymentFailure'
)

export const updatePayment = createAction('payments/updatePayment')
export const updatePaymentSuccess = createAction(
  'payments/updatePaymentSuccess'
)
export const updatePaymentFailure = createAction(
  'payments/updatePaymentFailure'
)

export const createPayment = createAction('payments/createPayment')
export const createPaymentSuccess = createAction(
  'payments/createPaymentSuccess'
)
export const createPaymentFailure = createAction(
  'payments/createPaymentFailure'
)
