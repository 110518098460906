import { createSelector } from '@reduxjs/toolkit'
import { areFiltersAndSorterEmpty } from 'helpers/filters'
import { formatDevelopers } from './formatters'

export const developersState = ({ developers }) => developers
export const getIsLoaded = createSelector(
  developersState,
  ({ isLoaded }) => isLoaded
)
export const getIsSavingActive = createSelector(
  developersState,
  ({ isSavingActive }) => isSavingActive
)

export const isLoadingOrSavingActive = createSelector(
  [getIsLoaded, getIsSavingActive],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getEntities = createSelector(
  developersState,
  ({ entities }) => entities
)
export const getDevelopers = createSelector(getEntities, (entities) =>
  formatDevelopers(entities)
)
export const getCSVLink = createSelector(
  developersState,
  ({ CSVLink }) => CSVLink
)
export const getCSV = createSelector(getCSVLink, (CSVLink) => CSVLink)
export const getPage = createSelector(developersState, ({ page }) => page)
export const getFilters = createSelector(
  developersState,
  ({ filters }) => filters
)
export const getSorter = createSelector(developersState, ({ sorter }) => sorter)
export const getIsDataFilteredOrSorted = createSelector(
  [getFilters, getSorter],
  areFiltersAndSorterEmpty
)
export const getPageCount = createSelector(
  developersState,
  ({ pageCount }) => pageCount
)
export const getDevelopersAsArray = createSelector(
  [getDevelopers],
  (developers) => Object.values(developers)
)
