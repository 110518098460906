import React, { useEffect, useRef, useState } from 'react'
import { FIELD_NAMES } from 'const/forms'
import useAction from 'utils/useAction'
import * as actions from 'actions/views/payments'
import { useSelector } from 'react-redux'
import moment from 'moment'
import * as viewSelectors from 'selectors/views/payments'
import { PAYMENT_STATUSES } from 'const/payments'
import { openConfirmModal, openPaymentModal } from 'reducers/modals/actions'
import { Button, Dropdown, Menu } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import MenuItem from 'antd/es/menu/MenuItem'

const PaymentActions = ({ data }) => {
  const countdownTimerId = useRef(null)
  const [countdownDateInThePast, setCountdownDateInThePast] = useState(
    moment(data[FIELD_NAMES.countdownDate]).isBefore()
  )
  const paymentId = data[FIELD_NAMES.id]
  const hasPendingStatus = data[FIELD_NAMES.state] === PAYMENT_STATUSES.PENDING
  const hasRejectedStatus =
    data[FIELD_NAMES.state] === PAYMENT_STATUSES.REJECTED
  const isDeletingAvailable = useSelector(
    viewSelectors.isPaymentDeletingAvailable
  )(paymentId)
  const isApproveAvailable = useSelector(
    viewSelectors.isPaymentApproveAvailable
  )(paymentId)
  const isResendingAvailable = useSelector(
    viewSelectors.isPaymentResendingAvailable
  )(paymentId)
  const isRetractingAvailable = useSelector(
    viewSelectors.isPaymentRetractingAvailable
  )(paymentId)
  const isRejectingAvailable = !countdownDateInThePast && hasPendingStatus
  const isEditingAvailable = hasPendingStatus
    ? !countdownDateInThePast
    : hasRejectedStatus

  const deletePaymentFunc = useAction(actions.deletePayment)
  const retractPaymentFunc = useAction(actions.retractPayment)
  const rejectPaymentFunc = useAction(actions.rejectPayment)
  const approvePaymentFunc = useAction(actions.approvePayment)
  const resendPaymentFunc = useAction(actions.resendPayment)
  const openConfirmModalFunc = useAction(openConfirmModal)
  const openPaymentModalFunc = useAction(openPaymentModal)

  const onEdit = () => openPaymentModalFunc({ id: paymentId })

  const onDelete = () => {
    const callback = () => deletePaymentFunc(paymentId)
    const text =
      `Are you sure you want to delete payment ${
        data[FIELD_NAMES.name] || ''
      } (ID: ${paymentId})? ` + "This action can't be revoked."
    openConfirmModalFunc({ callback, text })
  }

  const onReject = () => {
    const callback = () => rejectPaymentFunc(paymentId)
    const text = `Are you sure you want to reject payment ${
      data[FIELD_NAMES.name] || ''
    } (ID: ${paymentId})? `
    openConfirmModalFunc({ callback, text })
  }

  const onRetract = () => {
    const callback = () => retractPaymentFunc(paymentId)
    const text =
      `Are you sure you want to retract payment ${
        data[FIELD_NAMES.name] || ''
      } (ID: ${paymentId})? ` + "This action can't be revoked."
    openConfirmModalFunc({ callback, text })
  }

  const onApprove = () => {
    const callback = () => approvePaymentFunc(paymentId)
    const text = `Are you sure you want to approve payment ${
      data[FIELD_NAMES.name] || ''
    } (ID: ${paymentId}) over the budget? `
    openConfirmModalFunc({ callback, text })
  }

  const onResend = () => {
    const callback = () => resendPaymentFunc(paymentId)
    const text = `Are you sure you want to resend payment ${
      data[FIELD_NAMES.name] || ''
    } (ID: ${paymentId})? `
    openConfirmModalFunc({ callback, text })
  }

  useEffect(() => {
    countdownTimerId.current = setInterval(() => {
      if (moment(data[FIELD_NAMES.countdownDate]).isBefore()) {
        setCountdownDateInThePast(true)
        clearInterval(countdownTimerId.current)
      }
    }, 1000)

    return () => clearInterval(countdownTimerId.current)
  }, [data])

  const menu = (
    <Menu>
      <MenuItem onClick={onEdit} disabled={!isEditingAvailable}>
        Edit
      </MenuItem>
      <MenuItem onClick={onReject} disabled={!isRejectingAvailable}>
        Reject
      </MenuItem>
      <MenuItem onClick={onResend} disabled={!isResendingAvailable}>
        Resend
      </MenuItem>
      <MenuItem onClick={onApprove} disabled={!isApproveAvailable}>
        Approve
      </MenuItem>
      <MenuItem onClick={onRetract} disabled={!isRetractingAvailable}>
        Retract
      </MenuItem>
      <MenuItem onClick={onDelete} disabled={!isDeletingAvailable}>
        Delete
      </MenuItem>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button icon={<MoreOutlined />} shape="circle" size="small" />
    </Dropdown>
  )
}

export default PaymentActions
