import styled from 'styled-components'
import { DeleteOutlined, UndoOutlined } from '@ant-design/icons'

const isDisabled = ({ isDisabled }) =>
  isDisabled
    ? `
      opacity: 0.2;
      pointer-events: none;
      cursor: not-allowed;
    `
    : ''

export const DeleteButton = styled(DeleteOutlined)`
  font-size: 24px;
  ${isDisabled}
  &:hover {
    color: red;
  }
`
export const RestoreButton = styled(UndoOutlined)`
  font-size: 24px;
  ${isDisabled}
`
