import { all, call, put, takeLatest } from 'redux-saga/effects'
import normalized from 'json-api-normalizer'
import * as actions from './actions'
import * as api from './api'
import { getSortedIds } from 'helpers/jsonAPI'

function* fetchGiveawaysWorker({ payload }) {
  try {
    const { data } = yield call(api.getGiveaways, payload) ?? {}

    const normalizedData = normalized(data, {
      camelizeKeys: false,
      camelizeTypeValues: false,
    })

    yield put(
      actions.fetchGiveawaysSuccess({
        ...payload,
        page: payload.page,
        pageCount: data.meta.page_count,
        giveaways: normalizedData,
        sortedIds: getSortedIds(data.data),
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchGiveawaysFailure())
  }
}

function* createGiveawayWorker({ payload }) {
  try {
    const { data } = yield call(api.createGiveaway, payload)
    if (data) {
      yield put(actions.createGiveawaySuccess(data))
    } else {
      yield put(actions.createGiveawayFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createGiveawayFailure())
  }
}

function* updateGiveawayWorker({ payload }) {
  try {
    const { data } = yield call(api.updateGiveaway, payload)
    if (data) {
      yield put(actions.updateGiveawaySuccess(payload))
    } else {
      yield put(actions.updateGiveawayFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateGiveawayFailure())
  }
}

function* deleteGiveawayWorker({ payload }) {
  try {
    const { data } = yield call(api.deleteGiveaway, payload)
    if (data) {
      yield put(actions.deleteGiveawaySuccess(payload))
    } else {
      yield put(actions.deleteGiveawayFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteGiveawayFailure())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchGiveaways, fetchGiveawaysWorker),
    takeLatest(actions.createGiveaway, createGiveawayWorker),
    takeLatest(actions.updateGiveaway, updateGiveawayWorker),
    takeLatest(actions.deleteGiveaway, deleteGiveawayWorker),
  ])
}
