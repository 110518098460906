import { FIELD_NAMES } from 'const/forms'

export const formatDeveloper = (developer) => {
  return {
    [FIELD_NAMES.id]: developer[FIELD_NAMES.email],
    [FIELD_NAMES.email]: developer[FIELD_NAMES.email],
    [FIELD_NAMES.rulesCreated]: developer[FIELD_NAMES.rulesCreated],
    [FIELD_NAMES.rulesUpdated]: developer[FIELD_NAMES.rulesUpdated],
    [FIELD_NAMES.propertiesUpdated]: developer[FIELD_NAMES.propertiesUpdated],
    key: developer[FIELD_NAMES.email],
  }
}

export const formatDevelopers = (developers = []) => {
  return (
    developers.reduce(
      (result, developer) => ({
        ...result,
        [developer[FIELD_NAMES.email]]: formatDeveloper(developer),
      }),
      {}
    ) || {}
  )
}
