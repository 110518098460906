import React from 'react'
import { Tooltip } from 'antd'
import { LinkIcon } from './UrlComponent.styles'
import { formatUrl } from 'helpers/formatters/fields'

const UrlComponent = ({ url }) =>
  url ? (
    <Tooltip title={url}>
      <a href={formatUrl(url)} rel="noreferrer noopener" target="_blank">
        <LinkIcon />
      </a>
    </Tooltip>
  ) : (
    '-'
  )

export default UrlComponent
