import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects'
import * as actions from './actions'
import * as api from './api'
import * as selectors from './selectors'
import { RELATIONSHIPS_NAMES } from '../../const/api'

// ! Sometimes response includes empty objects
const cutEmptyObjects = (response) => response.data.data.filter(({ id }) => id)

const mapTypeToApiCall = {
  [RELATIONSHIPS_NAMES.retailers]: api.getRetailers,
  [RELATIONSHIPS_NAMES.main]: api.getMainRetailers,
  [RELATIONSHIPS_NAMES.rules]: api.getRules,
}

function* fetchRecordsWorker({ payload }) {
  try {
    yield put(actions.startFetching({ key: payload.entity }))
    const [pageSelector, searchSelector] = yield all([
      select(selectors.getPage),
      select(selectors.getSearch),
    ])

    const page = pageSelector(payload.entity)
    const search = searchSelector(payload.entity)

    const apiFunc = mapTypeToApiCall[payload.entity]
    const response = yield call(apiFunc, {
      page,
      search,
      ...payload,
    })
    yield put(
      actions.saveRecords({
        data: cutEmptyObjects(response),
        key: payload.entity,
        page,
        pageCount: response.data.meta.page_count,
      })
    )
    yield put(actions.finishFetching({ key: payload.entity }))
  } catch (error) {
    console.error(error)
  }
}

function* fetchMoreRecordsWorker({ payload }) {
  try {
    yield put(actions.startFetching({ key: payload.entity }))

    const [pageSelector, searchSelector] = yield all([
      select(selectors.getPage),
      select(selectors.getSearch),
    ])

    const prevPage = pageSelector(payload.entity)
    const search = searchSelector(payload.entity)

    const page = prevPage + 1
    const apiFunc = mapTypeToApiCall[payload.entity]
    const response = yield call(apiFunc, {
      page,
      search,
      ...payload,
    })
    yield put(
      actions.saveMoreRecords({
        data: cutEmptyObjects(response),
        key: payload.entity,
        page,
        pageCount: response.data.meta.page_count,
      })
    )
    yield put(actions.finishFetching({ key: payload.entity }))
  } catch (error) {
    console.error(error)
  }
}

export default function* () {
  yield all([
    takeEvery(actions.fetchRecords, fetchRecordsWorker),
    takeLatest(actions.fetchMoreRecords, fetchMoreRecordsWorker),
  ])
}
