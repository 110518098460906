import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from './api'

function* fetchPitaSettingsWorker({ payload }) {
  try {
    const { data } = yield call(api.getPitaSettings, payload)

    yield put(
      actions.fetchPitaSettingsSuccess({
        ...payload,
        ...data,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchPitaSettingsFailure())
  }
}

function* updatePitaMaxWorker({ payload }) {
  try {
    const { data } = yield call(api.updatePitaMax, payload)
    if (data) {
      yield put(actions.updatePitaMaxSuccess(payload.data))
    } else {
      yield put(actions.updatePitaMaxFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updatePitaMaxFailure())
  }
}

function* uploadPitaWorker({ payload }) {
  try {
    const { data } = yield call(api.uploadPita, payload)
    if (data) {
      yield put(actions.uploadPitaSuccess(payload.data))
    } else {
      yield put(actions.uploadPitaFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.uploadPitaFailure())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchPitaSettings, fetchPitaSettingsWorker),
    takeLatest(actions.updatePitaMax, updatePitaMaxWorker),
    takeLatest(actions.uploadPita, uploadPitaWorker),
  ])
}
