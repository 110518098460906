import { FIELD_NAMES } from 'const/forms'
import { ENTITY_NAMES } from 'const/api'
import { DEFAULT_PAYMENT_COLUMNS } from 'pages/CustomerService/columns'
import { mapFilters, mapSorters } from 'helpers/jsonAPI'

export const formatPreferences = (id, settings) => ({
  data: {
    type: ENTITY_NAMES.admins,
    id,
    attributes: {
      [FIELD_NAMES.displayPreference]: settings,
    },
  },
})

export const formatAdminData = (admin) => ({
  ...admin,
  data: {
    ...admin.data,
    attributes: {
      ...admin.data.attributes,
      [FIELD_NAMES.displayPreference]:
        admin.data.attributes[FIELD_NAMES.displayPreference] ||
        DEFAULT_PAYMENT_COLUMNS,
    },
  },
})

export const formatPayloadForFetchRequest = ({
  page,
  filters,
  sorter,
  search,
} = {}) => ({
  page: page || 1,
  filters: mapFilters(filters),
  sorter: mapSorters(sorter),
  search,
})

export const formatAdmin = (admin) => ({
  [FIELD_NAMES.id]: admin[FIELD_NAMES.id],
  [FIELD_NAMES.name]: admin.attributes[FIELD_NAMES.name],
  [FIELD_NAMES.authorizedRoles]: admin.attributes[FIELD_NAMES.authorizedRoles],
  [FIELD_NAMES.email]: admin.attributes[FIELD_NAMES.email],
  key: admin[FIELD_NAMES.id],
})

export const formatAdmins = (admins = {}) =>
  Object.values(admins).reduce(
    (result, admin) => ({
      ...result,
      [admin[FIELD_NAMES.id]]: formatAdmin(admin),
    }),
    {}
  ) || {}

export const formatAdminForRequests = ({
  [FIELD_NAMES.id]: id,
  [FIELD_NAMES.email]: email,
  key,
  ...values
}) => ({
  ...(id && { id }),
  type: ENTITY_NAMES.backofficeUsers,
  attributes: {
    ...(id ? {} : { email }),
    ...values,
  },
})

export const formatPasswordResetForRequests = ({
  [FIELD_NAMES.id]: id,
  [FIELD_NAMES.password]: password,
}) => ({
  id,
  type: ENTITY_NAMES.backofficeUsers,
  attributes: {
    password,
  },
})
