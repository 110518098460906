import { createSelector } from '@reduxjs/toolkit'

export const pitaSettingsState = ({ pitaSettings }) => pitaSettings

export const getIsLoaded = createSelector(
  pitaSettingsState,
  ({ isLoaded }) => isLoaded
)

export const getIsSavingActive = createSelector(
  pitaSettingsState,
  ({ isSavingActive }) => isSavingActive
)

export const isLoadingOrSavingActive = createSelector(
  [getIsLoaded, getIsSavingActive],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getData = createSelector(
  pitaSettingsState,
  ({ payload }) => payload
)
