import React from 'react'
import PropTypes from 'prop-types'
import { StyledSelect } from './DynamicColumns.styles'
import omitBy from 'lodash/omitBy'
import { PAYMENT_COLUMN_KEY_TO_TITLE } from 'domains/user/const'
import { prepareSettings } from 'domains/admin/helpers'

const DynamicColumns = ({ options, selectedOptions, onChange }) => {
  const filteredOptions = Object.keys(
    omitBy(options, (_, key) => selectedOptions[key])
  )
  const formatted = Object.keys(selectedOptions).map((key) => ({
    label: PAYMENT_COLUMN_KEY_TO_TITLE[key],
    value: key,
  }))
  const handleChange = (selectedItems) => {
    onChange(prepareSettings(selectedItems))
  }

  return (
    <StyledSelect
      labelInValue
      mode="multiple"
      placeholder="Displayed columns"
      value={formatted}
      onChange={handleChange}
      style={{ width: '100%' }}
    >
      {filteredOptions.map((option) => (
        <StyledSelect.Option key={option} value={option}>
          {PAYMENT_COLUMN_KEY_TO_TITLE[option]}
        </StyledSelect.Option>
      ))}
    </StyledSelect>
  )
}

DynamicColumns.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      show: PropTypes.bool.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      show: PropTypes.bool.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
}

export default DynamicColumns
