import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Spin, Typography } from 'antd'
import ShopGroupsSwitches from 'pages/Shops/ShopGroupsSwitches/ShopGroupsSwitches'
import ShopCategoriesSelect from 'components/ShopCategoriesSelect'
import { getIsSavingActive } from 'domains/shops/selectors'
import {
  Container,
  ContentContainer,
  SectionContainer,
} from './ShopGroupsCategoriesSettings.styles'

const ShopGroupsCategoriesSettings = () => {
  const isSavingActive = useSelector(getIsSavingActive)

  return (
    <Container>
      <Spin spinning={isSavingActive} size="large">
        <ContentContainer>
          <SectionContainer>
            <Typography.Title level={4}>Shop groups settings</Typography.Title>
            <ShopGroupsSwitches />
          </SectionContainer>
          <SectionContainer>
            <Typography.Title level={4}>
              Shop categories settings
            </Typography.Title>
            <ShopCategoriesSelect />
          </SectionContainer>
        </ContentContainer>
      </Spin>
    </Container>
  )
}

ShopGroupsCategoriesSettings.propTypes = {
  id: PropTypes.any,
  closeModal: PropTypes.func.isRequired,
}

ShopGroupsCategoriesSettings.defaultTypes = {
  id: null,
}

export default ShopGroupsCategoriesSettings
