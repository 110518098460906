import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import FieldMarketingPageURL from 'components/FieldMarketingPageURL'
import FormItem from 'components/FormItem/FormItem'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { validMarketingPageUrl } from 'helpers/validators'
import { FIELD_NAMES } from 'const/forms'
import { MAX_NUMBER_OF_MARKETING_LINKS } from 'const/marketingPages'
import { AddButton } from './FieldMarketingPageURLs.styles'

const FieldMarketingPageURLs = ({ isEmpty }) => {
  const addRecord = (push) => () => {
    const newRecord = {
      [FIELD_NAMES.url]: '',
      [FIELD_NAMES.trafficPercentage]: 0,
    }
    push(newRecord)
  }

  return (
    <>
      <FieldArray name={FIELD_NAMES.marketingPagesUrls}>
        {({ fields }) => (
          <>
            <FormItem $isEmpty={isEmpty} $fullWidth>
              {fields.map((name, index) => (
                <Field
                  key={name}
                  name={name}
                  removeHandler={() => fields.remove(index)}
                  validate={validMarketingPageUrl}
                  component={FieldMarketingPageURL}
                />
              ))}
              <AddButton
                size="default"
                type="dashed"
                block
                disabled={fields.length === MAX_NUMBER_OF_MARKETING_LINKS}
                onClick={addRecord(fields.push)}
              >
                <PlusOutlined /> Add new url
              </AddButton>
            </FormItem>
            {isEmpty && (
              <AddButton
                size="default"
                type="dashed"
                block
                disabled={fields.length === MAX_NUMBER_OF_MARKETING_LINKS}
                onClick={addRecord(fields.push)}
              >
                <PlusOutlined /> Add new url
              </AddButton>
            )}
          </>
        )}
      </FieldArray>
    </>
  )
}

export default FieldMarketingPageURLs
