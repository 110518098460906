import styled from 'styled-components'
import { Button } from 'antd'

export const Action = styled(Button)`
  margin-left: 10px;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
