import { all, put, select, take, takeEvery, call } from 'redux-saga/effects'

import { actions as adPackagesActions } from 'domains/adPackages'
import { startLoading, finishLoading } from 'reducers/layoutSlice'
import * as actions from 'actions/views/adPackages'
import { selectors as adPackageSelectors } from 'domains/adPackages'
import isEqual from 'lodash/isEqual'

function* getRequestOptionsForAdPackagesFetching(payload) {
  try {
    const [sorter, filters, page, search] = yield all([
      select(adPackageSelectors.getSorter),
      select(adPackageSelectors.getFilters),
      select(adPackageSelectors.getPage),
      select(adPackageSelectors.getSearchString),
    ])
    const newFiltersOrSorter =
      !isEqual(payload?.filters, filters) || !isEqual(payload?.sorter, sorter)
    const newPage = newFiltersOrSorter ? 1 : payload?.page || page

    return {
      page: newPage,
      filters: payload?.filters || filters,
      sorter: payload?.sorter || sorter,
      search: payload?.hasOwnProperty('search')
        ? payload?.search || ''
        : search,
    }
  } catch (error) {
    console.error(error)
  }
}

function* getAdPackagesWorker({ payload } = {}) {
  try {
    if (window.location.pathname === '/shops') return
    const requestBody = yield call(
      getRequestOptionsForAdPackagesFetching,
      payload
    )
    yield all([
      put(startLoading()),
      put(adPackagesActions.resetFiltersAndSorter()),
      put(adPackagesActions.fetchAdPackagesRequest(requestBody)),
    ])
    yield take([
      adPackagesActions.fetchAdPackagesSuccess,
      adPackagesActions.fetchAdPackagesFailure,
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* fetchAdPackagesWorker({ payload } = {}) {
  try {
    yield put(startLoading())
    const requestBody = yield call(
      getRequestOptionsForAdPackagesFetching,
      payload
    )

    yield put(adPackagesActions.fetchAdPackagesRequest(requestBody))

    yield take([
      adPackagesActions.fetchAdPackagesSuccess,
      adPackagesActions.fetchAdPackagesFailure,
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

export default function* () {
  yield all([
    getAdPackagesWorker(),
    takeEvery(actions.fetchAdPackages, fetchAdPackagesWorker),
  ])
}
