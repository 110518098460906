import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const iconStyles = `
  font-size: 24px;
  margin-right: 10px;
`

export const ActiveCb = styled(CheckCircleTwoTone)`
  ${iconStyles}
`

export const InactiveCb = styled(CloseCircleTwoTone)`
  ${iconStyles}
`
