import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'react-final-form-arrays'
import { EMPTY_IMAGE_SET, LOGO, MAX_NUMBER_OF_SET } from 'const/images'
import { FIELD_NAMES } from 'const/forms'
import {
  impressionsPropType,
  setFullPropType,
  setPropType,
  retailerPropType,
} from 'const/propTypes'
import {
  AddButton,
  Content,
  Images,
  Text,
  Header,
  RetailerData,
  RetailerText,
  Name,
  Names,
} from './Sets.styles'
import ImageSet from './ImageSet'
// import Impressions from './Impressions'
import SetInfo from './SetInfo'
import { Field } from 'react-final-form'
import ImagePicker from 'components/ImagePicker'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { selectors as generalSelectors } from 'domains/general'
import {
  deletedSets,
  getIsLoaded,
  getIsSavingActive,
} from 'domains/retailers/selectors'
import RetailerCountry from 'components/RetailerCountry'
import SyncImages from './SyncImages'
import useAction from 'utils/useAction'
import { saveDeletedSets } from 'domains/retailers/actions'

const addRecord = (push) => () => push(EMPTY_IMAGE_SET)

const Sets = ({
  // impressions,
  fullData,
  showInfo,
  retailer,
  values,
  disableCopying,
  disableCopyingReason,
  disableRefreshButton,
}) => {
  const imageSets = useSelector(
    generalSelectors.getSortedImagesByGroupPositions
  )
  const isLoaded = useSelector(getIsLoaded)
  const isSavingActive = useSelector(getIsSavingActive)
  const deletedFields = useSelector(deletedSets)
  const setDeletedFields = useAction(saveDeletedSets)

  const deleteHandler = useCallback(
    (fields, index) => () => {
      if (!fullData?.[index]) {
        fields.remove(index)
      } else {
        fields.update(index, {
          ...fields.value[index],
          [FIELD_NAMES.deleted]: true,
        })
        setDeletedFields([...deletedFields, index])
      }
    },
    [deletedFields, fullData, setDeletedFields]
  )

  const restoreHandler = (fields, index) => () => {
    setDeletedFields(deletedFields.filter((item) => item !== index))
    fields.update(index, {
      ...fields.value[index],
      [FIELD_NAMES.deleted]: false,
    })
  }

  return (
    <FieldArray name={FIELD_NAMES.set}>
      {({ fields }) => (
        <>
          <Header>
            <Field
              name={LOGO.name}
              cropSize={LOGO.cropSize}
              forceInvalid
              component={ImagePicker}
              hasCropper
            />
            <RetailerData>
              <RetailerText>
                Retailer name: <strong>{retailer[FIELD_NAMES.name]}</strong>
              </RetailerText>
              <RetailerText>
                Retailer Id: <strong>{retailer[FIELD_NAMES.id]}</strong>
              </RetailerText>
              <RetailerCountry retailer={retailer} />
            </RetailerData>
            <SyncImages
              retailer={retailer}
              disabled={disableCopying}
              disabledReason={disableCopyingReason}
              disableRefreshButton={disableRefreshButton}
            />
          </Header>
          {!showInfo ? (
            <>
              <Content>
                <Images>
                  {(isLoaded || isSavingActive) && (
                    <Names>
                      {imageSets.map((image) => (
                        <Tooltip key={image.name} title={image.tooltip}>
                          <Name>{image.label}</Name>
                        </Tooltip>
                      ))}
                    </Names>
                  )}
                  {fields.map((name, index) => {
                    const isDeleted = deletedFields.includes(index)
                    const isLocked = fullData?.[index]?.[FIELD_NAMES.isLocked]
                    return (
                      <ImageSet
                        isHidden={
                          values[FIELD_NAMES.set][index]?.[FIELD_NAMES.hidden]
                        }
                        isDeleted={isDeleted}
                        isLocked={isLocked}
                        fullData={fullData?.[index]}
                        name={name}
                        key={name}
                      >
                        <SetInfo
                          isNullable={!values[FIELD_NAMES.set][index]}
                          isDeleted={isDeleted}
                          isLocked={isLocked}
                          fieldName={name}
                          setId={index}
                          deleteHandler={deleteHandler(fields, index)}
                          restoreHandler={restoreHandler(fields, index)}
                          fullData={fullData}
                        />
                      </ImageSet>
                    )
                  })}
                  {/*TODO: add when API is ready*/}
                  {/*{!!fields.length && (*/}
                  {/*  <Impressions impressions={impressions}/>*/}
                  {/*)}*/}
                </Images>
              </Content>
            </>
          ) : (
            <Text>
              Please create at least one image set for each gender to save
              changes
            </Text>
          )}
          {fields.length - deletedFields.length < MAX_NUMBER_OF_SET && (
            <AddButton onClick={addRecord(fields.push)} />
          )}
        </>
      )}
    </FieldArray>
  )
}

Sets.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  showInfo: PropTypes.bool,
  // eslint-disable-next-line react/boolean-prop-naming
  disableCopying: PropTypes.bool.isRequired,
  disableCopyingReason: PropTypes.string,
  impressions: impressionsPropType.isRequired,
  fullData: PropTypes.arrayOf(setFullPropType),
  values: PropTypes.shape({
    [FIELD_NAMES.set]: PropTypes.arrayOf(setPropType),
  }),
  retailer: retailerPropType.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  disableRefreshButton: PropTypes.bool.isRequired,
}

Sets.defaultProps = {
  disableCopyingReason: null,
}

export default Sets
