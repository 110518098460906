import React, { useEffect } from 'react'
import { deleteShopOffer, fetchShopOffers } from 'actions/views/shops'
import useAction from 'utils/useAction'
import {
  getCurrentShopId,
  getIsShopOffersLoaded,
  getShopOffers,
  getShopOffersFilters,
  getShopOffersPage,
  getShopOffersPageCount,
  getShopOffersSorter,
  getIsSavingActive,
  getIsShopOffersDataFilteredOrSorted,
} from 'domains/shops/selectors'
import { useSelector } from 'react-redux'
import { Button, Spin } from 'antd'
import { Buttons, StyledTable } from '../Shops.styles'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import getColumns from './shopOffersColumns'
import { openConfirmModal, openShopOffersModal } from 'reducers/modals/actions'
import { FIELD_NAMES } from 'const/forms'
import { PAGE_SIZE } from '../../../const/tables'

const ShopOffersTab = () => {
  const currentShopId = useSelector(getCurrentShopId)
  const shopOffers = useSelector(getShopOffers)
  const pageCount = useSelector(getShopOffersPageCount)
  const filters = useSelector(getShopOffersFilters)
  const sorter = useSelector(getShopOffersSorter)
  const page = useSelector(getShopOffersPage)
  const isLoaded = useSelector(getIsShopOffersLoaded)
  const isSavingActive = useSelector(getIsSavingActive)

  const fetchShopOffersFunc = useAction(fetchShopOffers)
  const openShopOffersModalFunc = useAction(openShopOffersModal)
  const deleteShopOfferFunc = useAction(deleteShopOffer)
  const openConfirmModalFunc = useAction(openConfirmModal)

  const isDataFilteredOrSorted = useSelector(
    getIsShopOffersDataFilteredOrSorted
  )

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  const handleResetFilters = () => {
    fetchShopOffersFunc({
      filters: {},
      sorter: {},
      page: 1,
    })
  }

  const handleDeleteShopOffer = (shopOffer) => {
    const callback = () => deleteShopOfferFunc(shopOffer[FIELD_NAMES.id])
    const text =
      `Are you sure you want to delete shop offer (ID: ${
        shopOffer[FIELD_NAMES.id]
      })? ` + "This action can't be revoked."
    openConfirmModalFunc({ callback, text })
  }

  const onTableChange = (pagination, filters, sorter) => {
    fetchShopOffersFunc({
      page: pagination.current,
      filters,
      sorter,
    })
  }

  const handleEditOffer = (id) => {
    openShopOffersModalFunc({ id })
  }

  const handleCreateOffer = () => {
    openShopOffersModalFunc()
  }

  useEffect(() => {
    if (currentShopId) fetchShopOffersFunc()
  }, [currentShopId])

  return (
    <Spin spinning={isSavingActive} size="large">
      <Buttons>
        <Button
          onClick={handleResetFilters}
          disabled={!isLoaded || !isDataFilteredOrSorted}
          style={{ marginLeft: '12px' }}
        >
          Reset filters
        </Button>

        <Button
          onClick={handleCreateOffer}
          disabled={!currentShopId}
          style={{ marginBottom: '10px' }}
        >
          Create offer
        </Button>
      </Buttons>

      <StyledTable
        onChange={onTableChange}
        dataSource={shopOffers}
        columns={getColumns(
          filters,
          sorter,
          handleEditOffer,
          handleDeleteShopOffer
        )}
        size="medium"
        pagination={paginationConfig}
        rowKey={getRowKey}
        rowClassName={getRegularRowClass}
        bordered
        sticky={isLoaded}
      />
    </Spin>
  )
}

export default ShopOffersTab
