import { all, call, put, takeLatest } from 'redux-saga/effects'
import normalized from 'json-api-normalizer'
import * as actions from './actions'
import * as api from './api'
import { getSortedIds } from 'helpers/jsonAPI'

function* fetchPaymentsUploadsWorker({ payload }) {
  try {
    const { data } = yield call(api.getPaymentsUploads, payload)
    const normalizedData = normalized(data, {
      camelizeKeys: false,
      camelizeTypeValues: false,
    })

    yield put(
      actions.fetchPaymentUploadsSuccess({
        ...payload,
        page: payload.page,
        pageCount: data.meta.page_count,
        paymentUploads: normalizedData,
        sortedIds: getSortedIds(data.data),
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchPaymentUploadsFailure())
  }
}

function* fetchBudgetWorker() {
  try {
    const { data } = yield call(api.getBudget)

    if (data) {
      yield put(actions.fetchBudgetSuccess(data))
    } else {
      yield put(actions.fetchBudgetFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.fetchBudgetFailure())
  }
}

function* fetchPaymentsWorker({ payload }) {
  try {
    const { data } = yield call(api.getPayments, payload)
    const normalizedData = normalized(data, {
      camelizeKeys: false,
      camelizeTypeValues: false,
    })

    yield put(
      actions.fetchPaymentsSuccess({
        ...payload,
        page: payload.page,
        pageCount: data.meta.page_count,
        payments: normalizedData,
        sortedIds: getSortedIds(data.data),
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchPaymentsFailure())
  }
}

function* uploadPaymentListWorker({ payload }) {
  try {
    const { data } = yield call(api.uploadPaymentList, payload)
    if (data) {
      yield put(actions.uploadPaymentListSuccess(payload))
    } else {
      yield put(actions.uploadPaymentListFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.uploadPaymentListFailure())
  }
}

function* deletePaymentWorker({ payload }) {
  try {
    const { data } = yield call(api.deletePayment, payload)
    if (data) {
      yield put(actions.deletePaymentSuccess(payload))
    } else {
      yield put(actions.deletePaymentFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deletePaymentFailure())
  }
}

function* approvePaymentWorker({ payload }) {
  try {
    const { data } = yield call(api.approvePayment, payload)
    if (data) {
      yield put(actions.approvePaymentSuccess(payload))
    } else {
      yield put(actions.approvePaymentFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.approvePaymentFailure())
  }
}

function* resendPaymentWorker({ payload }) {
  try {
    const { data } = yield call(api.resendPayment, payload)
    if (data) {
      yield put(actions.resendPaymentSuccess(payload))
    } else {
      yield put(actions.resendPaymentFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.resendPaymentFailure())
  }
}

function* rejectPaymentWorker({ payload }) {
  try {
    const { data } = yield call(api.rejectPayment, payload)
    if (data) {
      yield put(actions.rejectPaymentSuccess(payload))
    } else {
      yield put(actions.rejectPaymentFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.rejectPaymentFailure())
  }
}

function* retractPaymentWorker({ payload }) {
  try {
    const { data } = yield call(api.retractPayment, payload)
    if (data) {
      yield put(actions.retractPaymentSuccess(payload))
    } else {
      yield put(actions.retractPaymentFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.retractPaymentFailure())
  }
}

function* updatePaymentWorker({ payload }) {
  try {
    const { data } = yield call(api.updatePayment, payload)
    if (data) {
      yield put(actions.updatePaymentSuccess(payload))
    } else {
      yield put(actions.updatePaymentFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updatePaymentFailure())
  }
}

function* createPaymentWorker({ payload }) {
  try {
    const { data } = yield call(api.createPayment, payload)
    if (data) {
      yield put(actions.createPaymentSuccess(payload))
    } else {
      yield put(actions.createPaymentFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createPaymentFailure())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.uploadPaymentList, uploadPaymentListWorker),
    takeLatest(actions.fetchPaymentUploads, fetchPaymentsUploadsWorker),
    takeLatest(actions.fetchBudget, fetchBudgetWorker),
    takeLatest(actions.fetchPayments, fetchPaymentsWorker),
    takeLatest(actions.deletePayment, deletePaymentWorker),
    takeLatest(actions.approvePayment, approvePaymentWorker),
    takeLatest(actions.resendPayment, resendPaymentWorker),
    takeLatest(actions.rejectPayment, rejectPaymentWorker),
    takeLatest(actions.retractPayment, retractPaymentWorker),
    takeLatest(actions.updatePayment, updatePaymentWorker),
    takeLatest(actions.createPayment, createPaymentWorker),
  ])
}
