import React from 'react'
import { useSelector } from 'react-redux'
import {
  getFilters,
  getIsDataFilteredOrSorted,
  getIsLoaded,
  getPage,
  getPageCount,
  getSearchString,
  getSorter,
  getSortedCampaigns,
} from 'domains/campaigns/selectors'
import useAction from 'utils/useAction'
import { deleteCampaign, fetchCampaigns } from 'actions/views/campaign'
import { StyledTable, Wrapper, Buttons, Breadcrumbs } from './Campaign.styles'
import SearchInTableBar from 'components/SearchInTableBar'
import { Button } from 'antd'
import getColumns from './columns'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import { FIELD_NAMES } from 'const/forms'
import { openConfirmModal, openCampaignModal } from 'reducers/modals/actions'
import isEqual from 'lodash/isEqual'

const PAGE_SIZE = 20
const SCROLL = { x: 900 }
export const HEADER_OFFSET = { offsetHeader: 49 }

const Campaigns = () => {
  const isLoaded = useSelector(getIsLoaded)
  const page = useSelector(getPage)
  const search = useSelector(getSearchString)
  const pageCount = useSelector(getPageCount)
  const filters = useSelector(getFilters)
  const sorter = useSelector(getSorter)
  const dataSource = useSelector(getSortedCampaigns)
  const isDataFilteredOrSorted = useSelector(getIsDataFilteredOrSorted)

  const fetchCampaignsFunc = useAction(fetchCampaigns)
  const deleteCampaignFunc = useAction(deleteCampaign)
  const openConfirmModalFunc = useAction(openConfirmModal)
  const openCampaignModalFunc = useAction(openCampaignModal)

  const onSearchStringChange = (search) =>
    fetchCampaignsFunc({
      page: 1,
      search,
      filters,
      sorter,
    })

  const handleEditCampaign = (id) => {
    openCampaignModalFunc({ id })
  }

  const handleDeleteCampaign = (campaign) => {
    const callback = () => deleteCampaignFunc(campaign[FIELD_NAMES.id])
    const text =
      `Are you sure you want to delete campaign (ID: ${
        campaign[FIELD_NAMES.id]
      })? ` + "This action can't be revoked."
    openConfirmModalFunc({ callback, text })
  }

  const handleCreateCampaign = () => openCampaignModalFunc()

  const onTableChange = (pagination, newFilters, newSorter) => {
    const pageChanged = page !== pagination.current
    const newFiltersOrSorter =
      !pageChanged &&
      (!isEqual(newFilters, filters) || !isEqual(newSorter, sorter))
    fetchCampaignsFunc({
      page: newFiltersOrSorter ? 1 : pagination.current,
      search,
      filters: newFilters,
      sorter: newSorter,
    })
  }

  const handleResetFilters = () =>
    fetchCampaignsFunc({
      search,
      page: 1,
      filters: {},
      sorter: {},
    })

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  return (
    <Wrapper>
      <Breadcrumbs>Promotions → Campaigns</Breadcrumbs>
      <SearchInTableBar
        placeholder="Search brands here"
        onSubmit={onSearchStringChange}
        value={search}
      >
        <Buttons>
          <Button
            onClick={handleCreateCampaign}
            type="primary"
            disabled={!isLoaded}
          >
            Create
          </Button>
          <Button
            onClick={handleResetFilters}
            disabled={!isLoaded || !isDataFilteredOrSorted}
          >
            Reset filters
          </Button>
        </Buttons>
      </SearchInTableBar>
      <StyledTable
        onChange={onTableChange}
        showSorterTooltip={false}
        columns={getColumns(
          filters,
          sorter,
          handleEditCampaign,
          handleDeleteCampaign
        )}
        dataSource={dataSource}
        expandable={false}
        pagination={paginationConfig}
        sticky={isLoaded && HEADER_OFFSET}
        scroll={SCROLL}
        bordered
        loading={false}
        rowKey={getRowKey}
        rowClassName={getRegularRowClass}
      />
    </Wrapper>
  )
}

export default Campaigns
