import React from 'react'
import { withRouter } from 'react-router-dom'
import { FORM_NAMES } from 'const/forms'
import Retailers from './Retailers'
import ROUTES from 'const/routes'

const MAP_ROUTE_TO_FORM_NAME = {
  [ROUTES.EDITORIAL]: FORM_NAMES.EDITORIAL_CHANGES,
  [ROUTES.RETAILERS]: FORM_NAMES.STORE_CHANGES,
}

const RetailersMapper = ({ location: { pathname } }) => (
  <Retailers key={pathname} formName={MAP_ROUTE_TO_FORM_NAME[pathname]} />
)

export default withRouter(RetailersMapper)
