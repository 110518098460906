import { all, put, takeLatest } from 'redux-saga/effects'
import { startLoading, finishLoading } from 'reducers/layoutSlice'
import { actions as algorithmsActions } from 'domains/algorithms'
import * as actions from 'actions/views/algorithms'

function* postKProjectDatesWorker({ payload }) {
  try {
    yield all([
      put(startLoading()),
      put(algorithmsActions.postKProjectDates(payload.values)),
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* postIntervalTestWorker() {
  try {
    yield all([put(startLoading()), put(algorithmsActions.postIntervalTest())])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.postKProjectDates, postKProjectDatesWorker),
    takeLatest(actions.postIntervalTest, postIntervalTestWorker),
  ])
}
