import React, { useRef, useState } from 'react'
import { Col, Row, Spin } from 'antd'
import { Field } from 'react-final-form'
import { apiInstance } from 'utils/request'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'
import debounce from 'lodash/debounce'
import { requiredNested } from 'helpers/validators'
import { StyledSelect } from './SearchNetwork.styles'

const { Option } = StyledSelect

const SearchNetworks = ({ isGlobalSelected }) => {
  const lastFetchId = useRef(0)
  const [data, setData] = useState([])
  const [fetching, setFetching] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [isGlobal, setIsGlobal] = useState(isGlobalSelected)

  const fetchRetailers = (value) => {
    lastFetchId.current += 1
    const fetchId = lastFetchId.current
    setData([])
    setFetching(true)
    apiInstance
      .get('/admin/networks')
      .then((response) => response.data.networks)
      .then((body) => {
        if (fetchId !== lastFetchId.current) {
          return
        }
        const data = body
        setData(data)
        setFetching(false)
      })
  }

  const fetchRetailersFunc = debounce(fetchRetailers, 800)

  return (
    <Field name={'network'} validate={requiredNested}>
      {(props) => (
        <>
          <FieldWrapper label="Network" meta={props.meta} required>
            <StyledSelect
              invalid={props.meta.touched && props.meta.error}
              labelInValue
              value={props.input.value || undefined} // to fix bug with placeholder's displaying
              showSearch
              placeholder="Search Networks"
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={fetchRetailersFunc}
              onClick={() => fetchRetailers('')}
              onChange={(value) => {
                const item = data?.find((item) => item.value === value.value)
                setFetching(false)
                setIsGlobal(!!item?.isGlobal)
                setData([])
                props.input.onChange(value)
              }}
            >
              {data.map((value) => (
                <Option key={value} value={value}>
                  <Row>
                    <Col span={3}>{value}</Col>
                  </Row>
                </Option>
              ))}
            </StyledSelect>
          </FieldWrapper>
        </>
      )}
    </Field>
  )
}

export default SearchNetworks
