import styled from 'styled-components'
import { Button, Form } from 'antd'

export const Text = styled.div`
  margin-bottom: 24px;
  font-size: 14px;
  white-space: pre-wrap;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledButton = styled(Button)`
  margin-left: 10px;
`
export const StyledForm = styled(Form)`
  .ant-form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .ant-form-item-control {
    width: 100%;
  }
`
