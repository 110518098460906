import { createSelector } from '@reduxjs/toolkit'
import { areFiltersAndSorterEmpty } from 'helpers/filters'
import { memoize } from 'lodash'

export const bonusesState = ({ bonuses }) => bonuses

export const getIsLoaded = createSelector(
  bonusesState,
  ({ isLoaded }) => isLoaded
)

export const getIsSavingActive = createSelector(
  bonusesState,
  ({ isSavingActive }) => isSavingActive
)

export const getEntities = createSelector(
  bonusesState,
  ({ entities }) => entities
)

export const getBonusById = createSelector(getEntities, (bonus) =>
  memoize((id) => (id ? bonus.find((item) => item.id === id) : null))
)

export const isLoadingOrSavingActive = createSelector(
  [getIsLoaded, getIsSavingActive],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getSearchString = createSelector(
  bonusesState,
  ({ search }) => search
)

export const getPage = createSelector(bonusesState, ({ page }) => page)

export const getFilters = createSelector(bonusesState, ({ filters }) => filters)

export const getSorter = createSelector(bonusesState, ({ sorter }) => sorter)

export const getIsDataFilteredOrSorted = createSelector(
  [getFilters, getSorter],
  areFiltersAndSorterEmpty
)

export const getPageCount = createSelector(
  bonusesState,
  ({ pageCount }) => pageCount
)

export const getSortedIds = createSelector(
  bonusesState,
  ({ sortedIds }) => sortedIds
)
