import { FIELD_NAMES } from '../const/forms'
import isEmpty from 'lodash/isEmpty'
import {
  MAP_FILTER_TO_FILTER_NAME,
  MAP_SORTER_TO_FILTER_NAME,
  REDUCE_SORT_ORDER,
} from './filters'
import omitBy from 'lodash/omitBy'

export const getSortedIds = (items) => {
  return items?.map((item) => item[FIELD_NAMES.id]) || []
}
export const mapFilters = (data = {}) => {
  const filters = omitBy(data, isEmpty)
  if (isEmpty(filters)) {
    return {}
  }
  return Object.entries(filters).reduce((result, [name, value]) => {
    return {
      ...result,
      [MAP_FILTER_TO_FILTER_NAME[name]]: value.join(',') || '',
    }
  }, {})
}

export const mapSorters = (sorter, defaultFieldForSorting = FIELD_NAMES.id) => {
  if (!sorter?.order) return `-${defaultFieldForSorting}`
  const { order, columnKey } = sorter
  return `${REDUCE_SORT_ORDER[order]}${MAP_SORTER_TO_FILTER_NAME[columnKey]}`
}
