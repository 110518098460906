import styled from 'styled-components'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'

const isLocked = ({ isLocked }) =>
  isLocked
    ? `
      opacity: 0.2;
      pointer-events: none;
      cursor: not-allowed;
    `
    : ''

export const HideButton = styled(EyeInvisibleOutlined)`
  font-size: 24px;
  cursor: pointer;
  color: #1890ff;
  ${isLocked}

  &:hover {
    color: #eb2f96;
  }
`

export const ShowButton = styled(EyeOutlined)`
  font-size: 24px;
  cursor: pointer;
  color: #1890ff;
  ${isLocked}

  &:hover {
    color: #52c41a;
  }
`
