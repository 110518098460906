import React from 'react'
import { InputNumber as AntInput } from 'antd'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'

const InputNumber = ({
  input,
  meta,
  label,
  description,
  hint,
  required,
  ...rest
}) => (
  <FieldWrapper hint={hint} label={label} meta={meta} required={required}>
    <AntInput
      invalid={(!!(meta.modified && meta.error)).toString()}
      placeholder={description}
      onChange={input.onChange}
      type="number"
      {...input}
      {...rest}
    />
  </FieldWrapper>
)

export default InputNumber
