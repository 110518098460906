import { Button, message } from 'antd'
import React from 'react'
import axios from 'axios'
import { BO_API_URL } from 'const/env'

const getList = () => {
  axios
    .get(`${BO_API_URL}/retailers/export`, { responseType: 'blob' })
    .then((data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'stores.xlsx')
      document.body.appendChild(link)
      link.click()
      link.remove()
      message.success('Successfully.')
    })
    .catch((e) => {
      message.error(e.message)
    })
}

const ExportBtn = () => {
  return (
    <Button type="primary" onClick={getList}>
      Export store list
    </Button>
  )
}

export default ExportBtn
