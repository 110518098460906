import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Select as AntSelect } from 'antd'
import countries from 'const/country'
import { StyledSelect } from '../RegionSelect/RegionSelect.styles'
import { useSelector } from 'react-redux'
import { getRegion } from 'domains/adPackages/selectors'
import { COUNTRIES_BY_REGION } from 'const/regions'

const Select = ({ input: { value, onChange }, isDisabled }) => {
  const regionGroup = useSelector(getRegion)

  const filterOption = (search, option) => {
    search = search.toLowerCase()
    return (
      option.value.toLowerCase().includes(search) ||
      option.name.toLowerCase().includes(search)
    )
  }

  const handleChange = (newValue) => {
    onChange(newValue)
  }

  useEffect(() => {
    if (isDisabled) {
      onChange([])
    }
  }, [isDisabled])

  useEffect(() => {
    if (regionGroup) {
      const newValue = COUNTRIES_BY_REGION[regionGroup].reduce(
        (result, country) => {
          const fullCountry = countries.find((item) => item.name === country)

          if (fullCountry && !result.includes(fullCountry?.code)) {
            return [...result, fullCountry?.code]
          }

          return result
        },
        value
      )

      onChange(newValue)
    }
  }, [regionGroup])

  return (
    <StyledSelect
      mode="multiple"
      value={value}
      disabled={isDisabled}
      filterOption={filterOption}
      placeholder="Search by name"
      showSearch
      onChange={handleChange}
    >
      {countries.map((country) => {
        return (
          <AntSelect.Option
            key={country.code}
            value={country.code}
            name={country.name}
          >
            {country.code} - {country.name}
          </AntSelect.Option>
        )
      })}
    </StyledSelect>
  )
}

Select.propTypes = {
  isDisabled: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
}

Select.defaultProps = {
  isDisabled: false,
}

export default Select
