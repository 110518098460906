import { createAction } from '@reduxjs/toolkit'

export const fetchAppVersions = createAction(
  'mobileAppVersion/fetchMobileAppVersions'
)
export const updateAppVersion = createAction(
  'mobileAppVersion/updateMobileAppVersion'
)
export const createAppVersion = createAction(
  'mobileAppVersion/createMobileAppVersion'
)
export const deleteAppVersion = createAction(
  'mobileAppVersion/deleteMobileAppVersion'
)
