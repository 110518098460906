import { createSelector } from '@reduxjs/toolkit'
import last from 'lodash/last'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const modalsState = ({ modals }) => modals

export const modals = createSelector(modalsState, ({ modals }) => modals)

export const isModalOpened = createSelector(
  modalsState,
  ({ modals }) => !isEmpty(modals)
)

export const lastModal = createSelector(modals, (modals) => last(modals))

export const prevModal = createSelector(modals, (modals) =>
  get(modals, modals?.length - 2)
)

export const lastModalType = createSelector(lastModal, (modal) =>
  get(modal, 'type')
)

export const prevModalType = createSelector(prevModal, (modal) =>
  get(modal, 'type')
)

export const lastModalData = createSelector(lastModal, (modal) =>
  get(modal, 'data')
)
