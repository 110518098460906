import reducer from './reducer'
import { createAction } from '@reduxjs/toolkit'

export const {
  fetchAllLogsSuccess,
  fetchAllLogsFailure,
  setSearchString,
  saveLogsFiltersAndSorter,
  startSaving,
  finishSaving,
} = reducer.actions

export const fetchAllLogs = createAction('domains/adminLogs/fetchAllLogs')
export const changeSearchString = createAction(
  'domains/adminLogs/changeSearchString'
)
