import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Input, Button, Space } from 'antd'
import { SearchWrapper } from './SearchWrapper.styles.js'

export const Search = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  const [text, setText] = React.useState(selectedKeys[0])
  const inputRef = React.useRef()

  React.useEffect(() => {
    inputRef.current.select()
  }, [setSelectedKeys])

  return (
    <SearchWrapper>
      <Input
        value={text}
        ref={inputRef}
        onChange={(e) => setText(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => {
          setSelectedKeys(text)
          confirm()
        }}
      />
      <Space>
        <Button
          type="primary"
          htmlType="submit"
          icon={<SearchOutlined />}
          size="small"
          onClick={() => {
            setSelectedKeys(text)
            confirm()
          }}
        >
          Search
        </Button>
        <Button
          size="small"
          onClick={() => {
            clearFilters()
            setText('')
          }}
        >
          Reset
        </Button>
      </Space>
    </SearchWrapper>
  )
}

export default Search
