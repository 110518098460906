import ReactJson from 'react-json-view'
import React from 'react'
import Title from '../Title/Title'
import { Root } from './Json.styles'
import PropTypes from 'prop-types'

const Json = ({ src, title }) => (
  <Root>
    {title && <Title>{title}</Title>}
    <ReactJson
      src={JSON.parse(src)}
      theme="summerfruit:inverted"
      iconStyle="square"
      displayObjectSize={false}
      displayDataTypes={false}
      enableClipboard={false}
      quotesOnKeys={false}
      collapsed
    />
  </Root>
)

Json.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
}

Json.defaultProps = {
  title: null,
}

export default Json
