import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isSavingActive: false,
  kProjectInstalledData: null,
  intervalTestData: null,
  scoreData: null,
}

const algorithmsSlice = createSlice({
  name: 'algorithms',
  initialState,
  reducers: {
    startSaving: (state) => ({
      ...state,
      isSavingActive: true,
    }),
    finishSaving: (state) => ({
      ...state,
      isSavingActive: false,
    }),
    setKProjectInstalledData: (state, { payload }) => ({
      ...state,
      kProjectInstalledData: payload,
    }),
    setIntervalTestData: (state, { payload }) => ({
      ...state,
      intervalTestData: payload,
    }),
    setScoreData: (state, { payload }) => ({
      ...state,
      scoreData: payload,
    }),
    clearData: () => initialState,
  },
})

export default algorithmsSlice
