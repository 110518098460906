import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  createMarketingPage,
  updateMarketingPage,
} from 'actions/views/marketingPages'
import useAction from 'utils/useAction'
import { Row, Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/Input'
import { FIELD_NAMES } from 'const/forms'
import { selectors as marketingPagesSelectors } from 'domains/marketingPages'
import { StyledButton, StyledForm } from './MarketingPages.styles'
import arrayMutators from 'final-form-arrays'
import isEmpty from 'lodash/isEmpty'
import FieldMarketingPageURLs from 'components/FieldMarketingPageURLs'
import FormItem from 'antd/es/form/FormItem'
import isEqual from 'lodash/isEqual'
import { INVALID_PERCENTAGE_SUM } from '../../../const/errors'

const MarketingPages = ({ id, closeModal }) => {
  const isSavingActive = useSelector(marketingPagesSelectors.getIsSavingActive)
  const initialValues = useSelector(
    marketingPagesSelectors.getMarketingPageById
  )(id)
  const createMarketingPageFunc = useAction(createMarketingPage)
  const updateMarketingPageFunc = useAction(updateMarketingPage)

  const onSubmit = (data) => {
    if (id) updateMarketingPageFunc({ values: data, callback: closeModal })
    else createMarketingPageFunc({ values: data, callback: closeModal })
  }

  return (
    <Form
      destroyOnUnregister
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={initialValues}
      render={({
        handleSubmit,
        values,
        dirtyFields,
        hasValidationErrors,
        errors,
      }) => (
        <Spin spinning={isSavingActive} size="large">
          <StyledForm onSubmit={handleSubmit}>
            {id && (
              <FormItem $fullWidth>
                <Field name="id" label="ID" disabled component={Input} />
              </FormItem>
            )}
            <FieldMarketingPageURLs
              isEmpty={isEmpty(values[FIELD_NAMES.marketingPagesUrls])}
              isDirty={!!dirtyFields[FIELD_NAMES.marketingPagesUrls]}
            />
            <FormItem>
              <Row>Note: {INVALID_PERCENTAGE_SUM}</Row>
            </FormItem>
            <StyledButton
              type="primary"
              disabled={isEqual(initialValues, values) || hasValidationErrors}
              onClick={handleSubmit}
            >
              Save changes
            </StyledButton>
          </StyledForm>
        </Spin>
      )}
    />
  )
}

MarketingPages.propTypes = {
  id: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
}

MarketingPages.defaultTypes = {
  id: null,
}

export default MarketingPages
