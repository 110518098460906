import axios from 'axios'

let interceptorID

export function setAuthorizationToken(getToken) {
  axios.interceptors.request.eject(interceptorID)

  interceptorID = axios.interceptors.request.use(
    (config) => {
      if (getToken())
        config.headers.common['Authorization'] = `Bearer ${getToken()}`
      else delete config.headers.common['Authorization']
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axios.defaults.headers.common['Content-Type'] = 'application/json'
}
