import styled from 'styled-components'
import { Button, Form, Row } from 'antd'

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
`
export const StyledButton = styled(Button)`
  margin-left: 10px;
`
export const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 12px;
  }
  .ant-form-item-label {
    padding-bottom: 4px;
    font-weight: 500;
  }
  .anticon-minus-circle {
    font-size: 18px;
    float: right;
    margin-top: -2px;
  }
  .faq-item {
    margin-bottom: 24px;
    input,
    textarea {
      margin-bottom: 8px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
`

export const Type = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
`

export const HeaderRow = styled(Row)`
  & > div:first-child {
    flex: 0.5;
  }
`
