import { ENTITY_NAMES } from 'const/api'
import { FIELD_NAMES } from 'const/forms'
import { mapFilters, mapSorters } from 'helpers/jsonAPI'

export const formatLandingPage = (landingPage) => {
  return {
    [FIELD_NAMES.id]: landingPage[FIELD_NAMES.id],
    [FIELD_NAMES.name]: landingPage.attributes[FIELD_NAMES.name],
    [FIELD_NAMES.productPreview]:
      landingPage.attributes[FIELD_NAMES.productPreview],
    [FIELD_NAMES.landingPageUrl]:
      landingPage.attributes[FIELD_NAMES.landingPageUrl],
    [FIELD_NAMES.title]: landingPage.attributes[FIELD_NAMES.title],
    key: landingPage[FIELD_NAMES.id],
  }
}

export const formatLandingPages = (landingPages = {}) =>
  Object.values(landingPages).reduce(
    (result, landingPage) => ({
      ...result,
      [landingPage[FIELD_NAMES.id]]: formatLandingPage(landingPage),
    }),
    {}
  ) || {}

export const formatPayloadForFetchRequest = ({
  page,
  filters,
  sorter,
  search,
} = {}) => ({
  page: page || 1,
  filters: mapFilters(filters),
  sorter: mapSorters(sorter),
  search,
})

export const formatLandingPageForRequests = (
  {
    [FIELD_NAMES.id]: id,
    [FIELD_NAMES.productPreview]: productPreview,
    [FIELD_NAMES.landingPageUrl]: landingPageUrl,
    [FIELD_NAMES.name]: name,
    key,
    ...values
  },
  { isImageUpdate = false, isCreate = false } = {}
) => {
  const attributes = isImageUpdate
    ? { [FIELD_NAMES.productPreview]: productPreview }
    : { ...values, ...(isCreate ? { [FIELD_NAMES.name]: name } : {}) }

  return {
    ...(id && { id }),
    type: ENTITY_NAMES.landingPages,
    attributes,
  }
}
