import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from './api'
import { getSortedIds } from 'helpers/jsonAPI'
import { formatMarketingPagesResponse } from './formatters'

function* fetchMarketingPagesWorker({ payload }) {
  try {
    const { data } = yield call(api.getMarketingPages, payload)

    const normalizedData = formatMarketingPagesResponse(data.data)

    yield put(
      actions.fetchMarketingPagesSuccess({
        ...payload,
        page: payload.page,
        pageCount: data.page_count,
        marketing_pages: normalizedData,
        sortedIds: getSortedIds(data.data),
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchMarketingPagesFailure())
  }
}

function* createMarketingPageWorker({ payload }) {
  try {
    const { data } = yield call(api.createMarketingPage, payload)
    if (data) {
      yield put(actions.createMarketingPageSuccess(data))
    } else {
      yield put(actions.createMarketingPageFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createMarketingPageFailure())
  }
}

function* updateMarketingPageWorker({ payload }) {
  try {
    const { data } = yield call(api.updateMarketingPage, payload)
    if (data) {
      yield put(actions.updateMarketingPageSuccess(payload))
    } else {
      yield put(actions.updateMarketingPageFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateMarketingPageFailure())
  }
}

function* deleteMarketingPageWorker({ payload }) {
  try {
    const { data } = yield call(api.deleteMarketingPage, payload)
    if (data) {
      yield put(actions.deleteMarketingPageSuccess(payload))
    } else {
      yield put(actions.deleteMarketingPageFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteMarketingPageFailure())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchMarketingPagesRequest, fetchMarketingPagesWorker),
    takeLatest(actions.createMarketingPageRequest, createMarketingPageWorker),
    takeLatest(actions.updateMarketingPageRequest, updateMarketingPageWorker),
    takeLatest(actions.deleteMarketingPageRequest, deleteMarketingPageWorker),
  ])
}
