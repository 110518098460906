export const OFFER_TYPE_FILTERS = [
  { text: 'Coupon', value: 'coupon' },
  { text: 'Sale', value: 'sale' },
]

export const OFFER_TYPES = [
  { label: 'Coupon', value: 'coupon' },
  { label: 'Sale', value: 'sale' },
]

export const COUPONS_SOURCE_FILTERS = [
  { text: 'user', value: 'user' },
  { text: 'backoffice', value: 'backoffice' },
  { text: 'savings', value: 'savings' },
  { text: 'joinhoney', value: 'joinhoney' },
  { text: 'retailmenot', value: 'retailmenot' },
  { text: 'shoptagr', value: 'shoptagr' },
  { text: 'fmtc', value: 'fmtc' },
  { text: 'fake', value: 'fake' },
  { text: 'rakuten', value: 'rakuten' },
  { text: 'skimlinks', value: 'skimlinks' },
]

export const EXCLUSIVE_STATUS_FILTERS = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
]

export const EXCLUSIVE_STATUS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]
