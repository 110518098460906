import React from 'react'
import { Buttons, StyledTable } from '../Shops.styles'
import useAction from 'utils/useAction'
import {
  openCouponsSettingsModal,
  openShopModal,
} from 'reducers/modals/actions'
import { useSelector } from 'react-redux'
import * as localizedShopsSelector from 'domains/shops/selectors'
import { localizedShopsColumns } from './localizedShopsColumns'
import { fetchLocalizedShops } from 'actions/views/shops'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import { Button, Spin } from 'antd'
import { formatCountryCodesForFiltering } from 'const/shops'
import { PAGE_SIZE } from '../../../const/tables'

const LocalizedShopsTab = () => {
  const dataSource = useSelector(localizedShopsSelector.getLocalizedShops)
  const currentShopId = useSelector(localizedShopsSelector.getCurrentShopId)
  const availableCountryCodes = useSelector(
    localizedShopsSelector.getLocalizedShopsCountryCodes
  )
  const isLoaded = useSelector(localizedShopsSelector.getIsLocalizedShopsLoaded)
  const isSavingActive = useSelector(localizedShopsSelector.getIsSavingActive)
  const page = useSelector(localizedShopsSelector.getLocalizedShopsPage)
  const pageCount = useSelector(
    localizedShopsSelector.getLocalizedShopsPageCount
  )
  const filters = useSelector(localizedShopsSelector.getLocalizedShopsFilters)
  const sorter = useSelector(localizedShopsSelector.getLocalizedShopsSorter)
  const isDataFilteredOrSorted = useSelector(
    localizedShopsSelector.getIsLocalizedShopsDataFilteredOrSorted
  )

  const openShopModalFunc = useAction(openShopModal)
  const openCouponsSettingsModalFunc = useAction(openCouponsSettingsModal)
  const fetchLocalizedShopsFunc = useAction(fetchLocalizedShops)

  const onTableChange = (pagination, filters, sorter) => {
    fetchLocalizedShopsFunc({
      search: currentShopId,
      filters,
      sorter,
      page: pagination.current,
    })
  }

  const handleResetFilters = () => {
    fetchLocalizedShopsFunc({
      page: 1,
      search: currentShopId,
      filters: {},
      sorter: {},
    })
  }

  const handleEditLocalizedShop = (id) =>
    openShopModalFunc({ id, localized: true })

  const handleCouponsEdit = ({ id }) => openCouponsSettingsModalFunc({ id })

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  return (
    <Spin spinning={isSavingActive} size="large">
      <Buttons>
        <Button
          onClick={handleResetFilters}
          disabled={!isLoaded || !isDataFilteredOrSorted}
          style={{ marginLeft: '12px' }}
        >
          Reset filters
        </Button>
      </Buttons>
      <StyledTable
        showSorterTooltip={false}
        columns={localizedShopsColumns({
          filters,
          sorter,
          countriesFilters: formatCountryCodesForFiltering(
            availableCountryCodes
          ),
          handleEditLocalizedShop,
          handleCouponsEdit,
        })}
        pagination={paginationConfig}
        dataSource={dataSource || []}
        expandable={false}
        bordered
        loading={currentShopId && !isLoaded}
        rowKey={getRowKey}
        rowClassName={getRegularRowClass}
        onChange={onTableChange}
        // sticky={isLoaded}
      />
    </Spin>
  )
}

export default LocalizedShopsTab
