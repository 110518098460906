import React from 'react'
import { Col, Layout, Row, Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import useAction from 'utils/useAction'
import { FIELD_NAMES } from 'const/forms'
import { required } from 'helpers/validators'
import { selectors as ruleSelectors } from 'domains/rules'
import TextInput from 'components/TextInput'
import Title from 'components/Title/Title'
import { Buttons, StyledButton } from './RuleSlug.styles'
import * as actions from 'actions/views/ruleSlugs'
import SearchSelect from 'components/SearchSelect'
import { RELATIONSHIPS_NAMES } from 'const/api'

const RuleSlug = ({ id, closeModal }) => {
  const isSavingActive = useSelector(ruleSelectors.getIsSavingActive)
  const initialValues = useSelector(ruleSelectors.getRuleSlugById)(id)
  const createRuleSlugFunc = useAction(actions.createRuleSlug)
  const updateRuleSlugFunc = useAction(actions.updateRuleSlug)

  const onSubmit = (values) => {
    if (id) {
      updateRuleSlugFunc({ values, callback: closeModal })
    } else {
      createRuleSlugFunc({ values, callback: closeModal })
    }
  }
  return (
    <Layout.Content>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ hasValidationErrors, handleSubmit }) => {
          return (
            <Spin spinning={isSavingActive} size="large">
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Title>Slug</Title>
                  <Field
                    validate={required}
                    component={TextInput}
                    name={FIELD_NAMES.ruleSlug}
                  />
                </Col>
                <Col span={12}>
                  <Title>Rule</Title>
                  <Field
                    entity={RELATIONSHIPS_NAMES.rules}
                    validate={required}
                    component={SearchSelect}
                    name={FIELD_NAMES.ruleId}
                  />
                </Col>
              </Row>
              <Buttons>
                <StyledButton size="default" onClick={closeModal}>
                  Cancel
                </StyledButton>

                <StyledButton
                  disabled={hasValidationErrors}
                  size="default"
                  type="primary"
                  onClick={handleSubmit}
                >
                  Save
                </StyledButton>
              </Buttons>
            </Spin>
          )
        }}
      />
    </Layout.Content>
  )
}

export default RuleSlug
