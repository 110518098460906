import { useEffect } from 'react'

export default (ref, callback, condition = true) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref?.current?.contains(event.target) && condition) {
        event.preventDefault()
        event.stopPropagation()
        callback()
      }
    }

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [ref, condition, callback])
}
