import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  EmptyUpload,
  Text,
  Upload,
  ResetButton,
  Wrapper,
} from './CsvPicker.styles'
import { getBase64 } from 'helpers/files'
import { useForm } from 'react-final-form'
import { Tooltip } from 'antd'

const CsvPicker = ({ input, meta, forceInvalid, changeCallback, disabled }) => {
  const { change } = useForm()
  const [loading, setLoading] = useState(false)
  const [fileList, setFileList] = useState([{ url: input.value, uid: 1 }])

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  useEffect(() => {
    if (input.value) {
      setFileList([{ url: input.value, uid: 1 }])
    }
  }, [input.value])

  const handleChange = ({ file }) => {
    setLoading(true)

    switch (file.status) {
      case 'uploading':
        break
      case 'done':
        getBase64(file.originFileObj, (imageUrl) => {
          input.onChange(imageUrl)
          if (changeCallback) {
            changeCallback(imageUrl)
          }
          setLoading(false)
        })
        break
      default:
        setFileList([])
        input.onChange(null)
        setLoading(false)
    }
  }

  const handleRemove = () => {
    setFileList([])
    input.onChange(null)
  }

  const resetChanges = () => change(input.name, meta.initial)

  const UploadButton = (
    <EmptyUpload>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <Text>Upload</Text>
    </EmptyUpload>
  )

  const UploadComponent = (
    <Upload
      disabled={disabled}
      invalid={(forceInvalid || meta.touched || meta.modified) && meta.error}
      accept={['text/csv']}
      showUploadList={
        input.value
          ? {
              showRemoveIcon: true,
              showPreviewIcon: false,
            }
          : false
      }
      defaultFileList={[input.value]}
      customRequest={dummyRequest}
      onChange={handleChange}
      onRemove={handleRemove}
      fileList={fileList}
      listType="picture-card"
      maxCount={1}
    >
      {!input.value && UploadButton}
    </Upload>
  )

  return (
    <Wrapper>
      {UploadComponent}
      {!meta.pristine && !disabled && (
        <Tooltip title="Click to reset changes">
          <ResetButton onClick={resetChanges} />
        </Tooltip>
      )}
    </Wrapper>
  )
}

CsvPicker.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: PropTypes.bool,
  changeCallback: PropTypes.func,
  // eslint-disable-next-line react/boolean-prop-naming
  forceInvalid: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    modified: PropTypes.bool,
    pristine: PropTypes.bool,
    touched: PropTypes.bool,
    initial: PropTypes.string,
  }).isRequired,
}

CsvPicker.defaultProps = {
  changeCallback: null,
  disabled: false,
  forceInvalid: false,
}

export default CsvPicker
