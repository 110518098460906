import { createSelector } from '@reduxjs/toolkit'

export const extensionVersionsState = ({ extensionVersions }) =>
  extensionVersions

export const getIsLoaded = createSelector(
  extensionVersionsState,
  ({ isLoaded }) => isLoaded
)

export const getIsSavingActive = createSelector(
  extensionVersionsState,
  ({ isSavingActive }) => isSavingActive
)

export const isLoadingOrSavingActive = createSelector(
  [getIsLoaded, getIsSavingActive],
  (isLoaded, isSavingActive) => !isLoaded || isSavingActive
)

export const getExtensionVersion = createSelector(
  extensionVersionsState,
  ({ currentActiveVersion }) => currentActiveVersion
)
