import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { createCampaign, updateCampaign } from 'actions/views/campaign'
import useAction from 'utils/useAction'
import arrayMutators from 'final-form-arrays'
import { Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/Input'
import InputNumber from 'components/Input/InputNumber'
import DatePicker from 'components/DatePicker/DatePicker'
import { FIELD_NAMES } from 'const/forms'
import { required, requiredNumber } from 'helpers/validators'
import { selectors as CampaignsSelectors } from 'domains/campaigns'
import { StyledButton, StyledForm } from './Campaign.styles'
import Select from 'components/Select/Select'
import { CAMPAIGN_TYPES } from './const'
import FieldSwitch from 'components/FieldSwitch/FieldSwitch'
import SearchRetailers from '../../SearchRetailers'
import SearchNetwork from './SearchNetwork'
import TextArea from 'components/TextArea/TextArea'

const Campaign = ({ id, closeModal }) => {
  const isSavingActive = useSelector(CampaignsSelectors.getIsSavingActive)
  const initialValues = useSelector(CampaignsSelectors.getCampaignById)(id)
  const createCampaignFunc = useAction(createCampaign)
  const updateCampaignFunc = useAction(updateCampaign)
  const [ruleId, setRuleId] = useState(
    id ? initialValues[FIELD_NAMES.ruleId] : null
  )

  const onSubmit = (data) => {
    const values = {
      ...data,
      [FIELD_NAMES.ruleId]: ruleId,
    }

    if (
      values[FIELD_NAMES.dealType] === 'flat fee' ||
      values[FIELD_NAMES.dealType] === 'fixed fee'
    ) {
      values[FIELD_NAMES.currentCpaPercentage] = null
      values[FIELD_NAMES.newCpaPercentage] = null
    }

    if (
      values[FIELD_NAMES.dealType] === 'flat fee' ||
      values[FIELD_NAMES.dealType] === 'hybrid'
    ) {
      values[FIELD_NAMES.fixedFee] = null
      values[FIELD_NAMES.fixedFeeCurrency] = null
    }

    if (values[FIELD_NAMES.dealType] === 'fixed fee') {
      values[FIELD_NAMES.flatFee] = null
      values[FIELD_NAMES.flatFeeCurrency] = null
    }

    if (values[FIELD_NAMES.dealType] === 'cpa') {
      values[FIELD_NAMES.flatFee] = null
      values[FIELD_NAMES.flatFeeCurrency] = null
      values[FIELD_NAMES.fixedFee] = null
      values[FIELD_NAMES.fixedFeeCurrency] = null
    }

    if (data.id) {
      updateCampaignFunc({ data: values, callback: closeModal })
    } else {
      createCampaignFunc({ data: values, callback: closeModal })
    }
  }

  const updateRule = (retailer) =>
    setRuleId(retailer?.attributes?.[FIELD_NAMES.ruleId])

  return (
    <Form
      destroyOnUnregister
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, values }) => {
        return (
          <Spin spinning={isSavingActive} size="large">
            <StyledForm onSubmit={handleSubmit}>
              {id && (
                <Field
                  name={FIELD_NAMES.id}
                  label="Campaign"
                  disabled
                  component={Input}
                />
              )}

              <SearchRetailers
                required
                validate={required}
                callback={updateRule}
                name={FIELD_NAMES.stores}
              />

              <SearchNetwork
                required
                name={FIELD_NAMES.network}
                label="Network"
                placeholder="Network"
                component={Input}
                validate={required}
              />

              <Field
                required
                name={FIELD_NAMES.dealType}
                options={CAMPAIGN_TYPES}
                label="Type"
                component={Select}
                validate={required}
              />

              {(values[FIELD_NAMES.dealType] === 'flat fee' ||
                values[FIELD_NAMES.dealType] === 'hybrid') && (
                <>
                  <Field
                    required
                    name={FIELD_NAMES.flatFee}
                    label="Flat Fee"
                    placeholder="%"
                    component={InputNumber}
                    validate={requiredNumber}
                  />

                  <Field
                    required
                    name={FIELD_NAMES.flatFeeCurrency}
                    label="Flat Fee Currency"
                    placeholder="Ex: GBT"
                    component={Input}
                    validate={required}
                  />
                </>
              )}

              {values[FIELD_NAMES.dealType] === 'fixed fee' && (
                <>
                  <Field
                    required
                    name={FIELD_NAMES.fixedFee}
                    label="Fixed Fee"
                    placeholder="number"
                    component={InputNumber}
                    validate={required}
                  />

                  <Field
                    required
                    name={FIELD_NAMES.fixedFeeCurrency}
                    label="Fixed Fee Currency"
                    placeholder="Ex: GBT"
                    component={Input}
                    validate={required}
                  />
                </>
              )}

              {values[FIELD_NAMES.dealType] === 'flat fee' ||
              values[FIELD_NAMES.dealType] === 'fixed fee' ? null : (
                <>
                  <Field
                    required
                    name={FIELD_NAMES.currentCpaPercentage}
                    label="Existing CPA"
                    placeholder="%"
                    component={InputNumber}
                    validate={required}
                  />

                  <Field
                    required
                    validate={required}
                    name={FIELD_NAMES.newCpaPercentage}
                    label="New Cpa"
                    placeholder="%"
                    component={InputNumber}
                  />
                </>
              )}
              <Field
                required
                name={FIELD_NAMES.startDate}
                label="Start Date"
                component={DatePicker}
              />

              <Field
                required
                name={FIELD_NAMES.endDate}
                label="End Date"
                component={DatePicker}
              />

              {values[FIELD_NAMES.notifySlack] ? (
                <Field
                  required
                  validate={required}
                  name={FIELD_NAMES.freeTextSlack}
                  label="Free Text"
                  placeholder=""
                  component={TextArea}
                />
              ) : null}

              <Field
                type="checkbox"
                name={FIELD_NAMES.notifySlack}
                component={FieldSwitch}
                label="Notify"
                defaultValue={false}
              />

              {!values[FIELD_NAMES.startDate] ||
              !values[FIELD_NAMES.endDate] ? (
                <StyledButton
                  type="primary"
                  disabled={true}
                  onClick={handleSubmit}
                >
                  Save changes
                </StyledButton>
              ) : (
                <StyledButton
                  type="primary"
                  disabled={pristine}
                  onClick={handleSubmit}
                >
                  Save changes
                </StyledButton>
              )}
            </StyledForm>
          </Spin>
        )
      }}
    />
  )
}

Campaign.propTypes = {
  id: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
}

Campaign.defaultTypes = {
  id: null,
}

export default Campaign
