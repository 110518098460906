import { notification } from 'antd'
import { RELATIONSHIPS_NAMES } from 'const/api'
import { FIELD_NAMES, JAVA_FIELD_NAMES } from 'const/forms'
import parseError from 'helpers/parseServerError'
import { javaWithCookieInstance } from 'utils/requestJAVA'

export const getTransactions = ({ page, from, to, sorter, size }) =>
  javaWithCookieInstance
    .get(`/${RELATIONSHIPS_NAMES.aggregatedTransaction}`, {
      params: {
        page,
        size,
        sort: sorter || FIELD_NAMES.date,
        [JAVA_FIELD_NAMES.fromDate]: from || null,
        [JAVA_FIELD_NAMES.toDate]: to || null,
      },
    })
    .catch((error) => {
      return error?.response?.status === 504
        ? notification.error({
            placement: 'bottomRight',
            duration: 10,
            message: 'Java Authentication failed',
            description:
              "Ensure you are accessing BO from the office. All routes that require JAVA won't work properly.",
          })
        : parseError(error, 'Cannot get transactions')
    })
