import { message } from 'antd'
import { IMAGE_SIZE_LIMIT_IN_MB, IMAGE_TYPES } from 'const/retailers'

async function readFileAsDataURL(file) {
  return await new Promise((resolve) => {
    const fileReader = new FileReader()
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.readAsDataURL(file)
  })
}

const createImage = (data) =>
  new Promise((resolve) => {
    const image = new Image()
    image.src = data

    image.onload = function () {
      const { height, width } = this
      resolve({
        width,
        height,
      })
    }
  })

export const checkImageSize = async (
  file,
  { width: minWidth, height: minHeight }
) => {
  const aspect = minWidth / minHeight

  const data = await readFileAsDataURL(file)
  const { width, height } = await createImage(data)
  if (width < minWidth) {
    message.error(`Width must not be less than ${minWidth}px.`)
    return false
  }

  if (height < minHeight) {
    message.error(`Height must not be less than ${minHeight}px.`)
    return false
  }
  const rightWidth = height * aspect
  const rightHeight = width / aspect
  if (rightWidth < width && rightHeight < height) {
    message.error('ероры')
    return false
  }
  return true
}

export const checkFileBeforeCrop =
  ({ types = IMAGE_TYPES, imageSize } = {}) =>
  async (file) => {
    const hasAvailableType = types.includes(file.type)
    if (!hasAvailableType) {
      message.error(`You can only upload ${types.join(', ')} files!`)
      return false
    }
    const hasAvailableSizeInPx = imageSize
      ? await checkImageSize(file, imageSize)
      : true
    return hasAvailableType && hasAvailableSizeInPx
  }

export const checkFileBeforeUpload =
  (limitInMB = IMAGE_SIZE_LIMIT_IN_MB) =>
  (file) => {
    const isLimitReached = file.size / 1024 ** 2 > limitInMB
    if (isLimitReached) {
      message.error(`Image must smaller than ${limitInMB}MB!`)
    }
    return !isLimitReached
  }

export const getBase64 = (img, callback) => {
  try {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  } catch (error) {
    message.error(error)
  }
}

export const formatBase64ForRequest = (base64) =>
  base64?.replace('data:text/csv;base64,', '')
