import styled from 'styled-components'

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: flex-end;
  margin-bottom: -4px;
`

export const Name = styled.span`
  margin-left: 10px;
  font-size: 14px;
`
