import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getFiltersPayments,
  getIsDataFilteredOrSortedPayments,
  getIsLoadedPayments,
  getPagePayments,
  getPageCountPayments,
  getSearchStringPayments,
  getSorterPayments,
  getSortedPayments,
} from 'domains/payments/selectors'
import { openPaymentModal } from 'reducers/modals/actions'
import useAction from 'utils/useAction'
import { StyledTable, Buttons, Wrapper } from './PaymentsList.styles'
import { Button } from 'antd'
import { WarningTwoTone } from '@ant-design/icons'
import getColumns from './columns'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import isEqual from 'lodash/isEqual'
import { fetchPayments, fetchBudget } from 'actions/views/payments'
import { HEADER_OFFSET, PAGE_SIZE, SCROLL } from 'const/tables'
import SearchInTableBar from 'components/SearchInTableBar'

const PaymentsList = () => {
  const [refreshAvailable, setRefreshAvailable] = useState(false)
  const isLoaded = useSelector(getIsLoadedPayments)
  const page = useSelector(getPagePayments)
  const search = useSelector(getSearchStringPayments)
  const pageCount = useSelector(getPageCountPayments)
  const filters = useSelector(getFiltersPayments)
  const sorter = useSelector(getSorterPayments)
  const dataSource = useSelector(getSortedPayments)
  const isDataFilteredOrSorted = useSelector(getIsDataFilteredOrSortedPayments)

  const fetchBudgetFunc = useAction(fetchBudget)
  const fetchPaymentsFunc = useAction(fetchPayments)
  const openPaymentModalFunc = useAction(openPaymentModal)

  const onCreate = () => openPaymentModalFunc()

  const onTableChange = (pagination, newFilters, newSorter) => {
    const pageChanged = page !== pagination.current
    const newFiltersOrSorter =
      !pageChanged &&
      (!isEqual(newFilters, filters) || !isEqual(newSorter, sorter))
    fetchPaymentsFunc({
      page: newFiltersOrSorter ? 1 : pagination.current,
      search,
      filters: newFilters,
      sorter: newSorter,
    })
  }

  const onCountdownEnd = () => setRefreshAvailable(true)

  const onRefresh = () => {
    fetchPaymentsFunc({
      page,
      search,
      filters,
      sorter,
    })
    fetchBudgetFunc()
    setRefreshAvailable(false)
  }

  const onSearchStringChange = (search) =>
    fetchPaymentsFunc({
      page: 1,
      search,
      filters,
      sorter,
    })

  const handleResetFilters = () =>
    fetchPaymentsFunc({
      search,
      page: 1,
      filters: {},
      sorter: {},
    })

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  return (
    <Wrapper>
      <SearchInTableBar
        placeholder="Search payment here"
        onSubmit={onSearchStringChange}
        value={search}
      >
        <Buttons>
          {refreshAvailable && (
            <Button onClick={onRefresh} disabled={!isLoaded}>
              <WarningTwoTone twoToneColor="#ebcc2f" />
              Refresh
            </Button>
          )}
          <Button onClick={onCreate} type="primary" disabled={!isLoaded}>
            Create
          </Button>
          <Button
            onClick={handleResetFilters}
            disabled={!isLoaded || !isDataFilteredOrSorted}
          >
            Reset filters
          </Button>
        </Buttons>
      </SearchInTableBar>
      <StyledTable
        onChange={onTableChange}
        showSorterTooltip={false}
        columns={getColumns(filters, sorter, onCountdownEnd)}
        dataSource={dataSource}
        pagination={paginationConfig}
        sticky={isLoaded && HEADER_OFFSET}
        scroll={SCROLL}
        bordered
        rowKey={getRowKey}
        rowClassName={getRegularRowClass}
      />
    </Wrapper>
  )
}

export default PaymentsList
