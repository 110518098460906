import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  saveRuleSlugsFiltersAndSorter,
  fetchRuleSlugsSuccess,
  fetchRuleSlugsFailure,
  startSaving,
  finishSaving,
  clearData,
} = reducer.actions

export const fetchRuleSlugs = createAction('ruleSlugs/fetchRuleSlugs')

export const deleteRuleSlug = createAction('ruleSlugs/deleteRuleSlug')
export const deleteRuleSlugSuccess = createAction(
  'ruleSlugs/deleteRuleSlugSuccess'
)
export const deleteRuleSlugFailure = createAction(
  'ruleSlugs/deleteRuleSlugFailure'
)

export const createRuleSlug = createAction('ruleSlugs/createRuleSlug')
export const createRuleSlugSuccess = createAction(
  'ruleSlugs/createRuleSlugSuccess'
)
export const createRuleSlugFailure = createAction(
  'ruleSlugs/createRuleSlugFailure'
)

export const updateRuleSlug = createAction('ruleSlugs/updateRuleSlug')
export const updateRuleSlugSuccess = createAction(
  'ruleSlugs/updateRuleSlugSuccess'
)
export const updateRuleSlugFailure = createAction(
  'ruleSlugs/updateRuleSlugFailure'
)
