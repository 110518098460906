import styled from 'styled-components'
import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
`

export const StyledButton = styled(Button)`
  margin-left: 10px;
`

export const CloseButton = styled(CloseOutlined)`
  top: -79px;
  right: -24px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 47px;
  font-size: 16px;
  color: rgb(156 156 156);
  background: white;
`
