import React from 'react'
import { Col, Input, Layout, Row } from 'antd'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import useAction from 'utils/useAction'
import { FIELD_NAMES } from 'const/forms'
import { required } from 'helpers/validators'
import { openConfirmModal } from 'reducers/modals/actions'
import { lastModalData } from 'reducers/modals/selectors'
import { convertToRetailer } from 'domains/retailers/actions'
import RegionSelect from 'components/RegionSelect'
import Title from 'components/Title/Title'
import { Buttons, StyledButton } from './ConvertToRetailer.styles'
import { RETAILER_TYPE_TO_LABEL } from 'const/retailers'
import { ENTITY_NAMES, RELATIONSHIPS_NAMES } from 'const/api'
import SearchSelect from 'components/SearchSelect'

const ConvertToRegionalRetailer = ({ closeModal }) => {
  const { retailer, type: destinationType } = useSelector(lastModalData)
  const { [FIELD_NAMES.name]: retailerName, id, type: sourceType } = retailer

  const isTheSameType = sourceType === destinationType
  const countryCode = retailer[FIELD_NAMES.countryCode]

  const initialValues = {
    [FIELD_NAMES.mainRetailerId]: '',
    [FIELD_NAMES.countryCode]: isTheSameType ? countryCode : null,
  }

  const exceptRetailerIds =
    retailer.type === ENTITY_NAMES.main
      ? retailer[FIELD_NAMES.id]
      : isTheSameType
      ? retailer[FIELD_NAMES.mainId]
      : null
  const exceptCodes =
    isTheSameType && sourceType === ENTITY_NAMES.regional ? countryCode : null

  const convertToRetailerFunc = useAction(convertToRetailer)
  const openConfirmModalFunc = useAction(openConfirmModal)

  const onSubmit = (data) => {
    const callback = () => {
      convertToRetailerFunc({ id, data, sourceType, destinationType })
      closeModal()
    }
    const text = isTheSameType
      ? `Are you sure you want to change global retailer for this ${RETAILER_TYPE_TO_LABEL[sourceType]} retailer?`
      : `Are you sure you want to convert ${retailerName} from ${RETAILER_TYPE_TO_LABEL[sourceType]} ` +
        `to ${RETAILER_TYPE_TO_LABEL[destinationType]}?`
    openConfirmModalFunc({
      callback,
      text: text + " This action can't be revoked.",
    })
  }

  return (
    <Layout.Content>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ hasValidationErrors, handleSubmit }) => (
          <>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Title>Retailer ID</Title>
                <Input value={id} disabled />
              </Col>
              <Col span={12}>
                <Title>Name</Title>
                <Input value={retailerName} disabled />
              </Col>
              <Col span={12}>
                <Field
                  except={exceptRetailerIds}
                  exceptCodes={exceptCodes}
                  title="Global retailer"
                  component={SearchSelect}
                  entity={RELATIONSHIPS_NAMES.main}
                  name={FIELD_NAMES.mainRetailerId}
                  validate={required}
                />
              </Col>
              {destinationType === ENTITY_NAMES.regional && !isTheSameType && (
                <Col span={12}>
                  <RegionSelect />
                </Col>
              )}
            </Row>

            <Buttons>
              <StyledButton size="default" onClick={closeModal}>
                Cancel
              </StyledButton>

              <StyledButton
                disabled={hasValidationErrors}
                size="default"
                type="primary"
                onClick={handleSubmit}
              >
                Save changes
              </StyledButton>
            </Buttons>
          </>
        )}
      />
    </Layout.Content>
  )
}

export default ConvertToRegionalRetailer
