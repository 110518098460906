import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import * as actions from './actions'

function* fetchEngagementMetricsWorker({ payload }) {
  try {
    yield put(actions.setLoading({ key: 'engagementMetrics', value: true }))
    const response = yield call(api.getEngagementMetrics, payload)
    yield put(
      actions.fetchEngagementMetricsSuccess({
        data: [response?.data?.data],
        page: payload.page || 1,
        pageCount: response?.data?.meta?.page_count,
      })
    )
  } catch (error) {
    yield put(actions.fetchEngagementMetricsFailure())
    console.error(error)
  } finally {
    yield put(actions.setLoading({ key: 'engagementMetrics', value: false }))
  }
}

function* fetchPaymentTransactionsWorker({ payload }) {
  try {
    yield put(actions.setLoading({ key: 'paymentTransactions', value: true }))
    const response = yield call(api.getPaymentTransactions, payload)
    yield put(
      actions.savePaymentTransactions({
        data: response?.data?.data,
        page: payload.page || 1,
        pageCount: response?.data?.meta?.page_count,
      })
    )
  } catch (error) {
    console.error(error)
  } finally {
    yield put(actions.setLoading({ key: 'paymentTransactions', value: false }))
  }
}

function* deleteUserWorker({ payload }) {
  try {
    const { data } = yield call(api.deleteUser, payload)
    if (data) {
      yield put(actions.deleteUserSuccess())
    } else {
      yield put(actions.deleteUserFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteUserFailure())
  }
}

function* verifyPayPalWorker({ payload }) {
  try {
    const { data } = yield call(api.sendPayPalEmailToUser, payload)
    if (data) {
      yield put(actions.verifyUserPayPalSuccess())
    } else {
      yield put(actions.verifyUserPayPalFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.verifyUserPayPalFailure())
  }
}

function* confirmPayPalWorker({ payload }) {
  try {
    const { data } = yield call(api.confirmPayPal, payload)
    if (data) {
      yield put(actions.confirmPayPalSuccess())
    } else {
      yield put(actions.confirmPayPalFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.confirmPayPalFailure())
  }
}

function* updateUserWorker({ payload }) {
  try {
    const { data } = yield call(api.updateUser, payload)
    if (data) {
      yield put(actions.updateUserSuccess())
    } else {
      yield put(actions.updateUserFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateUserFailure())
  }
}

function* resetUserPasswordWorker({ payload }) {
  try {
    const apiResponse = yield call(api.resetUserPassword, payload)
    if (apiResponse?.new_password) {
      yield put(actions.setUser({ password: apiResponse.new_password }))
      yield put(actions.resetUserPasswordSuccess())
    } else {
      yield put(actions.resetUserPasswordFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.resetUserPasswordFailure())
  }
}

function* unblockUserPaymentWorker({ payload }) {
  try {
    const apiResponse = yield call(api.unblockUserPayment, payload)
    if (apiResponse) {
      yield put(actions.unblockUserPayment())
      yield put(actions.unblockUserPaymentSuccess())
    } else {
      yield put(actions.unblockUserPaymentFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.unblockUserPaymentFailure())
  }
}

function* mergeUserWorker({ payload }) {
  try {
    const response = yield call(api.mergeUser, payload)
    if (response?.data) {
      yield put(actions.mergeUserSuccess())
    } else {
      yield put(actions.mergeUserFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.mergeUserFailure())
  }
}

function* fetchUsersWorker({ payload }) {
  try {
    const { data } = yield call(api.getUsers, payload)
    yield put(
      actions.fetchUsersSuccess({
        ...payload,
        page: payload.page,
        pageCount: data.meta?.page_count,
        users: data.data,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchUsersFailure())
  }
}

function* sendSMSVerificationWorker({ payload }) {
  try {
    const response = yield call(api.sendSMSVerification, payload)
    if (response) {
      yield put(actions.sendSMSVerificationSuccess(response))
    }
  } catch (error) {
    console.error(error)
    yield put(actions.sendSMSVerificationFailure())
  }
}

function* unsubscribeUserWorker({ payload }) {
  try {
    const { data } = yield call(api.unsubscribeUser, payload)
    if (data) {
      yield put(actions.unsubscribeUserSuccess())
    } else {
      yield put(actions.unsubscribeUserFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.unsubscribeUserFailure())
  }
}

function* fetchBonusesForUserWorker({ payload }) {
  try {
    const { data } = yield call(api.fetchBonusesForUser, payload)
    if (data) {
      yield put(actions.fetchBonusesForUserSuccess(data))
    } else {
      yield put(actions.fetchBonusesForUserFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.fetchBonusesForUserFailure())
  }
}

export default function* () {
  yield all([
    takeEvery(actions.fetchUsers, fetchUsersWorker),
    takeEvery(actions.fetchPaymentTransactions, fetchPaymentTransactionsWorker),
    takeEvery(actions.fetchEngagementMetrics, fetchEngagementMetricsWorker),
    takeEvery(actions.deleteUser, deleteUserWorker),
    takeEvery(actions.verifyUserPayPal, verifyPayPalWorker),
    takeEvery(actions.updateUser, updateUserWorker),
    takeEvery(actions.mergeUser, mergeUserWorker),
    takeEvery(actions.resetUserPassword, resetUserPasswordWorker),
    takeEvery(actions.unblockUserPayment, unblockUserPaymentWorker),
    takeEvery(actions.sendSMSVerification, sendSMSVerificationWorker),
    takeEvery(actions.unsubscribeUser, unsubscribeUserWorker),
    takeEvery(actions.fetchBonusesForUser, fetchBonusesForUserWorker),
    takeEvery(actions.confirmPayPal, confirmPayPalWorker),
  ])
}
