import React from 'react'
import { Button } from 'antd'
import useAction from 'utils/useAction'
import { actions as adPackageActions } from 'domains/adPackages'
import { Wrapper } from './Wrapper.styles.js'
import { openConfirmModal } from 'reducers/modals/actions'

const ButtonGroup = ({ id, onEdit }) => {
  const openConfirmModalFunc = useAction(openConfirmModal)
  const deleteAdPackage = useAction(adPackageActions.deleteAdPackageRequest)
  const duplicateAdPackage = useAction(adPackageActions.duplicateAdPackageRequest)

  const handleDelete = () => {
    const callback = () => deleteAdPackage(id)
    const text = `Are you sure you want to delete this ad package (ID:${id})? This action can't be revoked.`
    openConfirmModalFunc({ callback, text })
  }

  const handleDuplicate = () => {
    const callback = () => duplicateAdPackage(id)
    const text = `Are you sure you want to duplicate this ad package (ID:${id})?`
    openConfirmModalFunc({ callback, text })
  }

  const handleEdit = () => onEdit({ id })

  return (
    <Wrapper>
      <Button onClick={handleEdit}>Edit</Button>
      <Button onClick={handleDuplicate}>Duplicate</Button>
      <Button onClick={handleDelete} type="danger">
        Delete
      </Button>
    </Wrapper>
  )
}

export default ButtonGroup
