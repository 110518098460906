import React from 'react'
import SearchSelectShops from './SearchSelectShops'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'

const ShopsField = ({ input, meta, required, ...rest }) => (
  <FieldWrapper hint={rest.hint} label={rest.label} required={required}>
    <SearchSelectShops input={input} meta={meta} isEditing {...rest} />
  </FieldWrapper>
)

export default ShopsField
