import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getIsLoaded,
  getDevelopersAsArray,
  getSorter,
} from 'domains/developers/selectors'
import { Button, DatePicker } from 'antd'
import useAction from 'utils/useAction'
import { Buttons, HeaderWrapper, StyledTable, Wrapper } from './ShopDev.styles'
import getColumns from './columns'
import { HEADER_OFFSET, SCROLL } from 'const/tables'
import { getRowClassByTagID, getRowKey } from 'helpers/tables'
import { fetchCSV, fetchDevelopers } from 'actions/views/developers'
import moment from 'moment'
import { DATE_FORMAT_DASH } from 'const/dates'

const { RangePicker } = DatePicker

const ShopDev = () => {
  const [from, setFrom] = useState(
    moment().subtract(14, 'days').format(DATE_FORMAT_DASH)
  )
  const [to, setTo] = useState(moment().format(DATE_FORMAT_DASH))

  const sorter = useSelector(getSorter)
  const isLoaded = useSelector(getIsLoaded)
  const dataSource = useSelector(getDevelopersAsArray)

  const downloadCSV = useAction(fetchCSV)
  const fetchDevelopersFunc = useAction(fetchDevelopers)
  const onDownLoad = () => downloadCSV({ from, to })

  const onDateChange = (e) => {
    const from = e[0].format(DATE_FORMAT_DASH)
    const to = e[1].format(DATE_FORMAT_DASH)
    setFrom(from)
    setTo(to)
    return fetchDevelopersFunc({ from, to })
  }

  const onTableChange = (pagination, newFilters, newSorter) => {
    fetchDevelopersFunc({
      sorter: newSorter,
      from,
      to,
    })
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <RangePicker onChange={onDateChange} />
        <p>
          {from} - {to}
        </p>
        <Buttons>
          <Button onClick={onDownLoad}>Download CSV</Button>
        </Buttons>
      </HeaderWrapper>

      <StyledTable
        onChange={onTableChange}
        showSorterTooltip={false}
        columns={getColumns(sorter)}
        dataSource={dataSource}
        sticky={isLoaded && HEADER_OFFSET}
        scroll={SCROLL}
        bordered
        loading={!isLoaded}
        rowKey={getRowKey}
        rowClassName={getRowClassByTagID}
        pagination={false}
      />
    </Wrapper>
  )
}

export default ShopDev
