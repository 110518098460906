import React from 'react'
import { Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Wrapper } from './Wrapper.styles.js'

const FieldWrapper = ({ children, label, hint, meta, required }) => (
  <Wrapper
    className="field-wrapper"
    required={required}
    error={meta.error && meta.touched}
  >
    {label && (
      <label className="field-label">
        {hint && (
          <Tooltip placement="topLeft" title={hint}>
            <ExclamationCircleOutlined />
          </Tooltip>
        )}
        {`${label}: `}
      </label>
    )}
    <div className="field-control">
      {children}
      {meta.error && meta.touched && <span>{meta.error}</span>}
    </div>
  </Wrapper>
)

FieldWrapper.defaultProps = {
  meta: {},
}

export default FieldWrapper
