import { createSlice } from '@reduxjs/toolkit'
import merge from 'lodash/merge'
import noop from 'lodash/noop'

const initialState = {
  savedValues: {},
  onResetForm: noop,
  onChangeFieldState: noop,
}

const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    updateState: (state, action) => {
      const { form, formState, onResetForm, onChangeFieldState } =
        action.payload
      return {
        ...state,
        onResetForm,
        onChangeFieldState,
        [form]: {
          onResetForm,
          onChangeFieldState,
          ...formState,
        },
      }
    },
    saveValues: (state, { payload }) => ({
      ...state,
      savedValues: merge(
        JSON.parse(JSON.stringify(state.savedValues)),
        payload
      ),
    }),
    replaceValues: (state, { payload }) => ({
      ...state,
      savedValues: payload.values,
    }),
    resetValues: (state) => ({
      ...state,
      savedValues: initialState.savedValues,
    }),
  },
})

export default formsSlice
