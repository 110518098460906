import styled from 'styled-components'
import { Select } from 'antd'

export const StyledSelect = styled(Select)`
  padding: 10px;
  .ant-select-selector {
    max-height: 60px;
    overflow-x: auto;
  }
`
