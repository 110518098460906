import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  createGiveawayPopup,
  updateGiveawayPopup,
} from 'actions/views/giveawaysPopups'
import useAction from 'utils/useAction'
import { Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/Input'
import { FIELD_NAMES } from 'const/forms'
import { required } from 'helpers/validators'
import { selectors as giveawaysPopups } from 'domains/giveaways'
import { FlexWrapper, StyledButton, StyledForm } from './Giveaways'
import { GIVEAWAYS_IMAGE_CROP_SIZE } from 'const/retailers'
import ImagePicker from 'components/ImagePicker'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper'
import Markdown from 'components/Markdown/Markdown'
import FieldSwitch from 'components/FieldSwitch/FieldSwitch'
import SearchRetailers from '../../SearchRetailers'
import CustomRangePicker from './CustomRangePicker'

const Giveaways = ({ id, closeModal }) => {
  const isSavingActive = useSelector(giveawaysPopups.getIsSavingActive)
  const initialValues = useSelector(giveawaysPopups.getGiveawayById)(id)
  const createGiveawayPopupFunc = useAction(createGiveawayPopup)
  const updateGiveawayPopupFunc = useAction(updateGiveawayPopup)

  const onSubmit = (values) => {
    if (id) {
      const { [FIELD_NAMES.productPreview]: image, ...restValues } = values
      updateGiveawayPopupFunc({
        values: {
          ...restValues,
          ...(image !== initialValues[FIELD_NAMES.productPreview] && {
            [FIELD_NAMES.productPreview]: image,
          }),
        },
        callback: closeModal,
      })
    } else {
      createGiveawayPopupFunc({ values, callback: closeModal })
    }
  }

  return (
    <Form
      destroyOnUnregister
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, valid }) => (
        <Spin spinning={isSavingActive} size="large">
          <StyledForm onSubmit={handleSubmit}>
            {id && <Field name="id" label="ID" disabled component={Input} />}
            <SearchRetailers name={FIELD_NAMES.retailers} />
            <CustomRangePicker />

            <Field
              required
              name={FIELD_NAMES.name}
              validate={required}
              label="Name"
              placeholder="Name"
              component={Input}
            />

            <Field
              required
              name={FIELD_NAMES.header}
              validate={required}
              label="Header"
              placeholder="Header"
              component={Markdown}
            />

            <Field
              required
              name={FIELD_NAMES.body}
              validate={required}
              label="Body"
              placeholder="Body"
              component={Markdown}
            />

            <Field
              name={FIELD_NAMES.cta}
              label="CTA copy"
              placeholder="CTA copy"
              component={Input}
            />

            <Field
              type="checkbox"
              name={FIELD_NAMES.visible}
              component={FieldSwitch}
              label="Show / Hide on UI"
              defaultValue={false}
            />

            <FieldWrapper label="Image" required>
              <FlexWrapper>
                <Field
                  hasCropper
                  validate={required}
                  cropSize={GIVEAWAYS_IMAGE_CROP_SIZE}
                  name={FIELD_NAMES.productPreview}
                  component={ImagePicker}
                />
              </FlexWrapper>
            </FieldWrapper>

            <StyledButton
              type="primary"
              disabled={pristine || !valid}
              onClick={handleSubmit}
            >
              Save changes
            </StyledButton>
          </StyledForm>
        </Spin>
      )}
    />
  )
}

Giveaways.propTypes = {
  id: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
}

Giveaways.defaultTypes = {
  id: null,
}

export default Giveaways
