import { all, put, race, select, take, takeLatest } from 'redux-saga/effects'
import { startLoading, finishLoading } from 'reducers/layoutSlice'
import * as actions from 'actions/views/paymentTransactions'
import {
  selectors as paymentTransactionsSelectors,
  actions as paymentTransactionsActions,
  formatters as paymentTransactionsFormatters,
} from 'domains/paymentTransactions'
import { message } from 'antd'
import { FIELD_NAMES } from 'const/forms'

function* fetchPaymentTransactionsWorker({ payload } = {}) {
  try {
    const body =
      paymentTransactionsFormatters.formatPayloadForFetchRequest(payload)
    yield all([put(paymentTransactionsActions.fetchPaymentTransactions(body))])
    const [success] = yield race([
      take(paymentTransactionsActions.fetchPaymentTransactionsSuccess),
      take(paymentTransactionsActions.fetchPaymentTransactionsFailure),
      put(paymentTransactionsActions.saveFiltersAndSorter(payload)),
    ])
    if (success) {
      yield put(paymentTransactionsActions.saveFiltersAndSorter(payload))
    }
  } catch (error) {
    console.error(error)
  }
}

function* fetchTransactionDetailsWorker({ payload } = {}) {
  try {
    yield all([
      put(startLoading()),
      put(paymentTransactionsActions.fetchTransactionDetails(payload)),
    ])
    yield race([
      take(paymentTransactionsActions.fetchTransactionDetailsSuccess),
      take(paymentTransactionsActions.fetchTransactionDetailsFailure),
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* createPaymentPackageWorker({ payload }) {
  try {
    const body = payload.values
    yield all([
      put(paymentTransactionsActions.startSaving()),
      put(
        paymentTransactionsActions.createPaymentPackage({
          data: body,
        })
      ),
    ])

    const [success] = yield race([
      take(paymentTransactionsActions.createPaymentPackageSuccess),
      take(paymentTransactionsActions.createPaymentPackageFailure),
    ])

    if (success) {
      message.success('Payment Ad Package updated')
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(paymentTransactionsActions.finishSaving())
  }
}

function* updatePaymentPackageWorker({ payload }) {
  try {
    const body = payload.values
    yield all([
      put(paymentTransactionsActions.startSaving()),
      put(
        paymentTransactionsActions.updatePaymentPackage({
          data: body,
          id: body?.[FIELD_NAMES.id],
        })
      ),
    ])
    const [success] = yield race([
      take(paymentTransactionsActions.updatePaymentPackageSuccess),
      take(paymentTransactionsActions.updatePaymentPackageFailure),
    ])

    if (success) {
      const [page, filters, sorter, search] = yield all([
        select(paymentTransactionsSelectors.getPage),
        select(paymentTransactionsSelectors.getFilters),
        select(paymentTransactionsSelectors.getSorter),
        select(paymentTransactionsSelectors.getSearch),
      ])
      yield put(
        actions.fetchPaymentTransactions({
          page,
          filters,
          sorter,
          search,
        })
      )
      message.success('Payment Ad Package updated')
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(paymentTransactionsActions.finishSaving())
  }
}

function* getPaymentPackageRulesWorker() {
  try {
    yield put(paymentTransactionsActions.getRules())
  } catch (error) {
    console.error(error)
  }
}

export default function* () {
  yield all([
    fetchPaymentTransactionsWorker(),
    getPaymentPackageRulesWorker(),
    takeLatest(
      actions.fetchPaymentTransactions,
      fetchPaymentTransactionsWorker
    ),
    takeLatest(actions.fetchTransactionDetails, fetchTransactionDetailsWorker),
    takeLatest(actions.updatePaymentPackage, updatePaymentPackageWorker),
    takeLatest(actions.createPaymentPackage, createPaymentPackageWorker),
    takeLatest(actions.getRules, getPaymentPackageRulesWorker),
  ])
}
