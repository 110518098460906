import {
  all,
  put,
  take,
  takeLatest,
  race,
  select,
  call,
} from 'redux-saga/effects'

import { startLoading, finishLoading } from 'reducers/layoutSlice'
import {
  actions as rulesActions,
  formatters as rulesFormatters,
  selectors as rulesSelectors,
} from 'domains/rules'
import * as actions from 'actions/views/ruleSlugs'
import { FIELD_NAMES } from 'const/forms'

function* getRuleSlugs() {
  const [search, page, filters, sorter] = yield all([
    select(rulesSelectors.getSlugSearchString),
    select(rulesSelectors.getSlugPage),
    select(rulesSelectors.getSlugFilters),
    select(rulesSelectors.getSlugSorter),
  ])
  yield put(
    actions.fetchRuleSlugs({
      search,
      page,
      filters,
      sorter,
    })
  )
}

function* fetchRuleSlugsWorker({ payload } = {}) {
  try {
    const search = yield select(rulesSelectors.getSlugSearchString)
    const body = rulesFormatters.formatPayloadForFetchRequest({
      search,
      ...payload,
    })
    yield all([put(startLoading()), put(rulesActions.fetchRuleSlugs(body))])
    const [success] = yield race([
      take(rulesActions.fetchRuleSlugsSuccess),
      take(rulesActions.fetchRuleSlugsFailure),
    ])
    if (success) {
      yield put(rulesActions.saveRuleSlugsFiltersAndSorter(payload))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* deleteRuleSlugWorker({ payload }) {
  try {
    yield all([put(startLoading()), put(rulesActions.deleteRuleSlug(payload))])
    const [success] = yield race([
      take(rulesActions.deleteRuleSlugSuccess),
      take(rulesActions.deleteRuleSlugFailure),
    ])

    if (success) {
      yield call(getRuleSlugs)
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* createRuleSlugWorker({ payload }) {
  try {
    const body = rulesFormatters.formatEntityForRequests(payload.values)
    yield all([
      put(rulesActions.startSaving()),
      put(rulesActions.createRuleSlug(body)),
    ])
    const [success] = yield race([
      take(rulesActions.createRuleSlugSuccess),
      take(rulesActions.createRuleSlugFailure),
    ])

    if (success) {
      yield call(getRuleSlugs)
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(rulesActions.finishSaving())
  }
}

function* updateRuleSlugWorker({ payload }) {
  try {
    const body = rulesFormatters.formatEntityForRequests(payload.values)
    yield all([
      put(rulesActions.startSaving()),
      put(
        rulesActions.updateRuleSlug({
          data: body,
          id: payload.values[FIELD_NAMES.id],
        })
      ),
    ])
    const [success] = yield race([
      take(rulesActions.updateRuleSlugSuccess),
      take(rulesActions.updateRuleSlugFailure),
    ])

    if (success) {
      yield call(getRuleSlugs)
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(rulesActions.finishSaving())
  }
}

export default function* () {
  yield all([
    fetchRuleSlugsWorker(),
    takeLatest(actions.fetchRuleSlugs, fetchRuleSlugsWorker),
    takeLatest(actions.createRuleSlug, createRuleSlugWorker),
    takeLatest(actions.updateRuleSlug, updateRuleSlugWorker),
    takeLatest(actions.deleteRuleSlug, deleteRuleSlugWorker),
  ])
}
