import { createSlice } from '@reduxjs/toolkit'
import { ENTITY_NAMES } from 'const/api'

const initialState = {
  groups: [],
  categories: [],
  isLoaded: false,
  links: {
    started: null,
    escaped: null,
    not_escaped: null,
  },
  marketingCopy: '',
}

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoaded = false
    },
    finishLoading: (state) => {
      state.isLoaded = true
    },
    overwriteGroups: (state, action) => {
      state.groups = action.payload
    },
    addNewGroup: (state, action) => {
      state.groups = [...state.groups, action.payload]
    },
    deleteGroup: (state, action) => {
      const arr = state.groups.filter((item) => item.id !== action.payload)
      return { ...state, groups: [...arr] }
    },
    changeGroup: (state, action) => {
      let index = undefined
      state.groups.forEach((item, i) => {
        if (item.id === action.payload.id) {
          index = i
        }
      })
      state.groups[index] = action.payload
    },
    saveCategories: (state, action) => ({
      ...state,
      categories: action.payload,
    }),
    createCategorySuccess: (state, action) => {
      state.categories = [...state.categories, action.payload]
    },
    editCategoryFailure: (state) => state,
    createCategoryFailure: (state) => state,
    deleteCategoryFailure: (state) => state,
    deleteCategorySuccess: (state, action) => {
      const arr = state.categories.filter((item) => item.id !== action.payload)
      return { ...state, categories: [...arr] }
    },
    editCategorySuccess: (state, action) => {
      let index = undefined
      state.categories.forEach((item, i) => {
        if (item.id === action.payload.id) {
          index = i
        }
      })
      state.categories[index] = action.payload
    },
    updateMarketingCopySuccess: (state, { payload }) => ({
      ...state,
      marketingCopy: { [ENTITY_NAMES?.marketingCopy]: payload.data.payload },
    }),
    updateMarketingCopyFailure: (state) => state,
    fetchMarketingCopySuccess: (state, { payload }) => ({
      ...state,
      marketingCopy: payload.marketingCopy,
    }),
    fetchMarketingCopyFailure: (state) => state,
    convertLinkSuccess: (state, { payload }) => ({
      ...state,
      links: payload,
    }),
    clearData: () => initialState,
  },
})

export default generalSlice
