import React from 'react'
import useAction from 'utils/useAction'
import { Spin, Typography } from 'antd'
import {
  fetchAllCreditCards,
  fetchCreditCardsByDomain,
  validateCreditCardProgram,
} from 'actions/views/creditCardsComparison'
import { PageContainer, Row, Wrapper } from './CreditCardsComparison.styles'
import { Field, Form } from 'react-final-form'
import { validLink } from '../../helpers/validators'
import { FIELD_NAMES } from '../../const/forms'
import Input from '../../components/Input/Input'
import { useSelector } from 'react-redux'
import {
  getCurrentWebsite,
  getIsLoadingOrSavingActive,
} from 'domains/creditCardsComparison/selectors'
import { getCreditCards } from 'domains/creditCardsComparison/selectors'
import { openCreditCardNegativeFeedbackModal } from 'reducers/modals/actions'
import { getColumns } from './CreditCardsComparison.utils'
import {
  StyledButton,
  StyledForm,
  StyledTable,
} from './CreditCardsComparison.styles'

const CreditCardsComparison = () => {
  const isSavingActive = useSelector(getIsLoadingOrSavingActive)
  const creditCards = useSelector(getCreditCards)
  const currentWebsite = useSelector(getCurrentWebsite)

  const fetchCreditCardsByDomainFunc = useAction(fetchCreditCardsByDomain)
  const validateCreditCardProgramFunc = useAction(validateCreditCardProgram)
  const fetchAllCreditCardsFunc = useAction(fetchAllCreditCards)
  const openCreditCardNegativeFeedbackModalFunc = useAction(
    openCreditCardNegativeFeedbackModal
  )

  const onFetchAllCreditCards = () => {
    fetchAllCreditCardsFunc()
  }

  const onSubmit = (value) => {
    fetchCreditCardsByDomainFunc({ value })
  }

  const onPositiveVote = (creditCardProgram) => {
    validateCreditCardProgramFunc({
      [FIELD_NAMES.valid]: true,
      [FIELD_NAMES.id]: creditCardProgram[FIELD_NAMES.id],
      [FIELD_NAMES.merchantDomains]:
        creditCardProgram[FIELD_NAMES.merchant_domains],
      [FIELD_NAMES.rewardValue]: creditCardProgram[FIELD_NAMES.reward_value],
    })
  }

  const sendNegativeFeedback = (updatedCreditCardProgram) => {
    validateCreditCardProgramFunc({
      [FIELD_NAMES.valid]: false,
      [FIELD_NAMES.id]: updatedCreditCardProgram[FIELD_NAMES.id],
      [FIELD_NAMES.merchantDomains]:
        updatedCreditCardProgram[FIELD_NAMES.merchant_domains],
      [FIELD_NAMES.rewardValue]:
        updatedCreditCardProgram[FIELD_NAMES.reward_value],
      [FIELD_NAMES.comment]: updatedCreditCardProgram[FIELD_NAMES.comment],
    })
  }

  const onDeclineCreditCardProgram = (creditCardProgram) => {
    validateCreditCardProgramFunc({
      [FIELD_NAMES.decline]: true,
      [FIELD_NAMES.id]: creditCardProgram[FIELD_NAMES.id],
      [FIELD_NAMES.merchantDomains]:
        creditCardProgram[FIELD_NAMES.merchant_domains],
      [FIELD_NAMES.rewardValue]: creditCardProgram[FIELD_NAMES.reward_value],
    })
  }

  const onNegativeVote = (creditCardProgram) => {
    openCreditCardNegativeFeedbackModalFunc({
      creditCardProgram,
      callback: (values) => sendNegativeFeedback(values),
    })
  }

  const getRowClassName = (record) => {
    if (record.processed) {
      return 'processed-row'
    }
    if (record.declined) {
      return 'declined-row'
    }
    return ''
  }

  return (
    <Wrapper>
      <Typography.Title level={3}>Credit Cards Comparison</Typography.Title>
      <Spin spinning={isSavingActive} size="large">
        <PageContainer>
          <Row>
            <Form
              destroyOnUnregister
              onSubmit={(values, form) => {
                onSubmit(values)
                form.reset()
                form.resetFieldState(FIELD_NAMES.website)
              }}
              render={({ handleSubmit, pristine }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <Field
                    validate={validLink}
                    name={FIELD_NAMES.website}
                    label="Website"
                    placeholder="Enter website domain"
                    component={Input}
                  />
                  <StyledButton
                    type="primary"
                    disabled={pristine}
                    onClick={handleSubmit}
                  >
                    Fetch credit cards by website
                  </StyledButton>
                </StyledForm>
              )}
            />
            <StyledButton onClick={onFetchAllCreditCards}>
              Refetch all credit cards
            </StyledButton>
          </Row>
          {currentWebsite && (
            <Typography.Title
              level={4}
              style={{
                alignSelf: 'flex-start',
              }}
            >
              Credit card programs for "{currentWebsite}"
            </Typography.Title>
          )}
          {creditCards?.length > 0 && (
            <StyledTable
              dataSource={creditCards}
              columns={getColumns(
                onPositiveVote,
                onNegativeVote,
                onDeclineCreditCardProgram
              )}
              rowClassName={getRowClassName}
            />
          )}
        </PageContainer>
      </Spin>
    </Wrapper>
  )
}

export default CreditCardsComparison
