import React from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { StyledInputNumber } from 'components/styled/InputWithInvalidState'
import useGlobalChangeOnBlur from 'utils/useGlobalChangeOnBlur'
import { inputNumberValueParser } from 'helpers/retailers'

const NumberInput = ({ changeCallback, input, meta, parser, ...rest }) => {
  const handleBlur = useGlobalChangeOnBlur(
    input.value,
    meta.modified,
    changeCallback
  )

  return (
    <Tooltip title={meta.error} color="red">
      <StyledInputNumber
        min={0}
        parser={parser}
        onBlur={handleBlur}
        invalid={meta.error}
        value={input.value}
        onChange={input.onChange}
        {...rest}
      />
    </Tooltip>
  )
}

NumberInput.propTypes = {
  changeCallback: PropTypes.func,
  parser: PropTypes.func,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    modified: PropTypes.bool,
  }).isRequired,
}

NumberInput.defaultProps = {
  changeCallback: noop,
  parser: inputNumberValueParser,
}

export default NumberInput
