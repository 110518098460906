import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  saveFiltersAndSorter,
  startSaving,
  finishSaving,
  clearData,
  fetchPaymentTransactionsSuccess,
  fetchPaymentTransactionsFailure,
  fetchTransactionDetailsSuccess,
  fetchTransactionDetailsFailure,
  fetchPaymentPackageSuccess,
  fetchPaymentPackageFailure,
  clearTransactionDetails,
} = reducer.actions

export const fetchPaymentTransactions = createAction(
  'domain/paymentTransactions/fetchTransactions'
)
export const fetchTransactionDetails = createAction(
  'domain/paymentTransactions/fetchTransactionDetails'
)

export const updatePaymentPackage = createAction(
  'domain/paymentTransaction/updatePaymentPackage'
)
export const updatePaymentPackageSuccess = createAction(
  'domain/paymentTransaction/updatePaymentPackageSuccess'
)
export const updatePaymentPackageFailure = createAction(
  'domain/paymentTransaction/updatePaymentPackageFailure'
)

export const createPaymentPackage = createAction(
  'domain/paymentTransaction/createPaymentPackage'
)
export const createPaymentPackageSuccess = createAction(
  'domain/paymentTransaction/createPaymentPackageSuccess'
)
export const createPaymentPackageFailure = createAction(
  'domain/paymentTransaction/createPaymentPackageFailure'
)

export const getRules = createAction('domain/paymentTransaction/getRules')
