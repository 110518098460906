import { apiInstance3 } from 'utils/request'
import { ENTITY_NAMES, RELATIONSHIPS_NAMES } from 'const/api'
import parseError from 'helpers/parseServerError'

export const markExtensionPassedReview = async (version) => {
  try {
    return await apiInstance3.put(
      `/${RELATIONSHIPS_NAMES.backoffice}/${ENTITY_NAMES.extensionVersions}/mark_as_pass_review`,
      { version }
    )
  } catch (error) {
    parseError(error, 'Failed to mark extension as passed review')
  }
}

export const getExtensionVersions = async (version) => {
  try {
    const response = await apiInstance3.get(`/${RELATIONSHIPS_NAMES.backoffice}/${ENTITY_NAMES.extensionVersions}/counter`, { params: { version } })
    return response.data.counter
  } catch (error) {
    parseError(error, 'Failed to get extension versions counter')
    return null
  }
}
