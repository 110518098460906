import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getIsLoaded,
  getSortedLogs,
  getPage,
  getPageCount,
} from 'domains/adminLogs/selectors'
import useAction from 'utils/useAction'
import {
  StyledTable,
  StyledSelect,
  StyledButtonStack,
  StyledInputNumber,
  ButtonsWrapper,
} from './AdminHistory.styles'
import { Button, Select } from 'antd'
import getColumns from './columns'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import { HEADER_OFFSET, PAGE_SIZE, SCROLL } from 'const/tables'
import { fetchAllLogs } from 'actions/views/adminLogs'

const AdminHistory = () => {
  const [filters, setFilters] = useState({})
  const [idValue, setIdValue] = useState(null)
  const isLoaded = useSelector(getIsLoaded)
  const page = useSelector(getPage)
  const pageCount = useSelector(getPageCount)
  const dataSource = useSelector(getSortedLogs)

  const fetchLogsFunc = useAction(fetchAllLogs)

  const itemTypes = [
    'Retailer',
    'Category',
    'RetailerCategory',
    'Group',
    'RetailerGroup',
    'ShopOffer',
    'AdPackage',
    'User',
    'Admin',
    'AdminPayment',
    'EmailNotification',
    'MobileNotification',
  ]

  const onTableChange = (pagination, filter) => {
    fetchLogsFunc({
      ...filters,
      page: pagination.current,
    })
  }

  const onLogsTypeChosen = (value) => {
    setIdValue(null)
    setFilters((prev) => ({ ...prev, itemType: value }))

    fetchLogsFunc({ itemType: value })
  }

  const onIdFilter = () => {
    setFilters((prev) => ({ ...prev, itemId: idValue }))

    fetchLogsFunc({ ...filters, itemId: idValue })
  }

  const idChangeHandler = (value) => {
    setIdValue(value)
  }

  const clearFiltersHandler = () => {
    onLogsTypeChosen(null)
    setIdValue(null)
    setFilters({})
  }

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  return (
    <>
      <StyledButtonStack>
        <StyledSelect
          placeholder="Choose logs type"
          onChange={onLogsTypeChosen}
          value={filters?.itemType}
        >
          {itemTypes.map((item) => {
            return (
              <Select.Option key={item}>
                {item.replace(/([A-Z])/g, ' $1')}
              </Select.Option>
            )
          })}
        </StyledSelect>

        <StyledInputNumber
          disabled={!filters?.itemType}
          onChange={(value) => idChangeHandler(value)}
          placeholder="Search id"
          min={1}
          value={idValue}
        />
        <ButtonsWrapper>
          <Button
            type="primary"
            disabled={!idValue}
            onClick={() => onIdFilter()}
          >
            Search
          </Button>

          <Button
            type="primary"
            disabled={!filters?.itemType}
            onClick={() => clearFiltersHandler()}
          >
            Clear filters
          </Button>
        </ButtonsWrapper>
      </StyledButtonStack>

      <StyledTable
        onChange={onTableChange}
        showSorterTooltip={false}
        columns={getColumns()}
        dataSource={dataSource}
        expandable={false}
        pagination={paginationConfig}
        sticky={isLoaded && HEADER_OFFSET}
        scroll={SCROLL}
        bordered
        loading={false}
        rowKey={getRowKey}
        rowClassName={getRegularRowClass}
      />
    </>
  )
}

export default AdminHistory
