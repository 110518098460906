import React from 'react'
import { PageLayout } from 'components/styled/layout'
import SendEmailNotification from './SendEmailNotification'
import { Title } from './EmailNotifications.styles'

const EmailNotifications = () => (
  <PageLayout>
    <Title>Test email notification</Title>
    <SendEmailNotification />
  </PageLayout>
)

export default EmailNotifications
