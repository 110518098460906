import styled from 'styled-components'
import { Skeleton } from 'antd'

export const StyledSkeleton = styled(Skeleton.Input)`
  position: absolute;
  height: 114px;
  width: 100%;
  z-index: 1;

  & > .ant-skeleton-input {
    height: 100%;
  }
`
