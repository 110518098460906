export default () => (next) => (action) => {
  const [, actionState] = action.type.split(':')

  if (actionState === 'request') {
    return new Promise((resolve, reject) =>
      next({ ...action, promise: { resolve, reject } })
    )
  }

  return next(action)
}
