import moment from 'moment'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import {
  FORM_CHANGES_FIELD_PREFIX,
  FORM_COMMENTS_FIELD_PREFIX,
  FIELD_NAMES,
  getGroupFieldName,
} from 'const/forms'
import {
  RETAILER_TYPES_IN_ATTRS,
  RETAILER_TYPE_TO_ENTITY_NAME,
} from 'const/retailers'
import { RELATIONSHIPS_NAMES, ENTITY_NAMES, ENTITY_ATTRS } from 'const/api'
import { DEFAULT_MEDIA_TYPE } from 'const/adPackage'
import { getFlagUrl } from 'helpers/flags'
import { multiplyFloat } from 'helpers/float'

const formatCountry = (retailer) =>
  retailer.attributes[FIELD_NAMES.countryCode]
    ? getFlagUrl(retailer.attributes[FIELD_NAMES.countryCode])
    : null

export const formatType = (retailer) =>
  RETAILER_TYPE_TO_ENTITY_NAME[retailer.attributes[FIELD_NAMES.type]]

const formatCbActive = (retailer) =>
  retailer.attributes[ENTITY_ATTRS[FIELD_NAMES.cb]] === 'active'

const formatMediaType = (retailer) => {
  const value = retailer.attributes[FIELD_NAMES.mediaType]
  return {
    label: value ? capitalize(value) : 'None',
    value: value || DEFAULT_MEDIA_TYPE,
    key: value || DEFAULT_MEDIA_TYPE,
  }
}

export const getGeneralRetailerAttrs = (retailer) => {
  const isRegional =
    retailer.attributes.type === RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.regional]
  const isDomain =
    retailer.attributes.type === RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.domain]
  const isGlobal =
    retailer.attributes.type === RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.main]
  const isMobile =
    retailer.attributes.type === RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.mobile]
  const isInvalid = !(isGlobal || isRegional || isMobile || isDomain)
  const karmaGivesCanBeChanged =
    retailer.attributes[FIELD_NAMES.karmaGivesCanBeChanged]
  return {
    isDisabled: isMobile || isDomain || isInvalid || !karmaGivesCanBeChanged,
    isInvalid,
    isRegional,
    isDomain,
    isGlobal,
    isMobile,
    [FIELD_NAMES.id]: retailer.id,
    key: retailer.id,
    type: formatType(retailer),
    [FIELD_NAMES.stake]: Math.floor(
      Number(retailer.attributes[FIELD_NAMES.stake])
    ),
    [FIELD_NAMES.type]: formatType(retailer),
    [FIELD_NAMES.deleted]: retailer.attributes[FIELD_NAMES.deleted],
    [FIELD_NAMES.maxKarmaGives]: retailer.attributes[FIELD_NAMES.maxKarmaGives],
    [FIELD_NAMES.tagsCount]: retailer.attributes[FIELD_NAMES.tagsCount],
    [FIELD_NAMES.karmaGivesCanBeChanged]:
      retailer.attributes[FIELD_NAMES.karmaGivesCanBeChanged],
    [FIELD_NAMES.termsAndConditions]:
      retailer.attributes[FIELD_NAMES.termsAndConditions],
    [FIELD_NAMES.logo]: retailer.attributes[FIELD_NAMES.logo],
    [FIELD_NAMES.description]: retailer.attributes[FIELD_NAMES.description],
    [FIELD_NAMES.whatHappensNext]:
      retailer.attributes[FIELD_NAMES.whatHappensNext],
    [FIELD_NAMES.cannotBeDeleted]:
      retailer.attributes[FIELD_NAMES.cannotBeDeleted],
    [FIELD_NAMES.mainId]: retailer.attributes[FIELD_NAMES.mainRetailerId],
    [FIELD_NAMES.hasDomainRetailers]:
      retailer.attributes[FIELD_NAMES.hasDomainRetailers],
    [FIELD_NAMES.hasMobileRetailers]:
      retailer.attributes[FIELD_NAMES.hasMobileRetailers],
    [FIELD_NAMES.mediaType]: formatMediaType(retailer),
    [FIELD_NAMES.unavailableRegions]:
      retailer.attributes[FIELD_NAMES.unavailableRegions],
    hasRegionalRetailers: !isEmpty(
      retailer.attributes[FIELD_NAMES.unavailableRegions]
    ),
    url: `//${retailer.attributes.domain}`,
    [FIELD_NAMES.domain]: retailer.attributes[FIELD_NAMES.domain],
    [FIELD_NAMES.country]: formatCountry(retailer),
    [FIELD_NAMES.countryCode]: retailer.attributes[FIELD_NAMES.countryCode],
    [FIELD_NAMES.name]: retailer.attributes[FIELD_NAMES.name],
    [FIELD_NAMES.cbActive]: formatCbActive(retailer),
    // [FIELD_NAMES.cb]: retailer.relationships[RELATIONSHIPS_NAMES.cb]?.data || {},
    [FIELD_NAMES.ruleId]: retailer.attributes[FIELD_NAMES.ruleId],
  }
}

export const formatRetailerData = (retailer) => ({
  ...getGeneralRetailerAttrs(retailer),
  [FIELD_NAMES.contacts]:
    retailer.relationships[RELATIONSHIPS_NAMES.contacts]?.data || {},
  [FIELD_NAMES.comment]:
    retailer.relationships[RELATIONSHIPS_NAMES.comment]?.data || {},
  // [FIELD_NAMES.promoCb]: retailer.relationships[RELATIONSHIPS_NAMES.promoCb]?.data || {},
  // [FIELD_NAMES.plusCb]: retailer.relationships[RELATIONSHIPS_NAMES.plusCb]?.data || {},
  // [FIELD_NAMES.promoPlusCb]: retailer.relationships[RELATIONSHIPS_NAMES.promoPlusCb]?.data || {},
  // [FIELD_NAMES.cpa]: retailer.attributes[FIELD_NAMES.cpa],
})

export const formatDeletedRetailerData = (retailer) => ({
  ...getGeneralRetailerAttrs(retailer),
})

export const getCashbackData = (cashback) => {
  const start =
    cashback?.meta?.started_at && new Date(cashback?.meta?.started_at)
  const finish =
    cashback?.meta?.finished_at && new Date(cashback?.meta?.finished_at)
  return {
    cb: ~~cashback?.attributes?.value,
    dates: start
      ? [
          moment(start).toISOString(),
          finish ? moment(finish).toISOString() : null,
        ]
      : [null, null],
  }
}

export const getContacts = (contacts, ids) =>
  ids?.map((contact) => contacts?.[contact.id]?.attributes) || []

export const getContactsIds = (contacts, ids) =>
  ids?.map((contact) => contacts?.[contact.id]?.id)

export const getMainCategoryId = (retailer, retailerCategories) => {
  const retailerCategoriesPointers =
    retailer.relationships[RELATIONSHIPS_NAMES.retailerCategories]?.data || []
  const retailerCategoriesData = retailerCategoriesPointers.reduce(
    (result, pointer) => {
      result.push(retailerCategories[pointer.id])
      return result
    },
    []
  )
  const mainCategory = retailerCategoriesData.find(
    ({ id }) => retailerCategories[id]?.attributes[ENTITY_ATTRS.main]
  )
  return mainCategory?.id
}

export const getCashbackValue = (cashback, retailer, name) =>
  ~~cashback?.[retailer.relationships[name]?.data?.id]?.attributes?.value

export const getRetailerData = (entities, ids) => {
  const retailers = entities[RELATIONSHIPS_NAMES.retailers]
  return (
    retailers &&
    ids.reduce((result, id) => {
      result.push(formatRetailerData(retailers[id]))
      return result
    }, [])
  )
}

export const getDeletedRetailerData = (entities, ids) => {
  const retailers = entities[RELATIONSHIPS_NAMES.retailers]
  return (
    retailers &&
    ids.reduce((result, id) => {
      result.push(formatDeletedRetailerData(retailers[id]))
      return result
    }, [])
  )
}

export const getRetailersWithChanges = (entities) => {
  const retailers = entities[RELATIONSHIPS_NAMES.retailers]
  return (
    retailers &&
    Object.keys(retailers).reduce((result, id) => {
      result.push(formatRetailerData(retailers[id]))
      return result
    }, [])
  )
}

export const getDataForRetailers = (
  entities,
  formatter,
  prefix,
  categories
) => {
  const {
    [ENTITY_NAMES.retailers]: retailers,
    [ENTITY_NAMES.cb]: cb,
    [ENTITY_NAMES.comments]: comments,
    [ENTITY_NAMES.contacts]: contacts,
    [ENTITY_NAMES.promoCb]: promoCb,
    [ENTITY_NAMES.plusCb]: plusCb,
    [ENTITY_NAMES.promoPlusCb]: promoPlusCb,
    [ENTITY_NAMES.retailerCategories]: retailerCategories,
  } = entities

  return (
    retailers &&
    Object.values(retailers).reduce((result, retailer) => {
      result[prefix ? `${prefix}_${retailer.id}` : retailer.id] = formatter(
        {
          retailer,
          mainId: retailer.id,
          cb,
          promoCb,
          plusCb,
          promoPlusCb,
          comments,
          contacts,
          retailerCategories,
        },
        categories
      )
      return result
    }, {})
  )
}

export const formatValues = ({ retailer, mainId, comments, contacts }) => ({
  regional:
    retailer.attributes.type === RETAILER_TYPES_IN_ATTRS[ENTITY_NAMES.regional],
  type: formatType(retailer),
  [FIELD_NAMES.mainId]: mainId,
  [FIELD_NAMES.cbActive]:
    retailer.attributes[ENTITY_ATTRS[FIELD_NAMES.cb]] === 'active',
  // [FIELD_NAMES.cb]: getCashbackValue(cb, retailer, RELATIONSHIPS_NAMES.cb),
  [FIELD_NAMES.maxKarmaGives]: multiplyFloat(
    retailer.attributes[ENTITY_ATTRS[FIELD_NAMES.maxKarmaGives]] || 0,
    100
  ),
  [FIELD_NAMES.tagsCount]:
    retailer.attributes[ENTITY_ATTRS[FIELD_NAMES.tagsCount]] || 0,
  [FIELD_NAMES.ruleId]: retailer.attributes[ENTITY_ATTRS[FIELD_NAMES.ruleId]],
  // [FIELD_NAMES.cpa]: retailer.attributes[ENTITY_ATTRS[FIELD_NAMES.cpa]] || 0,
  // [FIELD_NAMES.promoCb]: getCashbackData(promoCb?.[retailer.relationships[RELATIONSHIPS_NAMES.promoCb]?.data?.id]),
  // [FIELD_NAMES.plusCb]: getCashbackValue(plusCb, retailer, RELATIONSHIPS_NAMES.plusCb),
  // [FIELD_NAMES.promoPlusCb]:
  //   getCashbackData(promoPlusCb?.[retailer.relationships[RELATIONSHIPS_NAMES.promoPlusCb]?.data?.id]),
  ...formatCommentsValues({
    retailer,
    comments,
    contacts,
  }),
})

export const formatCommentsValues = ({ retailer, comments, contacts }) => ({
  [FIELD_NAMES.comment]:
    comments?.[retailer.relationships[RELATIONSHIPS_NAMES.comment]?.data?.id]
      ?.attributes?.text,
  [FIELD_NAMES.contacts]: getContacts(
    contacts,
    retailer.relationships[RELATIONSHIPS_NAMES.contacts]?.data
  ),
})

export const formatIds = ({ retailer, contacts }) => ({
  // [FIELD_NAMES.cb]: cb?.[retailer.relationships[RELATIONSHIPS_NAMES.cb]?.data?.id]?.id,
  // [FIELD_NAMES.promoCb]: promoCb?.[retailer.relationships[RELATIONSHIPS_NAMES.promoCb]?.data?.id]?.id,
  // [FIELD_NAMES.plusCb]: plusCb?.[retailer.relationships[RELATIONSHIPS_NAMES.plusCb]?.data?.id]?.id,
  // [FIELD_NAMES.promoPlusCb]: promoPlusCb?.[retailer.relationships[RELATIONSHIPS_NAMES.promoPlusCb]?.data?.id]?.id,
  [FIELD_NAMES.comment]:
    retailer.relationships[RELATIONSHIPS_NAMES.comment]?.data?.id,
  [FIELD_NAMES.contacts]: getContactsIds(
    contacts,
    retailer.relationships[RELATIONSHIPS_NAMES.contacts]?.data
  ),
})

export const getInitialValues = (entities) =>
  getDataForRetailers(entities, formatValues, FORM_CHANGES_FIELD_PREFIX)
export const getCommentsValues = (entities) =>
  getDataForRetailers(
    entities,
    formatCommentsValues,
    FORM_COMMENTS_FIELD_PREFIX
  )
export const getIds = (entities) => getDataForRetailers(entities, formatIds)

const formatCategoriesInitialValue = (
  retailer,
  retailerCategories,
  categories
) => {
  const filteredRetailerCategories = retailer.relationships[
    RELATIONSHIPS_NAMES.retailerCategories
  ]?.data?.map((item) => retailerCategories[item.id])

  return (
    filteredRetailerCategories?.reduce((result, retailerCategory) => {
      const categoryId =
        retailerCategory.relationships[RELATIONSHIPS_NAMES.category]?.data?.id
      result[categoryId] = {
        value: categoryId,
        key: categoryId,
        label: categories[categoryId]?.attributes.name,
        [FIELD_NAMES.mainCategory]:
          retailerCategory.attributes[ENTITY_ATTRS.main],
      }
      return result
    }, {}) || {}
  )
}

const formatCategories = (retailer, retailerCategories) =>
  retailer.relationships[RELATIONSHIPS_NAMES.retailerCategories]?.data?.map(
    (item) => retailerCategories[item.id]
  )

const formatShowOnUiInitialValue = (retailer) => retailer.attributes.visible

const formatGroupsInitialValues = (
  retailer,
  retailerGroups = {},
  groups = {}
) => {
  const retailerGroupsPointers =
    retailer.relationships[RELATIONSHIPS_NAMES.retailerGroups]?.data || []
  const retailerGroupsRelationships = retailerGroupsPointers.reduce(
    (result, retailerGroupsPointer) => {
      const retailerGroup = retailerGroups[retailerGroupsPointer.id]
      if (retailerGroup) {
        result[
          retailerGroup.relationships[RELATIONSHIPS_NAMES.group].data?.id
        ] = retailerGroup.attributes.position
      }
      return result
    },
    {}
  )

  return Object.values(groups).reduce((result, { id }) => {
    result[getGroupFieldName(id)] = {
      [FIELD_NAMES.active]: retailerGroupsRelationships.hasOwnProperty(id),
      [FIELD_NAMES.position]: retailerGroupsRelationships?.[id] || 0,
    }
    return result
  }, {})
}

export const formatData = ({
  retailer,
  retailerGroups,
  groups,
  retailerCategories,
}) => ({
  ...getGeneralRetailerAttrs(retailer),
  [FIELD_NAMES.categories]: formatCategories(retailer, retailerCategories),
  [FIELD_NAMES.showOnUi]:
    retailer.attributes[ENTITY_ATTRS[FIELD_NAMES.showOnUi]],
  [FIELD_NAMES.actionOnClick]:
    retailer.attributes[ENTITY_ATTRS[FIELD_NAMES.actionOnClick]],
  [FIELD_NAMES.hasHeaderWebImage]:
    retailer.attributes[ENTITY_ATTRS.hasHeaderWebImage],
  ...formatGroupsInitialValues(retailer, retailerGroups, groups),
})

const formatInitialValue = ({
  retailer,
  retailerGroups,
  groups,
  retailerCategories,
  categories,
}) => ({
  [FIELD_NAMES.mediaType]: formatMediaType(retailer),
  [FIELD_NAMES.categories]: formatCategoriesInitialValue(
    retailer,
    retailerCategories,
    categories
  ),
  [FIELD_NAMES.actionOnClick]:
    retailer.attributes[FIELD_NAMES.actionOnClick] === 'show',
  [FIELD_NAMES.showOnUi]: formatShowOnUiInitialValue(retailer),
  ...formatGroupsInitialValues(retailer, retailerGroups, groups),
})

const formatGroupsToRetailerGroupsRelationships = (
  retailer,
  retailerGroups
) => {
  const retailerGroupsPointers =
    retailer.relationships[RELATIONSHIPS_NAMES.retailerGroups]?.data || []
  return retailerGroupsPointers.reduce((result, { id }) => {
    result[retailerGroups[id].relationships.group.data.id] = id
    return result
  }, {})
}

const formatCategoriesToRetailerCategoriesRelationships = (
  retailer,
  retailerCategories
) => {
  const retailerCategoriesPointers =
    retailer.relationships[RELATIONSHIPS_NAMES.retailerCategories]?.data || []
  return retailerCategoriesPointers.reduce((result, { id }) => {
    result[retailerCategories[id].relationships.category.data.id] = id
    return result
  }, {})
}

export const formatEditorialTableData = (entities, ids) => {
  const retailers = entities[RELATIONSHIPS_NAMES.retailers]
  return (
    retailers &&
    ids.reduce((result, id) => {
      result.push(
        formatData({
          retailer: retailers[id],
          retailerGroups: entities[RELATIONSHIPS_NAMES.retailerGroups],
          groups: entities[RELATIONSHIPS_NAMES.groups],
          retailerCategories: entities[RELATIONSHIPS_NAMES.retailerCategories],
          categories: entities[RELATIONSHIPS_NAMES.categories],
        })
      )
      return result
    }, [])
  )
}

export const formatEditorialTableInitialValues = (entities, groups) => {
  const retailers = entities[RELATIONSHIPS_NAMES.retailers]
  const retailerGroups = entities[RELATIONSHIPS_NAMES.retailerGroups] || []
  const retailerCategories =
    entities[RELATIONSHIPS_NAMES.retailerCategories] || []
  const categories = entities[RELATIONSHIPS_NAMES.categories] || []
  return (
    retailers &&
    Object.values(retailers).reduce((result, mainRetailer) => {
      result[`${FORM_CHANGES_FIELD_PREFIX}_${mainRetailer.id}`] =
        formatInitialValue({
          retailer: mainRetailer,
          retailerGroups,
          groups,
          retailerCategories,
          categories,
        })
      return result
    }, {})
  )
}

export const formatRetailersGroupsRelationships = (entities) => {
  const retailers = entities[RELATIONSHIPS_NAMES.retailers]
  const retailerGroups = entities[RELATIONSHIPS_NAMES.retailerGroups] || []
  return (
    retailers &&
    Object.values(retailers).reduce((result, retailer) => {
      result[retailer.id] = formatGroupsToRetailerGroupsRelationships(
        retailer,
        retailerGroups
      )
      return result
    }, {})
  )
}

export const formatRetailersCategoriesRelationships = (entities) => {
  const retailers = entities[RELATIONSHIPS_NAMES.retailers]
  const retailerCategories =
    entities[RELATIONSHIPS_NAMES.retailerCategories] || []
  return (
    retailers &&
    Object.values(retailers).reduce((result, retailer) => {
      result[retailer.id] = formatCategoriesToRetailerCategoriesRelationships(
        retailer,
        retailerCategories
      )
      return result
    }, {})
  )
}

export const formatEditStoreContentInitialValues = (entities, id) => {
  const retailer = entities?.[ENTITY_NAMES.retailers]?.[id]
  return {
    id,
    [ENTITY_ATTRS.name]: retailer?.attributes?.[ENTITY_ATTRS.name] || '',
    type: formatType(retailer),
    [FIELD_NAMES.country]: formatCountry(retailer),
    [FIELD_NAMES.countryCode]: retailer.attributes[FIELD_NAMES.countryCode],
    [ENTITY_ATTRS.termsAndConditions]:
      retailer?.attributes?.[ENTITY_ATTRS.termsAndConditions] || '',
    [ENTITY_ATTRS.whatHappensNext]:
      retailer?.attributes?.[ENTITY_ATTRS.whatHappensNext] || '',
    [ENTITY_ATTRS.description]:
      retailer?.attributes?.[ENTITY_ATTRS.description] || '',
    [ENTITY_ATTRS.metaDescription]:
      retailer?.attributes?.[ENTITY_ATTRS.metaDescription] || '',
    [ENTITY_ATTRS.faq]: retailer?.attributes?.[ENTITY_ATTRS.faq] || [],
    [ENTITY_ATTRS.hasHeaderWebImage]:
      !!retailer?.attributes?.[ENTITY_ATTRS.hasHeaderWebImage],
    [ENTITY_ATTRS.logoImageUri]:
      retailer?.attributes?.[ENTITY_ATTRS.logoImageUri] || '',
    [ENTITY_ATTRS.brandImage1]:
      retailer?.attributes?.[ENTITY_ATTRS.brandImage1] || '',
    [ENTITY_ATTRS.brandImage2]:
      retailer?.attributes?.[ENTITY_ATTRS.brandImage2] || '',
    [ENTITY_ATTRS.brandImage3]:
      retailer?.attributes?.[ENTITY_ATTRS.brandImage3] || '',
    [ENTITY_ATTRS.brandImage4]:
      retailer?.attributes?.[ENTITY_ATTRS.brandImage4] || '',
    [ENTITY_ATTRS.brandImage5]:
      retailer?.attributes?.[ENTITY_ATTRS.brandImage5] || '',
    [ENTITY_ATTRS.brandImage6]:
      retailer?.attributes?.[ENTITY_ATTRS.brandImage6] || '',
    [ENTITY_ATTRS.brandImage9]:
      retailer?.attributes?.[ENTITY_ATTRS.brandImage9] || '',
    [ENTITY_ATTRS.brandImage10]:
      retailer?.attributes?.[ENTITY_ATTRS.brandImage10] || '',
    [ENTITY_ATTRS.brandImage11]:
      retailer?.attributes?.[ENTITY_ATTRS.brandImage11] || '',
    [ENTITY_ATTRS.brandImage12]:
      retailer?.attributes?.[ENTITY_ATTRS.brandImage12] || '',
  }
}
