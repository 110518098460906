import React, { useContext, useCallback } from 'react'
import { Button } from 'antd'
import Modal from 'components/Modal/Modal'
import { Wrapper } from './Wrapper.styles.js'
import useAction from 'utils/useAction.js'
import { openConfirmModal } from 'reducers/modals/actions.js'
import { deleteFeatureRequest } from 'domains/features/actions.js'
import { useSelector } from 'react-redux'
import { newAdminRoles } from 'domains/admin/selectors.js'
import { ROLES } from 'const/roles.js'

const ButtonGroup = ({ id, name }) => {
  const modal = useContext(Modal.Context)
  const userRoles = useSelector(newAdminRoles)
  const openConfirmModalFunc = useAction(openConfirmModal)
  const deleteFeatureFunc = useAction(deleteFeatureRequest)

  const openModalCallback = useCallback(() => {
    modal.open('featureForm', { id })
  }, [modal, id])

  const handleDeleteFeature = () => {
    const callback = () => deleteFeatureFunc(id)
    const text = `Are you sure you want to delete feature "${name}"?`
    openConfirmModalFunc({ callback, text })
  }

  return (
    <Wrapper>
      <Button onClick={openModalCallback} type="primary">
        Edit
      </Button>
      {(userRoles.includes(ROLES.super) || userRoles.includes(ROLES.lead)) && (
        <Button onClick={handleDeleteFeature} type="primary">
          Delete
        </Button>
      )}
    </Wrapper>
  )
}

export default ButtonGroup
