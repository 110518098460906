import React from 'react'
import Table from './Table'
import { Wrapper } from './AdPackages.styles'

const AdPackages = () => (
  <Wrapper>
    <Table />
  </Wrapper>
)

export default AdPackages
