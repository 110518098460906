import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { diff } from 'deep-object-diff'
import { Layout, Spin } from 'antd'
import ImagesForm from './ImagesForm'
import useAction from 'utils/useAction'
import { useSelector } from 'react-redux'
import {
  getRetailerImages,
  getInitialValuesRetailerImages,
  getRetailerImagesImpressions,
  isLoadingOrSavingActive,
  getRetailerById,
  deletedSets,
} from 'domains/retailers/selectors'
import {
  updateRetailerImages,
  getRetailerImagesById,
} from 'domains/retailers/actions'
import { FIELD_NAMES, FORM_NAMES } from 'const/forms'

const StoreImages = ({ closeModal, id }) => {
  const deletedFields = useSelector(deletedSets)
  const spinning = useSelector(isLoadingOrSavingActive)
  const initialValues = useSelector(getInitialValuesRetailerImages)(id)
  const fullData = useSelector(getRetailerImages)(id)
  const impressions = useSelector(getRetailerImagesImpressions)(id)
  const retailer = useSelector(getRetailerById)(id)
  const updateRetailerImagesFunc = useAction(updateRetailerImages)
  const getRetailerImagesFunc = useAction(getRetailerImagesById)

  const onSubmit = (values) => {
    const filteredValues = {
      ...values,
      [FIELD_NAMES.set]:
        values[FIELD_NAMES.set]?.map((set, index) =>
          deletedFields.includes(index) ? null : set
        ) || [],
    }
    updateRetailerImagesFunc({
      retailerId: id,
      fullData,
      values: diff(initialValues, filteredValues),
    })
  }

  useEffect(() => {
    getRetailerImagesFunc({ id })
  }, [getRetailerImagesFunc, id])

  return (
    <Layout.Content>
      <Spin spinning={spinning} size="large">
        <ImagesForm
          retailer={retailer}
          formName={FORM_NAMES.IMAGES}
          closeModal={closeModal}
          initialValues={initialValues}
          fullData={fullData}
          impressions={impressions}
          onSubmit={onSubmit}
        />
      </Spin>
    </Layout.Content>
  )
}

StoreImages.propTypes = {
  id: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default StoreImages
