import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sortedIds: [],
  entities: {},
  filters: {},
  sorter: {},
  isLoaded: false,
  isSavingActive: false,
  search: '',
  page: 1,
  pageCount: 1,
}

const bonusesSlice = createSlice({
  name: 'bonuses',
  initialState,
  reducers: {
    fetchBonusesSuccess: (state, { payload }) => ({
      ...state,
      isLoaded: true,
      page: payload?.page || initialState.page,
      pageCount: payload?.meta?.page_count,
      entities: payload.bonuses,
      sortedIds: payload.sortedIds,
      filters: payload.filters || initialState.filters,
    }),
    fetchBonusesFailure: (state) => state,
    resetFiltersAndSorter: (state) => ({
      ...state,
      page: initialState.page,
      filters: initialState.filters,
      sorter: initialState.sorter,
    }),
    saveFiltersAndSorter: (state, { payload = {} }) => ({
      ...state,
      search: payload.search,
      filters: payload.filters || initialState.filters,
      sorter: payload.sorter || initialState.sorter,
    }),
    startSaving: (state) => ({
      ...state,
      isSavingActive: true,
    }),
    finishSaving: (state) => ({
      ...state,
      isSavingActive: false,
    }),
    clearData: () => initialState,
  },
})

export default bonusesSlice
