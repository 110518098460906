import { apiInstance } from 'utils/request'
import { RELATIONSHIPS_NAMES } from 'const/api'
import { FIELD_NAMES } from '../../const/forms'
import parseError from 'helpers/parseServerError'

export const getCampaigns = ({ page, search, filters, sorter }) => {
  return apiInstance
    .get(`/${RELATIONSHIPS_NAMES.campaignDeals}`, {
      params: {
        filter: {
          search: search ? encodeURIComponent(search) : null,
          ...filters,
        },
        sort: sorter || FIELD_NAMES.id,
        page: {
          number: page,
          size: 20,
        },
      },
    })
    .catch((error) => {
      parseError(error, 'Cannot get campaigns')
    })
}

export const createCampaign = (data) => {
  const notify = data.attributes[FIELD_NAMES.notifySlack]
  delete data.attributes[FIELD_NAMES.notifySlack]

  return apiInstance
    .post(`/${RELATIONSHIPS_NAMES.campaignDeals}?notify_slack=${notify}`, {
      data,
    })
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Campaign hasn't been created")
    })
}

export const updateCampaign = (data) => {
  const notify = data.attributes[FIELD_NAMES.notifySlack]
  delete data.attributes[FIELD_NAMES.notifySlack]

  return apiInstance
    .patch(
      `/${RELATIONSHIPS_NAMES.campaignDeals}/${data.id}?notify_slack=${notify}`,
      {
        data,
      }
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Campaign hasn't been created")
    })
}

export const deleteCampaign = (id) =>
  apiInstance
    .delete(`/${RELATIONSHIPS_NAMES.campaignDeals}/${id}`)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Campaign hasn't been deleted")
    })
