import React from 'react'
import { useSelector } from 'react-redux'
import {
  getFiltersPaymentsUploads,
  getIsDataFilteredOrSortedPaymentsUploads,
  getIsLoadedPaymentsUploads,
  getPagePaymentsUploads,
  getPageCountPaymentsUploads,
  getSearchStringPaymentsUploads,
  getSorterPaymentsUploads,
  getSortedPaymentsUploads,
} from 'domains/payments/selectors'
import useAction from 'utils/useAction'
import {
  StyledTable,
  Buttons,
  StyledButton,
  Wrapper,
} from './PaymentsUploads.styles'
import getColumns from './columns'
import { getRowClass, getRowKey } from 'helpers/tables'
import isEqual from 'lodash/isEqual'
import { fetchPaymentUpload } from 'actions/views/payments'
import { HEADER_OFFSET, PAGE_SIZE, SCROLL } from 'const/tables'

const PaymentsUploadAttempts = () => {
  const isLoaded = useSelector(getIsLoadedPaymentsUploads)
  const page = useSelector(getPagePaymentsUploads)
  const search = useSelector(getSearchStringPaymentsUploads)
  const pageCount = useSelector(getPageCountPaymentsUploads)
  const filters = useSelector(getFiltersPaymentsUploads)
  const sorter = useSelector(getSorterPaymentsUploads)
  const dataSource = useSelector(getSortedPaymentsUploads)
  const isDataFilteredOrSorted = useSelector(
    getIsDataFilteredOrSortedPaymentsUploads
  )

  const fetchAttemptFunc = useAction(fetchPaymentUpload)

  const onTableChange = (pagination, newFilters, newSorter) => {
    const pageChanged = page !== pagination.current
    const newFiltersOrSorter =
      !pageChanged &&
      (!isEqual(newFilters, filters) || !isEqual(newSorter, sorter))
    fetchAttemptFunc({
      page: newFiltersOrSorter ? 1 : pagination.current,
      search,
      filters: newFilters,
      sorter: newSorter,
    })
  }

  const handleResetFilters = () =>
    fetchAttemptFunc({
      search,
      page: 1,
      filters: {},
      sorter: {},
    })

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  return (
    <Wrapper>
      <Buttons>
        <StyledButton
          onClick={handleResetFilters}
          disabled={!isLoaded || !isDataFilteredOrSorted}
        >
          Reset filters
        </StyledButton>
      </Buttons>
      <StyledTable
        onChange={onTableChange}
        showSorterTooltip={false}
        columns={getColumns(filters, sorter)}
        dataSource={dataSource}
        pagination={paginationConfig}
        sticky={isLoaded && HEADER_OFFSET}
        scroll={SCROLL}
        bordered
        rowKey={getRowKey}
        rowClassName={getRowClass}
      />
    </Wrapper>
  )
}

export default PaymentsUploadAttempts
