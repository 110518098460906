import React from 'react'
import PropTypes from 'prop-types'
import { RetailerName, Items, Wrapper } from './Changes.styles'
import Item from './Item'

const Changes = ({ data, changeFieldState, formName }) => (
  <Wrapper>
    <RetailerName>
      Retailer {data.name}. ID: {data.id}
    </RetailerName>
    <Items>
      {data.changes.map((item) => (
        <Item
          key={`${data.id}_${item.name}`}
          handleChange={changeFieldState}
          item={item}
          formName={formName}
        />
      ))}
    </Items>
  </Wrapper>
)

Changes.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    changes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.oneOfType([
          PropTypes.arrayOf(PropTypes.string),
          PropTypes.string,
        ]).isRequired,
      })
    ),
  }).isRequired,
  changeFieldState: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
}

export default Changes
