import { UserOutlined } from '@ant-design/icons'
import styled from 'styled-components'

export const UserIcon = styled(UserOutlined)`
  transform: translateY(36%);
  font-size: 20px;
  color: white;
  cursor: pointer;

  &:hover {
    color: #40a9ff;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`

export const Text = styled.span`
  ${({ $bold }) =>
    $bold
      ? `
      font-size: 20px;
      line-height: 20px;
      font-weight: 600;
      margin-right: 5px;
      color: black;
    `
      : `
      color: #737373;
    `}
`

export const Name = styled.div`
  align-items: center;
  display: flow-root;
`
