import React from 'react'
import { Layout } from 'antd'
import {
  getIsVerificationCodeValid,
  getVerificationCode,
  getInvalidMessage,
} from 'domains/user/selectors'
import { useSelector } from 'react-redux'

const SMSVerification = () => {
  const isValid = useSelector(getIsVerificationCodeValid)
  const verificationCode = useSelector(getVerificationCode)
  const invalidMessage = useSelector(getInvalidMessage)

  return (
    <Layout.Content>
      {verificationCode
        ? isValid
          ? `Verification Code: ${verificationCode}`
          : `Error, code: ${verificationCode}. ${invalidMessage}`
        : 'Loading verification code...'}
    </Layout.Content>
  )
}

export default SMSVerification
