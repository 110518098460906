import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import {
  JAVA_REDUCE_SORT_ORDER,
  MAP_JAVA_FILTER_TO_FILTER_NAME,
} from './filters'
import { JAVA_FIELD_NAMES } from '../const/forms'

export const mapFilters = (data = {}) => {
  const filters = omitBy(data, isEmpty)
  if (isEmpty(filters)) {
    return {}
  }

  return Object.entries(filters).reduce((result, [name, value]) => {
    const filterName = MAP_JAVA_FILTER_TO_FILTER_NAME[name]

    return {
      ...result,
      [filterName || name]: value.join(',') || '',
    }
  }, {})
}

export const mapSorters = (
  sorter,
  defaultFieldForSorting = JAVA_FIELD_NAMES.id,
  defaultOrder = 'asc'
) => {
  if (!sorter?.order) return `${defaultFieldForSorting} ${defaultOrder}`
  const { order, columnKey } = sorter
  return JAVA_REDUCE_SORT_ORDER[order]
    ? `${columnKey} ${JAVA_REDUCE_SORT_ORDER[order]}`
    : columnKey
}
