import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Row, Select, Spin } from 'antd'
import debounce from 'lodash/debounce'
import { DELAY } from 'const/delay'
import useAction from 'utils/useAction'
import {
  fetchRecords,
  fetchMoreRecords,
  resetRecords,
  setSearch,
} from 'domains/search/actions'
import {
  getRecords,
  getIsFetching,
  getPage,
  getPageCount,
  getSearch,
} from 'domains/search/selectors'
import Title from 'components/Title/Title'
import { StyledSelect, Id } from './SearchSelect.styles'
import { FIELD_NAMES } from 'const/forms'

const SearchSelect = ({
  input: { value, onChange },
  title,
  meta,
  mode,
  entity,
  callback,
  ...rest
}) => {
  // ! It's necessary to show placeholder if nothing selected
  value = value || null

  const records = useSelector(getRecords)(entity)
  const isFetching = useSelector(getIsFetching)(entity)
  const page = useSelector(getPage)(entity)
  const pageCount = useSelector(getPageCount)(entity)
  const search = useSelector(getSearch)(entity)

  const fetchRecordsFunc = useAction(fetchRecords)
  const fetchMoreRecordsFunc = useAction(fetchMoreRecords)
  const resetRecordsFunc = useAction(resetRecords)
  const setSearchFunc = useAction(setSearch)

  const onPopupScroll = ({ target }) => {
    if (
      !isFetching &&
      page < pageCount &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      fetchMoreRecordsFunc({ ...rest, entity })
    }
  }

  const handleChange = (value) => {
    onChange(value)
    if (callback) {
      const retailer = records?.find((item) => ~~item[FIELD_NAMES.id] === value)
      callback(retailer)
    }
  }

  const onSearch = useMemo(
    () =>
      debounce(
        (value) => setSearchFunc({ value, key: entity }),
        DELAY.onSearch
      ),
    [setSearchFunc, entity]
  )

  useEffect(() => {
    onSearch(value || '')
  }, [onSearch, value, entity])

  useEffect(() => {
    fetchRecordsFunc({ ...rest, entity })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRecordsFunc, search])

  useEffect(() => resetRecordsFunc, [resetRecordsFunc])

  const handleFocus = () => setSearchFunc({ value: '', key: entity })

  return (
    <Row>
      {title && <Title>{title}</Title>}
      <StyledSelect
        mode={mode}
        value={value}
        onFocus={handleFocus}
        placeholder="Search by name or id"
        filterOption={false}
        notFoundContent={isFetching ? <Spin size="small" /> : null}
        showSearch
        onChange={handleChange}
        onPopupScroll={onPopupScroll}
        onSearch={onSearch}
        invalid={meta.modified && meta.error}
      >
        {records.map((retailer) => {
          const name = retailer.attributes[FIELD_NAMES.name]
          const id = retailer[FIELD_NAMES.id]
          return (
            <Select.Option key={id} value={~~id}>
              <Row>
                <Id>{id}</Id> - {name}
              </Row>
            </Select.Option>
          )
        })}
      </StyledSelect>
    </Row>
  )
}

SearchSelect.propTypes = {
  callback: PropTypes.func,
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  title: PropTypes.string,
  mode: PropTypes.string,
  entity: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    modified: PropTypes.bool,
  }).isRequired,
}

SearchSelect.defaultProps = {
  mode: null,
  title: null,
  except: null,
  exceptCodes: null,
  types: null,
  callback: null,
}

export default SearchSelect
