import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from './api'
import { message } from 'antd'

function* fetchCreditCardsWorker({ payload: { website, sample } }) {
  try {
    yield put(actions.startSaving())
    const data = yield call(api.fetchCreditCardsByDomain, website, sample)

    if (data?.success) {
      yield put(
        actions.setCreditCards({
          creditCards: data.data,
          website,
        })
      )
    }
    yield put(actions.finishSaving())
  } catch (error) {
    console.error(error)
    yield put(actions.finishSaving())
  }
}

function* fetchAllCreditCardsWorker() {
  try {
    const data = yield call(api.fetchAllCreditCards)
    if (data?.success) {
      yield put(
        actions.setCreditCards({ creditCards: data.data, website: null })
      )
    }
    yield put(actions.finishSaving())
  } catch (error) {
    console.error(error)
    yield put(actions.finishSaving())
  }
}

function* validateCreditCardProgramWorker({ payload }) {
  try {
    const isValid = yield call(api.validateCreditCardProgram, payload)
    if (isValid) {
      yield put(actions.setCreditCardProcessed(payload.id))
      message.success('Successfully validated')
    } else {
      message.error('Invalid validation response')
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(actions.finishSaving())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchCreditCardsByDomain, fetchCreditCardsWorker),
    takeLatest(actions.fetchAllCreditCards, fetchAllCreditCardsWorker),
    takeLatest(
      actions.validateCreditCardProgram,
      validateCreditCardProgramWorker
    ),
  ])
}
