import { all, put, takeLatest } from 'redux-saga/effects'
import { actions as creditCardsComparisonActions } from 'domains/creditCardsComparison'
import * as actions from 'actions/views/creditCardsComparison'

function* fetchCreditCardsWorker({ payload }) {
  try {
    yield all([
      put(creditCardsComparisonActions.clearData()),
      put(creditCardsComparisonActions.startSaving()),
      put(creditCardsComparisonActions.fetchCreditCardsByDomain(payload.value)),
    ])
  } catch (error) {
    console.error(error)
  }
}

function* fetchAllCreditCardsWorker() {
  try {
    yield all([
      put(creditCardsComparisonActions.clearData()),
      put(creditCardsComparisonActions.startSaving()),
      put(creditCardsComparisonActions.fetchAllCreditCards()),
    ])
  } catch (error) {
    console.error(error)
  }
}

function* validateCreditCardProgramWorker({ payload }) {
  try {
    yield all([
      put(creditCardsComparisonActions.startSaving()),
      put(creditCardsComparisonActions.validateCreditCardProgram(payload)),
    ])
  } catch (error) {
    console.error(error)
  }
}

export default function* () {
  yield all([
    fetchAllCreditCardsWorker(),
    takeLatest(actions.fetchCreditCardsByDomain, fetchCreditCardsWorker),
    takeLatest(actions.fetchAllCreditCards, fetchAllCreditCardsWorker),
    takeLatest(
      actions.validateCreditCardProgram,
      validateCreditCardProgramWorker
    ),
  ])
}
