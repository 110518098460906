import { all, put, take, takeLatest, race, select } from 'redux-saga/effects'

import { startLoading, finishLoading } from 'reducers/layoutSlice'
import {
  actions as marketingPagesActions,
  formatters as marketingPagesFormatters,
  selectors as marketingPagesSelectors,
} from 'domains/marketingPages'
import * as actions from 'actions/views/marketingPages'

function* fetchMarketingPagesWorker({ payload } = {}) {
  try {
    const search = yield select(marketingPagesSelectors.getSearchString)
    const body = marketingPagesFormatters.formatPayloadForFetchRequest(payload)
    yield all([
      put(startLoading()),
      put(
        marketingPagesActions.fetchMarketingPagesRequest({ search, ...body })
      ),
    ])
    const [success] = yield race([
      take(marketingPagesActions.fetchMarketingPagesSuccess),
      take(marketingPagesActions.fetchMarketingPagesFailure),
    ])
    if (success) {
      yield put(marketingPagesActions.saveFiltersAndSorter(payload))
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* deleteMarketingPageWorker({ payload }) {
  try {
    yield all([
      put(startLoading()),
      put(marketingPagesActions.deleteMarketingPageRequest(payload)),
    ])
    const [success] = yield race([
      take(marketingPagesActions.deleteMarketingPageSuccess),
      take(marketingPagesActions.deleteMarketingPageFailure),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(marketingPagesSelectors.getSearchString),
        select(marketingPagesSelectors.getPage),
        select(marketingPagesSelectors.getFilters),
        select(marketingPagesSelectors.getSorter),
      ])
      yield put(
        actions.fetchMarketingPages({
          search,
          page,
          filters,
          sorter,
        })
      )
      yield race([
        take(marketingPagesActions.fetchMarketingPagesSuccess),
        take(marketingPagesActions.fetchMarketingPagesFailure),
      ])
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* createMarketingPageWorker({ payload }) {
  try {
    const body = marketingPagesFormatters.formatMarketingPageForRequests(
      payload.values,
      { isCreate: true }
    )
    yield all([
      put(marketingPagesActions.startSaving()),
      put(marketingPagesActions.createMarketingPageRequest(body)),
    ])
    const [success] = yield race([
      take(marketingPagesActions.createMarketingPageSuccess),
      take(marketingPagesActions.createMarketingPageFailure),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(marketingPagesSelectors.getSearchString),
        select(marketingPagesSelectors.getPage),
        select(marketingPagesSelectors.getFilters),
        select(marketingPagesSelectors.getSorter),
      ])
      yield put(
        actions.fetchMarketingPages({
          search,
          page,
          filters,
          sorter,
        })
      )
      yield race([
        take(marketingPagesActions.fetchMarketingPagesSuccess),
        take(marketingPagesActions.fetchMarketingPagesFailure),
      ])
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(marketingPagesActions.finishSaving())
  }
}

function* updateMarketingPageWorker({ payload }) {
  try {
    const body = marketingPagesFormatters.formatMarketingPageForRequests(
      payload.values
    )

    yield all([
      put(marketingPagesActions.startSaving()),
      put(marketingPagesActions.updateMarketingPageRequest({ data: body })),
    ])
    const [success] = yield all([
      race([
        take(marketingPagesActions.updateMarketingPageSuccess),
        take(marketingPagesActions.updateMarketingPageFailure),
      ]),
    ])

    if (success) {
      const [search, page, filters, sorter] = yield all([
        select(marketingPagesSelectors.getSearchString),
        select(marketingPagesSelectors.getPage),
        select(marketingPagesSelectors.getFilters),
        select(marketingPagesSelectors.getSorter),
      ])
      yield put(
        actions.fetchMarketingPages({
          search,
          page,
          filters,
          sorter,
        })
      )
      yield race([
        take(marketingPagesActions.fetchMarketingPagesSuccess),
        take(marketingPagesActions.fetchMarketingPagesFailure),
      ])
      payload.callback()
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(marketingPagesActions.finishSaving())
  }
}

export default function* () {
  yield all([
    fetchMarketingPagesWorker(),
    takeLatest(actions.fetchMarketingPages, fetchMarketingPagesWorker),
    takeLatest(actions.createMarketingPage, createMarketingPageWorker),
    takeLatest(actions.updateMarketingPage, updateMarketingPageWorker),
    takeLatest(actions.deleteMarketingPage, deleteMarketingPageWorker),
  ])
}
