import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from './api'
import { message } from 'antd'

function* markExtensionPassedReviewWorker({ payload: { version } }) {
  try {
    yield put(actions.startSaving())
    const data = yield call(api.markExtensionPassedReview, version)

    if (data.status === 200) {
      message.success(data.data.message)
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(actions.finishSaving())
  }
}

export default function* () {
  yield all([
    takeLatest(
      actions.markExtensionPassedReview,
      markExtensionPassedReviewWorker
    ),
  ])
}
