import { apiInstance } from 'utils/request'
import { ENTITY_NAMES } from 'const/api'
import parseError from 'helpers/parseServerError'

export const getBonuses = (payload) =>
  apiInstance
    .get(`/${ENTITY_NAMES.bonuses}`, {
      params: {
        page: {
          number: payload?.page,
          size: 20,
        },
        filter: {
          active: payload?.filters?.active?.[0],
        },
      },
    })
    .catch((error) => {
      parseError(error, 'Cannot get shops')
    })

export const deleteBonus = (id) =>
  apiInstance
    .delete(`/${ENTITY_NAMES.bonuses}/${id}`)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Bonus hasn't been deleted")
    })

export const updateBonus = ({ data }) =>
  apiInstance
    .patch(`/${ENTITY_NAMES.bonuses}/${data.id}`, {
      data,
    })
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Bonus hasn't been edited")
    })

export const createBonus = ({ data }) =>
  apiInstance
    .post(`/${ENTITY_NAMES.bonuses}`, { data })
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Bonus hasn't been created")
    })
