import React from 'react'
import identity from 'lodash/identity'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { FormSpy } from 'react-final-form'
import { updateState } from 'reducers/forms/actions'
import useAction from 'utils/useAction'
import useFormInitialize from 'utils/useFormInitialize'

const FormStateToRedux = ({
  form,
  formatter,
  onResetForm,
  onChangeFieldState,
  isMultipleSaveActive,
}) => {
  const updateStateFunc = useAction(updateState)
  const handleChange = (formState) =>
    setTimeout(() =>
      updateStateFunc({
        form,
        formState: formatter(formState),
        onResetForm,
        onChangeFieldState,
      })
    )
  useFormInitialize(isMultipleSaveActive)

  return (
    <FormSpy
      subscription={{ dirtyFields: true, initialValues: true, values: true }}
      onChange={handleChange}
    />
  )
}

FormStateToRedux.propTypes = {
  form: PropTypes.string.isRequired,
  formatter: PropTypes.func,
  onResetForm: PropTypes.func,
  onChangeFieldState: PropTypes.func,
  isMultipleSaveActive: PropTypes.bool,
}

FormStateToRedux.defaultProps = {
  isMultipleSaveActive: false,
  formatter: identity,
  onResetForm: noop,
  onChangeFieldState: noop,
}

export default FormStateToRedux
