import styled from 'styled-components'

export const Text = styled.span`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }) => lines ?? 1};
  -webkit-box-orient: vertical;
`
