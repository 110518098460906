import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ImgCrop from 'components/ImageCropper'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  EmptyUpload,
  Text,
  SizeText,
  Upload,
  ResetButton,
  Wrapper,
  SizeTextSmall,
} from './ImagePicker.styles'
import {
  checkFileBeforeUpload,
  checkFileBeforeCrop,
  getBase64,
} from 'helpers/files'
import { useForm } from 'react-final-form'
import { Tooltip } from 'antd'

const ImagePicker = ({
  input,
  meta,
  hasCropper,
  cropSize,
  forceInvalid,
  changeCallback,
  disabled,
}) => {
  const { change } = useForm()
  const [loading, setLoading] = useState(false)
  const [fileList, setFileList] = useState([{ url: input.value, uid: 1 }])

  useEffect(() => {
    if (input.value) {
      setFileList([{ url: input.value, uid: 1 }])
    }
  }, [input.value])

  const handleChange = ({ file, fileList: newFileList }) => {
    if (file.status === 'uploading') {
      setLoading(true)
      setFileList([...newFileList])
    }
    if (file.status === 'done') {
      getBase64(file.originFileObj, (imageUrl) => {
        input.onChange(imageUrl)
        if (changeCallback) {
          changeCallback(imageUrl)
        }
        setLoading(false)
      })
    }
  }

  const handleRemove = () => {
    setFileList([])
    input.onChange(null)
  }

  const Size = cropSize ? (
    <SizeText>
      ({cropSize.width}x{cropSize.height})
    </SizeText>
  ) : null

  const resetChanges = () => change(input.name, meta.initial)

  const UploadButton = (
    <EmptyUpload>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <Text>Upload</Text>
      {Size}
    </EmptyUpload>
  )

  const UploadComponent = (
    <Upload
      disabled={disabled}
      invalid={(forceInvalid || meta.touched || meta.modified) && meta.error}
      accept={['image/jpeg', 'image/png']}
      showUploadList={
        input.value
          ? {
              showRemoveIcon: true,
              showPreviewIcon: false,
            }
          : false
      }
      defaultFileList={[input.value]}
      customRequest={({ onSuccess }) => {
        Promise.resolve().then(() => onSuccess())
      }}
      beforeUpload={checkFileBeforeUpload()}
      onChange={handleChange}
      onRemove={handleRemove}
      fileList={fileList}
      listType="picture-card"
      maxCount={1}
    >
      {!input.value && UploadButton}
    </Upload>
  )

  return (
    <Wrapper>
      {hasCropper ? (
        <ImgCrop
          grid
          modalWidth="70%"
          beforeCrop={checkFileBeforeCrop({ imageSize: cropSize })}
          aspect={cropSize.width / cropSize.height}
          initialCroppedAreaPixels={cropSize}
        >
          {UploadComponent}
        </ImgCrop>
      ) : (
        UploadComponent
      )}
      {!meta.pristine && !disabled && (
        <Tooltip title="Click to reset changes">
          <ResetButton onClick={resetChanges} />
        </Tooltip>
      )}
      {hasCropper && input.value && (
        <SizeTextSmall>{`${cropSize.width}x${cropSize.height}`}</SizeTextSmall>
      )}
    </Wrapper>
  )
}

ImagePicker.propTypes = {
  hasCropper: PropTypes.bool,
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: PropTypes.bool,
  changeCallback: PropTypes.func,
  // eslint-disable-next-line react/boolean-prop-naming
  forceInvalid: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    modified: PropTypes.bool,
    pristine: PropTypes.bool,
    touched: PropTypes.bool,
    initial: PropTypes.string,
  }).isRequired,
  cropSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
}

ImagePicker.defaultProps = {
  changeCallback: null,
  hasCropper: false,
  disabled: false,
  forceInvalid: false,
  cropSize: null,
}

export default ImagePicker
