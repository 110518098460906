import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from './api'
import { FIELD_NAMES } from 'const/forms'
import { message } from 'antd'

function* fetchFeaturesWorker() {
  try {
    const { data } = yield call(api.getFeatures)

    yield put(actions.fetchFeaturesSuccess(data))
  } catch (e) {
    yield put(actions.fetchFeaturesFailure(e))
  }
}

function* fetchFeaturesByPlatformWorker({ payload }) {
  try {
    const { data } = yield call(api.getFeaturesByPlatform, payload)

    yield put(actions.fetchFeaturesByPlatformSuccess(data))
  } catch (e) {
    yield put(actions.fetchFeaturesByPlatformsFailure(e))
  }
}

function* updateFeatureWorker({ payload, promise }) {
  try {
    const {
      feature: { feature_configuration_options: _, ...feature },
      action,
      userId,
    } = payload

    const body = {
      ...feature,
      [FIELD_NAMES.updatedBy]: userId,
      feature_configurations: feature.feature_configurations?.map((item) => ({
        ...item,
        groups: item.groups || '',
        user_attributes: item.user_attributes || {},
      })),
    }

    const request = action === 'create' ? api.createFeature : api.updateFeature

    const { data } = yield call(request, body)
    yield put(actions.updateFeatureSuccess({ feature: data, action }))
    yield call(promise.resolve)
  } catch (error) {
    console.error(error)
    yield call(promise.resolve, error?.response?.data)
  }
}

function* deleteFeatureWorker({ payload }) {
  try {
    yield call(api.deleteFeature, payload)

    yield put(actions.deleteFeatureSuccess(payload))
    message.success('Feature deleted')
  } catch (e) {
    yield put(actions.deleteFeatureSuccess(e))
  }
}

function* createFeatureConfigurationOptionWorker({
  payload: { options, featureId },
  promise,
}) {
  try {
    const { data } = yield call(api.createFeatureConfigurationOption, {
      ...options.feature_configuration_option,
      feature_id: featureId,
    })

    if (data.errors) {
      message.error(data.errors)
      yield call(promise.resolve, data.errors)
    } else {
      yield put(
        actions.createFeatureConfigurationOptionSuccess({ data, featureId })
      )
      yield call(promise.resolve)
    }
  } catch (error) {
    yield put(actions.createFeatureConfigurationOptionError(error))
  }
}

function* deleteFeatureConfigurationOptionWorker({
  payload: { id, featureId },
  promise,
}) {
  try {
    yield call(api.deleteFeatureConfigurationOption, id)
    yield put(
      actions.deleteFeatureConfigurationOptionSuccess({ id, featureId })
    )

    yield call(promise.resolve)
  } catch (error) {
    yield put(actions.deleteFeatureConfigurationOptionError(error))
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchFeaturesRequest, fetchFeaturesWorker),
    takeLatest(
      actions.fetchFeaturesByPlatformRequest,
      fetchFeaturesByPlatformWorker
    ),
    takeLatest(actions.updateFeatureRequest, updateFeatureWorker),
    takeLatest(actions.deleteFeatureRequest, deleteFeatureWorker),
    takeLatest(
      actions.createFeatureConfigurationOptionRequest,
      createFeatureConfigurationOptionWorker
    ),
    takeLatest(
      actions.deleteFeatureConfigurationOptionRequest,
      deleteFeatureConfigurationOptionWorker
    ),
  ])
}
