function* colors() {
  while (true) {
    yield 'even'
    yield 'odd'
  }
}

function style(colors) {
  let current_id = 0
  let current_color = colors.next().value
  return function (id) {
    if (current_id !== id) {
      current_id = id
      current_color = colors.next().value
    }
    return current_color
  }
}

export const getStyle = style(colors())
