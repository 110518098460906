import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { StyledButton, Text } from './SyncModal.styles'
import { retailerPropType } from 'const/propTypes'
import { FIELD_NAMES } from 'const/forms'
import { syncImages } from 'actions/views/retailers'
import useAction from 'utils/useAction'

const SyncModal = ({ retailer, closeModal, show }) => {
  const syncImagesFunc = useAction(syncImages)
  const handleConfirm = () => {
    syncImagesFunc(retailer)
    closeModal()
  }

  return (
    <Modal
      title="Image sets synchronization"
      open={show}
      centered
      onCancel={closeModal}
      footer={[
        <StyledButton key="empty" onClick={closeModal}>
          Cancel
        </StyledButton>,
        <StyledButton key="all" onClick={handleConfirm} type="primary">
          Continue
        </StyledButton>,
      ]}
    >
      <Text>
        Are you sure you want to sync all images between{' '}
        {retailer[FIELD_NAMES.name]} and its regional retailers?
      </Text>
      <Text>
        All regional sets will be replaced.
        <br />
        This action can't be revoked.
      </Text>
    </Modal>
  )
}

SyncModal.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  show: PropTypes.bool.isRequired,
  retailer: retailerPropType.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default SyncModal
