import CheckboxIcon from 'components/CheckboxIcon'
import CopyText from 'components/CopyText'
import { BONUSES_STATES_FILTERS } from 'const/bonuses'
import { DATE_TIME_FORMAT } from 'const/dates'
import { FIELD_NAMES } from 'const/forms'
import moment from 'moment'
import React from 'react'
import Countdown from 'react-countdown'
import { Actions, StyledButton } from './Bonuses.styles'

export const bonusesColumns = (filters, handleEditBonus) => [
  {
    title: 'ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
  },
  {
    title: 'Slug',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.slug],
    key: FIELD_NAMES.slug,
  },
  {
    title: 'Wallet copy',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.walletCopy],
    key: FIELD_NAMES.walletCopy,
  },
  {
    title: 'Value',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.value],
    key: FIELD_NAMES.value,
    align: 'center',
  },
  {
    title: 'Active',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.active],
    render: (active) => <CheckboxIcon checked={active} />,
    filters: BONUSES_STATES_FILTERS,
    filterMultiple: false,
    filteredValue: filters[FIELD_NAMES.active] || null,
    align: 'center',
    key: FIELD_NAMES.active,
  },
  {
    title: 'Eligible times',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.eligibleTimes],
    key: FIELD_NAMES.eligibleTimes,
    align: 'center',
    render: (eligibleTimes) => (eligibleTimes > 0 ? eligibleTimes : 'INF'),
  },
  {
    title: 'Allowed For Guest',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.allowedForGuest],
    key: FIELD_NAMES.allowedForGuest,
    align: 'center',
    render: (allowedForGuest) => <CheckboxIcon checked={allowedForGuest} />,
  },
  {
    title: 'Auto Deactivate',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.autoDeactivate],
    key: FIELD_NAMES.autoDeactivate,
    align: 'center',
    render: (autoDeactivate) => <CheckboxIcon checked={autoDeactivate} />,
  },
  {
    title: 'Bonus TTL',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.bonusTTL],
    key: FIELD_NAMES.bonusTTL,
    align: 'center',
    render: (bonusTTL) => <span>{bonusTTL ? bonusTTL + ' Days' : '-'} </span>,
  },
  {
    align: 'center',
    title: 'Braze Link',
    width: 150,
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.brazeLink],
    key: FIELD_NAMES.brazeLink,
    render: (value) => <CopyText value={value} noText />,
  },
  {
    title: 'Created At',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.createdAt],
    key: FIELD_NAMES.createdAt,
    width: 150,
    align: 'center',
    fixed: 'right',
    render: (date) => (date ? moment(date).format(DATE_TIME_FORMAT) : '-'),
  },
  {
    title: 'Deactivation In',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.deactivationAt],
    key: FIELD_NAMES.deactivationAt,
    width: 150,
    align: 'center',
    fixed: 'right',
    render: (date) => (date ? <Countdown date={date} /> : '-'),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    fixed: 'right',
    width: 120,
    render: (_, bonus) => {
      const onEdit = () => handleEditBonus(bonus[FIELD_NAMES.id])
      return (
        <Actions>
          <StyledButton onClick={onEdit}>Edit</StyledButton>
        </Actions>
      )
    },
  },
]
