import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ noText }) => noText && 'justify-content: center'};
`

export const Text = styled.span`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }) => lines ?? 1};
  -webkit-box-orient: vertical;
`

export const CopyButton = styled.div`
  display: inline-block;
  cursor: pointer;
  padding: 5px;
`
