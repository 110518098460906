import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isSavingActive: false,
}

const extensionVersionsSlice = createSlice({
  name: 'extensionVersions',
  initialState,
  reducers: {
    startSaving: (state) => ({
      ...state,
      isSavingActive: true,
    }),
    finishSaving: (state) => ({
      ...state,
      isSavingActive: false,
    }),
  },
})

export default extensionVersionsSlice
