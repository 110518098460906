import React from 'react'
import { useSelector } from 'react-redux'
import {
  getFilters,
  getIsDataFilteredOrSorted,
  getIsLoaded,
  getPage,
  getPageCount,
  getSearchString,
  getSorter,
  getSortedAppVersions,
} from 'domains/mobileApp/selectors'
import useAction from 'utils/useAction'
import {
  deleteAppVersion,
  fetchAppVersions,
} from 'actions/views/mobileVersions'
import { StyledTable, Wrapper, Buttons } from './MobileAppVersions.styles'
import { Button } from 'antd'
import getColumns from './columns'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import { FIELD_NAMES } from 'const/forms'
import {
  openConfirmModal,
  openMobileAppVersionModal,
} from 'reducers/modals/actions'
import isEqual from 'lodash/isEqual'

const PAGE_SIZE = 20
const SCROLL = { x: 900 }
export const HEADER_OFFSET = {
  offsetHeader: 49,
}

const MobileAppVersions = () => {
  const isLoaded = useSelector(getIsLoaded)
  const page = useSelector(getPage)
  const search = useSelector(getSearchString)
  const pageCount = useSelector(getPageCount)
  const filters = useSelector(getFilters)
  const sorter = useSelector(getSorter)
  const dataSource = useSelector(getSortedAppVersions)
  const isDataFilteredOrSorted = useSelector(getIsDataFilteredOrSorted)

  const fetchMobileAppVersionsFunc = useAction(fetchAppVersions)
  const deleteMobileAppVersionFunc = useAction(deleteAppVersion)
  const openConfirmModalFunc = useAction(openConfirmModal)
  const openMobileAppVersionModalFunc = useAction(openMobileAppVersionModal)

  const handleEditMobileAppVersion = (id) =>
    openMobileAppVersionModalFunc({ id })
  const handleCreateMobileAppVersion = () => openMobileAppVersionModalFunc()
  const handleDeleteMobileAppVersion = (MobileAppVersion) => {
    const callback = () =>
      deleteMobileAppVersionFunc(MobileAppVersion[FIELD_NAMES.id])
    const text =
      `Are you sure you want to delete ` +
      `version ${MobileAppVersion[FIELD_NAMES.version]}` +
      `(${MobileAppVersion[FIELD_NAMES.platform]}, ID: ${
        MobileAppVersion[FIELD_NAMES.id]
      })? ` +
      "This action can't be revoked."
    openConfirmModalFunc({ callback, text })
  }

  const onTableChange = (pagination, newFilters, newSorter) => {
    const pageChanged = page !== pagination.current
    const newFiltersOrSorter =
      !pageChanged &&
      (!isEqual(newFilters, filters) || !isEqual(newSorter, sorter))
    fetchMobileAppVersionsFunc({
      page: newFiltersOrSorter ? 1 : pagination.current,
      search,
      filters: newFilters,
      sorter: newSorter,
    })
  }

  const handleResetFilters = () =>
    fetchMobileAppVersionsFunc({
      search,
      page: 1,
      filters: {},
      sorter: {},
    })

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  return (
    <Wrapper>
      <Buttons>
        <Button
          onClick={handleCreateMobileAppVersion}
          type="primary"
          disabled={!isLoaded}
        >
          Create
        </Button>
        <Button
          onClick={handleResetFilters}
          disabled={!isLoaded || !isDataFilteredOrSorted}
        >
          Reset filters
        </Button>
      </Buttons>
      <StyledTable
        onChange={onTableChange}
        showSorterTooltip={false}
        columns={getColumns(
          filters,
          sorter,
          handleEditMobileAppVersion,
          handleDeleteMobileAppVersion
        )}
        dataSource={dataSource}
        expandable={false}
        pagination={paginationConfig}
        sticky={isLoaded && HEADER_OFFSET}
        scroll={SCROLL}
        bordered
        loading={false}
        rowKey={getRowKey}
        rowClassName={getRegularRowClass}
      />
    </Wrapper>
  )
}

export default MobileAppVersions
