import React from 'react'
import { useSelector } from 'react-redux'
import {
  getIsLoaded,
  getPage,
  getPageCount,
  getSortedMarketingPages,
  getSearchString,
  getFilters,
  getSorter,
  getIsDataFilteredOrSorted,
} from 'domains/marketingPages/selectors'
import {
  fetchMarketingPages,
  deleteMarketingPage,
} from 'actions/views/marketingPages'
import useAction from 'utils/useAction'
import { Buttons, StyledTable, Wrapper } from './MarketingPages.styles'
import getColumns from './columns'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import {
  openConfirmModal,
  openMarketingPageModal,
} from 'reducers/modals/actions'
import { HEADER_OFFSET, PAGE_SIZE, SCROLL } from 'const/tables'
import { Button } from 'antd'
import SearchInTableBar from 'components/SearchInTableBar'
import { FIELD_NAMES } from 'const/forms'
import { isEqual } from 'lodash'

const MarketingPages = () => {
  const isLoaded = useSelector(getIsLoaded)
  const page = useSelector(getPage)
  const search = useSelector(getSearchString)
  const pageCount = useSelector(getPageCount)
  const filters = useSelector(getFilters)
  const sorter = useSelector(getSorter)
  const dataSource = useSelector(getSortedMarketingPages)
  const isDataFilteredOrSorted = useSelector(getIsDataFilteredOrSorted)

  const openConfirmModalFunc = useAction(openConfirmModal)
  const openMarketingPageModalFunc = useAction(openMarketingPageModal)

  const fetchMarketingPagesFunc = useAction(fetchMarketingPages)
  const deleteMarketingPageFunc = useAction(deleteMarketingPage)

  const handleEditProduct = (id) => openMarketingPageModalFunc({ id })
  const handleCreateProduct = () => openMarketingPageModalFunc()
  const handleDeleteProduct = (product) => {
    const callback = () => deleteMarketingPageFunc(product[FIELD_NAMES.id])
    const text =
      `Are you sure you want to delete product ${
        product[FIELD_NAMES.name]
      } (ID: ${product[FIELD_NAMES.id]})? ` + "This action can't be revoked."
    openConfirmModalFunc({ callback, text })
  }

  const onSearchStringChange = (search) =>
    fetchMarketingPagesFunc({
      page: 1,
      search,
      filters,
      sorter,
    })

  const onTableChange = (pagination, newFilters, newSorter) => {
    const pageChanged = page !== pagination.current
    const newFiltersOrSorter =
      !pageChanged &&
      (!isEqual(newFilters, filters) || !isEqual(newSorter, sorter))
    fetchMarketingPagesFunc({
      page: newFiltersOrSorter ? 1 : pagination.current,
      search,
      filters: newFilters,
      sorter: newSorter,
    })
  }

  const handleResetFilters = () =>
    fetchMarketingPagesFunc({
      search,
      page: 1,
      filters: {},
      sorter: {},
    })

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  return (
    <Wrapper>
      <SearchInTableBar
        placeholder="Search marketing page"
        onSubmit={onSearchStringChange}
        value={search}
      >
        <Buttons>
          <Button
            onClick={handleCreateProduct}
            type="primary"
            disabled={!isLoaded}
          >
            Create
          </Button>
          <Button
            onClick={handleResetFilters}
            disabled={!isLoaded || !isDataFilteredOrSorted}
          >
            Reset filters
          </Button>
        </Buttons>
      </SearchInTableBar>
      <StyledTable
        onChange={onTableChange}
        showSorterTooltip={false}
        columns={getColumns(
          filters,
          sorter,
          handleEditProduct,
          handleDeleteProduct
        )}
        dataSource={dataSource}
        expandable={false}
        pagination={paginationConfig}
        sticky={isLoaded && HEADER_OFFSET}
        scroll={SCROLL}
        bordered
        loading={false}
        rowKey={getRowKey}
        rowClassName={getRegularRowClass}
      />
    </Wrapper>
  )
}

export default MarketingPages
