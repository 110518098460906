import styled from 'styled-components'
import { Button, Select, Tag } from 'antd'

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const StyledButton = styled(Button)`
  align-self: flex-end;
`

export const StyledSelect = styled(Select)`
  width: 100%;
`

export const SelectedCategoriesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
`

export const CategoryGroup = styled.div``

export const MainCategoryTag = styled(Tag)`
  font-weight: bold;
  background-color: #e6f7ff;
  border-color: #91d5ff;
  color: #0050b3;
  margin: 0;
  margin-bottom: 8px;
`

export const SubCategoryList = styled.ul`
  margin: 0;
  padding-left: 16px;
`

export const SubCategoryItem = styled.li`
  color: #595959;
`
