import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import { paymentTransactionsColumns } from './columns'
import { StyledTable, Wrapper, Header } from './PaymentTransactions.styles'
import { useSelector } from 'react-redux'
import {
  getPage,
  getPageCount,
  getSorter,
  getPaymentTransactions,
  getFilters,
  getNetworks,
  getSearch,
  getIsLoaded,
  getIsSavingActive,
  getSelect,
  getIsDataFilteredOrSorted,
} from 'domains/paymentTransactions/selectors'
import { fetchPaymentTransactions } from 'actions/views/paymentTransactions'
import useAction from 'utils/useAction'
import { isEqual } from 'lodash'
import { PAGE_SIZE } from 'const/tables'
import { Button, Input, Select, Spin, Typography } from 'antd'
import { useHistory } from 'react-router'
import ROUTES from 'const/routes'
import {
  COMMISSION_TYPES,
  SUPPORTED_COMMISSION_TYPES_VALUES,
} from 'const/transactions'
import {
  openPaymentPackageModal,
  openTransactionDetailsModal,
} from 'reducers/modals/actions'
import { JAVA_FIELD_NAMES } from 'const/forms'

const { Text } = Typography
const Option = Select.Option
const { Search } = Input

const PaymentTransactions = () => {
  const [searchValue, setSearchValue] = useState(null)
  const [selectValue, setSelectValue] = useState(null)
  const fetchPaymentsTransactionsFunc = useAction(fetchPaymentTransactions)
  const openTransactionDetailsModalFunc = useAction(openTransactionDetailsModal)
  const openAdPackageModalFunc = useAction(openPaymentPackageModal)
  const history = useHistory()
  const page = useSelector(getPage)
  const pageCount = useSelector(getPageCount)
  const sorter = useSelector(getSorter)
  const filters = useSelector(getFilters)
  const search = useSelector(getSearch)
  const select = useSelector(getSelect)
  const isLoaded = useSelector(getIsLoaded)
  const isSavingActive = useSelector(getIsSavingActive)
  const networks = useSelector(getNetworks)
  const dataSource = useSelector(getPaymentTransactions)
  const isDataFilteredOrSorted = useSelector(getIsDataFilteredOrSorted)

  const mappedNetworks = networks?.map((network, index) => ({
    text: network,
    value: index,
  }))

  const onTableChange = (pagination, newFilters, newSorter) => {
    const pageChanged = page !== pagination.current
    const newFiltersOrSorter =
      !pageChanged &&
      (!isEqual(newFilters, filters) || !isEqual(newSorter, sorter))

    fetchPaymentsTransactionsFunc({
      page: newFiltersOrSorter ? 1 : pagination.current,
      sorter: newFiltersOrSorter ? newSorter : sorter,
      filters: newFiltersOrSorter ? newFilters : filters,
      size: newFiltersOrSorter ? 20 : pagination.pageSize,
      search,
      select,
    })
  }

  const handleSelect = (value) => {
    fetchPaymentsTransactionsFunc({
      page: 1,
      sorter,
      filters,
      select: value,
      search,
    })
  }

  const handleSearch = (value) => {
    fetchPaymentsTransactionsFunc({
      page: 1,
      sorter,
      filters,
      search: value,
      select,
    })
  }

  const handleRowCLick = (record) => {
    const currentTransaction = dataSource.find(
      (transaction) => transaction.id === record.id
    )
    if (
      SUPPORTED_COMMISSION_TYPES_VALUES.includes(
        currentTransaction?.[JAVA_FIELD_NAMES.commissionType]
      )
    )
      openAdPackageModalFunc({ currentTransaction })
    else openTransactionDetailsModalFunc({ id: record.id })
  }

  const handleResetFilters = () => {
    setSelectValue(null)
    setSearchValue(null)
    fetchPaymentsTransactionsFunc({
      page: 1,
      sorter: {},
      filters: {},
      size: PAGE_SIZE,
      search: '',
      select: '',
    })
  }

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  useEffect(() => {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    if (params?.date) {
      sessionStorage.setItem('paymentDate', params.date)
    }
  }, [])

  return (
    <Wrapper>
      <Header>
        <span>
          <div>
            <Text style={{ paddingInline: '10px' }}>
              Payment date: {sessionStorage.getItem('paymentDate')}
            </Text>
            <Button
              type="link"
              onClick={() => history.replace(ROUTES.TRANSACTIONS_BY_DATE)}
            >
              Back
            </Button>
          </div>
          <Select
            placeholder="Commission type"
            onSelect={handleSelect}
            style={{
              marginInline: '8px',
              marginBottom: '10px',
              width: '155px',
            }}
            value={selectValue}
            onChange={(value) => setSelectValue(value)}
          >
            {COMMISSION_TYPES.map(({ text, value }) => {
              return (
                <Option key={value} value={value}>
                  {text}
                </Option>
              )
            })}
          </Select>
          <Search
            placeholder="Rule ID"
            onSearch={handleSearch}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
            style={{ width: '155px' }}
          />
          <Button
            onClick={handleResetFilters}
            disabled={!isDataFilteredOrSorted}
            style={{ marginLeft: '8px' }}
          >
            Reset filters
          </Button>
        </span>
        <div>
          <Button
            type="primary"
            style={{ marginLeft: '8px' }}
            onClick={openAdPackageModalFunc}
            disabled={!isLoaded}
          >
            Add commission
          </Button>
        </div>
      </Header>

      <Spin spinning={!isLoaded || isSavingActive}>
        <StyledTable
          onChange={onTableChange}
          bordered
          columns={paymentTransactionsColumns(filters, sorter, mappedNetworks)}
          dataSource={dataSource?.length ? dataSource : []}
          expandable={false}
          loading={false}
          rowClassName={getRegularRowClass}
          rowKey={getRowKey}
          showSorterTooltip={false}
          pagination={paginationConfig}
          onRow={(record) => {
            return {
              onClick: () => {
                handleRowCLick({ id: record.id })
              },
            }
          }}
        />
      </Spin>
    </Wrapper>
  )
}

export default PaymentTransactions
