import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sortedIds: [],
  entities: {},
  filters: {},
  sorter: {},
  isLoaded: false,
  isSavingActive: false,
  search: '',
  page: 1,
  pageCount: 1,
}

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    fetchProductsSuccess: (state, { payload }) => ({
      ...state,
      isLoaded: true,
      page: payload.page,
      pageCount: payload.pageCount,
      entities: payload.products,
      sortedIds: payload.sortedIds,
    }),
    fetchProductsFailure: (state) => state,
    resetFiltersAndSorter: (state) => ({
      ...state,
      page: initialState.page,
      filters: initialState.filters,
      sorter: initialState.sorter,
    }),
    saveFiltersAndSorter: (state, { payload = {} }) => ({
      ...state,
      search: payload.search,
      filters: payload.filters || initialState.filters,
      sorter: payload.sorter || initialState.sorter,
    }),
    startSaving: (state) => ({
      ...state,
      isSavingActive: true,
    }),
    finishSaving: (state) => ({
      ...state,
      isSavingActive: false,
    }),
    clearData: () => initialState,
  },
})

export default productsSlice
