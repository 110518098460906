import { all, call, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from './api'

function* sendEmailNotificationWorker({ payload }) {
  try {
    yield call(api.sendEmailNotification, payload)
  } catch (error) {
    console.error(error)
  }
}

export default function* () {
  yield all([
    takeLatest(actions.sendEmailNotification, sendEmailNotificationWorker),
  ])
}
