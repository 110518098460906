import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Select as AntSelect } from 'antd'
import countries from 'const/country'
import Title from 'components/Title/Title'
import { StyledSelect } from './RegionSelect.styles'

const Select = ({ input: { value, onChange }, excludedRegions }) => {
  const options = countries.filter(
    (country) => !excludedRegions.includes(country.code)
  )
  // ! It's necessary to show placeholder if nothing selected
  value = value || null

  const filterOption = (search, option) => {
    search = search.toLowerCase()
    return (
      option.value.toLowerCase().includes(search) ||
      option.name.toLowerCase().includes(search)
    )
  }

  const isSelectorRegionalAvailable = !excludedRegions.includes(value)

  useEffect(() => {
    if (!isSelectorRegionalAvailable) {
      onChange(null)
    }
  }, [isSelectorRegionalAvailable, onChange])

  return (
    <>
      <Title>Country region</Title>
      <StyledSelect
        value={value}
        filterOption={filterOption}
        placeholder="Search by name"
        showSearch
        onChange={onChange}
      >
        {options.map((country) => {
          return (
            <AntSelect.Option
              key={country.code}
              value={country.code}
              name={country.name}
            >
              <Row>
                <Col span={3}>{country.code}</Col>
                <Col span={21}>- {country.name}</Col>
              </Row>
            </AntSelect.Option>
          )
        })}
      </StyledSelect>
    </>
  )
}

Select.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  excludedRegions: PropTypes.arrayOf(PropTypes.string),
}

Select.defaultProps = {
  excludedRegions: [],
}

export default Select
