import React from 'react'
import Input from 'components/styled/InputWithInvalidState'
import {
  getRequiredFieldError,
  INVALID_LINK,
  INVALID_PERCENTAGE,
  INVALID_PERCENTAGE_SUM,
} from 'const/errors'
import { FIELD_NAMES } from '../../const/forms'
import { Wrapper, Fields, RemoveIcon } from './MarkettingPageUrl.styles'
import { PercentageOutlined } from '@ant-design/icons'
import FormItem from '../FormItem/FormItem'
import { Divider } from 'antd'

const isUrlRequired = getRequiredFieldError([FIELD_NAMES.url])

const FieldMarketingPageURL = ({ input, removeHandler, meta }) => {
  const changeHandler = (key, event, isNumber = false) =>
    input.onChange({
      ...input.value,
      [key]: isNumber ? ~~event.target.value : event.target.value,
    })

  return (
    <>
      <Wrapper>
        <Fields>
          <FormItem label="URL">
            <Input
              invalid={[INVALID_LINK, isUrlRequired].includes(meta.error)}
              value={input.value[FIELD_NAMES.url]}
              placeholder="URL"
              onChange={(event) => changeHandler(FIELD_NAMES.url, event)}
            />
          </FormItem>
          <FormItem label="Traffic percentages">
            <Input
              type="number"
              invalid={[INVALID_PERCENTAGE, INVALID_PERCENTAGE_SUM].includes(
                meta.error
              )}
              value={input.value[FIELD_NAMES.trafficPercentage]}
              placeholder="Traffic percentage"
              onChange={(event) =>
                changeHandler(FIELD_NAMES.trafficPercentage, event, true)
              }
              addonAfter={<PercentageOutlined />}
            />
          </FormItem>
        </Fields>

        <RemoveIcon onClick={removeHandler} />
      </Wrapper>
      <Divider />
    </>
  )
}

export default FieldMarketingPageURL
