import { FIELD_NAMES } from 'const/forms'
import { ENTITY_NAMES } from 'const/api'
import { mapFilters, mapSorters } from 'helpers/jsonAPI'
import { regularFormatDate } from 'helpers/dates'

export const formatAppVersion = (appVersion) => {
  return {
    [FIELD_NAMES.id]: appVersion[FIELD_NAMES.id],
    key: appVersion[FIELD_NAMES.id],
    [FIELD_NAMES.platform]: appVersion.attributes[FIELD_NAMES.platform],
    [FIELD_NAMES.version]: appVersion.attributes[FIELD_NAMES.version],
    [FIELD_NAMES.updateRequired]:
      appVersion.attributes[FIELD_NAMES.updateRequired],
  }
}

export const formatAppVersions = (appVersions = {}) =>
  Object.values(appVersions).reduce(
    (result, appVersion) => ({
      ...result,
      [appVersion[FIELD_NAMES.id]]: formatAppVersion(appVersion),
    }),
    {}
  ) || {}

export const formatNotification = (notification) => {
  return {
    [FIELD_NAMES.id]: notification[FIELD_NAMES.id],
    key: notification[FIELD_NAMES.id],
    [FIELD_NAMES.deviceToken]: notification[FIELD_NAMES.deviceToken],
    [FIELD_NAMES.type]: notification[FIELD_NAMES.type],
    [FIELD_NAMES.deliveredAt]: notification[FIELD_NAMES.deliveredAt],
    [FIELD_NAMES.userEmail]: notification[FIELD_NAMES.userEmail],
    [FIELD_NAMES.userId]: notification[FIELD_NAMES.userId],
    [FIELD_NAMES.createdAt]: regularFormatDate(
      notification[FIELD_NAMES.createdAt]
    ),
    [FIELD_NAMES.updatedAt]: regularFormatDate(
      notification[FIELD_NAMES.updatedAt]
    ),
    [FIELD_NAMES.sound]: notification[FIELD_NAMES.sound],
    [FIELD_NAMES.badge]: notification[FIELD_NAMES.badge],
    [FIELD_NAMES.alert]: notification[FIELD_NAMES.alert],
    [FIELD_NAMES.expiry]: notification[FIELD_NAMES.expiry],
    [FIELD_NAMES.delivered]: notification[FIELD_NAMES.delivered],
    [FIELD_NAMES.deliveredAt]: notification[FIELD_NAMES.deliveredAt],
    [FIELD_NAMES.alertIsJson]: notification[FIELD_NAMES.alertIsJson],
    [FIELD_NAMES.delayWhileIdle]: notification[FIELD_NAMES.delayWhileIdle],
    [FIELD_NAMES.processing]: notification[FIELD_NAMES.processing],
    [FIELD_NAMES.contentAvailable]: notification[FIELD_NAMES.contentAvailable],
    [FIELD_NAMES.mutableContent]: notification[FIELD_NAMES.mutableContent],
    [FIELD_NAMES.type]: notification[FIELD_NAMES.type],
    [FIELD_NAMES.externalDeviceId]: notification[FIELD_NAMES.externalDeviceId],
    [FIELD_NAMES.collapseKey]: notification[FIELD_NAMES.collapseKey],
    [FIELD_NAMES.userId]: notification[FIELD_NAMES.userId],
    [FIELD_NAMES.threadId]: notification[FIELD_NAMES.threadId],
    [FIELD_NAMES.failed]: notification[FIELD_NAMES.failed],
    [FIELD_NAMES.soundIsJson]: notification[FIELD_NAMES.soundIsJson],
    [FIELD_NAMES.dryRun]: notification[FIELD_NAMES.dryRun],
    [FIELD_NAMES.notification]: notification[FIELD_NAMES.notification],
    [FIELD_NAMES.notificationData]: notification[FIELD_NAMES.notificationData],
    [FIELD_NAMES.failedAt]: regularFormatDate(
      notification[FIELD_NAMES.failedAt]
    ),
    [FIELD_NAMES.errorCode]: notification[FIELD_NAMES.errorCode],
    [FIELD_NAMES.priority]: notification[FIELD_NAMES.priority],
    [FIELD_NAMES.errorDescription]: notification[FIELD_NAMES.errorDescription],
    [FIELD_NAMES.deliverAfter]: notification[FIELD_NAMES.deliverAfter],
    [FIELD_NAMES.createdAt]: regularFormatDate(
      notification[FIELD_NAMES.createdAt]
    ),
    [FIELD_NAMES.updatedAt]: regularFormatDate(
      notification[FIELD_NAMES.updatedAt]
    ),
    [FIELD_NAMES.appId]: notification[FIELD_NAMES.appId],
    [FIELD_NAMES.retries]: notification[FIELD_NAMES.retries],
    [FIELD_NAMES.uri]: notification[FIELD_NAMES.uri],
    [FIELD_NAMES.failAfter]: notification[FIELD_NAMES.failAfter],
    [FIELD_NAMES.urlArgs]: notification[FIELD_NAMES.urlArgs],
    [FIELD_NAMES.category]: notification[FIELD_NAMES.category],
    [FIELD_NAMES.registrationIds]: notification[FIELD_NAMES.registrationIds],
  }
}

export const formatNotifications = (notifications = []) =>
  notifications.reduce(
    (result, notification) => ({
      ...result,
      [notification[FIELD_NAMES.id]]: formatNotification(notification),
    }),
    {}
  ) || {}

export const formatPayloadForFetchRequest = ({
  page,
  filters,
  sorter,
  search,
} = {}) => ({
  page: page || 1,
  filters: mapFilters(filters),
  sorter: mapSorters(sorter),
  search,
})

export const formatAppVersionForRequests = ({
  [FIELD_NAMES.id]: id,
  key,
  ...values
}) => ({
  ...(id && { id }),
  type: ENTITY_NAMES.mobileAppVersions,
  attributes: {
    ...values,
  },
})

export const formatNotificationForRequests = ({
  [FIELD_NAMES.id]: id,
  key,
  ...values
}) => ({
  ...(id && { id }),
  type: ENTITY_NAMES.mobileNotifications,
  attributes: {
    ...values,
  },
})
