import sortBy from 'lodash/sortBy'
import omit from 'lodash/omit'
import { FIELD_NAMES } from 'const/forms'
import { ENTITY_NAMES } from 'const/api'

export const formatNewGroupOrder = (data) => {
  const positions =
    data?.reduce(
      (result, group, index) => ({
        ...result,
        [group[FIELD_NAMES.id]]: index,
      }),
      {}
    ) || {}

  return {
    positions,
  }
}

export const formatGroups = (data) =>
  data.map((item) => omit(item, ['meta.created_at', 'meta.updated_at']))

export const formatUpdatedGroups = (data) =>
  formatGroups(sortBy(Object.values(data), 'attributes.position'))

export const formatMarketingCopyToUpdate = ({ data }) => {
  return { [ENTITY_NAMES.marketingCopy]: data.payload }
}
