import React, { useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Field, useForm, useFormState } from 'react-final-form'
import ImagePicker from 'components/ImagePicker'
import {
  Wrapper,
  Images,
  ImageWrapper,
  // CTR,
} from './ImageSet.styles'
import { IMAGES_TO_OVERRIDE } from 'const/images'
import { FIELD_NAMES } from 'const/forms'
import { validHeaderWeb } from 'helpers/validators'
import OverrideModal from './OverrideModal'
import SetSkeleton from './SetSkeleton'
import { setPropType } from 'const/propTypes'
import { useSelector } from 'react-redux'
import { selectors as generalSelectors } from 'domains/general'

const ImageSet = ({
  name,
  fullData,
  children,
  isDeleted,
  isHidden,
  isLocked,
}) => {
  const imageSets = useSelector(
    generalSelectors.getSortedImagesByGroupPositions
  )
  const [overrideImage, setOverrideImage] = useState(false)
  const [showOverrideModal, setShowOverrideModal] = useState(false)
  const form = useForm()
  const { modified, values } = useFormState()

  const closeModal = () => {
    setShowOverrideModal(false)
    setOverrideImage(null)
  }

  const updateImages =
    (value, forceUpdate = false) =>
    () => {
      const { batch, change } = form
      batch(() => {
        IMAGES_TO_OVERRIDE.forEach((fieldName) => {
          const wasModified = get(modified, `${name}.${fieldName}`)
          const wasRemoved = !get(values, `${name}.${fieldName}`)
          const canBeOverridden = wasModified
            ? wasRemoved
            : !fullData?.[fieldName]?.manuallyUploaded
          if (forceUpdate || canBeOverridden) {
            change(`${name}.${fieldName}`, value)
          }
        })
      })
    }

  const forceCrop = (value) => {
    setShowOverrideModal(true)
    setOverrideImage(value)
  }

  return (
    <Wrapper>
      <Images>
        {imageSets.map((image) => {
          // const ctr = fullData?.[image.name]?.ctr
          return (
            <ImageWrapper
              key={image.name}
              isHidden={isHidden}
              isDeleted={isDeleted}
            >
              <Field
                disabled={isLocked || isDeleted}
                validate={validHeaderWeb(name, image.name)}
                name={`${name}.${image.name}`}
                cropSize={image.cropSize}
                forceInvalid
                changeCallback={
                  image.name === FIELD_NAMES.headerWeb ? forceCrop : null
                }
                component={ImagePicker}
                hasCropper={!!image.cropSize}
              />
              {/*<CTR>{ctr ? `CTR ${ctr}%` : '-'}</CTR> //TODO: add when API is ready*/}
            </ImageWrapper>
          )
        })}
        {children}
        {isLocked && <SetSkeleton />}
      </Images>
      <OverrideModal
        show={showOverrideModal}
        overrideAll={updateImages(overrideImage, true)}
        overrideEmpty={updateImages(overrideImage)}
        closeModal={closeModal}
      />
    </Wrapper>
  )
}

ImageSet.propTypes = {
  isDeleted: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool.isRequired,
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  fullData: PropTypes.shape({
    [FIELD_NAMES.set]: PropTypes.arrayOf(setPropType),
  }),
}

export default ImageSet
