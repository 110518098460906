import React from 'react'
import { Input } from 'antd'
import FormItem from 'components/FormItem/FormItem'

const FieldTextarea = ({
  input: { value, onChange },
  meta: { dirty },
  label,
  ...rest
}) => {
  const changeHandler = (e) => onChange(e.target.value)

  return (
    <FormItem label={label} $isDirty={dirty}>
      <Input.TextArea value={value} onChange={changeHandler} {...rest} />
    </FormItem>
  )
}

export default FieldTextarea
