import { all, call, put, takeLatest } from 'redux-saga/effects'
import normalized from 'json-api-normalizer'
import * as actions from './actions'
import * as api from './api'
import { getSortedIds } from 'helpers/jsonAPI'

function* fetchProductsWorker({ payload }) {
  try {
    const { data } = yield call(api.getProducts, payload)
    const normalizedData = normalized(data, {
      camelizeKeys: false,
      camelizeTypeValues: false,
    })

    yield put(
      actions.fetchProductsSuccess({
        ...payload,
        page: payload.page,
        pageCount: data.meta.page_count,
        products: normalizedData,
        sortedIds: getSortedIds(data.data),
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchProductsFailure())
  }
}

function* deleteProductWorker({ payload }) {
  try {
    const { data } = yield call(api.deleteProduct, payload)
    if (data) {
      yield put(actions.deleteProductSuccess(payload))
    } else {
      yield put(actions.deleteProductFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteProductFailure())
  }
}

function* updateProductWorker({ payload }) {
  try {
    const { data } = yield call(api.updateProduct, payload)
    if (data) {
      yield put(actions.updateProductSuccess(payload))
    } else {
      yield put(actions.updateProductFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateProductFailure())
  }
}

function* createProductWorker({ payload }) {
  try {
    const { data } = yield call(api.createProduct, payload)
    if (data) {
      yield put(actions.createProductSuccess(payload))
    } else {
      yield put(actions.createProductFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createProductFailure())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchProducts, fetchProductsWorker),
    takeLatest(actions.deleteProduct, deleteProductWorker),
    takeLatest(actions.createProduct, createProductWorker),
    takeLatest(actions.updateProduct, updateProductWorker),
  ])
}
