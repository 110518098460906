import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { Field } from 'react-final-form'
import { Divider, Timeline } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import Input from 'components/Input/Input'
import Select from 'components/Select/Select'
import Options from './components/Options/Options.js'
import FeatureConfUpload from '../FeatureConfUpload/FeatureConfUpload'
import { FeatureConfigurationWrapper } from './FeatureConfigurationWrapper.styles.js'

const FeatureConfiguration = ({ feature }) => (
  <FieldArray name="feature_configurations">
    {({ fields }) =>
      fields.map((name, index) => (
        <Timeline key={name} mode="right">
          <Timeline.Item
            key={name}
            color="red"
            dot={
              index === 0 ? (
                <></>
              ) : (
                <MinusCircleOutlined
                  style={{ fontSize: '25px' }}
                  onClick={() => fields.remove(index)}
                />
              )
            }
          >
            <FeatureConfigurationWrapper>
              <Field
                name={`${name}.predicate_rule`}
                label="Predicate"
                options={[
                  { value: 'AND', label: 'AND' },
                  { value: 'Blank', label: 'Blank' },
                  { value: 'OR', label: 'OR' },
                ]}
                component={Select}
              />
              <Divider />
              <Field
                name={`${name}.groups`}
                hint="should be comma separated numbers for example: 1,2,3,4"
                label="Groups"
                component={Input}
              />
              <Divider />
              <FeatureConfUpload name={name} />
              <Divider />
              <Field
                name={`${name}.user_attributes.utm_term`}
                hint="The value is mapped to utm_term in the user record"
                label="utm_term"
                component={Input}
              />
              <Options feature={feature} name={name} />
            </FeatureConfigurationWrapper>
          </Timeline.Item>
        </Timeline>
      ))
    }
  </FieldArray>
)

export default FeatureConfiguration
