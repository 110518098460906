import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { createProduct, updateProduct } from 'actions/views/insights'
import useAction from 'utils/useAction'
import arrayMutators from 'final-form-arrays'
import { Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/Input'
import { FIELD_NAMES } from 'const/forms'
import { required, validProductLink } from 'helpers/validators'
import { selectors as productsSelectors } from 'domains/products'
import { StyledButton, StyledForm } from './Product.styles'

const Product = ({ id, closeModal }) => {
  const isSavingActive = useSelector(productsSelectors.getIsSavingActive)
  const initialValues = useSelector(productsSelectors.getProductById)(id)
  const createProductFunc = useAction(createProduct)
  const updateProductFunc = useAction(updateProduct)

  const onSubmit = (values) => {
    if (id) {
      updateProductFunc({ values, callback: closeModal })
    } else {
      createProductFunc({ values, callback: closeModal })
    }
  }
  return (
    <Form
      destroyOnUnregister
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, valid }) => (
        <Spin spinning={isSavingActive} size="large">
          <StyledForm onSubmit={handleSubmit}>
            {id && (
              <Field name="id" label="Product ID" disabled component={Input} />
            )}
            <Field
              required
              name={FIELD_NAMES.tagId}
              validate={required}
              label="Tag ID"
              placeholder="ID"
              type="number"
              component={Input}
            />
            <Field
              required
              name={FIELD_NAMES.name}
              validate={required}
              label="Product Name"
              placeholder="Name"
              component={Input}
            />
            <Field
              required
              name={FIELD_NAMES.productType}
              validate={required}
              label="Product Type"
              placeholder="Type"
              component={Input}
            />
            <Field
              required
              name={FIELD_NAMES.company}
              validate={required}
              label="Product Company"
              placeholder="Company"
              component={Input}
            />
            <Field
              name={FIELD_NAMES.originalUrl}
              validate={validProductLink}
              label="URL"
              placeholder="URL"
              component={Input}
            />
            <StyledButton
              type="primary"
              disabled={pristine || !valid}
              onClick={handleSubmit}
            >
              Save changes
            </StyledButton>
          </StyledForm>
        </Spin>
      )}
    />
  )
}

Product.propTypes = {
  id: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
}

Product.defaultTypes = {
  id: null,
}

export default Product
