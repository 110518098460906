import { apiInstance } from 'utils/request'
import { RELATIONSHIPS_NAMES } from 'const/api'

export const getAdPackages = ({ page, filters, sorter, search }) =>
  apiInstance.get(
    `/${RELATIONSHIPS_NAMES.packages}?include=${RELATIONSHIPS_NAMES.packageAsset},${RELATIONSHIPS_NAMES.packageAsset}` +
      `&page[number]=${page}` +
      '&page[size]=20' +
      filters +
      (search ? '&filter[search]=' + encodeURIComponent(search) : '') +
      (sorter || '&sort=-id')
  )

export const updateAdPackage = (values) =>
  apiInstance.put(
    `/${RELATIONSHIPS_NAMES.packages}/${values.data.id}` +
      `?include=${RELATIONSHIPS_NAMES.packageAsset},${RELATIONSHIPS_NAMES.packageAsset}`,
    values
  )

export const updateAdPackageAsset = (values) =>
  apiInstance.put(
    `/${RELATIONSHIPS_NAMES.packageAssets}/${values.data.id}`,
    values
  )

export const createAdPackage = (values) => {
  const { id, ...rest } = values

  return apiInstance.post(
    `/${RELATIONSHIPS_NAMES.packages}` +
      `?include=${RELATIONSHIPS_NAMES.packageAsset},${RELATIONSHIPS_NAMES.packageAsset}`,
    rest
  )
}

export const deleteAdPackage = (id) => apiInstance.delete(`/ad_packages/${id}`)

export const duplicateAdPackage = (id) => apiInstance.post(`/ad_packages/${id}/duplicate`)

export const createAdPackageAsset = (values) =>
  apiInstance.post(`/ad_package_assets`, values)
