import React from 'react'
import { Layout, Menu, Row } from 'antd'
import { ROUTE_TO_LABEL } from 'const/routes'
import MenuLink from './MenuLink'
import Submenu from './SubMenu'
import { selectors as adminSelectors } from 'domains/admin'
import { useSelector } from 'react-redux'
import RightMenu from 'components/RightMenu'
import { useHistory, useLocation } from 'react-router'

const Header = () => {
  const { pathname } = useLocation()
  const { push } = useHistory()
  const links = useSelector(adminSelectors.getAvailableLinks)

  return (
    <Layout.Header>
      <Row justify="space-between" wrap={false}>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={[pathname]}
          selectedKeys={[pathname]}
        >
          {links.map(({ route, title, children, icon, titleRoute }) =>
            route ? (
              <Menu.Item key={route}>
                <MenuLink
                  to={route}
                  push={push}
                  label={ROUTE_TO_LABEL[route]}
                  pathname={pathname}
                />
              </Menu.Item>
            ) : (
              <Menu.SubMenu
                key={title}
                icon={icon}
                title={<Submenu route={titleRoute} label={title} />}
              >
                {children.map((route) => {
                  const linkProps = route?.startsWith('http')
                    ? { href: route, target: '_blank' }
                    : { to: route }

                  return (
                    <Menu.Item key={route}>
                      <MenuLink
                        push={push}
                        label={ROUTE_TO_LABEL[route]}
                        pathname={pathname}
                        {...linkProps}
                      />
                    </Menu.Item>
                  )
                })}
              </Menu.SubMenu>
            )
          )}
        </Menu>
        <RightMenu />
      </Row>
    </Layout.Header>
  )
}

export default Header
