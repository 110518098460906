import styled from 'styled-components'
import { CloseSquareOutlined } from '@ant-design/icons'

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding: 5px 0;
  :last-child {
    border: none;
  }
`

export const Description = styled.div`
  display: flex;
  align-items: center;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`

export const Wrapper = styled.div`
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 0;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`

export const CloseSquare = styled(CloseSquareOutlined)`
  margin-left: 10px;
  font-size: 24px;
  cursor: pointer;
  &:hover {
    color: red;
  }
`

export const RetailerName = styled.span`
  font-size: 16px;
  font-weight: 600;
`

export const Items = styled.div`
  padding-left: 10px;
`
