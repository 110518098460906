import { useEffect } from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { getFormSavedValues } from 'reducers/forms/selectors'
import { resetValues } from 'reducers/forms/actions'
import useAction from 'utils/useAction'
import {
  getDirtyFields,
  getIsLoaded,
  getPage,
  getSearchString,
} from 'domains/retailers/selectors'

export default (condition) => {
  const resetValuesFunc = useAction(resetValues)
  const form = useForm()
  const { batch, change, getState } = form
  const savedValues = useSelector(getFormSavedValues)
  const page = useSelector(getPage)
  const search = useSelector(getSearchString)
  const dirtyFields = useSelector(getDirtyFields)
  const isLoaded = useSelector(getIsLoaded)
  const { initialValues } = getState()

  useEffect(() => {
    if (condition && isLoaded && !isEmpty(savedValues)) {
      batch(() => {
        Object.keys(dirtyFields).forEach((key) => {
          if (
            get(savedValues, key) !== undefined &&
            get(initialValues, key) !== undefined
          ) {
            change(key, get(savedValues, key))
          }
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    batch,
    change,
    savedValues,
    condition,
    dirtyFields,
    isLoaded,
    page,
    search,
  ])

  useEffect(() => {
    return () => {
      resetValuesFunc()
    }
  }, [resetValuesFunc])
}
