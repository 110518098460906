import React from 'react'
import { FIELD_NAMES } from 'const/forms'
import { required } from 'helpers/validators'
import { Field, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import Regions from './Select'
import { getUnavailableRegions } from 'domains/search/selectors'

const RegionSelect = ({ disabled }) => {
  const { values } = useFormState()
  const id = values?.[FIELD_NAMES.mainRetailerId]
  const unavailableRegions = useSelector(getUnavailableRegions)(
    id,
    FIELD_NAMES.retailers
  )
  return (
    <Field
      disabled={disabled}
      excludedRegions={unavailableRegions}
      component={Regions}
      name={FIELD_NAMES.countryCode}
      validate={required}
    />
  )
}

export default RegionSelect
