import moment from 'moment'
import { FIELD_NAMES } from 'const/forms'
import { DATE_FORMAT_DASH_REVERSED } from 'const/dates'

export const formatDatesForRequest = ({
  [FIELD_NAMES.installedDate]: firstDate,
}) => {
  const fixedInstalledDate = moment(firstDate).format(DATE_FORMAT_DASH_REVERSED)

  return {
    [FIELD_NAMES.installedDate]: fixedInstalledDate,
  }
}
