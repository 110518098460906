import PropTypes from 'prop-types'
import { FIELD_NAMES } from './forms'

export const setPropType = PropTypes.shape({
  [FIELD_NAMES.headerWeb]: PropTypes.string,
  [FIELD_NAMES.headerMobile]: PropTypes.string,
  [FIELD_NAMES.cashbackWeb]: PropTypes.string,
  [FIELD_NAMES.cashbackMobile]: PropTypes.string,
  [FIELD_NAMES.featuredWeb]: PropTypes.string,
  [FIELD_NAMES.featuredMobile]: PropTypes.string,
  [FIELD_NAMES.trendingWeb]: PropTypes.string,
  [FIELD_NAMES.trendingMobile]: PropTypes.string,
})

const imagePropType = PropTypes.shape({
  src: PropTypes.string,
  id: PropTypes.number,
  createdAt: PropTypes.string,
  manuallyUploaded: PropTypes.bool,
})

export const retailerPropType = PropTypes.shape({
  isInvalid: PropTypes.bool,
  isGlobal: PropTypes.bool,
  isRegional: PropTypes.bool,
  isDomain: PropTypes.bool,
  isMobile: PropTypes.bool,
  url: PropTypes.string,
  [FIELD_NAMES.country]: PropTypes.string,
  [FIELD_NAMES.countryCode]: PropTypes.string,
  [FIELD_NAMES.domain]: PropTypes.string,
  [FIELD_NAMES.type]: PropTypes.string,
  [FIELD_NAMES.name]: PropTypes.string,
  [FIELD_NAMES.id]: PropTypes.string,
})

export const paymentPropTypes = PropTypes.shape({
  [FIELD_NAMES.userEmail]: PropTypes.number,
  [FIELD_NAMES.amount]: PropTypes.number,
  [FIELD_NAMES.name]: PropTypes.string,
  [FIELD_NAMES.id]: PropTypes.string,
})

export const groupPropType = PropTypes.shape({
  attributes: PropTypes.shape({
    name: PropTypes.string,
  }),
  id: PropTypes.string,
})

export const setFullPropType = PropTypes.shape({
  [FIELD_NAMES.headerWeb]: imagePropType,
  [FIELD_NAMES.headerMobile]: imagePropType,
  [FIELD_NAMES.cashbackWeb]: imagePropType,
  [FIELD_NAMES.cashbackMobile]: imagePropType,
  [FIELD_NAMES.featuredWeb]: imagePropType,
  [FIELD_NAMES.featuredMobile]: imagePropType,
  [FIELD_NAMES.trendingWeb]: imagePropType,
  [FIELD_NAMES.trendingMobile]: imagePropType,
})

export const impressionsPropType = PropTypes.shape({
  [FIELD_NAMES.headerWeb]: PropTypes.number,
  [FIELD_NAMES.headerMobile]: PropTypes.number,
  [FIELD_NAMES.cashbackWeb]: PropTypes.number,
  [FIELD_NAMES.cashbackMobile]: PropTypes.number,
  [FIELD_NAMES.featuredWeb]: PropTypes.number,
  [FIELD_NAMES.featuredMobile]: PropTypes.number,
  [FIELD_NAMES.trendingWeb]: PropTypes.number,
  [FIELD_NAMES.trendingMobile]: PropTypes.number,
})

export const mobileNotificationPropType = PropTypes.shape({
  [FIELD_NAMES.id]: PropTypes.number,
  [FIELD_NAMES.deviceToken]: PropTypes.string,
  [FIELD_NAMES.sound]: PropTypes.any,
  [FIELD_NAMES.badge]: PropTypes.any,
  [FIELD_NAMES.alert]: PropTypes.any,
  [FIELD_NAMES.expiry]: PropTypes.number,
  [FIELD_NAMES.delivered]: PropTypes.bool,
  [FIELD_NAMES.alertIsJson]: PropTypes.bool,
  [FIELD_NAMES.delayWhileIdle]: PropTypes.bool,
  [FIELD_NAMES.processing]: PropTypes.bool,
  [FIELD_NAMES.contentAvailable]: PropTypes.bool,
  [FIELD_NAMES.mutableContent]: PropTypes.bool,
  [FIELD_NAMES.type]: PropTypes.string,
  [FIELD_NAMES.externalDeviceId]: PropTypes.number,
  [FIELD_NAMES.collapseKey]: PropTypes.string,
  [FIELD_NAMES.userId]: PropTypes.number,
  [FIELD_NAMES.threadId]: PropTypes.number,
  [FIELD_NAMES.failed]: PropTypes.bool,
  [FIELD_NAMES.soundIsJson]: PropTypes.bool,
  [FIELD_NAMES.dryRun]: PropTypes.bool,
  [FIELD_NAMES.deliveredAt]: PropTypes.string,
  [FIELD_NAMES.notification]: PropTypes.string,
  [FIELD_NAMES.notificationData]: PropTypes.string,
  [FIELD_NAMES.failedAt]: PropTypes.string,
  [FIELD_NAMES.errorCode]: PropTypes.number,
  [FIELD_NAMES.priority]: PropTypes.number,
  [FIELD_NAMES.errorDescription]: PropTypes.string,
  [FIELD_NAMES.deliverAfter]: PropTypes.string,
  [FIELD_NAMES.createdAt]: PropTypes.string,
  [FIELD_NAMES.updatedAt]: PropTypes.string,
  [FIELD_NAMES.appId]: PropTypes.number,
  [FIELD_NAMES.retries]: PropTypes.number,
  [FIELD_NAMES.uri]: PropTypes.string,
  [FIELD_NAMES.failAfter]: PropTypes.string,
  [FIELD_NAMES.urlArgs]: PropTypes.string,
  [FIELD_NAMES.category]: PropTypes.string,
  [FIELD_NAMES.registrationIds]: PropTypes.arrayOf(PropTypes.string),
})
