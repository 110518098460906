export const STATUS_FILTERS = [
  { text: 'Yes', value: 'true' },
  { text: 'No', value: 'false' },
]

export const MOBILE_APP_PLATFORM_FILTERS = [
  { text: 'iPhone', value: 'iphone' },
  { text: 'Android', value: 'android' },
  { text: 'Android(Amazon)', value: 'android_amazon' },
]

export const PLATFORM_TYPES = [
  { label: 'iPhone', value: 'iphone' },
  { label: 'Android', value: 'android' },
  { label: 'Android(Amazon)', value: 'android_amazon' },
]

export const NOTIFICATION_TYPE_KEYS = {
  lowInStockAlert: 'low_in_stock_alert',
  backInStockAlert: 'back_in_stock_alert',
  saleAlert: 'sale_alert',
  tagSaved: 'tag_saved',
  socialTagSaved: 'social_tag_saved',
  autoMuted: 'auto_muted',
}

export const NOTIFICATION_TYPE_OPTIONS = [
  { value: NOTIFICATION_TYPE_KEYS.lowInStockAlert, label: 'Low in stock' },
  { value: NOTIFICATION_TYPE_KEYS.backInStockAlert, label: 'Back in stock' },
  { value: NOTIFICATION_TYPE_KEYS.saleAlert, label: 'Sale' },
  { value: NOTIFICATION_TYPE_KEYS.tagSaved, label: 'Tag saved' },
  { value: NOTIFICATION_TYPE_KEYS.autoMuted, label: 'Tag auto-muted' },
]

export const MAP_NOTIFICATION_TYPE_TO_USER_TEXT = {
  [NOTIFICATION_TYPE_KEYS.lowInStockAlert]: 'Low in stock',
  [NOTIFICATION_TYPE_KEYS.backInStockAlert]: 'Back in stock',
  [NOTIFICATION_TYPE_KEYS.saleAlert]: 'Sale',
  [NOTIFICATION_TYPE_KEYS.tagSaved]: 'Tag saved',
  [NOTIFICATION_TYPE_KEYS.autoMuted]: 'Tag auto-muted',
  [NOTIFICATION_TYPE_KEYS.socialTagSaved]: 'Social tag saved',
  '-': 'unknown',
}
