import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { createShopOffer, updateShopOffer } from 'actions/views/shops'
import useAction from 'utils/useAction'
import arrayMutators from 'final-form-arrays'
import { Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/Input'
import { FIELD_NAMES } from 'const/forms'
import {
  required,
  validProductLink,
  validExclusivePriority,
} from 'helpers/validators'
import { StyledButton, StyledForm } from './ShopOffers.styles'
import InputNumber from 'components/Input/InputNumber'
import CustomRangePicker from '../AdPackage/CustomRangePicker'
import RadioGroup from 'components/RadioGroup/GroupField'
import { EXCLUSIVE_STATUS, OFFER_TYPES } from 'const/coupons'
import Select from 'components/Select/Select'
import TextArea from 'components/TextArea/TextArea'
import { getIsSavingActive, getShopOffersById } from 'domains/shops/selectors'
import SearchRules from 'components/SearchRules'
import DatePicker from '../../DatePicker/DatePicker'

const autoSize = { minRows: 3, maxRows: 3 }

const ShopOffers = ({ id, closeModal }) => {
  const isSavingActive = useSelector(getIsSavingActive)
  const initialValues = useSelector(getShopOffersById)(id).attributes
  const createShopOfferFunc = useAction(createShopOffer)
  const updateShopOfferFunc = useAction(updateShopOffer)

  const onSubmit = (data) => {
    const values = {
      ...data,
      [FIELD_NAMES.ruleId]: id && initialValues.ruleId,
      [FIELD_NAMES.ruleIds]:
        !id && data.rule_id?.map((item) => item.value).toString(),
    }

    if (id) {
      updateShopOfferFunc({ values, callback: closeModal })
    } else {
      createShopOfferFunc({ values, callback: closeModal })
    }
  }

  return (
    <Form
      destroyOnUnregister
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={
        { ...initialValues, [FIELD_NAMES.id]: id } || {
          [FIELD_NAMES.exclusive]: false,
          [FIELD_NAMES.offerType]: OFFER_TYPES[0].value,
          [FIELD_NAMES.rate]: 0,
        }
      }
      render={({ values, handleSubmit, pristine }) => {
        const isOfferTypeCoupons =
          values[FIELD_NAMES.offerType] === OFFER_TYPES[0].value
        return (
          <Spin spinning={isSavingActive} size="large">
            <StyledForm onSubmit={handleSubmit}>
              {id && (
                <Field
                  name={FIELD_NAMES.id}
                  label="ID"
                  disabled
                  component={Input}
                />
              )}
              <SearchRules
                validate={required}
                disabled={!!id}
                initialValues={initialValues?.rule_id}
                name={FIELD_NAMES.ruleId}
              />
              <Field
                required
                name={FIELD_NAMES.offerType}
                validate={required}
                options={OFFER_TYPES}
                label="Offer type"
                component={RadioGroup}
              />
              <Field
                required
                name={FIELD_NAMES.code}
                validate={required}
                label="Code"
                placeholder="Code"
                component={Input}
              />
              {isOfferTypeCoupons && (
                <>
                  <Field
                    required
                    defaultValue={false}
                    name={FIELD_NAMES.exclusive}
                    options={EXCLUSIVE_STATUS}
                    label="Karma exclusive"
                    component={Select}
                  />
                  <Field
                    name={FIELD_NAMES.temporaryPriorityUntil}
                    label="Temporary priority until"
                    component={DatePicker}
                  />
                </>
              )}
              <Field
                name={FIELD_NAMES.description}
                label="Description"
                placeholder="Description"
                component={Input}
              />
              {isOfferTypeCoupons && (
                <Field
                  name={FIELD_NAMES.priority}
                  label="Priority"
                  component={Input}
                />
              )}
              <CustomRangePicker
                keys={[FIELD_NAMES.startedAt, FIELD_NAMES.expiredAt]}
                placeholder={['Start Date', 'Expiration Date']}
              />
              <Field
                required
                name={FIELD_NAMES.rate}
                min={0}
                max={1}
                step={0.1}
                label="Rate"
                placeholder="Rate"
                component={InputNumber}
              />
              <Field
                label="Exclusive Priority"
                component={InputNumber}
                type="number"
                min={1}
                max={50}
                name={FIELD_NAMES.exclusivePriority}
                validate={validExclusivePriority}
              />
              {isOfferTypeCoupons && (
                <Fragment>
                  <Field
                    name={FIELD_NAMES.url}
                    label="URL"
                    component={Input}
                    validate={validProductLink}
                  />
                  <Field
                    name={FIELD_NAMES.salesRep}
                    label="Sales Rep"
                    component={TextArea}
                  />
                  <Field
                    name={FIELD_NAMES.comments}
                    label="Comments"
                    component={TextArea}
                    autoSize={autoSize}
                  />
                </Fragment>
              )}
              <StyledButton
                type="primary"
                disabled={pristine}
                onClick={handleSubmit}
              >
                Save changes
              </StyledButton>
            </StyledForm>
          </Spin>
        )
      }}
    />
  )
}

ShopOffers.propTypes = {
  id: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
}

ShopOffers.defaultTypes = {
  id: null,
}

export default ShopOffers
