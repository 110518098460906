import { createSlice } from '@reduxjs/toolkit'
import initial from 'lodash/initial'

const initialState = {
  modals: [],
}

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    open: (state, action) => ({
      modals: [...state.modals, action.payload],
    }),
    close: (state) => ({
      modals: initial(state.modals),
    }),
    closePrev: (state) => {
      const modals = JSON.parse(JSON.stringify(state.modals))
      modals.shift()
      return {
        modals,
      }
    },
    clearModalsStack: () => initialState,
  },
})

export default modalsSlice
