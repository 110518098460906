import { MODAL_TYPES } from 'const/modals'
import { useSelector } from 'react-redux'
import { formValues } from 'reducers/forms/selectors'
import { FIELD_NAMES, FORM_NAMES } from 'const/forms'
import {
  deletedSets,
  getInitialValuesRetailerImages,
} from 'domains/retailers/selectors'
import isEmpty from 'lodash/isEmpty'
import { diff } from 'deep-object-diff'
import useAction from 'utils/useAction'
import { saveDeletedSets } from 'domains/retailers/actions'
import { useCallback } from 'react'

export default (type, data, closeHandler, openModal) => {
  const initialValues = useSelector(getInitialValuesRetailerImages)(
    data?.[FIELD_NAMES.id]
  )
  const values = useSelector(formValues)(FORM_NAMES.IMAGES)
  const deletedFields = useSelector(deletedSets)
  const setDeletedFields = useAction(saveDeletedSets)
  const skipChanges = () => {
    closeHandler()
    setDeletedFields([])
  }

  const isChangesEmpty = useCallback(() => {
    const filteredValues = {
      ...values,
      [FIELD_NAMES.set]:
        values?.[FIELD_NAMES.set]?.map((set, index) =>
          deletedFields.includes(index) ? null : set
        ) || [],
    }
    return isEmpty(diff(initialValues, filteredValues))
  }, [deletedFields, initialValues, values])

  if (type === MODAL_TYPES.STORE_IMAGES) {
    if (!isChangesEmpty()) {
      return [openModal, skipChanges]
    } else {
      return [closeHandler]
    }
  } else {
    return [closeHandler]
  }
}
