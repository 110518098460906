import React from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { FIELD_NAMES } from 'const/forms'
import { Wrapper, PriorityInputNumber, StyledSwitch } from './GroupCell.styles'
import useGlobalChangeOnBlur from 'utils/useGlobalChangeOnBlur'

const GroupCell = ({
  input: { value, onChange },
  meta,
  changeCallback,
  disabled,
  isShadow,
  ...rest
}) => {
  const handleBlur = useGlobalChangeOnBlur(
    { [FIELD_NAMES.position]: value?.[FIELD_NAMES.position] || 0 },
    meta.modified,
    changeCallback
  )
  const activeChangeHandler = (active) => {
    const newValue = {
      ...value,
      [FIELD_NAMES.active]: active,
      [FIELD_NAMES.position]: 0,
    }
    onChange(newValue)
    changeCallback(newValue)
  }
  const positionChangeHandler = (position) => {
    onChange({
      ...value,
      [FIELD_NAMES.position]: position || 0,
    })
  }
  const active = value[FIELD_NAMES.active]
  const position = value[FIELD_NAMES.position]
  return (
    <Wrapper {...rest}>
      <StyledSwitch
        shadow={isShadow ? active?.toString() : undefined}
        disabled={disabled}
        checked={active}
        onChange={activeChangeHandler}
      />
      <PriorityInputNumber
        value={position}
        onBlur={handleBlur}
        disabled={disabled || !active}
        placeholder="Priority"
        min={0}
        onChange={positionChangeHandler}
      />
    </Wrapper>
  )
}

GroupCell.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.shape({
        [FIELD_NAMES.active]: PropTypes.bool,
        [FIELD_NAMES.position]: PropTypes.number,
      }),
      PropTypes.string,
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    modified: PropTypes.bool,
  }).isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: PropTypes.bool,
  isShadow: PropTypes.bool,
  changeCallback: PropTypes.func,
}

GroupCell.defaultProps = {
  changeCallback: noop,
  disabled: false,
  isShadow: false,
}

export default GroupCell
