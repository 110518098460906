export const CONFIRMED_FILTERS = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
]

export const ACTIVE_FILTERS = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
]

export const DEALTYPE_FILTERS = [
  { text: 'CPA', value: 'cpa' },
  { text: 'Flat Fee', value: 'flat fee' },
  { text: 'Fixed Fee', value: 'fixed fee' },
  { text: 'Hybrid', value: 'hybrid' },
]
