import React from 'react'
import PropTypes from 'prop-types'
import { Switch as AntSwitch } from 'antd'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'

const FieldSwitch = ({
  input,
  meta,
  label,
  description,
  hint,
  required,
  ...rest
}) => (
  <FieldWrapper hint={hint} label={label} meta={meta} required={required}>
    <AntSwitch
      invalid={(!!(meta.modified && meta.error)).toString()}
      placeholder={description}
      type={`${input.type || 'text'}`}
      {...input}
      {...rest}
      onChange={input.onChange}
    />
  </FieldWrapper>
)

FieldSwitch.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  hint: PropTypes.string,
  // eslint-disable-next-line react/boolean-prop-naming
  required: PropTypes.bool,
  input: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    modified: PropTypes.bool,
  }).isRequired,
}

export default FieldSwitch
