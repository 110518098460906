import { SearchOutlined } from '@ant-design/icons'
import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import { Actions, StyledButton } from './Coupons.styles'
import { formatDate } from 'helpers/dates'
import {
  OFFER_TYPE_FILTERS,
  COUPONS_SOURCE_FILTERS,
  EXCLUSIVE_STATUS_FILTERS,
} from 'const/coupons'
import { formatFieldValue } from 'helpers/formatters/fields'
import UrlComponent from 'components/UrlComponent'
import Status from 'components/Status'
import ShortText from 'components/ShortText'
import Search from '../Features/Table/Search'

export default (filters, sorter, handleEditCoupon, handleDeleteCoupon) => [
  {
    title: 'Coupon ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 110,
    fixed: 'left',
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.id && sorter.order,
  },
  {
    title: 'Code',
    fixed: 'left',
    dataIndex: FIELD_NAMES.code,
    key: FIELD_NAMES.code,
    width: 150,
    align: 'center',
    filteredValue: filters[FIELD_NAMES.code] || null,
    filterIcon: (filtered) => (
      <SearchOutlined color={filtered ? 'blue' : undefined} />
    ),
    filterDropdown: (props) => <Search {...props} />,
  },
  {
    title: 'Description',
    width: 250,
    dataIndex: FIELD_NAMES.description,
    key: FIELD_NAMES.description,
    ellipsis: true,
    render: formatFieldValue,
  },
  {
    title: 'Rate',
    dataIndex: FIELD_NAMES.rate,
    key: FIELD_NAMES.rate,
    width: 80,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.rate && sorter.order,
  },
  {
    title: 'Retailer',
    dataIndex: FIELD_NAMES.retailerName,
    key: FIELD_NAMES.retailerName,
    render: formatFieldValue,
    width: 120,
    ellipsis: true,
    align: 'center',
  },
  {
    title: 'Retailer ID',
    dataIndex: FIELD_NAMES.mainRetailerId,
    key: FIELD_NAMES.mainRetailerId,
    render: formatFieldValue,
    width: 100,
    align: 'center',
  },
  {
    title: 'Priority',
    dataIndex: FIELD_NAMES.priority,
    key: FIELD_NAMES.priority,
    width: 100,
    align: 'center',
    render: formatFieldValue,
  },
  {
    title: 'Exclusive Priority',
    dataIndex: FIELD_NAMES.exclusivePriority,
    key: FIELD_NAMES.exclusivePriority,
    width: 100,
    align: 'center',
    render: formatFieldValue,
  },
  {
    title: 'Geo’s',
    dataIndex: FIELD_NAMES.geos,
    key: FIELD_NAMES.geos,
    width: 100,
    align: 'center',
    render: formatFieldValue,
  },
  {
    title: 'URL',
    dataIndex: FIELD_NAMES.url,
    key: FIELD_NAMES.url,
    width: 100,
    align: 'center',
    render: (_, { url }) => <UrlComponent url={url} />,
  },
  {
    title: 'Rule',
    ellipsis: true,
    dataIndex: FIELD_NAMES.ruleName,
    key: FIELD_NAMES.ruleName,
    width: 120,
    align: 'center',
    render: formatFieldValue,
  },
  {
    title: 'Rule ID',
    dataIndex: FIELD_NAMES.ruleId,
    key: FIELD_NAMES.ruleId,
    width: 100,
    align: 'center',
    render: formatFieldValue,
  },
  {
    title: 'Started at',
    dataIndex: FIELD_NAMES.startedAt,
    key: FIELD_NAMES.startedAt,
    width: 120,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.startedAt && sorter.order,
    render: formatDate,
  },
  {
    title: 'Expired at',
    dataIndex: FIELD_NAMES.expiredAt,
    key: FIELD_NAMES.expiredAt,
    width: 120,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.expiredAt && sorter.order,
    render: formatDate,
  },
  {
    title: 'Number of uses',
    dataIndex: FIELD_NAMES.totalUseCount,
    key: FIELD_NAMES.totalUseCount,
    width: 130,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.totalUseCount && sorter.order,
    render: formatFieldValue,
  },
  {
    title: 'Successful uses',
    dataIndex: FIELD_NAMES.successUseCount,
    key: FIELD_NAMES.successUseCount,
    width: 130,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.successUseCount && sorter.order,
    render: formatFieldValue,
  },
  {
    title: 'Last successful use',
    dataIndex: FIELD_NAMES.lastSuccessUse,
    key: FIELD_NAMES.lastSuccessUse,
    width: 120,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.lastSuccessUse && sorter.order,
    render: formatDate,
  },
  {
    title: 'Created at',
    dataIndex: FIELD_NAMES.createdAt,
    key: FIELD_NAMES.createdAt,
    width: 120,
    sorter: true,
    align: 'center',
    sortOrder: sorter.columnKey === FIELD_NAMES.createdAt && sorter.order,
    render: formatDate,
  },
  {
    title: 'Source',
    dataIndex: FIELD_NAMES.source,
    key: FIELD_NAMES.source,
    width: 100,
    align: 'center',
    render: formatFieldValue,
    filters: COUPONS_SOURCE_FILTERS,
    filteredValue: filters[FIELD_NAMES.source] || null,
    onFilter: (value, record) => record[FIELD_NAMES.source] === value,
  },
  {
    title: 'Sales Rep',
    dataIndex: FIELD_NAMES.salesRep,
    key: FIELD_NAMES.salesRep,
    width: 180,
    align: 'center',
    render: (value) => <ShortText text={value} lines={4} />,
  },
  {
    title: 'Comments',
    dataIndex: FIELD_NAMES.comments,
    key: FIELD_NAMES.comments,
    width: 180,
    align: 'center',
    render: (value) => <ShortText text={value} lines={4} />,
  },
  {
    title: 'Temporary priority until',
    dataIndex: FIELD_NAMES.temporaryPriorityUntil,
    key: FIELD_NAMES.temporaryPriorityUntil,
    width: 120,
    sorter: true,
    align: 'center',
    fixed: 'right',
    sortOrder: sorter.columnKey === FIELD_NAMES.temporaryPriorityUntil && sorter.order,
    render: formatDate,
  },
  {
    title: 'Karma exclusive',
    dataIndex: FIELD_NAMES.exclusive,
    key: FIELD_NAMES.exclusive,
    width: 100,
    render: (value) => <Status value={value} />,
    align: 'center',
    fixed: 'right',
    filters: EXCLUSIVE_STATUS_FILTERS,
    filteredValue: filters[FIELD_NAMES.exclusive] || null,
  },
  {
    title: 'Offer type',
    dataIndex: FIELD_NAMES.offerType,
    key: FIELD_NAMES.offerType,
    width: 100,
    render: formatFieldValue,
    filters: OFFER_TYPE_FILTERS,
    fixed: 'right',
    align: 'center',
    filteredValue: filters[FIELD_NAMES.offerType] || null,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    fixed: 'right',
    width: 120,
    render: (_, coupon) => {
      const onEdit = () => handleEditCoupon(coupon[FIELD_NAMES.id])
      const onDelete = () => handleDeleteCoupon(coupon)
      return (
        <Actions>
          <StyledButton onClick={onEdit}>Edit</StyledButton>
          <StyledButton onClick={onDelete} type="danger">
            Delete
          </StyledButton>
        </Actions>
      )
    },
  },
]
