import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoaded: false,
  isSavingActive: false,
  payload: {},
}

const marketingPagesSlice = createSlice({
  name: 'marketingPages',
  initialState,
  reducers: {
    fetchPitaSettingsSuccess: (state, { payload }) => ({
      ...state,
      isLoaded: true,
      payload: payload,
    }),
    fetchPitaSettingsFailure: (state) => state,
    startSaving: (state) => ({
      ...state,
      isSavingActive: true,
    }),
    finishSaving: (state) => ({
      ...state,
      isSavingActive: false,
    }),
    clearData: () => initialState,
  },
})

export default marketingPagesSlice
