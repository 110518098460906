/* eslint-disable react/boolean-prop-naming */
import React from 'react'
import PropTypes from 'prop-types'
import { ActiveCb, InactiveCb, Wrapper } from './StakeInfo.styles'

const StakeInfo = ({ isActive, value, hideValue, hideStatus }) => (
  <Wrapper>
    {!hideStatus &&
      (isActive ? (
        <ActiveCb twoToneColor="#52c41a" />
      ) : (
        <InactiveCb twoToneColor="#eb2f96" />
      ))}
    {!hideValue && value}
  </Wrapper>
)

StakeInfo.propTypes = {
  value: PropTypes.number,
  isActive: PropTypes.bool,
  hideValue: PropTypes.bool,
  hideStatus: PropTypes.bool,
}

StakeInfo.defaultProps = {
  value: 0,
  isActive: false,
  hideValue: false,
  hideStatus: false,
}

export default StakeInfo
