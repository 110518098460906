import React from 'react'
import { Wrapper, Image, Text, SubText, BackButton } from './NotFound.styles'
import ROUTES from 'const/routes'

const NotFound = () => (
  <Wrapper>
    <Image src="/images/404-illustration@2x.png" />
    <Text>Page Not Found</Text>
    <SubText>Oops, it looks like this page doesn't exist.</SubText>
    <BackButton to={ROUTES.HOME}>Back Home</BackButton>
  </Wrapper>
)

export default NotFound
