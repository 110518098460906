import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isSavingActive: false,
  isLoaded: false,
  currentWebsite: null,
  creditCards: [],
}

const creditCardsComparisonSlice = createSlice({
  name: 'creditCardsComparison',
  initialState,
  reducers: {
    startSaving: (state) => ({
      ...state,
      isSavingActive: true,
    }),
    finishSaving: (state) => ({
      ...state,
      isSavingActive: false,
      isLoaded: true,
    }),
    setCreditCards: (state, { payload }) => ({
      ...state,
      creditCards: payload.creditCards,
      currentWebsite: payload.website,
      isLoaded: true,
    }),
    setIsLoaded: (state, { payload }) => ({
      ...state,
      isLoaded: payload,
    }),
    setCreditCardProcessed: (state, { payload }) => ({
      ...state,
      creditCards: state.creditCards.map((creditCard) =>
        creditCard.id === payload
          ? { ...creditCard, processed: true }
          : creditCard
      ),
    }),
    clearData: () => initialState,
  },
})

export default creditCardsComparisonSlice
