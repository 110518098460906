import React from 'react'
import PropTypes from 'prop-types'
import { Text } from './ShortText.styles'

const ShortText = ({ text, lines }) => <Text lines={lines}>{text || '-'}</Text>

ShortText.propTypes = {
  text: PropTypes.string,
  lines: PropTypes.number,
}

export default ShortText
