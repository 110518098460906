import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { StyledRow, StyledButton, StyledCol, Wrapper } from './UserForm.styles'
import Input from 'components/TextInput'
import * as actions from 'actions/views/customerService'
import useAction from 'utils/useAction'
import { FIELD_NAMES } from 'const/forms'
import { validUserEmail } from 'helpers/validators'

const UserForm = ({ initialValues }) => {
  const fetchUserTransactionsFunc = useAction(actions.fetchUserTransactions)
  const handleSubmit = (values) => fetchUserTransactionsFunc(values)
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ hasValidationErrors, handleSubmit, values, pristine }) => {
        return (
          <Wrapper>
            <StyledCol>
              <StyledRow>
                <Field
                  allowClear
                  onPressEnter={handleSubmit}
                  validate={validUserEmail}
                  name={FIELD_NAMES.email}
                  placeholder="Enter user email"
                  component={Input}
                />
              </StyledRow>
              <StyledRow>
                <Field
                  allowClear
                  onPressEnter={handleSubmit}
                  name={FIELD_NAMES.search}
                  placeholder="Search retailer name or retailer ID"
                  component={Input}
                />
              </StyledRow>
            </StyledCol>
            <StyledButton
              onClick={handleSubmit}
              disabled={
                hasValidationErrors || !values[FIELD_NAMES.email] || pristine
              }
            >
              Search
            </StyledButton>
          </Wrapper>
        )
      }}
    />
  )
}

UserForm.propTypes = {
  initialValues: PropTypes.shape({
    [FIELD_NAMES.email]: PropTypes.string,
    [FIELD_NAMES.search]: PropTypes.string,
  }),
}

export default UserForm
