import { apiInstance } from 'utils/request'
import { ENTITY_NAMES, RELATIONSHIPS_NAMES } from 'const/api'
import parseError from 'helpers/parseServerError'

export const getPayments = ({ page, search, filters, sorter }) =>
  apiInstance
    .get(`/${RELATIONSHIPS_NAMES.admin}/${RELATIONSHIPS_NAMES.payments}`, {
      params: {
        filter: {
          search: search ? encodeURIComponent(search) : null,
          ...filters,
        },
        sort: sorter,
        page: {
          number: page,
          size: 20,
        },
      },
    })
    .catch((error) => {
      parseError(error, 'Cannot get payments')
    })

export const getBudget = () =>
  apiInstance
    .get(`/${RELATIONSHIPS_NAMES.admin}/${RELATIONSHIPS_NAMES.payments}/budget`)
    .catch((error) => {
      parseError(error, 'Cannot get budget')
    })

export const getPaymentsUploads = ({ page, search, filters, sorter }) =>
  apiInstance
    .get(
      `/${RELATIONSHIPS_NAMES.admin}/${RELATIONSHIPS_NAMES.payments}/uploads`,
      {
        params: {
          filter: {
            search: search ? encodeURIComponent(search) : null,
            ...filters,
          },
          sort: sorter,
          page: {
            number: page,
            size: 20,
          },
        },
      }
    )
    .catch((error) => {
      parseError(error, 'Cannot get payments uploads')
    })

export const uploadPaymentList = (data) =>
  apiInstance
    .post(
      `/${RELATIONSHIPS_NAMES.admin}/${RELATIONSHIPS_NAMES.payments}/upload_payments`,
      {
        data,
      }
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Payments haven't been uploaded")
    })

export const deletePayment = (id) =>
  apiInstance
    .delete(`/${RELATIONSHIPS_NAMES.admin}/${ENTITY_NAMES.payments}/${id}`)
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Payment hasn't been deleted")
    })

export const rejectPayment = (id) =>
  apiInstance
    .patch(
      `/${RELATIONSHIPS_NAMES.admin}/${ENTITY_NAMES.payments}/${id}/reject`
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Payment hasn't been rejected")
    })

export const retractPayment = (id) =>
  apiInstance
    .patch(
      `/${RELATIONSHIPS_NAMES.admin}/${ENTITY_NAMES.payments}/${id}/retract`
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Payment hasn't been retracted")
    })

export const updatePayment = ({ id, data }) =>
  apiInstance
    .patch(
      `/${RELATIONSHIPS_NAMES.admin}/${ENTITY_NAMES.payments}/${id}`,
      { data },
      { headers: { 'Content-Type': 'application/vnd.api+json' } }
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Payment hasn't been updated")
    })

export const createPayment = (data) =>
  apiInstance
    .post(
      `/${RELATIONSHIPS_NAMES.admin}/${ENTITY_NAMES.payments}/single_payment`,
      { data },
      { headers: { 'Content-Type': 'application/vnd.api+json' } }
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Payment hasn't been updated")
    })

export const approvePayment = (id) =>
  apiInstance
    .patch(
      `/${RELATIONSHIPS_NAMES.admin}/${ENTITY_NAMES.payments}/${id}/approve`
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Payment hasn't been approved")
    })

export const resendPayment = (id) =>
  apiInstance
    .patch(
      `/${RELATIONSHIPS_NAMES.admin}/${ENTITY_NAMES.payments}/${id}/resend`
    )
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(error, "Payment hasn't been resent")
    })
