import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { updateShop } from 'actions/views/shops'
import useAction from 'utils/useAction'
import arrayMutators from 'final-form-arrays'
import { Spin, Select as AntdSelect } from 'antd'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/Input'
import { FIELD_NAMES } from 'const/forms'
import { selectors as shopsSelectors } from 'domains/shops'
import {
  ENRICHED_CATEGORIES,
  EXCLUSIVE_ENRICHED_CATEGORIES,
  GENDER_OPTIONS,
} from 'const/shops'
import InputNumber from 'components/Input/InputNumber'
import Select from 'components/Select/Select'
import {
  CategoryGroup,
  MainCategoryTag,
  SelectedCategoriesList,
  StyledButton,
  StyledForm,
  StyledSelect,
  SubCategoryItem,
  SubCategoryList,
} from './ShopEnrichedData.styles'
import flatMap from 'lodash/flatMap'
import {
  getCurrentShopAttributes,
  getCurrentShopId,
} from 'domains/shops/selectors'

const { Option } = AntdSelect

const flattenCategories = (categories) => {
  return flatMap(categories, (subCategories, mainCategory) => {
    return [mainCategory, ...subCategories]
  })
}

const ShopEnrichedData = ({ closeModal }) => {
  const isSavingActive = useSelector(shopsSelectors.getIsSavingActive)
  const updateShopFunc = useAction(updateShop)
  const attributes = useSelector(getCurrentShopAttributes)
  const shopId = useSelector(getCurrentShopId)

  const [selectedCategories, setSelectedCategories] = useState(
    attributes?.categories || {}
  )

  const parsedInitialValues = useMemo(
    () => ({
      [FIELD_NAMES.description]: attributes?.[FIELD_NAMES.description],
      [FIELD_NAMES.gender]: attributes?.[FIELD_NAMES.gender],
      [FIELD_NAMES.minAge]: attributes?.[FIELD_NAMES.minAge],
      [FIELD_NAMES.maxAge]: attributes?.[FIELD_NAMES.maxAge],
      [FIELD_NAMES.shopId]: attributes?.[FIELD_NAMES.shopId],
      [FIELD_NAMES.categories]: flattenCategories(selectedCategories),
    }),
    []
  )

  const onSubmit = (data) => {
    const submittedData = { ...data, [FIELD_NAMES.id]: shopId }
    delete submittedData[FIELD_NAMES.categories]

    if (Object.keys(selectedCategories).length > 0) {
      submittedData[FIELD_NAMES.shivaCategories] = selectedCategories
    }

    updateShopFunc({ values: submittedData, callback: closeModal })
  }

  const handleOnSelect = (value) => {
    const mainCategories = Object.keys(ENRICHED_CATEGORIES)
    const newSelectedCategories = { ...selectedCategories }

    if (EXCLUSIVE_ENRICHED_CATEGORIES.includes(value)) {
      setSelectedCategories({ [value]: [] })
      return
    }

    const isMainCategory = mainCategories.includes(value)

    if (isMainCategory) {
      newSelectedCategories[value] = []
    } else {
      const mainCategory = mainCategories.find((category) =>
        ENRICHED_CATEGORIES[category].includes(value)
      )
      newSelectedCategories[mainCategory] = [
        ...(newSelectedCategories[mainCategory] || []),
        value,
      ]
    }

    setSelectedCategories(newSelectedCategories)
  }

  const handleOnDeselect = (value) => {
    const mainCategories = Object.keys(ENRICHED_CATEGORIES)
    const newSelectedCategories = { ...selectedCategories }

    const isMainCategory = mainCategories.includes(value)

    if (isMainCategory) {
      delete newSelectedCategories[value]
    } else {
      const mainCategory = mainCategories.find((category) =>
        newSelectedCategories[category]?.includes(value)
      )
      newSelectedCategories[mainCategory] = newSelectedCategories[
        mainCategory
      ].filter((subCategory) => subCategory !== value)
    }

    setSelectedCategories(newSelectedCategories)
  }

  return (
    <Form
      destroyOnUnregister
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={parsedInitialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <Spin spinning={isSavingActive} size="large">
            <StyledForm onSubmit={handleSubmit}>
              <Field
                name={FIELD_NAMES.description}
                label="Description"
                component={Input}
              />
              <Field
                name={FIELD_NAMES.gender}
                label="Gender"
                component={Select}
                options={GENDER_OPTIONS}
              />
              <Field
                name={FIELD_NAMES.minAge}
                label="Min age"
                component={InputNumber}
                min={0}
              />
              <Field
                name={FIELD_NAMES.maxAge}
                label="Max age"
                component={InputNumber}
                min={0}
              />

              <Field name={FIELD_NAMES.categories}>
                {({ input }) => (
                  <>
                    <StyledSelect
                      {...input}
                      mode="multiple"
                      placeholder="Select categories and subcategories"
                      onSelect={handleOnSelect}
                      onDeselect={handleOnDeselect}
                      value={flattenCategories(selectedCategories)}
                      showArrow
                      dropdownRender={(menu) => <>{menu}</>}
                      tagRender={() => null}
                    >
                      {Object.keys(ENRICHED_CATEGORIES).map((category) => (
                        <React.Fragment key={category}>
                          <Option
                            key={category}
                            value={category}
                            style={{
                              fontWeight: '500',
                              color: '#1f1f1f',
                              cursor: 'pointer',
                            }}
                          >
                            {category}
                          </Option>
                          {ENRICHED_CATEGORIES[category].map((subCategory) => (
                            <Option
                              key={subCategory}
                              value={subCategory}
                              style={{
                                color: '#595959',
                                paddingLeft: 24,
                                cursor: 'pointer',
                              }}
                            >
                              • {subCategory}
                            </Option>
                          ))}
                        </React.Fragment>
                      ))}
                    </StyledSelect>

                    <SelectedCategoriesList>
                      {Object.keys(ENRICHED_CATEGORIES).map((category) => {
                        const selectedSubCategories =
                          selectedCategories[category]

                        if (selectedSubCategories) {
                          return (
                            <CategoryGroup key={category}>
                              <MainCategoryTag>{category}</MainCategoryTag>
                              {selectedSubCategories.length > 0 && (
                                <SubCategoryList>
                                  {selectedSubCategories.map((subCategory) => (
                                    <SubCategoryItem key={subCategory}>
                                      {subCategory}
                                    </SubCategoryItem>
                                  ))}
                                </SubCategoryList>
                              )}
                            </CategoryGroup>
                          )
                        }
                        return null
                      })}
                    </SelectedCategoriesList>
                  </>
                )}
              </Field>

              <StyledButton
                type="primary"
                disabled={pristine}
                onClick={handleSubmit}
              >
                Save changes
              </StyledButton>
            </StyledForm>
          </Spin>
        )
      }}
    />
  )
}

export default ShopEnrichedData
