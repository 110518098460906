import React from 'react'
import RadioGroup from './index'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'

const GroupField = ({ input, meta, required, ...rest }) => (
  <FieldWrapper hint={rest.hint} label={rest.label} required={required}>
    <RadioGroup input={input} meta={meta} {...rest} />
  </FieldWrapper>
)

export default GroupField
