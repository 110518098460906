import React from 'react'
import { useSelector } from 'react-redux'
import {
  getIsLoaded,
  getEntities,
  getPageCount,
  getPage,
  getFilters,
} from 'domains/bonuses/selectors'
import useAction from 'utils/useAction'
import { deleteBonus, fetchBonuses } from 'actions/views/bonuses'
import { StyledTable, Wrapper, Buttons } from './Bonuses.styles'
import { bonusesColumns } from './columns'
import { getRegularRowClass, getRowKey } from 'helpers/tables'
import { FIELD_NAMES } from 'const/forms'
import { PAGE_SIZE } from 'const/tables'
import { openConfirmModal, openBonusModal } from 'reducers/modals/actions'
import { Button } from 'antd'

const Bonuses = () => {
  const isLoaded = useSelector(getIsLoaded)
  const dataSource = useSelector(getEntities)
  const pageCount = useSelector(getPageCount)
  const page = useSelector(getPage)
  const filters = useSelector(getFilters)

  const deleteBonusFunc = useAction(deleteBonus)
  const openConfirmModalFunc = useAction(openConfirmModal)
  const openBonusModalFunc = useAction(openBonusModal)
  const fetchBonusesFunc = useAction(fetchBonuses)

  const handleEditBonus = (id) => openBonusModalFunc({ id })
  const handleCreateBonus = () => openBonusModalFunc()

  const handleDeleteBonus = (bonus) => {
    const callback = () => deleteBonusFunc(bonus[FIELD_NAMES.id])
    const text =
      `Are you sure you want to delete bonus ${
        bonus[FIELD_NAMES.attributes][FIELD_NAMES.slug]
      } ` + `(ID: ${bonus[FIELD_NAMES.id]})? This action can't be revoked.`
    openConfirmModalFunc({ callback, text })
  }

  const onTableChange = (pagination, newFilters) => {
    fetchBonusesFunc({
      filters: newFilters,
      page: pagination.current,
    })
  }

  const handleResetFilters = () =>
    fetchBonusesFunc({
      filters: {},
      page: 1,
    })

  const paginationConfig = {
    position: ['bottomCenter'],
    showQuickJumper: true,
    total: pageCount * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
  }

  return (
    <Wrapper>
      <Buttons>
        <Button onClick={handleCreateBonus} type="primary" disabled={!isLoaded}>
          Create
        </Button>
        <Button onClick={handleResetFilters} disabled={!isLoaded}>
          Reset filters
        </Button>
      </Buttons>

      <StyledTable
        onChange={onTableChange}
        showSorterTooltip={false}
        columns={bonusesColumns(filters, handleEditBonus, handleDeleteBonus)}
        dataSource={dataSource.length ? dataSource : []}
        expandable={false}
        bordered
        loading={false}
        rowKey={getRowKey}
        rowClassName={getRegularRowClass}
        pagination={paginationConfig}
      />
    </Wrapper>
  )
}

export default Bonuses
