import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Spin, Upload } from 'antd'
import { Text, UploadingIcon, Wrapper } from './DNDFilePicker.styles'
import { getBase64 } from 'helpers/files'

const { Dragger } = Upload

const DNDFilePicker = ({ onUpload, accept }) => {
  const [loading, setLoading] = useState(false)
  const [fileList, setFileList] = useState([])

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const handleUpload = (file) =>
    getBase64(file.originFileObj, (imageUrl) => {
      onUpload(imageUrl)
      setLoading(false)
    })

  const handleChange = ({ file }) => {
    setLoading(true)

    switch (file.status) {
      case 'uploading':
        break
      case 'done':
        handleUpload(file)
        break
      default:
        setFileList([])
        setLoading(false)
    }
  }

  const props = {
    name: 'file',
    multiple: true,
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
    accept,
    defaultFileList: [],
    customRequest: dummyRequest,
    beforeUpload: () => {},
    onChange: handleChange,
    fileList: fileList,
    maxCount: 1,
  }

  return (
    <Dragger {...props}>
      {loading ? (
        <Spin size="small" spinning={loading} />
      ) : (
        <Wrapper>
          <UploadingIcon />
          <Text>Click or drag file to this area to upload user payments</Text>
        </Wrapper>
      )}
    </Dragger>
  )
}

DNDFilePicker.propTypes = {
  onUpload: PropTypes.func.isRequired,
  accept: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default DNDFilePicker
