import { all, call, put, takeLatest } from 'redux-saga/effects'
import normalized from 'json-api-normalizer'
import * as actions from './actions'
import * as api from './api'
import { getSortedIds } from 'helpers/jsonAPI'

function* fetchLandingPagesWorker({ payload }) {
  try {
    const { data } = yield call(api.getLandingPages, payload)

    const normalizedData = normalized(data, {
      camelizeKeys: false,
      camelizeTypeValues: false,
    })

    yield put(
      actions.fetchLandingPagesSuccess({
        ...payload,
        page: payload.page,
        pageCount: data.meta.page_count,
        landing_pages: normalizedData,
        sortedIds: getSortedIds(data.data),
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.fetchLandingPagesFailure())
  }
}

function* createLandingPageWorker({ payload }) {
  try {
    const { data } = yield call(api.createLandingPage, payload)
    if (data) {
      yield put(actions.createLandingPageSuccess(data))
    } else {
      yield put(actions.createLandingPageFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createLandingPageFailure())
  }
}

function* updateLandingPageWorker({ payload }) {
  try {
    const { data } = yield call(api.updateLandingPage, payload)
    if (data) {
      yield put(actions.updateLandingPageSuccess(payload))
    } else {
      yield put(actions.updateLandingPageFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateLandingPageFailure())
  }
}

function* deleteLandingPageWorker({ payload }) {
  try {
    const { data } = yield call(api.deleteLandingPage, payload)
    if (data) {
      yield put(actions.deleteLandingPageSuccess(payload))
    } else {
      yield put(actions.deleteLandingPageFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteLandingPageFailure())
  }
}

export default function* () {
  yield all([
    takeLatest(actions.fetchLandingPages, fetchLandingPagesWorker),
    takeLatest(actions.createLandingPage, createLandingPageWorker),
    takeLatest(actions.updateLandingPage, updateLandingPageWorker),
    takeLatest(actions.deleteLandingPage, deleteLandingPageWorker),
  ])
}
