import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Content, DNDIcon } from './ListSort.styles'
import { groupPropType } from 'const/propTypes'

const ListItem = ({ data, onClick, isSelected, index }) => (
  <Wrapper isSelected={isSelected}>
    <Content role="button" onClick={() => onClick(data.attributes.name)}>
      {index}. {data.attributes.name}
    </Content>
    <DNDIcon />
  </Wrapper>
)

ListItem.propTypes = {
  data: groupPropType,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
}

export default ListItem
