import reducer from './reducer'
import { createAction } from '@reduxjs/toolkit'

export const {
  login,
  setToken,
  clearData,
  fetchAllAdminsSuccess,
  fetchAllAdminsFailure,
  saveAdminFiltersAndSorter,
  startSaving,
  finishSaving,
} = reducer.actions

export const updatePaymentSettings = createAction('admin/updatePaymentSettings')
export const updatePaymentSettingsSuccess = createAction(
  'admin/updatePaymentSettingsSuccess'
)
export const updatePaymentSettingsFailure = createAction(
  'admin/updatePaymentSettingsFailure'
)

export const fetchAdminData = createAction('admin/fetchAdminData')
export const adminLogin = createAction('admin/adminLogin')
export const adminLogout = createAction('admin/adminLogout')

export const deleteAdmin = createAction('admin/deleteAdmin')
export const deleteAdminSuccess = createAction('admin/deleteAdminSuccess')
export const deleteAdminFailure = createAction('admin/deleteAdminFailure')

export const fetchAllAdmins = createAction('admin/fetchAllAdmins')

export const updateAdmin = createAction('admin/updateAdmin')
export const updateAdminSuccess = createAction('admin/updateAdminSuccess')
export const updateAdminFailure = createAction('admin/updateAdminFailure')

export const createAdmin = createAction('admin/createAdmin')
export const createAdminSuccess = createAction('admin/createAdminSuccess')
export const createAdminFailure = createAction('admin/createAdminFailure')

export const resetPassword = createAction('admin/resetPassword')
export const resetPasswordSuccess = createAction('admin/resetPasswordSuccess')
export const resetPasswordFailure = createAction('admin/resetPasswordFailure')

export const sendResetPasswordEmail = createAction(
  'admin/sendResetPasswordEmail'
)
export const sendResetPasswordEmailSuccess = createAction(
  'admin/sendResetPasswordEmailSuccess'
)
export const sendResetPasswordEmailFailure = createAction(
  'admin/sendResetPasswordEmailFailure'
)

export const changePassword = createAction('admin/changePassword')
export const changePasswordSuccess = createAction('admin/changePasswordSuccess')
export const changePasswordFailure = createAction('admin/changePasswordFailure')
