import { all, put, race, select, take, takeLatest } from 'redux-saga/effects'
import * as actions from 'actions/views/settings'
import {
  actions as generalActions,
  selectors as generalSelectors,
} from 'domains/general'
import { finishLoading, startLoading } from 'reducers/layoutSlice'
import { message } from 'antd'
import { RELATIONSHIPS_NAMES } from 'const/api'

function* createCategoryWorker({ payload }) {
  try {
    const name = payload.name.trim()
    const currentNames = yield select(generalSelectors.categoryNames)
    if (name && !currentNames.includes(name)) {
      const body = {
        type: RELATIONSHIPS_NAMES.categories,
        attributes: {
          name,
        },
      }
      yield all([put(startLoading()), put(generalActions.createCategory(body))])
      const [success] = yield race([
        take(generalActions.createCategorySuccess),
        take(generalActions.createCategoryFailure),
      ])

      if (success) {
        message.success('Category has been created')
        if (payload.callback) {
          payload.callback()
        }
      }
    } else {
      message.error('You must enter valid name or name already exist...')
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* editCategoryWorker({ payload }) {
  try {
    const name = payload.name.trim()
    const currentNames = yield select(generalSelectors.categoryNames)
    if (name && !currentNames.includes(name)) {
      const body = {
        id: payload.id,
        type: RELATIONSHIPS_NAMES.categories,
        attributes: {
          name,
        },
      }
      yield all([
        put(startLoading()),
        put(
          generalActions.editCategory({
            data: body,
            id: payload.id,
          })
        ),
      ])
      const [success] = yield race([
        take(generalActions.editCategorySuccess),
        take(generalActions.editCategoryFailure),
      ])

      if (success) {
        message.success(`Category has been updated`)
        if (payload.callback) {
          payload.callback()
        }
      }
    } else {
      message.error('You must enter valid name or name already exist...')
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* deleteCategoryWorker({ payload }) {
  try {
    yield all([
      put(startLoading()),
      put(generalActions.deleteCategory(payload)),
    ])
    const [success] = yield race([
      take(generalActions.deleteCategorySuccess),
      take(generalActions.deleteCategoryFailure),
    ])

    if (success) {
      message.success(`Category has been deleted`)
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* fetchMarketingCopyWorker() {
  try {
    yield all([put(startLoading()), put(generalActions.fetchMarketingCopy())])
    yield take([
      generalActions.fetchMarketingCopySuccess,
      generalActions.fetchMarketingCopyFailure,
    ])
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

function* updateMarketingCopyWorker({ payload }) {
  try {
    if (payload) {
      yield all([
        put(startLoading()),
        put(
          generalActions.updateMarketingCopy({
            data: { payload },
          })
        ),
      ])
      const [success] = yield race([
        take(generalActions.updateMarketingCopySuccess),
        take(generalActions.updateMarketingCopyFailure),
      ])

      if (success) {
        message.success(`Copy has been updated`)
        if (payload.callback) {
          payload.callback()
        }
      }
    } else {
      message.error('You must enter a valid copy.')
    }
  } catch (error) {
    console.error(error)
  } finally {
    yield put(finishLoading())
  }
}

export default function* () {
  yield all([
    fetchMarketingCopyWorker(),
    takeLatest(actions.createCategory, createCategoryWorker),
    takeLatest(actions.editCategory, editCategoryWorker),
    takeLatest(actions.deleteCategory, deleteCategoryWorker),
    takeLatest(actions.getMarketingCopy, fetchMarketingCopyWorker),
    takeLatest(actions.updateMarketingCopy, updateMarketingCopyWorker),
  ])
}
