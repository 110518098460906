export const PAYMENT_STATUSES = {
  PENDING: 'pending',
  PAID: 'paid',
  REJECTED: 'rejected',
  RETRACTED: 'retracted',
}

export const PAYMENT_STATES_FILTERS = [
  { text: 'Pending', value: PAYMENT_STATUSES.PENDING },
  { text: 'Paid', value: PAYMENT_STATUSES.PAID },
  { text: 'Rejected', value: PAYMENT_STATUSES.REJECTED },
  { text: 'Retracted', value: PAYMENT_STATUSES.RETRACTED },
]

export const ATTEMPT_STATUS_FILTERS = [
  { text: 'Success', value: 'success' },
  { text: 'Failure', value: 'failure' },
  { text: 'Warning', value: 'warning' },
]

export const OVER_THE_BUDGET_ERROR = 'budget exhausted'

export const MAX_SINGLE_PAYMENT_AMOUNT = 1000

export const DEFAULT_PAYMENT_NAME = 'Karma Cash'
