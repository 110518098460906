import { apiInstance } from 'utils/request'
import { ENTITY_NAMES } from 'const/api'
import { FIELD_NAMES } from 'const/forms'
import parseError from 'helpers/parseServerError'

export const getSEOPages = ({ page, filters, sorter, search }) =>
  apiInstance
    .get(`/${ENTITY_NAMES.SEOPages}`, {
      params: {
        filter: {
          search: search ? encodeURIComponent(search) : null,
          ...filters,
        },
        sort: sorter || FIELD_NAMES.id,
        page: {
          number: page,
          size: 20,
        },
      },
    })
    .catch((error) => {
      parseError(error, 'Cannot get SEO pages')
    })

export const createSEOPage = (data) =>
  apiInstance
    .post(`/${ENTITY_NAMES.SEOPages}`, {
      data,
    })
    .catch((error) => {
      parseError(error, "SEO page hasn't been created")
    })

export const updateSEOPage = ({ data, updateImage }) =>
  apiInstance
    .patch(`/${ENTITY_NAMES.SEOPages}/${data.id}`, {
      data,
    })
    .then(() => ({ data: 'OK' }))
    .catch((error) => {
      parseError(
        error,
        updateImage
          ? "SEO page images hasn't been updated"
          : "SEO page hasn't been updated"
      )
    })
