import React, { useState } from 'react'
import { Field } from 'react-final-form'
import { Upload, Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'

const FeatureConfUpload = ({ name }) => {
  const [fileList, setFileList] = useState(null)

  return (
    <FieldWrapper label="Users upload">
      <Field name={`${name}.feature_conf_file`}>
        {({ input }) => (
          <Upload
            multiple={false}
            onChange={({ file }) => {
              if (file.status === 'removed') {
                setFileList([])
                input.onChange(null)
              } else {
                setFileList([file])
                const reader = new FileReader()
                reader.readAsDataURL(file)

                reader.onload = () => {
                  input.onChange(reader.result)
                }
              }
            }}
            fileList={
              fileList ||
              (input.value
                ? [
                    {
                      uid: '1',
                      status: 'done',
                      response: '200',
                      name: input.value,
                      url: input.value,
                    },
                  ]
                : [])
            }
            beforeUpload={() => false}
          >
            <Button>
              <UploadOutlined /> Click to Upload
            </Button>
          </Upload>
        )}
      </Field>
    </FieldWrapper>
  )
}

export default FeatureConfUpload
