import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { createCoupon, updateCoupon } from 'actions/views/coupon'
import useAction from 'utils/useAction'
import arrayMutators from 'final-form-arrays'
import { Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/Input'
import DatePicker from 'components/DatePicker/DatePicker'
import { FIELD_NAMES } from 'const/forms'
import {
  required,
  requiredCouponsRule,
  requiredCouponsRetailer,
  validProductLink,
  validExclusivePriority,
} from 'helpers/validators'
import { selectors as CouponsSelectors } from 'domains/coupons'
import { StyledButton, StyledForm } from './Coupon.styles'
import InputNumber from 'components/Input/InputNumber'
import CustomRangePicker from '../AdPackage/CustomRangePicker'
import RadioGroup from 'components/RadioGroup/GroupField'
import { EXCLUSIVE_STATUS, OFFER_TYPES } from 'const/coupons'
import { RELATIONSHIPS_NAMES } from 'const/api'
import SearchSelect from 'components/SearchSelect/WrappedField'
import Select from 'components/Select/Select'
import TextArea from 'components/TextArea/TextArea'
import { setSearch } from '../../../domains/search/actions'
import { setPage } from '../../../domains/search/actions'

const autoSize = { minRows: 3, maxRows: 3 }
const Coupon = ({ id, closeModal }) => {
  const isSavingActive = useSelector(CouponsSelectors.getIsSavingActive)
  const initialValues = useSelector(CouponsSelectors.getCouponById)(id)
  const createCouponFunc = useAction(createCoupon)
  const updateCouponFunc = useAction(updateCoupon)
  const setSearchFunc = useAction(setSearch)
  const setPageFunc = useAction(setPage)

  const onSubmit = (data) => {
    const values = {
      ...data,
    }
    if (id) {
      updateCouponFunc({ values, callback: closeModal })
    } else {
      createCouponFunc({ values, callback: closeModal })
    }
  }

  useEffect(() => {
    if (initialValues?.[FIELD_NAMES.ruleId]) {
      setSearchFunc({
        value: initialValues?.[FIELD_NAMES.ruleId],
        key: RELATIONSHIPS_NAMES.rules,
      })
    }
    if (initialValues?.[FIELD_NAMES.mainRetailerId]) {
      setSearchFunc({
        value: initialValues?.[FIELD_NAMES.mainRetailerId],
        key: RELATIONSHIPS_NAMES.main,
      })
    }
  }, [])

  return (
    <Form
      destroyOnUnregister
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value)
        },
      }}
      initialValues={
        initialValues || {
          [FIELD_NAMES.exclusive]: false,
          [FIELD_NAMES.offerType]: OFFER_TYPES[0].value,
          [FIELD_NAMES.rate]: 0,
        }
      }
      render={({ values, handleSubmit, pristine, form }) => {
        const isOfferTypeCoupons =
          values[FIELD_NAMES.offerType] === OFFER_TYPES[0].value

        return (
          <Spin spinning={isSavingActive} size="large">
            <StyledForm onSubmit={handleSubmit}>
              {id && (
                <Field
                  name={FIELD_NAMES.id}
                  label="ID"
                  disabled
                  component={Input}
                />
              )}
              <Field
                required
                name={FIELD_NAMES.offerType}
                validate={required}
                options={OFFER_TYPES}
                label="Offer type"
                component={RadioGroup}
              />
              <Field
                required
                name={FIELD_NAMES.code}
                validate={required}
                label="Code"
                placeholder="Code"
                component={Input}
              />
              {isOfferTypeCoupons && (
                <Field
                  required
                  defaultValue={false}
                  name={FIELD_NAMES.exclusive}
                  options={EXCLUSIVE_STATUS}
                  label="Karma exclusive"
                  component={Select}
                />
              )}
              {isOfferTypeCoupons && (
                <Field
                  name={FIELD_NAMES.temporaryPriorityUntil}
                  label="Temporary priority until"
                  component={DatePicker}
                />
              )}
              <Field
                name={FIELD_NAMES.description}
                label="Description"
                placeholder="Description"
                component={Input}
              />
              {isOfferTypeCoupons && (
                <Field
                  name={FIELD_NAMES.priority}
                  label="Priority"
                  component={Input}
                />
              )}
              <CustomRangePicker
                keys={[FIELD_NAMES.startedAt, FIELD_NAMES.expiredAt]}
                placeholder={['Start Date', 'Expiration Date']}
              />
              <Field
                required
                name={FIELD_NAMES.rate}
                min={0}
                max={1}
                step={0.1}
                label="Rate"
                placeholder="Rate"
                component={InputNumber}
              />
              <Field
                validate={requiredCouponsRetailer}
                label="Retailer"
                component={SearchSelect}
                name={FIELD_NAMES.mainRetailerId}
                entity={RELATIONSHIPS_NAMES.main}
                callback={(retailer) => {
                  setPageFunc({ key: FIELD_NAMES.ruleId, value: 1 })
                  form.mutators.setValue(
                    FIELD_NAMES.ruleId,
                    retailer?.attributes?.[FIELD_NAMES.ruleId]
                  )
                }}
              />
              <Field
                required
                validate={requiredCouponsRule}
                label="Rule"
                entity={RELATIONSHIPS_NAMES.rules}
                component={SearchSelect}
                callback={() =>
                  form.mutators.setValue(FIELD_NAMES.mainRetailerId, null)
                }
                name={FIELD_NAMES.ruleId}
              />
              <Field
                label="Exclusive Priority"
                component={InputNumber}
                type="number"
                min={1}
                max={50}
                name={FIELD_NAMES.exclusivePriority}
                validate={validExclusivePriority}
              />
              {isOfferTypeCoupons && (
                <Fragment>
                  <Field
                    name={FIELD_NAMES.geos}
                    label="Geo's"
                    component={Input}
                  />
                  <Field
                    name={FIELD_NAMES.url}
                    label="URL"
                    component={Input}
                    validate={validProductLink}
                  />
                  <Field
                    name={FIELD_NAMES.salesRep}
                    label="Sales Rep"
                    component={TextArea}
                  />
                  <Field
                    name={FIELD_NAMES.comments}
                    label="Comments"
                    component={TextArea}
                    autoSize={autoSize}
                  />
                </Fragment>
              )}
              <StyledButton
                type="primary"
                disabled={pristine}
                onClick={handleSubmit}
              >
                Save changes
              </StyledButton>
            </StyledForm>
          </Spin>
        )
      }}
    />
  )
}

Coupon.propTypes = {
  id: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
}

Coupon.defaultTypes = {
  id: null,
}

export default Coupon
