import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import * as actions from './actions'
import * as helpers from './helpers'
import * as formatters from './formatters'
import { push } from 'connected-react-router'
import { actions as retailersActions } from '../retailers'
import { actions as generalActions } from '../general'
import normalized from 'json-api-normalizer'
import { getSortedIds } from 'helpers/jsonAPI'
import { ENTITY_NAMES } from 'const/api'
import { message } from 'antd'
import ROUTES from 'const/routes'

function* handleServerError({ status }) {
  if (status === 401) {
    yield put(actions.clearData())
    helpers.clearUserDataInLS()
    yield put(push(ROUTES.LOGIN))
  }
}

function* fetchAllAdminsWorker({ payload }) {
  try {
    const { data } = yield call(api.getAllAdmins, payload)
    const normalizedData = normalized(data, {
      camelizeKeys: false,
      camelizeTypeValues: false,
    })

    if (data) {
      yield put(
        actions.fetchAllAdminsSuccess({
          ...payload,
          page: payload.page,
          pageCount: data.meta.page_count,
          data: normalizedData?.[ENTITY_NAMES.backofficeUsers],
          sortedIds: getSortedIds(data.data),
        })
      )
    } else {
      yield put(actions.fetchAllAdminsFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.fetchAllAdminsFailure())
  }
}

function* updatePaymentSettingsWorker({ payload }) {
  try {
    const { data } = yield call(api.updatePaymentSettings, payload)
    if (data) {
      yield put(actions.updatePaymentSettingsSuccess(data))
    } else {
      yield put(actions.updatePaymentSettingsFailure())
    }
  } catch (error) {
    yield put(actions.updatePaymentSettingsFailure())
    console.error(error)
  }
}

function* fetchAdminDataWorker({ payload }) {
  try {
    const { data, error } = yield call(api.getAdminData, payload)
    if (data) {
      yield put(actions.login(formatters.formatAdminData(data)))
      helpers.loginHandler(data)
    } else {
      yield call(handleServerError, error)
    }
  } catch (error) {
    console.error(error)
  }
}

function* deleteAdminWorker({ payload }) {
  try {
    const { data } = yield call(api.deleteAdmin, payload)
    if (data) {
      yield put(actions.deleteAdminSuccess(payload))
    } else {
      yield put(actions.deleteAdminFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.deleteAdminFailure())
  }
}

function* adminLoginWorker({ payload }) {
  try {
    const response = yield call(api.login, payload)

    api.getCookie({ username: payload.email, password: payload.password })

    if (response.data) {
      const token = response.headers.authorization.trim().split(' ')[1]
      const admin = {
        ...response.data.data,
        type: response.data.data.attributes.name,
        token,
      }

      yield put(
        actions.login({
          ...admin,
          ...formatters.formatAdminData(response.data),
        })
      )
      helpers.loginHandler(admin)
      yield all([
        put(actions.fetchAdminData(admin.id)),
        put(retailersActions.fetchMainRetailers()),
      ])
      if (admin.type) {
        yield all([
          put(generalActions.fetchGroups()),
          put(generalActions.fetchCategories()),
        ])
      }
      yield put(push(ROUTES.HOME))
    }
  } catch (error) {
    console.error(error)
  }
}

function* adminLogoutWorker() {
  try {
    yield all([call(api.logout), helpers.clearUserDataInLS])
    yield put(actions.clearData())
    helpers.clearUserDataInLS()
    yield put(push(ROUTES.LOGIN))
  } catch (error) {
    console.error(error)
  }
}

function* resetPasswordWorker({ payload }) {
  try {
    const { data } = yield call(api.resetPassword, payload)
    if (data) {
      yield put(actions.resetPasswordSuccess(payload))
    } else {
      yield put(actions.resetPasswordFailure())
    }
  } catch (error) {
    yield put(actions.resetPasswordFailure())
    console.error(error)
  }
}

function* updateAdminWorker({ payload }) {
  try {
    const { data } = yield call(api.updateAdmin, payload)
    if (data) {
      yield put(actions.updateAdminSuccess(payload))
    } else {
      yield put(actions.updateAdminFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.updateAdminFailure())
  }
}

function* createAdminWorker({ payload }) {
  try {
    const { data } = yield call(api.createAdmin, payload)
    if (data) {
      yield put(actions.createAdminSuccess(payload))
    } else {
      yield put(actions.createAdminFailure())
    }
  } catch (error) {
    console.error(error)
    yield put(actions.createAdminFailure())
  }
}

function* sendResetPasswordEmail({ payload }) {
  try {
    const { data } = yield call(api.sendResetPasswordEmail, payload)
    if (data) {
      yield put(actions.sendResetPasswordEmailSuccess(payload))
      message.success('Reset password details were sent to your email')
    } else {
      yield put(actions.sendResetPasswordEmailFailure())
    }
  } catch (error) {
    yield put(actions.sendResetPasswordEmailFailure())
    console.error(error)
  }
}

function* changePassword({ payload }) {
  try {
    const { data } = yield call(api.changePassword, payload)
    if (data) {
      yield put(actions.changePasswordSuccess(payload))
      message.success('Password updated successfully')
    } else {
      yield put(actions.changePasswordFailure())
    }
  } catch (error) {
    yield put(actions.changePasswordFailure())
    console.error(error)
  }
}

export default function* () {
  yield all([
    takeEvery(actions.updatePaymentSettings, updatePaymentSettingsWorker),
    takeEvery(actions.fetchAdminData, fetchAdminDataWorker),
    takeEvery(actions.adminLogin, adminLoginWorker),
    takeEvery(actions.adminLogout, adminLogoutWorker),
    takeEvery(actions.deleteAdmin, deleteAdminWorker),
    takeEvery(actions.updateAdmin, updateAdminWorker),
    takeEvery(actions.createAdmin, createAdminWorker),
    takeEvery(actions.fetchAllAdmins, fetchAllAdminsWorker),
    takeEvery(actions.resetPassword, resetPasswordWorker),
    takeEvery(actions.sendResetPasswordEmail, sendResetPasswordEmail),
    takeEvery(actions.changePassword, changePassword),
  ])
}
