import React from 'react'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/InputPassword'
import { StyledForm } from './ChangePassword.styles'
import { FIELD_NAMES } from 'const/forms'
import useAction from 'utils/useAction'
import { actions as adminActions } from 'domains/admin'
import { selectors as adminSelectors } from 'domains/admin'
import { Button } from 'antd'
import { openConfirmModal } from 'reducers/modals/actions'
import { useSelector } from 'react-redux'

const ChangePassword = ({ closeModal }) => {
  const changePasswordFunc = useAction(adminActions.changePassword)
  const resetPasswordFunc = useAction(adminActions.sendResetPasswordEmail)
  const userData = useSelector(adminSelectors.adminData)
  const openConfirmModalFunc = useAction(openConfirmModal)

  const handleSubmit = (values) => {
    changePasswordFunc({ values, callback: closeModal })
  }

  const handleResetPasswordClick = () => {
    const callback = () => {
      resetPasswordFunc(userData?.attributes?.[FIELD_NAMES.email])
      closeModal()
    }
    const text = 'Are you sure you want to reset password?'
    openConfirmModalFunc({ callback, text })
  }

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <StyledForm onSubmit={handleSubmit}>
            <Field
              name={FIELD_NAMES.currentPassword}
              required
              label="Current password"
              component={Input}
            />
            <Field
              name={FIELD_NAMES.newPassword}
              required
              label="New password"
              component={Input}
            />
            <Field
              name={FIELD_NAMES.confirmCurrentPassword}
              required
              label="Confirm new password"
              component={Input}
            />
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </StyledForm>
        )}
      />
      <Button
        type="link"
        onClick={handleResetPasswordClick}
        style={{ width: '100%' }}
      >
        Send reset password email
      </Button>
    </>
  )
}

export default ChangePassword
