import ShopGroupSwitch from 'components/ShopGroupSwitch/ShopGroupSwitch'
import React from 'react'
import { getGroupIdsAndNames } from 'domains/general/selectors'
import { useSelector } from 'react-redux'
import { getCurrentShopGroupsAttributesAndId } from 'domains/shops/selectors'
import { ShopGroupsSwitchesContainer } from './ShopGroupsSwitches.styles'
import useAction from 'utils/useAction'
import {
  createShopGroup,
  deleteShopGroup,
  updateShopGroup,
} from 'actions/views/shops'

const ShopGroupsSwitches = () => {
  const shopGroupsAttributes = useSelector(getCurrentShopGroupsAttributesAndId)
  const groups = useSelector(getGroupIdsAndNames)

  const createShopGroupFunc = useAction(createShopGroup)
  const updateShopGroupFunc = useAction(updateShopGroup)
  const deleteShopGroupFunc = useAction(deleteShopGroup)

  return (
    <ShopGroupsSwitchesContainer>
      {groups.map((group) => {
        const currentShopGroup = shopGroupsAttributes.find(
          (shopGroup) => Number(shopGroup.group_id) === Number(group.id)
        )

        const active = !!currentShopGroup
        const position = currentShopGroup?.position || 0

        return (
          <ShopGroupSwitch
            key={group.id}
            name={group.name}
            value={{
              active,
              position,
            }}
            onPositionChange={(value) =>
              updateShopGroupFunc({
                values: {
                  shop_group_id: currentShopGroup.shop_group_id,
                  position: value,
                },
              })
            }
            onActiveChange={() =>
              active
                ? deleteShopGroupFunc({
                    values: currentShopGroup.shop_group_id,
                  })
                : createShopGroupFunc({
                    values: group.id,
                  })
            }
          />
        )
      })}
    </ShopGroupsSwitchesContainer>
  )
}

export default ShopGroupsSwitches
