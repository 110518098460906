import map from 'lodash/map'
import { FIELD_NAMES } from 'const/forms'
import { ACTIVE_FILTERS } from 'const/shops'
import React from 'react'
import Status from 'components/Status'
import Payout from 'components/Payout'
import { Row } from 'antd'

const getDynamicColumns = ({ data, key, render }) =>
  map(data, (item) => ({
    width: 100,
    title: item.replaceAll('_', ' '),
    dataIndex: [key, item],
    render: render,
    align: 'center',
    key: item,
  }))

export const getFlagsColumns = (data) =>
  getDynamicColumns({
    data,
    key: FIELD_NAMES.flags,
    render: (value) => <Status value={value} />,
    filtersItems: ACTIVE_FILTERS,
  })

export const getFeaturesColumns = (data) =>
  getDynamicColumns({
    data,
    key: FIELD_NAMES.features,
    sort: (a = '', b = '') => a.localeCompare(b),
  })

export const getEPCSColumns = (data) =>
  getDynamicColumns({
    data,
    key: FIELD_NAMES.epcs,
    sort: (a = 0, b = 0) => a - b,
  })

export const getActionsColumns = (data) => [
  {
    width: 200,
    fixed: 'left',
    title: 'Name',
    dataIndex: FIELD_NAMES.name,
    align: 'center',
    key: FIELD_NAMES.name,
  },
  {
    width: 150,
    title: 'Type',
    dataIndex: FIELD_NAMES.type,
    align: 'center',
    key: FIELD_NAMES.type,
  },
  {
    width: 250,
    title: 'Default payout',
    dataIndex: FIELD_NAMES.defaultPayout,
    align: 'center',
    key: FIELD_NAMES.defaultPayout,
    render: (payout) => <Payout payout={payout} />,
  },
  {
    title: 'Specific payouts',
    dataIndex: FIELD_NAMES.specificPayouts,
    align: 'center',
    key: FIELD_NAMES.specificPayouts,
    render: (payouts) => (
      <Row justify="space-between">
        {map(payouts, (payout) => (
          <Payout payout={payout} />
        ))}
      </Row>
    ),
  },
]
