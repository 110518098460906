import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  text-align: left;
  flex-flow: row nowrap;
  margin: 15px 0;
  justify-content: center;

  .ant-divider-horizontal {
    margin: 15px 0;
  }

  .field-label {
    width: 20%;
    text-align: right;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;

    .anticon-exclamation-circle {
      font-size: 13px;
      margin-right: 3px;
    }
    ${({ required }) =>
      required &&
      `
      &:before {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun,sans-serif;
        line-height: 1;
        content: '*';
      }
    `}
  }

  .field-control {
    max-width: 400px;
    width: 80%;
    color: ${({ error }) => (error ? 'red' : '')};
    input {
      border: ${({ error }) => (error ? '1px solid red' : '')};
    }

    .ant-input-number {
      width: 100%;
    }

    .ant-picker-range {
      input {
        border: unset;
      }
    }

    .ant-select-selector {
      border: ${({ error }) => (error ? '1px solid red' : '')};
    }

    .ant-picker {
      width: 100%;
    }

    .ant-select {
      width: 100%;
    }
    .ant-upload-picture-card-wrapper {
      display: unset;
      width: unset;
      min-width: 104px;
      min-height: 104px;
    }

    .ant-upload-list-picture-card-container {
      margin-right: 0;
    }

    .ant-upload-list-picture-card {
      display: flex;

      .ant-upload-list-item {
        width: 104px;
        margin: 0;
      }
    }
  }
`
