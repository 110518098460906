import React from 'react'
import PropTypes from 'prop-types'
import { Layout, Spin } from 'antd'
import { diff } from 'deep-object-diff'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import {
  getEditStoreContentInitialValues,
  getIsSavingActive,
} from 'domains/retailers/selectors'
import { updateRetailer } from 'domains/retailers/actions'
import { ENTITY_ATTRS, RETAILER_ATTRS_RESET_VALUES } from 'const/api'
import useAction from 'utils/useAction'
import {
  Buttons,
  StyledButton,
  StyledForm,
  Label,
  Type,
  HeaderRow,
} from './EditStoreContent.styles'
import { Form as FinalForm, Field } from 'react-final-form'
import FieldFAQS from 'components/FieldFAQS/FieldFAQS'
import FieldInput from 'components/FieldInput/FieldInput'
import FieldTextarea from 'components/FieldTextarea/FieldTextarea'
import { nameValidator } from 'helpers/validators'
import arrayMutators from 'final-form-arrays'
import RetailerCountry from 'components/RetailerCountry'

const autoSize = { minRows: 3, maxRows: 3 }

const EditStoreContent = ({ closeModal, id, type, hideTermsAndConditions }) => {
  const initialValues = useSelector(getEditStoreContentInitialValues)(id)
  const isSaving = useSelector(getIsSavingActive)

  const updateRetailerFunc = useAction(updateRetailer)
  const submitHandler = (values) => {
    const diffAttrs = Object.keys(diff(initialValues, values))
    if (diffAttrs.length) {
      const attributes = diffAttrs.reduce((result, attrName) => {
        result[attrName] =
          values[attrName] || RETAILER_ATTRS_RESET_VALUES[attrName]
        return result
      }, {})
      updateRetailerFunc({
        id,
        type,
        attributes,
        closeModal,
        successMessage: 'Retailer has been updated',
      })
    }
  }

  return (
    <Layout.Content>
      <Spin size="large" spinning={isSaving}>
        <FinalForm
          mutators={{
            ...arrayMutators,
          }}
          initialValues={initialValues}
          onSubmit={submitHandler}
          render={({ handleSubmit, values, dirtyFields }) => {
            const isFAQDirty = !!dirtyFields[ENTITY_ATTRS.faq]
            return (
              <StyledForm layout="vertical" size="small">
                <HeaderRow>
                  <Field
                    name="id"
                    label="Retailer ID"
                    component={FieldInput}
                    disabled
                  />
                  <Type>
                    <Label>Retailer type:</Label>
                    <RetailerCountry retailer={values} />
                  </Type>
                </HeaderRow>

                <Field
                  name={ENTITY_ATTRS.name}
                  disabled
                  label="Name"
                  validate={nameValidator}
                  component={FieldInput}
                />

                <Field
                  disabled={!hideTermsAndConditions}
                  name={ENTITY_ATTRS.termsAndConditions}
                  label="Special Terms and Conditions"
                  component={FieldTextarea}
                  autoSize={autoSize}
                />
                {!hideTermsAndConditions && (
                  <>
                    <Field
                      name={ENTITY_ATTRS.whatHappensNext}
                      label="What happens next?"
                      component={FieldTextarea}
                      autoSize={autoSize}
                    />

                    <Field
                      name={ENTITY_ATTRS.description}
                      label="Description"
                      component={FieldTextarea}
                      autoSize={autoSize}
                    />

                    <Field
                      name={ENTITY_ATTRS.metaDescription}
                      label="SEO Description"
                      component={FieldTextarea}
                      autoSize={autoSize}
                      maxLength={160}
                    />

                    <FieldFAQS
                      isEmpty={isEmpty(values[ENTITY_ATTRS.faq])}
                      isDirty={isFAQDirty}
                    />
                  </>
                )}

                <Buttons>
                  <StyledButton size="default" onClick={closeModal}>
                    Cancel
                  </StyledButton>

                  <StyledButton
                    disabled={isSaving}
                    size="default"
                    type="primary"
                    onClick={handleSubmit}
                  >
                    Save changes
                  </StyledButton>
                </Buttons>
              </StyledForm>
            )
          }}
        />
      </Spin>
    </Layout.Content>
  )
}

EditStoreContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  hideTermsAndConditions: PropTypes.bool,
}

EditStoreContent.defaultProps = {
  hideTermsAndConditions: false,
}

export default EditStoreContent
