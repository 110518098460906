import React from 'react'
import chunk from 'lodash/chunk'
import { FIELD_NAMES } from 'const/forms'
import { mobileNotificationPropType } from 'const/propTypes'
import { Root } from './MobileNotification.styles'
import { Row } from 'antd'
import Json from 'components/Json'
import InfoItem from './InfoItem'

const MobileNotification = ({ data }) => {
  const { [FIELD_NAMES.notificationData]: notification, ...rest } = data
  const chunks = chunk(Object.entries(rest), 2)
  return (
    <Root>
      {chunks.map(([first, second]) => (
        <Row gutter={[16, 16]} key={first[0]}>
          <InfoItem span={12} data={first} />
          <InfoItem span={12} data={second} />
        </Row>
      ))}
      {notification && <Json src={notification} title="Notification Data" />}
    </Root>
  )
}

MobileNotification.propTypes = {
  data: mobileNotificationPropType.isRequired,
}

export default MobileNotification
