import React from 'react'
import uniqueId from 'lodash/uniqueId'
import isArray from 'lodash/isArray'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import {
  Actions,
  CloseSquare,
  Description,
  Info,
  ItemWrapper,
} from './Changes.styles'
import Checkbox from 'components/Checkbox'
import { formatFieldName } from 'helpers/formatters/forms'
import { useSelector } from 'react-redux'
import {
  getFieldInitialValue,
  getEditorialTabFieldInitialValue,
} from 'domains/retailers/selectors'
import { FORM_NAMES } from 'const/forms'

const initialValueFieldGetterMap = {
  [FORM_NAMES.EDITORIAL_CHANGES]: getEditorialTabFieldInitialValue,
  [FORM_NAMES.STORE_CHANGES]: getFieldInitialValue,
}

const Item = ({ handleChange, item, formName }) => {
  const initialValue = useSelector(initialValueFieldGetterMap[formName])(
    item.name
  )
  const handleClearField = () => handleChange(item.name, initialValue)
  const descriptions = isArray(item.description)
    ? item.description
    : [item.description]

  return (
    <ItemWrapper>
      <Info>
        {descriptions.map((text) => (
          <Description key={uniqueId()}>{text}</Description>
        ))}
      </Info>
      <Actions>
        <Field name={formatFieldName(item.name)} component={Checkbox} />
        <CloseSquare onClick={handleClearField} />
      </Actions>
    </ItemWrapper>
  )
}

Item.propTypes = {
  handleChange: PropTypes.func.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]).isRequired,
  }).isRequired,
  formName: PropTypes.string.isRequired,
}

export default Item
