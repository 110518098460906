import { createSelector } from '@reduxjs/toolkit'
import { areFiltersAndSorterEmpty } from 'helpers/filters'

export const logsState = ({ adminLogs }) => adminLogs

export const getSortedLogs = createSelector(logsState, ({ data }) => data)

export const getPage = createSelector(logsState, ({ page }) => page)

export const getFilters = createSelector(logsState, ({ filters }) => filters)

export const getSorter = createSelector(logsState, ({ sorter }) => sorter)

export const getPageCount = createSelector(
  logsState,
  ({ pageCount }) => pageCount
)

export const getIsDataFilteredOrSorted = createSelector(
  [getFilters, getSorter],
  areFiltersAndSorterEmpty
)

export const getIsLoaded = createSelector(logsState, ({ isLoaded }) => isLoaded)

export const getIsSavingActive = createSelector(
  logsState,
  ({ isSavingActive }) => isSavingActive
)
